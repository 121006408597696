import { FC } from "react";
import { StyledTabbedContainer } from "elements";
import { menu } from "./constants";
import Users from "./Users/Users";
import ManageUser from "./ManageUser/ManageUser";
import UserManagementToast from "./UserManagementToast";
import { useUserManagementTab } from "./useUserManagementTab";
import { UserManagementTabOption } from "types";

const UserManagement: FC = () => {
  const { selectedTab, handleChangeTab } = useUserManagementTab();

  return (
    <StyledTabbedContainer>
      <div className="tabs">
        {menu.map((item) => (
          <button
            key={item}
            name={item}
            onClick={handleChangeTab}
            className={selectedTab === item ? "selected" : ""}
            disabled={
              item === UserManagementTabOption.Details &&
              selectedTab !== UserManagementTabOption.Details
            }
            data-testid={`menu-${item.toLowerCase()}`}
          >
            <h3>{item}</h3>
          </button>
        ))}
      </div>

      <div className="container">
        {selectedTab === "Users" && <Users />}
        {selectedTab === "Details" && <ManageUser />}
      </div>

      <UserManagementToast />
    </StyledTabbedContainer>
  );
};

export default UserManagement;
