import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISimulation, ITask } from "@ehabitation/ts-utils/browser";
import {
  ITaskObj,
  ITaskUpdate,
  IThresholdUpdate,
  ITaskDependency,
} from "types";
import { SimulationResult } from "helpers";
import { RootState } from "store";

export interface ITransientData {
  showChildren?: boolean;
}
export interface ITransientObj {
  [id: string]: ITransientData;
}

export interface ITaskState {
  currentTaskId: string | null;
  loadedPlanId: string | null;
  allTasks: ITaskObj | null;
  latestCurrentPlanSimulation: ISimulation | null;
  currentPlanSimulation: ISimulation | null;
  currentPlanSimulationResults: SimulationResult | null;
  transientTaskData: ITransientObj | null;
  tasksSnapshot: ITaskObj | null;
  dependencyMap: ITaskDependency | null;
}

export const initialState: ITaskState = {
  currentTaskId: null,
  loadedPlanId: null,
  allTasks: null,
  latestCurrentPlanSimulation: null,
  currentPlanSimulation: null,
  currentPlanSimulationResults: null,
  transientTaskData: {},
  tasksSnapshot: null,
  dependencyMap: null,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setLoadedPlanId: (state, action: PayloadAction<string>) => {
      state.loadedPlanId = action.payload;
    },

    setAllTasks: (state, action: PayloadAction<ITaskObj | null>) => {
      state.allTasks = action.payload;
    },

    setTransientData: (state, action: PayloadAction<ITransientObj>) => {
      state.transientTaskData = action.payload;
    },

    setTasksSnapshot: (state, action: PayloadAction<ITaskObj | null>) => {
      state.tasksSnapshot = action.payload;
    },

    setCurrentTaskId: (state, action: PayloadAction<string | null>) => {
      state.currentTaskId = action.payload;
    },

    setLatestCurrentPlanSimulation: (
      state,
      action: PayloadAction<ISimulation | null>
    ) => {
      state.latestCurrentPlanSimulation = action.payload;
    },

    setCurrentPlanSimulation: (
      state,
      action: PayloadAction<ISimulation | null>
    ) => {
      state.currentPlanSimulation = action.payload;
    },

    setCurrentPlanSimulationResults: (
      state,
      action: PayloadAction<SimulationResult | null>
    ) => {
      state.currentPlanSimulationResults = action.payload;
    },

    updateTask: (
      state,
      action: PayloadAction<{
        id: string;
        update: ITaskUpdate;
      }>
    ) => {
      if (
        state.allTasks &&
        state.currentTaskId &&
        action.payload.id === state.currentTaskId
      ) {
        state.allTasks[action.payload.id] = {
          ...state.allTasks[action.payload.id],
          ...action.payload.update,
        };
      }
    },

    updateTaskThresholdById: (
      state,
      action: PayloadAction<{
        id: string;
        update: IThresholdUpdate;
      }>
    ) => {
      const { id, update } = action.payload;

      if (state.allTasks && state.currentTaskId) {
        state.allTasks[id] = { ...state.allTasks[id], ...update };
      }
    },

    updateWBSThresholdById: (
      state,
      action: PayloadAction<{
        id: string;
        update: IThresholdUpdate;
      }>
    ) => {
      const { id, update } = action.payload;

      if (state.allTasks) {
        state.allTasks[id] = { ...state.allTasks[id], ...update };
      }
    },

    batchUpdateTask: (state, action: PayloadAction<ITaskObj>) => {
      if (state.allTasks)
        state.allTasks = {
          ...state.allTasks,
          ...action.payload,
        };
    },

    toggleShowSubtasks: (state, action: PayloadAction<string>) => {
      if (
        state.transientTaskData &&
        state.allTasks &&
        state.allTasks[action.payload].WBS
      ) {
        if (!state.transientTaskData.hasOwnProperty(action.payload)) {
          state.transientTaskData[action.payload] = { showChildren: false };
        }

        const previousValue = state.transientTaskData[
          action.payload
        ].hasOwnProperty("showChildren")
          ? state.transientTaskData[action.payload].showChildren
          : false;

        state.transientTaskData[action.payload].showChildren = !previousValue;
      }
    },

    clearTaskState: () => {
      return initialState;
    },
  },
});

export const {
  clearTaskState,
  batchUpdateTask,
  toggleShowSubtasks,
  setLoadedPlanId,
  setAllTasks,
  setLatestCurrentPlanSimulation,
  setCurrentPlanSimulation,
  setCurrentPlanSimulationResults,
  setTransientData,
  setCurrentTaskId,
  setTasksSnapshot,
  updateTask,
  updateTaskThresholdById,
  updateWBSThresholdById,
} = tasksSlice.actions;

export function allTasksSelector(state: RootState) {
  return state.task.allTasks;
}

export function selectLatestCurrentPlanSimulation(state: RootState) {
  return state.task.latestCurrentPlanSimulation;
}
export function selectCurrentPlanSimulation(state: RootState) {
  return state.task.currentPlanSimulation;
}
export function selectCurrentPlanSimulationResults(state: RootState) {
  return state.task.currentPlanSimulationResults;
}

export function selectTransientTaskData(state: RootState) {
  return state.task.transientTaskData;
}

export const selectCurrentTaskId = (state: RootState) =>
  state.task.currentTaskId;

export const tasksSnapshotSelector = (state: RootState) =>
  state.task.tasksSnapshot;

export const selectCurrentTab = (state: RootState) => state.tabs.selectedTab;

export const selectDependencyMap = (state: RootState) =>
  state.task.dependencyMap;

export const selectLoadedPlanId = (state: RootState) => state.task.loadedPlanId;

export default tasksSlice.reducer;
