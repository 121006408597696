import { startCase } from "lodash";
import React from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedChart, setSelectedChart } from "store/ui/uiSlice";
import { ICharts } from "types/ui";

import { StyledChartsToggleButton } from "./styles";

interface IChartsToggleButton {
  label: ICharts;
}

const ChartsToggleButton = ({ label }: IChartsToggleButton) => {
  const dispatch = useAppDispatch();
  const selectedChart = useAppSelector(selectSelectedChart);

  const toggleCharts = () => {
    dispatch(setSelectedChart(label));
  };

  return (
    <StyledChartsToggleButton
      data-testid={`chart-toggle-button-${label}`}
      selected={label === selectedChart}
      onClick={toggleCharts}
    >
      {startCase(label)}
    </StyledChartsToggleButton>
  );
};

export default ChartsToggleButton;
