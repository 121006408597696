import { persistActiveSite } from "helpers";
import { AppThunk } from "store";
import { selectUserId, updateUserState } from "store/auth";

export const setActiveSiteId: AppThunk =
  (siteId: string) => async (dispatch, getState) => {
    const state = getState();
    const userId = selectUserId(state);
    dispatch(updateUserState({ siteId: siteId }));

    if (siteId) {
      persistActiveSite(userId, siteId);
    }
  };
