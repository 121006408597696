import {
  AiOutlineUpload,
  AiOutlineFileExcel,
  AiOutlineBug,
  AiOutlineAudit,
} from "react-icons/ai";
import {
  BiHome,
} from "react-icons/bi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { GrUserAdmin, GrOverview } from "react-icons/gr";

export const options = {
  dashboard: {
    icon: <BiHome className="h-7 w-7" />,
    text: "Dashboard",
  },
  admin: {
    icon: <GrUserAdmin className="h-7 w-7" />,
    text: "Admin",
  },
  logout: {
    icon: <RiLogoutBoxRLine className="h-7 w-7" />,
    text: "Logout",
  },
  activity: {
    icon: <AiOutlineFileExcel className="h-7 w-7" />,
    text: "Activity Export",
  },
  ["debug-model"]: {
    icon: <AiOutlineBug className="h-7 w-7" />,
    text: "Model Rerun",
  },
  ["debug-audit"]: {
    icon: <AiOutlineAudit className="h-7 w-7" />,
    text: "Log & Copy State",
  },
};
