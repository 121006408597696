import React, { FC } from "react";
import { useAppSelector } from "store";
import { selectCurrentTask } from "store/tasks";
import { StyledTaskInfo } from "./styles";
import DescriptionInput from "./DescriptionInput/DescriptionInput";
import TitleInput from "./TitleInput/TitleInput";
import DateControls from "./DateControls/DateControls";
import { isGuest as roleIsGuest } from "@ehabitation/ts-utils/browser";
import { selectRole } from "store/auth";

const InformationTab: FC = () => {
  const currentTask = useAppSelector(selectCurrentTask);
  const userRole = useAppSelector(selectRole);
  const isGuest = roleIsGuest(userRole!);
  if (!currentTask) return null;

  return (
    <StyledTaskInfo>
      <div className="task-info" data-testid="info-tab">
        <TitleInput stateValue={currentTask.title} disabled={isGuest} />

        <DescriptionInput
          stateValue={currentTask.description}
          disabled={isGuest}
        />

        <DateControls currentTask={currentTask} disabled={isGuest} />
      </div>
    </StyledTaskInfo>
  );
};

export default InformationTab;
