const dep_utils = {
  // Extract location data from task bars
  // x = start of bar, w = width of bar, r = width of selected risk
  get_dependency_bar_attributes(bar) {
    const x =
      bar.$bar.dep && typeof bar.$bar.x === "number"
        ? bar.$bar.x
        : bar.$bar.getX();
    const w =
      bar.$bar.dep && typeof bar.$bar.width === "number"
        ? bar.$bar.width
        : bar.$bar.getWidth();
    const r = bar.$bar.selectedRisk || 0;

    return { x, w, r };
  },

  get_dependency_delay_type(rel) {
    if (rel.slice(-1) === "F") return "finish";
    else return "start";
  },

  // Rules to determine parent/child relationship
  check_valid_dependency(rel, parent, child) {
    const p = this.get_dependency_bar_attributes(parent);
    const c = this.get_dependency_bar_attributes(child);

    if (rel === "FF") return c.x + c.w + c.r !== p.x + p.w + p.r;
    if (rel === "SS") return c.x !== p.x;
    if (rel === "FS") return c.x < p.x + p.w + p.r;
    if (rel === "SF") return c.x + c.w + c.r > p.x;

    return false;
  },

  // Calculate where to move child bar based on parent location and selected risk
  calculate_dependency_offset_days(gantt, rel, parent, child) {
    this.gantt = gantt;

    const p = this.get_dependency_bar_attributes(parent);
    const c = this.get_dependency_bar_attributes(child);

    let offset = 0;

    if (rel === "FF")
      offset = Math.floor(
        (p.x + p.w + p.r - (c.x + c.w + c.r)) / this.gantt.options.step
      );
    if (rel === "SS")
      offset = Math.floor((p.x - c.x) / this.gantt.options.step);
    if (rel === "FS")
      offset = Math.floor((p.x + p.w + p.r - c.x) / this.gantt.options.step);
    if (rel === "SF")
      offset = Math.floor((p.x - (c.x + c.w + c.r)) / this.gantt.options.step);

    // Seems when we move negative we need to apply a +1 offset (probably related to hour display)
    return offset < 0 ? offset + 1 : offset;
  },
};

export default dep_utils;
