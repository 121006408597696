import styled from "styled-components";
import { flex, colors, fontSize, grays, font, highlightHover } from "styles";

export const StyledTitleInput = styled.div`
  ${flex("row", "space-between", "flex-start")}
  margin: 0;
  width: 100%;

  input,
  h2 {
    width: 90%;
    font-size: ${fontSize.xl};
    font-weight: 500;
    ${font("body")};
  }

  h2 {
    line-height: 1.1;
  }

  input {
    cursor: pointer;
    border: none;
    outline: none;
  }

  .icon-container {
    ${flex("row", "flex-end", "center")};

    .icon {
      font-size: 2.4rem;
      cursor: pointer;
      border-radius: 1rem;

      :active {
        background-color: #dedede;
      }
    }

    button {
      ${highlightHover()}
      outline: none;
      background-color: white;
      border: none;
      &:disabled {
        cursor: default;
      }
    }

    .bin {
      color: ${colors.warning};
    }

    .edit {
      color: ${grays.dark};
    }

    .save {
      color: ${colors.safe};
    }
  }
`;
