import { VIEW_MODE } from ".";
import date_utils from "./date_utils";
import { $, createSVG } from "./svg_utils";

export default class Bar {
  constructor(gantt, task, mode, draw = true) {
    this.set_defaults(gantt, task, mode);
    this.prepare();
    if (draw) this.draw();
    if (draw) this.bind();
    if (!draw) this.add_dep_data();
  }

  // This function lets us simulate an SVG bar without having to draw it
  // Useful for date manipulations of hidden tasks
  add_dep_data() {
    this.task._safeEnd = date_utils.parse(this.task.safetyEnd);

    this.risk_duration =
      date_utils.diff(this.task._safeEnd, this.task._end, "hour") /
      this.gantt.options.step;

    this.risk_width = this.gantt.options.column_width * this.risk_duration;

    this.$bar = {
      dep: true,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      id: this.task.id,
      selectedRisk: this.risk_width,
    };
  }

  set_defaults(gantt, task, mode) {
    this.view_mode = mode;
    this.action_completed = false;
    this.gantt = gantt;
    this.task = task;
    this.risk_bars = !task.WBS ? task.risks : [];
  }

  prepare() {
    this.prepare_values();
    this.prepare_helpers();
  }

  prepare_values() {
    this.invalid = this.task.invalid;
    this.height = this.gantt.options.bar_height;
    this.x = this.compute_x();
    this.y = this.compute_y();
    this.corner_radius = this.gantt.options.bar_corner_radius;
    this.duration =
      date_utils.diff(this.task._end, this.task._start, "hour") /
      this.gantt.options.step;
    this.width = this.gantt.options.column_width * this.duration;

    this.cummulated_width = this.width;

    this.selectedRisk = this.choose_threshold_class(this.task.accuracy);

    this.group = createSVG("g", {
      class: "bar-wrapper " + (this.task.custom_class || ""),
      "data-id": this.task.id,
    });
    this.bar_group = createSVG("g", {
      class: "bar",
      append_to: this.group,
    });
    this.risk_bars_group = createSVG("g", {
      class: "bar-risk",
      append_to: this.group,
    });
  }

  prepare_helpers() {
    SVGElement.prototype.getX = function () {
      return +this.getAttribute("x");
    };
    SVGElement.prototype.getY = function () {
      return +this.getAttribute("y");
    };
    SVGElement.prototype.getWidth = function () {
      return +this.getAttribute("width");
    };
    SVGElement.prototype.getHeight = function () {
      return +this.getAttribute("height");
    };
    SVGElement.prototype.getEndX = function () {
      return this.getX() + this.getWidth();
    };
    SVGElement.prototype.getValueLabel = function () {
      return this.getAttribute("valueLabel");
    };
    SVGElement.prototype.getValueWidth = function () {
      return this.getAttribute("valueWidth");
    };
    SVGElement.prototype.getRiskThreshold = function () {
      return this.getAttribute("riskThreshold");
    };
    SVGElement.prototype.getID = function () {
      return this.getAttribute("id");
    };
    SVGElement.prototype.getClass = function () {
      return this.getAttribute("class");
    };
    SVGElement.prototype.setWidth = function (w) {
      this.setAttribute("width", w);
    };
    SVGElement.prototype.setClass = function (cl) {
      this.setAttribute("class", cl);
    };
  }

  // Function to draw WBS bars
  buildWbsDashArray(dimensions, border) {
    const borderOrder = ["top", "right", "bottom", "left"];

    const sideOffset = dimensions.height / 2;

    const sideLengths = {
      top: dimensions.width,
      right: dimensions.height - sideOffset,
      bottom: dimensions.width + sideOffset * 2,
      left: dimensions.height - sideOffset,
    };

    const dashArray = [];
    let lastSide = -1;
    for (let side of borderOrder) {
      if (lastSide !== border[side]) {
        if (side === "top" && !border[side]) {
          // Start with 0 so we can gap the top
          dashArray.push(0);
        }

        // Start a line or a gap
        dashArray.push(sideLengths[side]);
      } else {
        // Continue the last line or gap
        dashArray[dashArray.length - 1] += sideLengths[side];
      }
      lastSide = border[side];
    }

    return dashArray.join(",");
  }

  draw() {
    // Conditionals to handle different bar types (WBS, milestone, task)
    if (this.task.WBS) {
      this.draw_wbs();
    } else if (this.task.milestone) {
      this.draw_milestone();
    } else {
      this.draw_bar();
      this.draw_base_confidence_label();
      if (this.task.isModelBrokenDep || this.task.isTaskBrokenDep)
        this.draw_dependency_warning_icon();
      this.draw_resize_handles();
      this.draw_risk_bars();
      this.draw_risk_bar_label();
    }
  }

  //
  //
  //
  //
  //
  //BAR FUNCTIONS
  //
  //
  //
  //
  //

  // draw the milestone bar
  draw_milestone() {
    this.$bar = createSVG("rect", {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "milestone",
      append_to: this.bar_group,
      id: `bar_milestone_${this.task.objectId}`,
    });
  }

  //draws the wbs bar
  draw_wbs() {
    this.$bar = createSVG("rect", {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "wbs",
      append_to: this.bar_group,
      id: `bar_wbs_${this.task.objectId}`,
    });

    this.draw_wbs_border();
  }

  draw_wbs_border() {
    this.$bar.style.setProperty(
      "stroke-dasharray",
      this.buildWbsDashArray(
        {
          width: parseFloat(this.width),
          height: parseFloat(this.height),
        },
        {
          top: true,
          right: true,
          bottom: false,
          left: true,
        }
      )
    );
  }

  //text appearing on bar containing the bar name
  draw_wbs_label() {
    createSVG("text", {
      x: this.x + this.width / 2,
      y: this.y + this.height * 0.75,
      innerHTML: this.task.title,
      class: "bar-label",
      append_to: this.bar_group,
      id: "task_label_" + this.task.id,
    });
    // labels get BBox in the next tick
    requestAnimationFrame(() => this.update_label_position());
  }

  //draws the task bar
  draw_bar() {
    const barClass = this.task.isCriticalPath ? "bar critical" : "bar main";

    this.$bar = createSVG("rect", {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: barClass,
      append_to: this.bar_group,
      id: this.task.id,
      selectedRisk: this.selectedRisk,
      "data-testid": `${this.task.title}_gantt_bar`,
    });

    if (this.invalid) {
      this.$bar.classList.add("bar-invalid");
    }

    //bind click event to the risk_bar
    this.setup_click_bar(this.$bar);
  }

  //text appearing on bar containing the bar name
  draw_label() {
    const text = createSVG("text", {
      x: this.x + this.width / 2,
      y: this.y + this.height / 2,
      innerHTML: this.task.title,
      class: "bar-label",
      append_to: this.bar_group,
      id: "task_label_" + this.task.id,
    });
    // labels get BBox in the next tick
    requestAnimationFrame(() => this.update_label_position());

    //bind click event to the risk_bar
    this.setup_click_bar_label(text);
  }

  //text appearing on bar containing the bar name
  draw_base_confidence_label() {
    if (this.width > 30) {
      const labelOffset = this.task.baseAccuracy === 100 ? 20 : 17;
      const text = createSVG("text", {
        x: this.x + this.width - labelOffset,
        y: this.y + this.height / 2,
        innerHTML: `${this.task.baseAccuracy}%`,
        class: "confidence-label",
        id: `${this.task.id}_confidence_label`,
        append_to: this.bar_group,
      });
      // labels get BBox in the next tick
      requestAnimationFrame(() => this.update_label_position());

      //bind click event to the risk_bar
      this.setup_click_bar_label(text);
    }
  }

  // Draw warning icon when dependencies aren't met.
  draw_dependency_warning_icon() {
    const labelSVG = document.getElementById(
      `${this.task.id}_dep_warning_label`
    );

    if (labelSVG) return;

    const text = createSVG("text", {
      x: this.x + 10,
      y: this.y + this.height / 2 - 2,
      innerHTML: `\u26A0\uFE0E`, // Warning icon with consistent styling
      class: "dep-warning-label",
      id: `${this.task.id}_dep_warning_label`,
      append_to: this.bar_group,
    });
    // labels get BBox in the next tick
    requestAnimationFrame(() => this.update_label_position());

    //bind click event to the risk_bar
    this.setup_click_bar_label(text);
  }

  draw_resize_handles() {
    if (this.invalid) return;

    const bar = this.$bar;
    const handle_width = 8;

    createSVG("rect", {
      x: bar.getX() + bar.getWidth() - 9,
      y: bar.getY() + 1,
      width: handle_width,
      height: this.height - 2,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "handle right",
      append_to: this.bar_group,
    });

    createSVG("rect", {
      x: bar.getX() + 1,
      y: bar.getY() + 1,
      width: handle_width,
      height: this.height - 2,
      rx: this.corner_radius,
      ry: this.corner_radius,
      class: "handle left",
      append_to: this.bar_group,
    });
  }

  //click event on task bar
  setup_click_event() {
    let timer;

    // Will Trigger A Popup On Hover Of Main Task Bar
    $.on(this.$bar, "mouseover", (e) => {
      if (this.gantt.bar_being_dragged) return;
      if (this.action_completed) return; // just finished a move action, wait for a few seconds
      timer = setTimeout(
        () =>
          this.gantt.show_detailed_popup({
            target_element: this.$bar,
            task: this.task,
            x: e.clientX,
            y: e.clientY,
          }),
        700
      );

      this.gantt.unselect_all();
      this.bar_group.classList.add("active");
    });

    $.on(this.bar_group, "focus " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      this.show_popup();
      this.gantt.unselect_all();
      this.bar_group.classList.add("active");
    });

    $.on(
      this.bar_group,
      "mouseout " + this.gantt.options.popup_trigger,
      (e) => {
        clearTimeout(timer);

        if (this.action_completed) return; // just finished a move action, wait for a few seconds

        this.gantt.hide_popup();

        this.gantt.unselect_all();
        this.bar_group.classList.remove("active");
      }
    );

    $.on(this.bar_group, "dblclick", (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      this.gantt.trigger_event("click", [this.task]);
    });
  }

  //binds the setup click event to the bar
  bind() {
    if (this.invalid) return;
    this.setup_click_event();
  }

  //pop up for task bar
  show_popup() {
    if (this.gantt.bar_being_dragged) return;

    const start_date = date_utils.format(
      this.task._start,
      "MMM D",
      this.gantt.options.language
    );
    const end_date = date_utils.format(
      date_utils.add(this.task._end, -1, "second"),
      "MMM D",
      this.gantt.options.language
    );
    const subtitle = start_date + " - " + end_date;

    this.gantt.show_popup({
      target_element: this.$bar,
      title: this.task.title,
      subtitle: subtitle,
      task: this.task,
    });
  }

  recalculate_bar_dimensions(offset = 0) {
    this.x = this.compute_x();
    this.duration =
      date_utils.diff(this.task._end, this.task._start, "hour") /
        this.gantt.options.step +
      offset;
    this.width = this.gantt.options.column_width * this.duration;
  }

  //
  //
  //
  //
  //
  // RISK BARS FUNCTIONS
  //
  //
  //
  //
  //

  // draws all the risk_bars for the thresholds given
  // function is gross, once we revamp the risk data this should be simplified
  draw_risk_bars() {
    let lastRiskBarLength = 0;
    this.risk_bars = this.risk_bars.map((riskObj, i) => {
      //find % of accuarcy
      const riskThreshold = Object.keys(riskObj)[0];

      //CSS class for that risk bar
      let className =
        this.task.accuracy >= Number(riskThreshold)
          ? this.choose_threshold_class(this.task.accuracy)
          : `${this.choose_threshold_class(
              riskThreshold
            )} bar-risk-transparent`;

      const riskWidth = riskObj[riskThreshold].width
        ? riskObj[riskThreshold].width - lastRiskBarLength
        : 0;
      const riskLabel = riskObj[riskThreshold].width
        ? riskObj[riskThreshold].width
        : 0;

      lastRiskBarLength = riskObj[riskThreshold].width;

      //create  ris bar SVG
      const risk_bar = createSVG("rect", {
        x: this.x + this.cummulated_width,
        y: this.y,
        width:
          this.gantt.options.column_width *
          (riskWidth / this.gantt.options.step),
        height: this.height,
        rx: this.corner_radius,
        ry: this.corner_radius,
        class: className,
        append_to: this.risk_bars_group,
        riskThreshold,
        valueLabel: riskLabel,
        valueWidth: riskWidth,
        id: this.task.title + riskThreshold,
        disabled: false,
      });

      if (this.invalid) {
        risk_bar.classList.add("bar-invalid");
      }

      //updates the cumulated width of the task bar + all the risk bars until this one
      this.cummulated_width +=
        this.gantt.options.column_width * (riskWidth / this.gantt.options.step);

      //bind click event to the risk_bar
      this.setup_click_event_riskBar(risk_bar, i);

      return risk_bar;
    });

    // If the accuracy has been changed in the front-end, this code will trigger a client-side model update
    // We iterate over the task risks to find the risk that has been selected.
    // Then we have to format the days delay into selectedRisk (hours delay / 24 * gantt column width).
    // This is how bar length is calculated in the gantt library.
    if (this.task.hasSafetyChanged === "true") {
      for (let risk of this.task.risks) {
        if (
          risk[this.task.accuracy] ||
          this.task.accuracy === this.task.baseAccuracy
        ) {
          if (this.task.accuracy === this.task.baseAccuracy) {
            this.$bar.selectedRisk = 0;
          } else {
            this.$bar.selectedRisk =
              (risk[this.task.accuracy].width / 24) *
              this.gantt.options.column_width;
          }
          this.task.hasSafetyChanged = "false";
          this.proposed_end_changed(this.task.accuracy);
        }
      }
    }
  }

  //text appearing on bar containing the bar name
  draw_risk_bar_label() {
    if (this.risk_bars.length > 0) {
      this.risk_bar_labels = this.risk_bars.map((riskObj, i) => {
        const labelOffset =
          riskObj.getWidth() < 30 ? riskObj.getWidth() * 0.5 : 15;

        const label = createSVG("text", {
          x: riskObj.getX() + riskObj.getWidth() - labelOffset,
          y: riskObj.getY() + riskObj.getHeight() / 2,
          innerHTML:
            this.view_mode === VIEW_MODE.YEAR
              ? ""
              : parseInt(riskObj.getValueWidth()) !== 0
              ? `${riskObj.getRiskThreshold()}%`
              : "",
          class: "bar-label risk-label",
          append_to: this.risk_bars_group,
          id: riskObj.id + riskObj.getRiskThreshold() + "label",
        });
        // labels get BBox in the next tick
        requestAnimationFrame(() => this.update_label_position());

        //bind click event to the risk_bar
        this.setup_click_event_riskLabel(label, i);

        return label;
      });
    } else if (!this.task.milestone) {
      const label = createSVG("text", {
        x: this.x + this.width + 50,
        y: this.y + this.height / 2,
        innerHTML: this.gantt.isSimulating ? "Simulating..." : "",
        class: "bar-label",
        append_to: this.risk_bars_group,
        id: "loading_label_" + this.task.id,
      });
      return [label];
    }
  }

  // choose appropriate CSS class for risk bar
  choose_threshold_class(riskThreshold) {
    let className;
    if (riskThreshold < 30) {
      className = "bar-risk-very-high";
    } else if (riskThreshold < 60) {
      className = "bar-risk-high";
    } else if (riskThreshold < 80) {
      className = "bar-risk-low";
    } else if (riskThreshold <= 100) {
      className = "bar-risk-very-low";
    }

    return className;
  }

  //drawing risk text on top of risk bar
  //currently we cant click on the text to show popup. why ?
  draw_risk_label(accuracy, value, x) {
    createSVG("text", {
      x: x,
      y: this.y + this.height / 2,
      innerHTML: `${accuracy} : ${Math.floor(value / 24)} day(s)`,
      class: "bar-label",
      append_to: this.risk_bars_group,
    });

    // labels get BBox in the next tick
    requestAnimationFrame(() => this.update_label_position());
  }

  //click event for riskBar
  //eventually will change the width of the task bar in here ?
  setup_click_event_riskBar(risk_bar, i) {
    let timer;
    $.on(risk_bar, "mouseover " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      timer = setTimeout(() => this.show_riskBar_popup(risk_bar), 1000);
      this.gantt.unselect_all();
      this.risk_bars_group.classList.add("active");
      clearTimeout();
    });

    $.on(risk_bar, "click", (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      // Stops scroll from jumping on risk bar selection.
      this.gantt.last_scroll_position =
        this.gantt.$svg.parentElement.scrollLeft;

      this.update_risk_bar_state(i);
      this.destroy_dependency_warning_icon();
    });

    $.on(risk_bar, "mouseout " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }
      this.gantt.unselect_all();
      this.risk_bars_group.classList.remove("active");
      clearTimeout(timer);
      this.gantt.hide_popup();
    });
  }

  //click event for riskBar label. Used to enable/disable selected risk.
  setup_click_event_riskLabel(label, i) {
    let timer;
    $.on(label, "mouseover " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }

      let risk_bar_id = label.id.slice(0, -7);
      const risk_bar = this.gantt.get_risk_bar(this.risk_bars, risk_bar_id);

      timer = setTimeout(() => this.show_riskBar_popup(risk_bar), 1000);
      this.gantt.unselect_all();
      this.risk_bars_group.classList.add("active");
      clearTimeout();
    });
    $.on(label, "mouseout " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }
      this.gantt.unselect_all();
      this.risk_bars_group.classList.remove("active");
      clearTimeout(timer);
      this.gantt.hide_popup();
    });

    $.on(label, "dbclick " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }
      this.gantt.hide_popup();

      // Stops scroll from jumping on risk bar selection.
      this.gantt.last_scroll_position =
        this.gantt.$svg.parentElement.scrollLeft;

      this.update_risk_bar_state(i);
      this.destroy_dependency_warning_icon();
    });
  }

  //click event for task bar label. Used to set selected risk to 0.
  setup_click_bar_label(label) {
    $.on(label, "dbclick " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }
      this.gantt.hide_popup();

      // Stops scroll from jumping on risk bar selection.
      this.gantt.last_scroll_position =
        this.gantt.$svg.parentElement.scrollLeft;

      this.set_risk_bar_to_base();
      this.destroy_dependency_warning_icon();
    });
  }

  //click event for task bar label. Used to set selected risk to 0.
  setup_click_bar(bar) {
    $.on(bar, "dbclick " + this.gantt.options.popup_trigger, (e) => {
      if (this.action_completed) {
        // just finished a move action, wait for a few seconds
        return;
      }
      this.gantt.hide_popup();

      this.gantt.last_scroll_position =
        this.gantt.$svg.parentElement.scrollLeft;

      this.set_risk_bar_to_base();
    });
  }

  // Will update the selected risk bar with the correct accuracy value.
  // Uses length of SVGs to determine new proposed end date and triggers.
  update_risk_bar_state(index) {
    let total,
      // eslint-disable-next-line
      delta,
      riskThreshold = 0;

    // Get wanted confidence level
    const targetSVG = document.getElementById(this.risk_bars[index].getID());
    const targetRisk = targetSVG ? targetSVG.getRiskThreshold() : -1;

    // Only change confidence level if accuracy is different from existing
    if (targetRisk !== this.task.accuracy) {
      [total, delta, riskThreshold] = this.select_risk_bar(index);

      this.$bar.selectedRisk = total;

      this.gantt.trigger_event("risk_change", [this.task, riskThreshold]);

      this.proposed_end_changed(riskThreshold);
    } else if (!this.risk_bars[index].disabled) {
      // eslint-disable-next-line
      [total, delta, riskThreshold] = this.deselect_risk_bar(index);
    }
  }

  set_risk_bar_to_base() {
    // Only retract bar if not in fully retracted state
    if (Number(this.task.accuracy) !== Number(this.task.baseAccuracy)) {
      this.deselect_risk_bar(-1);

      this.$bar.selectedRisk = 0;

      this.gantt.trigger_event("risk_change", [
        this.task,
        this.$bar.selectedRisk,
      ]);

      this.proposed_end_changed(this.task.baseAccuracy);
    }
  }

  // Will select the appropriate SVGs and class for the risk bar.
  // Will set correct selected risk.
  // Returns total risk, delta from last selection and the threshold selected.
  select_risk_bar(index) {
    let total = 0;
    let delta = 0;
    let targetRisk = 0;

    if (index >= 0 && index <= this.risk_bars.length) {
      const targetSVG = document.getElementById(this.risk_bars[index].getID());
      targetRisk = targetSVG ? targetSVG.getRiskThreshold() : 0;
      const targetThreshold = this.choose_threshold_class(targetRisk);

      for (let i = 0; i <= index; i++) {
        const riskBarSVG = document.getElementById(this.risk_bars[i].getID());
        if (riskBarSVG) {
          delta += this.risk_bars[i].getWidth();
          this.risk_bars[i].disabled = true;
          riskBarSVG.setAttribute("class", targetThreshold);
        }
        total += this.risk_bars[i].getWidth();
      }
    }

    return [total, delta, targetRisk];
  }

  // Will select the appropriate SVGs and class for the risk bar.
  // Will set correct selected risk.
  // Returns total risk, delta from last selection and the threshold selected.
  deselect_risk_bar(index) {
    let total = 0;
    let delta = 0;

    for (let i = this.risk_bars.length - 1; i >= 0; i--) {
      if (i > index) {
        const riskBarSVG = document.getElementById(this.risk_bars[i].getID());
        if (riskBarSVG && this.risk_bars[i].disabled) {
          delta -= this.risk_bars[i].getWidth();
          this.risk_bars[i].disabled = false;
          riskBarSVG.setAttribute(
            "class",
            `${this.choose_threshold_class(
              riskBarSVG.getRiskThreshold()
            )} bar-risk-transparent`
          );
        }
        total += this.risk_bars[i].getWidth();
      }
    }

    const [rt] = this.select_risk_bar(index);

    return [total, delta, rt];
  }

  // Removes the risk bar elements (bar and labels).
  // Used to disable risk bars when moving task.
  destroy_risk_bar_elements() {
    if (
      this.risk_bars.length > 0 &&
      Object.keys(this.risk_bars[0]).length > 2
    ) {
      for (let i = 0; i < this.risk_bars.length; i++) {
        const riskBarSVG = document.getElementById(this.risk_bars[i].getID());
        if (riskBarSVG) {
          riskBarSVG.remove();
        }

        const riskBarLabelSVG = document.getElementById(
          this.risk_bar_labels[i].getID()
        );
        if (riskBarLabelSVG) {
          riskBarLabelSVG.remove();
        }
      }
    }

    this.risk_bars_group.innerHTML = "";
    this.cummulated_width = this.width;
  }

  //popups for risk bars
  show_riskBar_popup(risk_bar) {
    this.gantt.show_popup({
      target_element: risk_bar,
      title: "Accuracy",
      subtitle: `${risk_bar.getRiskThreshold()}% : ${Math.floor(
        risk_bar.getValueLabel() / 24
      )} day(s) `,
      task: this.task,
    });
  }

  destroy_confidence_label() {
    const labelSVG = document.getElementById(
      `${this.task.id}_confidence_label`
    );
    if (labelSVG) labelSVG.remove();
  }

  destroy_dependency_warning_icon() {
    const labelSVG = document.getElementById(
      `${this.task.id}_dep_warning_label`
    );
    if (labelSVG) labelSVG.remove();
    this.task.isModelBrokenDep = false;
    this.task.isTaskBrokenDep = false;
  }

  destroy_bar_label() {
    const labelSVG = document.getElementById(`task_label_${this.task.id}`);
    if (labelSVG) labelSVG.remove();
  }

  //
  //
  //
  //
  //
  //UPDATE FUNCTIONS
  //
  //
  //
  //
  //

  update_bar_position({ x = null, width = null, isWbs = false }) {
    const bar = this.$bar;

    if (x) this.update_attr(bar, "x", x);

    if (width && width >= this.gantt.options.column_width) {
      this.update_attr(bar, "width", width);
    }

    if (isWbs) {
      this.draw_wbs_border();
      return;
    }

    this.destroy_bar_label();
    this.destroy_confidence_label();
    this.destroy_dependency_warning_icon();

    if (!this.task.milestone) {
      this.update_handle_position();
    }
    this.update_arrow_position();
  }

  // Calculates the proposed risk field (end date + selected risk)
  proposed_end_changed(accuracy) {
    let changed = false;
    const new_proposed_end_date = this.compute_proposed_end_date();

    if (Number(this.task.safetyEnd) !== Number(new_proposed_end_date)) {
      changed = true;
      this.task.oldSafetyEnd = this.task.safetyEnd;
      this.task.safetyEnd = new_proposed_end_date;
    }

    if (!changed) return;

    this.task = this.handle_bar_critical_path();

    const wbsTasks = this.gantt.recalculate_wbs_bar([this.task]);

    this.task.safetyEnd = this.task.safetyEnd.toISOString();

    const taskPayload = [this.task];

    // We fetch all successors so that we can assess the dependency status on reload
    //for (const task of this.task.successors) {
    //  const taskBar = this.gantt.get_bar(task);
    //  taskPayload.push(taskBar.task);
    //}

    this.gantt.trigger_event("safety_end_change", [
      taskPayload,
      wbsTasks[0],
      new_proposed_end_date,
      parseInt(accuracy),
    ]);
  }

  handle_bar_critical_path() {
    const taskSafetyEnd =
      typeof this.task.safetyEnd == "string"
        ? new Date(this.task.safetyEnd)
        : this.task.safetyEnd;

    const doesTaskCrossFinishDate =
      taskSafetyEnd.getTime() >= this.gantt.endDate.getTime();
    const isCriticalPathEnabled = this.$bar.getClass() === "bar critical";

    if (doesTaskCrossFinishDate && !isCriticalPathEnabled) {
      // Run as part of `make_bars` when all bars not yet present on `this.gantt`. Delay until complete.
      // TODO: refactor so critical path checking happens after all bars created.
      setTimeout(() => this.gantt.trigger_critical_path(this.task, true), 0);
      this.task.isCriticalPath = true;
    } else if (!doesTaskCrossFinishDate && isCriticalPathEnabled) {
      // Run as part of `make_bars` when all bars not yet present on `this.gantt`. Delay until complete.
      setTimeout(() => this.gantt.trigger_critical_path(this.task, false), 0);
      this.task.isCriticalPath = false;
    }

    return this.task;
  }

  date_changed() {
    let changed = false;
    const { new_start_date, new_end_date } = this.compute_start_end_date();

    let fixed_new_start_date = date_utils.add(new_start_date, 1, "minute");
    let fixed_new_end_date = date_utils.add(new_end_date, -1, "minute");

    if (!this.task.oldStart || !this.task.oldEnd) {
      this.task.oldStart = this.task._start.toISOString();
      this.task.oldEnd = this.task._end.toISOString();
    }

    if (Number(this.task._start) !== Number(fixed_new_start_date)) {
      changed = true;
      this.task.oldStart = this.task._start.toISOString();
      this.task._start = fixed_new_start_date;
      this.task.start = fixed_new_start_date;
    }

    if (Number(this.task._end) !== Number(fixed_new_end_date)) {
      changed = true;
      this.task.oldEnd = this.task._end.toISOString();
      this.task._end = fixed_new_end_date;
      this.task.end = fixed_new_end_date;
    }

    if (!changed) return;

    this.task.safetyEnd = this.task.end.toISOString();
  }

  set_action_completed() {
    this.action_completed = true;
    setTimeout(() => (this.action_completed = false), 1000);
  }

  compute_start_end_date() {
    const bar = this.$bar;

    const x_in_units = bar.getX() / this.gantt.options.column_width;
    const new_start_date = date_utils.add(
      this.gantt.gantt_start,
      x_in_units * this.gantt.options.step,
      "hour"
    );
    const width_in_units = bar.getWidth() / this.gantt.options.column_width;
    const new_end_date = date_utils.add(
      new_start_date,
      width_in_units * this.gantt.options.step,
      "hour"
    );

    return { new_start_date, new_end_date };
  }

  // Get end date based on risk selected
  compute_proposed_end_date() {
    const bar = this.$bar;

    // Need to include risk bars here for start & end date.
    const risk = bar.selectedRisk ? bar.selectedRisk : 0;

    const width_floor = Math.floor(this.gantt.options.column_width);
    const bar_floor = Math.floor(bar.getX());
    const bar_width_floor = Math.floor(bar.getWidth());

    const x_in_units = bar_floor / width_floor;
    const new_start_date = date_utils.add(
      this.gantt.gantt_start,
      x_in_units * this.gantt.options.step,
      "hour"
    );

    const width_in_units = (bar_width_floor + risk) / width_floor;
    let new_proposed_end_date = date_utils.add(
      new_start_date,
      width_in_units * this.gantt.options.step,
      "hour"
    );

    new_proposed_end_date = date_utils.add(new_proposed_end_date, -2, "hour");

    // Subtract day from safety end date, was always 1 day longer than expected.
    //new_proposed_end_date = date_utils.add(new_proposed_end_date, -1, "day");
    return new_proposed_end_date;
  }

  compute_x() {
    const { step, column_width } = this.gantt.options;
    const task_start = this.task._start;
    const gantt_start = this.gantt.gantt_start;

    const diff = date_utils.diff(task_start, gantt_start, "hour");
    let x = (diff / step) * column_width;

    if (this.gantt.view_is("Month")) {
      const diff = date_utils.diff(task_start, gantt_start, "day");
      x = (diff * column_width) / 30;
    }

    return x;
  }

  compute_y() {
    return (
      this.gantt.options.header_height +
      this.gantt.options.padding +
      this.task._index * (this.height + this.gantt.options.padding)
    );
  }

  get_snap_position(dx) {
    let odx = dx,
      rem,
      position;

    if (this.gantt.view_is("Week")) {
      rem = dx % (this.gantt.options.column_width / 7);
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 14
          ? 0
          : this.gantt.options.column_width / 7);
    } else if (this.gantt.view_is("Month")) {
      rem = dx % (this.gantt.options.column_width / 30);
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 60
          ? 0
          : this.gantt.options.column_width / 30);
    } else {
      rem = dx % this.gantt.options.column_width;
      position =
        odx -
        rem +
        (rem < this.gantt.options.column_width / 2
          ? 0
          : this.gantt.options.column_width);
    }
    return position;
  }

  update_attr(element, attr, value) {
    value = +value;
    if (!isNaN(value)) {
      element.setAttribute(attr, value);
    }
    return element;
  }

  update_label_position(fromRisk = false) {
    const bar = this.$bar,
      textLabel = this.bar_group.querySelector(".bar-label"),
      confidenceLabel = this.bar_group.querySelector(".confidence-label");

    if (!fromRisk && textLabel) {
      const isBarLabelTooLong = textLabel.getBBox().width > bar.getWidth() - 70;

      if (isBarLabelTooLong) {
        const excess = (textLabel.getBBox().width - bar.getWidth() - 70) * -1;
        const textWidth = this.get_text_width(textLabel.getBBox().text);

        const stripChar = Math.ceil(excess / textWidth) + 3;

        textLabel.innerHTML =
          bar.getWidth() > 130
            ? textLabel.innerHTML.slice(0, stripChar) + "..."
            : "";
      }

      textLabel.classList.remove("big");
      textLabel.setAttribute("x", bar.getX() + bar.getWidth() / 2);

      const isBarTooShort = bar.getWidth() > 30;

      if (confidenceLabel && isBarTooShort) {
        confidenceLabel.classList.remove("transparent");
        confidenceLabel.setAttribute(
          "x",
          bar.getX() + bar.getWidth() - confidenceLabel.getBBox().width
        );
      } else if (confidenceLabel && !isBarTooShort) {
        confidenceLabel.classList.add("transparent");
      }

      if (confidenceLabel)
        confidenceLabel.innerHTML = `${this.task.baseAccuracy}%`;
    }
  }

  //have to check why position is not exactly the same one we have saved when refresh.
  update_handle_position() {
    const bar = this.$bar;
    this.bar_group
      .querySelector(".handle.left")
      .setAttribute("x", bar.getX() + 1);
    this.bar_group
      .querySelector(".handle.right")
      .setAttribute("x", bar.getEndX() - 9);
  }

  update_arrow_position() {
    this.arrows = this.arrows || [];
    for (let arrow of this.arrows) {
      arrow.update();
    }
  }

  get_text_width(text) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context.font = getComputedStyle(document.body).font;

    return context.measureText(text).width;
  }
}
