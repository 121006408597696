//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortArrayOfISOStringASC } from "helpers";
//Types
import { IWorkDay } from "types";
import { RootState } from "store";

//State
export interface ICalendarState {
  workDays: IWorkDay[];
  holidays: string[];
  calendarInitialised: boolean;

  //Work Hours Modal
  workHoursModalOpen: boolean;
  workHoursError: string;
  initialStartTime: number; //TS
  initialEndTime: number; //TS
  selectedDay: string;
}

export const initialState: ICalendarState = {
  workDays: [],
  holidays: [],
  calendarInitialised: false,
  workHoursModalOpen: false,
  workHoursError: "",
  initialStartTime: new Date().getTime(),
  initialEndTime: new Date().getTime(),
  selectedDay: "Monday",
};

//Slice
export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setAllWorkDays: (state, action: PayloadAction<IWorkDay[]>) => {
      state.workDays = action.payload;
    },

    updateWorkDay: (state, action: PayloadAction<IWorkDay>) => {
      const updatedWorkdays = state.workDays.map((workDay) =>
        workDay.day === action.payload.day ? action.payload : workDay
      );

      state.workDays = updatedWorkdays;
    },

    setAllHolidays: (state, action: PayloadAction<string[]>) => {
      state.holidays = action.payload;
    },

    addHoliday: (state, action: PayloadAction<string>) => {
      const newHolidays = Array.from(
        new Set(sortArrayOfISOStringASC([...state.holidays, action.payload]))
      ); //Set to remove any dupes
      state.holidays = newHolidays;
    },

    deleteHoliday: (state, action: PayloadAction<string>) => {
      const index = state.holidays.findIndex(
        (holiday) => holiday === action.payload
      );
      if (index !== -1) state.holidays.splice(index, 1);
    },

    setCalendarInitialised: (state, action: PayloadAction<boolean>) => {
      state.calendarInitialised = action.payload;
    },

    setWorkHoursModal: (state, action: PayloadAction<boolean>) => {
      state.workHoursModalOpen = action.payload;
    },

    setWorkHoursError: (state, action: PayloadAction<string>) => {
      state.workHoursError = action.payload;
    },

    setWorkHoursFormInit: (
      state,
      action: PayloadAction<{
        startTime: number;
        endTime: number;
        day: string;
      }>
    ) => {
      state.initialStartTime = action.payload.startTime;
      state.initialEndTime = action.payload.endTime;
      state.selectedDay = action.payload.day;
    },

    clearWorkHoursForm: (state) => {
      state.initialStartTime = new Date().getTime();
      state.initialEndTime = new Date().getTime();
      state.selectedDay = "Monday";
    },
  },
});

//Actions
export const {
  addHoliday,
  clearWorkHoursForm,
  deleteHoliday,
  setAllHolidays,
  setAllWorkDays,
  setCalendarInitialised,
  setWorkHoursError,
  setWorkHoursFormInit,
  setWorkHoursModal,
  updateWorkDay,
} = calendarSlice.actions;

//Selectors
export const selectCalendarInitialised = (state: RootState) =>
  state.calendar.calendarInitialised;

export const selectWorkDays = (state: RootState) => state.calendar.workDays;

export const selectHolidays = (state: RootState) => state.calendar.holidays;

export const selectSelectedDay = (state: RootState) =>
  state.calendar.selectedDay;

export const selectInitialStartTime = (state: RootState) =>
  state.calendar.initialStartTime;

export const selectInitialEndTime = (state: RootState) =>
  state.calendar.initialEndTime;

export const selectWorkHoursError = (state: RootState) =>
  state.calendar.workHoursError;

export const selectWorkHoursModalOpen = (state: RootState) =>
  state.calendar.workHoursModalOpen;

//Reducer
export default calendarSlice.reducer;
