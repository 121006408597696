import React, { FC, memo } from "react";
import { StyledCapitalisedTooltip } from "elements/Tooltip";
import ShowChildrenIcon from "./ShowChildrenIcon";

interface IProps {
  title: string;
  wbsId?: string;
  isMilestone?: boolean;
  isLevelOfEffort?: boolean;
}

const NameCell: FC<IProps> = ({
  title,
  wbsId,
  isMilestone,
  isLevelOfEffort,
}) => (
  <StyledCapitalisedTooltip
    title={title}
    arrow
    placement="right"
    enterDelay={500}
  >
    <div
      className={`text-xl flex items-center flex-grow min-w-0 ${
        wbsId ? "" : "pl-3"
      }`}
      data-testid="name-cell"
    >
      {wbsId ? <ShowChildrenIcon wbsId={wbsId} /> : null}
      <span className="min-w-0 flex-grow truncate">
        {isMilestone ? <span className="milestone">Milestone | </span> : null}
        {isLevelOfEffort ? (
          <span className="milestone">LOE | </span>
        ) : null}{" "}
        {title}
      </span>
      {/* {title.length < 17 ? title : title.substring(0, 17) + "..."} */}
    </div>
  </StyledCapitalisedTooltip>
);

export default memo(NameCell);
