import { FeatureFlags } from "@ehabitation/ts-utils/browser";
import { useAppSelector } from "store";
import { selectUserState } from "store/auth";

export const useFeatureFlag = (featureName: FeatureFlags) => {
  const userState = useAppSelector(selectUserState);
  const hasLocalStorageFlag =
    localStorage.getItem(`feature:${featureName}`) !== null ? true : false;
  const hasUserFlag = userState.featureFlags?.includes(featureName)
    ? true
    : false;
  return hasLocalStorageFlag || hasUserFlag;
};
