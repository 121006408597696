import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import { initializeFirestore } from "firebase/firestore";

import config from "config";

const firebase = initializeApp({
  apiKey: config.API_KEY,
  authDomain: config.AUTH_DOMAIN,
  databaseURL: config.DATABASE_URL,
  projectId: config.PROJECT_ID,
  storageBucket: config.STORAGE_BUCKET,
  messagingSenderId: config.MESSAGING_SENDER_ID,
  appId: config.APP_ID,
  measurementId: config.MEASUREMENT_ID,
});

if (config.APPCHECK_E2E_OVERIDE) {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = config.APPCHECK_E2E_OVERIDE;
} else if (window.location.hostname === "localhost") {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

initializeAppCheck(firebase, {
  provider: new ReCaptchaEnterpriseProvider(config.RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const db = initializeFirestore(firebase, {
  experimentalAutoDetectLongPolling: true,
  ignoreUndefinedProperties: true,
});

export const analytics = getAnalytics(firebase);
export const storage = getStorage(firebase);
export const functions = getFunctions(firebase, config.BACKEND_REGION);
export const auth = getAuth(firebase);

export default firebase;
