import { ISite } from "@ehabitation/ts-utils/browser";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { useEffect, useState } from "react";

type SourceType = 'openmeteo' | 'ECMWF' | 'IBM'

export const useHasVisibility = (site: ISite) => {
    const [isLoading, setIsLoading] = useState(false)
    const [hasVisibility, setHasVisibility] = useState(false)

    const getSourcesTypes = async (historicalInUse: string[]) => {
        setIsLoading(true)
        const sourceTypes: SourceType[] = []
        for(const sourceId of historicalInUse) {
            const ref = doc(db, 'sources', sourceId)
            const snap = await getDoc(ref)
            if(snap.exists()) {
                sourceTypes.push(snap.data().type as SourceType)
            }
        }
        if(sourceTypes.findIndex(i => i == 'IBM') > -1) {
            setHasVisibility(true)
        } else {
            setHasVisibility(false)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if(!site.historicalInUse || site.historicalInUse.length == 0) {
            return
        }
        getSourcesTypes(site.historicalInUse)
    }, [site])

    return {isLoading, hasVisibility}
}