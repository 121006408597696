import { StyledSimulationBreakdown } from "./styles";
import ConfidenceProfiles from "./ConfidenceProfiles";
import DateBreakdown from "./DateBreakdown";

const SimulationBreakdown = () => {
  return (
    <StyledSimulationBreakdown>
      <DateBreakdown />
      <ConfidenceProfiles />
    </StyledSimulationBreakdown>
  );
};

export default SimulationBreakdown;
