import React, { FC } from "react";
import { StyledTabbedContainer } from "elements";
import { menu } from "./constants";
import { useProjectManagementTab } from "./useProjectManagementTab";
import ProjectManagementToast from "./ProjectManagementToast";
import Projects from "./Projects/Projects";
import ProjectForm from "./ProjectForm/ProjectForm";
import { ProjectManagementTabOption } from "types";

const ProjectManagement: FC = () => {
  const { selectedTab, handleChangeTab } = useProjectManagementTab();

  return (
    <StyledTabbedContainer data-testid="project-management-container">
      <div className="tabs">
        {menu.map((item) => (
          <button
            key={item}
            name={item}
            onClick={handleChangeTab}
            className={selectedTab === item ? "selected" : ""}
            disabled={
              item === "Details" && selectedTab !== "Details" ? true : false
            }
            data-testid={`menu-${item.toLowerCase()}`}
          >
            <h3>{item}</h3>
          </button>
        ))}
      </div>

      <div className="container">
        {selectedTab === ProjectManagementTabOption.Projects && <Projects />}
        {selectedTab === ProjectManagementTabOption.Details && <ProjectForm />}
      </div>

      <ProjectManagementToast />
    </StyledTabbedContainer>
  );
};

export default ProjectManagement;
