import DivisionManagement from "Components/DivisionManagement/DivisionManagement";
import ProjectManagement from "Components/ProjectManagement/ProjectManagement";
import SiteManagement from "Components/SiteManagement/SiteManagement";
import UserManagement from "Components/UserManagement/UserManagement";
import { AdminMenuOption } from "./types";

import tstormSvgPath from "assets/icons/weather/tstorm.svg";
import drizzleSvgPath from "assets/icons/weather/drizzle.svg";
import rainSvgPath from "assets/icons/weather/rain.svg";
import snowSvgPath from "assets/icons/weather/snow.svg";
import icePelletsSvgPath from "assets/icons/weather/ice_pellets.svg";
import fogSvgPath from "assets/icons/weather/fog.svg";
import windSvgPath from "assets/icons/weather/wind.svg";
import cloudySvgPath from "assets/icons/weather/cloudy.svg";
import clearSvgPath from "assets/icons/weather/clear.svg";
import rainLightSvgPath from "assets/icons/weather/rain_light.svg";
import rainHeavySvgPath from "assets/icons/weather/rain_heavy.svg";

export const adminMenu: AdminMenuOption[] = [
  AdminMenuOption.UserManagement,
  AdminMenuOption.DivisionManagement,
  AdminMenuOption.ProjectManagement,
  AdminMenuOption.SiteManagement,
];

export const adminMenuOptionMap = {
  [AdminMenuOption.UserManagement]: UserManagement,
  [AdminMenuOption.DivisionManagement]: DivisionManagement,
  [AdminMenuOption.ProjectManagement]: ProjectManagement,
  [AdminMenuOption.SiteManagement]: SiteManagement,
};

export const adminSearchOptionMap = {
  [AdminMenuOption.UserManagement]: UserManagement,
  [AdminMenuOption.DivisionManagement]: DivisionManagement,
  [AdminMenuOption.ProjectManagement]: ProjectManagement,
  [AdminMenuOption.SiteManagement]: SiteManagement,
};

export const weatherCodeIconMap: any = {
  Thunderstorms: tstormSvgPath,
  Drizzle: drizzleSvgPath,
  Showers: rainSvgPath,
  Rain: rainSvgPath,
  Snow: snowSvgPath,
  Hail: icePelletsSvgPath,
  Foggy: fogSvgPath,
  Windy: windSvgPath,
  Cloudy: cloudySvgPath,
  "Mostly Cloudy": cloudySvgPath,
  "Partly Cloudy": cloudySvgPath,
  Sunny: clearSvgPath,
  "Mostly Sunny": clearSvgPath,
  "Scattered Showers": rainLightSvgPath,
  "Heavy Rain": rainHeavySvgPath,
  "Not Available": "",
};
