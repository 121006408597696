import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOrganisationProjects,
  IOrganisationSites,
  SiteManagementTabOption,
} from "types";
import { RootState } from "store";

export interface ISiteManagementState {
  organisationProjects: IOrganisationProjects;
  organisationSites: IOrganisationSites;
  selectedTab: SiteManagementTabOption;
  siteManagementError: string;
  isLoading: boolean;
  selectedSite: string | null;
}

export const initialState: ISiteManagementState = {
  organisationProjects: {},
  organisationSites: {},
  selectedTab: "Sites",
  siteManagementError: "",
  isLoading: false,
  selectedSite: null,
};

export const userManagementSlice = createSlice({
  name: "siteManagement",
  initialState,
  reducers: {
    setOrganisationProjects: (
      state,
      action: PayloadAction<IOrganisationProjects>
    ) => {
      state.organisationProjects = action.payload;
    },

    setOrganisationSites: (
      state,
      action: PayloadAction<IOrganisationSites>
    ) => {
      state.organisationSites = action.payload;
    },

    setSelectedSiteTab: (
      state,
      action: PayloadAction<SiteManagementTabOption>
    ) => {
      state.selectedTab = action.payload;
    },

    setSiteManagementIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setSiteManagementError: (state, action: PayloadAction<string>) => {
      state.siteManagementError = action.payload;
    },

    setSelectedSite: (state, action: PayloadAction<string | null>) => {
      state.selectedSite = action.payload;
    },
  },
});

//Actions
export const {
  setOrganisationProjects,
  setOrganisationSites,
  setSelectedSiteTab,
  setSiteManagementError,
  setSiteManagementIsLoading,
  setSelectedSite,
} = userManagementSlice.actions;

//Selectors
export const selectOrganisationSites = (state: RootState) =>
  state.siteManagement.organisationSites;

export const selectOrganisationProjects = (state: RootState) =>
  state.siteManagement.organisationProjects;

export const selectSelectedSiteTab = (state: RootState) =>
  state.siteManagement.selectedTab;

export const selectSiteManagementError = (state: RootState) =>
  state.siteManagement.siteManagementError;

export const selectSiteManagementLoading = (state: RootState) =>
  state.siteManagement.isLoading;

export const selectSelectedSite = (state: RootState) =>
  state.siteManagement.selectedSite;

export default userManagementSlice.reducer;
