import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { ISite, isGuest as roleIsGuest } from "@ehabitation/ts-utils/browser";
import { StyledPlanControls } from "./styles";
import { PlanSelector } from "./PlanSelector";
import Tabs from "./Tabs/Tabs";
import { useSubscribeToAvailablePlans } from "./PlanSelector/useSubscribeToAvailablePlans";
import { setAsMainPlan } from "./thunks";
import { selectCurrentPlanId } from "store/project";
import { selectLatestCurrentPlanSimulation } from "store/tasks";
import { selectRole } from "store/auth";
import DiscardAlert from "./DiscardAlert";
import { discardPlan } from "helpers/firebase/plans";

const ImportButton: FC<{ onClick?: () => void; disabled: boolean }> = ({ onClick, disabled }) => (
  <button
    className="mr-4 font-bold ml-auto cursor-pointer bg-[#1ceaa0] rounded-[5rem] border border-solid border-[#444444] inline-block text-[#444444] py-[0.4rem] px-8 no-underline hover:brightness-95 hover:disabled:brightness-100 hover:bg-[#44eeb0] active:relative active:top-[1px] disabled:opacity-40 disabled:cursor-default"
    onClick={onClick}
    disabled={disabled}
  >
    Import
  </button>
);

const PlanControls: FC<{
  handleSelectPlan: (planId: string) => void;
}> = ({
  handleSelectPlan,
}) => {
  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  const { currentSite, availablePlans: plans } = useSubscribeToAvailablePlans();
  const dispatch = useAppDispatch();
  const latestSimulation = useAppSelector(selectLatestCurrentPlanSimulation);
  const currentPlanId = useAppSelector(selectCurrentPlanId);

  const [discardPlanId, setDiscardPlanId] = useState("");

  const simulationInProgress = [
    "pending",
    "site_pending",
    "partial",
    "prepared",
  ].includes(latestSimulation?.status || "");
  

  return (
    <StyledPlanControls className="px-6">
      
      <div className="above-gantt-chart">
        <Tabs isSimulating={simulationInProgress} />

        {currentSite && (
          <>
            <PlanSelector
              plans={plans ? Object.values(plans) : []}
              currentSite={currentSite}
              selectedPlan={currentSite.plan}
              handleSelectPlan={handleSelectPlan}
              setMainPlan={(site: ISite, planId: string) =>
                dispatch(setAsMainPlan(site, planId))
              }
              setDiscardPlanModal={(planId: string) => {
                setDiscardPlanId(planId);
              }}
            />
          </>
        )}
      </div>

      {discardPlanId && (
        <DiscardAlert
          message="Are you sure you want to delete the plan?"
          handleCancel={() => setDiscardPlanId("")}
          handleDiscard={() => {
            discardPlan(discardPlanId);
            setDiscardPlanId("");
          }}
        ></DiscardAlert>
      )}
    </StyledPlanControls>
  );
};

export default PlanControls;
