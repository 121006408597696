import React, { FC } from "react";
import { useAppSelector } from "store";
import { selectTransientTaskData } from "store/tasks";

interface IProps {
  wbsId: string | null;
}

const ShowChildrenIcon: FC<IProps> = ({ wbsId }) => {
  const transientTaskData = useAppSelector(selectTransientTaskData);
  return (
    <div className="cell-show-children pr-2">
      <span>
        <strong>
          {transientTaskData &&
            wbsId &&
            transientTaskData[wbsId] &&
            transientTaskData[wbsId].showChildren &&
            "-"}
          {((transientTaskData &&
            wbsId &&
            transientTaskData[wbsId] &&
            !transientTaskData[wbsId].showChildren) ||
            !transientTaskData ||
            (transientTaskData && wbsId && !transientTaskData[wbsId])) &&
            "+"}
        </strong>
      </span>
    </div>
  );
};

export default ShowChildrenIcon;
