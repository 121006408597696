import { ISite } from "@ehabitation/ts-utils/browser";
import { createSelector } from "reselect";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement/siteManagementSlice";
import {
  selectDivisionMap,
  selectOrganisationUsers,
} from "store/userManagement/userManagementSlice";
import { IProjectWithSites } from "types";
import { selectSelectedProject } from "./projectManagementSlice";

type ProjectsWithSites = Record<string, IProjectWithSites>;

export const selectOrderedProjects = createSelector(
  [
    selectOrganisationProjects,
    selectOrganisationSites,
    selectDivisionMap,
    selectOrganisationUsers,
  ],
  (projects, sites, divisionMap, users) => {
    /* Add An Array Of Sites To Project Data */
    const projectsWithSites = Object.entries(
      projects
    ).reduce<ProjectsWithSites>((acc, [id, project]) => {
      return { ...acc, [id]: { ...project, sites: [] } };
    }, {});

    Object.keys(sites).forEach((siteId) => {
      const projectId = sites[siteId].project;
      const siteName = sites[siteId].name;
      const siteStatus = sites[siteId].status;

      if (!projectId || !siteName || !siteStatus) return;

      //Add any sites to the array and sort
      projectsWithSites[projectId].sites = [
        ...projectsWithSites[projectId].sites!,
        { id: siteId, name: siteName, status: siteStatus } as unknown as ISite,
      ].sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
    });

    /* Organise Projects By Division */
    const projectsByDivision: {
      [key: string]: IProjectWithSites[];
    } = {};

    Object.keys(projectsWithSites).forEach((projectId) => {
      const divisionId = projectsWithSites[projectId].divisionId!;
      const divisionName = divisionMap[divisionId].name;

      //Add number of users on each project for display in UI
      const noOfUsers = Object.keys(users).filter(
        (userId) => users[userId].project === projectId
      ).length;

      //If no projects exists, create an array with first one
      if (!projectsByDivision.hasOwnProperty(divisionName)) {
        projectsByDivision[divisionName] = [
          { ...projectsWithSites[projectId], users: noOfUsers },
        ];
        return;
      }

      //If projects already exist, add on to the arrray and sort alphabetically
      if (projectsByDivision.hasOwnProperty(divisionName)) {
        projectsByDivision[divisionName] = [
          ...projectsByDivision[divisionName],
          { ...projectsWithSites[projectId], users: noOfUsers },
        ].sort((a, b) => {
          if (a.name! > b.name!) return 1;
          if (b.name! > a.name!) return -1;
          return 0;
        });
        return;
      }
    });

    return projectsByDivision;
  }
);

export const selectNoOfUsersByProject = createSelector(
  [selectOrganisationUsers],
  (users) => {
    if (!users) return {};

    return Object.values(users).reduce((total, current) => {
      const project = current.project;
      if (!project) return total;

      return {
        ...total,
        [project]: !total[project] ? 1 : total[project] + 1,
      };
    }, {} as Record<string, number>);
  }
);

export const selectSelectedProjectDetails = createSelector(
  [selectSelectedProject, selectOrganisationProjects],
  (selectedProject, projects) => {
    if (!selectedProject) return null;
    return {
      ...projects[selectedProject],
      divisionId: projects[selectedProject].divisionId,
      id: selectedProject,
    };
  }
);
