import React from "react";

interface OptionSelectorProps {
  name: string;
  id: string;
  value: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const OptionSelector: React.FC<OptionSelectorProps> = ({
  name,
  id,
  value,
  label,
  onChange,
  checked,
}) => {
  return (
    <div className="ml-2 mb-6">
      <label htmlFor={id} className="flex items-center">
        <input
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="ml-2">{label}</span>
      </label>
    </div>
  );
};