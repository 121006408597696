import React from "react";

interface IntroTemplateProps {
  title: string;
  description: string;
  items: string[];
  benefits: string[];
}

export const IntroTemplate: React.FC<IntroTemplateProps> = ({
  title,
  description,
  items,
  benefits,
}) => {
  return (
    <div className="w-full min-w-0 h-full mt-20 px-3 flex flex-col items-center">
      <div className="w-[60%]">
        <h2 className="text-4xl font-semibold tracking-tight">{title}</h2>
        <div className="mt-10">
          <p className="text-m text-muted-foreground">{description}</p>
        </div>
        <div className="mt-16">
          <h3 className="text-2xl font-semibold pb-4">What do you get?</h3>
          <div className="text-m text-muted-foreground">
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
        <div className="mt-16">
          <h3 className="text-2xl font-semibold pb-4">Benefits</h3>
          <div className="text-m text-muted-foreground">
            {benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
