import React, { FC } from "react";
import { StyledTabbedContainer } from "elements";
import { menu } from "./constants";
import { useSiteManagementTab } from "./useSiteManagementTab";
import Sites from "./Sites/Sites";
import NewSite from "./NewSite/NewSite";
import SiteManagementToast from "./SiteManagementToast";
import { selectSiteManagementLoading } from "store/siteManagement";
import { useAppSelector } from "store";

const SiteManagement: FC = () => {
  const { selectedTab, handleChangeTab } = useSiteManagementTab();
  const isLoading = useAppSelector(selectSiteManagementLoading);

  return (
    <StyledTabbedContainer>
      <div className="tabs">
        {menu.map((item) => (
          <button
            key={item}
            name={item}
            onClick={handleChangeTab}
            className={selectedTab === item ? "selected" : ""}
            disabled={isLoading || selectedTab !== "Details"}
            data-testid={`menu-${item.toLowerCase()}`}
          >
            <h3> {item}</h3>
          </button>
        ))}
      </div>

      <div className="container">
        {selectedTab === "Sites" ? <Sites /> : null}
        {selectedTab === "Details" ? <NewSite /> : null}
      </div>

      <SiteManagementToast />
    </StyledTabbedContainer>
  );
};

export default SiteManagement;
