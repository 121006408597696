import { batch } from "react-redux";
import { Moment } from "moment";
import { ITask } from "@ehabitation/ts-utils/browser";
import { AppThunk } from "store";
import { updateTask, batchUpdateTask } from "store/tasks";
import { setForecast, setForecastError } from "store/forecast";
import { setTaskModified } from "store/ui";
import { selectActiveSiteId } from "store/auth";
import { fetchSiteForecast } from "helpers";

export const fetchForecast: AppThunk = () => async (dispatch, getState) => {
  const state = getState();

  const siteId = selectActiveSiteId(state);
  const existingForecastSiteID = state.forecast.siteId;

  if (!siteId || existingForecastSiteID === siteId) return;

  try {
    const forecast = await fetchSiteForecast(siteId);
    if (forecast) {
      dispatch(setForecast({ forecast, siteId }));
    }
  } catch (error) {
    if (error instanceof Error && error.message === "NO_SITE_FORECAST_IN_USE") {
      dispatch(setForecastError("No forecast found for this site"));
    } else {
      console.error(error);
      dispatch(setForecastError("Error fetching site forecast"));
    }
  }
};

//Updates state when using the gantt chart ui to change the date
export const handleGanttUpdateRisks: AppThunk = (task: ITask) => (dispatch) => {
  if (!task?.id) return;

  dispatch(
    updateTask({
      id: task.id,
      update: {
        risks: task.risks,
        isCriticalPath: task.isCriticalPath,
      },
    })
  );
};

//Updates state when using the gantt chart ui to change the date
export const handleGanttSafetyEndChange: AppThunk =
  (task: ITask, safeEnd: Moment, accuracy: number, wbs: ITask) =>
  (dispatch, getState) => {
    if (!task?.id) return;

    const modifiedTask = {
      ...task,
      safetyEnd: safeEnd.toISOString(),
      oldSafetyEnd: safeEnd.toISOString(),
      hasSafetyChanged: "false",
      accuracy: accuracy,
      isCriticalPath: task.isCriticalPath,
    };

    batch(() => {
      dispatch(batchUpdateTask({ [task.id!]: modifiedTask }));
      dispatch(setTaskModified(true));
      if (wbs) dispatch(handleUpdateWbsTasks([wbs]));
    });
  };

export const handleUpdateWbsTasks: AppThunk =
  (wbsArray: ITask[]) => (dispatch) => {
    const taskObj = Object.assign(
      {},
      ...wbsArray.map((task) => ({ [task.id!]: { ...task } }))
    );
    dispatch(batchUpdateTask(taskObj));
  };
