import { Input } from "@ehabitation/ui";
import { FC } from "react";

interface SearchParams {
  searchedOption: string;
  search: (searchOption: string) => void;
  classes?: string;
  placeholder?: string;
}

export const Search: FC<SearchParams> = ({
  searchedOption,
  search,
  classes,
  placeholder = "Search",
}) => {
  return (
    <Input
      placeholder={placeholder}
      value={searchedOption}
      onChange={(e) => search(e.target.value)}
      id="admin-search"
      data-testid="admin-search"
      className={`${classes} absolute right-0`}
    />
  );
};
