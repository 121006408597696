import React from "react";
import { useAppSelector } from "store";
import { selectImpactOverviewMetrics } from "store/selectors";
import { StyledOverview } from "./styles";
import {
  BiTachometer,
  BiCalendarStar,
  BiCalendarCheck,
  BiCalendarEvent,
} from "react-icons/bi";
import { FaSun } from "react-icons/fa";
import { GiCheckboxTree } from "react-icons/gi";
import moment from "moment";

const Overview = () => {
  const impactMetrics = useAppSelector(selectImpactOverviewMetrics);

  if (impactMetrics === null) return null;

  const {
    confidenceLevel,
    dependencies,
    startDate,
    originalEnd,
    proposedEnd,
    extraDays,
  } = impactMetrics;

  return (
    <StyledOverview>
      <p className="label">OVERVIEW</p>

      <div>
        <div className="icon-container">
          <BiTachometer className="icon" />
          <p data-testid="overview-confidence">{confidenceLevel}%</p>
        </div>

        <p>Confidence Level</p>
      </div>

      <div>
        <div className="icon-container">
          <FaSun className="icon" />
          <p data-testid="overview-extra-days">{extraDays}</p>
        </div>

        <p>Extra Days</p>
      </div>

      <div>
        <div className="icon-container">
          <BiCalendarEvent className="icon" />
          <p>{startDate}</p>
        </div>

        <p>Start</p>
      </div>

      <div>
        <div className="icon-container">
          <BiCalendarCheck className="icon" />
          <p>{originalEnd}</p>
        </div>

        <p>Original End</p>
      </div>

      <div>
        <div className="icon-container">
          <BiCalendarStar className="icon" />
          <p data-testid="proposed-end">
            {moment(proposedEnd).format("DD MMM YYYY")}
          </p>
        </div>

        <p>Proposed End</p>
      </div>

      <div>
        <div className="icon-container">
          <GiCheckboxTree className="icon" />
          <p>{dependencies}</p>
        </div>

        <p>Dependencies</p>
      </div>
    </StyledOverview>
  );
};

export default Overview;
