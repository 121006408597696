import { FC } from "react";

interface ButtonIslandProps {
  id: string;
  message?: string;
  positionX?: "left" | "right";
  positionY?: "top" | "bottom";
  buttons: JSX.Element[];
  className?: string;
  isAbsolute?: boolean;
  variant?: "primary" | "secondary";
}

const XClasses = {
  left: "left-24",
  right: "right-24",
};

const YClasses = {
  top: "top-2",
  bottom: "bottom-2",
};

export const ButtonIsland: FC<ButtonIslandProps> = ({
  id,
  message,
  positionX = "right",
  positionY = "top",
  className = "",
  isAbsolute = true,
  variant = "primary",
  buttons,
}) => {
  const positionClasses = `absolute ${YClasses[positionY]} ${XClasses[positionX]}`;
  
  return (
    <div
      id={`${id}-floating-island`}
      className={`${
        isAbsolute ? positionClasses : ""
      } p-3 rounded-full inline-flex ${className}`}
    >
      {message && <p className="px-4 my-auto italic">{message}</p>}
      {buttons}
    </div>
  );
};
