//React
import React from "react";
//Store
import { useAppSelector } from "store";
import {
  selectBaseConfidence,
  selectCurrentTaskEndDate,
  selectSelectedConfidence,
} from "store/tasks";
//Styles
import { StyledConfidenceProfiles } from "./styles";
//Hooks
import { useConfidenceProfiles } from "./useConfidenceProfiles";
//Components
import ConfidenceRow from "./ConfidenceRow";
//Helpers
import moment, { Moment } from "moment";

interface IConfidenceProfile {
  selectConfidenceRow?: (confidence: number, endDate: Moment) => void;
}

const ConfidenceProfiles = ({ selectConfidenceRow }: IConfidenceProfile) => {
  const baseConfidence = useAppSelector(selectBaseConfidence);
  const selectedConfidence = useAppSelector(selectSelectedConfidence);
  const taskEnd = useAppSelector(selectCurrentTaskEndDate);

  const confidenceProfiles = useConfidenceProfiles();

  return (
    <StyledConfidenceProfiles>
      <ConfidenceRow
        isSelected={baseConfidence === selectedConfidence}
        confidence={baseConfidence ?? undefined}
        endDate={moment(taskEnd)}
        selectConfidenceRow={selectConfidenceRow}
        isOriginal
      />

      {Object.entries(confidenceProfiles).map(
        ([risk, { endDate, noOfDaysAdded }]) => (
          <ConfidenceRow
            key={risk}
            confidence={Number(risk)}
            daysAdded={noOfDaysAdded}
            endDate={endDate}
            selectConfidenceRow={selectConfidenceRow}
            isSelected={risk === selectedConfidence?.toString()}
          />
        )
      )}
    </StyledConfidenceProfiles>
  );
};

export default ConfidenceProfiles;
