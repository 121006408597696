import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { IUserState } from "types";
import { UserRole, ICustomClaims } from "@ehabitation/ts-utils/browser";

export interface IAuthState {
  userState: IUserState;
  claims: ICustomClaims | null;
  user: string;
  role: UserRole | null;
}

export const initialState: IAuthState = {
  userState: {
    projectId: "",
    siteId: "",
    email: "",
  },
  claims: null,
  user: "",
  role: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserState: (state, action: PayloadAction<IUserState>) => {
      const updatedUserState = { ...state.userState, ...action.payload };
      state.userState = updatedUserState;
    },

    setUserClaims: (state, action: PayloadAction<ICustomClaims>) => {
      state.claims = action.payload;
    },

    setUserUID: (state, action: PayloadAction<string>) => {
      state.user = action.payload;
    },

    setRole: (state, action: PayloadAction<UserRole>) => {
      state.role = action.payload;
    },
  },
});

//Actions
export const { setRole, setUserUID, updateUserState, setUserClaims } =
  authSlice.actions;

//Selectors
export const selectUserState = (state: RootState) => state.auth.userState;

export const selectActiveSiteId = (state: RootState) =>
  state.auth.userState.siteId;

export const selectUserClaims = (state: RootState) => state.auth.claims;

export const selectUserId = (state: RootState) => state.auth.user;

export const selectIsLoggedIn = (state: RootState) => !!state.auth.user;

export const selectRole = (state: RootState) => state.auth.role;

export const selectUserOrgId = (state: RootState) =>
  state.auth.claims?.organisation;

export const selectUserDivisionId = (state: RootState) =>
  state.auth.claims?.division;

export const selectUserProjectId = (state: RootState) =>
  state.auth.claims?.project;

export const selectWorkspaceIsReady = createSelector(
  [selectActiveSiteId, selectRole],
  (siteId, role) => (siteId && role ? true : false)
);

//Reducer
export default authSlice.reducer;
