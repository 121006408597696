import React, { useEffect } from "react";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { selectRole } from "store/auth";
import { adminMenu } from "./constants";
import { useSetupSubscriptions } from "hooks";
import { adminMenuOptionMap } from "./constants";
import { TabPageWrapper } from "@ehabitation/ui";
import { UserRole } from "@ehabitation/ts-utils/browser";
import { AdminMenuOption } from "./types";
import { filterText } from "store/search";
import { logOpenAdmin } from "helpers/analytics";

const shouldRenderButton = (role: UserRole, menuOption: AdminMenuOption) => {
  switch (role) {
    case UserRole.superAdmin:
    case UserRole.orgAdmin:
      return true;
    case UserRole.divisionAdmin:
      return !(menuOption === AdminMenuOption.DivisionManagement);
    case UserRole.projectAdmin:
      return !(
        menuOption === AdminMenuOption.DivisionManagement ||
        menuOption === AdminMenuOption.ProjectManagement
      );
    default:
      return false;
  }
};

const Admin = () => {
  const subscriptionsReady = useSetupSubscriptions();
  const role = useAppSelector(selectRole);
  const { searchInput } = useAppSelector((state: RootState) => state.search);
  const dispatch = useAppDispatch();
  const search = (searchOption: string) => {
    dispatch(filterText(searchOption.toLowerCase()));
  };

  useEffect(() => {
    logOpenAdmin();
  }, []);

  return (
    <TabPageWrapper
      map={adminMenuOptionMap}
      menu={adminMenu.filter((item) => shouldRenderButton(role!, item))}
      id="admin"
      loading={!subscriptionsReady}
      search={search}
      showSearch={true}
      searchedOption={searchInput}
    />
  );
};

export default Admin;
