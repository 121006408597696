import React, { FC } from "react";
import { StyledDescriptionInput } from "./styles";
import { useInputEdit } from "../useInputEdit";
import { useFocusToTextarea } from "./useFocusToTextarea";
import { BiPencil } from "react-icons/bi";
import { RiSaveLine } from "react-icons/ri";

export interface IProps {
  stateValue: string;
  disabled: boolean;
}

const DescriptionInput: FC<IProps> = ({ stateValue, disabled }) => {
  const {
    mode,
    inputValue,
    updateLocalState,
    saveTitleChanges,
    setToEditMode,
  } = useInputEdit({ stateValue, type: "description" });

  const textareaRef = useFocusToTextarea(mode);

  return (
    <StyledDescriptionInput data-testid="parent">
      {mode === "edit" ? (
        <textarea
          value={inputValue}
          onChange={updateLocalState}
          placeholder="Task description, click the icon to edit"
          name="description"
          ref={textareaRef}
          data-testid="description-edit"
        />
      ) : (
        <textarea value={stateValue} disabled data-testid="description-read" />
      )}
      {!disabled && (
        <div className="icon-container">
          {mode === "edit" ? (
            <button onClick={saveTitleChanges}>
              <RiSaveLine
                className="save icon"
                data-testid="description-save-icon"
              />
            </button>
          ) : (
            <button onClick={setToEditMode}>
              <BiPencil
                className="edit icon"
                data-testid="description-edit-icon"
              />
            </button>
          )}
        </div>
      )}
    </StyledDescriptionInput>
  );
};

export default DescriptionInput;
