import { useRef, useEffect } from "react";
import $ from "jquery";

//Binds vertical scrolling between two given element selectors
export const useBindScrollbars = (element1: string, element2: string) => {
  const listenersInitialised = useRef(false);
  const timeout = useRef(0);

  useEffect(() => {
    if (listenersInitialised.current === true) return;

    const selectors = `${element1}, ${element2}`;

    $(selectors).on("scroll", function callback() {
      clearTimeout(timeout.current);

      const source = $(this);
      const target = $(source.is(element1) ? element2 : element1);

      target.off("scroll");

      target.scrollTop(source.scrollTop()!);

      //We use a timeout here to prevent the event listeners bouncing between each other
      //Without this the mouse scroll would be very sluggish

      const timer = setTimeout(() => {
        target.on("scroll", callback);
      }, 100) as unknown;
      timeout.current = timer as number;
    });

    listenersInitialised.current = true;

    return () => {
      //Cleanup
      $(element1).off("scroll");
      $(element2).off("scroll");
      listenersInitialised.current = false;
    };
  }, [element1, element2]);
};
