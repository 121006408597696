import { CollectionType } from "@ehabitation/ts-utils/browser";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "firebaseConfig";
import { useEffect, useState } from "react";
import { getStorage, ref } from "firebase/storage";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import { downloadReference } from "./helpers";

export const useOpcMapping = (planId: string, siteId: string) => {
    const [opcPreFilePath, setOpcPreFilePath] = useState<string>()
    const [opcPostFilePath, setOpcPostFilePath] = useState<string>()

    useEffect(() => {
        const unsub = onSnapshot(
            doc(db, CollectionType.Plans, planId),
            (snap) => {
                const plan = snap.data()
                const files = plan?.generatedFiles
                const filenamePre = files?.opcXlxsPreMitigation
                const filenamePost = files?.opcXlxsPostMitigation
                if(filenamePre && filenamePre !== 'pending') {
                    setOpcPreFilePath(filenamePre)
                }
                if(filenamePost && filenamePost !== 'pending') {
                    setOpcPostFilePath(filenamePost)
                }
            }
        )
        return unsub
    }, [planId])

    const downloadOpcFile = async (filename: string, suffix: string) => {
        if (!filename || filename === "pending") return;
        console.info('Downloading', filename)
        try {
            const storage = getStorage();
            const storageRef = ref(storage, filename);
            logExportEvent({
              planId,
              siteId,
              exportType: ExportTypes.AcumenRiskCalendar,
            });
            await downloadReference(
              storageRef,
              `OPC-${suffix}`,
              "xlsx",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          } catch (err) {
            console.error(err);
          }
    }

    return {
        opcPreFilePath,
        opcPostFilePath,
        downloadOpcFile
    }
}