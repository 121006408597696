import { UserRole } from "@ehabitation/ts-utils/browser";
import { useAppSelector } from "store";
import { selectUserClaims } from "store/auth";

export const useIsFreeUser = () => {
  const userClaims = useAppSelector(selectUserClaims);

  const { organisation, role } = userClaims!;

  const isFreeUser = role === UserRole.guest && organisation === "demo-org";

  return isFreeUser;
};
