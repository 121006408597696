import React, { FC } from "react";
import { VirtualItem } from "react-virtual";

interface TaskListWrapperProps {
  row?: VirtualItem;
}

const TaskListWrapper: FC<TaskListWrapperProps> = ({ children, row }) => {
  return (
    <div
      key={row ? row.index : "header"}
      style={{
        position: row ? "absolute" : "sticky",
        top: 0,
        left: 0,
        width: "100%",
        height: row ? row.size : "38px",
        transform: row ? `translateY(${row.start}px)` : "",
        display: "flex",
        alignItems: "center",
        background: row ? "" : "white",
        zIndex: row ? 1 : 10,
      }}
      data-testid="row"
    >
      {children}
    </div>
  );
};

export default TaskListWrapper;
