import {
  IPlan,
  ISimulation,
  ISite,
  isGuest as roleIsGuest,
} from "@ehabitation/ts-utils/browser";
import { SimulationResult } from "helpers";

import { FC, useEffect } from "react";
import { useAppSelector } from "store";
import { selectRole } from "store/auth";
import { EndDatesTable } from "../components/EndDatesTable";

import { SourcesBox } from "ehab-web-app/src/Components/Plan/SourcesBox";
import { WeatherImpactBox } from "ehab-web-app/src/Components/Plan/WeatherImpactBox";
import { useSiteOpenPageEvent } from "hooks";
import { NavLink } from "react-router-dom";
import { PlanHeatmap } from "./PlanHeatmap";

export const NoPlanSection: FC<{
  site: ISite;
}> = ({ site }) => {
  const isSiteActive = site?.status === "open";
  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  return (
    <div className="px-8 py-6 w-full h-[30rem] grid place-content-center">
      <div className="flex flex-col gap-4 content-center items-center">
        <p className="font-bold">No plan selected.</p>
        {isGuest ? null : (
          <>
            <NavLink to={"../import-plan"}>
              <span className="underline">Import a plan</span>
            </NavLink>
            <p>
              {isSiteActive
                ? "Supported: Primavera P6 XER & XML, Microsoft Project MPP & XML, Asta Powerproject PP"
                : "Building site model..."}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export const PlanView: FC<{
  site: ISite;
  selectedPlan: IPlan;
  simulation?: ISimulation;
  simulationResult?: SimulationResult;
  setPrintableLink: (id: string) => void;
  setHelpLink: (id: string) => void;
  historicalSourceId?: string;
  mitigationPlan?: IPlan;
  mitigationSimulationResult?: SimulationResult;
}> = ({
  site,
  selectedPlan,
  simulation,
  simulationResult,
  setPrintableLink,
  setHelpLink,
  historicalSourceId,
  mitigationPlan,
  mitigationSimulationResult,
}) => {
  const { id: siteId } = site;

  useSiteOpenPageEvent("plan", siteId, selectedPlan?.id);

  useEffect(() => {
    mitigationPlan
      ? setPrintableLink(
          `/print/${siteId}/${selectedPlan.id}/${mitigationPlan.id}/plan`
        )
      : setPrintableLink(`/print/${siteId}/${selectedPlan.id}/0/plan`);
    return () => setPrintableLink("");
  }, []);

  useEffect(() => {
    setHelpLink("https://ehab.co/docs/how-to-use-the-plan-dashboard/");
    return () => setHelpLink("");
  }, []);

  return (
    <div className="min-h-[50rem] w-full flex flex-col">
      <div className="hidden print:block print:text-3xl print:font-semibold print:text-gray-800 print:mb-8 print:mt-8 print:border-b print:border-gray-300 print:pb-4 print:px-8">
        {site.name}
      </div>

      <div className="gap-4">
        <EndDatesTable
          plan={selectedPlan}
          simulationPlanResult={simulationResult?.planResults}
          mitigationSimulationResult={mitigationSimulationResult}
        />
      </div>

      <div className="pb-6">
        <PlanHeatmap planId={selectedPlan.id} simulation={simulation} />
      </div>

      <div className="px-8 py-6 grid md:grid-cols-2 xs:grid-cols-1 gap-4">
        <WeatherImpactBox simulationResult={simulationResult} />
        <SourcesBox
          sources={
            site?.historicalInUse
              ? site.historicalInUse
              : historicalSourceId
              ? [historicalSourceId]
              : [siteId]
          }
        />
      </div>
    </div>
  );
};
