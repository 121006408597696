//React
import { useEffect } from "react";
//Store
import { useAppDispatch, useAppSelector } from "store";
import { selectWorkspaceIsReady } from "store/auth";
import { initProject } from "thunks";

/* Fetches Project and Site data when Main Router mounts */
export const useInitProject = (projectId: string | undefined) => {
  const dispatch = useAppDispatch();

  const workspaceIsReady = useAppSelector(selectWorkspaceIsReady);

  //Fetch project and site data when necessary metadata is available
  useEffect(() => {
    if (workspaceIsReady && projectId) dispatch(initProject(projectId));
  }, [dispatch, workspaceIsReady, projectId]);
};
