import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  IDivisionMap,
  IOrganisationUsers,
  UserManagementTabOption,
} from "types";
export interface IUserManagementState {
  organisationUsers: IOrganisationUsers;
  selectedUser: string | null; //userId
  selectedTab: UserManagementTabOption;
  divisionMap: IDivisionMap;
  loading: string;
  error: string;
}

export const initialState: IUserManagementState = {
  selectedTab: UserManagementTabOption.Users,
  selectedUser: null,
  organisationUsers: {},
  divisionMap: {},
  loading: "",
  error: "",
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setOrganisationUsers: (
      state,
      action: PayloadAction<IOrganisationUsers>
    ) => {
      state.organisationUsers = action.payload;
    },

    setSelectedUser: (state, action: PayloadAction<string | null>) => {
      state.selectedUser = action.payload;
    },

    setSelectedTab: (state, action: PayloadAction<UserManagementTabOption>) => {
      state.selectedTab = action.payload;
    },

    setDivisionMap: (state, action: PayloadAction<IDivisionMap>) => {
      state.divisionMap = action.payload;
    },

    setLoading: (state, action: PayloadAction<string>) => {
      state.loading = action.payload;
    },

    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setError,
  setDivisionMap,
  setLoading,
  setOrganisationUsers,
  setSelectedTab,
  setSelectedUser,
} = userManagementSlice.actions;

export const selectUserManagementTab = (state: RootState) =>
  state.userManagement.selectedTab;

export const selectOrganisationUsers = (state: RootState) =>
  state.userManagement.organisationUsers;

export const selectSelectedUser = (state: RootState) =>
  state.userManagement.selectedUser;

export const selectDivisionMap = (state: RootState): IDivisionMap =>
  state.userManagement.divisionMap;

export const selectUserManagementLoading = (state: RootState) =>
  state.userManagement.loading;

export const selectUserManagementError = (state: RootState) =>
  state.userManagement.error;

//Reducer
export default userManagementSlice.reducer;
