import { FC } from "react";
import ShiftInput from "./ShiftInput";

interface ShiftInputSectionProps {
  id: string;
  shift: string;
  handleChange: (field: "shift", value: string) => void;
  changeMap: Record<string, any>;
  readOnly: boolean;
}

const ShiftInputSection: FC<ShiftInputSectionProps> = ({
  id,
  shift,
  handleChange,
  changeMap,
  readOnly,
}) => {
  return (
    <>
      <div className="flex items-center mb-2">
        <ShiftInput
          id={id}
          shift={shift}
          disabled={readOnly}
          className={`${
            changeMap[id]?.shift !== shift ? "border-emerald-500 border-2  text-2xl" : ""
          }`}
          handleChange={(value: string) => handleChange("shift", value)}
        />
      </div>
    </>
  );
};

export default ShiftInputSection;
