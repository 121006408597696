import { FC, useMemo } from "react";
import { useAppSelector } from "store";
import { selectCurrentTask } from "store/tasks";
import { StyledThresholdsTab } from "./styles";
import TitleInput from "Components/Information/TitleInput/TitleInput";
import { selectRole } from "store/auth";
import { isGuest as roleIsGuest } from "@ehabitation/ts-utils/browser";
import { Button, threeDigitsMax } from "@ehabitation/ui";
import {
  thresholdCategories,
  thresholdUnits,
} from "Pages/sites/weather/WeatherView";

const ThresholdsTab: FC = () => {
  const userRole = useAppSelector(selectRole);

  const currentTask = useAppSelector(selectCurrentTask);
  const isGuest = roleIsGuest(userRole!);

  const shouldShowThresholds = useMemo(() => {
    if (currentTask?.WBS) return true;
    if (!currentTask?.WBS && currentTask?.taskType !== "No Weather Impact")
      return true;
    return false;
  }, [currentTask]);

  if (currentTask) {
    return (
      <StyledThresholdsTab>
        <TitleInput stateValue={currentTask.title} disabled={isGuest} />

        {!currentTask.WBS && (
          <div className="flex gap-2 items-center mb-6">
            <p className="text-3xl">Category:</p>
            <p className="text-3xl" data-testid="currentTask-category-display">
              {currentTask.categoryCode && `${currentTask.categoryCode} - `}
              {currentTask.taskType
                ? currentTask.taskType
                : "No Weather Impact"}
            </p>
          </div>
        )}

        {currentTask.WBS && !isGuest && (
          <p className="thresholds-message">
            The thresholds represent the worst case thresholds of the activities
            that make up {currentTask.title}. Changing the categories of the
            individual activities will update these.
          </p>
        )}
        
        {shouldShowThresholds && (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2 auto-rows-fr grid-flow-row-dense p-2">
            {thresholdCategories.map(({ title, key }) => {
              return typeof currentTask?.[key] === "number" ? (
                <div
                  key={key}
                  className="min-w-[7rem] text-base p-2 flex flex-row items-center gap-1 justify-between border-b border-gray-300"
                >
                  <div className="font-[500]">{title}</div>
                  <div className=" whitespace-nowrap pr-10 relative">
                    {typeof currentTask?.[key] === "number"
                      ? threeDigitsMax(currentTask[key]!, true)
                      : "-"}{" "}
                    <span className="text-xl absolute left-[calc(100%-2.1rem)] -top-1">
                      {thresholdUnits[key]}
                    </span>
                  </div>
                </div>
              ) : null;
            })}
          </div>
        )}
      </StyledThresholdsTab>
    );
  }
  return null;
};

export default ThresholdsTab;
