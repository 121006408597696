//Store
import { useAppDispatch } from "store";
import { setSelectedSiteTab } from "store/siteManagement";

export const useHandleTabSelect = () => {
  const dispatch = useAppDispatch();

  const goToNewSite = () => {
    dispatch(setSelectedSiteTab("Details"));
  };

  return goToNewSite;
};
