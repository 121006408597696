//Styles
import { css } from "styled-components";
import { colors } from "./variables";
//Patterns
import pattern from "assets/grunge/pattern.svg";

export const flex = (
  direction = "row",
  justify = "flex-start",
  align = "flex-start"
) => {
  return css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `;
};

export const font = (str: "title" | "body" = "body") => {
  if (str === "title") {
    return css`
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 200;
    `;
  } else {
    return css`
      font-family: "Decima Mono X", regular;
    `;
  }
};

export const scrollbarOff = () => {
  return css`
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      display: none;
    }
  `;
};

export const dotMatrix = () => {
  return css`
    background-image: url(${pattern});
    background-repeat: repeat;
  `;
};

export const replaceOutline = () => {
  return css`
    outline: none;

    &:focus {
      box-shadow: 0 0 0.2rem 0.1rem ${colors.primaryD};
    }
  `;
};

export const highlightHover = (brightness = 0.95) => {
  return css`
    &:hover {
      filter: brightness(${brightness});
      &:disabled,
      &.selected {
        filter: brightness(1);
      }
    }
  `;
};
