import { IDivision } from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers";

export const createNewDivision = async (
  newDivision: Omit<IDivision, "id" | "orgId">
) => {
  const callCreateNewDivision = firebaseFunction<{
    result: string;
    divisionId: string;
  }>("createNewDivision");
  const result = await callCreateNewDivision(newDivision);
  return result.data?.divisionId;
};

export const updateDivision = async (updatedDivision: IDivision) => {
  const callUpdateDivision = firebaseFunction("updateDivision");
  await callUpdateDivision(updatedDivision);
};
