import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import logo from "assets/images/logo.svg";
import pattern from "assets/grunge/pattern.svg";
import LoginForm from "Components/Auth/LoginForm";
import PasswordResetForm from "Components/Auth/PasswordResetForm";
import RegisterForm from "Components/Auth/RegisterForm";
import AuthAction from "Routers/AuthAction";
import UnsubscribePage from "Pages/unsubscribe/Unsubscribe";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const Divider = ({ className }: { className?: string }) => (
  <div
    className={`flex self-stretch border border-gray-300 my-2 ${className}`}
  />
);

const AuthPage = ({ children }: { children?: JSX.Element[] }) => {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  const logoElement = isLoginPage ? (
    <img
      className="w-full max-w-xs sm:max-w-lg m-6"
      src={logo}
      alt="EHAB Logo"
    />
  ) : (
    <Link
      className="w-full max-w-xs sm:max-w-lg m-6"
      aria-label="Login"
      to="/login"
    >
      <img className="w-full" src={logo} alt="EHAB Logo" />
    </Link>
  );

  return (
    <div
      className="flex flex-col items-center justify-center h-full overflow-auto py-4 sm:py-12"
      style={dotMatrixBgStyle}
    >
      <div className="w-[min(32rem,100vw)] flex flex-col items-center gap-6">
        {logoElement}
        {children}
      </div>
    </div>
  );
};

const Auth = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <AuthPage>
            <h1>Login</h1>
            <LoginForm reauthenticate={false} />
          </AuthPage>
        }
      ></Route>
      <Route
        path="reset_password"
        element={
          <AuthPage>
            <h1>Reset Password</h1>
            <PasswordResetForm />
            <Link to="/login" className="text-blue-500 text-2xl mt-2">
              Return to login
            </Link>
          </AuthPage>
        }
      ></Route>
      <Route
        path="register"
        element={
          <AuthPage>
            <h1>Create Account</h1>
            <RegisterForm />

            <Link
              to="/login"
              className="text-blue-500 text-2xl mt-2 hidden sm:inline-block"
            >
              Return to login
            </Link>
          </AuthPage>
        }
      ></Route>

      <Route path="auth_action/*" element={<AuthAction />} />
      <Route path="unsubscribe" element={<UnsubscribePage />} />

      <Route
        path="*"
        element={<Navigate to={"login"} state={{ from: location.pathname }} />}
      />
    </Routes>
  );
};

export default Auth;
