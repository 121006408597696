import { IPlan, ISimulation, ISite, ITask, ITaskCategory } from "@ehabitation/ts-utils/browser";
import { SimulationTaskResult } from "helpers";
import { RiskDriversView } from "Pages/sites/risk-drivers/RiskDriversView";
import { PlanRequiredWrapper } from "../PlanRequiredWrapper";
import { PlanTasksLoader } from "../PlanTasksLoader";
import { RouteContainer } from "../RouteContainer";
import { SimulationRequiredWrapper } from "../SimulationRequiredWrapper";

export function RiskDriversRoute(basePlan: IPlan | undefined, site: ISite, baseSimulation: ISimulation | undefined, baseSimulationLoading: boolean, requestBaseSimulation: () => void, setCategoriseOpen: (value: boolean) => void, siteCategories: ITaskCategory[], tasksError: string | undefined, allTasksWithSimulationResults: (ITask & { simulation: SimulationTaskResult; })[] | undefined, isLoadingTasks: boolean, milestones: any, setPrintableLink: (id: string) => void, setHelpLink: (id: string) => void, setOpeningTaskId: (arg0: string) => void) {
    return <RouteContainer>
      <PlanRequiredWrapper
        plan={basePlan}
        site={site}
        renderContent={({ plan: basePlan }) => (
          <SimulationRequiredWrapper
            simulation={baseSimulation}
            simulationLoading={baseSimulationLoading}
            requestSimulation={requestBaseSimulation}
            plan={basePlan}
            setCategoriseOpen={setCategoriseOpen}
            renderContent={({ simulation }) => (
              <PlanTasksLoader
                site={site}
                plan={basePlan}
                simulation={simulation}
                siteCategories={siteCategories}
                tasksError={tasksError}
                allTasksWithSimulationResults={allTasksWithSimulationResults}
                isLoadingTasks={isLoadingTasks}
                renderContent={({
                  simulationResult, allTasks: allBaseTasksWithSimulationResults,
                }) => (
                  <RiskDriversView
                    baseSimulation={baseSimulation}
                    simulationPlanResult={simulationResult?.planResults}
                    tasks={allBaseTasksWithSimulationResults ||
                      []}
                    milestones={milestones || []}
                    siteId={site.id}
                    planId={basePlan.id}
                    setPrintableLink={setPrintableLink}
                    setHelpLink={setHelpLink}
                    handleOpenCategorise={(taskId) => {
                      setOpeningTaskId(taskId);
                      setCategoriseOpen(true);
                    } } />
                )} />
            )} />
        )} />
    </RouteContainer>;
  }