import { StyledMatrixContainer } from "elements";
import NotFoundImg from "assets/images/404.png";

export default function NotFound() {
  return (
    <StyledMatrixContainer>
      <h1>OOPS!</h1>
      <h2>Sorry, but we couldn&apos;t find this page :(</h2>
      <img src={NotFoundImg} alt="404 illustration" />
      <span>
        {" "}
        What about the...<a href="/">homepage</a>?
      </span>
    </StyledMatrixContainer>
  );
}
