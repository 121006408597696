import React from "react";
import { ICharts } from "types/ui";
import ChartsToggleButton from "./ChartsToggleButton";
import { StyledChartsToggleButtonContainer } from "./styles";

type IChartToggleButtonLables = {
  [key in ICharts]: string;
};

const chartsButtonLabels: IChartToggleButtonLables = {
  // risk: "risk",
  weather: "weather",
  forecast: "forecast",
};

export const ChartsToggleButtonsContainer = () => {
  const chartToggleButtonLabels = Object.values(chartsButtonLabels);

  return (
    <StyledChartsToggleButtonContainer>
      {chartToggleButtonLabels.map((label: any) => (
        <ChartsToggleButton key={label} label={label} />
      ))}
    </StyledChartsToggleButtonContainer>
  );
};
