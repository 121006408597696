//Store
import { createSelector } from "reselect";
//Selectors
import { selectCurrentSite, selectAvailablePlans } from "store/project";
import { selectCurrentTask, allTasksAsArraySelector } from "store/tasks";

export const currentTaskIndexSelector = createSelector(
  [selectCurrentTask],
  (currentTask) => currentTask?.index
);

export const selectPlanCanBeExported = createSelector(
  [selectCurrentSite, selectAvailablePlans],
  (currentSite, availablePlans) => {
    if (!availablePlans) return false;

    const planId = currentSite?.plan;

    if (!planId) return false;

    if (
      availablePlans.hasOwnProperty(planId) &&
      availablePlans[planId].hasOriginalPlanName
    ) {
      return true;
    }

    return false;
  }
);

//Plan Error
export const allTasksLengthSelector = createSelector(
  [allTasksAsArraySelector],
  (allTasks) => (allTasks ? allTasks.length : 0)
);
