import React from "react";
import { AlertMessage } from "./AlertMessage";
import { OptionSelector } from "./OptionSelector";

interface MatrixDriftAlertProps {
  selectedOption: string;
  handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MatrixDriftAlert: React.FC<MatrixDriftAlertProps> = ({
  selectedOption,
  handleOptionChange,
}) => {
  return (
    <>
      <AlertMessage
        message="Weather impact thresholds have been modified. This means the weather-related risks for some activities may have changed."
        linkText="Click here to see project and plan thresholds."
        linkTo="../settings/thresholds"
      />
      <div className="flex flex-col mt-8">
        <OptionSelector
          name="syncPlanToRiskMatrixOption"
          id="overwriteThresholds"
          value="true"
          label="Apply new thresholds to plan before simulating. Note this change is irreversible."
          onChange={handleOptionChange}
          checked={selectedOption === "true"}
        />
        <OptionSelector
          name="syncPlanToRiskMatrixOption"
          id="ignoreThresholds"
          value="false"
          label="Simulate using previous thresholds."
          onChange={handleOptionChange}
          checked={selectedOption === "false"}
        />
      </div>
    </>
  );
};