import { useState } from "react";

import { firebaseFunction } from "helpers";

import { Button, Input } from "@ehabitation/ui";
import { useIsMounted } from "hooks";
import { Spinner } from "Components/Spinner";

export const resetPassword = async (email: string) => {
  const resetUserPassword = firebaseFunction("resetUserPassword");
  await resetUserPassword({ email });
};

const usePasswordReset = () => {
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess("");
    setError("");
    setEmail(e.target.value);
  };

  const isValid = () => {
    if (!email) return false;
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSuccess("");

    if (!isValid())
      return setError("Please provide the email address for your account");

    setError("");
    setIsLoading(true);

    try {
      await resetPassword(email);
      if (isMounted()) {
        setIsLoading(false);
        setEmail("");
        setSuccess(
          `Password reset email sent to "${email}" if it exists in our system.`
        );
      }
    } catch (error) {
      if (isMounted()) {
        console.error(error);
        setIsLoading(false);
        setError(
          "Password reset failed, please try again or contact support@ehab.co if the error persists."
        );
      }
    }
  };

  return {
    email,
    isLoading,
    error,
    success,
    handleChange,
    handleSubmit,
  };
};

const PasswordResetForm = () => {
  const { email, error, success, isLoading, handleChange, handleSubmit } =
    usePasswordReset();

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center w-full"
      aria-label="Reset Password"
    >
      <Input
        className="self-stretch"
        value={email}
        onChange={handleChange}
        type="email"
        placeholder="Email"
        autoComplete="email"
        name="email"
        disabled={isLoading}
      />

      {error && <p className="pt-4 text-red-700 text-center">{error}</p>}

      {success && (
        <p className="pt-4 text-emerald-600 text-center">{success}</p>
      )}

      {isLoading && (
        <div className="py-4">
          <Spinner />
        </div>
      )}

      <Button className="my-4" type="submit" disabled={isLoading}>
        Submit
      </Button>
    </form>
  );
};

export default PasswordResetForm;
