//Styles
import styled from "styled-components";
import {
  font,
  spacing,
  colors,
  grays,
  flex,
  fontSize,
  replaceOutline,
  highlightHover,
} from "styles";

export const StyledButton = styled.button`
  ${font("title")};
  ${highlightHover()};
  margin-left: auto;
  cursor: pointer;
  background-color: ${colors.primary};
  border-radius: 5rem;
  border: 1px solid ${grays.dark};
  display: inline-block;
  color: ${grays.dark};
  font-weight: 300;
  padding: ${`${spacing.s} ${spacing.l}`};
  text-decoration: none;

  &:hover {
    background-color: ${colors.primaryL};
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

interface IButtonProps {
  color: "black" | "white";
  width?: number;
}

export const StyledMonochromeSelect = styled.select<IButtonProps>`
  ${flex("row", "center", "center")};
  ${font("title")};
  ${replaceOutline()}
  cursor: pointer;
  white-space: nowrap;
  font-size: ${fontSize.s};
  border-radius: 2rem;
  background-color: ${(props) => props.color};
  color: ${(props) => (props.color === "black" ? "white" : "black")};
  border: 0.1rem solid black;
  padding: ${spacing.s} ${spacing.l};
  height: 3rem;
  ${(props) => (props.width ? `width: ${props.width}rem` : null)};
  ${highlightHover()}

  &:disabled {
    opacity: 0.4;
    cursor: inherit;
  }
`;

export const StyledCircularIconButton = styled.button`
  ${flex("column", "center", "center")};
  ${replaceOutline()};
  cursor: pointer;
  border-radius: 50%;
  width: 3.7rem;
  height: 3.7rem;

  .icon {
    height: 2.2rem;
    width: 2.2rem;
  }
  &:disabled {
    opacity: 0.4;
    cursor: inherit;
  }
`;
