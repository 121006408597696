import { useAppSelector } from "store";
import { selectMeanEndDate, selectMinMaxEndDate } from "store/selectors";
import { StyledDateBreakdown } from "./styles";

const DateBreakdown = () => {
  const { minEndDate, maxEndDate } = useAppSelector(selectMinMaxEndDate);
  const meanDate = useAppSelector(selectMeanEndDate);

  return (
    <StyledDateBreakdown>
      <div className="row">
        <p>Iterations :</p>
        <p data-testid="iteration-count">1000</p>
      </div>

      <div className="row">
        <p>Minimum End Date :</p>
        <p data-testid="minimum-end">{minEndDate}</p>
      </div>

      <div className="row">
        <p>Maximum End Date :</p>
        <p data-testid="maximum-end">{maxEndDate}</p>
      </div>

      <div className="row">
        <p>Mean End Date :</p>
        <p data-testid="mean-end">{meanDate}</p>
      </div>
    </StyledDateBreakdown>
  );
};

export default DateBreakdown;
