//Colors
export const colors = {
  //Brand Green/Teal
  primary: "#1ceaa0",
  primaryL: "#44eeb0",
  primaryD: "#13cd89",
  primaryDD: "#11b97b",
  //Orange
  secondary: "#ffd02b",
  secondaryL: "#ffdc5c",
  secondaryD: "#f5c000",
  //Blue
  tertiary: "#2f80ed",
  tertiaryL: "#4191fa",
  tertiaryD: "#236fd3",
  //Red
  warning: "#bc2c1a",
  //AM Chart
  teal: "#33C499",
  purple: "#DC6DEE",
  blue: "#5EB8F9",
  //Warning Levels
  safe: "#43bc7a",
  moderate: "#FFD02B",
  major: "#FF0000",
  //To desaturate dots
  semiOpaqueWhite: "rgba(255, 255, 255, 0.6)",
  //Gantt Chart Colors
  ganttGray: "#b7c1cc",
  ganttRed: "#fa7299",
  ganttYellow: "#fece71",
  ganttGreen: "#7adfb1",
  ganttText: "#0c0202",
  //Threshold Colors
  windSpeed: "#34a853",
  temp: "#fbbc05",
  cumulativeRainfall: "#b5222f",
  hourlyRainfall: "#4285f4",
  waveHeight: "#b45f06",
  windGusts: "#45818e",
  snowfall: "#674ea7",
  snowfall24Hour: "#a64d79",
  visibility: "#f09300",
};

export const grays = {
  dark: "#444444",
  medium: "#8b8b8b",
  light: "#b8b8b8",
  veryLight: "#F1F1F1",
};

//Spacing
export const spacing = {
  xs: "0.3rem",
  s: "0.6rem",
  m: "1rem",
  l: "2rem",
  xl: "3.3rem",
};

//Font
export const fontSize = {
  xs: "1rem",
  s: "1.4rem",
  m: "1.6rem",
  l: "1.8rem",
  xl: "2rem",
  xxl: "2.4rem",
};

//Fixed Heights
export const headerHeight = "7rem";
export const planControlsHeight = "7rem";
export const planBottomRowHeight = "14.50rem";

//Fixed Widths
export const taskListWidth = "47rem";
