import { useCallback } from "react";
import {CollectionType, IPlan, ITaskCategory } from "@ehabitation/ts-utils/browser";
import { getRiskMatrixCategories } from "helpers/firebase/riskMatrix";
import { getRiskMatrixDoc } from "helpers/firebase/managePlan";

export const useFetchPlanCategories = () => {
    const fetchPlanCategories = useCallback(async (plan: IPlan): Promise<ITaskCategory[]> => {
      const planRiskMatrixDoc = await getRiskMatrixDoc(plan.id, CollectionType.Plans);
      const { riskMatrix: planRiskMatrixCategoryDocs } = await getRiskMatrixCategories(planRiskMatrixDoc.id);
      
      const categories: ITaskCategory[] = planRiskMatrixCategoryDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      } as ITaskCategory));

      const activeCategories = plan?.activeCategories ?? [];
      const planCategories = (categories ?? []).filter((category: ITaskCategory) =>
        activeCategories.includes(category.name)
      );
      
      return planCategories;
    }, []);
  
    return { fetchPlanCategories };
  };