import { UserRole } from "@ehabitation/ts-utils/browser";

export const shouldShowUser = (
  role: UserRole,
  userRole: UserRole | undefined
) => {
  if (role === UserRole.orgAdmin) return true;

  if (role === UserRole.divisionAdmin) {
    if (userRole === UserRole.orgAdmin) return false;
    return true;
  }

  if (role === UserRole.projectAdmin) {
    if (userRole === UserRole.projectAdmin || userRole === UserRole.planner)
      return true;
    return false;
  }

  return false;
};
