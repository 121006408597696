/* Even though the createMuiTheme inteface has been extended, the Mui Pickers team forgot 
to include MuiPickersToolbarText, so we have to turn off typescript here */

// @ts-nocheck
import { createTheme } from "@material-ui/core/styles";
import { colors } from "styles";

export const materialTheme = createTheme({
  overrides: {
    MuiTypography: {
      h3: {
        fontSize: "3.2rem",
      },
      h4: {
        fontSize: "3.2rem",
      },
      h6: {
        fontSize: "1rem",
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        fontWeight: "400",
        lineHeight: "1.235em",
      },
    },
  },

  typography: {
    htmlFontSize: 10,
    fontFamily: `Decima Mono X, sans-serif`,
  },

  palette: {
    primary: {
      main: colors.primaryDD,
    },
  },
});
