import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectProjectId } from "store/project";
import { selectUserDivisionId, selectRole } from "store/auth";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IFormError } from "types";
import moment from "moment";
import { INewSiteForm } from "@ehabitation/ts-utils/browser";
import { newSiteSchema } from "./validation";
import { roundTo6DP, validateNewForm } from "helpers";
import { triggerCreateNewSite, triggerUpdateSite } from "./thunks";
import { selectSelectedSiteDetails } from "store/siteManagement";

export const useNewSiteForm = () => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);
  const projectId = useAppSelector(selectProjectId);
  const divisionId = useAppSelector(selectUserDivisionId);
  const selectedSiteDetails = useAppSelector(selectSelectedSiteDetails);

  const [newSite, setNewSite] = useState<INewSiteForm>(
    !selectedSiteDetails
      ? ({
          id: "",
          name: "",
          division: role === "orgAdmin" ? "" : divisionId!,
          project:
            role === "orgAdmin" || role === "divisionAdmin" ? "" : projectId!,
          lat: "",
          address: "",
          long: "",
          siteCode: "",
          startDate: new Date(),
          endDate: moment().add(1, "day").toDate(),
          description: "",
          visibility: "planner",
        } as INewSiteForm)
      : selectedSiteDetails
  );

  const [error, setError] = useState<IFormError | null>(null);

  const handleUpdateForm = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    e.persist();
    const { name, value } = e.target;
    setNewSite((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateAddress = (address: string) => {
    setNewSite((prev) => ({ ...prev, address: address }));
  };

  const handleUpdateDates = (date: MaterialUiPickersDate, type: string) => {
    setNewSite((prev) => ({ ...prev, [type]: date }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const [isValid, error] = await validateNewForm(newSiteSchema, newSite);

    if (!isValid && error) setError(error);

    if (isValid) {
      const newSiteClean = {
        ...newSite,
        lat: roundTo6DP(newSite.lat),
        long: roundTo6DP(newSite.long),
      };
      if (!newSiteClean.id) dispatch(triggerCreateNewSite(newSiteClean));
      else dispatch(triggerUpdateSite(newSiteClean));
    }
  };

  const setLatLong = ({ lat, long }: { lat: string; long: string }) => {
    setNewSite((prev) => ({
      ...prev,
      lat: String(roundTo6DP(lat) || ""),
      long: String(roundTo6DP(long) || ""),
    }));
  };

  return {
    role,
    error,
    newSite,
    handleUpdateDates,
    handleUpdateForm,
    handleUpdateAddress,
    handleSubmit,
    setLatLong,
  };
};
