import {
  ITask,
  WeatherKey,
  extremeDailyRainAcc,
  extremeMaxTemp,
  extremeMinTemp,
  extremeSnowfall,
  extremeSnowfall24Hour,
  extremeVisibility,
  extremeWaveHeight,
  extremeWind,
  extremeWindGusts,
} from "@ehabitation/ts-utils/browser";
import { getLevelBorderColorClass } from "@ehabitation/ui";
import { Tooltip } from "@material-ui/core";
import WeatherImpactsChart from "Components/Risk/WeatherImpactsChart";
import { SimulationLevel, SimulationTaskResult } from "helpers";
import { FC, useEffect, useMemo, useState } from "react";
import { BiPencil, BiX } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import Select, { GroupProps, components } from "react-select";
import { IThresholdUpdate, weatherUnitKey } from "types";
import { DifferenceCell } from "../components/DifferenceCell";
import { weatherKeyIcons } from "../constants";
import { thresholdCategories } from "../weather/WeatherView";

const tooltipClasses = `
  relative before:absolute before:shadow-lg before:font-normal before:bg-gray-300
  before:z-50 before:content-tip before:left-0 before:top-[125%] before:border
  before:border-gray-600 before:mb-2 before:bg-white before:rounded-md before:px-2
  hover:before:flex before:hidden
`;

export const indentColours = [
  "border-indigo-300",
  "border-fuchsia-300",
  "border-teal-300",
  "border-lime-300",
];

interface CategoryRowProps {
  task: ITask & { simulation: SimulationTaskResult };
  mitigatedTask?: Partial<
    SimulationTaskResult &
      IThresholdUpdate & {
        riskIntervalResults: any;
        likelyCause: string;
        isMitigated: boolean;
      }
  >;
  selectedSimLevel: SimulationLevel;
  editedMitigations: any;
  pendingMitigations: any;
  setMitigation: (key: string, value: string | undefined) => void;
  mitigationSimulationPending: boolean;
  mitigationSimulationInProgress: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isEditing?: boolean;
  isGuest?: boolean;
  handleOpenCategorise: (taskId: string) => void;
  applyMitigation: (key: string, value: string | undefined) => void;
}

export const groupName = (name: string) => {
  const groupName = name.replace(/([A-Z])/g, " $1").trim();
  return groupName[0].toUpperCase() + groupName.slice(1);
};

interface GroupOption {
  readonly value: string;
  readonly label: string;
  readonly icon: string;
}
const Group = (props: GroupProps<GroupOption, false>) => (
  <div>
    <components.Group {...props} />
  </div>
);

const Option = (props: any) => (
  <components.Option {...props}>
    <div className="flex flex-row items-center">
      <img src={props.data.icon} alt="logo" className="w-8 h-8 mr-2" />
      {props.data.label}
    </div>
  </components.Option>
);

export const CategoryRow: FC<CategoryRowProps> = ({
  task,
  mitigatedTask,
  selectedSimLevel,
  editedMitigations,
  pendingMitigations,
  setMitigation,
  mitigationSimulationPending,
  mitigationSimulationInProgress,
  setIsEditing,
  isEditing,
  isGuest,
  handleOpenCategorise,
  applyMitigation,
}) => {
  const isIndented = task.wbsHierarchyLevel! > 0;
  const [targetThreshold, setTargetThreshold] = useState<WeatherKey | "">("");
  const [noOptions, setNoOptions] = useState(true);
  const editedThreshold = editedMitigations?.[targetThreshold as keyof ITask];
  const pendingThreshold = pendingMitigations?.[targetThreshold as keyof ITask];
  const mitigatedThreshold = mitigatedTask?.isMitigated
    ? mitigatedTask?.[targetThreshold as keyof SimulationTaskResult]
    : undefined;

  const planThreshold = task?.[targetThreshold as keyof ITask];
  const unitThreshold = useMemo(
    () => targetThreshold && task?.[weatherUnitKey[targetThreshold]],
    [targetThreshold, task, weatherUnitKey]
  );
  const planThresholdCategories = useMemo(
    () =>
      thresholdCategories.filter(({ key }) => typeof task[key] !== "undefined"),
    [task, thresholdCategories]
  );
  const mitigationThresholdDisplay = useMemo(() => {
    const acc = {
      ...(mitigatedTask?.isMitigated ? mitigatedTask : {}),
      ...editedMitigations,
      ...pendingMitigations,
    };
    planThresholdCategories.forEach(({ key }) => {
      if (
        Number(acc[key]) === Number(task[key]) ||
        typeof acc[key] === "undefined"
      ) {
        delete acc[key];
      }
    });
    return acc;
  }, [
    editedMitigations,
    pendingMitigations,
    task,
    mitigatedTask,
    planThresholdCategories,
  ]);

  const limits = useMemo(() => {
    const getActiveValue = (key: WeatherKey) =>
      mitigationThresholdDisplay[key] ?? task[key];

    let max, min;

    if (targetThreshold === WeatherKey.maxTemp) {
      max = extremeMaxTemp;
    } else if (targetThreshold === WeatherKey.minTemp) {
      max =
        typeof getActiveValue(WeatherKey.maxTemp) === "undefined"
          ? extremeMaxTemp
          : getActiveValue(WeatherKey.maxTemp) - 1;
    } else if (targetThreshold === WeatherKey.dailyRainAcc) {
      max = extremeDailyRainAcc;
    } else if (targetThreshold === WeatherKey.hourlyRainAcc) {
      max =
        typeof getActiveValue(WeatherKey.dailyRainAcc) === "undefined"
          ? extremeDailyRainAcc
          : getActiveValue(WeatherKey.dailyRainAcc) - 1;
    } else if (targetThreshold === WeatherKey.wind) {
      max = extremeWind;
    } else if (targetThreshold === WeatherKey.windGusts) {
      max = extremeWindGusts;
    } else if (targetThreshold === WeatherKey.waveHeight) {
      max = extremeWaveHeight;
    } else if (targetThreshold === WeatherKey.visibility) {
      max = extremeVisibility;
    } else if (targetThreshold === WeatherKey.snowfall) {
      max =
        typeof getActiveValue(WeatherKey.snowfall24Hour) === "undefined"
          ? extremeSnowfall
          : getActiveValue(WeatherKey.snowfall24Hour) - 1;
    } else {
      max = extremeSnowfall24Hour;
    }

    if (targetThreshold === WeatherKey.minTemp) {
      min = extremeMinTemp;
    } else if (targetThreshold === WeatherKey.maxTemp) {
      min =
        typeof getActiveValue(WeatherKey.minTemp) === "undefined"
          ? extremeMinTemp
          : getActiveValue(WeatherKey.minTemp) + 1;
    } else if (targetThreshold === WeatherKey.dailyRainAcc) {
      min =
        typeof getActiveValue(WeatherKey.hourlyRainAcc) === "undefined"
          ? 1
          : getActiveValue(WeatherKey.hourlyRainAcc);
    } else if (targetThreshold === WeatherKey.snowfall24Hour) {
      min =
        typeof getActiveValue(WeatherKey.snowfall) === "undefined"
          ? 0
          : getActiveValue(WeatherKey.snowfall);
    } else {
      min = 0;
    }
    return { max, min };
  }, [targetThreshold, mitigationThresholdDisplay]);

  const mitigatedThresholdCategories = useMemo(
    () =>
      planThresholdCategories.filter(
        ({ key }) => typeof mitigationThresholdDisplay[key] !== "undefined"
      ),
    [planThresholdCategories, mitigationThresholdDisplay]
  );

  const groupedMitigationOptions = useMemo(() => {
    const mappedMitigatedThresholdCategories = mitigatedThresholdCategories.map(
      ({ key, title }) => {
        return {
          value: key as string,
          label: `${title}`,
          icon: weatherKeyIcons[key],
        };
      }
    );
    const mappedNonMitigatedThresholdCategories = planThresholdCategories
      .filter(
        ({ key }) => typeof mitigationThresholdDisplay?.[key] === "undefined"
      )
      .map(({ key, title }) => {
        return {
          value: key as string,
          label: `${title}`,
          icon: weatherKeyIcons[key],
        };
      });
    setNoOptions(
      mappedMitigatedThresholdCategories.length === 0 &&
        mappedNonMitigatedThresholdCategories.length === 0
    );
    const groupedOptions = [
      {
        label: "Mitigated",
        options: mappedMitigatedThresholdCategories,
      },
      {
        label: "Not Mitigated",
        options: mappedNonMitigatedThresholdCategories,
      },
    ];
    return groupedOptions;
  }, [
    mitigatedThresholdCategories,
    planThresholdCategories,
    mitigationThresholdDisplay,
  ]);

  const value = useMemo(() => {
    let option: { value: string; label: string; icon: string } | undefined;
    groupedMitigationOptions.forEach((mitigation) => {
      const foundOption = mitigation.options.find(
        (o) => o.value === targetThreshold
      );
      option = foundOption ? foundOption : option;
    });
    return option;
  }, [groupedMitigationOptions, targetThreshold]);

  useEffect(() => {
    if (mitigatedThresholdCategories.length && targetThreshold === "") {
      setTargetThreshold(mitigatedThresholdCategories[0].key);
    }
  }, [mitigatedThresholdCategories]);
  return (
    <tr
      data-task-id={task.id}
      data-testid="mitigation-category-row"
      className={`group ${task.WBS ? "" : "hover:bg-blue-50"} h-[42px]`}
    >
      <td className={`border-x border-b relative`}>
        <Tooltip arrow title={<span className="text-2xl">{task.title}</span>}>
          <div className="truncate pr-2">
            {isIndented && (
              <>
                <span
                  className={`border-l-2 absolute top-0 bottom-0 w-4 ${getLevelBorderColorClass(
                    task
                  )}`}
                />
                <span className="pr-4" />
              </>
            )}
            {task.title}
          </div>
        </Tooltip>
      </td>
      <td
        className="px-4 truncate border-r border-b relative"
        style={{ overflow: "visible" }}
      >
        <div className="flex items-center">
          <p className=" overflow-x-hidden w-5/6 flex items-center flex-1">
            {task.taskType ? task.taskType : "-"}{" "}
            <p
              className="pl-2 cursor-pointer"
              onClick={() => handleOpenCategorise(task.id)}
            >
              <BiPencil />
            </p>
          </p>
          {(task.taskType !== task.simulation.taskType ||
            (mitigatedTask &&
              mitigatedTask.isMitigated &&
              task.taskType !== mitigatedTask?.taskType)) && (
            <p
              className={`text-gray-500 flex-shrink-0 ${tooltipClasses} whitespace-pre`}
              aria-label="Mitigation issue"
              data-tip={
                "" +
                (task.simulation.taskType !== task.taskType
                  ? "Task category no longer matches simulation, resimulate plan"
                  : "") +
                (task.taskType !== task.simulation.taskType &&
                task.taskType !== mitigatedTask?.taskType
                  ? "\n"
                  : "") +
                (mitigatedTask?.taskType !== task.taskType
                  ? "Mitigation no longer matches simulation, resimulate mitigation"
                  : "")
              }
            >
              <FiAlertTriangle className="text-red-500" />
            </p>
          )}
        </div>
      </td>
      <td
        className={`px-4 truncate border-r border-b relative text-center cursor-pointer ${
          mitigationSimulationInProgress && "animate-pulse"
        }`}
      >
        <Tooltip
          arrow
          enterDelay={500}
          leaveDelay={250}
          enterNextDelay={500}
          title={
            <WeatherImpactsChart
              className="w-full h-5/6 bg-white rounded"
              maxDelayDays={task.simulation.maxDelayDays}
              violationData={task.simulation.violationData}
              settings={{ fontSize: 12, maxWidth: 80 }}
            />
          }
        >
          <p>
            {task.simulation.riskIntervalResults?.[selectedSimLevel]
              ?.weatherDays || 0}
          </p>
        </Tooltip>
      </td>
      <td
        className={`px-4 truncate border-r border-b relative text-center cursor-pointer ${
          mitigationSimulationInProgress && "animate-pulse"
        }`}
      >
        {mitigatedTask ? (
          <Tooltip
            arrow
            enterDelay={500}
            leaveDelay={250}
            enterNextDelay={500}
            title={
              <WeatherImpactsChart
                className="w-full h-5/6 bg-white rounded"
                maxDelayDays={mitigatedTask.maxDelayDays}
                violationData={mitigatedTask.violationData}
                settings={{ fontSize: 12, maxWidth: 80 }}
              />
            }
          >
            <div>
              <DifferenceCell
                base={
                  task.simulation.riskIntervalResults?.[selectedSimLevel]
                    ?.weatherDays
                }
                mitigated={
                  mitigatedTask && !mitigationSimulationPending
                    ? mitigatedTask?.riskIntervalResults?.[selectedSimLevel]
                        ?.weatherDays || 0
                    : "?"
                }
              />
            </div>
          </Tooltip>
        ) : (
          "-"
        )}
      </td>
      <td className="px-4 truncate border-r border-b relative text-center">
        <div className="flex">
          {mitigatedThresholdCategories.length
            ? [
                ...new Set(
                  mitigatedThresholdCategories.map(({ key }) => (
                    <div
                      key={key}
                      className="w-8 h-8 bg-contain"
                      style={{
                        backgroundImage: `url(${weatherKeyIcons[key]})`,
                      }}
                    ></div>
                  ))
                ),
              ]
            : "-"}
        </div>
      </td>
      <td className={`px-4 truncate border-r border-b relative pt-0.5`}>
        <Select<GroupOption>
          menuPosition="fixed"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuShouldBlockScroll
          className={`disabled:text-gray-500 absolute inset-0 bg-transparent pl-2 ${
            !mitigatedThresholdCategories.length && !targetThreshold
              ? "italic"
              : ""
          }`}
          isSearchable={false}
          value={value}
          onChange={(e) => {
            setTargetThreshold((e?.value as WeatherKey) || "");
            setIsEditing(false);
          }}
          isDisabled={noOptions || mitigationSimulationInProgress}
          options={groupedMitigationOptions}
          components={{ Group, Option }}
          formatOptionLabel={(option) => (
            <div className="flex flex-row items-center">
              <img src={option.icon} alt="logo" className="w-8 h-8 mr-2" />
              {option.label}
            </div>
          )}
        />
      </td>
      <td className={`px-4 truncate border-r border-b relative text-center`}>
        {typeof planThreshold !== "undefined" ? (
          <div className="grid gap-1 grid-cols-2 items-baseline">
            <div className="text-end">{planThreshold}</div>
            <div className="text-start text-xl opacity-80">
              {targetThreshold ? task?.[weatherUnitKey[targetThreshold]] : ""}
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td
        className={`px-4 truncate border-r border-b overflow-visible relative group`}
      >
        {isEditing ? (
          <Tooltip
            arrow
            disableHoverListener
            title={
              <span className="text-2xl">
                Min: {limits.min} Max: {limits.max}
              </span>
            }
          >
            <div className={`absolute inset-0`}>
              <input
                autoFocus={true}
                value={editedThreshold}
                onChange={(e) => {
                  setMitigation(targetThreshold as keyof ITask, e.target.value);
                }}
                disabled={mitigationSimulationInProgress}
                onBlur={() => {
                  if (typeof pendingThreshold === "undefined") {
                    setIsEditing(false);
                    setMitigation(
                      targetThreshold as keyof ITask,
                      mitigatedThreshold?.toString()
                    );
                    applyMitigation(
                      targetThreshold as keyof ITask,
                      mitigatedThreshold?.toString()
                    );
                  }
                  if (typeof editedThreshold != undefined) {
                    const numValue = Number(editedThreshold);
                    if (!isNaN(numValue)) {
                      let value = String(numValue);
                      if (numValue < limits.min) {
                        value = String(limits.min);
                      } else if (numValue > limits.max) {
                        value = String(limits.max);
                      }
                      setMitigation(targetThreshold as keyof ITask, value);
                      applyMitigation(targetThreshold as keyof ITask, value);
                    }
                  }
                }}
                className={`min-w-0 w-full h-full pl-3 pr-10`}
              />
              <button
                onClick={() => {
                  setMitigation(
                    targetThreshold as keyof ITask,
                    mitigatedThreshold?.toString()
                  );
                  applyMitigation(
                    targetThreshold as keyof ITask,
                    mitigatedThreshold?.toString()
                  );
                  setIsEditing(false);
                }}
                className="text-2xl absolute right-2 top-4 p-1 transition-colors hover:bg-gray-200 rounded-lg"
                aria-label="cancel"
                disabled={isGuest || mitigationSimulationInProgress}
              >
                <BiX className="text-red-500" />
              </button>
            </div>
          </Tooltip>
        ) : (
          <span className="absolute inset-0">
            <button
              onClick={() => {
                setMitigation(
                  targetThreshold as keyof ITask,
                  mitigatedThreshold?.toString()
                );
                setIsEditing(true);
              }}
              className="w-full h-full flex items-center justify-center"
              disabled={
                isGuest ||
                targetThreshold === "" ||
                mitigationSimulationInProgress
              }
            >
              {targetThreshold !== "" &&
              typeof mitigationThresholdDisplay[targetThreshold] !==
                "undefined" ? (
                <>
                  <span>{mitigationThresholdDisplay[targetThreshold]}</span>
                  <span className="pl-1 text-xl opacity-80">
                    {unitThreshold}
                  </span>
                  <button
                onClick={() => {
                  setMitigation(
                    targetThreshold as keyof ITask,
                    mitigatedThreshold?.toString()
                  );
                  applyMitigation(
                    targetThreshold as keyof ITask,
                    mitigatedThreshold?.toString()
                  );
                  setIsEditing(false);
                }}
                className="text-2xl absolute right-2 top-4 p-1 transition-colors hover:bg-gray-200 rounded-lg"
                aria-label="cancel"
                disabled={isGuest || mitigationSimulationInProgress}
              >
                <BiX className="text-red-500" />
              </button>
                </>
              ) : (
                "-"
              )}
            </button>
            <button
                onClick={() => {
                  setMitigation(
                    targetThreshold as keyof ITask,
                    planThreshold?.toString()
                  );
                  applyMitigation(
                    targetThreshold as keyof ITask,
                    planThreshold?.toString()
                  );
                  setIsEditing(false);
                }}
                className="text-2xl absolute right-2 top-4 p-1 transition-colors hover:bg-gray-200 rounded-lg"
                aria-label="cancel"
                disabled={isGuest || mitigationSimulationInProgress}
              >
                <BiX className="text-red-500" />
              </button>
          </span>
        )}
      </td>
    </tr>
  );
};
