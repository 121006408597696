import { IPlan, IProject, ISite } from "@ehabitation/ts-utils/browser";

export interface PlanImportProps {
  setPage: (page: PlanImportPage) => void;
  plan?: IPlan;
  site: ISite;
  project: IProject;
}

export enum PlanImportPage {
  Draft = "Draft",
  File = "File",
  CategorySelection = "CategorySelection",
  Sanitizing = "Sanitizing",
  Complete = "Complete",
}

export enum PlanFileType {
  UNKNOWN = "UNKNOWN",
  XML = "XML",
  XER = "XER",
  MPP = "MPP",
  PP = "PP",
}
