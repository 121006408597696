import Initialising from "Components/Initialising/Initialising";
import Plan from "Pages/projects/Plan";
import { useInitProject, useTaskTypes } from "hooks";
import { FC } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { selectCurrentSite } from "store/project";

const ProjectRoutes: FC = () => {
  const { projectId } = useParams();

  useInitProject(projectId);
  useTaskTypes(projectId);

  const currentLoadedSite = useAppSelector(selectCurrentSite);

  const workspaceReady =
    !!currentLoadedSite && currentLoadedSite.project === projectId;

  return (
    <Routes>
      <Route path="" element={<Navigate to="plan" replace />}></Route>
      <Route
        path="plan"
        element={
          !workspaceReady ? (
            <Initialising>
              <p>Loading Project Data...</p>
            </Initialising>
          ) : (
            <Plan/>
          )
        }
      />
    </Routes>
  );
};
export default ProjectRoutes;
