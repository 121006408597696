import { useContextMenu } from "hooks";
import { deleteSiteById } from "./thunks";
import { useAppDispatch } from "store";
import { batch } from "react-redux";
import { setSelectedSiteTab, setSelectedSite } from "store/siteManagement";

export const useHandleContextMenuActions = (siteId: string) => {
  const dispatch = useAppDispatch();

  const { anchorEl, handleOpenContextMenu, handleCloseContextMenu } =
    useContextMenu();

  const handleDeleteSite = () => {
    dispatch(deleteSiteById(siteId));
    handleCloseContextMenu();
  };

  const handleEditSite = () => {
    batch(() => {
      dispatch(setSelectedSite(siteId));
      dispatch(setSelectedSiteTab("Details"));
    });
  };

  return {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
    handleDeleteSite,
    handleEditSite,
  };
};
