import React from "react";
import { NavLink } from "react-router-dom";
import { EndDatesTable } from "../../components/EndDatesTable";
import { SimulationResult } from "helpers";

interface SimulationResultsProps {
  simulationResult: SimulationResult;
}

export const SimulationResults: React.FC<SimulationResultsProps> = ({ simulationResult }) => {
  return (
    <div className="flex justify-center mt-20">
      <div className="w-4/5 flex flex-col items-center">
        <div className="flex items-center justify-between mb-10 w-full">
          {/* First row for the simulation results and link */}
          <span className="text-3xl">Simulation results</span>
          <NavLink
            to={"../plan"}
            className="hidden sm:inline-block text-black py-[0.4rem] px-8 underline hover:text-black active:relative active:top-[1px]"
          >
            View summary report
          </NavLink>
        </div>
        <div className="w-full"> {/* Second row for the EndDatesTable */}
          <EndDatesTable
            simulationPlanResult={simulationResult.planResults}
            enableSubColumn2={false}
          />
        </div>
      </div>
    </div>
  );
};