//Types
import { IHistoricThresholdsKey } from "types";

export const formatTableTitle = (
  historicThresholdKey: IHistoricThresholdsKey
) => {
  if (historicThresholdKey === "cumRainfall")
    return "Cumulative Rainfall Per Month";
  else if (historicThresholdKey === "daysRainAbove5mm")
    return "Number of Days With Rainfall Above 5 mm";
  else if (historicThresholdKey === "daysTempBelow0C")
    return "Number of Days with Temperature Below 0°C";
  else if (historicThresholdKey === "maxTemp") return;
};
