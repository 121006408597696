import { useMemo } from "react";
import {
  ITaskCategory,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";

// DH TODO : make this more dynamic and not reliant on adding thresholds.
export const useCompareThresholds = (
  {
    wind,
    dailyRainAcc,
    hourlyRainAcc,
    maxTemp,
    minTemp,
    windGusts,
    waveHeight,
    snowfall,
    snowfall24Hour,
    visibility,
  }: WeatherThresholds,
  taskType: string,
  taskCategoryMap: Record<string, ITaskCategory>
) =>
  useMemo(() => {
    if (!taskType || !taskCategoryMap || !taskCategoryMap[taskType]) {
      return false;
    }

    const {
      wind: categoryWind,
      dailyRainAcc: categoryDailyRainAcc,
      hourlyRainAcc: categoryHourlyRainAcc,
      maxTemp: categoryMaxTemp,
      minTemp: categoryMinTemp,
      waveHeight: categoryWaveHeight,
      windGusts: categoryWindGusts,
      snowfall: categorySnowfall,
      snowfall24Hour: categorySnowfall24Hour,
      visibility: categoryVisibility,
    } = taskCategoryMap[taskType]?.thresholds;

    return !(
      wind === categoryWind &&
      dailyRainAcc === categoryDailyRainAcc &&
      hourlyRainAcc === categoryHourlyRainAcc &&
      maxTemp === categoryMaxTemp &&
      minTemp === categoryMinTemp &&
      waveHeight === categoryWaveHeight &&
      windGusts === categoryWindGusts &&
      snowfall === categorySnowfall &&
      snowfall24Hour === categorySnowfall24Hour &&
      visibility === categoryVisibility
    );
  }, [
    taskType,
    taskCategoryMap,
    wind,
    dailyRainAcc,
    hourlyRainAcc,
    maxTemp,
    minTemp,
    windGusts,
    waveHeight,
    snowfall,
    snowfall24Hour,
    visibility,
  ]);
