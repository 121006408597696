import { isOldFormatId } from "./constants";

export const getPlanTaskId = (taskId: string, planId: string | undefined) =>
  isOldFormatId(taskId) ? taskId : planId + "$$" + taskId.split("$$")[1];

export const wbsPaddingLevel = (level: number | undefined) =>
  level && level > 0 ? { paddingLeft: `${level * 2 + 1}rem` } : {};

export const yieldMainThread = (delay = 0) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};