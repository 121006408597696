import { AppThunk } from "store";
import { UserManagementTabOption } from "types";
import { IUser } from "@ehabitation/ts-utils/browser";
import { setError, setLoading, setSelectedTab } from "store/userManagement";
import { batch } from "react-redux";
import { createNewUser, updateUser } from "./helpers";

export const createNewUserFromForm: AppThunk =
  (userDetails: IUser, isFromOrgForm?: boolean) => async (dispatch) => {
    dispatch(setLoading("Creating the new user..."));

    try {
      await createNewUser(userDetails);
      batch(() => {
        dispatch(setLoading(""));
        dispatch(setSelectedTab(UserManagementTabOption.Users));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);

      batch(() => {
        dispatch(setLoading(""));
        dispatch(setError(error.message)); //shows a toast to the user
      });

      if (isFromOrgForm) {
        throw error;
      }
    }
  };

export const updateUserDetails: AppThunk =
  (userDetails: IUser) => async (dispatch) => {
    dispatch(setLoading("Updating the user's details..."));

    try {
      await updateUser(userDetails);

      batch(() => {
        dispatch(setLoading(""));
        dispatch(setSelectedTab(UserManagementTabOption.Users));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setLoading(""));
        dispatch(setError(error.message)); //shows a toast to the user
      });
    }
  };
