import {
  Action,
  ActionCreator,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { FC } from "react";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "store/auth/authSlice";
import projectReducer from "store/project/projectSlice";
import taskReducer from "store/tasks/tasksSlice";
import uiReducer from "store/ui/uiSlice";
import calendarReducer from "store/calendar/calendarSlice";
import taskCategoryReducer from "store/taskCategory/taskCategorySlice";
import menuReducer from "store/menu/menuSlice";
import tabsReducer from "store/tabs/tabsSlice";
import tornadoReducer from "store/tornado/tornadoSlice";
import userManagementReducer from "store/userManagement/userManagementSlice";
import siteManagementReducer from "store/siteManagement/siteManagementSlice";
import projectManagementReducer from "store/projectManagement/projectManagementSlice";
import divisionManagementReducer from "store/divisionManagement/divisionManagementSlice";
import forecastReducer from "store/forecast/forecastSlice";
import searchReducer from "store/search/searchSlice";
import unitReducer from "store/unit/unitSlice";

export const rootReducer = {
  auth: authReducer,
  project: projectReducer,
  task: taskReducer,
  ui: uiReducer,
  calendar: calendarReducer,
  taskCategory: taskCategoryReducer,
  menu: menuReducer,
  tabs: tabsReducer,
  tornado: tornadoReducer,
  userManagement: userManagementReducer,
  siteManagement: siteManagementReducer,
  projectManagement: projectManagementReducer,
  divisionManagement: divisionManagementReducer,
  forecast: forecastReducer,
  search: searchReducer,
  unit: unitReducer,
};

/* 
N.B : configureStore's defaults include middleware that checks for non-serializable values as well as mutations. We have problems with both.

1. Non-serializable values - we are storing date objects everywhere.
2. Mutation - the gantt library is mutating the state of tasks directly.

I have disabled these middlewares, but if you would like to use them to debug these issues, please use configureStore as follows:

export const store = configureStore({
  reducer: rootReducer,
});

I sugggest these issues get tackled during a cooldown.
*/

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch | AppThunk;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ActionCreator<
  ThunkAction<void, RootState, unknown, Action<string>>
>;

const ReduxStoreProvider: FC = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export { useAppDispatch, useAppSelector } from "./hooks";

export default ReduxStoreProvider;
