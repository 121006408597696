import { useCallback } from "react";
import {
  SimulationType,
  SimulationTrigger,
  isGuest,
  ISite,
} from "@ehabitation/ts-utils/browser";
import { useAppDispatch, useAppSelector } from "store";
import _ from "lodash";
import { selectActiveSiteId, selectRole, selectUserId } from "store/auth";
import {
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import { logSimulationTriggeredEvent } from "helpers/analytics";
import { selectOrganisationSites } from "store/siteManagement";

export const useTriggerSimulation = () => {
  const dispatch = useAppDispatch();

  const siteId = useAppSelector(selectActiveSiteId);
  const userId = useAppSelector(selectUserId);

  const userRole = useAppSelector(selectRole);
  const organisationSites = useAppSelector(selectOrganisationSites);
  const site = organisationSites[siteId!] as ISite;

  const handleTriggerSimulation = useCallback(
    async (planId: string) => {

      if (isGuest(userRole!)) {
        // TODO : Raise alert.
        console.warn("guests cannot simulate");
        return;
      }

      const q = query(
        collection(db, `plans/${planId}/simulations`),
        where("status", "not-in", ["site_pending", "error", "complete"]),
        limit(1)
      );

      const existingSimulations = await getDocs(q);

      if (existingSimulations.empty) {
        await triggerSimulation(planId!, siteId!, userId!, site);
      } else {
        existingSimulations.forEach((s) => {
          console.warn("incomplete simulations found ", s.id);
        });
      }
    },
    [dispatch, siteId, userRole]
  );

  return {
    triggerSimulation: (planId: string) => handleTriggerSimulation(planId),
  };
};

export const triggerSimulation = async (
  planId: string,
  siteId: string,
  userId: string,
  site: ISite
) => {
  const simulation = {
    plan: planId,
    site: siteId,
    createdAt: Timestamp.now(),
    version: 2,
    status: "pending",
    trigger: {
      type: SimulationType.User,
      userId,
    } as SimulationTrigger,
    historicalSourceId: site?.historicalInUse?.[0],
  };

  const { id } = await addDoc(
    collection(db, `plans/${planId}/simulations`),
    simulation
  );
  logSimulationTriggeredEvent(planId, siteId, id);
};
