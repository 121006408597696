import { FC } from "react";

interface HeatmapRowProps {
  data: Record<string, number>;
  className?: string;
  isPrintable?: boolean;
}

export const HeatmapRow: FC<HeatmapRowProps> = ({
  data,
  className,
  isPrintable = false,
}) => {
  const totalWeeks = 52;

  const getHeatmapColor = (ratio: number): string => {
    if (ratio >= 0.8) return isPrintable ? "border-red-600" : "bg-red-600";
    if (ratio >= 0.6) return isPrintable ? "border-red-200" : "bg-red-200";
    if (ratio >= 0.4)
      return isPrintable ? "border-yellow-400" : "bg-yellow-400";
    if (ratio >= 0.2) return isPrintable ? "border-green-200" : "bg-green-200";
    if (ratio >= 0.0) return isPrintable ? "border-green-400" : "bg-green-400";
    return isPrintable ? "border-gray-300" : "bg-gray-300";
  };

  return (
    <div className={`flex ${className}`}>
      {Array.from({ length: totalWeeks }).map((_, index) => {
        const weekNumber = (index + 1).toString();
        const probability = data[weekNumber];

        const colorClass =
          probability !== undefined
            ? getHeatmapColor(probability)
            : isPrintable
            ? "border-white"
            : "bg-white";

        const borderClass =
          probability !== undefined ? "border-black" : "border-gray-300";

        return (
          <div
            key={weekNumber}
            className={`w-[1.92%] h-8 grid place-items-center ${colorClass} border border-[0.5px] ${borderClass} mr-0.5`}
            title={`Week ${weekNumber}: ${
              probability !== undefined ? probability * 100 : 0
            }%`}
          >
            {probability !== undefined && (
              <span
                className={`text-xs ${
                  probability >= 0.8 ? "text-red-100" : ""
                }`}
              >
                {probability < 1
                  ? probability.toFixed(2).replace(/^0/, "")
                  : "1"}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
