import { CollectionType, ISite } from "@ehabitation/ts-utils/browser";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  IIBMDailyForecast,
  IIBMForecastDocument,
} from "firestoreTypes/IBMForecast";
import { IForecastDocument } from "types";
import { db } from "firebaseConfig";

const IBMForecastFromFirebaseConverter = (
  IBMData: IIBMForecastDocument,
  IBMDailyForecasts: IIBMDailyForecast[]
): IForecastDocument => {
  return {
    createdAt: IBMData.createdAt.toDate(),
    sourceRef: IBMData.sourceRef,
    dailyForecasts: IBMDailyForecasts.map((IBMDailyForecast: any) => ({
      ...IBMDailyForecast,
      dateReference: IBMDailyForecast.dateReference.toDate(),
    })).sort(
      (a: any, b: any) => a.dateReference.getTime() - b.dateReference.getTime()
    ),
  };
};

export const fetchSiteForecast = async (
  siteId: string
): Promise<IForecastDocument> => {
  const siteSnapshotRef = await getDoc(doc(db, CollectionType.Sites, siteId));
  const site = siteSnapshotRef.data() as ISite;
  if (!site.forecastInUse || site.forecastInUse.length === 0) {
    throw new Error("NO_SITE_FORECAST_IN_USE");
  }
  const forecastId = site.forecastInUse[0];
  const forecastSnapshot = await getDocs(
    query(
      collection(db, CollectionType.IBMForecasts),
      where("sourceRef", "==", forecastId),
      orderBy("createdAt", "desc"),
      limit(1)
    )
  );
  if (forecastSnapshot.docs.length === 0) {
    throw new Error("NO_FORECAST_DATA");
  }
  const forecastDoc = forecastSnapshot.docs[0];
  const forecastData = forecastDoc.data() as IIBMForecastDocument;
  const forecastDataRef = await getDocs(
    collection(forecastDoc.ref, "dailyForecasts")
  );
  const dailyForecasts = forecastDataRef.docs.map(
    (dailyForecast) => dailyForecast.data() as IIBMDailyForecast
  );
  if (dailyForecasts.length === 0) {
    throw new Error("FORECAST_EMPTY");
  }

  return IBMForecastFromFirebaseConverter(forecastData, dailyForecasts);
};
