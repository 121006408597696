import { FC } from "react";
import { FiX } from "react-icons/fi";

interface ModalProps {
  id: string;
  handleCloseModal?: Function;
  className?: string;
  title?: string;
  subTitle?: string;
  xl?: boolean;
}

const ModalRoot: FC<ModalProps> = ({
  id,
  handleCloseModal,
  className = "",
  title = "",
  subTitle = "",
  children,
  xl = false,
}) => {
  return (
    <div
      className="fixed flex justify-center inset-0 items-center"
      style={{
        opacity: 1,
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        zIndex: 100,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        className={`${
          xl ? "h-[95vh] w-[95vw]" : "h-[80vh] w-[95vw] max-w-screen-xl"
        } overflow-hidden items-center flex justify-center m-8 rounded relative bg-white border-r ${className}`}
        style={{
          boxShadow:
            "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        }}
        aria-labelledby={`${id}-modal`}
        data-testid={`${id}-modal`}
      >
        {handleCloseModal && (
          <button
            data-testid={id + "-close-button"}
            className="top-8 right-8 absolute text-5xl pointer rounded-lg bg-gray-200 enabled:hover:bg-gray-100"
            onClick={() => handleCloseModal()}
          >
            <FiX />
          </button>
        )}
        <div className="h-full w-full p-16">
          {title ? (
            <div className="absolute top-6 left-8 flex gap-4 items-baseline">
              <h1 data-testid={`${id}-title`}>{title}</h1>{" "}
              {subTitle ? (
                <h2 className="text-gray-500 italic">{subTitle}</h2>
              ) : null}
            </div>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};

export const Modal = Object.assign(ModalRoot, {});
