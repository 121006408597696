//React
import { useCallback } from "react";
//Store
import { useAppDispatch, useAppSelector } from "store";
import { selectCurrentTaskId } from "store/tasks";
import { updateTask } from "store/tasks";

export const useUpdateTaskConfidence = (level: string, proposedEnd: string) => {
  const dispatch = useAppDispatch();

  const currentTaskId = useAppSelector(selectCurrentTaskId);

  const handleUpdateTaskConfidence = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!currentTaskId) return;

      const update = {
        accuracy: Number(level) as number,
        hasSafetyChanged: "true", // If accuracy has changed in the front-end, we use this flag to trigger a client-side model update via the gantt library.
        safetyEnd: proposedEnd,
      };

      dispatch(updateTask({ id: currentTaskId, update }));

      e.currentTarget.blur();
    },
    [dispatch, currentTaskId, level, proposedEnd]
  );

  return { handleUpdateTaskConfidence };
};
