import React from "react";
import { StyledTableHeader } from "./styles";

const DivisionsHeader = () => {
  return (
    <StyledTableHeader data-testid="divisions-header">
      <p className="name">Name</p>
      <p className="projects">Projects</p>
      <p className="sites">Sites</p>
      <p className="users">Users</p>
      <p className="visibility">Visibility</p>
      <p className="icon"></p>
    </StyledTableHeader>
  );
};

export default DivisionsHeader;
