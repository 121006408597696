//React
import React, { FC, useMemo } from "react";
//Styles
import { StyledRiskProfile } from "./styles";
import { colors } from "styles";
//Store
import { useAppSelector } from "store";
import {
  selectActiveRiskProfile,
  selectBaseRiskProfile,
  selectIsInCriticalPath,
} from "store/selectors";
import { useUpdateTaskConfidence } from "./useUpdateTaskConfidence";

interface IProps {
  profile: {
    level: string;
    daysOver: number;
    proposedEnd: string;
    WBS: boolean;
    daysPastProjectDeadline: number;
  };
  index: number;
}

const RiskProfile: FC<IProps> = ({ profile, index }) => {
  const { level, daysOver, proposedEnd, WBS, daysPastProjectDeadline } =
    profile;

  const activeRiskProfile = useAppSelector(selectActiveRiskProfile);
  const baseRisk = useAppSelector(selectBaseRiskProfile);
  const isInCriticalPath = useAppSelector(selectIsInCriticalPath);

  const { handleUpdateTaskConfidence } = useUpdateTaskConfidence(
    level,
    proposedEnd
  );

  const color = useMemo(() => {
    const levelNumber = parseInt(level);
    let color = colors.ganttGray;
    if (levelNumber < 50 && (index === 1 || WBS)) color = colors.ganttRed;
    if (levelNumber > 49 || (levelNumber < 50 && index === 2))
      color = colors.ganttYellow;
    if (levelNumber > 69) color = colors.ganttGreen;
    if (level === baseRisk && !WBS) color = colors.ganttGray; //overwrites to gray if level is same as base risk

    return color;
  }, [level, baseRisk, index, WBS]);

  const isActive = useMemo(() => {
    if (level === activeRiskProfile && !WBS) return true;
    return false;
  }, [activeRiskProfile, level, WBS]);

  return (
    <StyledRiskProfile
      className={isActive ? "active" : ""}
      isActive={isActive}
      confidenceColor={color}
      onClick={!WBS ? handleUpdateTaskConfidence : () => {}}
      WBS={WBS}
      data-testid={`risk-profile-button-${level}`}
    >
      <p className={`header`}>{level}%</p>
      <p>{proposedEnd}</p>
      <p
        className={`
        ${WBS && "disabled"}
     `}
      >
        {!WBS ? daysOver : "-"}
      </p>
      <p>{daysPastProjectDeadline}</p>
      <p>{isInCriticalPath}</p>
    </StyledRiskProfile>
  );
};

export default RiskProfile;
