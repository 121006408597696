import {
  SimulationLevel,
  SimulationPlanResult,
  SimulationResult,
  SimulationTaskResult,
} from "helpers";
import moment from "moment";
import { FC, Fragment, useEffect } from "react";
import { BsInfoCircle } from "react-icons/bs";

import {
  DifferenceCell,
  DifferenceDateCell,
} from "../components/DifferenceCell";
import { impactMitigationLevels } from "../constants";
import { IPlan, ITask } from "@ehabitation/ts-utils/browser";

interface EndDatesTableProps {
  plan?: IPlan;
  selectedSimLevel?: string;
  setSelectedSimLevel?: React.Dispatch<React.SetStateAction<SimulationLevel>>;
  simulationPlanResult?: SimulationPlanResult;
  mitigationSimulationResult?: SimulationResult;
  selectedMilestoneResult?: ITask & { simulation: SimulationTaskResult };
  chartTitle?: string;
  subColumn1Title?: string;
  subColumn2Title?: string;
  enableSubColumn2?: boolean;
}

export const EndDatesTable: FC<EndDatesTableProps> = ({
  plan,
  selectedSimLevel,
  setSelectedSimLevel,
  simulationPlanResult,
  mitigationSimulationResult,
  selectedMilestoneResult,
  chartTitle = "Pre and Post Mitigation scenarios",
  subColumn1Title = "Pre",
  subColumn2Title = "Post",
  enableSubColumn2 = true,
}) => {
  let plannedEndDate = "-";

  if (selectedMilestoneResult && selectedSimLevel) {
    plannedEndDate = moment(selectedMilestoneResult?.end).format("D/M/YY");
  } else if (simulationPlanResult?.plannedEndDate) {
    plannedEndDate = moment(simulationPlanResult?.plannedEndDate).format(
      "D/M/YY"
    );
  } else if (plan?.end) {
    plannedEndDate = moment(plan.end).format("D/M/YY");
  }

  useEffect(() => {
    console.info("selectedMilestoneResult", selectedMilestoneResult);
  }, []);

  return (
    <div className="px-4 pt-4 divide-y-2">
      <div className="flex justify-between pb-2">
        <div className="flex gap-2 items-end">
          <span className="truncate flex-shrink-0 text-3xl">End Dates</span>
          <span className="truncate text-lg">(Pre and Post Mitigation)</span>
        </div>
        <div>
          <div className="flex justify-between print:hidden">
            <BsInfoCircle className="mt-1" />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center p-4">
        {selectedSimLevel && (
          <div>
            <div>Displaying</div>
            <div className="text-7xl font-thin whitespace-nowrap">
              {
                impactMitigationLevels.find(
                  (level) => level.key === selectedSimLevel
                )?.label
              }
            </div>
          </div>
        )}
        <table className="mb-8 w-full !border-0">
          <thead className="z-10">
            <tr>
              <th className="text-end px-4">{chartTitle}</th>
              <th className="border bg-gray-50">
                <span className="align-middle">Planned</span>
              </th>
              {impactMitigationLevels.map(({ key, label }) => (
                <th
                  key={key}
                  className={`border px-6 hover:font-bold ${
                    selectedSimLevel === key
                      ? "font-bold bg-[#86EFAC]"
                      : "bg-gray-50 hover:bg-[#d9f8ec] "
                  }
                      ${setSelectedSimLevel ? "cursor-pointer" : ""}
                  }`}
                  onClick={() =>
                    setSelectedSimLevel ? setSelectedSimLevel(key) : undefined
                  }
                  colSpan={enableSubColumn2 ? 2 : 1}
                >
                  {selectedSimLevel && (
                    <input
                      readOnly
                      className="mr-2 align-middle cursor-pointer accent-black"
                      type="radio"
                      value={key}
                      checked={selectedSimLevel === key}
                      name={key}
                    />
                  )}
                  <span className="align-middle">{label}</span>
                </th>
              ))}
            </tr>
            {enableSubColumn2 && (
              <tr>
                <th className="text-xl font-normal"></th>
                <th className="border text-xl font-normal col-span-2"></th>
                {impactMitigationLevels.map(({ key }) => (
                  <Fragment key={`header_${key}`}>
                    <th
                      key={`header_${key}_pre`}
                      className="bg-gray-50 border text-xl font-normal"
                    >
                      {subColumn1Title}
                    </th>
                    <th
                      key={`header_${key}_post`}
                      className="bg-gray-50 border text-xl font-normal"
                    >
                      {subColumn2Title}
                    </th>
                  </Fragment>
                ))}
              </tr>
            )}
          </thead>
          <tbody className="text-center">
            <tr>
              <td className="text-end px-4">
                {selectedMilestoneResult ? "Milestone" : "Project"} end date
              </td>
              <td className="text-center border pl-4 pr-4">{plannedEndDate}</td>
              {impactMitigationLevels.map(({ key }) => (
                <Fragment key={`date_${key}`}>
                  <td
                    key={`date_${key}_pre`}
                    className={`pl-4 pr-4 border ${
                      selectedSimLevel === key ? "font-bold" : ""
                    }`}
                  >
                    {selectedMilestoneResult
                      ? moment(
                          selectedMilestoneResult?.simulation
                            .riskIntervalResults?.[key]?.endDate
                        ).format("D/M/YY")
                      : moment(
                          simulationPlanResult?.riskIntervalResults?.[key]
                            ?.finishDate
                        ).format("D/M/YY")}
                  </td>
                  {enableSubColumn2 && (
                    <td
                      key={`date_${key}_post`}
                      className={`border relative ${
                        selectedSimLevel === key ? "font-bold" : ""
                      }`}
                    >
                      {!simulationPlanResult ? (
                        "?"
                      ) : simulationPlanResult?.riskIntervalResults?.[key]
                          ?.finishDate ? (
                        <DifferenceDateCell
                          base={
                            simulationPlanResult?.riskIntervalResults?.[key]
                              ?.finishDate
                          }
                          mitigated={
                            mitigationSimulationResult?.planResults
                              .riskIntervalResults?.[key]?.finishDate
                          }
                        />
                      ) : null}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>
            <tr className="text-end px-4">
              <td className="text-end px-4 font-semibold">
                Weather Working Days
              </td>
              <td className="text-end px-4  col-span-12">&nbsp;</td>
            </tr>
            <tr>
              <td className="text-end px-4">
                {/* {selectedMilestoneResult
                  ? "Milestone delay"
                  :  */}
                {"In Critical Paths"}
              </td>
              <td className="text-center px-4 border">-</td>
              {impactMitigationLevels.map(({ key }) => (
                <Fragment key={`weather_${key}`}>
                  <td
                    key={`weather_${key}_pre`}
                    className={`border relative ${
                      selectedSimLevel === key ? "font-bold" : ""
                    }`}
                  >
                    {selectedMilestoneResult?.simulation.riskIntervalResults?.[
                      key
                    ]?.weatherDays ||
                      (simulationPlanResult?.riskIntervalResults?.[key]
                        ?.weatherDays ??
                        0)}
                  </td>
                  {enableSubColumn2 && (
                    <td
                      key={`weather_${key}_post`}
                      className={`border relative ${
                        selectedSimLevel === key ? "font-bold" : ""
                      }`}
                    >
                      {!simulationPlanResult ? (
                        "?"
                      ) : (
                        <DifferenceCell
                          base={
                            simulationPlanResult?.riskIntervalResults?.[key]
                              ?.weatherDays || 0
                          }
                          mitigated={
                            (mitigationSimulationResult?.planResults
                              .riskIntervalResults?.[key]?.weatherDays || 0) +
                            (mitigationSimulationResult?.planResults
                              .riskIntervalResults?.[key]?.floats || 0)
                          }
                        />
                      )}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>

            <tr>
              <td className="text-end px-4">
                <div>Total for the project</div>
              </td>
              <td className="text-center px-4 border">-</td>
              {impactMitigationLevels.map(({ key }) => (
                <Fragment key={`weather_${key}`}>
                  <td
                    key={`weather_${key}_pre`}
                    className={`border relative ${
                      selectedSimLevel === key ? "font-bold" : ""
                    }`}
                  >
                    {simulationPlanResult?.riskIntervalResults?.[key]
                      ?.weatherDaysPerQuantile || "-"}
                  </td>
                  {enableSubColumn2 && (
                    <td
                      key={`weather_${key}_post`}
                      className={`border relative ${
                        selectedSimLevel === key ? "font-bold" : ""
                      }`}
                    >
                      {!simulationPlanResult ? (
                        "?"
                      ) : (
                        <DifferenceCell
                          base={
                            simulationPlanResult?.riskIntervalResults?.[key]
                              ?.weatherDaysPerQuantile || "-"
                          }
                          mitigated={
                            mitigationSimulationResult?.planResults
                              .riskIntervalResults?.[key]
                              ?.weatherDaysPerQuantile || "-"
                          }
                        />
                      )}
                    </td>
                  )}
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
