//Validation
import * as yup from "yup";

/* 
Regex taken from Stack Overflow 
https://stackoverflow.com/questions/3518504/regular-expression-for-matching-latitude-longitude-coordinates
Answer 128
*/
const LATITUDE_REGEX =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/;
const LONGITUDE_REGEX =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/;

export const newSiteSchema = yup.object().shape({
  name: yup.string().required("Please provide a name for the site"),
  division: yup.string().required("Please provide a division"),
  project: yup.string().required("Please provide a project"),
  lat: yup
    .string()
    .required("Please provide a latitude")
    .matches(LATITUDE_REGEX, "Please provide valid coordinates"),
  long: yup
    .string()
    .required("Please provide a longitude")
    .matches(LONGITUDE_REGEX, "Please provide valid coordinates"),
  startDate: yup
    .date()
    .required("Please provide a start date")
    .max(yup.ref("endDate"), "Start date must be earlier than end date"),
  endDate: yup
    .date()
    .required("Please provide an end date")
    .min(yup.ref("startDate"), "End date must be later than start date"),
  description: yup.string().required("Please provide a site description"),
});
