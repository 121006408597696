import { INewSiteForm, ISite } from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers";

export const createNewSite = async (newSite: INewSiteForm) => {
  const callCreateNewSite = firebaseFunction<{
    result: string;
    siteId: string;
  }>("createNewSite");
  const result = await callCreateNewSite(newSite);
  return result.data?.siteId;
};

export const updateSite = async (updatedSite: INewSiteForm) => {
  const callUpdateSite = firebaseFunction("updateSite");
  const { lat, long, division, orgId, project, ...updateSiteFields } =
    updatedSite;
  await callUpdateSite(updateSiteFields);
};
