import React, { FC, memo } from "react";

interface IProps {
  taskCode: string;
  isWBS: boolean;
}

const TaskCodeCell: FC<IProps> = ({ taskCode, isWBS }) => {
  return (
    <div className="cell-task-code" data-testid="cell-task-code">
      {!isWBS ? taskCode : ""}
    </div>
  );
};

export default memo(TaskCodeCell);
