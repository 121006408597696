import { FC, useEffect, useMemo, useState } from "react";
import {
  Input,
  LoadingWrapper,
  LoadingType,
  MonochromeButton,
  Spinner,
} from "@ehabitation/ui";
import { getStaticMapUrl, usePlace } from "helpers/location";
import { useIsMounted } from "hooks";
import { useAppSelector } from "store";
import { selectUserClaims, selectUserId } from "store/auth";
import { roundTo6DP } from "helpers";
import { UserRole } from "@ehabitation/ts-utils/browser";
import {
  LocationSearch,
  createNewSite,
  getNameFromPlace,
  newSiteSchema,
} from "../create";
import { createNewProject } from "Components/ProjectManagement/ProjectForm/helpers";
import { createNewDivision } from "Components/DivisionManagement/NewDivision/helpers";

const nbsp = String.fromCharCode(160);

export const InlineNewSiteForm: FC<{
  handleSiteCreated: (newSiteId: string) => void;
  handleCancel?: () => void;
  projectId?: string;
  divisionId?: string;
  disabled?: boolean;
}> = ({ handleSiteCreated, handleCancel, projectId, divisionId, disabled }) => {
  const userId = useAppSelector(selectUserId);
  const claims = useAppSelector(selectUserClaims);

  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [userLatitude, setUserLatitude] = useState<string>("");
  const [userLongitude, setUserLongitude] = useState<string>("");
  const [userPlace, setUserPlace] = useState<any>();
  const [siteParseResult, setSiteParseResult] = useState<any>();
  const [name, setName] = useState<string>("");
  const [siteCode, setSiteCode] = useState<string>("");

  const { place: latLongPlace, isLoading: isLoadinglatLongPlace } = usePlace(
    !userPlace ? userLatitude : "",
    !userPlace ? userLongitude : ""
  );

  const { latitude, longitude, address } = useMemo(() => {
    const targetPlace = userPlace || latLongPlace;
    if (targetPlace) {
      if (!name) {
        setName(getNameFromPlace(targetPlace));
      }
      return {
        latitude: roundTo6DP(
          typeof targetPlace.geometry?.location?.lat === "function"
            ? targetPlace.geometry?.location?.lat()
            : targetPlace.geometry?.location?.lat
        ),
        longitude: roundTo6DP(
          typeof targetPlace.geometry?.location?.lng === "function"
            ? targetPlace.geometry?.location?.lng()
            : targetPlace.geometry?.location?.lng
        ),
        address: targetPlace.formatted_address,
      };
    } else {
      return {};
    }
  }, [userPlace, latLongPlace]);

  useEffect(() => {
    const result = newSiteSchema.safeParse({
      name: name,
      location: { lat: latitude, long: longitude },
      address,
      siteCode,
    });
    setSiteParseResult(result);
  }, [name, latitude, longitude, address]);

  return (
    <form
      onFocusCapture={(e) => {
        e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }}
      className="flex flex-col gap-6 w-full max-w-[48rem] md:max-w-[96rem]"
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        createNewSite(
          { ...siteParseResult.data, visibility: UserRole.planner },
          userId!,
          claims!.organisation!,
          projectId,
          divisionId
        )
          .then((newSiteId: any) => {
            handleSiteCreated(newSiteId);
          })
          .catch((error) => {
            console.error(error);
            alert("Error creating site. Please refresh page and try again.");
          })
          .finally(() => {
            isMounted() && setIsLoading(false);
          });
      }}
    >
      <div className="flex flex-col items-stretch md:flex-row md:items-start w-full gap-6">
        <div className="grow max-w-[48rem] flex flex-col gap-6 items-stretch">
          <Input
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            placeholder="Site Name"
            value={name}
            paddingSize="small"
          />
          {projectId ? (
            <Input
              onChange={(e) => {
                setSiteCode(e.target.value);
              }}
              placeholder="Site Code (optional)"
              value={siteCode}
              paddingSize="small"
            />
          ) : null}
        </div>
        <div className="grow max-w-[48rem] flex flex-col gap-4">
          <div className="grow">
            <LocationSearch
              location={{
                latitude: latitude,
                longitude: longitude,
              }}
              address={address}
              setPlace={(place?: any) => {
                if (place) {
                  setUserLatitude(
                    String(roundTo6DP(place.geometry?.location?.lat()))
                  );
                  setUserLongitude(
                    String(roundTo6DP(place.geometry?.location?.lng()))
                  );
                } else {
                  setUserLatitude("");
                  setUserLongitude("");
                }
                setUserPlace(place);
              }}
              isCompact={true}
            />
          </div>
          <div
            className="grow bg-center bg-slate-300 flex items-start gap-4 relative"
            style={
              !isLoadinglatLongPlace && latitude && longitude
                ? {
                    backgroundImage: `url(${getStaticMapUrl(
                      latitude,
                      longitude
                    )})`,
                  }
                : {}
            }
          >
            <div className="p-2 flex flex-col gap-4 w-full">
              <div className="p-2 flex flex-col sm:flex-row gap-4">
                <Input
                  onChange={(e) => {
                    setUserPlace(undefined);
                    setUserLatitude(e.target.value);
                  }}
                  placeholder="Latitude"
                  value={userLatitude}
                  className="flex-grow"
                  paddingSize="small"
                />

                <Input
                  onChange={(e) => {
                    setUserPlace(undefined);
                    setUserLongitude(e.target.value);
                  }}
                  value={userLongitude}
                  placeholder="Longitude"
                  className="flex-grow"
                  paddingSize="small"
                />
              </div>
              {!userPlace &&
                (userLatitude && userLongitude ? (
                  <div className="w-full grow grid place-content-center p-2">
                    <LoadingWrapper
                      type={LoadingType.subscriptions}
                      loading={isLoadinglatLongPlace}
                      message=""
                    >
                      {latLongPlace ? (
                        <p className="p-2 py-4">{nbsp}</p>
                      ) : (
                        <p className="p-2 py-4 bg-gray-50">
                          Unable to locate provided lat/long
                        </p>
                      )}
                    </LoadingWrapper>
                  </div>
                ) : null)}
            </div>
          </div>
        </div>
      </div>
      <div className="grow flex gap-4 justify-end items-center">
        {isLoading ? (
          <div className="flex items-center gap-1 text-gray-500">
            <Spinner size="xSmall" />
            Creating Site...
          </div>
        ) : null}
        <div className="flex gap-4 items-center">
          <MonochromeButton
            disabled={!siteParseResult?.success || isLoading || !!disabled}
            type="submit"
            className="self-center"
            level="primary"
          >
            Submit
          </MonochromeButton>
          {handleCancel && (
            <MonochromeButton
              onClick={() => handleCancel()}
              disabled={isLoading}
              className="self-center"
              level="secondary"
            >
              Cancel
            </MonochromeButton>
          )}
        </div>
      </div>
    </form>
  );
};

export const InlineNewProjectForm: FC<{
  handleProjectCreated: (newProjectId: string) => void;
  handleSiteCreated: (newSiteId: string) => void;
  handleCancel?: () => void;
  projectId?: string;
  divisionId?: string;
  disabled?: boolean;
}> = ({
  handleProjectCreated: handleProjectCreated,
  handleSiteCreated,
  handleCancel,
  projectId,
  divisionId,
  disabled,
}) => {
  const userId = useAppSelector(selectUserId);
  const claims = useAppSelector(selectUserClaims);

  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [userLatitude, setUserLatitude] = useState<string>("");
  const [userLongitude, setUserLongitude] = useState<string>("");
  const [userPlace, setUserPlace] = useState<any>();
  const [siteParseResult, setSiteParseResult] = useState<any>();
  const [name, setName] = useState<string>("");
  const [siteName, setSiteName] = useState<string>("");
  const [projectCode, setProjectCode] = useState<string>("");

  const { place: latLongPlace, isLoading: isLoadinglatLongPlace } = usePlace(
    !userPlace ? userLatitude : "",
    !userPlace ? userLongitude : ""
  );

  const { latitude, longitude, address } = useMemo(() => {
    const targetPlace = userPlace || latLongPlace;
    if (targetPlace) {
      if (!siteName) {
        setSiteName(getNameFromPlace(targetPlace));
      }
      return {
        latitude: roundTo6DP(
          typeof targetPlace.geometry?.location?.lat === "function"
            ? targetPlace.geometry?.location?.lat()
            : targetPlace.geometry?.location?.lat
        ),
        longitude: roundTo6DP(
          typeof targetPlace.geometry?.location?.lng === "function"
            ? targetPlace.geometry?.location?.lng()
            : targetPlace.geometry?.location?.lng
        ),
        address: targetPlace.formatted_address,
      };
    } else {
      return {};
    }
  }, [userPlace, latLongPlace]);

  useEffect(() => {
    const result = newSiteSchema.safeParse({
      name,
      location: { lat: latitude, long: longitude },
      address,
    });
    setSiteParseResult(result);
  }, [name, latitude, longitude, address]);

  return (
    <form
      onFocusCapture={(e) => {
        e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }}
      className="flex flex-col gap-6 w-full max-w-[48rem] md:max-w-[96rem]"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const newProjectId = await createNewProject({
          name,
          projectCode,
          description: name,
          divisionId: divisionId!,
          orgId: claims!.organisation!,
          creationDate: new Date(),
          contractType: "",
          visibility: UserRole.planner,
        }).catch((error) => {
          console.error(error);
          alert("Error creating project. Please refresh page and try again.");
        });
        if (newProjectId) {
          handleProjectCreated(newProjectId!);
          createNewSite(
            {
              ...siteParseResult.data,
              name: siteName || name,
              visibility: UserRole.planner,
            },
            userId!,
            claims!.organisation!,
            newProjectId,
            divisionId
          )
            .then((newSiteId: any) => {
              handleSiteCreated(newSiteId);
            })
            .catch((error) => {
              console.error(error);
              alert(
                "Error creating project site. Please refresh page and try again."
              );
            })
            .finally(() => {
              isMounted() && setIsLoading(false);
            });
        } else {
          isMounted() && setIsLoading(false);
          alert("Error creating project. Please refresh page and try again.");
        }
      }}
    >
      <div className="flex flex-col items-stretch md:flex-row md:items-start w-full gap-6">
        <div className="grow max-w-[48rem] flex flex-col gap-6 items-stretch">
          <Input
            autoFocus
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Project Name"
            value={name}
            paddingSize="small"
          />
          {projectId ? (
            <Input
              onChange={(e) => {
                setProjectCode(e.target.value);
              }}
              placeholder="Project Code (optional)"
              value={projectCode}
              paddingSize="small"
            />
          ) : null}
        </div>
        <div className="grow max-w-[48rem] flex flex-col gap-4">
          <div className="grow">
            <LocationSearch
              location={{
                latitude: latitude,
                longitude: longitude,
              }}
              address={address}
              setPlace={(place?: any) => {
                if (place) {
                  setUserLatitude(
                    String(roundTo6DP(place.geometry?.location?.lat()))
                  );
                  setUserLongitude(
                    String(roundTo6DP(place.geometry?.location?.lng()))
                  );
                } else {
                  setUserLatitude("");
                  setUserLongitude("");
                }
                setUserPlace(place);
              }}
              isCompact={true}
            />
          </div>
          <div
            className="grow bg-center bg-slate-300 flex items-start gap-4 relative"
            style={
              !isLoadinglatLongPlace && latitude && longitude
                ? {
                    backgroundImage: `url(${getStaticMapUrl(
                      latitude,
                      longitude
                    )})`,
                  }
                : {}
            }
          >
            <div className="p-2 flex flex-col gap-4 w-full">
              <div className="p-2 flex flex-col sm:flex-row gap-4">
                <Input
                  onChange={(e) => {
                    setUserPlace(undefined);
                    setUserLatitude(e.target.value);
                  }}
                  placeholder="Latitude"
                  value={userLatitude}
                  className="flex-grow"
                  paddingSize="small"
                />

                <Input
                  onChange={(e) => {
                    setUserPlace(undefined);
                    setUserLongitude(e.target.value);
                  }}
                  value={userLongitude}
                  placeholder="Longitude"
                  className="flex-grow"
                  paddingSize="small"
                />
              </div>
              {!userPlace &&
                (userLatitude && userLongitude ? (
                  <div className="w-full grow grid place-content-center p-2">
                    <LoadingWrapper
                      type={LoadingType.subscriptions}
                      loading={isLoadinglatLongPlace}
                      message=""
                    >
                      {latLongPlace ? (
                        <p className="p-2 py-4">{nbsp}</p>
                      ) : (
                        <p className="p-2 py-4 bg-gray-50">
                          Unable to locate provided lat/long
                        </p>
                      )}
                    </LoadingWrapper>
                  </div>
                ) : null)}
            </div>
          </div>
        </div>
      </div>
      <div className="grow flex gap-4 justify-end items-center">
        {isLoading ? (
          <div className="flex items-center gap-1 text-gray-500">
            <Spinner size="xSmall" />
            Creating Project & Site...
          </div>
        ) : null}
        <div className="flex gap-4 items-center">
          <MonochromeButton
            disabled={!siteParseResult?.success || isLoading || disabled}
            type="submit"
            className="self-center"
            level="primary"
          >
            Submit
          </MonochromeButton>
          {handleCancel && (
            <MonochromeButton
              onClick={() => handleCancel()}
              disabled={isLoading}
              className="self-center"
              level="secondary"
            >
              Cancel
            </MonochromeButton>
          )}
        </div>
      </div>
    </form>
  );
};

export const InlineNewDivisionForm: FC<{
  handleDivisionCreated: (newSiteId: string) => void;
  handleCancel?: () => void;
  disabled?: boolean;
}> = ({ handleDivisionCreated, handleCancel, disabled }) => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState<string>("");

  return (
    <form
      onFocusCapture={(e) => {
        e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      }}
      className="flex flex-col gap-6 w-full max-w-[48rem] md:max-w-[96rem]"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        createNewDivision({
          name: name.trim(),
          visibility: UserRole.planner,
        })
          .then((newDivisionId: string) => {
            handleDivisionCreated(newDivisionId);
          })
          .catch((error) => {
            console.error(error);
            alert(
              "Error creating division. Please refresh page and try again."
            );
          })
          .finally(() => {
            isMounted() && setIsLoading(false);
          });
      }}
    >
      <div className="flex flex-col items-stretch md:flex-row md:items-start w-full gap-6">
        <div className="grow max-w-[48rem] flex flex-col gap-6 items-stretch">
          <Input
            autoFocus
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Division Name"
            value={name}
            paddingSize="small"
          />
        </div>
      </div>
      <div className="grow flex gap-4 justify-end items-center">
        {isLoading ? (
          <div className="flex items-center gap-1 text-gray-500">
            <Spinner size="xSmall" />
            Creating Division...
          </div>
        ) : null}
        <div className="flex gap-4 items-center">
          <MonochromeButton
            disabled={!name.trim().length || isLoading || disabled}
            type="submit"
            className="self-center"
            level="primary"
          >
            Submit
          </MonochromeButton>
          {handleCancel && (
            <MonochromeButton
              onClick={() => handleCancel()}
              disabled={isLoading}
              className="self-center"
              level="secondary"
              type="button"
            >
              Cancel
            </MonochromeButton>
          )}
        </div>
      </div>
    </form>
  );
};
