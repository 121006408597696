import { FC, useCallback, useMemo, useRef } from "react";
import { ITask } from "@ehabitation/ts-utils/browser";
import { BiTargetLock } from "react-icons/bi";
import { groupName } from "../pages/CategorySelectRow";
import { useScrollToFunction } from "../pages/PlanImportCategorySelection";
import { useVirtual } from "react-virtual";
import { getLevelBorderColorClass } from "@ehabitation/ui";

const getRowHeight = () => 26.2;

const tooltipClasses =
  "relative before:absolute before:shadow-lg before:font-normal before:bg-gray-300 before:z-10 before:content-tip before:right-0 before:top-[125%] before:border before:border-gray-600 before:mb-2 before:bg-white before:rounded-md before:px-2 hover:before:flex before:hidden";

const TaskList: FC<{
  allTasks: Map<string, ITask>;
  orderedTaskIds: string[];
  visibleTaskIds: Set<string>;
  highlightedTaskId: string;
  highlightTask: (id: string) => void;
  selectTask?: (id: string) => void;
  selectedTasks?: Set<string>;
  disabled?: boolean;
  className?: string;
  id: string;
}> = ({
  allTasks,
  orderedTaskIds,
  visibleTaskIds,
  highlightedTaskId,
  highlightTask,
  selectTask,
  selectedTasks,
  disabled,
  className = "",
  id,
}) => {
  const tasksContainerRef = useRef<HTMLDivElement>(null);
  const scrollToFunction = useScrollToFunction(tasksContainerRef);

  const {
    virtualItems: virtualRows,
    totalSize,
    scrollToIndex,
  } = useVirtual({
    size: orderedTaskIds.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(getRowHeight, []),
    overscan: 20,
    scrollToFn: scrollToFunction,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <div
      className={`min-h-0 overflow-y-auto ${className}`}
      ref={tasksContainerRef}
    >
      <div
        style={{
          height: totalSize,
        }}
      >
        <ol className="min-h-0 overflow-y-auto" id={id}>
          {paddingTop > 0 && <li style={{ height: `${paddingTop}px` }} />}
          {virtualRows.map((virtualRow) => {
            const taskId = orderedTaskIds[virtualRow.index];
            const task = allTasks.get(taskId);
            if (!task) throw new Error("Task not found");
            const visible = visibleTaskIds.has(task.id);
            return (
              <li
                className={`flex items-center gap-2 border-b border-gray-200 last:border-b-0 ${
                  task.WBS ? "font-[500]" : ""
                }`}
                key={taskId}
                data-testid="text-match"
              >
                {selectTask ? (
                  <>
                    <input
                      type="checkbox"
                      id={id + task.id}
                      data-testid="text-match-checkbox"
                      checked={selectedTasks?.has(task.id)}
                      onChange={() => {
                        selectTask(taskId);
                      }}
                      disabled={disabled || !visible}
                    />
                    <label
                      className={`ml-2 pl-2 border-l-2 flex items-center min-w-0 ${getLevelBorderColorClass(
                        task
                      )}`}
                      htmlFor={id + task.id}
                    >
                      {task.milestone ? (
                        <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                          M:
                        </div>
                      ) : task.levelOfEffort ? (
                        <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                          LOE:
                        </div>
                      ) : null}
                      <span className="truncate min-w-0">{task.title}</span>
                    </label>
                  </>
                ) : (
                  <div
                    className={`pl-2 border-l-2 flex items-center min-w-0 ${getLevelBorderColorClass(
                      task
                    )}`}
                  >
                    {task.milestone ? (
                      <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                        M:
                      </div>
                    ) : task.levelOfEffort ? (
                      <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                        LOE:
                      </div>
                    ) : null}
                    <span className="truncate min-w-0">{task.title}</span>
                  </div>
                )}
                <button
                  aria-label="highlight task in list"
                  aria-pressed={highlightedTaskId === task.id}
                  disabled={!visible}
                  aria-controls="import-task-list"
                  className={`ml-auto ${
                    highlightedTaskId === task.id
                      ? "text-blue-400"
                      : "text-gray-400"
                  }${visible ? " hover:opacity-80" : " opacity-50"}`}
                  onClick={() => highlightTask(task.id)}
                >
                  {" "}
                  <BiTargetLock className="w-8 h-8" />{" "}
                </button>
              </li>
            );
          })}
          {paddingBottom > 0 && <li style={{ height: `${paddingBottom}px` }} />}
        </ol>
      </div>
    </div>
  );
};

const TaskTitle: FC<{
  task?: ITask;
  title: string;
  taskId: string;
  isHighlighted?: boolean;
  highlightTask?: (id: string) => void;
  visible?: boolean;
  footer?: JSX.Element;
}> = ({
  task,
  title,
  taskId,
  isHighlighted,
  highlightTask,
  visible,
  footer,
}) => (
  <div
    className={`${
      task ? "" + getLevelBorderColorClass(task) : "border-gray-300"
    } border-b-2`}
  >
    <div className="flex items-center gap-2 group">
      <h3 className={`truncate group-hover:whitespace-normal inline overflow-hidden text-ellipsis whitespace-nowrap`}>
        <span
          className={`border-l-2 ml-1 pl-2 ${
            task ? "" + getLevelBorderColorClass(task) : "border-gray-300"
          }`}
        />
        {title}
      </h3>
      {highlightTask ? (
        <button
          aria-label="highlight task in list"
          aria-pressed={isHighlighted}
          disabled={!visible}
          aria-controls="import-task-list"
          className={`ml-auto group-hover:self-start group-hover:pt-[0.6rem] ${
            isHighlighted ? "text-blue-400" : "text-gray-400"
          }${visible ? " hover:opacity-80" : " opacity-50"}`}
          onClick={() => highlightTask(taskId)}
        >
          {" "}
          <BiTargetLock className="w-8 h-8" />{" "}
        </button>
      ) : null}
    </div>
    {footer ? footer : null}
  </div>
);
const TaskSubtitle: FC<{
  task: ITask;
}> = ({ task }) => (
  <div className="flex justify-between gap-2 px-2">
    {task.WBS ? (
      <p className="text-gray-500">WBS</p>
    ) : (
      <>
        <p className="text-gray-500 truncate">
          {task.taskCategory?.selectedName || "No category selected"}
        </p>
        <p
          className={`text-gray-500 flex-shrink-0 ${tooltipClasses}`}
          aria-label="Source"
          data-tip="Source"
        >
          {task.taskCategory?.selectedSource
            ? groupName(task.taskCategory?.selectedSource)
            : ""}
        </p>
      </>
    )}
  </div>
);

const TaskDetailRoot: FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <div className={`${className || ""} pt-0 flex flex-col relative h-full`}>
      {children}
    </div>
  );
};

const TaskDetail = Object.assign(TaskDetailRoot, {
  TaskSubtitle,
  TaskTitle,
  TaskList,
});

export default TaskDetail;
