import React, { forwardRef } from "react";

interface ISidePanelProps {
  className?: string;
}

const SidePanelRoot: React.FC<ISidePanelProps> = forwardRef<
  HTMLDivElement,
  ISidePanelProps
>((props, ref) => {
  return (
    <div
      {...props}
      className={`top-0 sticky h-screen w-[65%] 2xl:w-[50%] border border-solid border-black overflow-y-auto pt-6 ${
        props.className || ""
      }`}
      ref={ref}
    />
  );
});

SidePanelRoot.displayName = "SidePanel";

const Section: React.FC<ISidePanelProps> = forwardRef<
  HTMLDivElement,
  ISidePanelProps
>((props, ref) => {
  return (
    <div
      {...props}
      className={`px-8 py-6 ${props.className || ""}`}
      ref={ref}
    />
  );
});

Section.displayName = "SidePanel.Section";

export const SidePanel = Object.assign(SidePanelRoot, { Section });
