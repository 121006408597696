import { FC, useState } from "react";
import { doc, setDoc, runTransaction } from "firebase/firestore";

import { db } from "firebaseConfig";
import { useIsMounted } from "hooks";
import { FaBell } from "react-icons/fa";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import {
  ISite,
  CollectionType,
  IUser,
  NotificationType,
  NotificationTypeEnum,
} from "@ehabitation/ts-utils/browser";
import { getIsSiteActive } from "../hooks";

const setNotificationConfig = async (
  site: ISite,
  userId: string,
  enabled: boolean
) => {
  const configRef = doc(
    db,
    `${CollectionType.Users}/${userId}/${CollectionType.NotificationConfig}/${NotificationTypeEnum.SiteForecast}_${site.id}`
  );

  const userRef = doc(db, `${CollectionType.Users}/${userId}`);

  const notificationTypeRef = doc(
    db,
    `${CollectionType.NotificationTypes}/${NotificationTypeEnum.SiteForecast}`
  );

  if (!enabled) {
    await setDoc(configRef, {
      notificationType: NotificationTypeEnum.SiteForecast,
      enabled,
      siteId: site.id,
      userId,
      source: {
        type: "web-app",
        userId,
      },
      updatedAt: new Date(),
    });
  } else {
    await runTransaction(db, async (transaction) => {
      const user = (await transaction.get(userRef)).data() as IUser;
      const notificationType = (
        await transaction.get(notificationTypeRef)
      ).data() as NotificationType;

      const siteIsActive = getIsSiteActive(site);
      const siteIsPersonal = !site.project && site.owner === userId;
      const isDefaultEnabled =
        siteIsPersonal ||
        (siteIsActive &&
          notificationType?.defaultRecipients?.some(
            ({ role: defaultRole }) => user.role === defaultRole
          ));

      if (isDefaultEnabled) {
        transaction.delete(configRef);
      } else {
        transaction.set(configRef, {
          notificationType: NotificationTypeEnum.SiteForecast,
          enabled,
          siteId: site.id,
          userId,
          source: {
            type: "web-app",
            userId,
          },
          updatedAt: new Date(),
        });
      }
    });
  }
};

const tooltipClasses =
  "relative before:absolute before:border before:border-black before:shadow-lg before:bg-green-50 before:z-10 before:text-xl before:content-tip before:right-[70%] before:top-[80%] before:mb-2 before:rounded-md before:px-2 before:py-1 hover:before:block before:hidden before:w-[14rem]";

export const SiteNotificationButton: FC<{
  isEnabled: boolean;
  isSelectedSite: boolean;
  site: ISite;
  disabled?: boolean;
  tooltip?: string;
}> = ({ isEnabled, isSelectedSite, site, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const userId = useAppSelector(selectUserId);

  // if loading, we're waiting for the enabled status to flip
  const optimisticIsEnabled = isLoading ? !isEnabled : isEnabled;

  const tooltip = `Daily site forecast report email ${
    isEnabled ? "active" : "inactive"
  }.`;

  return (
    <button
      aria-label={isEnabled ? "unpin site" : "pin site"}
      onClick={(e) => {
        e.stopPropagation();
        setIsLoading(true);
        setNotificationConfig(site, userId, !isEnabled).then(() => {
          if (isMounted()) setIsLoading(false);
        });
      }}
      disabled={isLoading || disabled}
      className={`text-4xl p-4 rounded-lg transition-colors disabled:opacity-70 ${
        isSelectedSite ? "hover:bg-green-400" : "hover:bg-gray-200"
      } ${tooltipClasses}`}
      data-tip={tooltip}
    >
      <FaBell
        className={`${
          optimisticIsEnabled ? "text-yellow-600" : "text-gray-500"
        } text-2xl -z-10`}
      />
    </button>
  );
};
