//React
import React, { useCallback } from "react";
//Store
import { useAppDispatch, useAppSelector } from "store";
import { setHasFileError, selectHasFileError } from "store/ui";
//Material UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const FileErrorSnackbar = () => {
  const dispatch = useAppDispatch();
  const hasFileError = useAppSelector(selectHasFileError);

  const handleClose = useCallback(() => {
    dispatch(setHasFileError(false));
  }, [dispatch]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={3000}
      onClose={handleClose}
      open={hasFileError}
    >
      <Alert severity="error" onClose={handleClose}>
        There was an error with your export, please retry or contact
        support@ehab.co
      </Alert>
    </Snackbar>
  );
};

export default FileErrorSnackbar;
