import { createSelector } from "reselect";
import { selectUserId, selectActiveSiteId } from "store/auth/authSlice";
import { selectProjectId } from "store/project";

export const userMetadataSelector = createSelector(
  [selectUserId, selectActiveSiteId, selectProjectId],
  (userId, siteId, projectId) => ({
    userId,
    siteId,
    projectId,
  })
);
