import { FC, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const PasswordInput: FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className = "",
  ...inputProps
}) => {
  const [showText, setShowText] = useState(false);
  useEffect(() => {
    const hideText = () => setShowText(false);
    document.addEventListener("touchend", hideText);
    return () => document.removeEventListener("touchend", hideText);
  }, []);
  return (
    <span className={`relative ${className}`}>
      <input
        {...inputProps}
        className={`p-4 pl-12 rounded-full border border-gray-500 outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89] text-[1.6rem] sm:text-[1.4rem] pr-16 w-full`}
        type={showText ? "text" : "password"}
      />
      <button
        type="button"
        className="absolute w-8 h-8 right-8 top-1/2 -translate-y-1/2 text-gray-500"
        onMouseDown={() => setShowText(true)}
        onMouseUp={() => setShowText(false)}
        onMouseLeave={() => setShowText(false)}
        onTouchStart={() => setShowText(true)}
        onTouchEnd={() => setShowText(false)}
      >
        {showText ? (
          <FaEyeSlash className="w-full h-full" />
        ) : (
          <FaEye className="w-full h-full" />
        )}
      </button>
    </span>
  );
};
