import { Dialog } from "@material-ui/core";
import styled from "styled-components";
import { flex, fontSize, spacing, colors, font } from "styles";
import { StyledMonochromeSelect } from "elements";

export const StyledModal = styled(Dialog)`
  position: relative;
  z-index: 2000;
`;

export const StyledFloatingCloseButton = styled.button`
  ${font("title")};
  cursor: pointer;
  position: absolute;
  top: ${spacing.m};
  right: ${spacing.l};
  font-size: ${fontSize.l};
  z-index: 2001;
  border: none;
  background: transparent;
  outline: none;
`;

export const StyledMain = styled.main`
  ${flex("column", "center", "center")};
  height: 80vh;
  padding: 4rem;
`;

export const StyledSlideContainer = styled.div`
  ${flex("column", "center", "center")};
  position: relative;
  width: 100%;
  height: 100%;
  will-change: transform, opacity; //specifiy the animation type (from useSlideAnimation)
  overflow-x: hidden;

  //Each slide
  > div {
    height: 100%;
    width: 100%;
    position: absolute; //needs to be absolute for animation to work
  }
`;

export const StyledContentContainer = styled.div`
  ${flex("column", "center", "center")};
  height: 100%;
  width: 100%;

  h2:first-of-type {
    padding-top: ${spacing.l};
  }
`;

export const StyledContent = styled.div`
  ${flex("column", "center", "flex-start")};
  width: 60%;
  height: 100%;

  > p {
    width: 100%;
    padding-bottom: ${spacing.l};
  }
`;

export const StyledFileInput = styled.div`
  ${flex("row", "flex-start", "center")};
  width: 100%;
  padding-bottom: ${spacing.l};

  > button {
    margin-right: ${spacing.l};
  }

  > p {
    width: calc(100% - 15rem);
  }
`;

export const StyledNextSelect = styled(StyledMonochromeSelect)`
  align-self: flex-end;
`;

export const StyledHiddenInput = styled.input`
  display: none;
`;

export const StyledErrorMessage = styled.p`
  padding-top: ${spacing.m};
  align-self: flex-end;
  color: ${colors.warning};
  text-align: right;
  height: ${fontSize.s};
`;
