//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//Types
import { RootState } from "store";

//State
export interface ITornadoState {
  tornadoModalIsOpen: boolean;
}

export const initialState: ITornadoState = {
  tornadoModalIsOpen: false,
};

//Slice
export const tornadoSlice = createSlice({
  name: "tornado",
  initialState,
  reducers: {
    setTornadoModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.tornadoModalIsOpen = action.payload;
    },
  },
});

//Actions
export const { setTornadoModalIsOpen } = tornadoSlice.actions;

//Selectors
export const selectTornadoModalIsOpen = (state: RootState) =>
  state.tornado.tornadoModalIsOpen;

//Reducer
export default tornadoSlice.reducer;
