import styled from "styled-components";
import { spacing } from "styles";

export const StyledImpact = styled.div`
  width: 100%;
  min-width: 1010px; //point at which the components look bad
  padding-right: ${spacing.l};
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr;
`;
