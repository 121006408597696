import { FC, useState, useEffect } from "react";
import CodeInputNew from "./CodeInputNew";

interface ActivityCategoryInputsProps {
  id: string;
  activityCode: string;
  categoryCode: string;
  handleChange: (field: "activityCode" | "categoryCode", value: string) => void;
  showMappingInputs: boolean;
  setShowMappingInputs: (show: boolean) => void;
  changeMap: Record<string, any>;
  readOnly: boolean;
}

const ActivityCategoryInputs: FC<ActivityCategoryInputsProps> = ({
  id,
  activityCode,
  categoryCode,
  handleChange,
  showMappingInputs,
  setShowMappingInputs,
  changeMap,
  readOnly,
}) => {
  const [selectedOption, setSelectedOption] = useState<"activityCode" | "categoryCode">(
    activityCode ? "activityCode" : "categoryCode"
  );

  useEffect(() => {
    if (!activityCode && !categoryCode) {
      setShowMappingInputs(false);
    }
  }, [activityCode, categoryCode, setShowMappingInputs]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value as "activityCode" | "categoryCode");
  };

  return (activityCode || categoryCode || showMappingInputs) ? (
    <>
      <div className="flex items-center gap-2">
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          className="border border-gray-300 p-2 rounded"
        >
          <option value="activityCode">Activity Code</option>
          <option value="categoryCode">UDF EHAB_CATEGORY</option>
        </select>
      </div>
      {selectedOption === "activityCode" && (
        <div className="flex items-center gap-2">
          <CodeInputNew
            id={id}
            type="Activity"
            readOnly={readOnly}
            className={`${
              changeMap[id]?.activityCode !== activityCode
                ? "border-emerald-500 border-2"
                : ""
            }`}
            code={activityCode}
            handleChange={(value: string) =>
              handleChange("activityCode", value)
            }
            label={"Activity Code"}
          />
          {!activityCode && (
            <button
              className="ml-2 text-red-500"
              onClick={() => setShowMappingInputs(false)}
            >
              X
            </button>
          )}
        </div>
      )}
      {selectedOption === "categoryCode" && (
        <div className="flex items-center gap-2">
          <CodeInputNew
            id={id}
            type="Category"
            readOnly={readOnly}
            className={`${
              changeMap[id]?.categoryCode !== categoryCode
                ? "border-emerald-500 border-2"
                : ""
            }`}
            code={categoryCode}
            handleChange={(value: string) =>
              handleChange("categoryCode", value)
            }
            label={"Category"}
          />
          {!categoryCode && (
            <button
              className="ml-2 text-red-500"
              onClick={() => setShowMappingInputs(false)}
            >
              X
            </button>
          )}
        </div>
      )}
    </>
  ) : (
    <div>
      <button
        className="text-blue-500"
        onClick={() => setShowMappingInputs(true)}
      >
        Add automatic mapping
      </button>
    </div>
  );
};

export default ActivityCategoryInputs;
