import React, { FC } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { NavLink } from "react-router-dom";

interface AlertMessageProps {
  message: string;
  linkText: string;
  linkTo: string;
}

export const AlertMessage: FC<AlertMessageProps> = ({ message, linkText, linkTo }) => (
  <div className="text-2xl flex gap-2 items-center">
    <FiAlertTriangle className="text-2xl text-gray-400 shrink-0 mr-4" />
    <div>
      <label className="text-2xl">
        {message}
      </label>
      <label className="mt-2 text-2xl underline block">
        <NavLink to={linkTo}>
          <span>{linkText}</span>
        </NavLink>
      </label>
    </div>
  </div>
);