import styled from "styled-components";
import {
  headerHeight,
  flex,
  grays,
  spacing,
  fontSize,
  replaceOutline,
} from "styles";

export const StyledAdminContent = styled.div`
  ${flex("column", "flex-start", "flex-start")};
  min-height: calc(100vh - ${headerHeight} - 9rem);
  width: 100%;
  position: relative;

  .content-container {
    ${flex("column", "flex-start", "flex-start")};
    width: 100%;
    position: relative;
  }
`;

export const StyledAdminTableHeader = styled.div`
  ${flex("row", "flex-start", "center")};
  background: ${grays.veryLight};
  width: 100%;
  padding: ${spacing.m};

  p {
    font-weight: 500;
  }
`;

export const StyledAdminTableRow = styled.div`
  ${flex("row", "flex-start", "flex-start")};
  width: 100%;
  padding: ${spacing.m};

  .icon {
    ${flex("column", "center", "center")};
    align-self: center;
  }
`;

export const StyledAdminButtonContainer = styled.div`
  padding: ${spacing.m};
  align-self: flex-end;
`;

export const StyledAdminIconButton = styled.button`
  ${flex("column", "center", "center")};
  ${replaceOutline()};
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${fontSize.l};
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  color: ${grays.light};

  &:disabled {
    color: ${grays.veryLight};
  }
`;
