import styled, { css } from "styled-components";
import { flex, spacing, grays, replaceOutline } from "styles";
import { rgba } from "polished";

interface IStyledRiskProfiles {
  full: boolean;
}

export const StyledRiskProfiles = styled.div<IStyledRiskProfiles>`
  ${(props) =>
    flex("row", props.full ? "space-between" : "flex-start", "flex-start")};
  width: 100%;
  margin-top: 3rem;
  border-top: 0.1rem solid ${grays.light};
  overflow-y: auto;
`;

export const applyColumnStyles = () => {
  return css`
    ${flex("column", "flex-start", "flex-start")};

    > p {
      width: 100%;
      height: 6rem;
    }

    p.header {
      height: 3rem;
      width: 100%;
      font-weight: 500;
    }
  `;
};

export const StyledTitleColumn = styled.div`
  ${applyColumnStyles()};
  width: 25%;

  .header {
    height: 3rem;
    width: 100%;

    p {
      width: 90%;
      background: ${grays.veryLight};
      font-weight: 500;
    }
  }

  p {
    ${flex("column", "center", "flex-start")};
    padding: 0.3rem ${spacing.s};
    color: ${grays.dark};
    text-transform: uppercase;
  }
`;

interface IStyledRiskProfile {
  isActive: boolean;
  confidenceColor: string;
  WBS: boolean;
}

export const StyledRiskProfile = styled.button<IStyledRiskProfile>`
  ${applyColumnStyles()};
  ${(props) => !props.WBS && replaceOutline()};
  width: 17%;
  border: 0.1rem solid
    ${(props) =>
      props.isActive
        ? props.confidenceColor
        : props.WBS
        ? "white"
        : grays.veryLight};
  border-top: none;
  cursor: ${(props) => (!props.WBS ? "pointer" : "default")};

  &:focus {
    border: none;
  }

  p {
    ${flex("column", "center", "center")};
    width: 100%;

    background-color: ${(props) =>
      props.isActive ? rgba(props.confidenceColor, 0.4) : "white"};
  }

  p.header {
    background: ${(props) => props.confidenceColor};
  }

  p.disabled {
    color: ${grays.dark};
  }
`;
