//Styles
import styled from "styled-components";
import { flex, dotMatrix } from "styles";

export const StyledMatrixContainer = styled.div`
  ${flex("column", "center", "center")};
  ${dotMatrix()}
  height: calc(100vh - 15rem);
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 32px;
  }

  h2 {
    font-size: 16px;
  }

  img {
    max-height: 70%;
    padding-bottom: 20px;
  }

  a {
    margin-top: 10px;
  }
`;
