import { useCallback, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectPlanById } from "Components/Plan/PlanControls/thunks";
import { setSaveAsModalIsOpen } from "store/project";
import { selectTaskModified } from "store/ui";
import { selectRole } from "store/auth";
import { isGuest } from "@ehabitation/ts-utils/browser";

export const useSelectPlan = () => {
  const dispatch = useAppDispatch();

  const tasksHaveBeenModified = useAppSelector(selectTaskModified);
  const userRole = useAppSelector(selectRole);

  const [pendingPlanId, setPendingPlanId] = useState("");

  const unsubscribeRef = useRef<() => void>();

  const handleSelectPlan = useCallback(
    (id: string) => {
      if (tasksHaveBeenModified && !isGuest(userRole!)) setPendingPlanId(id);
      else dispatch(selectPlanById(id, unsubscribeRef));
    },
    [tasksHaveBeenModified, dispatch]
  );

  const handleCancelPlanSwitch = () => {
    setPendingPlanId("");
  };

  const handleConfirm = () => {
    dispatch(selectPlanById(pendingPlanId, unsubscribeRef));
    setPendingPlanId("");
  };

  const handleOpenSavePlanModal = () => {
    dispatch(setSaveAsModalIsOpen(true));
    setPendingPlanId("");
  };

  return {
    promptIsOpen: pendingPlanId ? true : false,
    handleSelectPlan,
    handleCancelPlanSwitch,
    handleConfirm,
    handleOpenSavePlanModal,
  };
};
