import { useAppDispatch, useAppSelector } from "store";
import {
  setSelectedSiteTab,
  selectSelectedSiteTab,
} from "store/siteManagement";
import { SiteManagementTabOption } from "types";

export const useSiteManagementTab = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectSelectedSiteTab);

  const handleChangeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabOption = e.currentTarget.name as SiteManagementTabOption;
    dispatch(setSelectedSiteTab(tabOption));
  };

  return {
    selectedTab,
    handleChangeTab,
  };
};
