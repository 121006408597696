import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectProjectManagementIsLoading,
  setSelectedProject,
} from "store/projectManagement";
import { StyledAdminButtonContainer, StyledAdminContent } from "elements";
import { useProjectManagementTab } from "../useProjectManagementTab";
import ProjectsHeader from "./ProjectsHeader";
import ProjectsList from "./ProjectsList";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";

const Projects = () => {
  const dispatch = useAppDispatch();
  const { goToNewTab } = useProjectManagementTab();

  const loading = !!useAppSelector(selectProjectManagementIsLoading);

  /* Clear the selected user when entering or returning to this tab */
  useEffect(() => {
    dispatch(setSelectedProject(null));
  }, [dispatch]);

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        <div className="content-container">
          <StyledAdminButtonContainer>
            <MonochromeButton
              level="primary"
              width="lg"
              onClick={goToNewTab}
              data-testid="project-add"
            >
              Add A New Project
            </MonochromeButton>
          </StyledAdminButtonContainer>
          <ProjectsHeader />
          <ProjectsList />
        </div>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default Projects;
