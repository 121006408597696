import styled from "styled-components";
import { flex, spacing, grays, fontSize, colors, highlightHover } from "styles";

export const StyledTabbedContainer = styled.div`
  min-width: 100rem;
  overflow: hidden;

  .tabs {
    ${flex("row", "flex-start", "center")};
    margin-bottom: -0.1rem;

    button {
      ${highlightHover()};
      cursor: pointer;
      background: white;
      padding: ${spacing.m} ${spacing.l};
      border: none;
      width: 15rem;
      font-size: ${fontSize.m};

      &:focus {
        outline: none;
        color: ${colors.primaryD};
      }

      &:disabled,
      &.selected {
        cursor: default;
      }
    }

    .selected {
      border-top: 0.1rem solid ${grays.light};
      border-left: 0.1rem solid ${grays.light};
      border-right: 0.1rem solid ${grays.light};
      border-bottom: 0.1rem solid white;
    }
  }

  .container {
    border: 0.1rem solid ${grays.light};
    padding: ${spacing.m} 0 ${spacing.l} ${spacing.l};

    .no-data-to-display {
      ${flex("row", "center", "center")}
      padding: ${spacing.l} 0;
    }
  }
`;
