import { FC } from "react";
import { IPlan, ITask, WeatherKey } from "@ehabitation/ts-utils/browser";
import { Tooltip } from "@material-ui/core";
import { SimulationLevel, SimulationTaskResult } from "helpers";

import moment from "moment";
import { isEmpty } from "lodash";
import { BsInfoCircle } from "react-icons/bs";
import {
  DifferenceDateCell,
  DifferenceVarianceCell,
} from "../components/DifferenceCell";
import { thresholdUnits } from "../weather/WeatherView";
import { getLevelBorderColorClass } from "@ehabitation/ui";

interface VarianceRowProps {
  task: ITask & { simulation: SimulationTaskResult };
  varianceTaskResult?: ITask & { simulation: SimulationTaskResult };
  selectedSimLevel: SimulationLevel;
  variancePlan?: IPlan;
  varianceSimulationInProgress: boolean;
  varianceSimulationPending: boolean;
  simulation: boolean;
  selectedColumnValues: {
    firstColumn: string;
    secondColumn: string;
    thirdColumn: string;
  };
}

export const groupName = (name: string) => {
  const groupName = name.replace(/([A-Z])/g, " $1").trim();
  return groupName[0].toUpperCase() + groupName.slice(1);
};

export const VarianceRow: FC<VarianceRowProps> = ({
  task,
  varianceTaskResult,
  selectedSimLevel,
  variancePlan,
  varianceSimulationInProgress,
  varianceSimulationPending,
  simulation,
  selectedColumnValues,
}) => {
  const basePlannedStartDate = task?.start;
  const baseProposedStartDate =
    task?.simulation.riskIntervalResults?.[selectedSimLevel]?.startDate ||
    basePlannedStartDate;

  const variancePlannedStartDate = varianceTaskResult?.start;
  const varianceProposedStartDate =
    varianceTaskResult?.simulation.riskIntervalResults?.[selectedSimLevel]
      ?.startDate || variancePlannedStartDate;

  const basePlannedEndDate = task?.end;
  const baseProposedEndDate =
    task?.simulation.riskIntervalResults?.[selectedSimLevel]?.endDate ||
    basePlannedEndDate;

  const variancePlannedEndDate = varianceTaskResult?.end;
  const varianceProposedEndDate =
    varianceTaskResult?.simulation.riskIntervalResults?.[selectedSimLevel]
      ?.endDate || variancePlannedEndDate;
  const isIndented = task
    ? task.wbsHierarchyLevel
    : ((varianceTaskResult as ITask)?.wbsHierarchyLevel || 0) > 0;
  const getCells = (value: string) => {
    switch (value) {
      case "proposedStartDate":
        return {
          baseline: baseProposedStartDate
            ? moment(baseProposedStartDate).format("D/M/YY")
            : "-",
          variation: varianceProposedStartDate ? (
            baseProposedStartDate ? (
              <DifferenceDateCell
                base={baseProposedStartDate}
                mitigated={varianceProposedStartDate}
              />
            ) : (
              moment(varianceProposedStartDate).format("D/M/YY")
            )
          ) : (
            "-"
          ),
        };
      case "plannedStartDate":
        return {
          baseline: basePlannedStartDate
            ? moment(basePlannedStartDate).format("D/M/YY")
            : "-",
          variation: variancePlannedStartDate ? (
            basePlannedStartDate ? (
              <DifferenceDateCell
                base={basePlannedStartDate}
                mitigated={variancePlannedStartDate}
              />
            ) : (
              moment(variancePlannedStartDate).format("D/M/YY")
            )
          ) : (
            "-"
          ),
        };
      case "startDelay":
        return {
          baseline:
            basePlannedStartDate && baseProposedStartDate
              ? moment(baseProposedStartDate)
                  .startOf("day")
                  .diff(moment(basePlannedStartDate).startOf("day"), "days")
              : 0,
          variation:
            variancePlannedStartDate && varianceProposedStartDate
              ? moment(varianceProposedStartDate)
                  .startOf("day")
                  .diff(moment(variancePlannedStartDate).startOf("day"), "days")
              : 0,
        };
      case "proposedEndDate":
        return {
          baseline: baseProposedEndDate
            ? moment(baseProposedEndDate).format("D/M/YY")
            : "-",
          variation: varianceProposedEndDate ? (
            baseProposedEndDate ? (
              <DifferenceDateCell
                base={baseProposedEndDate}
                mitigated={varianceProposedEndDate}
              />
            ) : (
              moment(varianceProposedEndDate).format("D/M/YY")
            )
          ) : (
            "-"
          ),
        };
      case "plannedEndDate":
        return {
          baseline: basePlannedEndDate
            ? moment(basePlannedEndDate).format("D/M/YY")
            : "-",
          variation: basePlannedEndDate ? (
            variancePlannedEndDate ? (
              <DifferenceDateCell
                base={basePlannedEndDate}
                mitigated={variancePlannedEndDate}
              />
            ) : (
              moment(variancePlannedEndDate).format("D/M/YY")
            )
          ) : (
            "-"
          ),
        };
      case "weatherDays":
        return {
          baseline:
            task?.simulation.riskIntervalResults?.[selectedSimLevel]
              ?.weatherDays || 0,
          variation: task ? (
            <DifferenceVarianceCell
              base={
                task.simulation.riskIntervalResults?.[selectedSimLevel]
                  ?.weatherDays || 0
              }
              mitigated={
                varianceTaskResult?.simulation.riskIntervalResults?.[
                  selectedSimLevel
                ]?.weatherDays || 0
              }
            />
          ) : (
            varianceTaskResult?.simulation.riskIntervalResults?.[
              selectedSimLevel
            ]?.weatherDays || 0
          ),
        };
      case "taskType":
        return {
          baseline: task?.taskType,
          variation: (
            varianceTaskResult as ITask & { simulation: SimulationTaskResult }
          )?.taskType,
        };
      case WeatherKey.minTemp:
        return {
          baseline: task?.minTemp || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.minTemp || "-",
        };
      case WeatherKey.maxTemp:
        return {
          baseline: task?.maxTemp || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.maxTemp || "-",
        };
      case WeatherKey.wind:
        return {
          baseline: task?.wind || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.wind || "-",
        };
      case WeatherKey.dailyRainAcc:
        return {
          baseline: task?.dailyRainAcc || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.dailyRainAcc || "-",
        };
      case WeatherKey.hourlyRainAcc:
        return {
          baseline: task?.hourlyRainAcc || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.hourlyRainAcc || "-",
        };
      case WeatherKey.waveHeight:
        return {
          baseline: task?.waveHeight || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.waveHeight || "-",
        };
      case WeatherKey.windGusts:
        return {
          baseline: task?.windGusts || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.windGusts || "-",
        };
      case WeatherKey.snowfall:
        return {
          baseline: task?.snowfall || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.snowfall || "-",
        };
      case WeatherKey.snowfall24Hour:
        return {
          baseline: task?.snowfall24Hour || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.snowfall24Hour || "-",
        };
      case WeatherKey.visibility:
        return {
          baseline: task?.visibility || "-",
          variation:
            (varianceTaskResult as ITask & { simulation: SimulationTaskResult })
              ?.visibility || "-",
        };
    }
  };
  const firstUnit = thresholdUnits[
    selectedColumnValues.firstColumn as WeatherKey
  ]
    ? thresholdUnits[selectedColumnValues.firstColumn as WeatherKey]
    : "";
  const secondUnit = thresholdUnits[
    selectedColumnValues.secondColumn as WeatherKey
  ]
    ? thresholdUnits[selectedColumnValues.secondColumn as WeatherKey]
    : "";
  const thirdUnit = thresholdUnits[
    selectedColumnValues.thirdColumn as WeatherKey
  ]
    ? thresholdUnits[selectedColumnValues.thirdColumn as WeatherKey]
    : "";

  return (
    <>
      <tr
        data-task-id={task?.id || (varianceTaskResult as ITask)?.id}
        className={`group hover:bg-blue-50 ${
          varianceSimulationInProgress ? "animate-pulse" : ""
        }`}
      >
        <td className={`border-b border-x relative`}>
          <Tooltip
            arrow
            title={
              <span className="text-2xl">
                {task?.title || (varianceTaskResult as ITask)?.title}
              </span>
            }
          >
            <div
              className={`truncate ${
                isEmpty(varianceTaskResult) ? "bg-[#afffe2] bg-opacity-25" : ""
              } ${isEmpty(task) ? "bg-[#1d2bdd] bg-opacity-10" : ""}
              }`}
            >
              {isIndented ? (
                <>
                  <span
                    className={`border-l-2 absolute top-0 bottom-0 w-4 ${getLevelBorderColorClass(
                      task ? task : (varianceTaskResult as ITask)
                    )}`}
                  />
                  <span className="pr-4" />
                </>
              ) : null}
              {task?.title || (varianceTaskResult as ITask)?.title}
            </div>
          </Tooltip>
        </td>
        {!isEmpty(task) ? (
          <>
            <td
              className={`px-4 truncate border-b border-r relative text-center`}
            >
              {getCells(selectedColumnValues.firstColumn)?.baseline}
              {firstUnit && <span className="text-xl"> {firstUnit}</span>}
            </td>
            <td
              className={`px-4 truncate border-b border-r relative text-center`}
            >
              {getCells(selectedColumnValues.secondColumn)?.baseline}
              {secondUnit && <span className="text-xl"> {secondUnit}</span>}
            </td>
            <td
              className={`px-4 truncate border-b border-r relative text-center`}
            >
              {getCells(selectedColumnValues.thirdColumn)?.baseline}
              {thirdUnit && <span className="text-xl"> {thirdUnit}</span>}
            </td>
          </>
        ) : (
          <>
            <Tooltip
              arrow
              title={
                <span className="text-2xl">Task not present in base plan.</span>
              }
            >
              <td
                colSpan={3}
                className="border-r border-b text-center italic text-gray-400 relative"
              >
                <BsInfoCircle className="mx-auto" />
              </td>
            </Tooltip>
          </>
        )}
        {varianceSimulationPending || isEmpty(variancePlan) || !simulation ? (
          <td
            colSpan={3}
            className="border-r border-b text-center italic text-gray-400 relative"
          >
            ?
          </td>
        ) : !isEmpty(varianceTaskResult) ? (
          <>
            <td className="px-4 truncate border-b border-r relative text-center">
              {getCells(selectedColumnValues.firstColumn)?.variation}
              {firstUnit && <span className="text-xl"> {firstUnit}</span>}
            </td>
            <td className="px-4 truncate border-b border-r relative text-center">
              {getCells(selectedColumnValues.secondColumn)?.variation}
              {secondUnit && <span className="text-xl"> {secondUnit}</span>}
            </td>
            <td className="px-4 truncate border-b border-r relative text-center">
              {getCells(selectedColumnValues.thirdColumn)?.variation}
              {thirdUnit && <span className="text-xl"> {thirdUnit}</span>}
            </td>
          </>
        ) : (
          <>
            <Tooltip
              arrow
              title={
                <span className="text-2xl">
                  Task not impacted or not found in variation plan.
                </span>
              }
            >
              <td
                colSpan={3}
                className="border-r border-b text-center italic text-gray-400 relative"
              >
                <BsInfoCircle className="mx-auto" />
              </td>
            </Tooltip>
          </>
        )}
      </tr>
    </>
  );
};
