import { ITask, LevelOfEffort, Milestone } from "@ehabitation/ts-utils/browser";
import { StyledTooltip } from "elements";
import { FC, memo, useMemo } from "react";
import { useAppSelector } from "store";
import { allTasksSelector } from "store/tasks";
import ConfidenceAlertIcon from "./ConfidenceAlertIcon";
import { thresholdAlert } from "./constants";
import { ConfidenceCellWrapper } from "./styles";

interface IProps {
  userSelectedConfidence: boolean;
  accuracy: number;
  color: string;
  WBS: ITask | null;
  isInCriticalPath: boolean;
  milestone: Milestone | undefined;
  levelOfEffort: LevelOfEffort | undefined;
  thresholdDiff: boolean;
}

const ConfidenceCell: FC<IProps> = ({
  userSelectedConfidence,
  accuracy,
  color,
  WBS,
  isInCriticalPath,
  milestone,
  levelOfEffort,
  thresholdDiff,
}) => {
  const allTasks = useAppSelector(allTasksSelector);
  const showAlertIcon = useMemo(() => {
    const confidenceIsLessThan80 = accuracy < 80;
    const isWBS = WBS !== null;
    const isMilestone = !!milestone;
    const isLevelOfEffort = !!levelOfEffort;

    return (
      (!userSelectedConfidence &&
        confidenceIsLessThan80 &&
        !isWBS &&
        !isMilestone &&
        !isLevelOfEffort) ||
      thresholdDiff
    );
  }, [userSelectedConfidence, WBS, milestone, levelOfEffort, thresholdDiff]);

  const tooltipText = useMemo(
    () => (thresholdDiff ? thresholdAlert : ""),
    [thresholdDiff]
  );

  return (
    <StyledTooltip title={tooltipText} arrow placement="right" enterDelay={500}>
      <div
        className={`cell-value confidence ${color}`}
        data-testid="confidence-cell"
      >
        <>
          <ConfidenceCellWrapper>
            {levelOfEffort && milestone && allTasks
              ? ``
              : `${Math.floor(accuracy)} %`}
          </ConfidenceCellWrapper>
          {showAlertIcon && (
            <ConfidenceAlertIcon
              criticalPath={isInCriticalPath}
              thresholdDiff={thresholdDiff}
            />
          )}
        </>
      </div>
    </StyledTooltip>
  );
};

export default memo(ConfidenceCell);
