//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//Types
import { IForecastDocument } from "types";
import { RootState } from "store";

//State
export interface IForecastState {
  forecast: IForecastDocument;
  siteId: string | null;
  error?: string;
}

export const initialState: IForecastState = {
  forecast: {
    createdAt: null,
    sourceRef: null,
    dailyForecasts: [],
  },
  siteId: null,
};

//Slice
export const forecastSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    setForecast: (state, action: PayloadAction<IForecastState>) => {
      return action.payload;
    },
    setForecastError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    clearForecastState: () => {
      return initialState;
    },
  },
});

//Actions
export const { setForecast, setForecastError, clearForecastState } =
  forecastSlice.actions;

//Selectors
export const selectDailyForecast = (state: RootState) =>
  state.forecast.forecast.dailyForecasts;
export const selectForecastSiteId = (state: RootState) => state.forecast.siteId;
export const selectForecastError = (state: RootState) => state.forecast.error;

//Reducer
export default forecastSlice.reducer;
