import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";
import { StyledContentContainer } from "elements/Modals";

interface IProps {
  message: string;
  subMessage?: string;
}

const Loader: FC<IProps> = ({ message, subMessage }) => {
  return (
    <StyledContentContainer>
      <CircularProgress color="inherit" />
      <h2>{message}</h2>
      {subMessage && <h2>{subMessage}</h2>}
    </StyledContentContainer>
  );
};

export default Loader;
