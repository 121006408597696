//React
import { useEffect, useCallback } from "react";
//Store
import { useAppSelector } from "store";
import { selectTaskModified } from "store/ui";

/* Alert the user when they use the browser to
  leave the page (i.e. close tab) */
export const usePromptUserBeforeUnload = () => {
  const tasksHaveBeenModified = useAppSelector(selectTaskModified);

  const alertUser = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (tasksHaveBeenModified)
      window.addEventListener("beforeunload", alertUser);
    if (!tasksHaveBeenModified)
      window.removeEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [tasksHaveBeenModified, alertUser]);

  return { tasksHaveBeenModified };
};
