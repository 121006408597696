//React
import React, { FC } from "react";
//Styles
import { StyledConfidenceRow } from "./styles";
import { colors } from "styles";
//Helpers
import moment, { Moment } from "moment";
import { determineConfidenceColor } from "./helpers";
import { useLocation } from "react-router-dom";

interface IProps {
  confidence: number | undefined;
  daysAdded?: number;
  endDate: Moment | undefined;
  isOriginal?: boolean;
  isSelected: boolean;
  selectConfidenceRow?: (confidence: number, endDate: Moment) => void;
}

const ConfidenceRow: FC<IProps> = ({
  confidence,
  daysAdded,
  endDate,
  isOriginal,
  isSelected,
  selectConfidenceRow,
}) => {
  const { pathname } = useLocation();
  const confidenceColor = isOriginal
    ? colors.ganttGray
    : determineConfidenceColor(Number(confidence));

  const hoverOnOriginalAvailable = pathname === "/overview" && isOriginal;

  const handleClick = () => {
    if (!selectConfidenceRow) return;
    if (!endDate || !confidence) return;

    selectConfidenceRow(confidence, endDate);
  };

  return (
    <StyledConfidenceRow
      isSelected={isSelected}
      confidenceColor={confidenceColor}
      data-testid="confidence-row"
      className={isSelected ? "selected" : ""}
      hoverOnOriginalAvailable={hoverOnOriginalAvailable}
      overviewPageStyles={pathname === "/overview"}
      onClick={() => {
        handleClick();
      }}
    >
      <div className="pip" />

      <p className="confidence" data-testid="confidence">
        {confidence}% {pathname !== "/overview" && isOriginal && "(original)"}
        <span className="tooltip-text">
          {hoverOnOriginalAvailable && "original"}
        </span>
      </p>

      <p className="end-date" data-testid="end-date">
        {isOriginal
          ? moment(endDate).format("DD/MM/YYYY")
          : endDate?.format("DD/MM/YYYY")}
      </p>
      <p className="extra-days" data-testid="extra-days">
        {isOriginal ? "0" : daysAdded}
        {pathname !== "/overview"
          ? isOriginal
            ? " days added"
            : " day(s) added"
          : ""}
      </p>
    </StyledConfidenceRow>
  );
};

export default ConfidenceRow;
