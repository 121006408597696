//Types
import { AppThunk } from "store";
//Store
import {
  setSiteManagementError,
  setSiteManagementIsLoading,
} from "store/siteManagement";
import { batch } from "react-redux";
//Helpers
import { deleteSite } from "./helpers";

export const deleteSiteById: AppThunk =
  (siteId: string) => async (dispatch) => {
    dispatch(setSiteManagementIsLoading(true));

    try {
      await deleteSite(siteId);
      dispatch(setSiteManagementIsLoading(false));
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setSiteManagementError(error.message)); //shows a toast to the user
        dispatch(setSiteManagementIsLoading(false));
      });
    }
  };
