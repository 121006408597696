export const enum PasswordStrength {
  Short,
  Common,
  Repeating,
  WeakLower,
  WeakUpper,
  WeakDigits,
  WeakSpecial,
  Weak, // Should not be
  Ok,
}

/*
Password should:
- contain a minimum of 8 characters
- avoid common sequences 
- contain no more than 3 repeating characters
- at least 1 upper case character
- at least 1 lower case character
- at least 1 digit
- at least one special character
*/

export const checkPasswordComplexity = (password: string): PasswordStrength => {
  // Expected length of all passwords
  const minLength = 8;
  // We should not allow repeating characters. (>3)
  const repeatingCharacters = /^(?!.*(\w)\1{3,}).+$/;
  // some common patterns to reject
  const commonPasswordPatterns =
    /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*/;
  const lowerCase = /.*[a-z].*/;
  const upperCase = /.*[A-Z].*/;
  const digits = /.*[0-9].*/;
  const specialChars = /[^A-Za-z0-9]/;

  const bSpecial = specialChars.test(password);
  const bLower = lowerCase.test(password);
  const bUpper = upperCase.test(password);
  const bDigits = digits.test(password);

  if (password.length < minLength) {
    // Password too short
    return PasswordStrength.Short;
  } else if (!repeatingCharacters.test(password)) {
    // Repeating characters detected.
    return PasswordStrength.Repeating;
  } else if (commonPasswordPatterns.test(password)) {
    // Not a common password
    return PasswordStrength.Common;
  } else if (!bLower) {
    return PasswordStrength.WeakLower;
  } else if (!bUpper) {
    return PasswordStrength.WeakUpper;
  } else if (!bDigits) {
    return PasswordStrength.WeakDigits;
  } else if (!bSpecial) {
    return PasswordStrength.WeakSpecial;
  } else {
    return PasswordStrength.Ok;
  }
};
