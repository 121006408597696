import { AppThunk } from "store";
import { INewSiteForm } from "@ehabitation/ts-utils/browser";
import { batch } from "react-redux";
import {
  setSiteManagementIsLoading,
  setSiteManagementError,
  setSelectedSiteTab,
} from "store/siteManagement";
import { createNewSite, updateSite } from "./helpers";

export const triggerCreateNewSite: AppThunk =
  (newSite: INewSiteForm) => async (dispatch) => {
    dispatch(setSiteManagementIsLoading(true));

    try {
      await createNewSite(newSite);

      batch(() => {
        dispatch(setSiteManagementIsLoading(false));
        dispatch(setSelectedSiteTab("Sites"));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setSiteManagementError(error.message)); //Trigger toast
        dispatch(setSiteManagementIsLoading(false));
      });
    }
  };

export const triggerUpdateSite: AppThunk =
  (newSite: INewSiteForm) => async (dispatch) => {
    dispatch(setSiteManagementIsLoading(true));

    try {
      await updateSite(newSite);

      batch(() => {
        dispatch(setSiteManagementIsLoading(false));
        dispatch(setSelectedSiteTab("Sites"));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setSiteManagementError(error.message)); //Trigger toast
        dispatch(setSiteManagementIsLoading(false));
      });
    }
  };
