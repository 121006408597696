//React
import React from "react";
//Store
import { useAppSelector } from "store";
import { selectImpactRiskProfiles } from "store/selectors";
//Components
import RiskProfile from "./RiskProfile";
//Styles
import { StyledRiskProfiles, StyledTitleColumn } from "./styles";

const RiskProfiles = () => {
  const riskProfiles = useAppSelector(selectImpactRiskProfiles);

  return (
    <StyledRiskProfiles
      full={riskProfiles.length > 1}
      data-testid="risk-profiles"
    >
      <StyledTitleColumn>
        <div className="header">
          <p className="base">Confidence On Completion</p>
        </div>

        <p>Proposed End</p>
        <p>Extra Days</p>
        <p>Extra Days Post Project Deadline</p>
        <p>Is In Critical Path?</p>
      </StyledTitleColumn>

      {riskProfiles.map((profile, i) => (
        <RiskProfile profile={profile} index={i} key={i} />
      ))}
    </StyledRiskProfiles>
  );
};

export default RiskProfiles;
