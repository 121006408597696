import { FC, useMemo } from "react";
import { format } from "date-fns";
import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import {
  StyledPlanConfiguratorPanel,
  StyledDraftsContainer,
  StyledMainPlanContainer,
  StyledPlanInfo,
} from "elements";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import { sortPlans } from "helpers/firebase/firestoreUtils";

interface IVarianceConfiguratorPanelProps {
  plans: IPlan[];
  basePlan: IPlan;
  currentSite: ISite;
  selectedPlan: IPlan | undefined;
  togglePanel: (visibility: boolean) => void;
  handleSelectPlan: Function;
  isShort: boolean;
}

export const Arrow = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute", left: "2rem" }}
  >
    <path d="M10 6L0 0.226497L0 11.7735L10 6Z" fill="#6D6E70" />
  </svg>
);

const Plan = ({
  plan,
  isSelected,
  onSelect,
}: {
  plan: IPlan;
  isSelected: boolean;
  onSelect?: (id: string) => void;
}) => {
  const userId = useAppSelector(selectUserId);
  return (
    <StyledPlanInfo
      role="button"
      onClick={() => {
        if (!isSelected && onSelect) {
          onSelect(plan.id);
        }
      }}
      key={plan.id}
      isSelected={isSelected}
    >
      {isSelected && <Arrow />}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{plan.title}</span>
        <div className="flex gap-2">
          {plan.createdAt && (
            <small>{`Created on ${format(
              plan.createdAt,
              "dd/MM/yyyy HH:mm"
            )}`}</small>
          )}
          {plan.owner === userId && <small>by you</small>}
        </div>
      </div>
    </StyledPlanInfo>
  );
};

const VarianceConfiguratorPanel: FC<IVarianceConfiguratorPanelProps> = ({
  plans,
  basePlan,
  currentSite,
  togglePanel,
  selectedPlan,
  handleSelectPlan,
  isShort,
}) => {
  const userId = useAppSelector(selectUserId);
  const sortedPlans = useMemo(() => (plans ? sortPlans(plans) : []), [plans]);
  const mainPlan = sortedPlans.find(
    (plan) => plan.id === currentSite.mainPlanId
  );
  const mitigationPlan = sortedPlans.find(
    (plan) => plan.mitigatesPlanId === basePlan?.id
  );
  const draftPlans = sortedPlans.filter(
    (plan) =>
      !plan.mitigatesPlanId &&
      plan.id !== currentSite.mainPlanId &&
      plan.id !== basePlan?.id
  );
  const basePlanIsMain = !!(mainPlan && basePlan?.id === mainPlan.id);

  const selectPlan = (planId: string) => {
    handleSelectPlan(planId);
    togglePanel(false);
  };

  const userDrafts = draftPlans.filter((plan) => plan.owner === userId);
  const otherUserDrafts = draftPlans.filter((plan) => plan.owner !== userId);

  return (
    <StyledPlanConfiguratorPanel className="shadow-md" isShort={isShort}>
      <StyledMainPlanContainer>
        {mitigationPlan && (
          <>
            <h3>Mitigation Plan</h3>
            <Plan
              plan={mitigationPlan}
              isSelected={selectedPlan?.id === mitigationPlan.id}
              onSelect={selectPlan}
            />
            <div className="w-full pb-6" />
          </>
        )}
        {mainPlan && !basePlanIsMain && (
          <>
            <h3 className="pt-6">Main Plan</h3>
            <Plan
              plan={mainPlan}
              isSelected={mainPlan.id === selectedPlan?.id}
              onSelect={selectPlan}
            />
            <div className="w-full pb-6" />
          </>
        )}
        {!mainPlan && (
          <>
            <h3>Main Plan</h3>
            <StyledPlanInfo disabled>
              <i>No Main Plan</i>
            </StyledPlanInfo>
          </>
        )}
      </StyledMainPlanContainer>
      <StyledDraftsContainer>
        <div className="flex self-stretch gap-2 justify-between">
          <h3>Your Drafts</h3>
        </div>
        {userDrafts.length ? (
          userDrafts.map((plan) => {
            const isSelected = selectedPlan?.id === plan.id;
            return (
              <Plan
                key={plan.id}
                plan={plan}
                isSelected={isSelected}
                onSelect={selectPlan}
              />
            );
          })
        ) : (
          <StyledPlanInfo disabled>
            <i>None</i>
          </StyledPlanInfo>
        )}
      </StyledDraftsContainer>
      <StyledDraftsContainer>
        <h3>Drafts</h3>
        {otherUserDrafts?.length ? (
          otherUserDrafts.map((plan) => {
            const isSelected = selectedPlan?.id === plan.id;
            return (
              <Plan
                key={plan.id}
                plan={plan}
                isSelected={isSelected}
                onSelect={selectPlan}
              />
            );
          })
        ) : (
          <StyledPlanInfo disabled>
            <i className="mb-6">None</i>
          </StyledPlanInfo>
        )}
      </StyledDraftsContainer>
    </StyledPlanConfiguratorPanel>
  );
};

export default VarianceConfiguratorPanel;
