import { IPlan, ISimulation, ISite } from "@ehabitation/ts-utils/browser";
import InfoSections from "Components/TRA/InfoSections"; // Import the InfoSections component
import { TraView } from "Pages/sites/tra-qsra/TraView";
import { PlanRequiredWrapper } from "../PlanRequiredWrapper";
import { RouteContainer } from "../RouteContainer";
import { SimulationRequiredWrapper } from "../SimulationRequiredWrapper";

export function TraQsraRoute(
  basePlan: IPlan | undefined,
  site: ISite,
  baseSimulation: ISimulation | undefined,
  baseSimulationLoading: boolean,
  requestBaseSimulation: () => void,
  setCategoriseOpen: (value: boolean) => void,
  setPrintableLink: ((link: string) => void) | undefined,
  setHelpLink: ((link: string) => void) | undefined
) {
  return (
    <RouteContainer>
      <PlanRequiredWrapper
        plan={basePlan}
        site={site}
        renderContent={({ plan: basePlan }) => (
          <>
          <div className="flex justify-center items-center w-full">
              <InfoSections
                siteId={site.id}
                planId={basePlan.id}
                site={site}
              />
          </div>
            <SimulationRequiredWrapper
              simulation={baseSimulation}
              simulationLoading={baseSimulationLoading}
              requestSimulation={requestBaseSimulation}
              plan={basePlan}
              setCategoriseOpen={setCategoriseOpen}
              renderContent={({ simulation }) => {
                return (
                  <TraView
                    site={site}
                    simulation={simulation}
                    setPrintableLink={setPrintableLink}
                    setHelpLink={setHelpLink}
                    orgId={site.orgId}
                    siteId={site.id}
                    planId={basePlan.id}
                  />
                );
              }}
            />
          </>
        )}
      />
    </RouteContainer>
  );
}
