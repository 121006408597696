import { ITask } from "@ehabitation/ts-utils/browser";
import { useEffect, useRef, useState } from "react";
import { pipe } from "lodash/fp";
import { compareTwoStrings } from "string-similarity";
import { useIsMounted } from "hooks";

export interface TaskMatch {
  id: string;
  title: string;
  taskCategory: any;
  checked: boolean;
}

export const taskMatch = (task: ITask, selectedTask: ITask) => {
  return (
    compareTwoStrings(
      formatCategoryMatchingString(task.title),
      formatCategoryMatchingString(selectedTask.title)
    ) > 0.4
  );
};

const formatCategoryMatchingString = (str: string) =>
  pipe(trim, lowerCase, removeSpecialCharacters)(str);

const trim = (str: string) => str.trim();
const lowerCase = (str: string) => str.toLowerCase();
const removeSpecialCharacters = (str: string) =>
  str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "");

const yieldMainThread = (delay = 0) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};

export const useSimilarTasks = (
  tasks: Map<string, ITask>,
  orderedTaskIds: string[],
  nonWBSTaskIds: Set<string>,
  targetTask?: ITask
) => {
  const [orderedSimilarTaskIds, setOrderedSimilarTaskIds] = useState<string[]>(
    []
  );
  const [loading, setLoadingMatches] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const selectedTaskRef = useRef<ITask | null>(null);

  useEffect(() => {
    selectedTaskRef.current = targetTask || null;
  }, [targetTask]);

  useEffect(() => {
    if (
      targetTask &&
      !targetTask.WBS &&
      !targetTask.milestone &&
      !targetTask.levelOfEffort &&
      nonWBSTaskIds
    ) {
      let updatedOrderedSimilarTaskIds: string[];
      setLoadingMatches(true);
      let count = 0;
      yieldMainThread(100)
        .then(async () => {
          for (const taskId of orderedTaskIds) {
            const task = tasks.get(taskId);
            if (!task) return;
            count++;
            if (
              task.id !== targetTask.id &&
              !task.WBS &&
              !task.milestone &&
              !task.levelOfEffort &&
              taskMatch(task, targetTask)
            ) {
              updatedOrderedSimilarTaskIds = updatedOrderedSimilarTaskIds || [];
              updatedOrderedSimilarTaskIds.push(task.id);
            }
            if (count % 100 === 0) {
              if (selectedTaskRef.current !== targetTask) {
                return;
              } else {
                await yieldMainThread(10);
              }
            }
          }
        })
        .then(() => {
          if (isMounted() && selectedTaskRef.current === targetTask) {
            setOrderedSimilarTaskIds(updatedOrderedSimilarTaskIds || []);
            setLoadingMatches(false);
          }
        });
    } else {
      setOrderedSimilarTaskIds(
        orderedSimilarTaskIds.length ? [] : orderedSimilarTaskIds
      );
      setLoadingMatches(false);
    }
  }, [targetTask, tasks, nonWBSTaskIds, orderedTaskIds]);

  return {
    orderedSimilarTaskIds,
    loading,
  };
};
