import { useRef, useEffect } from "react";

// autofocus wasn't working on textarea, so handling focus manually here
export const useFocusToTextarea = (mode: "edit" | "read") => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (mode !== "edit") return;
    if (!textareaRef?.current) return;
    textareaRef.current.focus();
  }, [mode]);

  return textareaRef;
};
