import { FC, useEffect, useState } from "react";
import { FiAlertCircle, FiX } from "react-icons/fi";

interface AlertProps {
  alerting: boolean;
  alertText: string;
  placement?: any;
}

export const Alert: FC<AlertProps> = ({ alertText, alerting }) => {
  const [showAlert, setShowAlert] = useState<boolean>(alerting);
  useEffect(() => setShowAlert(alerting), [alerting]);
  return (
    <>
      {showAlert && (
        <div
          className="flex flex-row justify-center items-center absolute left-8 bottom-8 w-fit bg-pink-100 py-2 px-4 rounded-md"
          role="alert"
        >
          <FiAlertCircle className="icon font-extrabold text-4xl text-red-400" />
          <p className="p-4">{alertText}</p>
          <FiX
            className="icon text-3xl cursor-pointer"
            onClick={() => setShowAlert(false)}
          />
        </div>
      )}
    </>
  );
};