import { useCallback } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "store";
import { clickTab } from "store/tabs";
import {
  setCurrentTaskId,
  toggleShowSubtasks,
  selectMilestoneId,
  selectTaskIsMilestone,
} from "store/tasks";
import { RootState } from "store";
import scrollIntoView from "scroll-into-view";

export const useSetCurrentTask = (taskId: string) => {
  const dispatch = useAppDispatch();

  const isMilestone = useAppSelector((state: RootState) =>
    selectTaskIsMilestone(state, taskId)
  );

  const milestoneId = useAppSelector((state: RootState) =>
    selectMilestoneId(state, taskId)
  );

  const setCurrentTask = useCallback(() => {
    batch(() => {
      dispatch(toggleShowSubtasks(taskId));
      dispatch(setCurrentTaskId(taskId));
      if (isMilestone) dispatch(clickTab("Gantt"));
    });

    const elementId = isMilestone ? milestoneId : taskId;

    if (document.getElementById(elementId)) {
      scrollIntoView(document.getElementById(elementId)!, {
        align: {
          left: 0.05,
          lockY: true,
        },
      });
    }
  }, [dispatch, taskId, isMilestone, milestoneId]);

  const eventTaskSelected = new Event("onSelectActivity");
  window.dispatchEvent(eventTaskSelected);

  return { setCurrentTask };
};
