import styled from "styled-components";
import { spacing, flex, fontSize, font, highlightHover } from "styles";

export const StyledThresholdsTab = styled.div`
  padding: ${spacing.l} ${spacing.l} 0 ${spacing.l};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .not-modifiable {
    margin-top: 5rem;

    p {
      color: red;
    }
  }

  .thresholds-message {
    margin: 1rem 0;
  }
`;

export const StyledTypeButton = styled.button`
  ${flex("row", "flex-start", "center")};
  ${font("body")}
  ${highlightHover()};
  cursor: pointer;
  border: none;
  background-color: white;
  margin-bottom: ${spacing.l};
  outline: none;

  p {
    margin-right: ${spacing.s};
    font-size: ${fontSize.l};
    font-weight: 300;
  }

  .icon {
    font-size: ${fontSize.l};
  }
`;
