import { ISimulation, ITask } from "@ehabitation/ts-utils/browser";
import {
  SimulationLevel,
  SimulationPlanResult,
  SimulationTaskResult,
} from "helpers";
import moment from "moment";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { BsSortDown } from "react-icons/bs";
import { useVirtual } from "react-virtual";
import { impactMitigationLevels } from "../constants";
import { RiskDriversRow } from "./RiskDriversRow";
import { useSiteOpenPageEvent } from "hooks";
import { FilterButton } from "@ehabitation/ui";
import Select, { GroupProps, components } from "react-select";
import { useSimulationRiskDriverResult } from "../hooks";
import {
  MilestoneOption,
  useRiskDriverMilestones,
  useSimulationRiskDrivers,
} from "./helpers";
import { EndDatesTable } from "../components/EndDatesTable";

export interface RiskDriversViewProps {
  siteId: string;
  planId: string;
  baseSimulation?: ISimulation;
  simulationPlanResult?: SimulationPlanResult;
  tasks: (ITask & { simulation: SimulationTaskResult })[];
  milestones: (ITask & { simulation: SimulationTaskResult })[];
  setPrintableLink: (id: string) => void;
  setHelpLink: (id: string) => void;
  handleOpenCategorise: (taskId: string) => void;
}

export const RiskDriversView: FC<RiskDriversViewProps> = ({
  siteId,
  planId,
  baseSimulation,
  simulationPlanResult,
  tasks,
  milestones,
  setPrintableLink,
  setHelpLink,
  handleOpenCategorise,
}) => {
  const [selectedSimLevel, setSelectedSimLevel] =
    useState<SimulationLevel>("80");

  const [sortOrder, setSortOrder] = useState<"weatherDays" | "correlation">(
    "correlation"
  );
  const [showIsCriticalPath, setShowIsCriticalPath] = useState<boolean>(false);

  useSiteOpenPageEvent("risk-drivers", siteId, planId);

  const { simulationRiskDriverResult, simulationRiskDriverResultLoading } =
    useSimulationRiskDriverResult(baseSimulation);

  const { milestoneOptions } = useRiskDriverMilestones(
    milestones,
    selectedSimLevel,
    simulationRiskDriverResult
  );

  const [selectedMilestone, setSelectedMilestone] = useState<MilestoneOption>({
    value: "",
    label: "Project End Date",
  });

  const { riskDriverViewContext } = useSimulationRiskDrivers(
    tasks,
    milestones,
    selectedMilestone,
    selectedSimLevel,
    sortOrder,
    showIsCriticalPath,
    simulationRiskDriverResult
  );

  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);
  const { virtualItems: virtualRows, totalSize } = useVirtual({
    size: riskDriverViewContext.visibleTasks.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(() => 140, []),
    overscan: 20,
  });

  const paddingTop =
    (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0) + 16;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const storeIsCriticalPathFilter = (value: boolean) => {
    value
      ? localStorage.removeItem(
          `riskDrivers_isCriticalPath_${siteId}_${planId}`
        )
      : localStorage.setItem(
          `riskDrivers_isCriticalPath_${siteId}_${planId}`,
          `${value}`
        );
    setShowIsCriticalPath(value);
  };

  useEffect(() => {
    setPrintableLink(`/print/${siteId}/${planId}/risk-drivers`);
    return () => setPrintableLink("");
  }, [siteId, planId]);

  useEffect(() => {
    localStorage.setItem(
      `risk_drivers_selectedSimLevel_${siteId}_${planId}`,
      `${selectedSimLevel}`
    );
  }, [selectedSimLevel, siteId, planId]);

  useEffect(() => {
    localStorage.setItem(
      `risk_drivers_sortOrder_${siteId}_${planId}`,
      `${sortOrder}`
    );
  }, [sortOrder, siteId, planId]);

  useEffect(() => {
    setHelpLink("https://ehab.co/docs/how-to-use-the-risk-drivers-dashboard/");
    return () => setHelpLink("");
  }, []);

  const Group = (props: GroupProps<MilestoneOption, false>) => (
    <div>
      <components.Group {...props} />
    </div>
  );

  interface GroupedOption {
    readonly label: string;
    readonly options: readonly MilestoneOption[];
  }

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: "Whole Plan",
      options: [{ value: "", label: "Project End Date" }],
    },
    {
      label: "Milestones",
      options: milestoneOptions,
    },
  ];

  return (
    <>
      {
        <>
          <div className="px-4 pt-4">
            <div className="flex justify-between items-center">
              <div>Milestone</div>
              <div className="w-full">
                <Select<MilestoneOption>
                  menuPosition="fixed"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  className={`disabled:text-gray-500 absolute inset-0 bg-transparent pl-2 w-1/5`}
                  isSearchable={true}
                  value={selectedMilestone}
                  onChange={(e) => {
                    if (e) setSelectedMilestone(e);
                  }}
                  isLoading={simulationRiskDriverResultLoading}
                  defaultValue={{ value: "", label: "Project End Date" }}
                  options={groupedOptions}
                  components={{ Group }}
                  formatOptionLabel={(option) => (
                    <div className="flex flex-row items-center">
                      {`${option.label}`}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="items-center pb-20">
              <EndDatesTable
                selectedSimLevel={selectedSimLevel}
                setSelectedSimLevel={setSelectedSimLevel}
                simulationPlanResult={simulationPlanResult}
                selectedMilestoneResult={riskDriverViewContext?.milestoneResult}
                enableSubColumn2={false}
              />
            </div>
            {/* <div className="col-span-4 xl:col-span-3 bg-gray-50 rounded-md gap-12 flex justify-between items-center mb-1">
              <div className="flex gap-4 px-4 py-2 items-center">
                <div className="text-xl">Filter by</div>
                <FilterButton
                  isEnabled={showIsCriticalPath}
                  onClick={() => storeIsCriticalPathFilter(!showIsCriticalPath)}
                  className="text-xl py-[0.35rem]"
                >
                  Only activities with critical path
                </FilterButton>
              </div>
            </div> */}
            <div
              className="pl-4 min-h-[36rem] flex-auto overflow-auto pt-6"
              ref={tasksContainerRef}
            >
              <div style={{ height: totalSize + 16 }}>
                <table className="mb-8 divide-y w-full table-fixed border-spacing-y-4">
                  <thead className="bg-gray-50 sticky top-0 z-[8]">
                    <tr>
                      <th className="w-10"></th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 px-4 text-left text-2xl font-semibold text-gray-900"
                      >
                        Risk Drivers
                      </th>
                      <th className="w-10"></th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-8 text-left text-2xl font-semibold text-gray-900"
                      >
                        <div className="flex justify-between">
                          <div className="px-1">Weather Days </div>
                          <div>
                            <BsSortDown
                              className={`text-3xl ${
                                sortOrder === "weatherDays" && "text-green-500"
                              }`}
                              onClick={() => setSortOrder("weatherDays")}
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-8 text-left text-2xl font-semibold text-gray-900"
                      >
                        <div className="flex justify-between">
                          <div className="px-1">
                            Correlation to Planned end date
                          </div>
                          <div>
                            <BsSortDown
                              className={`text-3xl ${
                                sortOrder === "correlation" && "text-green-500"
                              }`}
                              onClick={() => setSortOrder("correlation")}
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paddingTop > 0 && (
                      <tr>
                        <td style={{ height: `${paddingTop}px` }} />
                      </tr>
                    )}
                    {virtualRows.length == 0 && (
                      <tr>
                        <td colSpan={3}>
                          <h2>No weather impact on selected end date</h2>
                        </td>
                      </tr>
                    )}
                    {virtualRows.map((virtualRow) => {
                      const task =
                        riskDriverViewContext.visibleTasks[virtualRow.index];
                      return (
                        <RiskDriversRow
                          selectedSimLevel={selectedSimLevel}
                          handleOpenCategorise={handleOpenCategorise}
                          key={task.id}
                          task={task}
                          maxWeatherDays={riskDriverViewContext.maxWeatherDays}
                          hierarchy={task.hierarchy}
                          correlation={task.correlation}
                        />
                      );
                    })}
                    {paddingBottom > 0 && (
                      <tr>
                        <td style={{ height: `${paddingBottom}px` }} />
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};
