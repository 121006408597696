//Firebase
import { db } from "firebaseConfig";
//Types
import { IUser } from "@ehabitation/ts-utils/browser";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const fetchSingleUser = async (userId: string) => {
  try {
    const userDoc = await getDoc(doc(db, `users/${userId}`));

    if (userDoc.exists()) {
      const userDocWithId = { ...userDoc.data(), id: userDoc.id } as IUser;
      return userDocWithId;
    } else {
      console.log("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting user data:", error);
    return null;
  }
};

//Sets the site on the user's document to persist when loading the app
export const persistActiveSite = async (userId: string, siteId: string) => {
  try {
    const userDoc = doc(db, `users/${userId}`);
    await updateDoc(userDoc, { site: siteId });
  } catch (error) {
    console.error(error);
  }
};
