import {
    Button,
    LoadingWrapper,
    Modal
} from "@ehabitation/ui";
import { ChangeEvent, FC, useRef, useState } from "react";
import { RiskMatrixFileType } from "./helpers";





interface RiskMatrixModalProps {
    completeFunction: Function;
    loading: boolean;
    exportDefaultTemplate: () => Promise<void>;
    exporting: boolean;
    closeModal: () => void;
    errors: string[];
  }
  
export  const ImportRiskMatrixModal: FC<RiskMatrixModalProps> = ({
    closeModal,
    loading,
    completeFunction,
    exportDefaultTemplate,
    exporting,
    errors,
  }) => {
    const [importFile, setImportFile] = useState<File | undefined>();
    const [fileType, setFileType] = useState<RiskMatrixFileType>();
    const [unknownFile, setUnknownFile] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
  
    const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event || !event.target || !event.target.files) return; //error?
      const file = event.target.files[0];
      const fileType =
        (file.name
          .split(".")
          .at(-1)
          ?.toLocaleUpperCase() as RiskMatrixFileType) ?? undefined;
      if (!Object.keys(RiskMatrixFileType).includes(fileType)) {
        setUnknownFile(true);
        setFileType(RiskMatrixFileType.UNKNOWN);
      } else {
        setUnknownFile(false);
        setFileType(fileType);
      }
      setImportFile(file);
    };
  
    return (
      <Modal
        id="import-risk-matrix-modal"
        handleCloseModal={closeModal}
        title="Import Risk Matrix"
      >
        <div className="h-full w-full pb-12 pt-8 overflow-hidden grid place-content-center">
          <div className="relative flex flex-col gap-6 w-[72rem] p-4 min-h-[28rem]">
            <LoadingWrapper loading={loading} message={"Importing Risk Matrix"}>
              <div className="flex flex-col pl-4 flex-grow justify-between">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-6">
                      <Button
                        isCompact
                        color="black"
                        onClick={() => inputRef.current?.click()}
                      >
                        {importFile ? "Change File" : "Select File"}
                      </Button>
                      <p>
                        <input
                          className="hidden"
                          type="file"
                          onChange={handleFile}
                          accept=".xlsx,.csv"
                          ref={inputRef}
                        />
                        File:{" "}
                        <strong>
                          {importFile ? importFile.name : "None selected"}
                        </strong>
                      </p>
                    </div>
                    <p className="pl-2 text-gray-600 italic">
                      {"Supported: CSV, Microsoft Excel XLSX"}
                    </p>
                    {unknownFile && (
                      <>
                        <p className="text-red-600 pl-2">
                          File type could not be detected. Please try a different
                          file, or specify the type:&nbsp;&nbsp;
                          <select
                            className="text-black p-4 border-gray-400 border py-2"
                            onChange={(e) =>
                              setFileType(e.target.value as RiskMatrixFileType)
                            }
                            value={fileType}
                          >
                            <option value={RiskMatrixFileType.UNKNOWN}>
                              ---
                            </option>
                            {Object.keys(RiskMatrixFileType)
                              .filter(
                                (plan) => plan !== RiskMatrixFileType.UNKNOWN
                              )
                              .map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                          </select>
                        </p>
                      </>
                    )}
                  </div>
                  <Button
                    color="white"
                    isCompact
                    className="whitespace-nowrap"
                    onClick={async () => await exportDefaultTemplate()}
                    disabled={exporting}
                  >
                    {!exporting ? "Download Template" : "Downloading Template..."}
                  </Button>
                </div>
                <div className="self-end flex flex-col gap-2">
                  <Button
                    onClick={async () => await completeFunction(importFile)}
                    disabled={!importFile}
                  >
                    Upload
                  </Button>
                  {errors.length > 0 &&
                    errors.map((error) => (
                      <span
                        key={error}
                        className="col-span-2 text-red-600 text-center"
                      >
                        {error}
                      </span>
                    ))}
                </div>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </Modal>
    );
  };