import { AppThunk } from "store";
import { batch } from "react-redux";
import {
  setDivisionManagementError,
  setDivisionManagementIsLoading,
  setSelectedDivisionTab,
} from "store/divisionManagement";
import { createNewDivision, updateDivision } from "./helpers";
import { IDivision } from "@ehabitation/ts-utils/browser";

export const triggerCreateNewDivision: AppThunk =
  (newDivision: IDivision) => async (dispatch) => {
    dispatch(setDivisionManagementIsLoading(true));

    try {
      await createNewDivision(newDivision);

      batch(() => {
        dispatch(setDivisionManagementIsLoading(false));
        dispatch(setSelectedDivisionTab("Divisions"));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setDivisionManagementError(error.message)); //Trigger toast
        dispatch(setDivisionManagementIsLoading(false));
      });
    }
  };

export const triggerUpdateDivision: AppThunk =
  (newDivision: IDivision) => async (dispatch) => {
    dispatch(setDivisionManagementIsLoading(true));

    try {
      await updateDivision(newDivision);

      batch(() => {
        dispatch(setDivisionManagementIsLoading(false));
        dispatch(setSelectedDivisionTab("Divisions"));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setDivisionManagementError(error.message)); //Trigger toast
        dispatch(setDivisionManagementIsLoading(false));
      });
    }
  };
