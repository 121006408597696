import styled from "styled-components";
import { flex, grays } from "styles";

interface IStyledProgressBar {
  percentageComplete: number;
}

export const StyledProgressBar = styled.div<IStyledProgressBar>`
  ${flex("row", "center", "center")};
  height: 2.7rem;
  width: 100%;
  border: 0.1rem solid ${grays.dark};

  > div {
    height: 100%;

    > p {
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .completed {
    width: ${(props) => props.percentageComplete}%;
    background: ${grays.veryLight};
    border-right: ${(props) =>
      props.percentageComplete === 100
        ? "none"
        : `0.1rem solid ${grays.light}`};
    display: ${(props) => (props.percentageComplete === 0 ? "none" : "block")};
  }

  .remaining {
    background: white;
    width: ${(props) => 100 - props.percentageComplete}%;
    display: ${(props) =>
      props.percentageComplete === 100 ? "none" : "block"};
  }
`;
