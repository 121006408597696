import { useContextMenu } from "hooks";
import { deleteDivisionById } from "./thunks";
import { useAppDispatch } from "store";
import { batch } from "react-redux";
import {
  setSelectedDivisionTab,
  setSelectedDivision,
} from "store/divisionManagement";

export const useHandleContextMenuActions = (divisionId: string | undefined) => {
  const dispatch = useAppDispatch();

  const { anchorEl, handleOpenContextMenu, handleCloseContextMenu } =
    useContextMenu();

  const handleDeleteDivision = () => {
    dispatch(deleteDivisionById(divisionId));
    handleCloseContextMenu();
  };

  const handleEditDivision = () => {
    batch(() => {
      dispatch(setSelectedDivision(divisionId!));
      dispatch(setSelectedDivisionTab("Details"));
    });
  };

  return {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
    handleDeleteDivision,
    handleEditDivision,
  };
};
