import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";

import {
  ICustomClaims,
  IDivision,
  IOrganisation,
  IProject,
  IUser,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { Button } from "@ehabitation/ui";
import { roles } from "./constants";
import { useGrantRole } from "./useGrantRole";
import { useCollectionQuery } from "hooks";
import { Chip } from "./Organisations";
import { ImPencil } from "react-icons/im";
import { RxCross2 } from "react-icons/rx";
import { useLocation } from "react-router-dom";

const tooltipClasses =
  "relative before:absolute before:shadow-lg before:bg-gray-300 before:z-10 before:content-tip before:right-[50%] before:top-[125%] before:mb-2 before:bg-white before:rounded-md before:px-2 hover:before:flex before:hidden";

export const UserRoleChip: FC<{
  claims: ICustomClaims;
  organisations: IOrganisation[];
  divisions: IDivision[];
  projects: IProject[];
  isWarning?: boolean;
  onClear?: () => void;
  onEdit?: () => void;
}> = ({
  claims,
  organisations,
  divisions,
  projects,
  isWarning = false,
  onClear,
  onEdit,
}) => {
  const tooltipText = onClear && "Clear";
  const label = onClear ? "Clear" : onEdit ? "Edit" : "";
  return (
    <div className="relative">
      <div
        className={`flex gap-2 items-center ${
          onClear || onEdit ? `${tooltipClasses} cursor-pointer` : ""
        }`}
        aria-label={label}
        data-tip={tooltipText}
        role={(onClear || onEdit) && "button"}
        onClick={onClear || onEdit}
      >
        <Chip warning={isWarning} baseBGClass="bg-green-200">
          <div className="flex gap-2 flex-wrap items-center">
            <div className=" font-normal">{claims.role}</div>
            {claims.organisation ? (
              <div>
                Org:{" "}
                {
                  organisations.find(({ id }) => claims.organisation === id)
                    ?.name
                }
              </div>
            ) : null}
            {claims.division ? (
              <div>
                Div: {divisions.find(({ id }) => claims.division === id)?.name}
              </div>
            ) : null}
            {claims.project ? (
              <div>
                Proj: {projects.find(({ id }) => claims.project === id)?.name}
              </div>
            ) : null}
          </div>
        </Chip>
        {onClear ? <RxCross2 className="font-3xl" /> : null}
        {onEdit ? <ImPencil /> : null}
      </div>
    </div>
  );
};

const GrantRoleForm = () => {
  const [role, setRole] = React.useState<UserRole | "">("");
  const [orgId, setOrgId] = React.useState<string>("");
  const [divisionId, setDivisionId] = React.useState<string>("");
  const [projectId, setProjectId] = React.useState<string>("");

  const location = useLocation();

  const {
    isLoading: isOrgsLoading,
    data: organisations,
    error: orgsError,
  } = useCollectionQuery<IOrganisation>("organisations");
  const {
    isLoading: isDivisionsLoading,
    data: divisions,
    error: divisionsError,
  } = useCollectionQuery<IDivision>("divisions");
  const {
    isLoading: isProjectsLoading,
    data: projects,
    error: projectsError,
  } = useCollectionQuery<IProject>("projects");
  const {
    isLoading: isUsersLoading,
    data: users,
    error: usersError,
  } = useCollectionQuery<IUser>("users");

  const {
    email,
    handleUpdateEmail,
    handleSubmit,
    isHandlingRequest,
    error: grantRoleError,
    foundUser,
    isValid,
  } = useGrantRole(
    role,
    orgId,
    divisionId,
    projectId,
    users,
    location.state?.email
  );

  const isLoadingData =
    isOrgsLoading || isDivisionsLoading || isProjectsLoading || isUsersLoading;

  const error: string | undefined =
    grantRoleError ||
    orgsError?.message ||
    divisionsError?.message ||
    projectsError?.message ||
    usersError?.message;

  return (
    <div>
      <form
        onSubmit={(event) => {
          handleSubmit(event)
            .then(() => {
              setRole("");
              setOrgId("");
              setDivisionId("");
              setProjectId("");
            })
            .catch(() => {
              // do not clear, hook populates error state.
            });
        }}
        className={`flex flex-col items-stretch gap-8 ${
          isHandlingRequest ? "animate-pulse" : ""
        }`}
      >
        <h1 className="self-center">Edit User Org / Role</h1>
        {isLoadingData ? (
          <CircularProgress color="inherit" size={"2rem"} />
        ) : (
          <>
            <div className="self-center max-w-[400px] flex flex-col gap-4">
              <div className="flex gap-4 justify-evenly flex-wrap">
                <div className="input-group flex-grow min-w-[300px]">
                  <label htmlFor="email">Email Address</label>
                  <input
                    className={`block w-full rounded-md border-gray-300 border shadow-sm focus:ring-indigo-500 py-1 px-2 ${
                      foundUser ? "bg-green-200" : email ? "bg-red-200" : ""
                    }`}
                    name="role"
                    id="email"
                    type="email"
                    value={email}
                    onChange={handleUpdateEmail}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="role">Role</label>
                <select
                  name="role"
                  id="role"
                  value={role}
                  onChange={(e) => {
                    setOrgId("");
                    setDivisionId("");
                    setProjectId("");
                    setRole(e.target.value as UserRole | "");
                  }}
                  className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-2 px-2"
                >
                  <option value={""}>----</option>
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="org">Org</label>
                <select
                  disabled={(isValid && !orgId) || isHandlingRequest}
                  name="orgId"
                  id="org"
                  value={orgId}
                  onChange={(e) => {
                    setDivisionId("");
                    setProjectId("");
                    setOrgId(e.target.value);
                  }}
                  className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-2 px-2"
                >
                  <option value={""}>----</option>
                  {organisations!.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="division">Division</label>
                <select
                  disabled={
                    (isValid && !divisionId) || !orgId || isHandlingRequest
                  }
                  name="divisionId"
                  id="division"
                  value={divisionId}
                  onChange={(e) => {
                    setProjectId("");
                    setDivisionId(e.target.value);
                  }}
                  className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-2 px-2"
                >
                  <option value={""}>----</option>
                  {divisions!
                    .filter(({ orgId: dOrgId }) => orgId === dOrgId)
                    .map((division) => (
                      <option key={division.id} value={division.id}>
                        {division.name}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label htmlFor="project">Project</label>
                <select
                  disabled={
                    (isValid && !projectId) || !divisionId || isHandlingRequest
                  }
                  name="projectId"
                  id="project"
                  value={projectId}
                  onChange={(e) => {
                    setProjectId(e.target.value);
                  }}
                  className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-2 px-2"
                >
                  <option value={""}>----</option>
                  {projects!
                    .filter(
                      ({ divisionId: pDivisionId }) =>
                        divisionId === pDivisionId
                    )
                    .map((projects) => (
                      <option key={projects.id} value={projects.id}>
                        {projects.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {foundUser ? (
                <>
                  <div className="italic text-center font-bold ">{`${foundUser.firstName} ${foundUser.lastName} (${foundUser.email})`}</div>
                </>
              ) : email ? (
                <div className="italic">
                  No user found with that email address
                </div>
              ) : (
                <div className="italic">Enter a valid email address</div>
              )}
              {foundUser && (
                <div className="pb-6 flex items-center justify-between gap-4">
                  <UserRoleChip
                    isWarning={isValid}
                    claims={{
                      role: foundUser.role,
                      organisation: foundUser.orgId,
                      division: foundUser.divisionId,
                      project: foundUser.project,
                    }}
                    organisations={organisations!}
                    divisions={divisions!}
                    projects={projects!}
                  />
                  <div> {`-->`} </div>
                  <div className="flex flex-col items-center justify-center">
                    {isValid && role ? (
                      <div className="flex gap-1 items-center">
                        <UserRoleChip
                          onClear={
                            isHandlingRequest
                              ? undefined
                              : () => {
                                  setRole("");
                                  setOrgId("");
                                  setDivisionId("");
                                  setProjectId("");
                                }
                          }
                          claims={{
                            role,
                            organisation: orgId,
                            division: divisionId,
                            project: projectId,
                          }}
                          organisations={organisations!}
                          divisions={divisions!}
                          projects={projects!}
                        />
                      </div>
                    ) : (
                      <p className="italic">Enter a valid role assignment</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <Button
              type="submit"
              className="self-center"
              disabled={!isValid || !foundUser || isHandlingRequest}
            >
              Change Role
            </Button>

            <p className="text-red-400 font-bold text-center mt-0">{error}</p>
          </>
        )}
      </form>
    </div>
  );
};

export default GrantRoleForm;
