import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { selectUserDivisionId, selectRole } from "store/auth";
import { IFormError, ProjectMode } from "types";
import { triggerCreateNewProject, updateExistingProject } from "./thunks";
import { validateNewForm } from "helpers/validation/validation";
import { newProjectSchema } from "./validation";
import { selectSelectedProjectDetails } from "store/projectManagement";
import { IProject, UserRole } from "@ehabitation/ts-utils/browser";

export const useNewProjectForm = () => {
  const dispatch = useAppDispatch();
  const selectedProjectDetails = useAppSelector(selectSelectedProjectDetails);
  const role = useAppSelector(selectRole);
  const divisionId = useAppSelector(selectUserDivisionId);

  const [newProject, updateNewProject] = useState<Partial<IProject>>(() => {
    if (!selectedProjectDetails)
      return {
        name: "",
        divisionId: role === "divisionAdmin" && divisionId ? divisionId : "",
        description: "",
        projectCode: "",
        projectMode: ProjectMode.SingleSite,
        siteMappingActivityCodeGroup: "",
        visibility: UserRole.planner,
      };
    else return selectedProjectDetails;
  });

  const [error, setError] = useState<IFormError | null>(null);

  const handleUpdateForm = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    e.persist();
    const { name, value } = e.target;
    updateNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const [isValid, error] = await validateNewForm(
      newProjectSchema,
      newProject
    );

    if (!isValid && error) setError(error);

    if (isValid && newProject.id) dispatch(updateExistingProject(newProject));
    else if (isValid) dispatch(triggerCreateNewProject(newProject));
  };

  return {
    isNewProject: selectedProjectDetails === null,
    role,
    error,
    newProject,
    handleUpdateForm,
    handleSubmit,
  };
};
