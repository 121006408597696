import { firebaseFunction } from "helpers";

export enum RiskMatrixFileType {
  CSV = "CSV",
  XLSX = "XLSX",
  UNKNOWN = "UNKNOWN",
}

export const importRiskMatrix = async (
  tier: string,
  tierObjectId: string,
  fileName: string,
  orgId: string,
  userId: string
) => {
  const importRiskMatrix = firebaseFunction<{
    errors: string[];
    message: string;
    success: string;
  }>("ImportRiskMatrix");
  return await importRiskMatrix({
    tier,
    tierObjectId,
    fileName,
    orgId,
    userId,
  });
};

export const exportRiskMatrix = async (
  riskMatrixId: string,
  fileType: RiskMatrixFileType,
  orgId: string,
  hasVisibility?: boolean
) => {
  const exportRiskMatrix = firebaseFunction<{
    error: string;
    message: string;
    file: string;
    hasVisibility?: boolean
  }>("ExportRiskMatrix");
  return await exportRiskMatrix({
    riskMatrixId,
    fileType,
    orgId,
    hasVisibility
  });
};
