//Types
import { AppThunk } from "store";
//Action Creators
import { updateUserState } from "store/auth";
//Helpers
import { fetchSingleUser } from "helpers";

export const fetchUserMetadata: AppThunk = () => async (dispatch, getState) => {
  const state = getState();
  const userId = state.auth.user;

  if (!userId) return;

  const userData = await fetchSingleUser(userId);

  if (!userData) return;

  const userState = {
    orgId: userData.orgId,
    divisionId: userData.divisionId,
    projectId: userData.project,
    siteId: userData.site,
    sites: userData.sites,
    email: userData.email,
    featureFlags: userData.featureFlags,
  };

  return dispatch(updateUserState(userState));
};
