//React
import { StyledCapitalisedTooltip } from "elements";
import React, { FC } from "react";
//Icons
import { FiAlertCircle, FiAlertTriangle } from "react-icons/fi";
//Styles
import { StyledAlertIcon } from "./styles";

interface ConfidenceAlertProps {
  criticalPath?: boolean;
  thresholdDiff?: boolean;
}

const ConfidenceAlertIcon: FC<ConfidenceAlertProps> = ({
  criticalPath,
  thresholdDiff,
}) => {
  return (
    <StyledAlertIcon data-testid="alert-icon">
      {thresholdDiff ? (
        <FiAlertTriangle className="icon alert" />
      ) : (
        <FiAlertCircle className="icon" />
      )}
      {criticalPath && (
        <div className="critical-path-alert" data-testid="critical-pip" />
      )}
    </StyledAlertIcon>
  );
};

export default ConfidenceAlertIcon;
