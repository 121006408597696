import { ISite } from "@ehabitation/ts-utils/browser";
import { Location } from "@ehabitation/ui";
import { getStaticMapUrl } from "helpers/location";
import { FC } from "react";

export const MapSite: FC<{ site: ISite }> = ({ site }) => {
  const { location } = site!;

  return site ? (
    <div className="max-w-screen-md self-stretch">
      <div className="flex flex-col w-full">
        <div
          className="flex flex-col md:flex-row justify-between items-start gap-12 aspect-[21/9] bg-cover"
          style={{
            backgroundImage: `url('${getStaticMapUrl(
              location.latitude,
              location.longitude,
              "580x206",
              18
            )}')`,
          }}
        ></div>
        <div className="py-4 flex flex-col gap-1 w-full">
          {/* <PlanWeatherTitle site={site} /> */}
          <div className="flex gap-1 text-base px-2 pb-4">
            <Location
              address={site?.address}
              longitude={site?.location?.longitude}
              latitude={site?.location?.latitude}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
