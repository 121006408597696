import styled from "styled-components";
import { flex, spacing, grays } from "styles/index";

export const StyledDateControls = styled.div`
  ${flex("row", "center", "center")};
  border-top: 0.1rem solid ${grays.light};
  min-width: 40rem;
  width: 100%;

  .detail-box {
    ${flex("row", "center", "center")};
    width: 50%;

    label {
      padding-right: ${spacing.m};
    }
  }

  .detail-box:not(:last-of-type) {
    border-right: 0.1rem solid ${grays.light};
  }
`;
