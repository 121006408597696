import axios from "axios";
import fileDownload from "js-file-download";
import { storage } from "firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import { firebaseFunction } from "helpers";

interface IHistoricalWeatherDataParams {
  site: string;
  start: number;
  end: number;
  types: string;
}

export const triggerHistoricalWeatherDataDownload = async ({
  site,
  start,
  end,
  types,
}: IHistoricalWeatherDataParams) => {
  const generateHistoricData = firebaseFunction<{ result: string }>(
    "GenerateHistoricData"
  );
  const historicData = await generateHistoricData({
    site,
    start,
    end,
    types,
  });

  const filename = historicData.data.result;

  const fileURL = await getDownloadURL(
    ref(storage, `/historicCsv/${filename}`)
  );

  const file = await axios.get(fileURL, {
    responseType: "blob",
  });

  fileDownload(file.data as Blob, "Historic Weather Data.csv");
};

export const triggerTaskDataDownload = async (site: string, plan: string) => {
  const generateTaskExport = firebaseFunction<{ result: string }>(
    "GenerateTaskExport"
  );
  const response = await generateTaskExport({
    site,
    plan,
  });

  const filename = response.data.result;

  const fileURL = await getDownloadURL(ref(storage, filename));

  const file = await axios.get(fileURL, {
    responseType: "blob",
  });

  fileDownload(file.data as Blob, "Task Export Data.xlsx");
};
