import { FC, useEffect, useState } from "react";
// Assuming these components and assets are exported from their respective modules

import {
  StatBox, StatText
} from "@ehabitation/ui";
import { Tooltip } from "@material-ui/core";
import ECMWFLogo from "../../assets/images/ECMWF_logo.svg";
import IBMLogo from "../../assets/images/The_Weather_Company_IBM.svg";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import _ from "lodash";
import moment from "moment";

interface SourceType {
  type: string;
  difference: string;
}

interface SourcesBoxProps {
  sources?: string[];
}

export const SourcesBox: FC<SourcesBoxProps> = ({
  sources,
}) => {

  const getDocuments = async (sources: string[]) => {
    return _.compact(
      await Promise.all(
        sources.map(async (source) => {
          const forecastDoc = await getDoc(
            doc(collection(db, "sources"), source)
          );
          if (forecastDoc.exists()) {
            const endDate = forecastDoc.data()?.timeSeriesEnd?.toDate();
            const startDate = forecastDoc.data()?.timeSeriesStart?.toDate();
            if (endDate && startDate) {
              const diff = Math.round(
                moment(endDate).diff(moment(startDate), "years", true)
              );
              return {
                type: forecastDoc.data()?.type,
                difference: diff,
              };
            } else
              return {
                type: forecastDoc.data()?.type,
                difference: undefined,
              };
          }
        })
      )
    )
  }

  const [sourceType, setSourceType] = useState<SourceType[]>([]);

  useEffect(() => {
    if (sources) {
      getDocuments(sources).then((result) => {
        const formattedResult = result.map(item => ({
          type: item.type,
          difference: item.difference !== undefined ? item.difference.toString() : "N/A"
        }));
        setSourceType(formattedResult);
      });
    }
  }, [sources]);

  return (
    <StatBox
      title="Historic Source"
      subTitle=""
      subClassName=""
      className={`md:col-span-1`}
    >
      <div className="flex justify-between gap-10 items-center">
        {sources && sources.length > 0 ? (
          sourceType.map(({ type, difference}) =>
            type === "IBM" ? (
              <Tooltip
                key={type}
                arrow
                title={
                  <span className="text-xl">
                    Data sourced from IBM Weather Company
                  </span>
                }
              >
                <div>
                  <img src={IBMLogo} alt="IBM Logo" className="h-24 pr-20 pl-20" />
                  {difference && type && (
                    <div className="pt-4 pr-20 pl-20">{`${difference} years of ${type}`}</div>
                  )}
                </div>
              </Tooltip>
            ) : type === "ECMWF" ? (
              <Tooltip
                key={type}
                arrow
                title={
                  <span className="text-xl">
                    Data sourced from ECMWF (European Centre for Medium-Range Weather Forecasts)
                  </span>
                }
              >
                <div>
                  <img src={ECMWFLogo} alt="ECMWF Logo" className="h-12 pr-20 pl-20" />
                  {difference && type && (
                    <div className="pt-4 pr-20 pl-20">{`${difference} years of ${type}`}</div>
                  )}
                </div>
              </Tooltip>
            ) : (
              <></>
            )
          )
        ) : (
          <StatText value="Not Available" />
        )}
      </div>
    </StatBox>
  );
  
};

