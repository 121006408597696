import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { ITask, ITaskCategory } from "@ehabitation/ts-utils/browser";
import moment from "moment";
import Select from "react-select";
import { getLevelBorderColorClass } from "@ehabitation/ui";

interface CategorySelectProps {
  task: ITask;
  parent?: boolean;
  updateTaskCategory?: (category: string) => void;
  selectPlaceholderOverride?: string;
  isCollapsed?: boolean;
  toggleCollapsed?: Function;
  highlighted: boolean;
  open: boolean;
  handleClick?: Function;
  selected: boolean;
  handleSelect?: Function;
  disabled: boolean;
  categoryOptions: {
    label: string;
    value: string;
  }[];
  pendingCategory?:
    | ITaskCategory
    | {
        name: string;
      };
  setBlinkMultiSelect?: (blink: boolean) => void;
}

export const groupName = (name: string) => {
  const groupName = name.replace(/([A-Z])/g, " $1").trim();
  return groupName[0].toUpperCase() + groupName.slice(1);
};

export const CategorySelectRow: FC<CategorySelectProps> = ({
  task,
  parent,
  updateTaskCategory,
  selectPlaceholderOverride,
  isCollapsed,
  toggleCollapsed,
  highlighted,
  open,
  handleClick,
  selected: selected,
  handleSelect,
  disabled,
  categoryOptions,
  pendingCategory,
  setBlinkMultiSelect,
}) => {
  const isTask = !(task.WBS || task.milestone || task.levelOfEffort);
  const leftPadding = `${
    (isTask ? task.wbsHierarchyLevel! - 1 : task.wbsHierarchyLevel!) * 2 +
    (!isTask && task.wbsHierarchyLevel ? 0.5 : 0)
  }rem`;

  const startString = moment(task.start).format("DD/MM/YYYY");
  const endString = moment(task.end).format("DD/MM/YYYY");

  const categorySelectOverride = task.milestone ? (
    <div className="px-2 text-blue-500 tracking-wider">Milestone</div>
  ) : task.levelOfEffort ? (
    <div className="px-2 text-blue-500 tracking-wider">Level of Effort</div>
  ) : null;

  return (
    <>
      <tr
        data-task-id={task.id}
        onClick={
          handleClick && !disabled
            ? (handleClick as MouseEventHandler)
            : undefined
        }
        role={handleClick && !disabled ? "button" : ""}
        className={`h-16 transition-colors ${
          open
            ? "border-2 border-black"
            : handleClick && !disabled
            ? " hover:bg-blue-50"
            : ""
        } ${parent ? "font-bold" : ""} ${highlighted ? "bg-blue-100" : ""}`}
      >
        {isTask && (
          <td
            className="border-x border-b w-10 relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <label
              className="absolute inset-0 grid place-content-center"
              htmlFor={`${task.id}-check`}
            >
              <input
                id={`${task.id}-check`}
                className="mx-auto"
                disabled={disabled}
                type="checkbox"
                checked={selected}
                onChange={handleSelect as ChangeEventHandler}
              />
              <span className="sr-only">Select Row</span>
            </label>
          </td>
        )}
        <td
          className={`px-4 truncate border-x border-b  max-w-0 relative`}
          style={{ paddingLeft: leftPadding }}
          colSpan={isTask ? 2 : 3}
        >
          <div className="flex items-center">
            <span
              className={`border-l-2 absolute top-0 bottom-0 ${getLevelBorderColorClass(
                task
              )}`}
            />
            <span className={toggleCollapsed ? "" : "pr-4"}>
              {toggleCollapsed && (
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    toggleCollapsed();
                  }}
                  disabled={disabled}
                  className={`font-normal px-2 py-0 group`}
                  aria-label={isCollapsed ? "expand WBS" : "collapse WBS"}
                  title={isCollapsed ? "Expand WBS" : "Collapse WBS"}
                >
                  <span
                    className={`transition-colors rounded-lg bg-gray-200 px-2 ${
                      disabled
                        ? ""
                        : "group-hover:font-bold group-hover:bg-gray-100"
                    } `}
                  >
                    <span className="relative bottom-[1px]">
                      {isCollapsed ? "+" : "–"}
                    </span>
                  </span>
                </button>
              )}
            </span>
            {task.milestone && (
              <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                M:
              </div>
            )}
            {task.levelOfEffort && (
              <div className="pr-2 text-blue-400 font-bold font-sans leading-5">
                LOE:
              </div>
            )}
            {!isTask && task.title}
            {isTask &&
              (task.taskCode ? `${task.taskCode} - ` : "") + task.title}
          </div>
        </td>
        {!task.start || !task.end || startString === endString ? (
          <td colSpan={2} className="-m-px border-x border-b text-center">
            {task.start ? startString : endString}
          </td>
        ) : (
          <>
            <td className="px-4 -m-px border-x border-b">{startString}</td>
            <td className="px-4 -m-px border-x border-b">{endString}</td>
          </>
        )}
        <td
          className="-m-px border-x border-b  relative"
          onClick={isTask ? undefined : (e) => e.stopPropagation()}
        >
          {categorySelectOverride ? (
            categorySelectOverride
          ) : (
            <div className="absolute inset-0 ">
              <CategorySelect
                objId={task.objectId}
                value={isTask ? task.taskType : pendingCategory?.name || ""}
                showClear={!!task.taskType || task.WBS}
                disabled={disabled || !updateTaskCategory}
                updateTaskCategory={updateTaskCategory}
                placeholder={selectPlaceholderOverride}
                suggestions={task.taskCategory?.suggestions}
                categoryOptions={categoryOptions}
                setBlinkMultiSelect={setBlinkMultiSelect}
              />
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export const CategorySelect: FC<{
  objId?: string;
  value?: string;
  showClear?: boolean;
  disabled?: boolean;
  updateTaskCategory?: (category: string) => void;
  categoryOptions: {
    label: string;
    value: string;
  }[];
  suggestions?: any;
  placeholder?: string;
  hidePlaceholderOption?: boolean;
  setBlinkMultiSelect?: (blink: boolean) => void;
  blinkMultiSelect?: boolean;
}> = ({
  objId,
  value,
  showClear,
  disabled,
  updateTaskCategory,
  categoryOptions: categoryOptionsProp,
  suggestions,
  placeholder = "--Select a category--",
  hidePlaceholderOption,
  setBlinkMultiSelect,
  blinkMultiSelect = false,
}) => {
  const categoryOptions = hidePlaceholderOption
    ? categoryOptionsProp
    : [
        { label: placeholder, value: "", isDisabled: true },
        ...categoryOptionsProp,
      ];
  const [inputValue, setInputValue] = useState("");
  return (
    <div
      className={`h-full flex w-full font-normal ${
        disabled ? "opacity-60" : ""
      } ${disabled || !updateTaskCategory ? "absolute" : "cursor-pointer"}`}
    >
      <div
        className={`${
          disabled || !updateTaskCategory
            ? "absolute z-10 h-full flex w-full font-normal bg-transparent"
            : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          (disabled || !updateTaskCategory) && setBlinkMultiSelect
            ? setBlinkMultiSelect(true)
            : undefined;
        }}
      ></div>
      <Select
        id={`catSelect_${objId}`}
        inputId={`catSelectInput_${objId}`}
        key={`select_key__${value}`}
        options={categoryOptions}
        className={`w-screen max-w-96 py-0 flex-shrink bg-transparent max-h-1 ${
          blinkMultiSelect ? "animate-bounce" : ""
        }`}
        isDisabled={disabled || !updateTaskCategory}
        isSearchable={true}
        menuPosition="fixed"
        onChange={(event) => updateTaskCategory?.(event?.value || "")}
        value={categoryOptions.find((option) => option.value === value)}
        onFocus={() => setInputValue(" ")}
        inputValue={inputValue}
        onInputChange={setInputValue}
      />
      {showClear && updateTaskCategory ? (
        <button
          onClick={(event) => {
            event.stopPropagation();
            updateTaskCategory("");
          }}
          disabled={disabled || !updateTaskCategory}
          className={`min-w-0 flex-shrink-0 pl-[0.1rem] pr-[0.2rem] py-[0.1rem] group transition-colors rounded-lg`}
          title="Clear Category"
        >
          {/* <p className="text-center text-sm table-cell align-middle h-8 transition-colors rounded-lg text-black bg-gray-200 px-2 right-4">Clear</p> */}
          <p className="text-center text-sm table-cell align-middle h-8 transition-colors rounded-lg text-black px-2 right-4">
            Clear
          </p>
        </button>
      ) : (
        <div className="w-14 p-[0.1rem] flex-shrink-0" />
      )}
    </div>
  );
};
