import {
  ITaskCategory
} from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers/functions";
import { useCallback } from "react";
import { ISiteHeatmapData } from "./SingleCategoryHeatmap";

export const useGetHeatmap = (
  setHeatmap: React.Dispatch<
    React.SetStateAction<ISiteHeatmapData | undefined>
  >
) => {
  const fetchHeatmap = useCallback(
    async (site_id: string, category: ITaskCategory) => {
      const generateSiteHeatmap = firebaseFunction("GenerateSiteHeatmap");
      try {
        const response = (await generateSiteHeatmap({
          site: site_id,
          riskmatrix: { [category.name]: category },
        })) as {
          data?: {
            heatmap: ISiteHeatmapData;
            error: string[];
          };
        };

        if (response.data !== undefined) {
          setHeatmap(response.data.heatmap);
        } else {
          setHeatmap(undefined); // Explicitly set to null to indicate no data.
        }
      } catch (err) {
        console.error(err);
        setHeatmap(undefined); // Consider whether returning null is appropriate in case of error.
      }
    },
    [setHeatmap]
  );

  function cleanThresholds(thresholds: any) {
    // Exclude specific keys and remove null values
    const { activityCode, categoryCode, shift, ...rest } = thresholds;
    return Object.fromEntries(Object.entries(rest).filter(([key, value]) => value !== null));
  }

  return { fetchHeatmap, cleanThresholds };
};