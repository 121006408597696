//React
import React from "react";
//Material UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Store
import { setError, selectUserManagementError } from "store/userManagement";
import { useAppDispatch, useAppSelector } from "store";

const UserManagementToast = () => {
  const dispatch = useAppDispatch();
  const userManagementError = useAppSelector(selectUserManagementError);

  const handleClose = () => {
    dispatch(setError(""));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={5000}
      onClose={handleClose}
      open={!!userManagementError}
    >
      <Alert severity="error" onClose={handleClose}>
        {userManagementError}
      </Alert>
    </Snackbar>
  );
};

export default UserManagementToast;
