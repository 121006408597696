import { useState } from "react";
import {
  generateHistoricSiteStatsReport,
  downloadHistoricSiteStatsReport,
} from "./helpers";
import { ISite } from "@ehabitation/ts-utils/browser";
import { resetHistoricalStats } from "helpers";

interface HistoricSiteStatsProps {
  site: ISite;
  projectId?: string;
  onSuccess?: () => void;
}

export const useDownloadSiteStatsReport = ({
  site,
  projectId,
  onSuccess,
}: HistoricSiteStatsProps) => {
  const [reportGenerating, setReportGenerating] = useState<boolean>(false);
  const [reportDownloading, setReportDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loadingFailed = () => {
    setReportDownloading(false);
    setReportGenerating(false);
    setError(
      "An error occured while generating the site statistics report. Please try again later."
    );
  };

  const downloadReport = async () => {
    if (!site || !projectId) {
      setError("Unknown failure");
      return;
    }

    try {
      setReportDownloading(true);
      downloadHistoricSiteStatsReport(site.historicalStatsFile!, site.id);
      setReportDownloading(false);
    } catch (err) {
      console.error(err);
      loadingFailed();
    }
  };

  const generateReport = async () => {
    if (!site || !projectId) {
      setError("Unknown failure");
      return;
    }

    try {
      await generateHistoricSiteStatsReport({
        site: site.id,
      });

      onSuccess && onSuccess();
    } catch (err) {
      console.error(err);
      loadingFailed();
    }
  };

  return {
    downloadReport,
    generateReport,
    reportGenerating,
    reportDownloading,
    error,
    resetError: () => setError(null),
  };
};
