import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectCurrentPlanId } from "store/project";
import { selectLoadedPlanId } from "store/tasks";
import { selectFileIsLoading, selectIsUpdating } from "store/ui";

export const useLoadingMessage = () => {
  const loadedPlanId = useAppSelector(selectLoadedPlanId);
  const currentPlanId = useAppSelector(selectCurrentPlanId);
  const isSwitchingPlan = loadedPlanId && currentPlanId !== loadedPlanId;
  const isUpdating = useAppSelector(selectIsUpdating);
  const fileIsLoading = useAppSelector(selectFileIsLoading);

  const isLoading = isUpdating || fileIsLoading || isSwitchingPlan;

  const loadingMessage = useMemo(() => {
    if (isUpdating) {
      return "Updating Plan...";
    } else if (isSwitchingPlan) {
      return "Switching Plans...";
    } else if (fileIsLoading) {
      return "Preparing your file...";
    }
  }, [isUpdating, isSwitchingPlan, fileIsLoading]);

  return { loadingMessage, isLoading };
};
