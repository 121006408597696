import React, { FC } from "react";
import { ITask } from "@ehabitation/ts-utils/browser";
import { StyledDateControls } from "./styles";
import { DateTimePicker } from "@material-ui/pickers";
import { useDateControls } from "./useDateControls";

interface IProps {
  currentTask: ITask;
  disabled: boolean;
}

const DateControls: FC<IProps> = ({ currentTask, disabled }) => {
  const { handleEditDate, setDisableFinish, setDisableStart } =
    useDateControls(currentTask);

  return (
    <StyledDateControls>
      <div className="detail-box">
        <label>Start</label>

        <DateTimePicker
          disabled={disabled}
          ampm={false}
          onChange={(date) => {
            handleEditDate(date, "start");
          }}
          shouldDisableDate={setDisableStart}
          value={currentTask.start}
          data-testid="start-date-picker"
          hideTabs
        />
      </div>

      <div className="detail-box">
        <label>Finish</label>

        <DateTimePicker
          disabled={disabled}
          ampm={false}
          onChange={(date) => {
            handleEditDate(date, "end");
          }}
          shouldDisableDate={setDisableFinish}
          value={currentTask.safetyEnd}
          data-testid="end-date-picker"
          hideTabs
        />
      </div>
    </StyledDateControls>
  );
};

export default DateControls;
