import { useCallback } from "react";
import { batch } from "react-redux";
import { ITask } from "@ehabitation/ts-utils/browser";
import { useAppDispatch, useAppSelector } from "store";
import { selectTaskTriggerModel, setTaskModified } from "store/ui";
import {
  batchUpdateTask,
  allTasksAsArraySelector,
  allTasksSelector,
} from "store/tasks";
import { recalculateWbsTask } from "../Components/Plan/TabContainer/helpers";
import { selectActiveSiteId } from "store/auth";
import { selectCurrentPlanId } from "store/project";
import { logTaskModifyEvent } from "helpers/analytics";
import { updateWbsAggregations } from "helpers";

export const useProcessTaskUpdates = () => {
  const taskTriggerModel = useAppSelector(selectTaskTriggerModel);
  const allTasksAsArray = useAppSelector(allTasksAsArraySelector);
  const siteId = useAppSelector(selectActiveSiteId);
  const planId = useAppSelector(selectCurrentPlanId);
  const allTasks = useAppSelector(allTasksSelector);
  const dispatch = useAppDispatch();

  const handleTaskUpdate = useCallback(
    async (inTasks?: ITask[]) => {
      if (!inTasks) {
        console.error("no tasks selected, not updating anything!");
        return;
      }

      const modifiedTaskObj = {};
      for (const inTask of inTasks) {
        // Update wbs with new values and return to be added to modifiedTasks
        const wbs = recalculateWbsTask(inTask, allTasksAsArray!);

        Object.assign(modifiedTaskObj, {
          [inTask.id!]: {
            ...inTask,
            // override the risk with an empty array
            risks: [],
          },
        });

        if (wbs) {
          Object.assign(modifiedTaskObj, {
            [wbs.id!]: {
              ...wbs,
              // override the risk with an empty array
              risks: [],
            },
          });
        }
      }
      const newTaskObj = await updateWbsAggregations(
        allTasks!,
        modifiedTaskObj
      );

      batch(() => {
        dispatch(batchUpdateTask(newTaskObj));
        dispatch(setTaskModified(true));
      });

      // log event with google analytics
      if (planId && siteId) logTaskModifyEvent(planId, siteId);
    },
    [dispatch, taskTriggerModel, allTasksAsArray]
  );

  return {
    handleTaskUpdate,
  };
};
