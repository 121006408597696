import { selectUserId } from "store/auth";
import { useAppSelector } from "store";
import { useIsMounted } from "hooks";
import { useEffect, useState } from "react";
import { ISite, transformSiteDoc } from "@ehabitation/ts-utils/browser";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "firebaseConfig";

export const usePersonalSites = () => {
  const isMounted = useIsMounted();
  const [sites, setSites] = useState<ISite[] | null>();
  const [loadingSites, setLoadingSites] = useState(true);
  const userId = useAppSelector(selectUserId) || "";

  useEffect(() => {
    const ref = query(
      collection(db, "sites"),
      where("owner", "==", userId),
      where("deletedAt", "==", null)
    );
    const unSubscribe = onSnapshot(
      ref,
      (snap) => {
        if (isMounted()) {
          if (!snap.empty) {
            // add all sites to sites state
            const sites = snap.docs.map((doc) => {
              const data = doc.data();
              return transformSiteDoc(doc.id, data) as ISite;
            });
            setSites(sites);
            setLoadingSites(false);
          } else {
            setSites(null);
            setLoadingSites(false);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
    return () => unSubscribe();
  }, [userId]);

  return { sites, initialisingSites: loadingSites };
};
