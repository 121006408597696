import { createSelector } from "reselect";

import { selectDelayDistribution } from "store/tasks";

type DelayDistributionArray = {
  activityEndDate: string;
  simulations: number;
}[];

export const selectDelayDistributionAsArray = createSelector(
  [selectDelayDistribution],
  (delayDistribution) => {
    const delayDistributionArray: DelayDistributionArray = [];

    if (!delayDistribution) return delayDistributionArray;

    Object.keys(delayDistribution).forEach((date) => {
      delayDistributionArray.push({
        activityEndDate: date,
        simulations: delayDistribution[date],
      });
    });

    return delayDistributionArray.sort(
      (a, b) =>
        new Date(a.activityEndDate).getTime() -
        new Date(b.activityEndDate).getTime()
    );
  }
);
