import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ICharts } from "types";

export interface IUIState {
  isUpdating: boolean;
  isLoadingPlan: boolean;
  ganttFirstDate: Date | null;
  ganttLastDate: Date | null;
  taskModified: boolean;
  isRevertingToSnapshot: boolean;
  showDependencies: boolean;
  fileIsLoading: boolean;
  hasFileError: boolean;
  taskTriggerModel: boolean;
  tasksUpdating: boolean;
  selectedChart: ICharts;
}

export const initialState: IUIState = {
  isUpdating: false,
  taskTriggerModel: false,
  isLoadingPlan: false,
  ganttFirstDate: null,
  ganttLastDate: null,
  taskModified: false,
  isRevertingToSnapshot: false,
  showDependencies: true,
  fileIsLoading: false,
  hasFileError: false,
  tasksUpdating: false,
  selectedChart: "weather",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsLoadingPlan: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPlan = action.payload;
    },

    setGanttFirstDate: (state, action: PayloadAction<Date>) => {
      state.ganttFirstDate = action.payload;
    },

    setGanttLastDate: (state, action: PayloadAction<Date>) => {
      state.ganttLastDate = action.payload;
    },

    setTaskModified: (state, action: PayloadAction<boolean>) => {
      state.taskModified = action.payload;
    },

    setIsRevertingToSnapshot: (state, action: PayloadAction<boolean>) => {
      state.isRevertingToSnapshot = action.payload;
    },

    toggleShowDependencies: (state) => {
      state.showDependencies = !state.showDependencies;
    },

    setFileIsLoading: (state, action: PayloadAction<boolean>) => {
      state.fileIsLoading = action.payload;
    },

    setHasFileError: (state, action: PayloadAction<boolean>) => {
      state.hasFileError = action.payload;
    },

    setTaskTriggerModel: (state, action: PayloadAction<boolean>) => {
      state.taskTriggerModel = action.payload;
    },

    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },

    clearUIState: () => {
      return initialState;
    },

    setSelectedChart: (state, action: PayloadAction<ICharts>) => {
      state.selectedChart = action.payload;
    },

    setTasksUpdating: (state, action: PayloadAction<boolean>) => {
      state.tasksUpdating = action.payload;
    },
  },
});

export const {
  toggleShowDependencies,
  setIsLoadingPlan,
  clearUIState,
  setFileIsLoading,
  setGanttFirstDate,
  setHasFileError,
  setIsRevertingToSnapshot,
  setTaskModified,
  setGanttLastDate,
  setTaskTriggerModel,
  setIsUpdating,
  setSelectedChart,
  setTasksUpdating,
} = uiSlice.actions;

export const selectTaskModified = (state: RootState) => {
  return state.ui.taskModified;
};

export const selectFileIsLoading = (state: RootState) => state.ui.fileIsLoading;

export const selectIsLoadingPlan = (state: RootState) => state.ui.isLoadingPlan;

export const selectHasFileError = (state: RootState) => state.ui.hasFileError;

export const selectShowDependencies = (state: RootState) =>
  state.ui.showDependencies;

export const selectTaskTriggerModel = (state: RootState) =>
  state.ui.taskTriggerModel;

export const selectIsUpdating = (state: RootState) => state.ui.isUpdating;

export const selectSelectedChart = (state: RootState) => state.ui.selectedChart;

export const selectTasksUpdating = (state: RootState) => state.ui.tasksUpdating;

export default uiSlice.reducer;
