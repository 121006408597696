import Histogram from "./Histogram";
import WeatherImpactsChart from "./WeatherImpactsChart";
import SimulationBreakdown from "./SimulationBreakdown/SimulationBreakdown";
import Heading from "../Heading/Heading";
import { StyledRisk } from "./styles";
import { useAppSelector } from "store";
import { selectMaxDelayDays, selectViolationData } from "store/tasks";

const Risk = () => {
  const maxDelayDays = useAppSelector(selectMaxDelayDays);
  const violationData = useAppSelector(selectViolationData);
  return (
    <StyledRisk>
      <div className="distribution-histogram">
        <Heading text="Distribution Histogram" />
        <Histogram />
      </div>

      <div className="weather-impacts">
        <Heading text="Impact Per Weather Type" />
        <WeatherImpactsChart
          maxDelayDays={maxDelayDays}
          violationData={violationData}
        />
      </div>

      <div className="simulation-breakdown">
        <Heading text="Simulation Breakdown" />
        <SimulationBreakdown />
      </div>
    </StyledRisk>
  );
};

export default Risk;
