import { IOrganisationSite, IOrganisationSites } from "types";
import {
  ICustomClaims,
  UserRole,
  ISite,
  transformSiteDoc,
  CollectionType,
} from "@ehabitation/ts-utils/browser";
import { db } from "firebaseConfig";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

interface ISubscribeToSites {
  role: UserRole;
  orgId: string;
  divisionId: string;
  projectId: string;
  availableProjects: string[];
  dispatcher: (sites: IOrganisationSites) => void;
  setReady: (bool: boolean) => void;
}
export const subscribeToSites = ({
  role,
  orgId,
  divisionId,
  projectId,
  availableProjects,
  dispatcher,
  setReady,
}: ISubscribeToSites) => {
  if (!availableProjects || !availableProjects.length) return setReady(true);
  const queryBase = determineQueryBase(role, orgId, divisionId, projectId);
  const unsubscribe = onSnapshot(
    queryBase,
    (snapshot) => {
      setReady(true);

      const organisationSites: IOrganisationSites = {};

      snapshot.forEach((doc) => {
        const site = transformSiteDoc(doc.id, doc.data());

        if (!availableProjects.includes(site.project)) {
          return;
        }

        // We don't need all the fields on ISite,
        // and probably wouldn't want to store all that data in the cache anyway
        const payload = {
          id: doc.id,
          address: site.address,
          description: site.description,
          startDate: site.startDate,
          endDate: site.endDate,
          historicThresholds: site.historicThresholds,
          location: site.location,
          name: site.name,
          status: site.status,
          projectManager: site.projectManager,
          project: site.project,
          divisionId: site.divisionId,
          mainPlanId: site.mainPlanId,
          orgId: site.orgId,
          forecastInUse: site.forecastInUse,
          realTimeInUse: site.realTimeInUse,
          creationDate: site.creationDate,
          visibility: site.visibility,
          siteCode: site.siteCode,
          historicGraphs: site.historicGraphs,
          historicalInUse: site.historicalInUse,
          historicalStatsFile: site.historicalStatsFile,
        } as IOrganisationSite & {
          historicGraphs: any;
        };

        organisationSites[doc.id] = payload;
      });

      dispatcher(organisationSites);
    },
    (error: Error) => {
      console.log("sites subscription failed:", {
        orgId,
        divisionId,
        projectId,
        role,
      });
      console.error(error);
    }
  );

  return unsubscribe;
};

export const fetchProjectSites = async (
  projectId: string,
  claims: ICustomClaims | null
) => {
  try {
    if (!claims) throw new Error("No user custom claims");
    const docs = await getDocs(
      determineProjectSitesQueryBase(claims, projectId)
    );

    if (docs) {
      const sitesData: ISite[] = [];
      docs.forEach((doc) => {
        const newSite = transformSiteDoc(doc.id, doc.data());
        sitesData.push(newSite);
      });
      return sitesData;
    } else {
      console.log("No sites data found!");
      return null;
    }
  } catch (err) {
    console.log("Error getting project sites:", err);
  }
};

const determineQueryBase = (
  role: UserRole,
  orgId: string,
  divisionId: string,
  projectId: string
) => {
  switch (role) {
    case UserRole.guest:
    case UserRole.orgAdmin: {
      return query(
        collection(db, CollectionType.Sites),
        where("orgId", "==", orgId)
      );
    }
    case UserRole.divisionAdmin: {
      return query(
        collection(db, CollectionType.Sites),
        where("divisionId", "==", divisionId)
      );
    }
    default: {
      return query(
        collection(db, CollectionType.Sites),
        where("project", "==", projectId)
      );
    }
  }
};

const determineProjectSitesQueryBase = (
  claims: ICustomClaims,
  projectId: string
) => {
  const sitesCollection = collection(db, CollectionType.Sites);
  let baseQuery = query(sitesCollection, where("project", "==", projectId));

  switch (claims.role) {
    case "guest":
    case "orgAdmin": {
      if (!claims.organisation) {
        throw new Error("orgAdmin organisation missing from custom claims");
      }
      baseQuery = query(baseQuery, where("orgId", "==", claims.organisation));
      break;
    }
    case "divisionAdmin": {
      if (!claims.division) {
        throw new Error("divisionAdmin division missing from custom claims");
      }
      baseQuery = query(baseQuery, where("divisionId", "==", claims.division));
      break;
    }
    default:
      break;
  }

  return baseQuery;
};

export const resetHistoricalStats = async (
  siteId: string,
  value: string = "generating"
) => {
  const siteRef = doc(db, CollectionType.Sites, siteId);
  return updateDoc(siteRef, {
    historicalStatsFile: value,
  });
};
