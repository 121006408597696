import { FC, useState, useRef, ChangeEvent, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { CollectionType, PlanStatus } from "@ehabitation/ts-utils/browser";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import { db, storage } from "firebaseConfig";
import { PlanFileType, PlanImportPage, PlanImportProps } from "../types";
import moment from "moment";
import { StyledTextInput } from "elements";
import { ref, uploadBytes } from "firebase/storage";
import { ProjectMode } from "types";

export const PlanImportFile: FC<PlanImportProps> = ({
  plan,
  setPage,
  site,
  project,
}) => {
  // Bring current full site all the way down into planSelector!
  const userId = useAppSelector(selectUserId);

  const [importFile, setImportFile] = useState<File | undefined>();
  const [fileType, setFileType] = useState<PlanFileType>();
  const [stagingPlan, setStagingPlan] = useState<boolean>(false);
  const [unknownFile, setUnknownFile] = useState<boolean>(false);
  const [planName, setPlanName] = useState<string>("");
  const [planNameError, setPlanNameError] = useState<string>("");
  const [firebaseUpload, setFirebaseUpload] = useState<any>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return; //error?
    const file = event.target.files[0];
    const fileType =
      (file.name.split(".").at(-1)?.toLocaleUpperCase() as PlanFileType) ??
      undefined;
    if (!Object.keys(PlanFileType).includes(fileType)) {
      setUnknownFile(true);
      setFileType(PlanFileType.UNKNOWN);
    } else {
      setUnknownFile(false);
      setFileType(fileType);
    }
    setImportFile(file);
  };

  const handleUploadFile = async () => {
    if (!importFile) return; //error?
    if (!planName.trim()) {
      setPlanNameError("Please enter a plan name");
      return;
    }
    setStagingPlan(true);
    const fileExtension = importFile.name.split(".").at(-1);
    const uploadRef = ref(
      storage,
      `/planFiles/${site.id}_${userId}_${moment().format("X")}.${fileExtension}`
    );
    const upload = await uploadBytes(uploadRef, importFile);
    setFirebaseUpload(upload);
    const newPlan = {
      createdAt: new Date(),
      owner: userId,
      project: site?.project,
      originalPlanName: importFile?.name,
      originalPlanFile: uploadRef.fullPath,
      title: planName.trim(),
      fileType,
      status: PlanStatus.staging,
      complete: false,
    };

    if (project?.projectMode == ProjectMode.MultiSiteWithActivityCodes)
      await addDoc(collection(db, CollectionType.Plans), {
        ...newPlan,
        siteMappingActivityCodeGroup: project.siteMappingActivityCodeGroup,
        projectMode: project.projectMode,
      });
    else
      await addDoc(collection(db, CollectionType.Plans), {
        ...newPlan,
        site: site.id,
      });
    setStagingPlan(false);
  };

  const loadingMessage =
    plan?.status === PlanStatus.staging
      ? "Processing file..."
      : plan?.status === PlanStatus.staged
      ? "Processing tasks..."
      : "Uploading file...";

  useEffect(() => {
    if (plan?.status === PlanStatus.categorised) {
      setPage(PlanImportPage.CategorySelection);
    }
    if (plan?.status === PlanStatus.multistaged) {
      setPage(PlanImportPage.Draft);
    }
  }, [plan]);

  const isFailedUpload = !!firebaseUpload && !stagingPlan && !plan;

  return (
    <div className="relative flex flex-col gap-6 w-[72rem] p-4 min-h-[28rem]">
      {isFailedUpload ? (
        <div>
          <p className="text-red-600">File processing failed.</p>
          <p className="text-red-600">
            Please reload this page and try again, or contact support@ehab.co if
            this error persists.
          </p>
        </div>
      ) : (
        <LoadingWrapper
          loading={stagingPlan || !!plan}
          message={loadingMessage}
        >
          <div className="space-y-2">
            <div className="flex items-center gap-6">
              <MonochromeButton
                level="primary"
                onClick={() => inputRef.current?.click()}
              >
                {importFile ? "Change File" : "Select File"}
              </MonochromeButton>
              <input
                className="hidden"
                type="file"
                onChange={handleFile}
                accept=".mpp,.xer,.xml,.pp"
                ref={inputRef}
              />
              <p>
                File:{" "}
                <strong>
                  {importFile ? importFile.name : "None selected"}
                </strong>
              </p>
            </div>
            <p className="pl-2 text-gray-600 italic">
              {
                "Supported: Primavera P6 XER & XML, Microsoft Project MPP & XML, Asta Powerproject PP"
              }
            </p>
            {unknownFile && (
              <>
                <p className="text-red-600 pl-2">
                  File type could not be detected. Please try a different file,
                  or specify the type:&nbsp;&nbsp;
                  <select
                    className="text-black p-4 border-gray-400 border py-2"
                    onChange={(e) =>
                      setFileType(e.target.value as PlanFileType)
                    }
                    value={fileType}
                  >
                    <option value={PlanFileType.UNKNOWN}>---</option>
                    {Object.keys(PlanFileType)
                      .filter((plan) => plan !== PlanFileType.UNKNOWN)
                      .map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </p>
              </>
            )}
          </div>
          <div>
            <StyledTextInput
              type="text"
              placeholder="Plan name..."
              aria-label="Plan Name"
              value={planName}
              disabled={!importFile}
              className="pl-2 border-black"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPlanName(e.target.value);
                setPlanNameError("");
              }}
            />
            <p className="ml-2 text-red-600">{planNameError}&nbsp;</p>
          </div>
          <MonochromeButton
            // align to right
            className="ml-auto self-end"
            width="md"
            level="secondary"
            disabled={!importFile || fileType === PlanFileType.UNKNOWN}
            onClick={handleUploadFile}
          >
            Upload
          </MonochromeButton>
        </LoadingWrapper>
      )}
    </div>
  );
};
