import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { BiPencil } from "react-icons/bi";
import { collection, GeoPoint, runTransaction, doc } from "firebase/firestore";
import z from "zod";
import { logCreatePersonalSiteEvent } from "helpers/analytics";
import {
  Button,
  Input,
  Location,
  LoadingWrapper,
  LoadingType,
  SidePanel,
  MonochromeButton,
} from "@ehabitation/ui";
import { db } from "firebaseConfig";
import { getStaticMapUrl, usePlace } from "helpers/location";
import { setActiveSiteId } from "Pages/sites/thunks";
import { useIsFreeUser, usePersonalSites, useIsMounted } from "hooks";
import { useAppSelector, useAppDispatch } from "store";
import { selectUserClaims, selectUserId } from "store/auth";
import { roundTo6DP } from "helpers";
import config from "config";
import { INewSiteForm, ISite, UserRole } from "@ehabitation/ts-utils/browser";
import { createNewSite as createNewProjectSite } from "Components/SiteManagement/NewSite/helpers";

export const newSiteSchema = z.object({
  name: z.string().min(1),
  description: z.string().min(1).optional(),
  location: z.object({
    lat: z.number(),
    long: z.number(),
  }),
  address: z.string(),
  siteCode: z.string().optional(),
  visibility: z.enum([UserRole.planner, UserRole.guest]).optional(),
});

export const getNameFromPlace = (place: any) => {
  const postCode = place.address_components.find((component: any) =>
    component.types.includes("postal_code")
  )?.long_name;
  const streetName = place.address_components.find((component: any) =>
    component.types.includes("route")
  )?.short_name;
  const preferredName =
    streetName && postCode ? `${streetName}, ${postCode}` : "";
  return preferredName || postCode || place.formatted_address;
};

export const createNewSite = async (
  newSite: z.infer<typeof newSiteSchema>,
  userId: string,
  orgId: string,
  projectId?: string,
  divisionId?: string
) => {
  const geoPointLocation = new GeoPoint(
    newSite.location.lat,
    newSite.location.long
  );

  if (projectId) {
    const location = newSite.location;
    const startDate = new Date();
    startDate.setUTCHours(12, 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 2);
    const newProjectSite: INewSiteForm = {
      name: newSite.name,
      description: newSite.description || newSite.name,
      lat: String(location.lat),
      long: String(location.long),
      address: newSite.address,
      startDate: startDate,
      endDate: endDate,
      orgId,
      project: projectId,
      division: divisionId!,
      siteCode: newSite.siteCode,
      visibility: newSite.visibility || UserRole.planner,
    };

    const createdSiteId = await createNewProjectSite(newProjectSite);
    return createdSiteId;
  } else if (userId) {
    const newSiteTyped: Partial<
      Pick<
        ISite,
        | "name"
        | "createdBy"
        | "location"
        | "address"
        | "creationDate"
        | "status"
        | "owner"
        | "orgId"
        | "deletedAt"
        | "deletedBy"
      >
    > = {
      name: newSite.name,
      location: geoPointLocation,
      address: newSite.address,
      createdBy: userId,
      creationDate: new Date(),
      deletedAt: null,
      deletedBy: null,
      status: "pending",
    };
    newSiteTyped.owner = userId;
    let newSiteId;
    await runTransaction(db, async (transaction) => {
      const newSiteDoc = doc(collection(db, "sites"));
      transaction.set(newSiteDoc, newSiteTyped);
      const siteUsersCollection = collection(newSiteDoc, "userPermissions");
      const siteUserDoc = doc(siteUsersCollection, userId);
      newSiteId = newSiteDoc.id;
      transaction.set(siteUserDoc, {
        roles: ["owner", "admin", "editor", "viewer"],
      });
    });
    return newSiteId;
  }
};

export const LocationSearch: FC<{
  location: { latitude?: number; longitude?: number };
  address?: string;
  setPlace: (place?: any) => any;
  isCompact?: boolean;
}> = ({
  location: { latitude, longitude },
  address,
  setPlace,
  isCompact = false,
}) => {
  return latitude && longitude ? (
    <div className="flex">
      <Location address={address} latitude={latitude} longitude={longitude} />
      <button
        type="button"
        onClick={() => setPlace()}
        className="text-4xl px-1 ml-2 transition-colors hover:bg-gray-200 rounded-lg"
      >
        <BiPencil className="text-gray-500" />
      </button>
    </div>
  ) : (
    <Autocomplete
      id="location"
      className={`${
        isCompact ? "p-2 pl-6" : "p-4 pl-12"
      } rounded-full border border-gray-500 outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89]`}
      placeholder="Search for a location..."
      apiKey={config.API_KEY as string}
      inputAutocompleteValue="off"
      options={{
        types: ["geocode"],
        bounds: { north: 54.09, south: 54.09, east: -2.89, west: -2.89 }, // Bias results to UK
      }}
      onPlaceSelected={setPlace}
      onChange={() => setPlace()}
      style={{ width: "100%" }}
      disabled={!!address}
    />
  );
};

const NewSiteForm: FC<{
  handleSiteCreated: (newSiteId?: string) => void;
}> = ({ handleSiteCreated }) => {
  const userId = useAppSelector(selectUserId);
  const claims = useAppSelector(selectUserClaims);

  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [userLatitude, setUserLatitude] = useState<string>("");
  const [userLongitude, setUserLongitude] = useState<string>("");
  const [userPlace, setUserPlace] = useState<any>();
  const [siteParseResult, setSiteParseResult] = useState<any>();

  const { place: latLongPlace, isLoading: isLoadinglatLongPlace } = usePlace(
    !userPlace ? userLatitude : "",
    !userPlace ? userLongitude : ""
  );

  const { latitude, longitude, address, name } = useMemo(() => {
    const targetPlace = userPlace || latLongPlace;
    return targetPlace
      ? {
          latitude: roundTo6DP(
            typeof targetPlace.geometry?.location?.lat === "function"
              ? targetPlace.geometry?.location?.lat()
              : targetPlace.geometry?.location?.lat
          ),
          longitude: roundTo6DP(
            typeof targetPlace.geometry?.location?.lng === "function"
              ? targetPlace.geometry?.location?.lng()
              : targetPlace.geometry?.location?.lng
          ),
          address: targetPlace.formatted_address,
          name: getNameFromPlace(targetPlace),
        }
      : {};
  }, [userPlace, latLongPlace]);

  useEffect(() => {
    const result = newSiteSchema.safeParse({
      name,
      location: { lat: latitude, long: longitude },
      address,
    });
    setSiteParseResult(result);
  }, [name, latitude, longitude, address]);

  return (
    <form
      className="flex flex-col w-full gap-6"
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        createNewSite(siteParseResult.data, userId!, claims!.organisation!)
          .then((newSiteId) => {
            handleSiteCreated(newSiteId);
          })
          .catch((error) => {
            console.error(error);
            alert("Error creating site");
          })
          .finally(() => {
            isMounted() && setIsLoading(false);
          });
      }}
    >
      <LocationSearch
        location={{
          latitude: latitude,
          longitude: longitude,
        }}
        address={address}
        setPlace={(place?: any) => {
          if (place) {
            setUserLatitude(
              String(roundTo6DP(place.geometry?.location?.lat()))
            );
            setUserLongitude(
              String(roundTo6DP(place.geometry?.location?.lng()))
            );
          } else {
            setUserLatitude("");
            setUserLongitude("");
          }
          setUserPlace(place);
        }}
      />
      <div
        className="aspect-[4/3] sm:aspect-[21/9] bg-cover bg-slate-300 flex items-start gap-4 mt-2 mb-6 relative"
        style={
          !isLoadinglatLongPlace && latitude && longitude
            ? {
                backgroundImage: `url(${getStaticMapUrl(latitude, longitude)})`,
              }
            : {}
        }
      >
        <div className="p-2 flex flex-col gap-4 w-full">
          <div className="p-2 flex flex-col sm:flex-row gap-4">
            <Input
              onChange={(e) => {
                setUserPlace(undefined);
                setUserLatitude(e.target.value);
              }}
              placeholder="Latitude"
              value={userLatitude}
              className="flex-grow"
            />

            <Input
              onChange={(e) => {
                setUserPlace(undefined);
                setUserLongitude(e.target.value);
              }}
              value={userLongitude}
              placeholder="Longitude"
              className="flex-grow"
            />
          </div>
          {!userPlace &&
            (userLatitude && userLongitude ? (
              <div className="w-full grow grid place-content-center p-2">
                <LoadingWrapper
                  type={LoadingType.subscriptions}
                  loading={isLoadinglatLongPlace}
                >
                  {latLongPlace ? null : (
                    <p className="">Unable to locate provided lat/long</p>
                  )}
                </LoadingWrapper>
              </div>
            ) : null)}
        </div>
      </div>
      <Button
        disabled={!siteParseResult?.success || isLoading}
        type="submit"
        parentClassName="self-center"
      >
        Submit
      </Button>
    </form>
  );
};

const CreateNewSite: FC = () => {
  const dispatch = useAppDispatch();

  const {
    sites: personalSites = [],
    initialisingSites: initialisingUserSites,
  } = usePersonalSites();

  const MAX_PAID_SITES = 5;
  const MAX_FREE_SITES = 1;

  const isFreeUser = useIsFreeUser();
  const navigate = useNavigate();

  const personalSitesCount = personalSites?.length || 0;
  const maxPersonalSitesCount = isFreeUser ? MAX_FREE_SITES : MAX_PAID_SITES;

  return (
    <div className="flex flex-col gap-2 items-center px-8 py-2 w-full min-h-full">
      <LoadingWrapper loading={initialisingUserSites}>
        {maxPersonalSitesCount - personalSitesCount === 0 ? (
          <SidePanel.Section>
            <div className="mt-[33%]">
              <p className="text-center">
                {personalSitesCount}/{maxPersonalSitesCount} forecast only sites
                used. Please delete an existing forecast only site to create
                more.
              </p>
              {isFreeUser ? (
                <p className="text-center">
                  Contact us at support@ehab.co to find out about all the
                  benefits of upgrading your account.
                </p>
              ) : null}
            </div>
          </SidePanel.Section>
        ) : (
          <SidePanel.Section>
            <h3 className="text-center font-bold">Add Forecast Only Site</h3>
            <h3 className="text-center">
              Get weather forecast and EHAB construction risk analysis.
            </h3>
            <NewSiteForm
              handleSiteCreated={(siteId?: string) => {
                dispatch(setActiveSiteId(siteId));
                logCreatePersonalSiteEvent(siteId!);
                navigate(`/`);
              }}
            />
          </SidePanel.Section>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default CreateNewSite;
