import { ISite } from "@ehabitation/ts-utils/browser";
import { MonochromeButton } from "@ehabitation/ui";
import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

interface JourneyStep {
  title: string;
  path?: string;
  action?: () => void;
}

interface JourneyWrapperProps {
  site: ISite;
  steps: JourneyStep[];
  children: ReactNode;
  forceStart: boolean; // corrected Boolean to boolean
}

const JourneyWrapper: FC<JourneyWrapperProps> = ({ steps, children, forceStart }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const { siteId } = useParams<{ siteId: string }>();

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      handleStepClick(currentStep + 1, steps[currentStep + 1]);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      handleStepClick(currentStep - 1, steps[currentStep - 1]);
    }
  };

  const handleStepClick = (index: number, step: JourneyStep) => {
    setCurrentStep(index);
    if (step.path) {
      navigate(`/sites/${siteId}${step.path}`);
    } else if (step.action) {
      step.action();
    }
  };

  useEffect(() => {
    if (forceStart) {
      // Set current step to the first one and navigate to its path
      setCurrentStep(0);
      const firstStep = steps[0];
      if (firstStep.path) {
        navigate(`/sites/${siteId}${firstStep.path}`);
      }
    }
  }, [forceStart]);

  return (
    <div className="w-full pr-20 pl-10 pt-10">
      <div className="flex justify-between mb-4 border-b pb-2">
        <div className="flex space-x-2">
          {steps.map((step, index) => (
            <button
              key={index}
              className={`py-2 px-4 cursor-pointer text-center transition-colors ${
                currentStep === index
                  ? "font-bold border-b-2 border-black"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => handleStepClick(index, step)}
            >
              {index + 1}. {step.title}
            </button>
          ))}
        </div>
        <div className="flex space-x-2">
          <MonochromeButton
            className="ml-10"
            level="secondary"
            onClick={prevStep}
            disabled={currentStep === 0}
          >
            Previous
          </MonochromeButton>
          <MonochromeButton
            className="ml-10"
            level="primary"
            onClick={nextStep}
            disabled={currentStep === steps.length - 1}
          >
            Next
          </MonochromeButton>
        </div>
      </div>
      <div className="p-4 w-full">{children}</div>
    </div>
  );
};

export default JourneyWrapper;
