import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectCurrentTab,
  selectMilestone,
  selectCurrentTask,
  selectLevelOfEffort,
} from "store/tasks";
import { clickTab, TabOption } from "store/tabs";
import { StyledTabs } from "./styles";

const Tabs: FC<{ isSimulating: boolean }> = ({ isSimulating }) => {
  const dispatch = useAppDispatch();

  const currentTab = useAppSelector(selectCurrentTab);
  const currentTask = useAppSelector(selectCurrentTask);
  const milestone = useAppSelector(selectMilestone);
  const levelOfEffort = useAppSelector(selectLevelOfEffort);

  const isRiskLoading = isSimulating;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const tabOption = e.currentTarget.innerHTML as TabOption;
    dispatch(clickTab(tabOption));
  };

  return (
    <StyledTabs>
      <button
        className={currentTab === "Gantt" ? "selected" : ""}
        onClick={handleClick}
        data-testid="plan-tab"
      >
        Gantt
      </button>

      <button
        className={currentTab === "Info" ? "selected" : ""}
        onClick={handleClick}
        disabled={
          !currentTask || !!milestone || isRiskLoading || !!levelOfEffort
        }
        data-testid="plan-tab"
      >
        Info
      </button>

      <button
        className={currentTab === "Thresholds" ? "selected" : ""}
        onClick={handleClick}
        disabled={
          !currentTask || isRiskLoading || !!milestone || !!levelOfEffort
        }
        data-testid="plan-tab"
      >
        Thresholds
      </button>

      <button
        className={currentTab === "Impact" ? "selected" : ""}
        onClick={handleClick}
        disabled={
          !currentTask || !!milestone || isRiskLoading || !!levelOfEffort
        }
        data-testid="plan-tab"
      >
        Impact
      </button>

      <button
        className={currentTab === "Risk" ? "selected" : ""}
        onClick={handleClick}
        disabled={
          !currentTask || !!milestone || isRiskLoading || !!levelOfEffort
        }
        data-testid="plan-tab"
      >
        Risk
      </button>
    </StyledTabs>
  );
};

export default Tabs;
