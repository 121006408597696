import { useMemo } from "react";
import { useAppSelector } from "store";
import { selectCurrentTaskEndDate, selectCurrentTaskRisks } from "store/tasks";
import { IConfidenceDetail } from "./types";
import moment from "moment";
import { HOURS_IN_A_DAY } from "utils";

export const useConfidenceProfiles = () => {
  const taskEnd = useAppSelector(selectCurrentTaskEndDate);
  const risks = useAppSelector(selectCurrentTaskRisks);

  const confidenceProfiles = useMemo(() => {
    const confidenceDetails: Record<string, IConfidenceDetail> = {};

    if (!risks || risks?.length === 0) return confidenceDetails;
    if (risks[0].hasOwnProperty("label") || risks[0].hasOwnProperty("width"))
      return confidenceDetails;

    let previousLabel = 0;

    risks.forEach((item) => {
      //The timestamp is broken so we need to use the label to calculate noOfDays and new end date
      const [confidenceLevel, { label }] = Object.entries(item)[0];

      //The label is additive so we diff it with the previous one to find no of extra days
      const newLabel = label - previousLabel;
      previousLabel = label;

      const extraDays = Math.floor(newLabel / HOURS_IN_A_DAY);

      confidenceDetails[confidenceLevel] = {
        noOfDaysAdded: extraDays,
        endDate: moment(taskEnd).add(extraDays, "days"),
      };
    });

    return confidenceDetails;
  }, [risks, taskEnd]);

  return confidenceProfiles;
};
