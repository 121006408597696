import { useEffect } from "react";
import {
  SitePagesType,
  logOpenPlannerEvent,
  logOpenSiteEvent,
  logOpenSitePage,
  logOpenSitePrintPage,
  setAnalyticsUserId,
} from "helpers/analytics";

export const useSetAnalyticsUserId = (userId?: string) => {
  useEffect(() => {
    if (userId) setAnalyticsUserId(userId);
  }, [userId]);
};

export const useOpenPlannerEvent = (siteId?: string) => {
  useEffect(() => {
    if (siteId) {
      logOpenPlannerEvent(siteId);
      logOpenSiteEvent(siteId); // also log open_site event incase user has bookmarked the planner page directly
    }
  }, [siteId]);
};

export const useOpenSiteEvent = (siteId?: string) => {
  useEffect(() => {
    if (siteId) logOpenSiteEvent(siteId);
  }, [siteId]);
};

export const useSiteOpenPageEvent = (
  type: SitePagesType,
  siteId?: string,
  planId?: string
) => {
  useEffect(() => {
    if (siteId) logOpenSitePage(type, siteId, planId);
  }, [siteId, planId, type]);
};

export const useSiteOpenPrintPageEvent = (
  type: SitePagesType,
  siteId?: string,
  planId?: string
) => {
  useEffect(() => {
    if (siteId) logOpenSitePrintPage(type, siteId, planId);
  }, [siteId, planId, type]);
};
