//React
import { FC } from "react";
//Types
import { IHistoricThresholdsKey } from "types";
import { MONTHS_SHORT } from "./constants";
//Helpers
import { formatTableTitle } from "./helpers";

interface IProps {
  threshold: IHistoricThresholdsKey;
  values: number[];
}

const HistoricThresholdTable: FC<IProps> = ({ threshold, values }) => {
  if (
    threshold === "cumRainfall" ||
    threshold === "daysRainAbove5mm" ||
    threshold === "daysTempBelow0C"
  ) {
    return (
      <div>
        <h1 data-testid={`${threshold}-title`} className="px-0 pt-8 pb-4">
          {formatTableTitle(threshold)}
        </h1>
        <table data-testid={`${threshold}-table`}>
          <tbody>
            <tr className="border border-gray-400">
              {MONTHS_SHORT.map((month) => (
                <th key={month} className="py-0 px-4 border border-gray-400">
                  {month}
                </th>
              ))}
            </tr>
            <tr
              data-testid={`${threshold}-value-row`}
              className="border border-gray-400"
            >
              {values.map((value, i) => (
                <td
                  key={i}
                  data-testid={`${threshold + i.toString()}`}
                  className="py-0 px-4 border border-gray-400"
                >
                  {value}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else return null;
};

export default HistoricThresholdTable;
