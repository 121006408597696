import React from "react";
import { AlertMessage } from "./AlertMessage";
import { ITaskCategory } from "@ehabitation/ts-utils/browser";

interface SimulationIssuesAlertProps {
  categoriesWithHighRiskFactors: ITaskCategory[];
  renderThresholds: (thresholds: any) => JSX.Element;
}

export const SimulationIssuesAlert: React.FC<SimulationIssuesAlertProps> = ({
  categoriesWithHighRiskFactors,
  renderThresholds,
}) => {
  return (
    <>
      <AlertMessage 
        message="The simulation is very likely to fail. The following activity categories are likely to face issues throughout the year."
        linkText="Please click here to review the project thresholds prior to running the simulation."
        linkTo="../settings/thresholds"
      />
      <div className="flex flex-col mt-8">
        <div className="ml-2 flex items-center">
          <h4>Categories with factors above threshold:</h4>
        </div>
        <div className="ml-2 flex items-center">
          <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
            {categoriesWithHighRiskFactors.map((category, i) => (
              <li key={i}>
                {category.name} - Thresholds:
                {category.thresholds 
                  ? renderThresholds(category.thresholds) 
                  : " No thresholds available."}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};