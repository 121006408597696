import { FC, useLayoutEffect, useRef } from "react";
import { threeDigitsMax } from "./ForecastTable";
import {
  IHourlyData,
  IHourlyForecast,
  warningPrecipitation,
  warningTemperature,
  warningWind,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";
import { useSelector } from 'react-redux';
import { RootState } from '../../../apps/web/src/store';
import { convertUnits } from '../../../apps/web/src/utils/unitConversions';

interface IHoursFromNow {
  hour: string | number;
  hourlyForecasts: IHourlyData;
}

const bgClasses = {
  ok: "bg-green-200",
  alert: "bg-rose-200",
  warning: "bg-amber-200",
  off: "bg-gray-100",
};

const hourIsNight = (hour: number | string) => {
  return Number(hour) >= 20 || Number(hour) <= 6;
};

const getBgClass = (isBroken?: boolean, isWarning?: boolean) => {
  if (typeof isBroken === "boolean" && typeof isWarning === "boolean") {
    if (isBroken) {
      return bgClasses.alert;
    } else {
      if (isWarning) {
        return bgClasses.warning;
      } else {
        return bgClasses.ok;
      }
    }
  } else {
    return bgClasses.off;
  }
};

const dataRows = [
  {
    title: "Temp",
    key: "temp",
    unit: "C",
    render: (
      hourData: IHourlyData,
      i: number,
      thresholds?: WeatherThresholds
    ) => {
      const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
      const forecastTempValue = hourData?.temp.value;
      const hasForecastValue = typeof forecastTempValue === "number";

      // Convert temperature value if needed
      const convertedTemp = hasForecastValue ? 
        convertUnits(forecastTempValue, 'metric', currentUnit, 'temperature') : null;

      const isMinTempBroken =
        hasForecastValue && typeof thresholds?.minTemp === "number"
          ? forecastTempValue < thresholds?.minTemp
          : undefined;
      const isMaxTempBroken =
        hasForecastValue && typeof thresholds?.maxTemp === "number"
          ? forecastTempValue > thresholds?.maxTemp
          : undefined;
      const isMinTempWarning =
        hasForecastValue && typeof thresholds?.minTemp === "number"
          ? forecastTempValue < thresholds?.minTemp + warningTemperature
          : undefined;
      const isMaxTempWarning =
        hasForecastValue && typeof thresholds?.maxTemp === "number"
          ? forecastTempValue >
            (thresholds?.maxTemp > warningTemperature
              ? thresholds?.maxTemp - warningTemperature
              : 0)
          : undefined;
      const bgClass = getBgClass(
        isMinTempBroken || isMaxTempBroken,
        isMinTempWarning || isMaxTempWarning
      );
      return (
        <div
          key={i}
          className={`px-2 text-xl grid place-content-center h-11 font-m text-center ${bgClass}`}
        >
          {convertedTemp !== null ? threeDigitsMax(convertedTemp, true) : "-"}
        </div>
      );
    },
  },
  {
    title: "Wind",
    key: "windSpeed",
    unit: "m/s",
    render: (
      hourData: IHourlyData,
      i: number,
      thresholds?: WeatherThresholds
    ) => {
      const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
      const forecastWindValue = hourData?.windSpeed.value;
      const hasForecastValue = typeof forecastWindValue === "number";

      // Convert wind speed value if needed
      const convertedWind = hasForecastValue ? 
        convertUnits(forecastWindValue, 'metric', currentUnit, 'speed') : null;

      const isWindBroken =
        hasForecastValue && typeof thresholds?.wind === "number"
          ? forecastWindValue > thresholds?.wind
          : undefined;
      const isWindWarning =
        hasForecastValue && typeof thresholds?.wind === "number"
          ? forecastWindValue >
            (thresholds?.wind > warningWind
              ? thresholds?.wind - warningWind
              : 0)
          : undefined;
      const bgClass = getBgClass(isWindBroken, isWindWarning);
      return (
        <div
          key={i}
          className={`px-2 text-xl grid place-content-center h-11 font-m text-center ${bgClass}`}
        >
          {convertedWind !== null ? threeDigitsMax(convertedWind, true) : "-"}
        </div>
      );
    },
  },
  {
    title: "💧",
    key: "precipitation",
    unit: "mm",
    render: (
      hourData: IHourlyData,
      i: number,
      thresholds?: WeatherThresholds
    ) => {
      const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
      const forecastPrecipValue = hourData?.precipitation.value;
      const hasForecastValue = typeof forecastPrecipValue === "number";

      // Convert precipitation value if needed
      const convertedPrecip = hasForecastValue ? 
        convertUnits(forecastPrecipValue, 'metric', currentUnit, 'precipitation') : null;

      const isHourlyRainBroken =
        hasForecastValue && typeof thresholds?.hourlyRainAcc === "number"
          ? forecastPrecipValue > thresholds?.hourlyRainAcc
          : undefined;
      const isHourlyRainWarning =
        hasForecastValue && typeof thresholds?.hourlyRainAcc === "number"
          ? forecastPrecipValue >
            (thresholds?.hourlyRainAcc > warningPrecipitation
              ? thresholds?.hourlyRainAcc - warningPrecipitation
              : 0)
          : undefined;
      const bgClass = getBgClass(isHourlyRainBroken, isHourlyRainWarning);
      return (
        <div
          key={i}
          className={`px-2 text-xl grid place-content-center h-11 font-m text-center ${bgClass}`}
        >
          {convertedPrecip !== null ? threeDigitsMax(convertedPrecip, true) : "-"}
        </div>
      );
    },
  },
];

export const ForecastHourly: FC<{
  hourlyForecasts: IHourlyForecast[];
  selectedForecastIndex: number;
  thresholds?: WeatherThresholds;
  allowScroll?: boolean;
}> = ({
  hourlyForecasts,
  selectedForecastIndex,
  thresholds,
  allowScroll = true,
}) => {
  const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
  let newDayHours = 0;
  const hourNow = new Date().getHours();
  const firstDaytimeHourRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (allowScroll) {
      if (hourlyForecasts && firstDaytimeHourRef.current) {
        if (selectedForecastIndex === 0 && scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft = 0;
        } else {
          firstDaytimeHourRef.current.scrollIntoView({ inline: "start" });
        }
      }
    }
  }, [hourlyForecasts, selectedForecastIndex]);
  const checkHour = (i: number) => {
    const forecast = hourlyForecasts[selectedForecastIndex];
    if (selectedForecastIndex === 0) {
      if (i === 0)
        return {
          hour: "NOW",
          hourlyForecasts: forecast[hourNow] || forecast[hourNow + 1],
        };
      else if (i > 23 - hourNow) {
        newDayHours = newDayHours + 1;
        return {
          hour: newDayHours - 1,
          hourlyForecasts:
            hourlyForecasts[selectedForecastIndex + 1][newDayHours - 1],
        };
      } else {
        return {
          hour: i + hourNow,
          hourlyForecasts: forecast[i + hourNow],
        };
      }
    } else {
      return {
        hour: i,
        hourlyForecasts: forecast[i],
      };
    }
  };

  const hoursFromNow: IHoursFromNow[] = [...Array(24).keys()].map((i) => {
    return checkHour(i);
  });
  return (
    <div className="min-w-0">
      <div className="bg-white">
        <div className="bg-white text-xl flex">
          <div className="flex flex-col">
            <div className="h-11 pt-2 px-4">HOUR</div>
            <div className="py-2 px-4 h-11 font-medium">
              <div>
                Temp
                <span className="text-base text-gray-600 pl-2 align-middle">
                  ({currentUnit === 'metric' ? 'C' : 'F'})
                </span>
              </div>
            </div>
            <div className="py-2 px-4 h-11 font-medium">
              <div>
                Wind
                <span className="text-base text-gray-600 pl-2 align-middle">
                  ({currentUnit === 'metric' ? 'm/s' : 'mph'})
                </span>
              </div>
            </div>
            <div className="py-2 px-4 h-11 font-medium">
              <div>
                💧
                <span className="text-base text-gray-600 pl-2 align-middle">
                  ({currentUnit === 'metric' ? 'mm' : 'in'})
                </span>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto" ref={scrollContainerRef}>
            <div className="grid grid-flow-col-dense justify-start p-0 text-center">
              {hoursFromNow.map((h: IHoursFromNow, i: number) => {
                const isNight = hourIsNight(h.hour);
                const isFirstNightHour =
                  (i === 0 && isNight) ||
                  (isNight && !hourIsNight(hoursFromNow[i - 1].hour));
                const isFirstDaytimeHour = i === 7;
                return (
                  <div
                    key={i}
                    className="w-[3.7rem]"
                    ref={isFirstDaytimeHour ? firstDaytimeHourRef : undefined}
                  >
                    <div
                      id={isFirstNightHour ? "forecast_hourly_hour_night" : ""}
                      className={`px-2 text-xl grid place-content-center h-11 font-m ${
                        isNight ? "text-sky-100 bg-blue-800 opacity-70" : ""
                      }`}
                    >
                      {isFirstNightHour ? "🌛" : h.hour}
                    </div>
                    {dataRows.map(({ render }, i) => {
                      return render(h.hourlyForecasts, i, thresholds);
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
