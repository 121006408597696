import React, { FC, useRef, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { BiCaretRight } from "react-icons/bi";

interface IBreadcrumb {
  title?: string | JSX.Element;
  children: (close: VoidFunction) => JSX.Element;
  headerComponent?: React.ElementType<any>;
  headerClass?: string;
  defaultOpen?: boolean;
}

export const Breadcrumb: FC<IBreadcrumb> = ({
  title,
  children,
  headerComponent,
  defaultOpen = false,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Disclosure as="div" defaultOpen={defaultOpen}>
      {({ open, close: closeDisclosure }) => {
        const close = () => {
          closeDisclosure();
        };

        return (
          <>
            {open && (
              <div
                className="fixed inset-0 z-10 bg-black opacity-50"
                onClick={close}
              ></div>
            )}
            <div className="z-20 relative">
              <Disclosure.Button
                as={headerComponent}
                data-testid="breadcrumb-button"
                className={`flex gap-2 py-3 sm:py-4 px-6 sm:px-8 items-center w-full transition-colors ${
                  open ? "bg-[#c7f3e0]" : ""
                } hover:bg-[#c7f3e0]`}
                ref={buttonRef}
              >
                <>
                  <BiCaretRight
                    className={`text-3xl transition-transform ${
                      open ? "rotate-90" : "rotate-0"
                    }`}
                  />
                  {title}
                </>
              </Disclosure.Button>
              <div ref={panelRef}>
                <Disclosure.Panel className="bg-[#f4f4f4] h-[calc(100vh_-_10rem)] overflow-y-auto relative z-20">
                  {children(close)}
                </Disclosure.Panel>
              </div>
            </div>
          </>
        );
      }}
    </Disclosure>
  );
};
