export const baseStyle = "flex flex-col justify-center items-center";
export const LoadingBackdrop: React.FC = ({ children }) => (
  <div
    className={`${baseStyle} fixed h-screen w-screen top-0 left-0 z-50 text-white`}
    style={{ background: "rgba(0, 0, 0, 0.6)" }}
  >
    {children}
  </div>
);
export const LoadingBackdropAlt: React.FC = ({ children }) => (
  <div
    className={`${baseStyle} absolute top-0 left-0`}
    style={{ height: "calc(100% - 1rem)", width: "calc(100% - 2rem)" }}
  >
    {children}
  </div>
);
export const LoadingBackdropSubscriptions: React.FC = ({ children }) => (
  <div className={`${baseStyle} text-black h-full w-full`}>{children}</div>
);
