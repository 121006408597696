import { useCallback, useEffect, useState } from "react";
import { ICustomClaims } from "@ehabitation/ts-utils/browser";
import { useAppDispatch, useAppSelector } from "store";
import { selectRole, selectUserClaims } from "store/auth";
import {
  selectOrganisationProjects,
  setOrganisationSites,
} from "store/siteManagement";
import { IOrganisationSites } from "types";
import { subscribeToSites } from "helpers";
import { difference } from "lodash";

/* 
Subscribe to all sites in a given organisation for orgAdmins,
all sites in a given division for divisionAdmins or
all sites within a given project for anyone else
*/
export const useSubscribeToSites = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectRole);
  const projects = useAppSelector(selectOrganisationProjects);
  const [availableProjects, setProjects] = useState<string[]>(
    Object.keys(projects)
  );
  useEffect(() => {
    const newProjects = difference(Object.keys(projects), availableProjects);
    if (newProjects.length) setProjects(Object.keys(projects));
  }, [projects]);

  const { organisation, division, project } = useAppSelector(
    selectUserClaims
  ) as ICustomClaims;

  const [isReady, setReady] = useState(false);

  const dispatcher = useCallback(
    (organisationSites: IOrganisationSites) => {
      dispatch(setOrganisationSites(organisationSites));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!role) return;

    const unsubscribe = subscribeToSites({
      role,
      orgId: organisation || "",
      divisionId: division || "",
      projectId: project || "",
      availableProjects,
      setReady,
      dispatcher,
    });

    return unsubscribe;
  }, [role, organisation, division, project, availableProjects, dispatcher]);

  return isReady;
};
