import React, { FC, forwardRef } from "react";

interface IStat {
  value: number;
  unit?: string;
  thresholds?: [number, number];
  className?: string;
}

const getBgColorClass = (value: number, thresholds: [number, number]) => {
  if (value >= thresholds[1]) {
    return "bg-emerald-400";
  }
  if (value >= thresholds[0]) {
    return "bg-yellow-400";
  }
  return "bg-rose-400";
};

export const Stat: FC<IStat> = forwardRef<HTMLDivElement, IStat>(
  ({ value, unit = "%", thresholds = [40, 80], className = "" }, ref) => {
    return (
      <div
        className={`self-center border border-black border-solid text-5xl font-bold px-2 py-4 rounded-md ${getBgColorClass(
          value,
          thresholds
        )} ${className}`}
        ref={ref}
      >
        {value}
        {unit}
      </div>
    );
  }
);
