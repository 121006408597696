import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Input, PasswordInput } from "@ehabitation/ui";
import {
  EmailAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import config from "config";
import { auth } from "firebaseConfig";
import { useIsMounted } from "hooks";
import { logLoginEvent } from "helpers/analytics";
import { FaSpinner } from "react-icons/fa";

interface TenantSettings {
  tenantId: string | null;
  microsoftTenantId: string | null;
}

const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const useLogin = (reauthenticate: boolean) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [emailField, setEmailField] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [mfChallenge, setMfChallenge] = useState(false);
  const [mfResolver, setMfResolver] = useState<MultiFactorResolver | null>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const [resolvedTenant, setResolvedTenant] = useState<TenantSettings | null>(null);

  const loginMicrosoft = async (tenantSettings: TenantSettings) => {
    setIsLoading(true);
    try {
      var provider = new OAuthProvider("microsoft.com");
      if (tenantSettings.tenantId) {
        auth.tenantId = tenantSettings.tenantId;
      }
      if (tenantSettings.microsoftTenantId) {
        provider.setCustomParameters({
          tenant: tenantSettings.microsoftTenantId,
        });
      }
      // Use signInWithPopup and handle the result
      await signInWithPopup(auth, provider);
      // Keep the spinner spinning on successful login
      // logLoginEvent();
      // if (isMounted()) {
      //   navigate("/domain-select");
      // }
    } catch (e) {
      console.error("Microsoft sign-in failed:", e);
      setIsLoading(false);
    }
    // No need for finally block to stop spinner, handled in catch
  };

  const lookupTenant = (email: string) => {
    const domain = email.split("@")[1];
    const domainTenantMap: { [key: string]: TenantSettings } = {
      "kier.co.uk": { tenantId: config.KIER_TENANT_ID, microsoftTenantId: "d8de327a-9836-443f-8bbc-a1c10ff08dc0" },
      "morgansindall.com": { tenantId: config.MORGAN_SINDAL_TENENT_ID, microsoftTenantId: "71f4f5a1-3263-48ad-8ea4-0981a6fe99f4" },
      // "ehab.co": { tenantId: config.EHAB_TENENT_ID, microsoftTenantId: "1ff0ab2c-c989-4729-a188-228acea7b11b" },
    };
    return domainTenantMap[domain] || null;
  };

  useEffect(() => {
    if (mfResolver && !recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
        size: "invisible",
      });
      const mfInfo = mfResolver.hints.find(
        (f) => f.factorId === PhoneMultiFactorGenerator.FACTOR_ID
      );
      const phoneInfoOptions = {
        multiFactorHint: mfInfo,
        session: mfResolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, verifier!)
        .then(function (verificationId) {
          setVerificationId(verificationId);
        });
      setRecaptchaVerifier(verifier);
    }
  }, [mfResolver]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError("");
    if (e.target.name === "email") {
      const email = e.target.value;
      setEmailField(email);
      const tenant = lookupTenant(email);
      setResolvedTenant(tenant);
      if (!tenant) {
        setPassword("");
      }
    }
    if (e.target.name === "password") setPassword(e.target.value);
    if (e.target.name === "mfaCode") setMfaCode(e.target.value);
  };

  const isValid = () => {
    if (!emailField || !password) return false;
    return true;
  };

  const verifyCode = async () => {
    const cred = PhoneAuthProvider.credential(verificationId, mfaCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await mfResolver?.resolveSignIn(multiFactorAssertion);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e.currentTarget.name === "mfa-code") {
      await verifyCode();
    } else {
      await handleLogin(e);
    }
  };

  const handleLogin = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!isValid()) return setError("Please provide an email and password");

    setError("");
    setIsLoading(true);

    try {
      if (reauthenticate) {
        await reauthenticateWithCredential(
          auth.currentUser!,
          EmailAuthProvider.credential(emailField, password)
        );
      } else {
        await login(emailField, password);
      }
      logLoginEvent();
      if (isMounted()) {
        navigate("/domain-select");
      }
    } catch (e) {
      const error = e as { code: string };
      if (isMounted()) {
        setIsLoading(false);
        if (error.code == "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(
            auth,
            error as MultiFactorError
          );
          setMfResolver(resolver);
          setMfChallenge(true);
        } else if (
          [
            "auth/user-not-found",
            "auth/wrong-password",
            "auth/invalid-email",
          ].includes(error?.code)
        ) {
          setError("Incorrect email or password.");
        } else {
          console.error(error);
          setError(
            "Login failed, please try again or contact support@ehab.co if the error persists."
          );
        }
      }
    }
  };

  return {
    email: emailField,
    password,
    error,
    isLoading,
    handleChange,
    handleSubmit,
    mfChallenge,
    verifyCode,
    resolvedTenant,
    loginMicrosoft,
  };
};

const LoginForm = ({ reauthenticate }: { reauthenticate: boolean }) => {
  const {
    email,
    password,
    error,
    handleChange,
    handleSubmit,
    isLoading,
    mfChallenge,
    verifyCode,
    resolvedTenant,
    loginMicrosoft,
  } = useLogin(reauthenticate);

  return (
    <>
      <div id="mfa-captcha"></div>

      {mfChallenge && (
        <>
          <Input
            className="mb-8 self-stretch"
            type="text"
            onChange={handleChange}
            placeholder="SMS Code"
            name="mfaCode"
            aria-label="SMS Code"
            disabled={isLoading}
          />
          <Button
            id="verify"
            className="my-4 flex items-center justify-center"
            type="button"
            tabIndex={1}
            onClick={verifyCode}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Validate code
                <FaSpinner className="ml-2 animate-spin" />
              </>
            ) : (
              "Validate code"
            )}
          </Button>
          <span>Or</span>
        </>
      )}

      <Input
        className="mb-8 self-stretch"
        value={email}
        onChange={handleChange}
        type="email"
        autoComplete="email"
        placeholder="Email"
        tabIndex={1}
        name="email"
        aria-label="Email"
        disabled={isLoading}
      />

      {resolvedTenant ? (
        <Button
          id="sign-in-microsoft"
          className="mb-8 flex items-center justify-center"
          type="button"
          tabIndex={1}
          onClick={() => loginMicrosoft(resolvedTenant)}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              Signing in...
              <FaSpinner className="ml-2 animate-spin" />
            </>
          ) : (
            "Sign in with Microsoft"
          )}
        </Button>
      ) : (
        <>
          <PasswordInput
            className="self-stretch"
            value={password}
            autoComplete="current-password"
            onChange={handleChange}
            placeholder="Password"
            tabIndex={1}
            name="password"
            aria-label="Password"
            disabled={isLoading}
          />

          <Link to="/reset_password" className="text-blue-500 mt-2">
            Forgot your password?
          </Link>

          {error && (
            <p className="pt-4 text-red-700 text-center">{error}</p>
          )}

          <form onSubmit={handleSubmit}>
            <Button
              id="sign-in"
              className="my-4 flex items-center justify-center"
              type="submit"
              tabIndex={1}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Submitting...
                  <FaSpinner className="ml-2 animate-spin" />
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </form>

          <span>Or</span>
          <Link to="/register" className="text-blue-500 text-2xl mt-2">
            Create Account
          </Link>
        </>
      )}
    </>
  );
};

export default LoginForm;
