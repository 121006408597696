//Styles
import styled from "styled-components";
import { flex, fontSize, grays, spacing } from "styles";

export const StyledCustomAlert = styled.div`
  ${flex("column", "center", "center")};
  background: transparent;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2000;

  .backdrop {
    background: rgba(0, 0, 0, 0.32);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2002;
  }

  .content {
    background: white;
    border-radius: 0.4rem;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
      0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    padding: 3rem;
    z-index: 2003;

    .message {
      color: black;
      font-size: ${fontSize.m};
      margin-bottom: ${spacing.l};
    }

    .buttons {
      ${flex("row", "space-between", "center")};

      > button {
        background: ${grays.veryLight};
        cursor: pointer;
        font-size: ${fontSize.s};
        font-weight: 500;
        padding: 0.5rem 1.2rem;
        text-transform: uppercase;
      }
    }
  }
`;
