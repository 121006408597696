import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";

import logo from "assets/images/logo.svg";
import pattern from "assets/grunge/pattern.svg";

import PasswordForm from "Components/Auth/PasswordForm";
import { Spinner } from "Components/Spinner";

import { auth } from "firebaseConfig";
import { useIsMounted } from "hooks";
import { useEffect, useState } from "react";

const verifyResetCode = async (code: string) => {
  const email = await verifyPasswordResetCode(auth, code);
  return email;
};

const useFirebaseResetCode = (code = "") => {
  const isMounted = useIsMounted();
  const [validCode, setValidCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (code) {
      setIsLoading(true);
      verifyResetCode(code)
        .then(async (codeEmail) => {
          if (isMounted()) {
            setValidCode(code);
            setEmail(codeEmail);
            setIsLoading(false);
          }
        })
        .catch(() => {
          if (isMounted()) {
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
  }, [code]);

  const confirmPassword = validCode
    ? async (password: string) => {
        await confirmPasswordReset(auth, validCode, password);
        setIsSuccess(true);
      }
    : undefined;

  return { email, isLoading, isSuccess, confirmPassword };
};

const PasswordSuccessMessage = ({ email }: { email?: string }) => (
  <div className="flex items-center w-full">
    <p className="pt-4 text-emerald-600 text-center">
      Password successfully set for {email}, please continue to{" "}
      <Link
        to={{ pathname: "/login" }}
        state={{ email }}
        className="text-blue-500 mt-2"
      >
        login page
      </Link>
      .
    </p>
  </div>
);

const ResetPasswordPage = () => {
  const location = useLocation();
  const code = location.state?.code;

  const { email, isLoading, isSuccess, confirmPassword } =
    useFirebaseResetCode(code);

  if (isLoading) return <Spinner />;

  if (isSuccess) return <PasswordSuccessMessage email={email} />;

  if (confirmPassword && email)
    return (
      <div className="flex flex-col items-center w-full">
        <h1>Set Password</h1>
        <p className="mb-4 font-bold" aria-label="User Email">
          {email}
        </p>
        <PasswordForm confirmPassword={confirmPassword} />
      </div>
    );

  return (
    <InvalidLink message="Reset password link invalid, it may have previously been used. Please contact support@ehab.co if this error persists." />
  );
};

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

type Mode = "resetPassword" | "verifyEmail" | "recoverEmail";

const InvalidLink = ({ message }: { message: string }) => {
  return (
    <>
      <p className="pt-4 text-red-700 text-center">{message}</p>
      <p>
        <Link to="/" className="text-blue-500 mt-2">
          Return to Home Page
        </Link>
        .
      </p>
    </>
  );
};

const modePaths = {
  resetPassword: "reset_password",
  verifyEmail: "verify_email",
  recoverEmail: "recover_email",
};

const Root = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const code = urlSearchParams.get("oobCode") || "";
  const mode = urlSearchParams.get("mode") as Mode;

  const modePath = modePaths[mode];

  return modePath ? (
    <Navigate to={modePath} replace state={{ code }} />
  ) : (
    <InvalidLink message="Link invalid, please contact support@ehab.co if this error persists." />
  );
};

const AuthAction = () => {
  const logoElement = (
    <img className="self-stretch mb-12" src={logo} alt="EHAB Logo" />
  );

  return (
    <>
      <div
        className="flex flex-col items-center justify-center h-[calc(100vh-2rem)]"
        style={dotMatrixBgStyle}
      >
        <div className="w-[min(32rem,100vw)] flex flex-col items-center gap-4">
          {logoElement}
          <Routes>
            <Route path="" element={<Root />} />
            <Route path="reset_password" element={<ResetPasswordPage />} />
            <Route
              path="verify_email"
              element={
                <InvalidLink message="Verify email feature not yet implemented. Please contact support@ehab.co." />
              }
            />
            <Route
              path="recover_email"
              element={
                <InvalidLink message="Recover email feature not yet implemented. Please contact support@ehab.co." />
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default AuthAction;
