import logo from "assets/images/logo.svg";
import pattern from "assets/grunge/pattern.svg";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from "Components/Spinner";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const applyUnsubscribeToken = async (token: string) =>
  fetch(
    `https://${window.location.hostname}/api/unsubscribe?token=${token}`
  ).then((response) =>
    response
      .text()
      .then((text) => ({ bodyText: text, status: response.status }))
  );

const useApplyUnsubscribeToken = (token = "") => {
  const mutation = useMutation((code: string) => applyUnsubscribeToken(code));

  useEffect(() => {
    if (token) {
      mutation.mutate(token);
    }
  }, [token]);

  return mutation;
};

const UnsubscribePage = () => {
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { isLoading, isSuccess, isError, data } = useApplyUnsubscribeToken(
    query.get("token") || ""
  );

  const logoElement = <img className="w-full" src={logo} alt="EHAB Logo" />;

  return (
    <div
      className="flex flex-col items-center justify-center h-[calc(100vh-2rem)]"
      style={dotMatrixBgStyle}
    >
      <div className="w-[min(38rem,100vw)] flex flex-col items-center gap-4">
        <Link className="self-stretch mb-12" to="/">
          {logoElement}
        </Link>
        {!query.get("token") && (
          <h1 className=" text-red-600">Invalid Unsubscribe Token</h1>
        )}
        {isLoading && <Spinner />}
        {isSuccess && data.status === 200 && <h1>Successfully Unsubscribed</h1>}
        {isSuccess && data.status !== 200 && (
          <h1>
            {data?.bodyText ||
              "An error was encountered while unsubscribing, sorry!"}
          </h1>
        )}
        {isError && (
          <h1>An error was encountered while unsubscribing, sorry!</h1>
        )}
      </div>
    </div>
  );
};

export default UnsubscribePage;
