import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import pattern from "assets/grunge/pattern.svg";
import { useAppSelector } from "store";
import { projectTitleSelector } from "store/selectors";
import { selectProjectId } from "store/project";
import SettingsMenuContainer from "Components/Menu/SettingsMenuContainer";
import { useIsFreeUser, useOrganisation } from "hooks";
import Breadcrumb from "Pages/sites/Breadcrumb";
import { selectRole } from "store/auth";
import { UserRole } from "@ehabitation/ts-utils/browser";
import logo_small from "assets/images/logo_square.png";
import UnitDropdown from "Components/UnitDropdown/UnitDropdown";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const Header: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  const projectTitle = useAppSelector(projectTitleSelector);
  const projectId = useAppSelector(selectProjectId);
  const role = useAppSelector(selectRole);

  const showBreadcrumbs =
    role !== UserRole.superAdmin && pathname.includes("sites");
  const isPlanner = pathname.endsWith("plan");

  const organisation = useOrganisation();
  const isFreeUser = useIsFreeUser();

  return (
    <header
      className="flex shrink-0 items-center w-full bg-white h-22 sm:h-22 z-50"
      style={dotMatrixBgStyle}
    >
      <div className="flex h-full mr-auto">
        {/* {isFreeUser ? null : <h2>{organisation?.name}</h2>} */}
        {showBreadcrumbs && organisation && (
          <div className="relative">
            <div className="top-0 left-0 min-w-[650px] w-full z-9999">
              <Breadcrumb />
            </div>
          </div>
        )}
      </div>

      {!showBreadcrumbs && (
        <div className="flex items-center w-full ml-10 mr-8 mt-4">
          <div className="flex-col justify-center items-start" data-testid="logo-link">
            {/* Logo for small screens */}
            <a href="/">
              <img
                src={logo_small}
                alt="EHAB Small Logo"
                className="block lg:hidden h-20 sm:h-20"
              />
            </a>
            {/* Logo for large screens */}
            <a href="/">
              <img
                src={logo_small}
                alt="EHAB Logo"
                className="hidden lg:block h-12"
              />
            </a>
          </div>
          {isPlanner ? (
            <h4
              data-testid="project-title"
              className="ml-10 bg-white/[.06] capitalize p-2"
            >
              {projectTitle}
            </h4>
          ) : (
            <Link to={`/projects/${projectId}`} className="ml-10 text-gray-700">
              <h4
                data-testid="project-title"
                className="bg-white/[.06] capitalize p-2"
              >
                {projectTitle}
              </h4>
            </Link>
          )}
        </div>
      )}

      <p className="sm:hidden px-6 text-gray-500 italic">Mobile View</p>

      <div className="flex items-center mr-10 mt-2 space-x-4">
        <UnitDropdown />
        <SettingsMenuContainer />
      </div>
    </header>
  );
};

export default Header;
