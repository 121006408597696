import { ITaskCategory } from "@ehabitation/ts-utils/browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface ITaskCategoryState {
  taskCategoriesMap: ITaskCategoriesMap;
  isLoading: boolean;
}

export interface ITaskCategoriesMap {
  [key: string]: ITaskCategory;
}

export const initialState: ITaskCategoryState = {
  taskCategoriesMap: {},
  isLoading: false,
};

export const taskCategorySlice = createSlice({
  name: "taskCategory",
  initialState,
  reducers: {
    setTaskCategoriesMap: (
      state,
      action: PayloadAction<ITaskCategoriesMap>
    ) => {
      state.taskCategoriesMap = action.payload;
    },

    setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoadingStatus, setTaskCategoriesMap } =
  taskCategorySlice.actions;

export default taskCategorySlice.reducer;

export const selectTaskCategoriesMap = (state: RootState) =>
  state.taskCategory.taskCategoriesMap;
