import { FC } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Spinner } from "@ehabitation/ui";

export const Location: FC<{
  latitude: number;
  longitude: number;
  address?: string;
  isLoading?: boolean;
  className?: string;
}> = ({ latitude, longitude, address, isLoading, className = "" }) => {
  const displayString = address
    ? address
    : `${latitude.toFixed(4)}, ${longitude.toFixed(4)}`;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      aria-label="site location"
      className={`text-gray-500 gap-2 flex items-center ${
        isLoading ? "animate-pulse" : ""
      } ${className ? className : "min-w-[20rem]"}`}
      target="_blank"
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
    >
      <FaMapMarkerAlt className="flex-shrink-0" />{" "}
      {isLoading ? (
        <div className="w-full grid place-content-center pt-2">
          <Spinner size="xSmall" />
        </div>
      ) : (
        <span className="text-blue-500 underline truncate flex-shrink italic pt-1">
          {displayString}
        </span>
      )}
    </a>
  );
};
