import { createSelector } from "reselect";
import { IUser } from "@ehabitation/ts-utils/browser";
import { receiveIdFromProps } from "../selectors/generic";
import {
  selectDivisionMap,
  selectOrganisationUsers,
  selectSelectedUser,
} from "./userManagementSlice";
import { selectOrganisationProjects } from "store/siteManagement/siteManagementSlice";
import { selectRole } from "store/auth";
import { shouldShowUser } from "Components/UserManagement/Users/helpers";

export const selectSortedOrganisationUsers = createSelector(
  [selectOrganisationUsers, selectRole],
  (users, role) => {
    if (!role) return [];

    const usersArray: IUser[] = Object.keys(users)
      .map((key) => ({ ...users[key], id: key }))
      .filter((user) => shouldShowUser(role, user.role))
      .sort((a, b) => {
        if (!a.firstName || !b.firstName || !a.lastName || !b.lastName)
          return 0;

        const aName = `${a.firstName?.toLowerCase()} ${a.lastName?.toLowerCase()}`;
        const bName = `${b.firstName?.toLowerCase()} ${b.lastName?.toLowerCase()}`;

        if (aName < bName) return -1;
        if (bName > aName) return 1;
        return 0;
      });

    return usersArray;
  }
);

export const selectSelectedUserDetails = createSelector(
  [selectSelectedUser, selectOrganisationUsers],
  (selectedUser, organisationUsers) => {
    if (!selectedUser) return null;
    return { ...organisationUsers[selectedUser], id: selectedUser };
  }
);

export const selectDivisionName = createSelector(
  [selectDivisionMap, receiveIdFromProps],
  (divisionMap, divisionId) => {
    if (!divisionId) return "";
    if (!divisionMap.hasOwnProperty(divisionId)) return divisionId;
    return divisionMap[divisionId].name;
  }
);

export const selectProjectName = createSelector(
  [selectOrganisationProjects, receiveIdFromProps],
  (projects, projectId) => {
    if (!projectId) return "";
    if (!projects.hasOwnProperty(projectId)) return projectId;
    return projects[projectId].name;
  }
);
