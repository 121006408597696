import { FC, useEffect, useState } from "react";

interface IProps {
  activityCodeGroup?: string;
  type: string;
  label: string;
  className?: string;
  readOnly: boolean;
  handleChange: Function;
}

const ActivityCodeGroupInput: FC<IProps> = ({
  activityCodeGroup,
  readOnly,
  label,
  handleChange,
  className,
}) => {
  const [inputValue, setInputValue] = useState(activityCodeGroup || "");

  useEffect(() => {
    setInputValue(activityCodeGroup || "");
  }, [activityCodeGroup]);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleChange(inputValue);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, handleChange]);

  return (
    <div className={className} data-testid={`activity-code-group`}>
      <input
        type="text"
        style={{ display: "inline-block", border: "1px solid black", padding: "5px", width: "200px" }}
        value={inputValue}
        placeholder="EHAB-CATEGORY-CODE"
        className="bg-inherit border"
        onChange={(event) => setInputValue(event.target.value)}
        aria-label={label}
        disabled={readOnly}
        data-testid={`activity_code_group_input`}
      />
    </div>
  );
};

export default ActivityCodeGroupInput;