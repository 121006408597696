import { FaCheckCircle, FaExclamationTriangle, FaClipboardList, FaSpinner, FaFlask, FaTimes } from "react-icons/fa"; // Updated imports
import { Alert, AlertDescription, AlertTitle } from "ui-shadcn/alert";

const simulationStages = [
    {
      key: "start",
      name: "Start",
      description: "Initializing the simulation process, where all necessary parameters and configurations are set up to ensure a smooth execution of the simulation.",
      icon: <FaClipboardList className="h-4 w-4 mr-2" />, // Changed icon
    },
    {
      key: "prepare",
      name: "Prepare",
      description: "Preparing data and resources for simulation, including data validation, resource allocation, and ensuring all prerequisites are met for the simulation to run effectively.",
      icon: <FaExclamationTriangle className="h-4 w-4 mr-2" />, // Changed icon
    },
    {
      key: "simulate",
      name: "Simulate",
      description: "Running the simulation, where the core computational processes take place, and results are generated based on the defined parameters and models. Depending on the size of the plan, this may take even hours.",
      icon: <FaFlask className="h-4 w-4 mr-2" />, // Lab icon for running step
    },
    // {
    //   key: "completed_no_risks",
    //   name: "Completed (No Risks)",
    //   description: "Simulation completed without any risks detected, indicating that all processes ran successfully and the results are reliable and ready for analysis.",
    //   icon: <FaCheckCircle className="h-4 w-4 mr-2" />, // Check icon for completed
    // },
    {
      key: "merge_results",
      name: "Merge Results",
      description: "Merging simulation results, which involves consolidating data from various simulation runs to provide a comprehensive overview and insights.",
      icon: <FaClipboardList className="h-4 w-4 mr-2" />, // Changed icon
    },
  ];
  
// Component to display simulation stages
export const SimulationStages = ({ progressMessage, status }: { progressMessage: string | undefined, status: string | undefined }) => {
    const getIcon = (stageKey: string) => {
        if (progressMessage && !(status === "complete" || status === "error") && progressMessage === stageKey) {
            return <FaSpinner className="animate-spin h-4 w-4 mr-2" />;
        }
        if (status === "complete" || status === "error") {
            return simulationStages.findIndex(s => s.key === progressMessage) > simulationStages.findIndex(s => s.key === stageKey) 
                ? <FaCheckCircle className="h-4 w-4 mr-2" /> 
                : simulationStages.find(s => s.key === stageKey)?.icon;
        }
        return simulationStages.find(s => s.key === stageKey)?.icon;
    };

    return (
      <div className="w-4/5 mx-auto"> {/* Added wrapper div with 80% width */}
        {simulationStages.map((stage) => (
          <div className="pb-5 items-center" key={stage.key}>
            <Alert className={`rounded-2xl p-6 ${progressMessage === stage.key ? "" : "opacity-50"} ${simulationStages.findIndex(s => s.key === progressMessage) > simulationStages.findIndex(s => s.key === stage.key) ? "opacity-100" : ""}`}>
              <div className="flex items-center">
                <div className="flex w-12 justify-center"> {/* Centered icon */}
                  {getIcon(stage.key)}
                </div>
                <div className="ml-2">
                  <AlertTitle className="text-2xl">{stage.name}</AlertTitle>
                </div>
              </div>
              <div className="flex items-start mt-1">
                <div className="flex w-12"></div>
                <div className="ml-2">
                  <AlertDescription className="text-xl">
                    {stage.description}
                  </AlertDescription>
                </div>
              </div>
            </Alert>
          </div>
        ))}
        {status && (status === "error" || status === "complete") && (
          <Alert className={`mt-2 p-4 border rounded-2xl shadow-md ${status === "error" ? "bg-red-200" : "bg-[rgb(28,234,160)]"}`}>
            <div className="flex items-center">
              <div className="flex w-12 justify-center"> {/* Centered icon */}
                {status === "error" ? (
                  <FaTimes className="text-black" />
                ) : (
                  <FaCheckCircle className="text-black" />
                )}
              </div>
              <div className="ml-2">
                  Result: {status === "error" ? "Error" : "Complete"}
              </div>
            </div>
            {status === "error" && (
              <div className="flex items-start mt-1">
                <div className="flex w-12"></div>
                <div className="ml-4 mt-2">
                  <p className="text-xl">
                    EHAB automatically monitors the simulation process for failures. We will review the error and reach out to you shortly. Please feel free to contact us at <a href="mailto:support@ehab.com" className="text-base underline">support@ehab.com</a> if you have any questions.
                  </p>
                </div>
              </div>
            )}
          </Alert>
        )}
      </div>
    );
  };