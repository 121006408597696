import styled from "styled-components";
import { flex, spacing } from "styles";

export const StyledContentWrapper = styled.div`
  ${flex("column", "center", "center")};
  width: 100%;
  height: 100%;
`;

export const StyledForm = styled.form`
  ${flex("column", "center", "center")};
  padding: 0 ${spacing.l};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-width: 70rem;

  .button-container {
    ${flex("column", "flex-start", "flex-end")};
    width: 100%;
  }
`;
