import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import {
  Alert,
  Button,
  ButtonIsland,
  LoadingWrapper,
  Modal,
  MonochromeButton,
  Spinner,
} from "@ehabitation/ui";
import Loader from "Components/Loader/Loader";
import { logRiskMatrixModify, logRiskMatrixView } from "helpers/analytics";
import {
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSiteRiskMatrix,
} from "hooks";
import { FC, useEffect, useState } from "react";
import { AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";
import { RiDeleteBinLine, RiSaveLine } from "react-icons/ri";
import { TableRow } from "ui-shadcn/table";
import { RiskMatrixFileType } from "./helpers";

import {
  useImportExportRiskMatrix,
  useRiskMatrixChangeMap,
  useUserCanEditRiskMatrix,
} from "./hooks";

import { useHasVisibility } from "hooks/useHasVisibility";
import { Table, TableBody, TableHead, TableHeader } from "ui-shadcn/table";
import ActivityCodeGroupInput from "./CategoryThresholdsRow/ActivityCodeGroupInput";
import CategoryThresholdsRow from "./CategoryThresholdsRow/CategoryThresholdsRow";
import { ImportRiskMatrixModal } from "./ImportRiskMatrixModal";
import { firebaseFunction } from "helpers";

// ... existing code ...
const CategoryThresholds: FC<{
  site: ISite;
  selectedRiskMatrixType: string;
  plan?: IPlan;
}> = ({ site, selectedRiskMatrixType, plan }) => {
  const { riskMatrix: siteRiskMatrix, isLoading: isLoadingSiteRiskMatrix } =
    useSiteRiskMatrix(site);
  const { riskMatrix: planRiskMatrix, isLoading: isLoadingPlanRiskMatrix } =
    usePlanRiskMatrix(plan?.id);

  const riskMatrix =
    selectedRiskMatrixType == "plan" ? planRiskMatrix : siteRiskMatrix;

  const { hasVisibility } = useHasVisibility(site);

  useEffect(() => {
    riskMatrix?.id && logRiskMatrixView(site.id, riskMatrix?.id);
  }, [site.id, riskMatrix?.id]);

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(riskMatrix?.id);
  
  const [activeCategories, setActiveCategories] = useState<string[]>(plan?.activeCategories ?? []);

  useEffect(() => {
    if (plan && !plan.activeCategories) {
      const fetchActiveCategories = async () => {
        const SetPlanCategoriesInUse = firebaseFunction("SetPlanCategoriesInUse");
        await SetPlanCategoriesInUse({ plan_id: plan.id });
      };
      fetchActiveCategories();
    } else {
      setActiveCategories(plan?.activeCategories ?? []);
    }
  }, [plan]);

  const {
    importing,
    importModalOpen,
    importNewRiskMatrix,
    openImport,
    closeImport,
    exporting,
    exportRiskMatrix,
    errors,
  } = useImportExportRiskMatrix();

  const {
    changeMap,
    updateChangeMap,
    numChanged,
    isValid,
    isSaving,
    saveThresholds,
    saveDisabled,
    discardChanges,
    discardDisabled,
    activityCodeGroup,
    setActivityCodeGroup,
    newActivityCodeGroup,
    setNewActivityCodeGroup,
  } = useRiskMatrixChangeMap(riskMatrix);

  const userCanEdit = useUserCanEditRiskMatrix(site, riskMatrix);

  const planCategories = (categories ?? []).filter(category =>
    activeCategories.includes(category.name)
  );
  const otherCategories = (categories ?? []).filter(category =>
    !activeCategories.includes(category.name)
  );

  const [isOtherCategoriesCollapsed, setIsOtherCategoriesCollapsed] = useState(true);

  return riskMatrix && categories ? (
    <div className="relative flex-grow min-h-0 pl-10">
      <ul className="h-full overflow-y-scroll">
        <LoadingWrapper
          loading={
            isLoadingPlanRiskMatrix ||
            isLoadingSiteRiskMatrix ||
            isLoadingCategories
          }
        >
          <div className="w-full pt-8 pb-8" data-testid="row-container">
            <div className="flex">
              <div className="flex flex-col w-1/2">
                <div className="flex items-center">
                  <h3 className="mr-4">Activity Code Auto-Matching</h3>
                  <ActivityCodeGroupInput
                    type="Activity Code Group"
                    readOnly={false}
                    className="items-center ml-6"
                    activityCodeGroup={activityCodeGroup}
                    label="Activity Code Group"
                    handleChange={(value: string) => setNewActivityCodeGroup(value)}
                  />
                </div>
                <div className="text-s mt-2">
                  Specify the activity code from your plan that corresponds to each
                  type of activity. For each EHAB category, indicate the matching
                  activity code value so that EHAB can automatically pair them.
                </div>
              </div>
              <div className="flex flex-col w-1/2 text-right">
              <div className="">
                  <ButtonIsland
                    id="task-categories"
                    message={
                      numChanged
                        ? isValid
                          ? `${numChanged} categor${
                              numChanged > 1 ? "ies" : "y"
                            } edited`
                          : "Some changes are invalid"
                        : undefined
                    }
                    buttons={[
                      <MonochromeButton
                        level="primary"
                        key="save"
                        className="mr-2"
                        type="submit"
                        aria-label="Save"
                        onClick={async () => {
                          if (!isSaving) {
                            await saveThresholds();
                            setActivityCodeGroup(newActivityCodeGroup);
                            logRiskMatrixModify(site.id, riskMatrix?.id);
                          }
                        }}
                        disabled={saveDisabled || !userCanEdit}
                      >
                        {isSaving ? (
                          <Spinner />
                        ) : (
                          <>
                            <RiSaveLine className="inline mr-2" /> {"Save"}
                          </>
                        )}
                      </MonochromeButton>,
                      <MonochromeButton
                        level="primary"
                        key="discard"
                        className="bg-red-500"
                        type="submit"
                        aria-label="Discard"
                        onClick={() => {
                          if (!isSaving) discardChanges();
                        }}
                        disabled={discardDisabled || !userCanEdit}
                      >
                        {isSaving ? (
                          <Spinner />
                        ) : (
                          <>
                            <RiDeleteBinLine className="inline mr-2" /> {"Discard"}
                          </>
                        )}
                      </MonochromeButton>,
                    ]}
                    isAbsolute={false}
                  />
                  <div className="pt-4">
                  <ButtonIsland
                    id="import-export"
                    variant="secondary"
                    positionX="right"
                    message="Manage Risk Matrix"
                    buttons={[
                      <MonochromeButton
                        level="secondary"
                        key="import"
                        className="mr-2"
                        onClick={async () => {
                          if (!importing && !exporting) await openImport();
                        }}
                        disabled={!userCanEdit || isSaving || !!numChanged}
                      >
                        <AiOutlineDownload className="inline h-6 w-6 mr-2" /> {"Import"}
                      </MonochromeButton>,
                      <MonochromeButton
                        level="secondary"
                        key="export"
                        onClick={async () => {
                          if (!importing && !exporting) {
                            await exportRiskMatrix(
                              RiskMatrixFileType.XLSX,
                              riskMatrix?.id,
                              hasVisibility
                            );
                          }
                        }}
                        disabled={!userCanEdit || isSaving || !!numChanged}
                      >
                        <AiOutlineUpload className="inline h-6 w-6 mr-2" /> {"Export"}
                      </MonochromeButton>,
                    ]}
                    isAbsolute={false}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="" data-testid="row-container">
            <h3 className="mt-8 mb-8">Risk Categories in the Plan</h3>
            {planCategories.length > 0 ? (
              <Table className="text-xl">
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[40px]"></TableHead>
                    <TableHead>Category</TableHead>
                    <TableHead className="w-[100px]">Shift</TableHead>
                    <TableHead>Automatic mapping</TableHead>
                    <TableHead className="w-[300px]">Thresholds</TableHead>
                    <TableHead className="w-[300px]">Avg. daily prob. of Impact</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {planCategories.map((category) => (
                    <CategoryThresholdsRow
                      key={category.id}
                      category={category}
                      updateChangeMap={updateChangeMap}
                      changeMap={changeMap}
                      readOnly={!userCanEdit || isSaving}
                      hasVisibility={hasVisibility}
                      site={site}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="text-center text-xl mt-4">
                The plan has no categories mapped yet.
              </div>
            )}
          </div>

          {otherCategories.length > 0 && (
            <div className="" data-testid="row-container">
              <h3
                className="mt-8 mb-8 cursor-pointer flex items-center"
                onClick={() => setIsOtherCategoriesCollapsed(!isOtherCategoriesCollapsed)}
              >
                {isOtherCategoriesCollapsed ? '+' : '-'} Other Risk Categories
              </h3>
              {!isOtherCategoriesCollapsed && (
                <Table className="text-xl">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40px]"></TableHead>
                      <TableHead>Category</TableHead>
                      <TableHead className="w-[100px]">Shift</TableHead>
                      <TableHead>Automatic mapping</TableHead>
                      <TableHead className="w-[300px]">Thresholds</TableHead>
                      <TableHead className="w-[300px]">Avg. daily prob. of Impact</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {otherCategories.map((category) => (
                      <CategoryThresholdsRow
                        key={category.id}
                        category={category}
                        updateChangeMap={updateChangeMap}
                        changeMap={changeMap}
                        readOnly={!userCanEdit || isSaving}
                        hasVisibility={hasVisibility}
                        site={site}
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </LoadingWrapper>
        {importModalOpen && (
          <ImportRiskMatrixModal
            completeFunction={async (file: File) =>
              await importNewRiskMatrix(riskMatrix, file)
            }
            loading={importing}
            exportDefaultTemplate={() =>
              exportRiskMatrix(RiskMatrixFileType.XLSX)
            }
            exporting={exporting}
            closeModal={closeImport}
            errors={errors}
          />
        )}
        {exporting && (
          <Modal id="riskmatrix-export">
            <Loader message={"Risk Matrix export is being processed..."} />
          </Modal>
        )}
      </ul>
    </div>
  ) : (
    <div className="w-full grid place-items-center h-72">
      <Spinner />
      <Alert
        alertText="No risk matrix found for project"
        alerting={
          !isLoadingPlanRiskMatrix &&
          !isLoadingSiteRiskMatrix &&
          !isLoadingCategories &&
          !riskMatrix
        }
      />
    </div>
  );
};

export default CategoryThresholds;
