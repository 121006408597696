//Styles
import styled from "styled-components";
import { flex, spacing } from "styles";

export const StyledTaskInfo = styled.div`
  ${flex("column", "flex-start", "center")};
  height: 100%;
  padding: ${spacing.l} ${spacing.l} 0 ${spacing.l};
  width: 100%;

  .task-info {
    ${flex("column", "flex-start", "flex-start")};
    margin-top: 2rem;
    height: 100%;
    width: 80%;
  }

  .not-modifiable {
    margin-top: 5rem;

    p {
      color: red;
    }
  }
`;
