import { db } from "firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  Query,
  query,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { firebaseFunction } from "helpers";
import { CollectionType } from "@ehabitation/ts-utils/browser";

export const getTasksQuery = (planId: string): Query => {
  return query(
    collection(db, CollectionType.Plans, planId, "tasks"),
    orderBy("wbsHierarchyOrder")
  );
};

export async function getPlan(planId: string) {
  const planRef = doc(db, CollectionType.Plans, planId);
  const docSnap = await getDoc(planRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}

export const getRiskMatrixQuery = (
  id: string,
  collectionType: CollectionType
): Query => {
  return query(
    collection(db, CollectionType.RiskMatrix),
    where("tier", "==", collectionType),
    where("tierObjectId", "==", id)
  );
};

export const getPlanRiskMatrixQuery = (planId: string): Query =>
  getRiskMatrixQuery(planId, CollectionType.Plans);

export const getProjectRiskMatrixQuery = (projectId: string): Query =>
  getRiskMatrixQuery(projectId, CollectionType.Projects);

export const getRiskMatrixDoc = async (
  id: string,
  collectionType: CollectionType
) => {
  return (await getDocs(getRiskMatrixQuery(id, collectionType))).docs[0];
};

export const duplicatePlanData = async (
  planId: string,
  userId: string,
  title: string,
  isMitigation?: boolean
) => {
  const copyPlan = firebaseFunction<{ message: string }>("CopyPlan");
  const { data } = await copyPlan({
    planId,
    userId,
    planName: title,
    isMitigation,
  });

  return JSON.parse(data.message);
};
