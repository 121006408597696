import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; // Adjust the import based on your store setup
import { setUnit } from '../../store/unit/unitSlice';

const UnitDropdown: React.FC = () => {
    const dispatch = useDispatch();
    const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const unit = event.target.value as "metric" | "imperial";
        dispatch(setUnit(unit));
    };

    return (
        <select
            style={{ border: '1px solid black', padding: '5px' }}
            value={currentUnit}
            onChange={handleChange}
        >
            <option value="metric">Metric (°C, m/s, m)</option>
            <option value="imperial">Imperial (°F, mph, ft)</option>
        </select>
    );
};

export default UnitDropdown; 