import styled from "styled-components";
import { flex, spacing } from "styles";

export const StyledForm = styled.form`
  padding: 0 ${spacing.l};
  height: 90%;
  width: 90%;
  max-width: 70rem;
  overflow-y: auto;

  .project-division,
  .start-end {
    ${flex("row", "space-between", "center")};
  }

  .project-division,
  .lat-long,
  .start-end {
    width: 100%;
  }

  .lat-long,
  .start-end {
    gap: ${spacing.m};
  }

  .button-container {
    ${flex("column", "flex-start", "flex-end")};
    width: 100%;
  }
`;
