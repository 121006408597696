import { createSelector } from "reselect";
import moment from "moment";

import { allTasksSelector, selectCurrentTask } from "store/tasks";
import { selectProjectEndDate } from "store/project";
import calculateDiffBetweenDates from "Components/Impact/helpers/calculateDiffBetweenDates";
import { HOURS_IN_A_DAY } from "utils";

export const selectImpactOverviewMetrics = createSelector(
  [selectCurrentTask, allTasksSelector],
  (currentTask, allTasks) => {
    if (!currentTask || !allTasks || (currentTask.WBS && !currentTask.subtasks))
      return null;

    const originalEnd = moment(currentTask.end);
    const proposedEnd = moment(currentTask.safetyEnd);

    const extraDays = Math.abs(originalEnd.diff(proposedEnd, "days"));

    return {
      confidenceLevel: Math.floor(currentTask.accuracy),
      dependencies: currentTask.successors?.length || 0,
      startDate: moment(currentTask.start).format("DD MMM YYYY"),
      originalEnd: originalEnd.format("DD MMM YYYY"),
      proposedEnd: proposedEnd.toDate(),
      extraDays: extraDays,
    };
  }
);

export const selectImpactRiskProfiles = createSelector(
  [selectCurrentTask, allTasksSelector, selectProjectEndDate],
  (
    currentTask,
    allTasks,
    endDate
  ): {
    level: string;
    daysOver: number;
    proposedEnd: string;
    WBS: boolean;
    daysPastProjectDeadline: number;
  }[] => {
    if (
      !currentTask?.risks ||
      (currentTask.WBS && !currentTask.subtasks) ||
      !allTasks ||
      !endDate
    )
      return [];

    const siteEndDate = moment(endDate);
    const currentTaskEndDate = moment(currentTask.end);

    let risks = currentTask.risks
      .filter((risk) => {
        const riskLevel = Object.keys(risk)[0];
        if (riskLevel === "width" || riskLevel === "label") return false;
        return true;
      })
      .map(
        (
          risk,
          i,
          riskArray
        ): {
          level: string;
          daysOver: number;
          proposedEnd: string;
          WBS: boolean;
          daysPastProjectDeadline: number;
        } => {
          const riskLevel: string = Object.keys(risk)[0];
          const prevDaysOver =
            i === 0
              ? 0
              : Object.values(riskArray[i - 1])[0].width / HOURS_IN_A_DAY; // Get days of previous risk

          const daysOver = Object.values(risk)[0].width / HOURS_IN_A_DAY;
          const taskProposedEndDate = currentTaskEndDate.add(
            daysOver - prevDaysOver,
            "days"
          );

          const daysPastProjectDeadline = calculateDiffBetweenDates({
            taskProposedEndDate,
            siteEndDate,
          });

          const accuracy: string = Math.floor(currentTask.accuracy).toString();
          return {
            level: !currentTask.WBS ? riskLevel : accuracy,
            daysOver,
            proposedEnd: taskProposedEndDate.format("DD MMM YYYY"),
            WBS: currentTask.WBS ? true : false,
            daysPastProjectDeadline:
              daysPastProjectDeadline < 0 ? 0 : daysPastProjectDeadline,
          };
        }
      );

    if (currentTask.WBS) risks = [];

    const daysPastProjectDeadline = calculateDiffBetweenDates({
      taskProposedEndDate: moment(currentTask.end),
      siteEndDate,
    });

    const risksWithBase = [
      {
        level: !currentTask.WBS
          ? currentTask.baseAccuracy.toString()
          : Math.floor(currentTask.accuracy).toString(),
        daysOver: 0,
        proposedEnd: moment(currentTask.end).format("DD MMM YYYY"),
        WBS: currentTask.WBS ? true : false,
        daysPastProjectDeadline:
          daysPastProjectDeadline < 0 ? 0 : daysPastProjectDeadline,
      },
      ...risks,
    ];

    return risksWithBase;
  }
);

export const selectActiveRiskProfile = createSelector(
  [selectCurrentTask],
  (currentTask) => currentTask?.accuracy.toString()
);

export const selectBaseRiskProfile = createSelector(
  [selectCurrentTask],
  (currentTask) => currentTask?.baseAccuracy.toString()
);

export const selectIsInCriticalPath = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask?.isCriticalPath ? "Yes" : "No")
);
