//React
import React, { useState, useEffect } from "react";
//State
import { useAppDispatch, useAppSelector } from "store";
import { selectCurrentTask, updateTask } from "store/tasks";
import { setTaskModified } from "store/ui";
//Types
import { RootState } from "store";

interface IProps {
  stateValue: string;
  type: "title" | "description";
}

export const useInputEdit = ({ stateValue, type }: IProps) => {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(selectCurrentTask);

  const [mode, setMode] = useState<"read" | "edit">("read");
  const [inputValue, setInputValue] = useState(stateValue);

  //Clear state when navigating between tasks
  useEffect(() => {
    setMode("read");
    setInputValue(stateValue);
  }, [stateValue]);

  const updateLocalState = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.target.value);
  };

  const handleEditTaskTitle = (updatedValue: string) => {
    const update = {
      [type]: updatedValue,
    };
    dispatch(setTaskModified(true));
    dispatch(updateTask({ id: currentTask!.id!, update }));
  };

  const saveTitleChanges = () => {
    handleEditTaskTitle(inputValue);
    setMode("read");
  };

  const setToEditMode = () => {
    setMode("edit");
  };

  const handleKeyPressSave = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    saveTitleChanges();
  };

  return {
    mode,
    inputValue,
    updateLocalState,
    saveTitleChanges,
    setToEditMode,
    handleKeyPressSave,
  };
};
