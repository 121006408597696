import { IHistoricThresholds, ISite } from "@ehabitation/ts-utils/browser";
import { FC } from "react";
import HistoricThresholdTable from "./HistoricThresholdTable";

const CompensationEvents: FC<{ site: ISite }> = ({ site }) => {
  const historicThresholds = site.historicThresholds;

  const objKeys = [
    "cumRainfall",
    "daysRainAbove5mm",
    "daysTempBelow0C",
  ] as Array<keyof typeof historicThresholds>;

  return (
    <>
      {!historicThresholds || objKeys?.length === 0 ? (
        <div className="no-data-to-display">
          There are no 1 in 10 thresholds to display
        </div>
      ) : (
        <>
          {objKeys.map((threshold: keyof IHistoricThresholds) =>
            historicThresholds[threshold] ? (
              <HistoricThresholdTable
                key={threshold}
                threshold={threshold}
                values={historicThresholds[threshold]}
              />
            ) : null
          )}
        </>
      )}
    </>
  );
};

export default CompensationEvents;
