import moment from "moment";
import { FC } from "react";

export const DifferenceCell: FC<{
  base: number | string;
  mitigated?: number | string;
}> = ({ base = 0, mitigated }) => {
  const hasMitigation = typeof mitigated !== "undefined";
  let difference;
  let differenceClass = "text-gray-400";
  if (typeof mitigated === "number" && typeof base === "number") {
    difference = hasMitigation ? mitigated - base : 0;
    if (difference > 0) differenceClass = "text-red-500";
    if (difference < 0) differenceClass = "text-green-700";
  }

  return (
    <div className="text-center">
      <span></span>
      {hasMitigation && (
        <>
          <span className="text-center">{mitigated}</span>
        </>
      )}
    </div>
  );
};

export const DifferenceVarianceCell: FC<{
  base: number;
  mitigated?: number;
}> = ({ base, mitigated }) => {
  const hasMitigation = typeof mitigated === "number";
  const difference = hasMitigation ? mitigated - base : 0;
  let differenceClass = "text-gray-400";
  if (difference > 0) differenceClass = "text-red-500";
  if (difference < 0) differenceClass = "text-green-700";

  return (
    <div className="grid grid-cols-[1fr_min-content_1fr] gap-1 items-baseline text-right pr-6">
      <span>{mitigated}</span>
      <span
        className={`${differenceClass} absolute right-2 top-1/2 -translate-y-1/2 text-start text-xl`}
      >
        {difference}
      </span>
    </div>
  );
};

export const DifferenceDateCell: FC<{ base: Date; mitigated?: Date }> = ({
  base,
  mitigated,
}) => {
  const hasMitigation = !!mitigated;
  const difference = hasMitigation
    ? moment(mitigated).startOf("day").diff(moment(base).startOf("day"), "days")
    : 0;
  let differenceClass = "text-gray-400";
  if (difference > 0) differenceClass = "text-red-500";
  if (difference < 0) differenceClass = "text-green-700";

  return (
    <div className="grid gap-1 items-baseline text-center pl-4 pr-4">
      <span>
        {hasMitigation
          ? moment(mitigated).format("D/M/YY")
          : moment(base).format("D/M/YY")}
      </span>
    </div>
  );
};

export const ShortDifferenceDateCell: FC<{ base: Date; mitigated?: Date }> = ({
  base,
  mitigated,
}) => {
  const hasMitigation = !!mitigated;
  const difference = hasMitigation
    ? moment(mitigated).diff(moment(base), "days")
    : 0;
  let differenceClass = "text-gray-400";
  if (difference > 0) differenceClass = "text-red-500";
  if (difference < 0) differenceClass = "text-green-700";

  return (
    <div className="grid grid-cols-[auto_5px] gap-1 items-baseline text-center pr-6">
      <span className="">
        {hasMitigation
          ? moment(mitigated).format("D/M")
          : moment(base).format("D/M")}
      </span>
    </div>
  );
};
