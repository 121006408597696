import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { ICustomClaims } from "@ehabitation/ts-utils/browser";
import { selectUserClaims, selectRole } from "store/auth";
import { setOrganisationProjects } from "store/siteManagement";
import { IOrganisationProjects } from "types";
import { subscribeToProjects } from "helpers";
import { selectDivisionMap } from "store/userManagement";
import { difference } from "lodash";

/* 
Subscribe to all projects in a given organisation for orgAdmins,
all projects within a given division for divisionAdmins and
a single project for everyone else
*/
export const useSubscribeToProjects = () => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);
  const { organisation, division, project } = useAppSelector(
    selectUserClaims
  ) as ICustomClaims;

  const divisions = useAppSelector(selectDivisionMap);
  const [availableDivisions, setProjects] = useState<string[]>(
    Object.keys(divisions)
  );
  useEffect(() => {
    const diffDivisions = difference(
      Object.keys(divisions),
      availableDivisions
    );
    if (diffDivisions.length) setProjects(Object.keys(divisions));
  }, [divisions]);

  const [isReady, setReady] = useState(false);

  const dispatcher = useCallback(
    (organisationProjects: IOrganisationProjects) => {
      dispatch(setOrganisationProjects(organisationProjects));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!role) return;

    const unsubscribe = subscribeToProjects({
      role,
      orgId: organisation || "",
      divisionId: division || "",
      projectId: project || "",
      availableDivisions,
      dispatcher,
      setReady,
    });

    return unsubscribe;
  }, [role, organisation, division, project, availableDivisions, dispatcher]);

  return isReady;
};
