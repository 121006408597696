//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//State
export interface ISearchState {
  searchInput: string;
}

export const initialState: ISearchState = {
  searchInput: "",
};

//Slice
export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    filterText: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
  },
});

//Actions
export const { filterText } = searchSlice.actions;

//Reducer
export default searchSlice.reducer;
