import { useEffect, useState } from "react";
import { CollectionType, IRiskMatrix } from "@ehabitation/ts-utils/browser";
import { getRiskMatrixQuery } from "helpers";
import { onSnapshot } from "firebase/firestore";

export const useCheckPlanHash = (projectId: string, planId?: string) => {
  const [isLoadingPlanHash, setIsLoadingPlanHash] = useState<boolean>(false);
  const [isLoadingProjectHash, setIsLoadingProjectHash] =
    useState<boolean>(false);
  const [majorHashDrift, setMajorHashDrift] = useState<boolean>(false);
  const [minorHashDrift, setMinorHashDrift] = useState<boolean>(false);
  const [projectMajorHash, setProjectMajorHash] = useState<
    string | undefined
  >();
  const [projectMinorHash, setProjectMinorHash] = useState<
    string | undefined
  >();

  const clearStates = () => {
    setIsLoadingPlanHash(false);
    setIsLoadingProjectHash(false);
    setMajorHashDrift(false);
    setMinorHashDrift(false);
    setProjectMajorHash(undefined);
    setProjectMinorHash(undefined);
  };

  useEffect(() => {
    if (!projectId) return clearStates();
    setIsLoadingProjectHash(true);
    const unsubscribe = onSnapshot(
      getRiskMatrixQuery(projectId, CollectionType.Projects),
      (projectRM) => {
        const { majorHash, minorHash } =
          projectRM.docs[0].data() as IRiskMatrix;
        setProjectMajorHash(majorHash);
        setProjectMinorHash(minorHash);
        setIsLoadingProjectHash(false);
      }
    );
    return unsubscribe;
  }, [projectId, planId]);

  useEffect(() => {
    if (!planId) return clearStates();
    setIsLoadingPlanHash(true);
    const unsubscribe = onSnapshot(
      getRiskMatrixQuery(planId, CollectionType.Plans),
      (plan) => {
        const { majorHash: planMajorHash, minorHash: planMinorHash } =
          plan.docs[0].data() as IRiskMatrix;
        setMajorHashDrift(projectMajorHash !== planMajorHash);
        setMinorHashDrift(projectMinorHash !== planMinorHash);
        setIsLoadingPlanHash(false);
      }
    );
    return unsubscribe;
  }, [projectMajorHash, projectMinorHash, planId]);
  return {
    isLoading: isLoadingPlanHash || isLoadingProjectHash,
    majorHashDrift,
    minorHashDrift,
  };
};
