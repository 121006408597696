import { FC, useState } from "react";
import ThresholdInput from "./ThresholdInput";
import { WeatherKey } from "@ehabitation/ts-utils/browser";

interface AddThresholdSelectProps {
  id: string;
  selectedThreshold: WeatherKey | "";
  setSelectedThreshold: (threshold: WeatherKey | "") => void;
  handleThresholdChange: (threshold: WeatherKey, value: number | null) => void;
  availableColumns: WeatherKey[];
  readableThresholdNames: Record<WeatherKey, string>;
  activeThresholds: Record<string, any>;
  readOnly: boolean;
}

const AddThresholdSelect: FC<AddThresholdSelectProps> = ({
  id,
  selectedThreshold,
  setSelectedThreshold,
  handleThresholdChange,
  availableColumns,
  readableThresholdNames,
  activeThresholds,
  readOnly,
}) => {
  const [inputValue, setInputValue] = useState<number | null>(null);
  const [isAddVisible, setIsAddVisible] = useState<boolean>(false);

  return (
    <div className="flex items-center gap-2 mt-4">
      {availableColumns.length > 0 ? (
        <>
          <select
            onChange={(e) => {
              setSelectedThreshold(e.target.value as WeatherKey);
              setInputValue(null);
              setIsAddVisible(false);
            }}
            value={selectedThreshold}
            className="border px-2 py-1 rounded-md"
          >
            <option value="">Add another threshold</option>
            {availableColumns.map((threshold) => (
              <option key={threshold} value={threshold}>
                {readableThresholdNames[threshold]}
              </option>
            ))}
          </select>
          {selectedThreshold && (
            <div className="flex items-center gap-2">
              <span className="mr-2 pr-5">
                {selectedThreshold === WeatherKey.minTemp ? ">" : "<"}
              </span>
              <ThresholdInput
                id={`${id}_${selectedThreshold}`}
                className="flex-1 border border-black"
                thresholds={activeThresholds}
                readOnly={readOnly}
                handleChange={(value: number) => setInputValue(value)}
                type={selectedThreshold as WeatherKey}
                label={readableThresholdNames[selectedThreshold as WeatherKey]}
              />
              {!isAddVisible && inputValue !== null && (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    handleThresholdChange(selectedThreshold as WeatherKey, inputValue);
                    setSelectedThreshold("");
                    setInputValue(null);
                    setIsAddVisible(false);
                  }}
                >
                  Add
                </span>
              )}
            </div>
          )}
        </>
      ) : (
        <span>No thresholds left to be added</span>
      )}
    </div>
  );
};

export default AddThresholdSelect;
