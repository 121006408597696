import { FC, useCallback, useRef, useState } from "react";
import { StyledPlanDisplayInfo, StyledPlanTitle } from "elements";
import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import { useClickOutside } from "hooks/use-click-outside";
import VarianceConfiguratorPanel from "./VarianceConfiguratorPanel";

interface VarianceSelectorProps {
  plans?: IPlan[];
  basePlan: IPlan;
  handleSelectPlan: (selectedMitigationId: string) => void;
  currentVariancePlan: IPlan | undefined;
  isShort?: boolean;
  currentSite: ISite;
}

const VarianceSelector: FC<VarianceSelectorProps> = ({
  plans,
  basePlan,
  currentVariancePlan,
  handleSelectPlan,
  isShort = false,
  currentSite,
}) => {
  const [isPlanConfiguratorOpen, setIsPlanConfiguratorOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => setIsPlanConfiguratorOpen(false));
  const getDisplayLabel = useCallback(() => {
    if (!plans) {
      return "Loading Plans...";
    }

    if (!currentVariancePlan?.id) {
      return "Select a plan";
    }

    if (currentVariancePlan?.id) {
      const planName = currentVariancePlan?.title;
      return (
        <>
          <StyledPlanTitle>{planName}</StyledPlanTitle>
          <i className="font-light">
            &nbsp;
            {currentVariancePlan?.mitigatesPlanId === basePlan.id
              ? "Mitigation"
              : currentVariancePlan?.id === currentSite.mainPlanId
              ? "Main"
              : "Draft"}
          </i>
        </>
      );
    }
  }, [currentVariancePlan, plans]);

  return (
    <StyledPlanDisplayInfo
      ref={ref}
      onClick={() => setIsPlanConfiguratorOpen(!isPlanConfiguratorOpen)}
    >
      {getDisplayLabel()}
      {isPlanConfiguratorOpen && plans && (
        <VarianceConfiguratorPanel
          isShort={isShort}
          plans={plans.filter(
            (plan) =>
              !plan?.status ||
              !["discarded", "error", "categorised"].includes(plan?.status)
          )}
          basePlan={basePlan}
          selectedPlan={currentVariancePlan}
          currentSite={currentSite}
          togglePanel={setIsPlanConfiguratorOpen}
          handleSelectPlan={handleSelectPlan}
        />
      )}
    </StyledPlanDisplayInfo>
  );
};

export default VarianceSelector;
