//Store
import { createSelector } from "reselect";
//Selectors
import { selectProjectName, selectSiteName } from "store/project";

export const projectTitleSelector = createSelector(
  [selectProjectName, selectSiteName],
  (projectName, siteName) =>
    projectName && siteName ? `${projectName} : ${siteName}` : ""
);
