import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "store";
import { ITaskCategoriesMap, setTaskCategoriesMap } from "store/taskCategory";
import { determineTaskTypeDocRef } from "helpers";
import { ITaskCategory } from "@ehabitation/ts-utils/browser";
import {
  collection,
  DocumentData,
  DocumentReference,
  onSnapshot,
} from "firebase/firestore";

export const useTaskTypes = (projectId?: string) => {
  const dispatch = useAppDispatch();
  const unsubscribeRef = useRef<() => void>();
  const [docRef, setDocRef] = useState<DocumentReference<DocumentData> | null>(
    null
  );

  useEffect(() => {
    const checkCollection = async (projectId: string) => {
      const docReference = await determineTaskTypeDocRef(projectId);
      setDocRef(docReference);
    };

    if (projectId) {
      checkCollection(projectId);
    }
  }, [projectId]);

  //Watches the database, awaiting risks the model to complete
  useEffect(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }

    if (projectId && docRef) {
      unsubscribeRef.current = onSnapshot(
        collection(docRef, "categories"),
        (snapshot) => {
          const taskTypes = {} as ITaskCategoriesMap;

          snapshot.forEach((doc) => {
            const updatedDocData = {
              id: doc.id,
              ...doc.data(),
            } as ITaskCategory;
            taskTypes[doc.id] = updatedDocData;
          });
          dispatch(setTaskCategoriesMap(taskTypes));
        }
      );
    }
  }, [dispatch, projectId, docRef]);
};
