import { useEffect, useState } from "react";

import { UserRole } from "@ehabitation/ts-utils/browser";
import { RootState, useAppSelector } from "store";
import { selectDivisionMap } from "store/userManagement";
import { selectOrderedProjectsByDivision } from "store/divisionManagement";
import DivisionRow from "./DivisionRow";
import { IProjectWithSites } from "types";

interface IDivisionProject {
  divisionId: string;
  projects: IProjectWithSites[];
}

const DivisionsList = () => {
  const orderedDivisions = useAppSelector(selectOrderedProjectsByDivision);
  const divisionMap = useAppSelector(selectDivisionMap);
  const { searchInput } = useAppSelector((state: RootState) => state.search);
  const [searchedDivisions, setSearchedDivisions] = useState<
    IDivisionProject[] | null
  >(null);
  const [divisionProjects, setDivisionProjects] = useState<IDivisionProject[]>(
    []
  );

  const checkProjects = (projects: IProjectWithSites[]) => {
    const names = projects.map(({ name, projectCode }) =>
      projectCode ? projectCode : name
    );
    return names.join(" ").toLowerCase().includes(searchInput);
  };

  useEffect(() => {
    const createDivisionProjects = () =>
      Object.entries(orderedDivisions)
        .sort((a, b) => {
          if (a[0] > b[0]) return 1;
          if (b[0] > a[0]) return -1;
          return 0;
        })
        .map(([divisionId, projects]) => {
          return { divisionId, projects };
        });
    setDivisionProjects(createDivisionProjects());
  }, [orderedDivisions]);

  useEffect(() => {
    const filterDivisions = () => {
      return divisionProjects.filter(
        (dp) =>
          divisionMap[dp.divisionId]?.name
            ?.toLowerCase()
            .includes(searchInput) ||
          checkProjects(dp.projects) ||
          (divisionMap[dp.divisionId].visibility
            ? divisionMap[dp.divisionId]
                .visibility!?.toLowerCase()
                .includes(searchInput)
            : UserRole.planner?.toLowerCase().includes(searchInput))
      );
    };
    setSearchedDivisions(
      searchInput === "" ? divisionProjects : filterDivisions()
    );
  }, [searchInput, divisionProjects]);

  return (
    <>
      {searchedDivisions?.map((sd) => {
        const division = divisionMap[sd.divisionId];
        return (
          <DivisionRow
            division={division}
            key={sd.divisionId}
            projects={sd.projects}
          />
        );
      })}
    </>
  );
};

export default DivisionsList;
