import { ISimulation } from "@ehabitation/ts-utils/browser";
import { useSiteOpenPageEvent } from "hooks";
import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "store";
import { IOrganisationSite } from "types";
import { useSimulationResult } from "../hooks";

const months = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

interface TraData {
  // historicalYears: number; // 70
  weatherTypes: string[]; // temp, rain, wind, snow etc.
  simulationCount: number; // 1000
  // intervalWeatherDays: { [key: string]: number }; // min, max, mean, 20, 40, 60, 80, 90, 95, 100
  weatherDaysPerQuantile: {
    [key: string]: number; // min, max, mean, 20, 40, 60, 80, 90, 95, 99
  };
  weatherDaysPerQuantilePerYear: {
    [year: string]: {
      [key: string]: number; // min, max, mean, 20, 40, 60, 80, 90, 95, 99
    };
  };
  weatherDaysPerQuantilePerMonth: TraDataWeatherDays;
}
interface TraDataWeatherDays {
  [year: string]: {
    // 2023
    [month: string]: {
      // 1
      [key: string]: number; // min, max, mean, 20, 40, 60, 80, 90, 95, 100
    };
  };
}

const trimmData = (
  weatherDays: TraDataWeatherDays,
  data: string[],
  months: string[]
) => {
  const weatherDaysPerQuantilePerMonth: TraDataWeatherDays = {};
  let canTrimm = true;
  data.forEach((year) => {
    months.forEach((month) => {
      const yearMonth = weatherDays[year][month];
      if (yearMonth) {
        const hasValue = Object.keys(yearMonth).find(
          (key) => yearMonth[key] !== 0
        );
        if ((canTrimm && hasValue) || !canTrimm) {
          if (!weatherDaysPerQuantilePerMonth[year])
            weatherDaysPerQuantilePerMonth[year] = {};
          weatherDaysPerQuantilePerMonth[year][month] = yearMonth;
          canTrimm = false;
        }
      }
    });
  });
  return weatherDaysPerQuantilePerMonth;
};

export const TraView: FC<{
  site?: IOrganisationSite;
  orgId?: string;
  siteId?: string;
  planId?: string;
  simulation: ISimulation;
  setPrintableLink?: (link: string) => void;
  setHelpLink?: (link: string) => void;
}> = ({ site, orgId, siteId, planId, simulation, setPrintableLink, setHelpLink }) => {
  const dispatch = useAppDispatch();
  const [riskSoftwareOpen, setRiskSoftwareOpen] = useState(false);
  const [siteStatsModalOpen, setSiteStatsModalOpen] = useState<boolean>(false);
  const [
    trimmedWeatherDaysPerQuantilePerMonth,
    setTrimmedWeatherDaysPerQuantilePerMonth,
  ] = useState<TraDataWeatherDays>();
  const { simulationResult, simulationResultLoading } = useSimulationResult(
    simulation,
    "traResultsWithDependencies"
  ) as {
    simulation: ISimulation | undefined;
    simulationResult: TraData | undefined;
    simulationLoading: boolean;
    simulationResultLoading: boolean;
    requestSimulation: () => void;
  };

  useEffect(() => {
    if (setPrintableLink && !simulationResultLoading && siteId && planId) {
      setPrintableLink(`/print/${siteId}/${planId}/tra-qsra`);
      return () => setPrintableLink("");
    }
  }, [setPrintableLink, simulationResultLoading, siteId, planId]);

  useEffect(() => {
    if (simulationResult) {
      const orderedData = Object.keys(
        simulationResult.weatherDaysPerQuantilePerMonth
      ).sort((a, b) => (Number(a) - Number(b) < 0 ? -1 : 1));
      const weatherDaysPerQuantilePerMonth: TraDataWeatherDays = trimmData(
        simulationResult.weatherDaysPerQuantilePerMonth,
        orderedData,
        months
      );
      const reversedData = Object.keys(weatherDaysPerQuantilePerMonth).sort(
        (a, b) => (Number(b) - Number(a) < 0 ? -1 : 1)
      );
      const trimmedWeatherDays: TraDataWeatherDays = trimmData(
        weatherDaysPerQuantilePerMonth,
        reversedData,
        months.slice().reverse()
      );

      setTrimmedWeatherDaysPerQuantilePerMonth(trimmedWeatherDays);
    }
  }, [simulationResult]);

  useEffect(() => {
    if (setHelpLink) {
      setHelpLink('https://ehab.co/docs/how-to-run-bids-using-the-tra-qsra-dashboard/');
      return () => setHelpLink("");
    }
  }, []);

  // will only fire if siteId is defined, not defined on the printable page
  useSiteOpenPageEvent("tra", siteId, planId);

  if (simulationResultLoading) {
    return <div>Loading...</div>;
  }

  return simulationResult && trimmedWeatherDaysPerQuantilePerMonth ? (
    <div className="m-auto w-full max-w-[100rem] mt-20">
      <table className="w-full mt-8 text-center border-collapse border-2 ">
        <thead>
          <tr className="">
            <th colSpan={9}>
              <h2>Weather Days</h2>
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th className="border border-y-2 border-l-2">Min</th>
            <th className="border border-y-2">Max</th>
            <th className="border border-y-2">Mean</th>
            <th className="border border-y-2">20%</th>
            <th className="border border-y-2">40%</th>
            <th className="border border-y-2">80%</th>
            <th className="border border-y-2">95%</th>
          </tr>
          <tr className="bg-gray-100">
            <th colSpan={2} className="border">
              Total for the Project
            </th>
            <th className="border ">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["min"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["max"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["mean"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["p20"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["p40"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["p80"].toFixed(0)
                : "-"}
            </th>
            <th className="border">
              {simulationResult.weatherDaysPerQuantile
                ? simulationResult.weatherDaysPerQuantile["p95"].toFixed(0)
                : "-"}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(trimmedWeatherDaysPerQuantilePerMonth)
            .sort((a, b) => (Number(a) - Number(b) < 0 ? -1 : 1))
            .map((year) => (
              <>
                {months.map((month, i) => (
                  <tr
                    key={year + month}
                    className={`${i === 0 && "border-t-2"} ${
                      i === 11 && "border-b-2"
                    }`}
                  >
                    {i === 0 && (
                      <th rowSpan={13} scope="row" className="border-2">
                        {year}
                      </th>
                    )}
                    {trimmedWeatherDaysPerQuantilePerMonth[year][month] && (
                      <>
                        <th
                          scope="row"
                          className="border border-r-2 capitalize"
                        >
                          {month}
                        </th>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "min"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "max"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "mean"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "p20"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "p40"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "p80"
                          ].toFixed(0)}
                        </td>
                        <td className="border">
                          {trimmedWeatherDaysPerQuantilePerMonth[year][month][
                            "p95"
                          ].toFixed(0)}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                <tr className="bg-gray-100">
                  <th scope="row" className="border border-r-2 capitalize">
                    Year Total
                  </th>
                  <td className="border border-y-2 border-l-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "min"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "max"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "mean"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "p20"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "p40"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "p80"
                        ].toFixed(0)
                      : "-"}
                  </td>
                  <td className="border border-y-2">
                    {simulationResult.weatherDaysPerQuantilePerYear
                      ? simulationResult.weatherDaysPerQuantilePerYear[year][
                          "p95"
                        ].toFixed(0)
                      : "-"}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
    </div>
  ) : null;
};
