import { ITaskCategory, WeatherKey } from "@ehabitation/ts-utils/browser";
import { TableCell } from "@material-ui/core";
import {
  WbSunny,
  Toys,
  Opacity,
  AcUnit,
  Waves,
  Visibility
} from "@material-ui/icons";
import React, { FC } from "react";
import { TableRow } from "ui-shadcn/table";
import { ISiteHeatmapData } from "../CategoryHeatmaps/SingleCategoryHeatmap";
import { SingleRowHeatmap } from "../CategoryHeatmaps/singlerow-heatmap";
import ThresholdInput from "./ThresholdInput";

interface ThresholdListProps {
  id: string;
  thresholds: Record<string, any>;
  displayedColumns: WeatherKey[];
  handleThresholdChange: (threshold: WeatherKey, value: any) => void;
  handleRemoveThreshold: (threshold: WeatherKey) => void;
  changeMap: Record<string, any>;
  activeThresholds: Record<string, any>;
  readOnly: boolean;
  readableThresholdNames: Record<WeatherKey, string>;
  category: ITaskCategory;
  heatmap: ISiteHeatmapData | undefined;
}

const thresholdIcons: Record<WeatherKey, React.ReactElement> = {
  minTemp: <WbSunny style={{ fontSize: 18 }} />,
  maxTemp: <WbSunny style={{ fontSize: 18 }} />,
  wind: <Toys style={{ fontSize: 18 }} />,
  windGusts: <Toys style={{ fontSize: 18 }} />,
  dailyRainAcc: <Opacity style={{ fontSize: 18 }} />,
  hourlyRainAcc: <Opacity style={{ fontSize: 18 }} />,
  snowfall24Hour: <AcUnit style={{ fontSize: 18 }} />,
  snowfall: <AcUnit style={{ fontSize: 18 }} />,
  waveHeight: <Waves style={{ fontSize: 18 }} />,
  visibility: <Visibility style={{ fontSize: 18 }} />
};

const heatmapNames: Record<WeatherKey, string> = {
  minTemp: "Min Daily Temp (°C)",
  maxTemp: "Max Daily Temp (°C)",
  wind: "Wind Speed (m/s)",
  windGusts: "Wind Gusts (m)",
  dailyRainAcc: "Daily Cumulative Rainfall (mm)",
  hourlyRainAcc: "Max Hourly Snowfall (mm/h)",
  snowfall24Hour: "Max Hourly Snowfall (mm/h)",
  snowfall: "Max Hourly Snowfall (mm/h)",
  waveHeight: "Wave Height (m)",
  visibility: "Visibility (km)"
};

const ThresholdList: FC<ThresholdListProps> = ({
  id,
  thresholds,
  displayedColumns,
  handleThresholdChange,
  handleRemoveThreshold,
  changeMap,
  activeThresholds,
  readOnly,
  readableThresholdNames,
  category,
  heatmap,
}) => {
  const sortedColumns = [...displayedColumns].sort((a, b) => 
    readableThresholdNames[a].localeCompare(readableThresholdNames[b])
  );

  return (
    <>
      {sortedColumns.map((threshold) => (
        <TableRow className="border-hidden" key={`${id}_${threshold}`}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <React.Fragment>
            <TableCell>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {thresholdIcons[threshold]}
                  <span className="whitespace-nowrap">
                    {readableThresholdNames[threshold]}
                  </span>
                </div>
                <div className="flex items-center justify-end">
                  <span className="ml-4 mr-4">
                    {threshold === WeatherKey.minTemp ? ">" : "<"}
                  </span>
                  <ThresholdInput
                    id={`${id}_${threshold}`}
                    className={`flex-1 border border-black ${
                      changeMap[id]?.[threshold] !== thresholds[threshold]
                        ? "border-emerald-500 border-2"
                        : ""
                    }`}
                    thresholds={activeThresholds}
                    readOnly={readOnly}
                    handleChange={(value: number) =>
                      handleThresholdChange(threshold, value)
                    }
                    type={threshold}
                    label={readableThresholdNames[threshold]}
                  />
                  <button
                    onClick={() => handleRemoveThreshold(threshold)}
                    className="ml-2 text-red-300"
                  >
                    X
                  </button>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="col-span-8 items-start">
                <SingleRowHeatmap
                  data={
                    heatmap && heatmap[category.name]
                      ? heatmap[category.name].factors_success_ratio[heatmapNames[threshold]]
                      : []
                  }
                  title={""}
                  key={`${category.name}_${threshold}`}
                />
              </div>
            </TableCell>
          </React.Fragment>
        </TableRow>
      ))}
    </>
  );
};

export default ThresholdList;
