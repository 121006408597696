import { createSelector } from "reselect";
import { selectDelayDistribution } from "store/tasks";
import moment from "moment";
import { mean } from "mathjs";

const selectOrderedDelayDistributionEntries = createSelector(
  [selectDelayDistribution],
  (delayDistribution) => {
    if (!delayDistribution) return [];

    return Object.entries(delayDistribution)
      .filter(([_, hits]) => hits > 0)
      .sort((a, b) => {
        const [aDateString] = a;
        const [bDateString] = b;

        const aTS = moment(aDateString).valueOf();
        const bTS = moment(bDateString).valueOf();

        return aTS - bTS;
      });
  }
);

export const selectMinMaxEndDate = createSelector(
  [selectOrderedDelayDistributionEntries],
  (orderedEntries) => {
    const orderedTimestamps = orderedEntries.map(([dateString]) =>
      moment(dateString).valueOf()
    );
    const minEndDate = moment(orderedTimestamps[0]).format("DD/MM/YYYY");
    const maxEndDate = moment(
      orderedTimestamps[orderedTimestamps.length - 1]
    ).format("DD/MM/YYYY");
    return { minEndDate, maxEndDate };
  }
);

export const selectMeanEndDate = createSelector(
  [selectOrderedDelayDistributionEntries],
  (orderedEntries) => {
    const dates: number[] = [];

    orderedEntries.forEach(([date, hits]) => {
      for (let i = 0; i < hits; i++) dates.push(moment(date).valueOf()); //Add timestamps for calculating mean and median
    });

    return dates.length > 0
      ? moment(mean(dates)).format("DD/MM/YYYY")
      : "Error";
  }
);
