import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import {
  WeatherMetric,
  ColorOption,
  Source,
  SourceSeriesConfigs,
} from "./types";

import { colors } from "styles/variables";

const selectColor = (color: ColorOption) => {
  if (color === "purple") return am4core.color(colors.purple);
  if (color === "teal") return am4core.color(colors.teal);
  else return am4core.color(colors.blue);
};

export const sources: Source[] = ["iot", "forecast", "realTime"];

export const weatherMetrics: WeatherMetric[] = [
  "temperature",
  "windSpeed",
  "precipitation",
  "precipitationAccumulation",
];

const sourceSeriesConfigs: SourceSeriesConfigs = {
  iot: {
    label: "Site Sensor",
    color: "purple",
  },
  realTime: {
    label: "Region Sensor",
    color: "blue",
  },
  forecast: {
    label: "Forecast",
    color: "teal",
  },
};

const Y_AXIS_ID = "y-axis";

const getLineSeries = (
  dataKey: string,
  label: string,
  unit: string,
  color: ColorOption
) => {
  const lineSeries = new am4charts.LineSeries();

  lineSeries.dataFields.valueY = dataKey;
  lineSeries.dataFields.dateX = "time";
  lineSeries.stroke = selectColor(color);
  lineSeries.strokeWidth = 1;
  lineSeries.strokeDasharray = "2,5";
  lineSeries.fillOpacity = 0.1;
  lineSeries.fill = selectColor(color);
  lineSeries.hidden = true;
  lineSeries.tooltipText = `${label}: {valueY.value}${unit}`;

  return lineSeries;
};

const getColumnSeries = (
  dataKey: string,
  label: string,
  unit: string,
  color: ColorOption
) => {
  const columnSeries = new am4charts.ColumnSeries();
  columnSeries.dataFields.valueY = dataKey;
  columnSeries.dataFields.dateX = "time";
  columnSeries.strokeWidth = 0;
  columnSeries.fill = selectColor(color);
  columnSeries.fillOpacity = 0.3;
  columnSeries.hidden = true;
  columnSeries.tooltipText = `${label}: {valueY.value}${unit}`;

  return columnSeries;
};

am4core.useTheme(am4themes_animated);

export const createChart = (elementId: string): am4charts.XYChart => {
  const chart = am4core.create(elementId, am4charts.XYChart);
  chart.cursor = new am4charts.XYCursor();
  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  chart.scrollbarX.strokeWidth = 1;
  chart.scrollbarX.thumb.minWidth = 30;
  customizeGrip(chart.scrollbarX.startGrip);
  customizeGrip(chart.scrollbarX.endGrip);
  chart.scrollbarX.background.fillOpacity = 0.2;
  chart.scrollbarX.minHeight = 3;
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.formatOptions.getKey("html")!.disabled = true;
  chart.exporting.formatOptions.getKey("print")!.disabled = true;
  chart.exporting.formatOptions.getKey("pdf")!.disabled = true;
  chart.exporting.formatOptions.getKey("pdfdata")!.disabled = true;

  // TODO: fix and re-enable zoomOutButton https://app.clickup.com/t/28a6jhp
  chart.zoomOutButton.disabled = true;
  return chart;
};

function customizeGrip(grip: am4core.ResizeButton) {
  grip.background.fill = am4core.color("rgb(122, 223, 177)");
  grip.background.fillOpacity = 0.6;
  grip.width = 20;
  grip.height = 20;
  grip.icon.disabled = true;
}

export const setupDataLoader = (
  chart: am4charts.XYChart,
  dataUrl: string,
  handleNoData: Function,
  handleDataParsed: Function,
  handleDataLoadError: Function
) => {
  chart.dataSource.url = dataUrl;

  const parser = new am4core.JSONParser();
  // set dateFormat to epoch millis
  parser.options.dateFormat = "x";

  chart.dataSource.parser = parser;

  chart.dataSource.adapter.add("parsedData", function (data) {
    if (data.length === 0) {
      handleNoData();
    }
    handleDataParsed();

    // TODO: sort forecast data server-side at cache time https://app.clickup.com/t/28a6hag
    return data.sort((a: any, b: any) => a.time - b.time);
  });

  chart.dataSource.events.on("error", function (error) {
    console.error(error);
    handleDataLoadError();
  });
};

export const addTempSeries = (chart: am4charts.XYChart, source: Source) => {
  const sourceSeriesConfig = sourceSeriesConfigs[source];
  const dataKey = `${source}_temperature`;

  const tempSeries = getLineSeries(
    dataKey,
    sourceSeriesConfig.label,
    "°C",
    sourceSeriesConfig.color
  );

  chart.series.push(tempSeries);
};

export const addWindSeries = (chart: am4charts.XYChart, source: Source) => {
  const sourceSeriesConfig = sourceSeriesConfigs[source];
  const dataKey = `${source}_windSpeed`;

  const windSeries = getLineSeries(
    dataKey,
    sourceSeriesConfig.label,
    "m/s",
    sourceSeriesConfig.color
  );
  chart.series.push(windSeries);
};

export const addPrecSeries = (chart: am4charts.XYChart, source: Source) => {
  const sourceSeriesConfig = sourceSeriesConfigs[source];
  const dataKey = `${source}_precipitation`;

  const precipSeries = getColumnSeries(
    dataKey,
    sourceSeriesConfig.label,
    "mm",
    sourceSeriesConfig.color
  );
  precipSeries.groupFields.valueY = "sum";
  chart.series.push(precipSeries);
};

export const addPrecAccSeries = (chart: am4charts.XYChart, source: Source) => {
  const sourceSeriesConfig = sourceSeriesConfigs[source];
  const dataKey = `${source}_precipitationAccumulation`;

  const precAccSeries = getLineSeries(
    dataKey,
    sourceSeriesConfig.label,
    "mm",
    sourceSeriesConfig.color
  );
  chart.series.push(precAccSeries);
};

export const addXAxis = (chart: am4charts.XYChart) => {
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.renderer.grid.template.disabled = false;
  dateAxis.minZoomCount = 1;
  dateAxis.groupData = true;
  dateAxis.dataFields.date = "time";
  dateAxis.renderer.grid.template.strokeDasharray = "1,30";
  dateAxis.renderer.grid.template.strokeOpacity = 1;
  dateAxis.renderer.grid.template.stroke = am4core.color("#8b8b8b");
  dateAxis.fontSize = 9;
  dateAxis.tooltip!.disabled = false;
  dateAxis.renderer.labels.template.wrap = true;
  dateAxis.renderer.labels.template.maxWidth = 100;
};

export const addYAxis = (chart: am4charts.XYChart) => {
  const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  yAxis.title.rotation = 0;
  yAxis.id = Y_AXIS_ID;
  yAxis.renderer.minGridDistance = 15;
  yAxis.renderer.fontSize = 10;
  yAxis.renderer.grid.template.strokeWidth = 0;
};

export const showSeries = (
  chart: am4charts.XYChart,
  seriesToShow: string[]
) => {
  chart.series.each((series) => {
    if (series.dataFields.valueY) {
      seriesToShow.includes(series.dataFields.valueY)
        ? series.show()
        : series.hide();
    }
  });
};

export const setYAxisLabel = (chart: am4charts.XYChart, label: string) => {
  const yAxis = chart.map.getKey(Y_AXIS_ID);
  yAxis!.title!.text = label;
};
