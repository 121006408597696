import { ITask } from "@ehabitation/ts-utils/browser";

export const recalculateWbsTask = (
  currentTask: ITask,
  allTasksAsArray: ITask[]
) => {
  const parentId = currentTask!.parentId;
  const currentTaskId = currentTask!.id;

  let foundTask = allTasksAsArray!.find((task) => task.objectId === parentId);

  if (!foundTask) return null;

  const wbs = { ...foundTask };

  //For older dummy tasks created without parentId/objectId, both are null therefore equal and wbs = the task so creates an error later
  if (!wbs || !parentId) return null;

  if (currentTaskId && allTasksAsArray && wbs) {
    const wbsSubtasks = getWBSSubTasks(currentTaskId, wbs, allTasksAsArray);

    wbsSubtasks.push(currentTask);

    const newWbsStart = getFirstStartDate(wbsSubtasks);
    const newWbsEnd = getLastEndDate(wbsSubtasks);

    wbs.start = newWbsStart;
    wbs.end = newWbsEnd;
  }

  return wbs;
};

const getWBSSubTasks = (
  currentTaskId: string,
  wbs: ITask,
  allTasksAsArray: ITask[]
) => {
  let subtaskIds = [...wbs.subtasks!];

  // Get index of deleted task and remove from WBS subtasks
  const taskIndex = subtaskIds.indexOf(currentTaskId);
  if (taskIndex > -1) {
    subtaskIds.splice(taskIndex, 1);
  }

  // Recalculate WBS bar here
  const subtasks = allTasksAsArray.filter((task) =>
    subtaskIds.includes(task.id!)
  );

  return subtasks;
};

const getFirstStartDate = (tasks: ITask[]) => {
  tasks.sort(
    (a: ITask, b: ITask) =>
      new Date(a.start).getTime() - new Date(b.start).getTime()
  );

  return tasks[0].start;
};

const getLastEndDate = (tasks: ITask[]) => {
  tasks.sort(
    (a: ITask, b: ITask) =>
      new Date(a.safetyEnd!).getTime() - new Date(b.safetyEnd!).getTime()
  );

  return new Date(tasks[tasks.length - 1].safetyEnd!);
};
