import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IMenuState {
  isOpen: boolean;
}

export const initialState: IMenuState = {
  isOpen: false,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    clickMenuButton: (state) => {
      state.isOpen = !state.isOpen;
    },

    closeSettingsMenu: (state) => {
      state.isOpen = false;
    },
  },
});

export const { closeSettingsMenu, clickMenuButton } = menuSlice.actions;

export const selectMenuStatus = (state: RootState) => state.menu.isOpen;

export default menuSlice.reducer;
