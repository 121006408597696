import { createSelector } from "reselect";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement/siteManagementSlice";
import {
  selectDivisionMap,
  selectOrganisationUsers,
} from "store/userManagement/userManagementSlice";
import { IProjectWithSites } from "types";
import { selectSelectedDivision } from "./divisionManagementSlice";
import { selectUserClaims } from "store/auth";

type ProjectsWithSites = Record<string, IProjectWithSites>;

export const selectOrderedProjectsByDivision = createSelector(
  [
    selectOrganisationProjects,
    selectOrganisationSites,
    selectDivisionMap,
    selectOrganisationUsers,
    selectUserClaims,
  ],
  (projects, sites, divisionMap, users, claims) => {
    const userIsGuest = claims?.role === "guest";
    /* Add An Array Of Sites To Project Data */
    const projectsWithSites = Object.entries(
      projects
    ).reduce<ProjectsWithSites>((acc, [id, project]) => {
      return { ...acc, [id]: { ...project, id, sites: [] } };
    }, {});
    Object.entries(sites).forEach(([siteId, site]) => {
      const projectId = sites[siteId].project;
      const siteName = sites[siteId].name;
      const siteStatus = sites[siteId].status;

      if (!projectId || !siteName || !siteStatus) return;

      if (userIsGuest && site.visibility !== "guest") return;

      //Add any sites to the array and sort
      projectsWithSites[projectId].sites?.push(sites[siteId]);
      projectsWithSites[projectId].sites?.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
    });

    /* Organise Projects By Division */
    const projectsByDivision: {
      [key: string]: IProjectWithSites[];
    } = {};
    Object.entries(divisionMap).forEach(([divisionId, division]) => {
      projectsByDivision[divisionMap[divisionId].id] = [];
    });

    Object.entries(projectsWithSites).forEach(([projectId, project]) => {
      const divisionId = projectsWithSites[projectId].divisionId!;
      const divisionName = divisionMap[divisionId].id;

      if (userIsGuest && project.visibility !== "guest") return;

      //Add number of users on each project for display in UI
      const noOfUsers = Object.keys(users).filter(
        (userId) => users[userId].project === projectId
      ).length;

      //If no projects exists, create an array with first one
      if (!projectsByDivision.hasOwnProperty(divisionName)) {
        projectsByDivision[divisionName] = [
          { ...projectsWithSites[projectId], users: noOfUsers },
        ];
        return;
      }

      //If projects already exist, add on to the arrray and sort alphabetically
      if (projectsByDivision.hasOwnProperty(divisionName)) {
        projectsByDivision[divisionName] = [
          ...projectsByDivision[divisionName],
          { ...projectsWithSites[projectId], users: noOfUsers },
        ].sort((a, b) => {
          if (a.name! > b.name!) return 1;
          if (b.name! > a.name!) return -1;
          return 0;
        });
        return;
      }
    });

    return projectsByDivision;
  }
);

export const selectNoOfSitesByDivision = createSelector(
  [selectOrganisationSites],
  (sites) => {
    if (!sites) return {};

    return Object.values(sites).reduce((total, current) => {
      const division = current.divisionId;
      if (!division) return total;

      return {
        ...total,
        [division]: !total[division] ? 1 : total[division] + 1,
      };
    }, {} as Record<string, number>);
  }
);

export const selectNoOfUsersByDivision = createSelector(
  [selectOrganisationUsers],
  (users) => {
    if (!users) return {};

    return Object.values(users).reduce((total, current) => {
      const division = current.divisionId;
      if (!division) return total;

      return {
        ...total,
        [division]: !total[division] ? 1 : total[division] + 1,
      };
    }, {} as Record<string, number>);
  }
);

export const selectSelectedDivisionDetails = createSelector(
  [selectSelectedDivision, selectDivisionMap],
  (selectedDivision, divisionMap) => {
    if (!selectedDivision) return null;
    return divisionMap[selectedDivision];
  }
);
