import { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { prepareParetoData } from "./helpers";
import { useAppSelector } from "store";
import { selectDelayDistributionAsArray } from "store/selectors";
import { grays, colors } from "styles";

export const useHistogramChart = () => {
  const delayDistribution = useAppSelector(selectDelayDistributionAsArray);

  am4core.options.autoDispose = true;

  useLayoutEffect(() => {
    if (!delayDistribution || delayDistribution.length === 0) return;

    // Build + Populate
    let x = am4core.create("histogramdiv", am4charts.XYChart);
    x.preloader!.disabled = true;
    x.data = prepareParetoData(delayDistribution);

    // Date : Axis
    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = "activityEndDate";
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.tooltip!.disabled = true;
    dateAxis.dateFormats.setKey("day", "d/M/yyyy");

    // Hits : Axis
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    valueAxis.title.text = "Hits";
    valueAxis.title.rotation = -90;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "middle";
    valueAxis.title.marginRight = 15;

    // Pareto : Axes
    let paretoValueAxis = x.yAxes.push(new am4charts.ValueAxis());
    paretoValueAxis.renderer.opposite = true;
    paretoValueAxis.strictMinMax = true;
    paretoValueAxis.renderer.grid.template.disabled = true;
    paretoValueAxis.cursorTooltipEnabled = false;
    paretoValueAxis.renderer.labels.template.disabled = true;

    paretoValueAxis.title.text = "Cumulative Frequency";
    paretoValueAxis.title.rotation = -90;
    paretoValueAxis.title.valign = "middle";
    paretoValueAxis.title.marginLeft = 15;

    // Bar : Series
    let series = x.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "simulations";
    series.dataFields.dateX = "activityEndDate";
    // series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
    series.tooltipText =
      "[{categoryX}: bold]{dateX.formatDate('d/M/yyyy')} : {valueY}[/]";
    series.tooltip!.pointerOrientation = "vertical";
    series.columns.template.strokeWidth = 0;
    series.columns.template.fill = am4core.color(grays.light);
    series.columns.template.column.fillOpacity = 1;
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.fillOpacity = 1;

    series.tooltip!.getFillFromObject = false;
    series.tooltip!.background.fill = am4core.color(colors.primaryL);
    series.tooltip!.autoTextColor = false;
    series.tooltip!.label.fill = am4core.color("#000");

    // Pareto : Series
    let paretoSeries = x.series.push(new am4charts.LineSeries());
    paretoSeries.dataFields.valueY = "pareto";
    paretoSeries.dataFields.dateX = "activityEndDate";
    paretoSeries.yAxis = paretoValueAxis;
    paretoSeries.tooltipText =
      "Simulations Ran: {valueY.formatNumber('#.0')}%[/]";
    paretoSeries.strokeWidth = 1;
    paretoSeries.stroke = new am4core.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    paretoSeries.strokeOpacity = 1;

    paretoSeries.tooltip!.getFillFromObject = false;
    paretoSeries.tooltip!.background.fill = am4core.color(colors.primaryL);
    paretoSeries.tooltip!.autoTextColor = false;
    paretoSeries.tooltip!.label.fill = am4core.color("#000");

    // Cursor
    x.cursor = new am4charts.XYCursor();
    x.cursor.behavior = "panX";

    // Bullets
    x.maskBullets = false;
  }, [delayDistribution]);

  return { canShowChart: delayDistribution && delayDistribution.length > 0 };
};
