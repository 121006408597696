import { IPlan, IRiskMatrix, ISite, ITask, ITaskCategory } from "@ehabitation/ts-utils/browser";
import { WeatherView } from "Pages/sites/weather/WeatherView";

import { SimulationTaskResult } from "helpers";
import { PlanRequiredWrapper } from "../PlanRequiredWrapper";
import { PlanTasksLoader } from "../PlanTasksLoader";
import { RouteContainer } from "../RouteContainer";

export function WeatherRoute(basePlanId: string | undefined, basePlan: IPlan | undefined, site: ISite, siteCategories: ITaskCategory[], tasksError: string | undefined, allTasksWithSimulationResults: (ITask & { simulation: SimulationTaskResult; })[] | undefined, isLoadingTasks: boolean, siteRiskMatrix: IRiskMatrix, setPrintableLink: { (id: string): void; (id: string): void; }, setHelpLink: { (id: string): void; (id: string): void; }) {
    return <RouteContainer>
      {" "}
      {basePlanId ? (
        <PlanRequiredWrapper
          plan={basePlan}
          site={site}
          renderContent={({ plan: basePlan }) => (
            <PlanTasksLoader
              site={site}
              plan={basePlan}
              siteCategories={siteCategories}
              tasksError={tasksError}
              allTasksWithSimulationResults={allTasksWithSimulationResults}
              isLoadingTasks={isLoadingTasks}
              renderContent={({
                allTasks, planCategories, planRiskMatrix,
              }) => (
                <WeatherView
                  allTasks={allTasks}
                  site={site}
                  planId={basePlan.id}
                  riskMatrix={planRiskMatrix || siteRiskMatrix}
                  categories={planCategories || siteCategories}
                  setPrintableLink={setPrintableLink}
                  setHelpLink={setHelpLink} />
              )} />
          )} />
      ) : (
        <WeatherView
          site={site}
          riskMatrix={siteRiskMatrix}
          categories={siteCategories}
          setPrintableLink={setPrintableLink}
          setHelpLink={setHelpLink} />
      )}
    </RouteContainer>;
  }