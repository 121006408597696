import { FC } from "react";

interface IProps {
  id: string;
  code: string;
  type: string;
  label: string;
  className?: string;
  readOnly: boolean;
  handleChange: Function;
}

const CodeInput: FC<IProps> = ({
  id,
  code,
  type,
  readOnly,
  label,
  handleChange,
  className,
}) => {
  return (
    <div className={`flex flex-col ${className}`} data-testid={`${type.toLowerCase()}-code`}>
      <input
        type="text"
        defaultValue={code}
        className="px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 "
        onChange={(event) => handleChange(String(event.target.value))}
        name={`${type}Code`}
        aria-label={label}
        disabled={readOnly}
        data-testid={`${id}_${type}_input`}
      />
    </div>
  );
};

export default CodeInput;
