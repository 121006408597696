import { FC } from "react";
import { useAppDispatch } from "store";
import { closeSettingsMenu } from "store/menu";
import { options } from "./constants";
import { MenuOptionButton } from "./types";

interface IProps {
  type: MenuOptionButton;
  clickHandler?: () => void;
  disabled?: boolean;
  className?: string;
}

const MenuItem: FC<IProps> = ({ type, clickHandler, disabled, className }) => {
  const dispatch = useAppDispatch();
  return (
    <button
      className={`flex flex-row justify-start items-center hover:bg-gray-100 transition-colors hover:disabled:bg-transparent hover:active:bg-transparent bg-white border-none cursor-pointer pt-2 pb-2 pr-1 pl-1 disabled:cursor-default disabled:opacity-40 ${className}`}
      onClick={() => {
        dispatch(closeSettingsMenu());
        clickHandler && clickHandler();
      }}
      data-testid={`${type}-button`}
      disabled={disabled || !clickHandler}
    >
      {options[type].icon}
      <p className="text-2xl pl-8">{options[type].text}</p>
    </button>
  );
};

export default MenuItem;
