import { FC } from "react";
import { StyledRow, StyledTaskList } from "./styles";
import TaskListRow from "./TaskListRow";
import CellTitle from "./CellTitle/CellTitle";
import { useBindScrollbars } from "./useBindScrollbars";
import { useListVirtualizer } from "./useListVirtualizer";
import { useAppSelector } from "store";
import {
  allTasksAsArraySelector,
  selectCurrentPlanSimulationResults,
  selectCurrentTaskId,
  selectTransientTaskData,
} from "store/tasks";
import { selectTaskCategoriesMap } from "store/taskCategory";
import TaskListWrapper from "./TaskListWrapper";
import { VirtualItem } from "react-virtual";
import { selectRole } from "store/auth";
import {
  isGuest as roleIsGuest,
  ITaskCategory,
  ITask,
} from "@ehabitation/ts-utils/browser";
import { MonochromeButton } from "@ehabitation/ui";

const TaskList: FC<{ handleOpenPlanImport?: () => void }> = ({
  handleOpenPlanImport,
}) => {
  const tasks = useAppSelector(allTasksAsArraySelector);
  useAppSelector(selectTransientTaskData);
  useBindScrollbars(".tasks", ".gantt-container");

  const currentPlanSimulationResults = useAppSelector(
    selectCurrentPlanSimulationResults
  );

  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  const { parentRef, totalSize, virtualItems } = useListVirtualizer(
    tasks?.length
  );

  const currentTaskId = useAppSelector(selectCurrentTaskId);
  const taskCategoryMap = Object.values(
    useAppSelector(selectTaskCategoriesMap)
  )?.reduce((acc, cat) => {
    const key = cat.name as keyof typeof acc;
    const existing = acc[key] as ITaskCategory;

    return existing?.level == "category"
      ? // If existing item in accumulator is a category and not a subcategory, leave as is, don't replace.
        acc
      : { ...acc, [cat.name]: cat };
  }, {}) as Record<string, ITaskCategory>;

  if (!tasks || !tasks.length) {
    return (
      <div className="w-full h-full grid place-content-center gap-4">
        <p className="text-center">Select a plan</p>
        {isGuest ? null : (
          <>
            <p className="text-center">or</p>
            <MonochromeButton
              level="secondary"
              onClick={() => handleOpenPlanImport && handleOpenPlanImport()}
              disabled={!handleOpenPlanImport}
            >
              Import a New Plan
            </MonochromeButton>
          </>
        )}
        {!handleOpenPlanImport ? (
          <p className="text-center">Building site model...</p>
        ) : null}
      </div>
    );
  } else {
    return (
      <StyledTaskList className="task-list">
        <div className="tasks" ref={parentRef}>
          <div className="tasks-virtual" style={{ height: totalSize }}>
            <TaskListWrapper key="header">
              <StyledRow isHeader role="button">
                <CellTitle title="task_code" />
                <CellTitle title="name" />
                <CellTitle title="start" />
                <CellTitle title="end" />
                <CellTitle title="confidence" />
              </StyledRow>
            </TaskListWrapper>
            {virtualItems.map((virtualRow: VirtualItem, i) => {
              if (i === 0) {
                return null;
              }

              const task = tasks[virtualRow.index - 1] as ITask;
              const selected = task.id === currentTaskId;
              const isWBS = !!task.WBS;
              const taskAccuracy = task?.accuracy;

              return (
                <TaskListWrapper key={task.id!} row={virtualRow}>
                  <TaskListRow
                    id={task!.id!}
                    task={task}
                    taskAccuracy={taskAccuracy}
                    taskBaseAccuracy={isWBS ? taskAccuracy : task.baseAccuracy}
                    isWBS={isWBS}
                    taskCategoryMap={taskCategoryMap}
                    selected={selected}
                    hasSimulation={!!currentPlanSimulationResults}
                  />
                </TaskListWrapper>
              );
            })}
          </div>
        </div>
      </StyledTaskList>
    );
  }
};

export default TaskList;
