import { FC, ReactNode } from "react";

import {
  IPlan,
  ISimulation,
  ISite,
  ITask
} from "@ehabitation/ts-utils/browser";
import { SimulationResult, SimulationTaskResult } from "helpers";
import {
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories
} from "hooks";

import { useComparisonImpactedTasksNotInBase, useProcessedTasks, useSimulation, useSimulationResult, useTaskDocs } from "Pages/sites/hooks";
 

export const ComparisonPlanLoader: FC<{
    site: ISite;
    basePlanId: string;
    comparisonPlan?: IPlan;
    allBaseTasksWithSimulationResults: (ITask & {
      simulation: SimulationTaskResult;
    })[];
    renderContent: ({
      simulation,
      simulationResult,
      simulationResultLoading,
      requestSimulation,
      comparisonResultsByBaseTaskId,
      comparisonTaskResultsNotInBase,
      allTasksWithSimulationResults,
      allTasks,
    }: {
      simulation?: ISimulation;
      simulationLoading?: boolean;
      simulationResult?: SimulationResult;
      simulationResultLoading: boolean;
      requestSimulation: () => void;
      comparisonResultsByBaseTaskId: {
        [id: string]: ITask & { simulation: SimulationTaskResult };
      };
      comparisonTaskResultsNotInBase: {
        [id: string]: ITask & { simulation: SimulationTaskResult };
      };
      allTasksWithSimulationResults:
        | (ITask & { simulation: SimulationTaskResult })[]
        | undefined;
      allTasks: { [id: string]: ITask & { simulation: SimulationTaskResult } };
    }) => ReactNode;
  }> = ({
    site,
    basePlanId,
    comparisonPlan,
    allBaseTasksWithSimulationResults,
    renderContent,
  }) => {
    const { simulation, simulationLoading, requestSimulation } = useSimulation(
      site.id,
      comparisonPlan?.id
    );
  
    const { simulationResult, simulationResultLoading } =
      useSimulationResult(simulation);
  
    const { taskDocs } = useTaskDocs(site.id, comparisonPlan?.id);
  
    const { riskMatrix } = usePlanRiskMatrix(comparisonPlan?.id);
  
    const { categories } = useRiskMatrixTopLevelCategories(riskMatrix?.id);
  
    const { tasks: allTasksWithSimulationResults, error: taskDocsError } =
      useProcessedTasks(site.id, categories, taskDocs, simulationResult);
  
    const {
      comparisonResultsByBaseTaskId,
      comparisonTaskResultsNotInBase,
      allTasks,
    } = useComparisonImpactedTasksNotInBase(
      allBaseTasksWithSimulationResults,
      allTasksWithSimulationResults,
      comparisonPlan?.id,
      basePlanId
    );
  
    return taskDocsError ? (
      <div>Error loading tasks and categories</div>
    ) : (
      <>
        {renderContent({
          simulation,
          simulationLoading,
          simulationResult,
          simulationResultLoading,
          requestSimulation,
          comparisonResultsByBaseTaskId,
          comparisonTaskResultsNotInBase,
          allTasksWithSimulationResults,
          allTasks,
        })}
      </>
    );
  };
  