import React, { FC } from "react";
import { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

interface PieChartProps {
  id: string;
  data: any[];
  categoryKey: string;
  noChartMessage: string;
  className?: string;
  settings?: any;
}

export const usePieChart = (
  data: any[],
  categoryKey: string,
  div: string,
  pieChartSettings: any
) => {
  const {
    fontSize = 15,
    maxWidth = 100,
    chartRadius = 80,
    toggleKey = true,
  } = pieChartSettings;

  am4core.options.autoDispose = true;
  useLayoutEffect(() => {
    if (data.length === 0) return;

    // Setup
    let chart = am4core.create(div, am4charts.PieChart);
    chart.data = data;
    chart.radius = am4core.percent(chartRadius);

    // Pie : Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = categoryKey;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.propertyFields.stroke = "color";
    pieSeries.slices.template.fillOpacity = 0.3;
    pieSeries.slices.template.togglable = toggleKey;

    // Pie : Animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    chart.hiddenState.properties.radius = am4core.percent(0);

    // Pie : Labels
    pieSeries.labels.template.fontSize = fontSize;
    pieSeries.labels.template.wrap = true;
    pieSeries.labels.template.maxWidth = maxWidth;
    pieSeries.labels.template.text = `{category} {value.percent.formatNumber('#.')}%`;

    // Pie : Tooltip
    pieSeries.tooltip!.disabled = true;
  }, [data]);

  return {
    canShowChart: data.length > 0,
  };
};

export const PieChart: FC<PieChartProps> = ({
  id,
  data,
  categoryKey,
  noChartMessage,
  className = "",
  settings = {},
}) => {
  const { canShowChart } = usePieChart(
    data,
    categoryKey,
    `statchart-container-${id}`,
    settings
  );
  return canShowChart ? (
    <div
      id={`statchart-container-${id}`}
      className={`w-full h-full ${className}`}
    />
  ) : (
    <div className="flex flex-col justify-center items-center w-full h-full p-4">
      <p>{noChartMessage}</p>
    </div>
  );
};
