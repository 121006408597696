//React
import React, { FC } from "react";
//Types
import { IFormError } from "types";
//Styles
import { StyledSelectBoxGroup } from "./styles";
//Hooks
import { useHandleErrorFocus } from "./useHandleErrorFocus";

interface IProps {
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  error: IFormError | null;
  options: {
    id: string;
    name: string;
  }[];
  type: string;
  value: string | undefined;
  disabledOption?: string;
  allowClear?: boolean;
}

const typeToTitle = {
  divisionId: "Division",
  projectMode: "Project Mode",
} as { [key: string]: string };

const SelectBoxGroup: FC<IProps> = ({
  changeHandler,
  disabled = false,
  error,
  options,
  type,
  value,
  disabledOption,
  allowClear = true,
}) => {
  const { selectRef } = useHandleErrorFocus(error, type);
  const title = typeToTitle[type] ?? type;
  return (
    <StyledSelectBoxGroup>
      <label htmlFor={type}>{title}</label>

      <select
        value={value}
        name={type}
        id={type}
        onChange={changeHandler}
        disabled={disabled}
        data-testid={`${type}-select`}
        ref={selectRef}
      >
        <option value="" disabled={!allowClear}>
          Select a {title}
        </option>
        {options.map((option) => (
          <option
            key={option.id}
            value={option.id}
            disabled={
              disabledOption === option.id || disabledOption === option.name
            }
          >
            {option.name}
          </option>
        ))}
      </select>

      <p
        className="error"
        data-testid={error?.field === type ? "error-message" : ""}
      >
        {error?.field === type ? error.message : ""}
      </p>
    </StyledSelectBoxGroup>
  );
};

export default SelectBoxGroup;
