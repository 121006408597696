import React, { FC, forwardRef } from "react";

interface IStat {
  title?: string;
  value: string;
  className?: string;
  valueClassName?: string;
}

export const StatText: FC<IStat> = forwardRef<HTMLDivElement, IStat>(
  ({ title, value, className = "", valueClassName = "" }, ref) => {
    return (
      <div className={`flex flex-col ${className}`} ref={ref}>
        {title && <span className="text-gray-500">{title}</span>}
        {value && (
          <span className={`text-gray-600 text-2xl ${valueClassName}`}>
            {value}
          </span>
        )}
      </div>
    );
  }
);
