//Helpers
import moment from "moment";
import { IWorkDay } from "types";

export const sortArrayOfISOStringASC = (array: string[]) => {
  return array.sort((a, b) => {
    const aTimestamp = moment(a).valueOf();
    const bTimestamp = moment(b).valueOf();

    if (aTimestamp < bTimestamp) return -1;
    if (aTimestamp > bTimestamp) return 1;
    return 0;
  });
};

export const sortArrayOfWorkDays = (array: IWorkDay[]) => {
  const referenceObject = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  return array.slice().sort((a, b) => {
    return referenceObject[a.day] - referenceObject[b.day];
  });
};
