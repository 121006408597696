import { FC, useEffect, useMemo, useState } from "react";
import { PlanImportPage, PlanImportProps } from "../types";
import { db } from "firebaseConfig";
import {
  CollectionType,
  IPlan,
  ISite,
  PlanStatus,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { useIsMounted } from "hooks";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { Input, LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";
import { discardPlan } from "helpers/firebase/plans";

const createFromXerMultiProject = async (
  xerProjectId: string,
  title: string,
  plan: IPlan,
  userId: string,
  site: ISite,
  setPage: (page: PlanImportPage) => void
) => {
  await addDoc(collection(db, CollectionType.Plans), {
    createdAt: new Date(),
    owner: userId,
    project: site?.project,
    site: site?.id,
    originalPlanName: plan?.originalPlanName,
    originalPlanFile: plan?.originalPlanFile,
    title: title,
    fileType: plan?.fileType,
    xerProject: xerProjectId,
    xerProjectList: plan?.xerProjectList,
    status: PlanStatus.staging,
    complete: false,
  });
  setPage(PlanImportPage.File);
};

export const PlanImportDraft: FC<PlanImportProps> = ({
  setPage,
  plan,
  site,
}) => {
  const isMounted = useIsMounted();
  const userId = useAppSelector(selectUserId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [childPlanName, setChildPlanName] = useState<string>("");
  const [childPlanNameError, setChildPlanNameError] = useState<string>("");
  const [multiProjectPlans, setMultiProjectPlans] = useState<IPlan[]>();
  const [selectedMultiProject, setSelectedMultiProject] = useState<string>(
    plan?.xerProjectList?.length === 1 ? plan?.xerProjectList[0].id : ""
  );

  const isMultiProject =
    plan?.xerProjectList && plan?.xerProjectList?.length > 1;

  useEffect(() => {
    console.info("Rendering plan id", plan?.id)
    if (plan?.xerProjectList && site?.id) {
      const multiPlansQuery = query(
        collection(db, CollectionType.Plans),
        where("owner", "==", userId),
        where("site", "==", site?.id),
        where("originalPlanFile", "==", plan.originalPlanFile)
      );
      const unsubscribe = onSnapshot(
        multiPlansQuery,
        (snapshot) => {
          const fetchedMultiPlanDocs = snapshot.docs;

          if (fetchedMultiPlanDocs.length) {
            const activePlans = fetchedMultiPlanDocs
              .map((doc) => transformPlanDoc(doc.id, doc.data()))
              .filter(
                (plan) =>
                  ![PlanStatus.error, PlanStatus.discarded].includes(
                    plan.status!
                  )
              );
            setMultiProjectPlans(activePlans);
          } else {
            setMultiProjectPlans(undefined);
          }
        },
        (error) => {
          console.error(error);
        }
      );
      return unsubscribe;
    }
  }, [plan?.id]);

  const parentMultiPlan = useMemo(() => {
    return (multiProjectPlans || []).find((plan) => !plan.xerProject);
  }, [multiProjectPlans, plan]);

  const existingPlansByMultiId = useMemo(() => {
    if (!multiProjectPlans?.length || !parentMultiPlan) return {};
    const projectPlans: Record<string, IPlan[]> = {};
    multiProjectPlans.forEach((plan) => {
      if (plan?.xerProject) {
        projectPlans[plan?.xerProject] = projectPlans[plan?.xerProject] || [];
        projectPlans[plan?.xerProject].push(plan);
      }
    });
    return projectPlans;
  }, [multiProjectPlans, parentMultiPlan]);

  useEffect(() => {
    if (isLoading) return;
    if (!plan) {
      setPage(PlanImportPage.File);
    } else {
      if (plan.xerProjectList?.length === 1 && !plan.xerProject) {
        setChildPlanName(plan.title);
        createMulti();
        return;
      }
      if (plan.xerProject) {
        setChildPlanName(plan.title);
      } else if (isMultiProject && parentMultiPlan && selectedMultiProject) {
        const selectedChildFilePlan = parentMultiPlan.xerProjectList?.find(
          ({ id }) => String(id) === String(selectedMultiProject)
        );
        if (selectedChildFilePlan) {
          setChildPlanName(
            parentMultiPlan.title + " - " + selectedChildFilePlan.name
          );
          setChildPlanNameError("");
        }
      }
    }
  }, [plan, parentMultiPlan, isMultiProject, selectedMultiProject, isLoading]);

  const discard = () => {
    if (plan?.id) {
      setIsLoading(true);
      const discardPromises = [];
      if (plan.xerProjectList?.length === 1 && parentMultiPlan) {
        discardPromises.push(discardPlan(parentMultiPlan.id));
      }
      discardPromises.push(discardPlan(plan?.id));
      Promise.all(discardPromises).finally(() => {
        isMounted() && setIsLoading(false);
      });
    }
  };

  const createMulti = async () => {
    if (!childPlanName.trim()) {
      setChildPlanNameError("Please enter a plan name");
      return;
    }
    setIsLoading(true);
    await createFromXerMultiProject(
      selectedMultiProject!,
      childPlanName.trim(),
      plan!,
      userId!,
      site!,
      setPage
    );
    if (parentMultiPlan?.xerProjectList?.length === 1) {
      discardPlan(parentMultiPlan?.id);
    }
    isMounted() && setIsLoading(false);
  };

  return (
    <div className="w-[56rem] px-2">
      <LoadingWrapper loading={isLoading || !plan}>
        {plan ? (
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-6">
              <ul className="bg-slate-200 p-4 w-full">
                <li>
                  <strong>Site:</strong> {site?.name}
                </li>
                <li>
                  <strong>Name:</strong>{" "}
                  {(parentMultiPlan ? parentMultiPlan : plan).title}
                </li>
                <li>
                  <strong>File Name:</strong> {plan.originalPlanName}
                </li>
                {parentMultiPlan ? (
                  <li>
                    <strong>Available Projects:</strong>{" "}
                    {parentMultiPlan.xerProjectList?.length}
                  </li>
                ) : null}
                <li>
                  <strong>Uploaded:</strong>{" "}
                  {(parentMultiPlan
                    ? parentMultiPlan
                    : plan
                  ).createdAt?.toLocaleString()}
                </li>
              </ul>
              <div className="flex gap-6 justify-between w-full">
                <MonochromeButton
                  className="self-end order-1"
                  level="secondary"
                  onClick={discard}
                  disabled={isLoading || (isMultiProject && !!plan.xerProject)}
                  width="md"
                  name="discard"
                  data-testid="draft-slide-discard-button"
                >
                  Discard {isMultiProject ? "File" : ""}
                </MonochromeButton>
                {isMultiProject ? null : (
                  <MonochromeButton
                    className="self-end order-2"
                    level="primary"
                    onClick={() =>
                      setPage(
                        plan.status === PlanStatus.categorised
                          ? PlanImportPage.CategorySelection
                          : PlanImportPage.File
                      )
                    }
                    width="md"
                    name="continue"
                    data-testid="draft-slide-continue-button"
                  >
                    Resume
                  </MonochromeButton>
                )}
              </div>
            </div>
            {isMultiProject ? (
              <div className="flex flex-col gap-6">
                <div className="pl-2 w-full flex flex-col gap-4">
                  {plan.xerProject ? (
                    <strong>
                      In-progress plan created{" "}
                      {plan.createdAt?.toLocaleDateString()},
                      {plan.createdAt?.toLocaleTimeString()}
                    </strong>
                  ) : (
                    <strong>
                      Select a file project to create an EHAB site plan from.
                    </strong>
                  )}
                  <div className="self-stretch flex gap-4 items-center">
                    <label htmlFor="plan-name-input">File Project</label>
                    <select
                      id="child-project-select"
                      value={selectedMultiProject}
                      disabled={!!plan.xerProject}
                      onChange={(event) =>
                        setSelectedMultiProject(event.target.value)
                      }
                      className="flex-grow max-w-lg focus:border-indigo-500 focus:outline-none focus:ring-indigo-50 bg-[url('/src/assets/icons/chevron.svg')] bg-[right_-20px_top_50%]
                bg-no-repeat bg-[9rem_auto,100%] border border-[#444444] border-solid
                py-2 pl-6 pr-16 appearance-none disabled:bg-gray-100"
                    >
                      <option value="" disabled>
                        Select a project
                      </option>
                      {(plan.xerProjectList || [])
                        .filter(({ id }) => !existingPlansByMultiId[id])
                        .map((project) => (
                          <option value={project.id} key={project.id}>
                            {project.name}
                          </option>
                        ))}
                      {Object.keys(existingPlansByMultiId).length ? (
                        <optgroup label="Has Existing Plan">
                          {(plan.xerProjectList || [])
                            .filter(({ id }) => existingPlansByMultiId[id])
                            .map((project) => (
                              <option value={project.id} key={project.id}>
                                ({existingPlansByMultiId[project.id].length}) -{" "}
                                {project.name}
                              </option>
                            ))}
                        </optgroup>
                      ) : null}
                    </select>
                  </div>
                  <div className="self-stretch flex gap-4 items-center">
                    <label htmlFor="plan-name-input">Plan Name</label>
                    <Input
                      type="text"
                      id="plan-name-input"
                      paddingSize="small"
                      aria-label="Plan Name"
                      value={childPlanName}
                      disabled={!!plan.xerProject}
                      className="p-2 pl-6 flex-grow max-w-lg rounded-none"
                      onChange={(event) => {
                        setChildPlanName(event.target.value);
                        setChildPlanNameError("");
                      }}
                    />
                  </div>
                  <p className="ml-2 text-red-600">{childPlanNameError}</p>
                </div>
                <div className="flex gap-6 justify-between w-full">
                  {plan.xerProject ? (
                    <>
                      <MonochromeButton
                        level="secondary"
                        onClick={discard}
                        disabled={isLoading || !plan.xerProject}
                        width="md"
                        name="discard"
                        data-testid="draft-slide-discard-button"
                      >
                        Discard Plan
                      </MonochromeButton>
                      <MonochromeButton
                        level="primary"
                        onClick={() =>
                          setPage(
                            plan.status === PlanStatus.categorised
                              ? PlanImportPage.CategorySelection
                              : PlanImportPage.File
                          )
                        }
                        width="md"
                        name="continue"
                        data-testid="draft-slide-continue-button"
                      >
                        Resume
                      </MonochromeButton>
                    </>
                  ) : (
                    <>
                      <div />
                      <MonochromeButton
                        level="primary"
                        onClick={createMulti}
                        disabled={isLoading || !selectedMultiProject}
                        width="md"
                        name="create"
                        data-testid="draft-slide-continue-button"
                      >
                        Create Plan
                      </MonochromeButton>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </LoadingWrapper>
    </div>
  );
};
