import styled from "styled-components";
import {
  headerHeight,
  flex,
  grays,
  spacing,
  fontSize,
  font,
  colors,
} from "styles";

export const StyledContentForm = styled.form`
  ${flex("column", "flex-start", "flex-start")};
  height: calc(100vh - ${headerHeight} - 9rem);
  overflow-y: auto;
  width: 100%;
  position: relative;

  > h2 {
    text-transform: capitalize;
    width: 100%;
    padding-bottom: ${spacing.l};
  }

  .error {
    color: ${colors.major};
    font-size: ${fontSize.s};
    height: ${fontSize.s};
  }

  .user-details {
    ${flex("row", "center", "flex-start")}
    width: 100%;

    .left {
      width: 40%;
    }

    .right {
      width: 60%;
    }

    .left {
      padding-right: ${spacing.l};
    }

    .right {
      padding-right: ${spacing.l};

      h3 {
        ${font("body")};
        font-size: ${fontSize.s};
        font-weight: 300;
      }
    }
  }

  .button-container {
    align-self: flex-end;
    padding: ${spacing.l};
  }
`;

export const StyledProjectAndSiteSelector = styled.div`
  border: 0.1rem solid ${grays.dark};
  padding: ${spacing.m};

  .project-group {
    margin-bottom: ${spacing.l};

    .checkbox-group {
      input {
        margin-right: ${spacing.m};
        padding: ${spacing.s};
      }
    }

    .site {
      margin-left: 3rem;
    }
  }
`;
