import styled from "styled-components";
import { flex, fontSize, spacing } from "styles";

export const StyledDownloadSlide = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  > div {
    padding: 0;

    @media (min-width: 900px) {
      padding: ${spacing.l};
    }
  }

  .image-container {
    ${flex("column", "center", "center")};
    height: 100%;
    overflow: hidden;

    > img {
      width: 100%;
    }
  }

  .copy {
    ${flex("column", "center", "flex-start")};

    p {
      font-weight: 300;
      margin-bottom: ${spacing.m};
      font-size: ${fontSize.xs};

      @media (min-width: 900px) {
        font-size: ${fontSize.s};
      }
    }

    h2 {
      margin-bottom: ${spacing.m};
      font-size: ${fontSize.l};

      @media (min-width: 900px) {
        font-size: ${fontSize.xl};
      }
    }

    button {
      align-self: flex-end;
      margin-top: ${spacing.m};
    }
  }
`;
