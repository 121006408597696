import styled from "styled-components";
import { fontSize, grays, flex, colors, spacing, highlightHover } from "styles";

export const StyledDescriptionInput = styled.div`
  ${flex("row", "space-between", "flex-start")};
  width: 100%;
  height: 17rem;
  padding-top: ${spacing.l};

  textarea {
    cursor: pointer;
    border: none;
    outline: none;
    resize: none;
    width: 90%;
    height: 100%;
    font-size: ${fontSize.s};
    color: ${grays.medium};
    font-weight: 300;

    &:disabled {
      background: transparent;
      cursor: auto;
    }
  }

  .icon-container {
    ${flex("row", "flex-end", "center")};

    .icon {
      font-size: 2.4rem;
      cursor: pointer;
      border-radius: 1rem;

      :active {
        background-color: #dedede;
      }
    }

    button {
      ${highlightHover()}
      outline: none;
      background-color: white;
      border: none;
      &:disabled {
        cursor: default;
      }
    }

    .edit {
      color: ${grays.dark};
    }

    .save {
      color: ${colors.safe};
    }
  }
`;
