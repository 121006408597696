import { FC } from "react";
import { MonochromeButton } from "@ehabitation/ui";
import { AiOutlineDownload } from "react-icons/ai";

export const DownloadButton: FC<{
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
  description?: string;
  disabled?: boolean;
}> = ({
  label,
  description,
  onClick,
  disabled = false,
  icon = <AiOutlineDownload className="inline" />,
}) => {
  return (
    <div className="px-4">
      <div className="flex flex-col md:flex-row gap-8 justify-around pt-2">
        <div className="flex flex-col gap-2 items-left flex-grow max-w-md">
          <MonochromeButton
            className={`self-stretch ${disabled ? 'bg-gray-300' : ''}`} // Change background color when disabled
            level="secondary"
            onClick={() => onClick()}
            disabled={disabled}
          >
            {icon}
            {label}
          </MonochromeButton>
          {description ? (
            <p className="text-xl text-left">{description}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};