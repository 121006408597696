import { useEffect } from "react";
import {
  selectDivisionManagementIsLoading,
  setSelectedDivision,
} from "store/divisionManagement";
import { useAppDispatch, useAppSelector } from "store";
import { StyledAdminButtonContainer, StyledAdminContent } from "elements";
import { useDivisionManagementTab } from "../useDivisionManagementTab";
import DivisionsHeader from "./DivisionsHeader";
import DivisionsList from "./DivisionsList";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";

const Divisions = () => {
  const { goToNewTab } = useDivisionManagementTab();

  const loading = !!useAppSelector(selectDivisionManagementIsLoading);
  const dispatch = useAppDispatch();

  /* Clear the selected user when entering or returning to this tab */
  useEffect(() => {
    dispatch(setSelectedDivision(null));
  }, [dispatch]);

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        {/* SCROLLS */}
        <div className="content-container">
          <StyledAdminButtonContainer>
            <MonochromeButton
              level="primary"
              width="lg"
              onClick={goToNewTab}
              data-testid="division-add"
            >
              Add A New Division
            </MonochromeButton>
          </StyledAdminButtonContainer>
          <DivisionsHeader />
          <DivisionsList />
        </div>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default Divisions;
