import React, { FC } from "react";

import { Disclosure } from "@headlessui/react";
import { BiCaretRight } from "react-icons/bi";

interface IAccordion {
  title?: string | ((props: { open: boolean }) => JSX.Element);
  children: JSX.Element;
  headerComponent?: React.ElementType<any>;
  headerClass?: string;
  panelClass?: string;
  defaultOpen?: boolean;
  disabled?: boolean;
  id?: string;
}

export const Accordion: FC<IAccordion> = ({
  title = null,
  children,
  headerComponent,
  headerClass = "flex gap-4 p-2 items-center",
  panelClass = "",
  defaultOpen = false,
  disabled,
  id,
}) => {
  return (
    <Disclosure as="div" defaultOpen={defaultOpen} id={id}>
      <Disclosure.Button
        as={headerComponent}
        className={headerClass}
        disabled={disabled}
      >
        {({ open }) => (
          <>
            {disabled ? (
              <span className="w-2" />
            ) : (
              <BiCaretRight
                className={`transition-transform ${
                  open ? "rotate-90" : "rotate-0"
                }`}
              />
            )}
            {title && (typeof title === "string" ? title : title({ open }))}
          </>
        )}
      </Disclosure.Button>
      <Disclosure.Panel className={panelClass}>{children}</Disclosure.Panel>
    </Disclosure>
  );
};
