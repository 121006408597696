import { FC } from "react";
import { FiAlertTriangle } from "react-icons/fi";

const tooltipClasses = (direction: "right" | "left") => `
  relative before:absolute before:shadow-lg before:font-normal before:bg-gray-300
  before:z-50 before:content-tip before:${direction}-0 before:top-[125%] before:border
  before:border-gray-600 before:mb-2 before:bg-white before:rounded-md before:px-2
  hover:before:flex before:hidden
`;

export const Button: FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isCompact?: boolean;
    "data-testid"?: string;
    alert?: string;
    alertDirection?: "right" | "left";
    parentClassName?: string;
  }
> = ({
  alert = "",
  alertDirection = "right",
  className = "",
  color = "black",
  isCompact = false,
  "data-testid": dataTestId,
  parentClassName = "",
  ...inputProps
}) => {
  const isAlerted = !!alert;
  return (
    <div className={parentClassName}>
      <p
        className={`text-gray-500 inline-flex align-middle items-center ${
          isAlerted ? tooltipClasses(alertDirection) : ""
        } whitespace-pre`}
        data-tip={alert}
      >
        {isAlerted && (
          <FiAlertTriangle size="24" className="text-red-400 mx-4" />
        )}
        <button
          className={`
          cursor-pointer
          ${color == "black" ? "bg-black" : "bg-white"}
          ${color == "black" ? "text-white" : "text-black"}
          ${isAlerted ? " border-4 border-red-600" : "border-none"}
          rounded-full
          ${isCompact ? "px-8 py-1" : "px-8 py-4"}
          disabled:opacity-40
          disabled:cursor-default
          ${
            color == "black"
              ? "outline-none"
              : "outline outline-black outline-2"
          }
          focus:shadow-[0_0_0.2rem_0.1rem_#13cd89]
          ${className}
        `}
          data-testid={dataTestId}
          {...inputProps}
        />
      </p>
    </div>
  );
};
