import { FC } from "react";
import { StyledProgressBar } from "./styles";

const ProgressBar: FC<{ hidden: boolean; total: number; complete: number }> = ({
  hidden,
  total,
  complete,
}) => {
  let percentageComplete = Math.floor((complete / total) * 100);

  if (complete > 0 && complete < total) {
    if (percentageComplete < 20) percentageComplete = 20;
    if (percentageComplete > 80) percentageComplete = 80;
  }

  return (
    <StyledProgressBar
      percentageComplete={percentageComplete}
      className={` text-gray-600 ${hidden ? "invisible" : ""}`}
    >
      <div className="completed">
        <p data-testid="tasks-assigned">
          {complete}
          {percentageComplete > 19 ? " categorised" : ""}
        </p>
      </div>

      <div className="remaining">
        <p data-testid="tasks-remaining">
          {total - complete}
          {percentageComplete < 81 ? " uncategorised" : ""}
        </p>
      </div>
    </StyledProgressBar>
  );
};

export default ProgressBar;
