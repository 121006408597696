import { generatePopupMarkup } from "./helpers";

export default class Popup {
  constructor(parent, custom_html) {
    this.parent = parent;
    this.custom_html = custom_html;
    this.make();
  }

  make() {
    this.parent.innerHTML = `
            <div class="title"></div>
            <div class="subtitle"></div>
            <div class="pointer"></div>
        `;

    this.hide();

    this.title = this.parent.querySelector(".title");
    this.subtitle = this.parent.querySelector(".subtitle");
    this.pointer = this.parent.querySelector(".pointer");
  }

  show(options) {
    if (!options.target_element) {
      throw new Error("target_element is required to show popup");
    }
    if (!options.position) {
      options.position = "left";
    }
    const target_element = options.target_element;

    if (this.custom_html) {
      let html = this.custom_html(options.task);
      html += '<div class="pointer"></div>';
      this.parent.innerHTML = html;
      this.pointer = this.parent.querySelector(".pointer");
    } else {
      // set data
      this.title.innerHTML = options.title;
      this.subtitle.innerHTML = options.subtitle;
      this.parent.style.width = this.parent.clientWidth + "px";
    }

    // set position
    const position_meta = target_element.getBoundingClientRect();
    if (options.position === "left") {
      this.parent.style.left =
        position_meta.x + (position_meta.width + 10) + "px";
      this.parent.style.top = position_meta.y + "px";

      this.pointer.style.transform = "rotateZ(90deg)";
      this.pointer.style.left = "-7px";
      this.pointer.style.top = "2px";
    }

    // show
    this.parent.style.opacity = 1;
  }

  //Shows a popup for the task bars
  show_detailed(options) {
    if (!options.target_element)
      throw new Error("target_element is required to show popup");
    if (options.task.WBS) return; //Do not show for WBS

    // Set Markup
    const { popupTitle, popupBody } = generatePopupMarkup(options.task);
    this.title.innerHTML = popupTitle;
    this.subtitle.innerHTML = popupBody; //Subtitle is not ideal nomenclature, but I am injecting html here to use existing structures

    // Set Position
    const target_element = options.target_element;
    const position_meta = target_element.getBoundingClientRect();

    this.parent.style.left = options.x + 2 + "px"; // x position of the mouse
    this.parent.style.top = position_meta.bottom + 10 + "px"; //Below the bar

    this.pointer.style.transform = "rotateZ(180deg)";
    this.pointer.style.left = "5px";
    this.pointer.style.top = "-13px";

    // Show
    this.parent.style.width = "40rem";
    this.parent.style.visibility = "visible";
  }

  hide() {
    this.parent.style.visibility = "hidden";
    this.parent.style.width = "0rem";
  }
}
