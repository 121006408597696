//React
import React, { FC } from "react";
//Styles
import { StyledCustomAlert } from "./styles";

interface IProps {
  message: string;
  handleStayOnPage: () => void;
  handleLeavePage: () => void;
  handleSave: () => void | Promise<void>;
  saveButtonText?: string;
  dontSaveButtonText?: string;
}

const StayOnPageAlert: FC<IProps> = ({
  message,
  handleLeavePage,
  handleSave,
  handleStayOnPage,
  saveButtonText = "Save",
  dontSaveButtonText = "Don't Save",
}) => {
  return (
    <StyledCustomAlert>
      <div className="backdrop" onClick={handleStayOnPage} />

      <div className="content">
        <p className="message">{message}</p>

        <div className="buttons">
          {saveButtonText ? (
            <button onClick={handleSave}>{saveButtonText}</button>
          ) : (
            <></>
          )}
          <button onClick={handleLeavePage}>{dontSaveButtonText}</button>
        </div>
      </div>
    </StyledCustomAlert>
  );
};

export default StayOnPageAlert;
