//React
import React from "react";
//Components
import Overview from "./Overview/Overview";
import RiskProfiles from "./RiskProfiles/RiskProfiles";
//Styles
import { StyledImpact } from "./styles";

const Impact = () => {
  return (
    <StyledImpact>
      <Overview />
      <RiskProfiles />
    </StyledImpact>
  );
};

export default Impact;
