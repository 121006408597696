import { FC } from "react";

interface MonochromeButtonProps extends React.HTMLProps<HTMLButtonElement> {
  level: "primary" | "secondary" | "tertiary";
  width?: "md" | "lg";
  type?: "button" | "submit" | "reset";
}

export const MonochromeButton: FC<MonochromeButtonProps> = ({
  children,
  level,
  onClick,
  disabled,
  width,
  className = "",
  type,
  ...props
}) => {
  const innerClassName = `
    h-12 px-8 py-[0.6rem]
    flex justify-center items-center
    rounded-full
    focus:outline-none focus:ring-2 ring-offset-1 ring-[#13cd89]
    text-s
    ${level === "primary" ? "bg-black text-white border border-black" : ""}
    ${level === "secondary" ? "bg-white text-black border border-black" : ""}
    ${level === "tertiary" ? "" : ""}
    ${disabled ? "opacity-40 " : "cursor-pointer hover:brightness-90"}
    ${width === "md" ? "w-[15rem]" : ""}
    ${width === "lg" ? "w-[20rem]" : ""}
    ${className}
  `;

  return (
    <button
      className={innerClassName}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};
