//Types
import { AppThunk } from "store";
//Store
import {
  setDivisionManagementError,
  setDivisionManagementIsLoading,
} from "store/divisionManagement";
import { batch } from "react-redux";
//Helpers
import { deleteDivision } from "./helpers";

export const deleteDivisionById: AppThunk =
  (divisionId: string | undefined) => async (dispatch) => {
    dispatch(setDivisionManagementIsLoading(true));

    try {
      if (divisionId) await deleteDivision(divisionId);
      dispatch(setDivisionManagementIsLoading(false));
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setDivisionManagementError(error.message)); //shows a toast to the user
        dispatch(setDivisionManagementIsLoading(false));
      });
    }
  };
