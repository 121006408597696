import styled from "styled-components";
import { grays, spacing, flex, fontSize, font } from "styles";

export const StyledHeading = styled.div`
  ${flex("column", "flex-start", "flex-start")}
  width: 100%;
  border-top: 0.1rem solid ${grays.light};
  margin-bottom: ${spacing.m};

  .text {
    ${font("body")};
    font-size: ${fontSize.xs};
    text-transform: uppercase;
    background: ${grays.veryLight};
    padding: 0.3rem ${spacing.s};
    font-weight: 500;

    @media (min-width: 1200px) {
      font-size: ${fontSize.s};
    }
  }
`;
