import { FC, useEffect } from "react";
import { BiPencil } from "react-icons/bi";
import Autocomplete from "react-google-autocomplete";

import { Location, MonochromeButton } from "@ehabitation/ui";
import { useAppSelector } from "store";
import config from "config";
import {
  selectDivisionOptions,
  selectProjectOptions,
  selectSiteManagementLoading,
} from "store/siteManagement";
import { RootState } from "store";
import SelectBoxGroup from "Components/Inputs/SelectBoxGroup";
import TextInputGroup from "Components/Inputs/TextInputGroup";
import DatePickerGroup from "Components/Inputs/DatePickerGroup";
import { StyledForm } from "./styles";
import { StyledAdminContent } from "elements";
import { useNewSiteForm } from "./useNewSiteForm";
import { StyledTextInputGroup } from "Components/Inputs/styles";
import { getStaticMapUrl, usePlace } from "helpers/location";
import { roundTo6DP } from "helpers";

const LocationSearch: FC<{
  location: { latitude: number; longitude: number };
  setLatLong: Function;
  address?: string;
  setAddress: (address: string) => any;
}> = ({
  location: { latitude, longitude },
  setLatLong,
  address,
  setAddress,
}) => {
  return latitude && longitude ? (
    <div className="flex">
      <Location
        address={address}
        latitude={Number(latitude)}
        longitude={Number(longitude)}
      />
      <button
        onClick={() => {
          setAddress("");
          setLatLong({
            lat: "",
            long: "",
          });
        }}
        className="text-4xl px-1 ml-2 transition-colors hover:bg-gray-200 rounded-lg"
      >
        <BiPencil className="text-gray-500" />
      </button>
    </div>
  ) : (
    <Autocomplete
      id="location"
      placeholder="Search for a location..."
      apiKey={config.API_KEY as string}
      inputAutocompleteValue="off"
      options={{
        types: ["geocode"],
        bounds: { north: 54.09, south: 54.09, east: -2.89, west: -2.89 }, // Bias results to UK
      }}
      onPlaceSelected={(place: any) => {
        setAddress(place.formatted_address);
        setLatLong({
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        });
      }}
      onChange={() => {
        setAddress("");
        setLatLong({ lat: "", long: "" });
      }}
      style={{ width: "100%" }}
      disabled={!!address}
    />
  );
};

const NewSite = () => {
  const {
    role,
    error,
    newSite,
    handleUpdateDates,
    handleUpdateForm,
    handleUpdateAddress,
    handleSubmit,
    setLatLong,
  } = useNewSiteForm();

  const isUpdate = !!newSite.id;

  const { lat: numberLat, long: numberLong } = {
    lat: roundTo6DP(newSite.lat),
    long: roundTo6DP(newSite.long),
  };

  const { place: latLongPlace } = usePlace(newSite.lat, newSite.long);

  useEffect(() => {
    if (!newSite.address && latLongPlace) {
      const postcode = latLongPlace.address_components.find(
        ({ types }: { types: string[] }) => {
          return types.includes("postal_code") && types.length === 1;
        }
      );
      handleUpdateAddress(
        latLongPlace.formatted_address || postcode?.long_name
      );
    }
  }, [latLongPlace]);

  const isLoading = useAppSelector(selectSiteManagementLoading);

  const divisionOptions = useAppSelector(selectDivisionOptions);
  const projectOptions = useAppSelector((state: RootState) =>
    selectProjectOptions(state, newSite.division)
  );

  return (
    <StyledAdminContent>
      <h2 data-testid="new-site-details">Site Details</h2>

      <StyledForm onSubmit={handleSubmit}>
        <TextInputGroup
          type="name"
          changeHandler={handleUpdateForm}
          value={newSite.name}
          inputType="text"
          error={error}
        />

        <div className="project-division">
          <SelectBoxGroup
            type="division"
            changeHandler={handleUpdateForm}
            value={newSite.division}
            options={divisionOptions}
            error={error}
            disabled={role !== "orgAdmin" || isUpdate}
          />

          <SelectBoxGroup
            type="project"
            changeHandler={handleUpdateForm}
            value={newSite.project}
            options={projectOptions}
            disabled={
              !newSite.division ||
              projectOptions.length === 0 ||
              role === "projectAdmin" ||
              isUpdate
            }
            error={error}
          />
        </div>
        <StyledTextInputGroup>
          <label htmlFor="location">Location</label>
          {newSite.id ? (
            <Location
              latitude={numberLat}
              longitude={numberLong}
              address={newSite.address}
            />
          ) : (
            <LocationSearch
              setLatLong={setLatLong}
              location={{
                latitude: numberLat,
                longitude: numberLong,
              }}
              address={newSite.address}
              setAddress={handleUpdateAddress}
            />
          )}
        </StyledTextInputGroup>

        <div
          className="lat-long aspect-[21/9] bg-cover bg-slate-300 p-2 flex content-start mt-2 mb-6"
          style={
            newSite.lat && newSite.long
              ? {
                  backgroundImage: `url(${getStaticMapUrl(
                    numberLat,
                    numberLong
                  )})`,
                }
              : {}
          }
        >
          <TextInputGroup
            type="lat"
            changeHandler={(e: any) => {
              handleUpdateAddress("");
              handleUpdateForm(e);
            }}
            labelClassNames="bg-slate-300 px-2"
            value={newSite.lat || ""}
            inputType="text"
            error={error}
            disabled={isUpdate}
          />

          <TextInputGroup
            type="long"
            changeHandler={(e: any) => {
              handleUpdateAddress("");
              handleUpdateForm(e);
            }}
            value={newSite.long || ""}
            labelClassNames="bg-slate-300 px-2"
            inputType="text"
            error={error}
            disabled={isUpdate}
          />
        </div>

        <div className="start-end">
          <DatePickerGroup
            type="startDate"
            changeHandler={handleUpdateDates}
            value={newSite.startDate}
            error={error}
          />

          <DatePickerGroup
            type="endDate"
            changeHandler={handleUpdateDates}
            value={newSite.endDate}
            error={error}
          />
        </div>

        <TextInputGroup
          type="description"
          changeHandler={handleUpdateForm}
          value={newSite.description}
          inputType="textarea"
          error={error}
        />

        <TextInputGroup
          label="site code"
          type="siteCode"
          changeHandler={handleUpdateForm}
          value={newSite.siteCode}
          inputType="text"
          error={error}
        />

        <SelectBoxGroup
          type="visibility"
          changeHandler={handleUpdateForm}
          value={newSite.visibility}
          options={[
            { id: "planner", name: "Planner" },
            { id: "guest", name: "Guest" },
          ]}
          error={error}
          allowClear={false}
        />

        <div className="button-container">
          <MonochromeButton
            level="primary"
            width="lg"
            type="submit"
            disabled={isLoading}
          >
            Submit
          </MonochromeButton>
        </div>
      </StyledForm>
    </StyledAdminContent>
  );
};

export default NewSite;
