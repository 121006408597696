import { FC, ReactNode } from "react";

import {
  IPlan,
  ISite
} from "@ehabitation/ts-utils/browser";
import {
  useIsFreeUser
} from "hooks";
import { NoPlanSection } from "Pages/sites/plan/PlanView";


export const PlanRequiredWrapper: FC<{
    site?: ISite;
    plan?: IPlan;
    renderContent: ({ plan }: { plan: IPlan }) => ReactNode;
  }> = ({ site, plan, renderContent }) => {
    const isFreeUser = useIsFreeUser();
  
    const result = site?.project ? (
      plan?.id ? (
        <>{renderContent({ plan })}</>
      ) : (
        <NoPlanSection site={site} />
      )
    ) : (
      <div className="h-[20rem] w-full grid place-content-center">
        <p className="text-center">
          {isFreeUser ? (
            <>
              Uploading a plan and aligning our insights with your planned
              activities is a premium feature. If you want to find out about these
              benefits and more then{" "}
              <a
                className="ntext-blue-500 underline"
                href="https://ehab.co/book-a-demo/?hsCtaTracking=4a64d733-eb03-4223-a247-76ab30bd01f0%7C1f4fb64f-0386-4b2e-b774-fc8f89d65769"
                target="_blak"
                rel="noreferrer"
              >
                follow this link to book a demo call
              </a>
              .
            </>
          ) : (
            <>Forecast only sites do not support plans & mitigations.</>
          )}
        </p>
      </div>
    );
    return result;
  };