//React
import React from "react";
//Material UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Store
import { useAppDispatch, useAppSelector } from "store";
import {
  setDivisionManagementError,
  selectDivisionManagementError,
} from "store/divisionManagement";

const DivisionManagementToast = () => {
  const dispatch = useAppDispatch();

  const error = useAppSelector(selectDivisionManagementError);

  const handleClose = () => {
    dispatch(setDivisionManagementError(""));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={5000}
      onClose={handleClose}
      open={!!error}
    >
      <Alert severity="error" onClose={handleClose}>
        {error}
      </Alert>
    </Snackbar>
  );
};

export default DivisionManagementToast;
