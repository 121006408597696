import styled from "styled-components";
import { flex, grays } from "styles";
import chevronSvgPath from "assets/icons/chevron.svg";

export const StyledPlanDisplayInfo = styled.div`
  ${flex("row", "space-between")};
  position: relative;
  white-space: nowrap;
  min-width: 20rem;
  flex: 1;
  background-color: white;
  background-image: url(${chevronSvgPath});
  background-position: right -20px top 50%;
  background-repeat: no-repeat, repeat;
  background-size: 9rem auto, 100%;
  border-radius: 20px;
  border: 0.1rem solid #444444;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1.5rem;
  padding-right: 4.5rem;
  max-width: 30rem;
  cursor: default;
`;

export const StyledPlanConfiguratorPanel = styled.div<{ isShort: boolean }>`
  position: absolute;
  z-index: 30;
  top: 4.5rem;
  right: 0;
  width: 484px;
  max-height: ${({ isShort }) => (isShort ? "30rem" : "calc(100vh - 20rem)")};
  border-radius: 20px;
  border: 0.1rem solid #444444;
  background-color: white;
  ${flex("column")};
  padding-top: 2rem;
  overflow: auto;
  gap: 1rem;
`;

export const StyledMainPlanContainer = styled.div`
  ${flex("column")};
  width: 100%;
  flex: 1;
  color: black;
  text-align: left;

  h3 {
    width: 50%;
    border-bottom: 1px solid #ececec;
    margin-left: 3rem;
  }
`;

export const StyledPlanInfo = styled.div<{
  isSelected?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  ${flex("row", "space-between")};
  width: 100%;
  cursor: pointer;
  gap: 20px;
  margin-top: 1rem;
  padding: 1rem;
  padding-left: 4rem;
  color: black;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: bold;
  }

  small {
    margin-top: 5px;
    font-style: light;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;

    color: #6d6e70;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${grays.veryLight};
  `}
  ${({ isSelected, disabled }) =>
    (isSelected || disabled) &&
    `
    cursor: default;
  `}

  &:hover {
    ${({ isSelected, disabled }) =>
      !isSelected &&
      !disabled &&
      `
    background-color: ${grays.veryLight};
  `}
  }
`;

export const StyledDraftsContainer = styled.div`
  ${flex("column")};
  width: 100%;
  flex: 1;
  text-align: left;

  h3 {
    width: 50%;
    border-bottom: 1px solid #ececec;
    margin-left: 3rem;

    font-family: "IBM Plex Sans", sans-serif;
  }
`;

export const StyledPlanTitle = styled.div`
  font-weight: bold;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;
