import React from "react";
import { useAppSelector } from "store";
import { selectDivisionOptions } from "store/siteManagement";
import { selectProjectManagementIsLoading } from "store/projectManagement";
import { StyledAdminContent } from "elements";
import { StyledContentWrapper, StyledForm } from "./styles";
import SelectBoxGroup from "Components/Inputs/SelectBoxGroup";
import TextInputGroup from "Components/Inputs/TextInputGroup";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { useNewProjectForm } from "./useNewProjectForm";
import {
  isGuest,
  greaterThanEqualRole,
  greaterThanRole,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { visibilityOptions } from "Components/DivisionManagement/constants";
import { selectRole } from "store/auth";
import { ProjectMode } from "types";

export const projectModeOptions = [
  { id: ProjectMode.SingleSite, name: "Single Site Plans" },
  {
    id: ProjectMode.MultiSiteWithActivityCodes,
    name: "Multi-Site Plans using Activity Codes",
  },
];

const ProjectForm = () => {
  const divisionOptions = useAppSelector(selectDivisionOptions);
  const loading = !!useAppSelector(selectProjectManagementIsLoading);
  const callerRole = useAppSelector(selectRole);

  const {
    isNewProject,
    role,
    error,
    handleSubmit,
    handleUpdateForm,
    newProject,
  } = useNewProjectForm();
  const {
    projectCode,
    name,
    divisionId,
    description,
    projectMode,
    siteMappingActivityCodeGroup,
    visibility,
  } = newProject;

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        <StyledContentWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <h2>
              {isNewProject
                ? "New Project Details"
                : `Edit Project ${
                    projectCode ? `[${projectCode}] ` : ""
                  }${name}`}
            </h2>

            <TextInputGroup
              type="name"
              changeHandler={handleUpdateForm}
              value={name}
              inputType="text"
              error={error}
            />

            {isNewProject && (
              <SelectBoxGroup
                type="divisionId"
                changeHandler={handleUpdateForm}
                value={divisionId}
                options={divisionOptions}
                error={error}
                disabled={role === "divisionAdmin"}
              />
            )}

            <TextInputGroup
              type="description"
              changeHandler={handleUpdateForm}
              value={description}
              inputType="textarea"
              error={error}
            />

            <TextInputGroup
              label="project code"
              type="projectCode"
              changeHandler={handleUpdateForm}
              value={projectCode}
              inputType="text"
              error={error}
            />

            <SelectBoxGroup
              type="projectMode"
              changeHandler={handleUpdateForm}
              value={projectMode ?? ProjectMode.SingleSite}
              options={projectModeOptions}
              error={error}
            />

            <TextInputGroup
              label="Site Mapping Activity Code Group"
              type="siteMappingActivityCodeGroup"
              changeHandler={handleUpdateForm}
              value={siteMappingActivityCodeGroup}
              inputType="text"
              error={error}
            />

            <SelectBoxGroup
              type="visibility"
              changeHandler={handleUpdateForm}
              value={visibility ?? UserRole.planner}
              options={visibilityOptions.filter(
                (role) =>
                  greaterThanRole(callerRole!, role.id) &&
                  (!isGuest(role.id) ||
                    greaterThanEqualRole(callerRole!, UserRole.orgAdmin))
              )}
              error={error}
            />

            <div className="button-container">
              <MonochromeButton
                level="primary"
                width="lg"
                type="submit"
              >
                Save
              </MonochromeButton>
            </div>
          </StyledForm>
        </StyledContentWrapper>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default ProjectForm;
