import styled from "styled-components";
import { spacing } from "styles";

export const StyledDivider = styled.div`
  width: 100%;
  padding: ${spacing.m};

  h2 {
    font-weight: 300;
  }
`;
