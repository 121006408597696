//React
import React, { FC } from "react";
//Types
import { IFormError } from "types";
//Styles
import { StyledTextInputGroup } from "./styles";
//Hooks
import { useHandleErrorFocus } from "./useHandleErrorFocus";

interface IProps {
  inputType: "text" | "email" | "textarea" | "password";
  type: string;
  label?: string;
  labelClassNames?: string;
  value: string | undefined;
  changeHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  disabled?: boolean;
  error: IFormError | null;
  name?: string;
}

const renderLabel = (type: string, label: string | undefined) => {
  if (label) return label;

  const labelByTypeMapper = {
    firstName: "First name",
    lastName: "Last name",
  };

  return labelByTypeMapper[type as keyof typeof labelByTypeMapper] || type;
};

const TextInputGroup: FC<IProps> = ({
  type,
  label,
  labelClassNames,
  value,
  changeHandler,
  disabled = false,
  error,
  inputType,
  name,
}) => {
  const { inputRef, textareaRef } = useHandleErrorFocus(error, type);

  return (
    <StyledTextInputGroup>
      <label htmlFor={type} className={labelClassNames}>
        {renderLabel(type, label)}
      </label>

      {/* INPUT */}
      {inputType !== "textarea" ? (
        <input
          type={inputType}
          id={type}
          name={name || type}
          value={value}
          onChange={changeHandler}
          data-testid={`${type}-input`}
          disabled={disabled}
          ref={inputRef}
        />
      ) : null}

      {/* TEXTAREA */}
      {inputType === "textarea" ? (
        <textarea
          id={type}
          name={name || type}
          value={value}
          onChange={changeHandler}
          data-testid={`${type}-input`}
          disabled={disabled}
          rows={4}
          ref={textareaRef}
        />
      ) : null}

      <p
        className="error"
        data-testid={error?.field === type ? "error-message" : ""}
      >
        {error?.field === type ? error.message : ""}
      </p>
    </StyledTextInputGroup>
  );
};

export default TextInputGroup;
