import { ITask, isGuest } from "@ehabitation/ts-utils/browser";
import {
  ForecastDay,
  ForecastTable,
  LoadingType,
  LoadingWrapper,
} from "@ehabitation/ui";
import { useForecast } from "Components/ForecastTable/useForecast";
import GanttWrapper from "Components/GanttWrapper/GanttWrapper";
import Initialising from "Components/Initialising/Initialising";
import PlanControls from "Components/Plan/PlanControls/PlanControls";
import TabContainer from "Components/Plan/TabContainer/TabContainer";
import TaskList from "Components/Plan/TaskList/TaskList";
import StayOnPageAlert from "Components/StayOnPageAlert/StayOnPageAlert";
import WeatherGraph from "Components/WeatherGraph/WeatherGraph";
import { weatherCodeIconMap } from "Pages/admin/constants";
import { tasksEquivalent } from "helpers";
import {
  useCheckPlanHash,
  useInitPlanner,
  useOpenPlannerEvent,
  useSelectPlan,
} from "hooks";
import { useProcessTaskUpdates } from "hooks/useProcessTaskUpdates";
import { FC, useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectRole } from "store/auth";
import {
  selectCurrentPlanId,
  selectCurrentSite,
  selectProject,
} from "store/project";
import {
  selectCurrentTab,
  selectLatestCurrentPlanSimulation,
  tasksSnapshotSelector,
} from "store/tasks";
import {
  selectSelectedChart,
  selectShowDependencies,
  selectTasksUpdating,
  setTaskModified,
} from "store/ui";
import { ISessionMetadata, ViewMode } from "types";
import { ChartsToggleButtonsContainer } from "./ChartsToggleButtonsContainer";
import { StyledPlanPage } from "./styles";
import { handleGanttSafetyEndChange } from "./thunks";
import useInitPlan from "./useInitPlan";
import { useLoadingMessage } from "./useLoadingMessage";
import { usePlanStateManager } from "./usePlanStateManager";
import { usePromptUserBeforeUnload } from "./usePromptUserBeforeUnload";
import { useScrollLocation } from "./useScrollLocation";


const Plan: FC = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectRole);
  const { tasksHaveBeenModified } = usePromptUserBeforeUnload();
  const { handleOpenSavePlanModal, handleSelectPlan } = useSelectPlan();

  const { dailyForecasts, forecastSiteId, error } = useForecast();

  /* When navigating to the page, clear whether 
  there have been modifications to any tasks */
  useEffect(() => {
    dispatch(setTaskModified(false));
  }, [dispatch]);

  const currentTab = useAppSelector(selectCurrentTab);
  const showDependencies = useAppSelector(selectShowDependencies);
  const selectedChart = useAppSelector(selectSelectedChart);
  const tasksSnapshot = useAppSelector(tasksSnapshotSelector);
  const { handleTaskUpdate } = useProcessTaskUpdates();
  const currentPlanId = useAppSelector(selectCurrentPlanId);


  const [selectedForecastIndex, setSelectedForecastIndex] = useState(0);

  const {
    ganttTasks,
    allTasks,
    siteStartDate,
    siteEndDate,
    workDays,
    holidays,
    metadata,
  } = usePlanStateManager();

  const project = useAppSelector(selectProject);
  const projectId = project?.id;

  const site = useAppSelector(selectCurrentSite);
  const tasksUpdating = useAppSelector(selectTasksUpdating);

  const { isLoading: isLoadingInitPlan } = useInitPlan(
    handleSelectPlan,
    site,
    project
  );

  const latestSimulation = useAppSelector(selectLatestCurrentPlanSimulation);
  const isSimulating =
    !!latestSimulation &&
    !["error", "complete"].includes(latestSimulation?.status || "");

  const { loadingMessage, isLoading } = useLoadingMessage();

  useOpenPlannerEvent(site?.id);

  const { handleGraphSafetyEndChange } = useInitPlanner();

  const { handleUpdateScrollPosition, ganttScrollLocation } =
    useScrollLocation();

  const userIsGuest = isGuest(userRole!);

  const blocker = useBlocker(tasksHaveBeenModified && !userIsGuest);

  const {
    isLoading: checkingMatrix,
    minorHashDrift,
    majorHashDrift,
  } = useCheckPlanHash(projectId!, currentPlanId!);

  if (!isLoadingInitPlan && !checkingMatrix) {
    return (
      <StyledPlanPage className="px-2">
        <PlanControls handleSelectPlan={handleSelectPlan} />

        <div className="chart-container relative">
          <TaskList />

          {!currentPlanId ? null : (
            <>
              <TabContainer />
              {/* CONVERT THESE EVENT HANDLERS INTO THUNKS */}
              <GanttWrapper
                tasks={ganttTasks}
                allTasks={allTasks}
                isSimulating={isSimulating}
                showDeps={showDependencies}
                startDate={siteStartDate}
                endDate={siteEndDate}
                workDays={workDays}
                holidays={holidays}
                metadata={metadata}
                xPos={ganttScrollLocation.current}
                viewMode={ViewMode.DAY}
                onSafetyEndChange={(
                  tasks: ITask[],
                  wbs: ITask,
                  safeEnd: Date,
                  accuracy: number
                ) => {
                  const selectedTask = tasks[0];
                  if (!selectedTask.milestone) {
                    handleGraphSafetyEndChange(tasks, safeEnd, accuracy);
                    dispatch(
                      handleGanttSafetyEndChange(
                        selectedTask,
                        safeEnd,
                        accuracy,
                        wbs
                      )
                    );
                  }
                }}
                onUpdateRisks={(
                  metadata: ISessionMetadata,
                  tasks: ITask[],
                  wbs: ITask[]
                ) => {
                  const modifiedTasks = tasks.filter((task) =>
                    tasksEquivalent(tasksSnapshot?.[task.id], task)
                  );

                  if (modifiedTasks) {
                    handleTaskUpdate(tasks);
                  }
                }}
                onScrollChange={handleUpdateScrollPosition}
              />
            </>
          )}
        </div>

        <div className="bottom-row px-6">
          {currentTab === "Gantt" && (
            <>
              {selectedChart === "weather" && <WeatherGraph />}
              {selectedChart === "forecast" && dailyForecasts && (
                <div className="flex mr-auto -mt-6 items-center">
                  <div className="px-[4rem]">
                    <ForecastDay
                      data={dailyForecasts[selectedForecastIndex]}
                      weatherMap={weatherCodeIconMap}
                    />
                  </div>
                  <ForecastTable
                    hideMonthLabels={true}
                    weatherCodeIconMap={weatherCodeIconMap}
                    highlightIndex={selectedForecastIndex}
                    handleDayClick={(index: number) => {
                      setSelectedForecastIndex(index);
                    }}
                    dailyForecasts={dailyForecasts}
                    error={error}
                    forecastSiteId={forecastSiteId}
                    excludeRows={["hourlyRainAcc"]}
                    compact={true}
                  />
                </div>
              )}
              <ChartsToggleButtonsContainer />
            </>
          )}
        </div>
        <LoadingWrapper
          message={loadingMessage}
          type={LoadingType.full}
          loading={!!isLoading}
        />
        {/* PROMPTS TO LEAVE PAGE */}
        {blocker.state === "blocked" ? (
          <StayOnPageAlert
            message="You may have unsaved data, are you sure you want to leave?"
            handleStayOnPage={() => blocker.reset()}
            handleLeavePage={() => blocker.proceed()}
            handleSave={() => {
              blocker.reset();
              handleOpenSavePlanModal();
            }}
          />
        ) : null}
      </StyledPlanPage>
    );
  } else {
    return (
      <Initialising>
        <p>Loading Plan Data...</p>
      </Initialising>
    );
  }
};

export default Plan;
