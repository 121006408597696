import { AppThunk } from "store";
import { ProjectManagementTabOption } from "types";
import { batch } from "react-redux";
import {
  setProjectManagementError,
  setProjectManagementIsLoading,
  setSelectedProjectTab,
} from "store/projectManagement";
import { createNewProject, updateProject } from "./helpers";
import { IProject } from "@ehabitation/ts-utils/browser";

export const triggerCreateNewProject: AppThunk =
  (newProject: IProject) => async (dispatch) => {
    dispatch(setProjectManagementIsLoading(true));

    try {
      await createNewProject(newProject);

      batch(() => {
        dispatch(setProjectManagementIsLoading(false));
        dispatch(setSelectedProjectTab(ProjectManagementTabOption.Projects));
      });
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setProjectManagementError(error.message)); //Trigger toast
        dispatch(setProjectManagementIsLoading(false));
      });
    }
  };

export const updateExistingProject: AppThunk =
  (newProject: IProject) => async (dispatch) => {
    dispatch(setProjectManagementIsLoading(true));

    try {
      await updateProject(newProject);
      dispatch(setProjectManagementIsLoading(false));
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setProjectManagementError(error.message)); //shows a toast to the user
        dispatch(setProjectManagementIsLoading(false));
      });
    }
  };
