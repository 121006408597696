import styled from "styled-components";
import { spacing, fontSize, colors, grays, flex, highlightHover } from "styles";

export const StyledTabs = styled.div`
  ${flex("row", "flex-start", "center")};

  button {
    ${flex("column", "center", "center")};
    ${highlightHover()};
    cursor: pointer;
    background: white;
    border: none;
    padding: ${spacing.m} ${spacing.m};
    width: 9rem;
    font-size: ${fontSize.s};

    @media (min-width: 1350px) {
      width: 11rem;
    }

    &:focus {
      outline: none;
      color: ${colors.primaryD};
    }

    &:disabled {
      color: ${grays.medium};
    }

    &:disabled,
    &.selected {
      cursor: default;
    }

    &.selected {
      border-top: 0.1rem solid ${grays.light};
      border-left: 0.1rem solid ${grays.light};
      border-right: 0.1rem solid ${grays.light};
      border-bottom: 0.1rem solid white;
    }
  }
`;
