import { DocumentData } from "@firebase/firestore-types";
import {
  CollectionType,
  IPlan,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { IPlanOverview } from "types";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "firebaseConfig";

export const getSitesPlansWithoutMitigations = (
  siteId: string,
  sitePlansSnapshot: DocumentData[]
): IPlanOverview => {
  const newPlanList: IPlanOverview = {};

  sitePlansSnapshot.forEach((doc) => {
    const data = doc.data();
    const isMitigationPlan = !!data.mitigatesPlanId;
    if (!isMitigationPlan) {
      newPlanList[doc.id] = transformPlanDoc(doc.id, doc.data());
    }
  });

  return newPlanList;
};

export const sortPlans = (planList: IPlan[]): IPlan[] => {
  return planList
    ? planList.sort((a, b) => {
        const nameA = a.title?.toLowerCase();
        const nameB = b.title?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
    : [];
};

export const getHiearchicalConfig = async (
  startTierObjectId: string,
  startTier: CollectionType,
  fieldName: string
): Promise<{ tierObjectId: string; tier: CollectionType; value: any }> => {
  let tierObjectId = startTierObjectId;
  let tier = startTier;
  let value = undefined;

  if (tier === CollectionType.Plans) {
    const ref = await getDoc(doc(db, tier, tierObjectId));
    if (ref.exists()) {
      const data = ref.data();
      const value = data[fieldName];
      if (!value) {
        tier = CollectionType.Sites;
        tierObjectId = data.site;
      }
    }
  }

  if (tier === CollectionType.Sites) {
    const ref = await getDoc(doc(db, tier, tierObjectId));
    if (ref.exists()) {
      const data = ref.data();
      value = data[fieldName];
      if (!value) {
        tier = CollectionType.Projects;
        tierObjectId = data.project;
      }
    }
  }

  if (tier === CollectionType.Projects) {
    const ref = await getDoc(doc(db, tier, tierObjectId));
    if (ref.exists()) {
      const data = ref.data();
      value = data[fieldName];
      if (!value) {
        tier = CollectionType.Divisions;
        tierObjectId = data.divisionId;
      }
    }
  }

  if (tier === CollectionType.Divisions) {
    const ref = await getDoc(doc(db, tier, tierObjectId));
    if (ref.exists()) {
      const data = ref.data();
      value = data[fieldName];
      if (!value) {
        tier = CollectionType.Organisations;
        tierObjectId = data.orgId;
      }
    }
  }

  if (tier === CollectionType.Organisations) {
    const ref = await getDoc(doc(db, tier, tierObjectId));
    if (ref.exists()) {
      const data = ref.data();
      value = data[fieldName];
    }
  }

  return { tierObjectId, tier, value };
};
