//Hooks
import { useContextMenu } from "hooks";
import { batch } from "react-redux";
//Store
import { useAppDispatch } from "store";
import {
  setSelectedProjectTab,
  setSelectedProject,
} from "store/projectManagement";
import { ProjectManagementTabOption } from "types";
import { deleteProjectById } from "./thunks";

export const useHandleContextMenuActions = (projectId: string) => {
  const dispatch = useAppDispatch();

  const { anchorEl, handleOpenContextMenu, handleCloseContextMenu } =
    useContextMenu();

  const handleDeleteProject = () => {
    dispatch(deleteProjectById(projectId));
    handleCloseContextMenu();
  };

  const handleEditProject = () => {
    batch(() => {
      dispatch(setSelectedProject(projectId!));
      dispatch(setSelectedProjectTab(ProjectManagementTabOption.Details));
    });
  };

  return {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
    handleDeleteProject,
    handleEditProject,
  };
};
