import { FC } from "react";

import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

interface ISidebar {
  setIsOpened: (opened: boolean) => void;
  isOpened: boolean;
  header?: any;
}

export const Sidebar: FC<ISidebar> = ({
  children,
  setIsOpened,
  isOpened,
  header,
}) => {
  return (
    <div className={`hidden sm:flex h-full border-r border-gray `}>
      <div
        className={`pl-8 pr-2 pt-1 flex flex-col gap-8 w-[14rem] lg:w-[24rem] overflow-y-auto ${
          isOpened ? "block" : "hidden"
        } `}
      >
        <div>
          {header ? header : null}
          <ul className="relative w-full flex flex-col gap-4 mt-5">
            {children}
          </ul>
        </div>
      </div>
      <div
        className="hover:cursor-pointer text-gray-700 hover:bg-[#cfebe0] transition-colors relative p-1"
        onClick={() => setIsOpened(!isOpened)}
        role="button"
        aria-label="toggle sidebar"
      >
        <div
          className="text-lg sticky top-1/2 "
        >
          {isOpened ? <BiChevronsLeft /> : <BiChevronsRight />}
        </div>
      </div>
    </div>
  );
};