import React, { FC, useState } from "react";
import { FaSpinner } from "react-icons/fa";

import { IPlan, ISite, ITaskCategory } from "@ehabitation/ts-utils/browser";
import { saveCurrentTasksState } from "Components/Menu/thunks";
import { logSimulateBase } from "helpers/analytics";
import { useCheckPlanHash } from "hooks/useCheckPlanHash";
import { useTriggerSimulation } from "hooks/useTriggerSimulation";
import { useAppSelector } from "store";
import { selectTasksUpdating } from "store/ui";
import { SimulationResult } from "helpers";
import { SimulationStages } from "./SimulationStages";
import { SimulationIssuesAlert } from "./components/SimulationIssuesAlert";

import { useFetchPlanCategories } from "./hooks/useFetchPlanCetegories";
import { useFetchHighRiskCategories } from "./hooks/useFetchHighRiskCategories";
import { SimulationResults } from "./components/SimulationResults";
import { MatrixDriftAlert } from "./components/MatrixDriftAlert";

export const SimulationView: FC<{
  syncThresholds: (projectId: string, planId: string) => Promise<void>;
  projectId: string;
  plan?: IPlan;
  site: ISite;
  simulationInProgress: boolean;
  simulationResult?: SimulationResult;
  simulationStatus?: string | undefined;
  simulationProgressMessage?: string | undefined;
}> = ({
  syncThresholds,
  projectId,
  plan,
  site,
  simulationInProgress,
  simulationResult,
  simulationStatus,
  simulationProgressMessage,
}) => {
  const areTasksUpdating = useAppSelector(selectTasksUpdating);
  const { minorHashDrift, majorHashDrift } = useCheckPlanHash(site.project, plan?.id);

  const { triggerSimulation } = useTriggerSimulation();

  const [syncPlanToRiskMatrixOption, setSyncPlanToRiskMatrixOption] = useState<string>("");
  const [hasSimulationIssues, setHasSimulationIssues] = useState<boolean>(false);
  const [planCategories, setPlanCategories] = useState<ITaskCategory[] | null>(null);
  const [categoriesWithHighRiskFactors, setCategoriesWithHighRiskFactors] = useState<ITaskCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const hasMatrixDrift = minorHashDrift || majorHashDrift;

  const { fetchPlanCategories } = useFetchPlanCategories();
  const { fetchHighRiskCategories } = useFetchHighRiskCategories(site.id);

  // Handle the simulation process
  const handleSimulation = async () => {
    setError(null);
    setIsLoading(true);

    try {
      // Ensure a plan is selected
      if (!plan) {
        throw new Error("No plan selected for simulation.");
      }

      // Handle risk matrix drift
      if (hasMatrixDrift) {
        if (!syncPlanToRiskMatrixOption) {
          throw new Error("Please choose to apply or not apply the new risk matrix thresholds.");
        }

        if (syncPlanToRiskMatrixOption === "true") {
          await syncThresholds(projectId, plan.id);
        }
      }

      // Save current tasks state and log analytics
      saveCurrentTasksState(plan.id);
      logSimulateBase(site.id, plan.id);

      // Fetch plan categories
      const fetchedCategories = await fetchPlanCategories(plan);
      setPlanCategories(fetchedCategories);

      // Fetch high-risk categories synchronously
      let highRiskCategories: ITaskCategory[] = [];
      if (fetchedCategories) {
        highRiskCategories = await fetchHighRiskCategories(fetchedCategories);
        setCategoriesWithHighRiskFactors(highRiskCategories);
      } else {
        setCategoriesWithHighRiskFactors([]);
      }

      // Determine if there are simulation issues
      if (highRiskCategories.length > 0) {
        setHasSimulationIssues(true);
        throw new Error(
          "The simulation is very likely to fail. The following activity categories are likely to face issues throughout."
        );
      } else {
        setHasSimulationIssues(false);
      }

      // Trigger simulation if no issues
      await triggerSimulation(plan.id);
    } catch (err: any) {
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false);
      // Do not reset syncPlanToRiskMatrixOption to preserve user choice
    }
  };

  return (
    <div className="p-4 flex flex-col justify-top h-full overflow-auto">
      {!plan && (
        <div className="text-2xl text-red-500">
          Upload, or select, a plan to run a simulation.
        </div>
      )}

      {plan && (
        <>
          {hasMatrixDrift && (
            <MatrixDriftAlert
              handleOptionChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSyncPlanToRiskMatrixOption(event.target.value);
              }}
              selectedOption={syncPlanToRiskMatrixOption}
            />
          )}

          {hasSimulationIssues && (
            <SimulationIssuesAlert
              categoriesWithHighRiskFactors={categoriesWithHighRiskFactors}
              renderThresholds={(thresholds: any) => (
                <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                  {Object.entries(thresholds).map(([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  ))}
                </ul>
              )}
            />
          )}

          {error && (
            <div className="text-red-500 mt-4">
              {error}
            </div>
          )}

          <div className="flex justify-end">
            <button
              className={`bg-[#000] text-[#FFF] rounded-full cursor-pointer text-2xl px-12 py-3 flex gap-2 items-center ${
                areTasksUpdating ||
                isLoading ||
                (hasMatrixDrift && !syncPlanToRiskMatrixOption) ||
                simulationInProgress
                  ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleSimulation}
              disabled={
                areTasksUpdating ||
                isLoading ||
                (hasMatrixDrift && !syncPlanToRiskMatrixOption) ||
                simulationInProgress
              }
            >
              {simulationInProgress || isLoading || areTasksUpdating ? (
                <>
                  <FaSpinner className="animate-spin" /> Simulating
                </>
              ) : (
                "Simulate"
              )}
            </button>
          </div>

          {simulationResult && (
            <SimulationResults simulationResult={simulationResult} />
          )}

          <div className="mt-20">
            <SimulationStages
              progressMessage={simulationProgressMessage}
              status={simulationStatus}
            />
          </div>
        </>
      )}
    </div>
  );
};
