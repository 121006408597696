import styled, { css } from "styled-components";
import { StyledAdminTableHeader, StyledAdminTableRow } from "elements";
import { spacing, flex, colors } from "styles";

const columnWidths = css`
  .status {
    width: 10%;
  }

  .name {
    width: 20%;
    word-break: break-word;
    hyphens: auto;
  }

  .location {
    width: 35%;
    word-break: break-word;
    hyphens: auto;
  }

  .latlong {
    width: 20%;
  }
  .start {
    width: 10%;
  }

  .end {
    width: 10%;
  }

  .icon {
    width: 5%;
  }
`;

export const StyledTableHeader = styled(StyledAdminTableHeader)`
  ${columnWidths};
`;

export const StyledTableRow = styled(StyledAdminTableRow)`
  ${columnWidths};

  .status,
  .name,
  .start,
  .end {
    ${flex("column", "center", "flex-start")};
    height: 100%;
  }

  .name {
    p {
      text-transform: capitalize;
    }
  }
`;

interface IStatusPipProps {
  isOkay: boolean;
}

export const StyledStatusPip = styled.div<IStatusPipProps>`
  height: 1rem;
  width: 1rem;
  background: ${(props) => (props.isOkay ? colors.safe : colors.moderate)};
  margin-left: ${spacing.m};
`;
