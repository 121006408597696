//Styles
import styled, { css } from "styled-components";
import {
  grays,
  replaceOutline,
  spacing,
  flex,
  font,
  fontSize,
  colors,
} from "styles";

const baseInputStyles = css`
  ${flex("column", "flex-start", "flex-start")}
  padding-bottom: ${spacing.s};
  width: 100%;

  label {
    font-size: ${fontSize.s};
    text-transform: capitalize;
  }

  .error {
    color: ${colors.major};
    font-size: ${fontSize.s};
    height: ${fontSize.s};
  }

  .error-outline {
    &:focus {
      box-shadow: 0 0 0.2rem 0.1rem ${colors.major};
    }
  }
`;

export const StyledTextInputGroup = styled.div`
  ${baseInputStyles};

  input,
  textarea {
    ${replaceOutline()};
    ${font("body")};
    background: white;
    padding: ${spacing.s};
    width: 100%;
    border: 0.1rem solid ${grays.dark};
    &:disabled {
      background: ${grays.veryLight};
    }
  }

  textarea {
    resize: none;
  }
`;

export const StyledSelectBoxGroup = styled.div`
  ${baseInputStyles};

  select {
    ${replaceOutline()};
    ${font("body")};
    background: white;
    border: none;
    max-width: 20rem;
    padding: ${spacing.s};
    text-transform: capitalize;
    width: 100%;
  }
`;
