import {
  ConfidenceHeatMap,
  HeatmapType,
  ITask,
  ITaskCategory,
  KeyViolationData,
  ViolationData,
  transformTaskDoc,
} from "@ehabitation/ts-utils/browser";
import { SimulationResult, SimulationTaskResult } from "helpers";
import moment from "moment";
import { WeatherImpactData } from "types";
import {
  determineColor,
  determineWeatherTypeLongString,
} from "Components/Risk/helpers";
import { color } from "@amcharts/amcharts4/core";
import { applyRiskMatrix } from "Components/Plan/PlanControls/thunks";
import { QueryDocumentSnapshot, DocumentData } from "firebase/firestore";
import { yieldMainThread, getPlanTaskId } from "../helper";

const isInWindow = (checkDate: Date, window: { start: Date; end: Date }) => {
  const dateIsAfterStart = checkDate >= window.start;
  const dateIsBeforeEnd = checkDate <= window.end;
  return dateIsAfterStart && dateIsBeforeEnd;
};

export const getUIHeatmapData = (
  tasks: ITask[],
  type: HeatmapType,
  simulationResult?: SimulationResult,
  window?: { start: Date; end: Date }
) => {
  let planStart = window?.start;
  let planEnd = window?.end;
  const confidenceMap = {} as ConfidenceHeatMap;
  const years = new Set<number>();

  for (const task of tasks) {
    if (task.WBS || task.milestone) continue;
    if (!window) {
      (!planStart || task.start < planStart) && (planStart = task.start);
      (!planEnd || task.end > planEnd) && (planEnd = task.end);
    }

    const start = moment.utc(task.start);
    const length = moment.utc(task.end).diff(start, "days");
    years.add(start.year());

    for (let taskDay = 0; taskDay <= length; taskDay++) {
      const taskMoment = moment.utc(start).add(taskDay, "days");
      let confidenceKey = "";
      if (type === HeatmapType.Daily) {
        if (!isInWindow(taskMoment.toDate(), window!)) continue;
        confidenceKey = `${taskMoment.year()}_${taskMoment
          .dayOfYear()
          .toString()
          .padStart(3, "0")}`;
      }

      // Use max delay to indicate task has some risk. any risk.
      const maxDelay =
        simulationResult?.taskResults?.[task.id]?.maxDelayDays ?? -1;

      if (type === HeatmapType.Weekly) {
        const taskWeek = taskMoment.isoWeek();
        confidenceKey = `${taskWeek
          .toString()
          .padStart(2, "0")}_${taskMoment.year()}`;
      }
      !confidenceMap[confidenceKey]
        ? (confidenceMap[confidenceKey] = [maxDelay])
        : confidenceMap[confidenceKey].push(maxDelay);
    }
  }
  return {
    confidenceMap,
    years: Array.from(years).sort(),
    planStart,
    planEnd,
  };
};

export const getWeatherImpactData = (tasks: {
  [id: string]: SimulationTaskResult;
}) => {
  const widRoundup = {} as ViolationData;
  for (const { maxDelayDays, violationData } of Object.values(tasks)) {
    if (maxDelayDays && violationData) {
      let key: KeyViolationData;
      for (key in violationData) {
        if (widRoundup[key]) {
          widRoundup[key]! += violationData[key]! / maxDelayDays;
        } else {
          widRoundup[key] = violationData[key]! / maxDelayDays;
        }
      }
    }
  }
  const widData = [] as WeatherImpactData[];
  for (const [key, value] of Object.entries(widRoundup)) {
    widData.push({
      weatherType: determineWeatherTypeLongString(key as KeyViolationData),
      value,
      color: color(determineColor(key as KeyViolationData)),
    });
  }
  return widData;
};

export const processRawTasks = async (
  taskDocs: QueryDocumentSnapshot<DocumentData>[],
  categories: ITaskCategory[],
  taskResults?: { [id: string]: SimulationTaskResult }
) => {
  const simulatedTasks: (ITask & { simulation: SimulationTaskResult })[] = [];
  const milestones: (ITask & { simulation: SimulationTaskResult })[] = [];
  await Promise.all(
    taskDocs.map(async (taskDoc, i) => {
      if (i % 250 === 0) {
        await yieldMainThread();
      }
      let task: ITask & { simulation: SimulationTaskResult };
      const data = taskDoc.data();
      if (data.levelOfEffort) return;
      if (taskResults && taskResults[taskDoc.id]) {
        const result = taskResults[taskDoc.id];
        // TODO, this is not enough, if the result is empty we need to clear the result fields which may be left in DB from previous runs
        task = {
          ...applyRiskMatrix(transformTaskDoc(taskDoc.id, data), categories),
          simulation: result,
        };
      } else {
        // if no sim result is found just return what is on the task (for now until migrating)
        task = {
          ...applyRiskMatrix(
            transformTaskDoc(taskDoc.id, data) as ITask,
            categories
          ),
          simulation: {
            accuracy: taskResults ? data.accuracy : 100,
            baseAccuracy: taskResults ? data.baseAccuracy : 100,
            delayDistribution: data.delayDistribution,
            likelyCause: data.likelyCause,
            maxDelayDays: data.maxDelayDays,
            riskCategoryId: data.riskCategoryId,
            riskIntervalResults: data.riskIntervalResults,
            risks: data.risks,
            start: data.start,
            end: data.end,
            violationData: data.violationData,
            taskType: data.taskType,
            startDistribution: data.startDistribution,
            endDistribution: data.endDistribution,
            planEndCorrelation: data.planEndCorrelation,
          },
        };
      }
      if (data.milestone) {
        milestones.push(task);
      } else {
        simulatedTasks.push(task);
      }
    })
  );
  return { simulatedTasks, milestones };
};

export const compareByName = (
  baseTasks: { [id: string]: ITask & { simulation: SimulationTaskResult } },
  comparisonSimulationResults: (ITask & { simulation: SimulationTaskResult })[],
  task: ITask & { simulation: SimulationTaskResult }
): (ITask & { simulation: SimulationTaskResult }) | undefined => {
  const baseTitles = [task.title];
  let currentTask = task;
  while (currentTask.internalParentId) {
    currentTask = baseTasks[currentTask.internalParentId];
    baseTitles.push(currentTask.title);
  }
  const baseMatches: (
    | (ITask & { simulation: SimulationTaskResult })
    | undefined
  )[] = comparisonSimulationResults.filter((c) => c.title === baseTitles[0]);
  let matches = baseMatches.map((m) => {
    return {
      match: m,
      currentTask: m,
      parent: comparisonSimulationResults.find(
        (c) => c.id === m?.internalParentId
      ),
    };
  });
  let i = 1;
  while (matches.length > 1 && i < baseTitles.length) {
    const currentTitle = baseTitles[i];
    const matchesParents = matches.map((m) => {
      return {
        ...m,
        currentTask: m.parent,
        parent: comparisonSimulationResults.find(
          (c) => c.id === m?.parent?.internalParentId
        ),
      };
    });
    const newMatches =
      matchesParents.filter((m) => m?.currentTask?.title === currentTitle) ||
      [];
    matches = newMatches || [];
    i += 1;
  }
  return matches.length === 1 ? matches[0].match : undefined;
};

export const compareByParent = (
  task: ITask & { simulation: SimulationTaskResult },
  comparisonSimulationResultsById: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  },
  comparedParents: (ITask & { simulation: SimulationTaskResult })[]
): (ITask & { simulation: SimulationTaskResult }) | undefined => {
  const parentId = task.internalParentId;
  if (parentId && comparisonSimulationResultsById[parentId])
    return comparedParents.find(
      (c) => c.title === comparisonSimulationResultsById[parentId].title
    );
  else return comparedParents.find((c) => !c.internalParentId);
};

export const getComparisonImpactedTasksNotInBase = async (
  baseTasks: (ITask & { simulation: SimulationTaskResult })[],
  comparisonSimulationResultsById: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  },
  comparisonSimulationResults: (ITask & { simulation: SimulationTaskResult })[],
  comparisonPlanId: string,
  basePlanId: string,
  mitigationType = false
) => {
  const comparisonResultsByBaseTaskId: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  } = {};
  const allTasks: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  } = {};
  const baseTasksById: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  } = {};
  baseTasks.forEach((t) => (baseTasksById[t.id] = t));
  await Promise.all(
    baseTasks.map(async (task, i) => {
      if (i % 250 === 0) {
        await yieldMainThread();
      }
      if (mitigationType) {
        const comparisonTaskId = getPlanTaskId(task.id, comparisonPlanId);
        comparisonSimulationResultsById[comparisonTaskId] &&
          (comparisonResultsByBaseTaskId[task.id] =
            comparisonSimulationResultsById[comparisonTaskId]);
      } else {
        const comparisonByName = compareByName(
          baseTasksById,
          comparisonSimulationResults,
          task
        );
        if (comparisonByName)
          comparisonResultsByBaseTaskId[task.id] = comparisonByName;
      }
      // Ideally would search by objectId also but this is not currently available in the simulation result doc
    })
  );
  const comparisonTaskResultsNotInBase: {
    [id: string]: ITask & { simulation: SimulationTaskResult };
  } = {};
  await Promise.all(
    Object.keys(comparisonSimulationResultsById).map(
      async (comparisonTaskId, i) => {
        if (i % 250 === 0) {
          await yieldMainThread();
        }
        const basePlanTaskId = getPlanTaskId(comparisonTaskId, basePlanId);
        if (!comparisonResultsByBaseTaskId[basePlanTaskId]) {
          comparisonTaskResultsNotInBase[comparisonTaskId] =
            comparisonSimulationResultsById[comparisonTaskId];
        }
      }
    )
  );
  const matchingHierarchy: {
    id: string;
    task: ITask & { simulation: SimulationTaskResult };
  }[] = [];
  const noMatchingHierarchy: (ITask & { simulation: SimulationTaskResult })[] =
    [];
  if (Object.keys(comparisonTaskResultsNotInBase).length > 0) {
    Object.keys(comparisonTaskResultsNotInBase).forEach((id) => {
      let task = comparisonTaskResultsNotInBase[id];
      let parentFound = true;
      let hierarchy = [];
      while (task?.internalParentId && parentFound) {
        task = comparisonSimulationResultsById[task.internalParentId];
        const comparedParents = task
          ? baseTasks.filter((c) => c.title === task.title)
          : [];
        if (comparedParents.length > 1) {
          const correctParent = compareByParent(
            task,
            comparisonSimulationResultsById,
            comparedParents
          );
          if (correctParent) {
            hierarchy.push(correctParent.id);
          } else parentFound = false;
        } else if (comparedParents.length === 1) {
          hierarchy.push(comparedParents[0].id);
        } else parentFound = false;
      }
      if (parentFound && hierarchy.length > 0) {
        matchingHierarchy.push({
          id: hierarchy[0],
          task: comparisonTaskResultsNotInBase[id],
        });
        hierarchy = [];
      } else {
        noMatchingHierarchy.push(comparisonTaskResultsNotInBase[id]);
      }
    });
  }
  baseTasks
    .sort(({ wbsHierarchyOrder: a }, { wbsHierarchyOrder: b }) => a! - b!)
    .forEach((task) => {
      allTasks[task.id] = task;
      if (matchingHierarchy.length > 0) {
        const matchingTasks = matchingHierarchy.filter((h) => h.id === task.id);
        if (matchingTasks.length > 0) {
          matchingTasks.forEach((t) => (allTasks[t.task.id] = t.task));
        }
      }
    });
  if (noMatchingHierarchy.length > 0)
    noMatchingHierarchy
      .sort(({ wbsHierarchyOrder: a }, { wbsHierarchyOrder: b }) => a! - b!)
      .forEach((t) => (allTasks[t.id] = t));
  return {
    comparisonResultsByBaseTaskId,
    comparisonTaskResultsNotInBase,
    allTasks,
  };
};