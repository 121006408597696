import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { useIsMounted } from "hooks";
import React, { FC, useState } from "react";
import { FaThumbtack } from "react-icons/fa";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";

const pinSite = async (siteId: string, userId: string) => {
  const ref = doc(collection(db, "users"), userId);
  const snap = await getDoc(ref);
  if (snap.exists()) {
    const data = snap.data();
    const pinnedSites = data?.pinnedSites || [];
    if (!pinnedSites.includes(siteId)) {
      pinnedSites.push(siteId);
      await updateDoc(ref, { pinnedSites });
    } else {
      await updateDoc(ref, {
        pinnedSites: pinnedSites.filter((id: string) => id !== siteId),
      });
    }
  }
};

export const PinSiteButton: FC<{
  isPinned: boolean;
  isSelectedSite: boolean;
  siteId: string;
}> = ({ isPinned, isSelectedSite, siteId }) => {
  const [isPinning, setIsPinning] = useState(false);
  const isMounted = useIsMounted();
  const userId = useAppSelector(selectUserId);

  return (
    <button
      aria-label={isPinned ? "unpin site" : "pin site"}
      onClick={(e) => {
        e.stopPropagation();
        setIsPinning(true);
        pinSite(siteId, userId).then(() => {
          if (isMounted()) setIsPinning(false);
        });
      }}
      disabled={isPinning}
      className={`text-4xl p-4 rounded-lg transition-colors disabled:opacity-70 ${
        isSelectedSite ? "hover:bg-green-400" : "hover:bg-gray-200"
      }`}
    >
      <FaThumbtack
        className={`${isPinned ? "text-yellow-600" : "text-gray-500"} text-2xl`}
      />
    </button>
  );
};
