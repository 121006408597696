import { ISimulation, ITask } from "@ehabitation/ts-utils/browser";
import { SimulationResult, SimulationTaskResult } from "helpers";

export const applySimulationResults = (
  task: ITask,
  currentPlanSimulationResults: SimulationResult | null,
  simulation: ISimulation | null
) => {
  let taskSimulation = {} as any;
  const taskResult = currentPlanSimulationResults?.taskResults[task.id];
  if (simulation?.status === "complete" || simulation?.status === "partial") {
    if (!task.WBS && taskResult) {
      // Set task results if found
      taskSimulation.accuracy = taskResult.accuracy;
      taskSimulation.baseAccuracy = taskResult.baseAccuracy;
      taskSimulation.risks = taskResult.risks;
      taskSimulation.delayDistribution = taskResult.delayDistribution;
      taskSimulation.maxDelayDays = taskResult.maxDelayDays;
      taskSimulation.violationData = taskResult.violationData;
      taskSimulation.startDistribution = taskResult.startDistribution;
      taskSimulation.endDistribution = taskResult.endDistribution;
      taskSimulation.riskIntervalResults = taskResult.riskIntervalResults;
    } else if (!task.WBS) {
      // If not found, default values to show no result
      taskSimulation.accuracy = 100;
      taskSimulation.baseAccuracy = 100;
      taskSimulation.risks = [{ width: 0, label: 0 }];
      taskSimulation.delayDistribution = undefined;
      taskSimulation.maxDelayDays = undefined;
      taskSimulation.violationData = undefined;
    }
  } else {
    taskSimulation.accuracy = 0;
    taskSimulation.baseAccuracy = 0;
    taskSimulation.risks = [{ width: 0, label: 0 }];
    taskSimulation.delayDistribution = undefined;
    taskSimulation.maxDelayDays = undefined;
    taskSimulation.violationData = undefined;
  }
  return {
    ...task,
    ...taskSimulation,
    simulation: taskSimulation,
  } as ITask & { simulation: SimulationTaskResult };
};
