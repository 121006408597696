//Styles
import { flex, colors, grays, fontSize, highlightHover } from "styles";
import styled from "styled-components";

interface IStyledToggleButton {
  selected: boolean;
}

interface IStyledChartContainer {
  visible?: boolean | undefined;
}

export const StyledChart = styled.div`
  width: 100%;

  .amcharts-amexport-top {
    right: 20px !important;
    font-size: 10px !important;
  }

  .amcharts-amexport-item {
    border: 1px solid #777 !important;
    background-color: #fff !important;
    font-size: 10px !important;
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    background-color: #fff !important;
    font-size: 12px !important;
    line-height: 15px !important;
  }
`;

export const StyledSeriesMenu = styled.div`
  ${flex("column", "center", "")};
`;

export const StyledErrorMessage = styled.p`
  ${flex("column", "center", "center")};
  text-align: center;
  width: 100%;
  height: 100%;
  color: ${colors.warning};
`;

export const StyledChartContainer = styled.div<IStyledChartContainer>`
  ${flex("row", "center", "center")};
  width: 100%;
  height: 100%;
  ${(props) => (props.visible ? "" : "display: none;")};
`;

export const StyledLoadingMessage = styled.div`
  ${flex("column", "center", "center")};
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const StyledToggleButton = styled.button<IStyledToggleButton>`
  background-color: ${(props) => {
    if (props.selected) {
      return "white";
    } else return grays.veryLight;
  }};
  ${flex("row", "center", "center")};
  ${highlightHover()};

  border: none;
  border-left: 2px solid ${grays.veryLight};
  border-bottom: 2px solid ${grays.veryLight};
  padding-top: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  outline: none;
  cursor: pointer;
  color: ${grays.dark};
  font-size: ${fontSize.xs};
`;
