export const convertUnits = (
    value: number,
    fromUnit: 'metric' | 'imperial',
    toUnit: 'metric' | 'imperial',
    type: 'temperature' | 'distance' | 'speed' | 'precipitation'
): number => {
    if (fromUnit === toUnit) return value; // Return the original value if no conversion is needed

    switch (type) {
        case 'temperature':
            return fromUnit === 'metric' && toUnit === 'imperial'
                ? (value * 9 / 5) + 32      // Celsius to Fahrenheit
                : (value - 32) * 5 / 9;     // Fahrenheit to Celsius

        case 'distance':
            return fromUnit === 'metric' && toUnit === 'imperial'
                ? value * 3.28084           // Meters to Feet
                : value / 3.28084;          // Feet to Meters

        case 'speed':
            return fromUnit === 'metric' && toUnit === 'imperial'
                ? value * 2.23694           // Meters per second to Miles per hour
                : value / 2.23694;          // Miles per hour to Meters per second

        case 'precipitation':
            return fromUnit === 'metric' && toUnit === 'imperial'
                ? value * 0.0393701         // mm to inches
                : value / 0.0393701;        // inches to mm

        default:
            throw new Error(`Unsupported conversion type: ${type}`);
    }
};
