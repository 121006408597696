import fileDownload from "js-file-download";

import { ExportTypes, logExportEvent } from "helpers/analytics";
import { firebaseFunction } from "helpers";
import { getBlob, getStorage, ref } from "firebase/storage";

interface IGeneratePDFParams {
  site: string;
}

export const generateHistoricSiteStatsReport = async ({
  site,
}: IGeneratePDFParams): Promise<void> => {
  const generateHistoricStats = firebaseFunction("GenerateHistoricStats");

  generateHistoricStats({
    site_id: site,
  });
};

export const downloadHistoricSiteStatsReport = async (
  bucketFilename: string,
  siteId: string
) => {
  const storage = getStorage();

  const storageRef = ref(storage, bucketFilename);
  if (storageRef) {
    logExportEvent({
      siteId: siteId,
      exportType: ExportTypes.HistoricalSiteStats,
    });
    getBlob(storageRef)
      .then((blob) => {
        fileDownload(
          blob,
          `Site Statistics Report.xlsx`,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      })
      .catch((error) => {
        console.error(
          error.code,
          `could not download site statistics report ${bucketFilename} for ${siteId}`
        );
        throw error;
      });
  } else {
    console.error("could not download file");
  }
};
