import { IPlan } from "@ehabitation/ts-utils/browser";

export interface IPlanOverview {
  [key: string]: IPlan;
}

export enum ViewMode {
  DAY = "Day",
  WEEK = "Week",
  MONTH = "Month",
  YEAR = "Year",
}

export type ICharts = "weather" | "forecast";
