import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectUserOrgId,
  selectUserDivisionId,
  selectUserProjectId,
  selectRole,
} from "store/auth";
import { setOrganisationUsers } from "store/userManagement";
import { IOrganisationUsers } from "types";
import { subscribeToUsers } from "helpers";
import { UserRole } from "@ehabitation/ts-utils/browser";

/* 
Subscribe to all user docs on the organisation as the orgAdmin,
all user docs on the division as a divisionAdmin,
all user docs on the project as everyone else
*/
export const useSubscribeToUsers = () => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);
  const orgId = useAppSelector(selectUserOrgId);
  const divisionId = useAppSelector(selectUserDivisionId);
  const projectId = useAppSelector(selectUserProjectId);

  const [isReady, setReady] = useState(false);

  const dispatcher = useCallback(
    (organisationUsers: IOrganisationUsers) => {
      dispatch(setOrganisationUsers(organisationUsers));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!role || !orgId) return;
    if (role === UserRole.guest) return setReady(true);
    const unsubscribe = subscribeToUsers({
      role,
      orgId,
      divisionId,
      projectId,
      setReady,
      dispatcher,
    });

    return unsubscribe;
  }, [role, orgId, divisionId, projectId, dispatcher]);

  return isReady;
};
