// Get task duration from start & end date objects
export const calculateTaskDuration = (start: Date, end: Date) => {
  // Fixed ms resolution of firestore TS
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Construct UTC dates from input to get correct duration
  const utcStart = Date.UTC(
    start.getFullYear(),
    start.getMonth(),
    start.getDate()
  );
  const utcEnd = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());

  return Math.floor((utcEnd - utcStart) / MS_PER_DAY) + 1;
};
