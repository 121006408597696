//Styles
import styled from "styled-components";
import { grays, replaceOutline, spacing } from "styles";

export const StyledTextInput = styled.input<{ isOwner?: boolean }>`
  ${replaceOutline()};
  width: 30rem;
  padding: ${spacing.s};
  border: 0.1rem solid ${grays.light};
  margin-left: ${(props) => (props.isOwner ? spacing.l : 0)};
`;
