//React
import React, { FC } from "react";
//Styles
import { StyledInitialising } from "./styles";
//Images
import logo from "assets/images/logo_ehab.png";

const Initialising: FC = ({ children }) => {
  return (
    <StyledInitialising>
      <div className="welcome">
        <h1>Welcome to </h1>
        <img src={logo} alt="EHAB logo" />
      </div>
      {children}
    </StyledInitialising>
  );
};

export default Initialising;
