import {
  CollectionType,
  transformDivisionDoc,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { IDivisionMap } from "types";
import { db } from "firebaseConfig";
import { collection, query, where, onSnapshot } from "firebase/firestore";

interface ISubscribeToDivisions {
  role: UserRole;
  orgId: string;
  divisionId: string;
  dispatcher: (divisionMap: IDivisionMap) => void;
  setReady: (bool: boolean) => void;
}
export const subscribeToDivisions = ({
  role,
  orgId,
  divisionId,
  dispatcher,
  setReady,
}: ISubscribeToDivisions) => {
  const queryBase = determineQueryBase(role, orgId, divisionId);
  const unsubscribe = onSnapshot(
    queryBase,
    (docs) => {
      setReady(true);

      const divisionMap: IDivisionMap = {};

      docs.forEach((doc) => {
        const division = transformDivisionDoc(doc.id, doc.data());
        divisionMap[doc.id] = division;
      });

      dispatcher(divisionMap);
    },
    (error: Error) => {
      console.log("divisions subscription failed:", {
        orgId,
        divisionId,
        role,
      });
      console.error(error);
    }
  );

  return unsubscribe;
};

const determineQueryBase = (
  role: UserRole,
  orgId: string,
  divisionId: string
) => {
  switch (role) {
    case UserRole.orgAdmin: {
      return query(
        collection(db, CollectionType.Divisions),
        where("orgId", "==", orgId)
      );
    }
    case UserRole.guest: {
      return query(
        collection(db, CollectionType.Divisions),
        where("orgId", "==", orgId),
        where("visibility", "==", UserRole.guest)
      );
    }
    default: {
      return query(
        collection(db, CollectionType.Divisions),
        where("__name__", "==", divisionId)
      );
    }
  }
};
