///React
import React, { Fragment, useEffect, useState } from "react";
//Store
import { RootState, useAppSelector } from "store";
import { selectOrderedSites } from "store/siteManagement";
import { selectRole } from "store/auth";
//Components
import SiteRow from "./SiteRow";
import Divider from "Components/Admin/Divider";
import { IOrganisationSite } from "types";
import moment from "moment";

interface IProjectSites {
  name: string;
  id: string;
  sites: IOrganisationSite[];
}

const SitesList = () => {
  const orderedSites = useAppSelector(selectOrderedSites);
  const role = useAppSelector(selectRole);
  const { searchInput } = useAppSelector((state: RootState) => state.search);
  const [searchedSites, setSearchedSites] = useState<IProjectSites[] | null>(
    null
  );
  const isProjectAdmin = role === "projectAdmin";

  useEffect(() => {
    const filterSites = (sites: IOrganisationSite[]) => {
      return sites.filter(
        (s) =>
          s.name?.toLowerCase().includes(searchInput) ||
          s.location?.latitude.toString().includes(searchInput) ||
          s.location?.longitude.toString().includes(searchInput) ||
          moment(s.startDate).format("DD/MM/YYYY").includes(searchInput) ||
          moment(s.endDate).format("DD/MM/YYYY").includes(searchInput)
      );
    };
    const filterProjectSites = () => {
      const filteredProjectSites = orderedSites.map((os) => {
        return {
          ...os,
          sites: filterSites(os.sites),
        };
      });
      return filteredProjectSites.filter((ps) => ps.sites.length > 0);
    };
    setSearchedSites(searchInput === "" ? orderedSites : filterProjectSites());
  }, [searchInput, orderedSites]);

  return (
    <>
      {searchedSites?.map(({ id, name, sites }) => {
        return (
          <Fragment key={id}>
            {!isProjectAdmin && <Divider text={name} version="project" />}

            {sites.map((site: IOrganisationSite) => (
              <SiteRow key={site.id} site={site} />
            ))}
          </Fragment>
        );
      })}
    </>
  );
};

export default SitesList;
