import { FC, useEffect } from "react";

import {
  ISite,
  UserRole
} from "@ehabitation/ts-utils/browser";
import Initialising from "Components/Initialising/Initialising";
import { SitePage } from "Components/Site/SitePage";
import {
  usePersonalSites,
  useRiskMatrixTopLevelCategories,
  useSetupSubscriptions,
  useSiteRiskMatrix
} from "hooks";
import { usePlans } from "hooks/usePlans";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserClaims } from "store/auth";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
} from "store/siteManagement";

const Site: FC = () => {
  const navigate = useNavigate();
  const userClaims = useAppSelector(selectUserClaims);

  const subscriptionsReady = useSetupSubscriptions();

  const organisationSites = useAppSelector(selectOrganisationSites);
  const organisationProjects = useAppSelector(selectOrganisationProjects);

  const siteId = useParams<{ siteId: string }>().siteId;

  const {
    sites: personalSites = [],
    initialisingSites: initialisingPersonalSites,
  } = usePersonalSites();

  useEffect(() => {
    if (
      userClaims?.organisation === "demo-org" &&
      userClaims.role === UserRole.guest &&
      !initialisingPersonalSites &&
      !personalSites?.length
    ) {
      navigate("/sites/new");
    }
  }, [personalSites, initialisingPersonalSites]);

  const site = siteId
    ? organisationSites[siteId] ||
      personalSites?.find(({ id }: ISite) => id === siteId)
    : undefined;

  const project = site ? organisationProjects[site.project] : undefined;

  const plans = usePlans(site, project);

  const { riskMatrix: siteRiskMatrix, isLoading: isLoadingRiskMatrix } =
    useSiteRiskMatrix(site);

  const { categories: siteCategories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(siteRiskMatrix?.id);

  if (!subscriptionsReady || initialisingPersonalSites)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  if (!site) {
    return (
      <div className={`w-full h-full relative`}>
        <div className="h-[20rem] w-full grid place-content-center gap-2">
          <div className="flex flex-col items-center">
            <p>Select a site to get started!</p>
            <p>Or</p>
            <a className="text-blue-500 underline" href="/sites/new">
              Create a new forecast only site
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (!plans || isLoadingCategories || isLoadingRiskMatrix)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );

  return siteRiskMatrix && siteCategories ? (
    <SitePage
      site={site}
      project={project!}
      plans={plans}
      siteRiskMatrix={siteRiskMatrix}
      siteCategories={siteCategories}
      key={site.id}
    />
  ) : (
    <div className={`w-full h-full relative`}>
      <div className="h-[20rem] w-full grid place-content-center gap-2">
        <div className="flex flex-col items-center">
          <p>
            Failed to load risk matrix for this site, please try again or
            contact support@ehab.co if the error persists.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Site;
