import { IFormError } from "../../types";
import yup from "yup";
import { IDivision, IProject } from "@ehabitation/ts-utils/browser";

/*
Returns a tuple [isValid, errorObject]
*/

export const validateNewForm = async (
  newSchema: yup.ObjectSchema<any, any, any, any>,
  form: IDivision | Partial<IProject>
): Promise<[boolean, IFormError | null]> => {
  try {
    await newSchema.validate(form);
    return [true, null];
  } catch (e) {
    const error = e as yup.ValidationError;
    return [
      false,
      {
        field: error.path!,
        message: error.errors[0],
      },
    ];
  }
};

export const roundTo6DP = (value?: string) => {
  const numValue = Number(value);
  if (!value || isNaN(numValue)) return 0;
  return Math.round(numValue * 1000000) / 1000000;
};
