import { FC, ReactElement } from "react";

export const StepDescription: FC<{
  step: number;
  description?: string;
  children?: ReactElement;
}> = ({ step, description, children }) => {
  return (
    <div className="flex items-center justify-between pb-8">
      <div className="flex">
        <div className="mr-10 whitespace-nowrap">Step {step}</div>
        {description && <div>{description}</div>}
        {children}
      </div>
    </div>
  );
};
