//React
import React, { FC } from "react";
//Helpers
import { startCase } from "lodash";
//Styles
import { StyledToggleButton } from "./styles";
import { WeatherMetric } from "./types";

interface IProps {
  weatherMetric: WeatherMetric;
  toggleWeatherMetric: (weatherMetric: WeatherMetric) => void;
  label: string;
  selectedWeatherMetric: WeatherMetric;
}

const ToggleButton: FC<IProps> = ({
  selectedWeatherMetric,
  weatherMetric,
  label,
  toggleWeatherMetric,
}) => {
  const selected = selectedWeatherMetric === weatherMetric;

  return (
    <StyledToggleButton
      onClick={() => {
        toggleWeatherMetric(weatherMetric);
      }}
      selected={selected}
      data-testid={`weather-chart-series-button-${label}`}
    >
      {startCase(label)}
    </StyledToggleButton>
  );
};

export default ToggleButton;
