import { useState } from "react";
import { Button, PasswordInput } from "@ehabitation/ui";
import {
  checkPasswordComplexity,
  PasswordStrength,
} from "helpers/validation/passwordComplexity";

import { useIsMounted } from "hooks";

const usePasswordForm = (
  confirmPassword: (password: string) => Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  const [errorMessage, setErrorMessage] = useState("");

  const [password, setPassword] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    if (e.target.name === "password") setPassword(e.target.value);
  };

  const isValid = () => {
    const passwordStrength = checkPasswordComplexity(password);

    return passwordStrength == PasswordStrength.Ok;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValid())
      return setErrorMessage(
        "Please provide a password of 8 or more characters, it should contain a number, upper case, lower case and special character. Avoid common sequences."
      );

    setErrorMessage("");
    setIsLoading(true);

    try {
      await confirmPassword(password);
    } catch (e) {
      const error = e as { code: string };
      if (isMounted()) {
        console.error(error);
        setErrorMessage(
          "Password setting failed, please try again or contact support@ehab.co if the error persists."
        );
        setIsLoading(false);
      }
    }
  };

  return {
    password,
    errorMessage,
    isLoading,
    handleChange,
    handleSubmit,
  };
};

const PasswordForm = ({
  confirmPassword,
}: {
  confirmPassword: (password: string) => Promise<void>;
}) => {
  const { password, errorMessage, isLoading, handleChange, handleSubmit } =
    usePasswordForm(confirmPassword);

  return (
    <div className="flex items-center w-full">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-full max-w-xl"
        aria-label="Login"
      >
        <PasswordInput
          className="self-stretch"
          value={password}
          autoComplete="new-password"
          onChange={handleChange}
          placeholder="New Password"
          tabIndex={1}
          name="password"
          aria-label="New Password"
          disabled={isLoading}
        />

        {errorMessage && (
          <p className="pt-4 text-red-700 text-center">{errorMessage}</p>
        )}

        <Button
          className="my-4"
          type="submit"
          tabIndex={1}
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default PasswordForm;
