import { IProject } from "@ehabitation/ts-utils/browser";

export type IHistoricThresholdsKey =
  | "cumRainfall"
  | "daysRainAbove5mm"
  | "daysTempBelow0C"
  | "maxTemp"
  | "minTemp"
  | "windSpeed";

export interface ICurrentThresholds {
  cumRainfall: number;
  daysOver5mm: number;
  daysUnder0C: number;
}

export enum ProjectMode {
  SingleSite = "single-site",
  MultiSiteWithActivityCodes = "multi-site-activity-code",
}

export const isMultiSite = (project?: IProject | null): boolean =>
  !!project?.projectMode && project?.projectMode != ProjectMode.SingleSite;
