//React
import React, { FC } from "react";
//Material UI
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
//Styles
import { StyledSelectBoxGroup } from "./styles";
//Types
import { IFormError } from "types";

interface IProps {
  changeHandler: (date: MaterialUiPickersDate, type: string) => void;
  disabled?: boolean;
  error: IFormError | null;
  type: string;
  value: Date;
}

const DatePickerGroup: FC<IProps> = ({
  changeHandler,
  disabled = false,
  error,
  type,
  value,
}) => {
  return (
    <StyledSelectBoxGroup>
      <label htmlFor={type}>{type}</label>

      <DatePicker
        onChange={(date) => {
          changeHandler(date, type);
        }}
        format="MMMM do yyyy"
        value={value}
        data-testid={`${type}-input`}
        disabled={disabled}
      />

      <p
        className="error"
        data-testid={error?.field === type ? "error-message" : ""}
      >
        {error?.field === type ? error.message : ""}
      </p>
    </StyledSelectBoxGroup>
  );
};

export default DatePickerGroup;
