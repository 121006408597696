//React
import React, { FC } from "react";
//Styles
import { StyledDivider } from "./styles";

interface IProps {
  text: string;
  version: "division" | "project";
}

const Divider: FC<IProps> = ({ text, version }) => {
  return (
    <StyledDivider data-testid={`${version}-divider`}>
      <h2>{text}</h2>
    </StyledDivider>
  );
};

export default Divider;
