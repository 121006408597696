import React, { FC, useMemo } from "react";
import { prepareWeatherImpactData } from "./helpers";
import { PieChart } from "@ehabitation/ui";
import { ViolationData } from "@ehabitation/ts-utils/browser";

interface WeatherImpactsChartProps {
  maxDelayDays?: number | null;
  violationData?: ViolationData | null;
  className?: string;
  settings?: any;
}

const WeatherImpactsChart: FC<WeatherImpactsChartProps> = ({
  maxDelayDays,
  violationData,
  className,
  settings,
}) => {
  const data = useMemo(() => {
    if (!maxDelayDays || maxDelayDays === 0 || !violationData) return [];
    return prepareWeatherImpactData(maxDelayDays, violationData);
  }, [maxDelayDays, violationData]);
  return (
    <PieChart
      id="risk-weather-impact"
      data={data}
      className={className}
      categoryKey="weatherType"
      noChartMessage="There are no weather types causing delays for this plan."
      settings={settings}
    />
  );
};

export default WeatherImpactsChart;
