import { useCallback } from "react";
import { batch } from "react-redux";
import { ITask } from "@ehabitation/ts-utils/browser";
import { useAppDispatch } from "store";
import _ from "lodash";
import { batchUpdateTask } from "store/tasks";

export const useInitPlanner = () => {
  const dispatch = useAppDispatch();

  const handleGraphSafetyEndChange = useCallback(
    async (tasks: ITask[], safeEnd: Date, accuracy: number) => {
      const selectedTask = tasks[0];
      const modelTask: any = { ...selectedTask };
      modelTask.end = safeEnd;
      modelTask.newAccuracy = accuracy;

      const dependantTasks = tasks.slice(1);

      const dependanttaskObj = Object.assign(
        {},
        ...dependantTasks.map((task: ITask) => ({
          [task.id!]: { ...task },
        }))
      );

      if (dependantTasks.length === 0) return;

      batch(() => {
        dispatch(batchUpdateTask(dependanttaskObj));
      });
    },
    [dispatch]
  );

  return {
    handleGraphSafetyEndChange,
  };
};
