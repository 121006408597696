import { IOrganisationProjects } from "types";
import {
  UserRole,
  transformProjectDoc,
  CollectionType,
} from "@ehabitation/ts-utils/browser";
import { db } from "firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

interface ISubscribeToProjects {
  role: UserRole;
  orgId: string;
  divisionId: string;
  projectId: string;
  availableDivisions: string[];
  dispatcher: (projects: IOrganisationProjects) => void;
  setReady: (bool: boolean) => void;
}
export const subscribeToProjects = ({
  role,
  orgId,
  divisionId,
  projectId,
  availableDivisions,
  dispatcher,
  setReady,
}: ISubscribeToProjects) => {
  if (!availableDivisions || !availableDivisions.length) return setReady(true);

  const queryBase = determineQueryBase(role, orgId, divisionId, projectId);

  const unsubscribe = onSnapshot(
    queryBase,
    (docs) => {
      setReady(true);

      const organisationProjects: IOrganisationProjects = {};

      docs.forEach((doc) => {
        const project = transformProjectDoc(doc.id, doc.data());

        if (!availableDivisions.includes(project.divisionId)) {
          return;
        }

        organisationProjects[doc.id] = project;
      });

      dispatcher(organisationProjects);
    },
    (error: Error) => {
      console.log("projects subscription failed:", {
        orgId,
        divisionId,
        projectId,
        role,
      });
      console.error(error);
    }
  );

  return unsubscribe;
};

export const fetchProject = async (projectId: string) => {
  const projectDoc = await getDoc(doc(db, CollectionType.Projects, projectId));
  if (projectDoc.exists()) {
    return transformProjectDoc(projectDoc.id, projectDoc.data());
  } else {
    console.log("No such project document!");
    return null;
  }
};

const determineQueryBase = (
  role: UserRole,
  orgId: string,
  divisionId: string,
  projectId: string
) => {
  switch (role) {
    case UserRole.orgAdmin: {
      return query(
        collection(db, CollectionType.Projects),
        where("orgId", "==", orgId)
      );
    }
    case UserRole.divisionAdmin: {
      return query(
        collection(db, CollectionType.Projects),
        where("divisionId", "==", divisionId)
      );
    }
    case UserRole.guest: {
      return query(
        collection(db, CollectionType.Projects),
        where("orgId", "==", orgId),
        where("visibility", "==", UserRole.guest)
      );
    }
    default: {
      return query(
        collection(db, CollectionType.Projects),
        where("__name__", "==", projectId)
      );
    }
  }
};
