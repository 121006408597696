import { StyledErrorMessage } from "elements/Modals";
import { StyledDownloadSlide } from "./styles";
import { useDownloadSiteStatsReport } from "./useDownloadStatsReport";
import { MonochromeButton, Spinner } from "@ehabitation/ui";
import { ISite } from "@ehabitation/ts-utils/browser";
import { BiDownload } from "react-icons/bi";

interface DownloadSlideProps {
  handleCloseModal: () => void;
  site: ISite;
  projectId: string;
}

const SiteWeatherDownloadSlide = ({
  handleCloseModal,
  site,
  projectId,
}: DownloadSlideProps) => {
  const {
    downloadReport,
    generateReport,
    reportGenerating,
    reportDownloading,
    error,
  } = useDownloadSiteStatsReport({
    site,
    projectId,
  });

  return (
    <StyledDownloadSlide>
      <div className="copy">
        <h2 data-testid="WB-report-slide-title">Site statistics report.</h2>

        <p>
          EHAB's Site Statistics report provides key stats and graphs related to
          the historic weather conditions of the current site.
        </p>
        <p>
          <i>Generating new stats report may take a minute or two.</i>
        </p>
        <span>
          <MonochromeButton
            level="primary"
            onClick={async () => {
              await generateReport();
            }}
            width="md"
            className="inline-block"
          >
            Generate
          </MonochromeButton>
          <MonochromeButton
            level="secondary"
            disabled={
              !site.historicalStatsFile ||
              site.historicalStatsFile == "generating" ||
              reportDownloading
            }
            onClick={downloadReport}
            className=" inline-block mr-4"
          >
            Download<span>&nbsp;</span>
            {site.historicalStatsFile &&
              site.historicalStatsFile == "generating" && <Spinner />}
            {(!site.historicalStatsFile ||
              (site.historicalStatsFile &&
                site.historicalStatsFile != "generating")) && <BiDownload />}
          </MonochromeButton>
        </span>
        <StyledErrorMessage className="error">{error}</StyledErrorMessage>
      </div>
    </StyledDownloadSlide>
  );
};

export default SiteWeatherDownloadSlide;
