import { AppThunk } from "store";
import {
  selectActiveSiteId,
  selectUserClaims,
  selectUserId,
  selectUserState,
} from "store/auth";
import { selectCurrentPlanId, selectCurrentSite } from "store/project";
import { setFileIsLoading, setHasFileError, setTasksUpdating } from "store/ui";
import { auth, db } from "firebaseConfig";
import { signOut } from "firebase/auth";
import { getRiskMatrixDoc, saveTasksToDB } from "helpers";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import {
  batchUpdateTask,
  selectAllTasksWithResultsEntries,
  selectTasksSnapshotAsArray,
  allTasksSelector,
} from "store/tasks";
import {
  triggerHistoricalWeatherDataDownload,
  triggerTaskDataDownload,
} from "./helpers";
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  CollectionType,
  ITaskCategory,
  SimulationTrigger,
  SimulationType,
  WeatherKey,
  getRiskMatrixHashes,
} from "@ehabitation/ts-utils/browser";
import { isEqual } from "lodash";
import { ITaskObj } from "types";
import _ from "lodash";

export const logout: AppThunk = () => async () => {
  try {
    await signOut(auth);

    window.location.pathname = "/login"; // force reload to reset all state
  } catch (error) {
    console.error(error);
  }
};

export const downloadHistoricalWeatherData: AppThunk =
  (siteId: string) => async (dispatch) => {
    dispatch(setFileIsLoading(true));

    // THESE CAN BE CONFIGURED TO SET DESIRED RANGE (POSIX);
    const start = 0;
    const end = 9999999999;

    // CAN BE CONFIGURED TO SET DESIRED WEATHER ATTR.
    const types = "temp,wind,rain";

    try {
      await triggerHistoricalWeatherDataDownload({
        start,
        end,
        types,
        site: siteId,
      });
      logExportEvent({
        siteId,
        exportType: ExportTypes.HistoricalWeather,
      });
    } catch (error) {
      console.error(error);
      dispatch(setHasFileError(true));
    } finally {
      dispatch(setFileIsLoading(false));
    }
  };

export const downloadTaskExportData: AppThunk =
  (siteId: string, planId: string) => async (dispatch) => {
    dispatch(setFileIsLoading(true));

    try {
      await triggerTaskDataDownload(siteId, planId);
      logExportEvent({
        siteId: siteId,
        planId: planId,
        exportType: ExportTypes.TaskActivity,
      });
    } catch (error) {
      console.error(error);
      dispatch(setHasFileError(true));
    } finally {
      dispatch(setFileIsLoading(false));
    }
  };

export const rerunModel: AppThunk = () => async (dispatch, getState) => {
  const state = getState();

  const siteId = selectActiveSiteId(state);
  const planId = selectCurrentPlanId(state);
  const userId = selectUserId(state);

  const simulation = {
    plan: planId,
    site: siteId,
    createdAt: Timestamp.now(),
    version: 2,
    status: "pending",
    trigger: {
      type: SimulationType.User,
      userId,
    } as SimulationTrigger,
  };

  if (siteId && planId && planId !== siteId) {
    const simRef = await addDoc(
      collection(db, `plans/${planId}/simulations`),
      simulation
    );
  } else {
    console.error(`Invalid ids: site ${siteId}, plan ${planId}`);
  }
};

const clearWeatherKeys = (empty?: boolean) => {
  const clearKeys = {} as any;
  for (const key in WeatherKey) {
    clearKeys[key as WeatherKey] = empty ? undefined : deleteField();
  }
  return clearKeys;
};

export const resetThresholds: AppThunk =
  (planId: string) => async (dispatch, getState) => {
    const state = getState();
    const siteId = selectActiveSiteId(state);
    if (siteId && planId && planId !== siteId) {
      dispatch(setTasksUpdating(true));
      try {
        const rmCategoryNames = new Set<string>();
        const deletedCategoryNames = new Set<string>();
        const updatedCategoryNames = new Set<string>();

        const projectId = selectCurrentSite(state)!.project!;
        const projectRM = await getRiskMatrixDoc(
          projectId,
          CollectionType.Projects
        );

        const projectRMCategoriesCollection = (
          await getDocs(
            query(
              collection(
                db,
                CollectionType.RiskMatrix,
                projectRM.id,
                CollectionType.Categories
              )
            )
          )
        ).docs;

        const projectCategoriesNameMap = projectRMCategoriesCollection.reduce(
          (acc, doc) => {
            const category = { id: doc.id, ...doc.data() } as ITaskCategory;
            rmCategoryNames.add(category.name);
            acc.set(category.name, category);
            return acc;
          },
          new Map<string, ITaskCategory>()
        );

        const planRiskMatrixDoc = await getRiskMatrixDoc(
          planId,
          CollectionType.Plans
        );
        const planRMCategoriesCollection = collection(
          db,
          CollectionType.RiskMatrix,
          planRiskMatrixDoc.id,
          CollectionType.Categories
        );
        const planRiskMatrixCategoryDocs = (
          await getDocs(query(planRMCategoriesCollection))
        ).docs;

        const planCategoriesNameMap = planRiskMatrixCategoryDocs.reduce(
          (acc, doc) => {
            const category = { id: doc.id, ...doc.data() } as ITaskCategory;
            rmCategoryNames.add(category.name);
            acc.set(category.name, category);
            return acc;
          },
          new Map<string, ITaskCategory>()
        );

        // Iterate over all existing categories in both risk matrices
        const zipRiskMatrixBatches = _.chunk(
          Array.from(rmCategoryNames),
          300
        ).map((chunk) => {
          const batch = writeBatch(db);
          chunk.forEach((categoryName) => {
            const isPlanCategory = planCategoriesNameMap.has(categoryName);
            const isProjectCategory =
              projectCategoriesNameMap.has(categoryName);

            // If category name in project risk matrix but not in plan risk matrix, add it
            if (!isPlanCategory && isProjectCategory) {
              const { id, ...category } =
                projectCategoriesNameMap.get(categoryName)!;
              batch.set(doc(planRMCategoriesCollection, id), category, {
                merge: true,
              });
            }

            // If category name in plan risk matrix but not in project risk matrix, delete it
            if (isPlanCategory && !isProjectCategory) {
              const { id, name } = planCategoriesNameMap.get(categoryName)!;
              batch.delete(doc(planRMCategoriesCollection, id));
              deletedCategoryNames.add(name);
            }

            // If category name in both, update it (in the future this will check if thresholds match)
            if (isPlanCategory && isProjectCategory) {
              const planCategory = planCategoriesNameMap.get(categoryName)!;
              const projCategory = projectCategoriesNameMap.get(categoryName)!;
              const newCategory = {
                ...planCategory,
                thresholds: projCategory.thresholds,
              };

              // We don't need to update thresholds if they are equal.
              if (!isEqual(planCategory.thresholds, projCategory.thresholds)) {
                batch.set(
                  doc(planRMCategoriesCollection, planCategory.id),
                  newCategory
                );
                updatedCategoryNames.add(newCategory.name);

                // mutate plan thresholds so that the hashes are correct later.
                planCategory.thresholds = projCategory.thresholds;
              }
            }
          });

          return batch;
        });

        const tasks = selectAllTasksWithResultsEntries(state);
        const updatedTasks: ITaskObj = {};

        const taskChangeBatches = _.chunk(tasks, 300).map((taskChunk) => {
          const batch = writeBatch(db);

          taskChunk.forEach(({ simulation, ...task }) => {
            const taskCategory = task.taskCategory?.selectedName!;

            if (
              !updatedCategoryNames.has(taskCategory) &&
              !deletedCategoryNames.has(taskCategory)
            )
              return;

            const updatedCategory = projectCategoriesNameMap.get(task.taskType);
            let updatedTask = { ...task } as any;
            if (deletedCategoryNames.has(taskCategory)) {
              // Delete the category off the task
              updatedTask.taskType = "";
              updatedTask.taskCategory = {
                ...task.taskCategory,
                selectedName: "",
                selectedId: "",
                selectedSource: "deletedCategory",
                categoryError: {
                  hasError: true,
                  message: "Category deleted off Risk Matrix",
                },
              };
              updatedTasks[task.id] = {
                ...updatedTask,
                ...clearWeatherKeys(true),
              };
              updatedTask = {
                ...updatedTask,
                ...clearWeatherKeys(false),
              };
            } else if (updatedCategoryNames.has(task.taskType)) {
              // Update the category on the task
              updatedTask.taskType = updatedCategory?.name;
              updatedTask.taskCategory = {
                ...task.taskCategory,
                selectedName: updatedCategory?.name,
                selectedId: updatedCategory?.id,
                categoryError: {
                  hasError: false,
                  message: "",
                },
              };
              updatedTasks[task.id] = {
                ...updatedTask,
                ...clearWeatherKeys(true),
                ...updatedCategory?.thresholds,
              };
              updatedTask = {
                ...updatedTask,
                ...clearWeatherKeys(false),
                ...updatedCategory?.thresholds,
              };
            }

            // Trim the deleted categories off the taskCategory suggestions
            const updatedSuggestions = { ...task.taskCategory?.suggestions };
            if (updatedSuggestions) {
              for (const suggestionKey of Object.keys(updatedSuggestions)) {
                updatedSuggestions[suggestionKey].filter((suggestion) => {
                  return !deletedCategoryNames.has(suggestion.name);
                });
              }
              updatedTask.taskCategory = {
                ...updatedTask.taskCategory,
                suggestions: updatedSuggestions,
              };
            }
            batch.set(
              doc(
                db,
                CollectionType.Plans,
                planId,
                CollectionType.Tasks,
                task.id
              ),
              updatedTask,
              { merge: true }
            );
          });
          return batch;
        });

        const {
          rmDocMajorHash: projectMajorHash,
          rmDocMinorHash: projectMinorHash,
        } = getRiskMatrixHashes(Array.from(projectCategoriesNameMap.values()));
        const { rmDocMajorHash: planMajorHash, rmDocMinorHash: planMinorHash } =
          getRiskMatrixHashes(Array.from(planCategoriesNameMap.values()));

        // Make batched updates to the plan risk matrix
        await Promise.all(zipRiskMatrixBatches.map((batch) => batch.commit()));
        // Update the last updated field of the plan risk matrix
        await updateDoc(planRiskMatrixDoc.ref, {
          minorHash: planMinorHash,
          majorHash: planMajorHash,
          lastUpdated: Timestamp.now(),
        });
        await updateDoc(projectRM.ref, {
          minorHash: projectMinorHash,
          majorHash: projectMajorHash,
          lastUpdated: Timestamp.now(),
        });

        // Make batched updates to the plan tasks
        await Promise.all(taskChangeBatches.map((batch) => batch.commit()));

        dispatch(batchUpdateTask(updatedTasks));
        dispatch(saveCurrentTasksState(planId));
      } catch (error) {
        console.error(error);
      }
      dispatch(setTasksUpdating(false));
    }
  };

export const saveCurrentTasksState: AppThunk =
  (planId: string) => async (dispatch, getState) => {
    const state = getState();

    dispatch(setTasksUpdating(true));
    const tasksSnapshot = selectTasksSnapshotAsArray(state);
    const tasks = Object.values(allTasksSelector(state) || {});
    try {
      await saveTasksToDB(tasks, tasksSnapshot, planId);
    } finally {
      dispatch(setTasksUpdating(false));
    }
  };

export const logCopyKeyState: AppThunk = () => async (dispatch, getState) => {
  const state = getState();

  const keyState = {
    openOrganisationId: selectCurrentSite(state)?.orgId,
    openDivisionId: selectCurrentSite(state)?.divisionId,
    activeSiteId: selectActiveSiteId(state),
    openPlanId: selectCurrentPlanId(state),
    userState: selectUserState(state),
    authClaims: selectUserClaims(state),
  };

  console.log("********************");
  console.log(keyState);
  console.log("********************");

  navigator.clipboard.writeText(JSON.stringify(keyState));
};
