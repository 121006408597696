import { collection, doc, onSnapshot } from "firebase/firestore";
import { UserRole } from "@ehabitation/ts-utils/browser";
import { db } from "firebaseConfig";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";
import { selectUserClaims } from "store/auth";

type Organisation = {
  name: string;
};

export const useOrganisation = () => {
  const userClaims = useAppSelector(selectUserClaims);
  const { organisation: orgId, role } = userClaims!;
  const [organisation, setOrganisation] = useState<Organisation | null>(null);

  useEffect(() => {
    if (orgId) {
      const ref = doc(collection(db, "organisations"), orgId);
      const unSubscribe = onSnapshot(
        ref,
        (snap) => {
          if (snap.exists()) {
            const data = snap.data() as Organisation;
            setOrganisation(data);
          } else {
            setOrganisation(null);
          }
        },
        (error) => console.log("error subscribing to org: ", error)
      );
      return () => unSubscribe();
    } else if (role === UserRole.superAdmin) {
      setOrganisation({ name: "Super Admin" });
    }
  }, [orgId, role]);
  return organisation;
};
