//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
//Types
import { ProjectManagementTabOption } from "types";

//State
export interface IProjectManagementState {
  selectedTab: ProjectManagementTabOption;
  selectedProject: string | null;
  projectManagementError: string;
  isLoading: boolean;
}

export const initialState: IProjectManagementState = {
  selectedTab: ProjectManagementTabOption.Projects,
  selectedProject: null,
  isLoading: false,
  projectManagementError: "",
};

//Slice
export const projectManagementSlice = createSlice({
  name: "projectManagement",
  initialState,
  reducers: {
    setSelectedProjectTab: (
      state,
      action: PayloadAction<ProjectManagementTabOption>
    ) => {
      state.selectedTab = action.payload;
    },

    setSelectedProject: (state, action: PayloadAction<string | null>) => {
      state.selectedProject = action.payload;
    },

    setProjectManagementError: (state, action: PayloadAction<string>) => {
      state.projectManagementError = action.payload;
    },

    setProjectManagementIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

//Actions
export const {
  setProjectManagementError,
  setProjectManagementIsLoading,
  setSelectedProjectTab,
  setSelectedProject,
} = projectManagementSlice.actions;

//Selectors
export const selectProjectManagementIsLoading = (state: RootState) =>
  state.projectManagement.isLoading;

export const selectProjectManagementError = (state: RootState) =>
  state.projectManagement.projectManagementError;

export const selectProjectManagementTab = (state: RootState) =>
  state.projectManagement.selectedTab;

export const selectSelectedProject = (state: RootState) =>
  state.projectManagement.selectedProject;

//Reducer
export default projectManagementSlice.reducer;
