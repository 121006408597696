import moment from "moment";

interface ICalculateDiffBetweenDatesParams {
  taskProposedEndDate: moment.Moment;
  siteEndDate: moment.Moment;
}
const calculateDiffBetweenDates = ({
  taskProposedEndDate,
  siteEndDate,
}: ICalculateDiffBetweenDatesParams) => {
  //Neg integer means that the dealine for the project has not been crossed
  return Math.ceil(
    moment.duration(taskProposedEndDate.diff(siteEndDate)).asDays()
  );
};

export default calculateDiffBetweenDates;
