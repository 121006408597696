import { batch } from "react-redux";
import {
  setAllSites,
  setProject,
  setCurrentSite,
  selectCurrentSite,
} from "store/project";
import { AppThunk } from "store";
import { fetchProject, fetchProjectSites } from "helpers";
import { selectActiveSiteId, selectUserClaims } from "store/auth";
import { setActiveSiteId } from "Pages/sites/thunks";

export const initProject: AppThunk =
  (projectId: string) => async (dispatch, getState) => {
    const state = getState();

    const activeSiteId = selectActiveSiteId(state);
    const currentSiteId = selectCurrentSite(state)?.id;
    const claims = selectUserClaims(state);

    const project = await fetchProject(projectId); //fetch the project the user is currently asscociated with
    const projectSites = await fetchProjectSites(projectId, claims); // fetch project sites

    if (!project || !projectSites || !projectSites.length) {
      console.error(new Error("Project and/or project sites not found"));
      return (window.location.pathname = "/"); // TODO: show error user to explain project data not found
    }

    const primarySite =
      projectSites?.find((site) => site.id === activeSiteId) || projectSites[0]; //From the array of all sites, select the site that the user is currently asscociated with

    if (primarySite.id !== currentSiteId) {
      if (currentSiteId) {
        // clear previously loaded site state!
        window.location.reload();
      } else {
        batch(() => {
          dispatch(setProject(project));
          dispatch(setAllSites(projectSites));
          dispatch(setCurrentSite(primarySite));
        });
      }
    }
    if (primarySite.id !== activeSiteId) {
      dispatch(setActiveSiteId(primarySite.id));
    }
  };
