import { FC, useEffect, useState } from "react";
import {
  CollectionType,
  IPlan,
  ISite,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import moment from "moment";
import { FaCalendarAlt } from "react-icons/fa";
import { Spinner } from "@ehabitation/ui";
import { PinSiteButton } from "./PinSiteButton";
import { SiteNotificationButton } from "./SiteNotificationButton";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firebaseConfig";

interface SiteRowProps {
  site: ISite;
  isSelectedSite: boolean;
  setSiteId: (siteId: string) => void;
  isPinned?: boolean;
  notificationEnabled?: boolean;
  notificationExplainer?: string;
}

const useSiteDates = (site: ISite) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { mainPlanId, startDate: siteStart, endDate: siteEnd } = site;

  useEffect(() => {
    if (mainPlanId) {
      getDoc(doc(db, CollectionType.Plans, mainPlanId)).then((plan) => {
        const { start, end } = transformPlanDoc(plan.id, plan.data()) as IPlan;
        if (start && end) {
          setStartDate(start);
          setEndDate(end);
        } else {
          setStartDate(site.startDate);
          setEndDate(site.endDate);
        }
      });
    } else {
      setStartDate(site.startDate);
      setEndDate(site.endDate);
    }
  }, [mainPlanId, siteStart, siteEnd]);

  return {
    startDate,
    endDate,
  };
};

const SiteRow: FC<SiteRowProps> = ({
  site,
  isSelectedSite,
  setSiteId,
  isPinned,
  notificationEnabled,
  notificationExplainer,
}) => {
  const isSiteActive = site.status === "open";

  const rightButton = (
    <PinSiteButton
      isPinned={!!isPinned}
      isSelectedSite={isSelectedSite}
      siteId={site.id}
    />
  );

  const { startDate, endDate } = useSiteDates(site);

  return (
    <h3
      role="button"
      className={`flex flex-row justify-between items-center gap-2 text-gray-600 cursor-pointer py-0 px-4 border-b border-gray-300 text-2xl ${
        isSelectedSite ? "cursor-default" : "cursor-pointer hover:brightness-95"
      } ${isSelectedSite ? "bg-[#1CEAA0]" : "bg-gray-50"}`}
      onClick={() => setSiteId(site.id)}
    >
      <span className="md:flex gap-4 items-baseline inline-block overflow-hidden text-ellipsis whitespace-nowrap py-2">
        {site.name}
        {startDate ? (
          <div
            aria-label="site start/end dates"
            className="text-gray-500 italic flex items-baseline gap-1"
          >
            <>
              <FaCalendarAlt />
              {startDate ? moment(startDate).format("D/M/YY") : ""} -{" "}
              {endDate ? moment(endDate).format("D/M/YY") : ""}
            </>
          </div>
        ) : null}
      </span>
      <div className="flex items-center gap-1 text-gray-500">
        {isSiteActive ? (
          <>
            {rightButton}
            {typeof notificationEnabled === "boolean" ? (
              <SiteNotificationButton
                isEnabled={notificationEnabled}
                site={site}
                isSelectedSite={isSelectedSite}
                tooltip={notificationExplainer}
              />
            ) : null}
          </>
        ) : (
          <>
            <Spinner size="xSmall" />
            Building...
          </>
        )}
      </div>
    </h3>
  );
};

export default SiteRow;
