import styled from "styled-components";
import {
  flex,
  planControlsHeight,
  taskListWidth,
  fontSize,
  grays,
  spacing,
} from "styles";

export const StyledPlanControls = styled.div`
  ${flex("row", "start", "center")};
  width: 100%;
  height: ${planControlsHeight};

  .above-task-list {
    ${flex("row", "space-between", "center")};
    gap: ${spacing.s};
    width: ${taskListWidth};
    min-width: ${taskListWidth};
    margin-right: 2rem;
  }

  .above-gantt-chart {
    flex-grow: 1;
    ${flex("row", "space-between", "center")};
    overflow: visible;
  }
`;

export const StyledCustomAlert = styled.div`
  ${flex("column", "center", "center")};
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 2000;

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.32);
    z-index: 2002;
  }

  .content {
    width: 30%;
    z-index: 2003;
    background: white;
    border-radius: 4px;
    padding: 20px 23px;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
      0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);

    .message {
      height: 50px;
      margin-bottom: 25px;

      > p {
        color: black;
      }
    }

    .buttons {
      ${flex("row", "space-between", "center")};
      height: 50px;

      > button {
        padding: 5px 12px;
        font-size: ${fontSize.xs};
        text-transform: uppercase;
        font-weight: 700;
        background: ${grays.veryLight};
        cursor: pointer;

        @media (min-width: 340px) {
          padding: 5px 15px;
        }

        @media (min-width: 360px) {
          font-size: ${fontSize.s};
        }
      }
    }
  }
`;
