import React from "react";
import { StyledChartContainer, StyledNoGraphMessage } from "./styles";
import { useHistogramChart } from "./useHistogramChart";

const Histogram = () => {
  const { canShowChart } = useHistogramChart();

  if (canShowChart) return <StyledChartContainer id="histogramdiv" />;

  return (
    <StyledNoGraphMessage>
      <p>There is no simulation data for this task.</p>
    </StyledNoGraphMessage>
  );
};

export default Histogram;
