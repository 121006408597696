import { FC } from "react";
import { StyledHeading } from "./styles";

interface IProps {
  text: string;
}

const Heading: FC<IProps> = ({ text }) => {
  return (
    <StyledHeading>
      <h2 className="text">{text}</h2>
    </StyledHeading>
  );
};

export default Heading;
