import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { ICustomClaims } from "@ehabitation/ts-utils/browser";
import { selectUserClaims, selectRole } from "store/auth";
import { setDivisionMap } from "store/userManagement";
import { IDivisionMap } from "types";
import { subscribeToDivisions } from "helpers";

// Fetch a map of all divisions within the given organisation of the orgAdmin
// or a single division for other users
export const useSubscribeToDivisions = () => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);
  const { organisation, division } = useAppSelector(
    selectUserClaims
  ) as ICustomClaims;

  const [isReady, setReady] = useState(false);

  const dispatcher = useCallback(
    (divisionMap: IDivisionMap) => {
      dispatch(setDivisionMap(divisionMap));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!role) return;
    const unsubscribe = subscribeToDivisions({
      role,
      orgId: organisation || "",
      divisionId: division || "",
      dispatcher,
      setReady,
    });

    return unsubscribe;
  }, [role, organisation, division, dispatcher]);

  return isReady;
};
