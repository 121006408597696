import {
  ITask,
  ThresholdsSchema,
  WeatherKey,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";
import { isOldFormatId } from "Pages/sites/constants";
import { ITaskObj } from "types";

const widenThresholds = (
  thresholds: WeatherThresholds,
  newThresholds: WeatherThresholds
) => {
  for (const key of Object.keys(ThresholdsSchema.parse(newThresholds))) {
    const safeKey = key as WeatherKey;
    if (typeof newThresholds[safeKey] !== "undefined") {
      if (typeof thresholds[safeKey] === "undefined") {
        thresholds[safeKey] = newThresholds[safeKey];
      } else {
        const thresholdIsMin = safeKey.includes("min");
        const newValueIsExtreme =
          typeof thresholds[safeKey] !== "number" ||
          (thresholdIsMin
            ? newThresholds[safeKey]! > thresholds[safeKey]!
            : newThresholds[safeKey]! < thresholds[safeKey]!);
        if (newValueIsExtreme) {
          thresholds[safeKey] = newThresholds[safeKey];
        }
      }
    }
  }
};

class AggregateWbs {
  totalAccuracy = 0;
  countAccuracy = 0;
  aggregatedThresholds: WeatherThresholds = {} as WeatherThresholds;

  aggTask(task: ITask, initialThresholds: WeatherThresholds) {
    this.aggregatedThresholds = {
      minTemp: initialThresholds.minTemp,
      maxTemp: initialThresholds.maxTemp,
      dailyRainAcc: initialThresholds.dailyRainAcc,
      hourlyRainAcc: initialThresholds.hourlyRainAcc,
      wind: initialThresholds.wind,
      snowfall: initialThresholds.snowfall,
      windGusts: initialThresholds.windGusts,
      waveHeight: initialThresholds.waveHeight,
      snowfall24Hour: initialThresholds.snowfall24Hour,
      visibility: initialThresholds.visibility,
    };
    if (!task.milestone || !task.levelOfEffort) {
      this.aggAccuracy(task.accuracy);
      this.thresholds(task);
    }
  }

  private aggAccuracy(accuracy: number) {
    this.totalAccuracy += accuracy;
    this.countAccuracy++;
  }

  private thresholds(task: ITask) {
    // DH TODO: Need to aggregate from the risk matrix not task.
    // Find the worst thresholds
    if (!task || task.taskType === "No Weather Impact") return;
    // Widen the thresholds to be worst case
    widenThresholds(this.aggregatedThresholds, task);
  }

  rollup(): { accuracy: number } {
    return {
      accuracy:
        this.countAccuracy > 0 ? this.totalAccuracy / this.countAccuracy : 100,
      ...this.aggregatedThresholds,
    };
  }
}

const copyNewTask = (
  current: ITask,
  newData: { accuracy: number }
): ITask | null => {
  return { ...current, ...newData }; // copy
};

const traverseWbsForUpdates = (
  allTasks: ITaskObj,
  _updatedTasks: ITaskObj,
  root: ITask
): ITaskObj => {
  let updatedTasks = { ..._updatedTasks } as ITaskObj; // May not need to copy here, lets get it working though
  if (root?.WBS && root?.subtasks) {
    const agg = new AggregateWbs();
    for (const subtaskId of root.subtasks) {
      // for each subtask
      const subtask = updatedTasks[subtaskId] ?? allTasks[subtaskId];
      if (subtask) {
        if (subtask.WBS) {
          // if WBS, aggregate WBS
          const branchesUpdated = traverseWbsForUpdates(
            allTasks,
            updatedTasks,
            subtask
          );
          updatedTasks = { ...updatedTasks, ...branchesUpdated };
          const updatedWbs = branchesUpdated[subtaskId];
          if (updatedWbs) agg.aggTask(updatedWbs, root);
        } else {
          agg.aggTask(subtask, root);
        }
      } else {
        console.warn("subtask not found", subtaskId);
      }
    }

    const newTask = copyNewTask(allTasks[root.id], agg.rollup());
    if (newTask) updatedTasks[newTask.id] = newTask;
  }

  return updatedTasks;
};

export const getRoots = (
  allTasks: ITaskObj,
  modifiedTasks?: ITaskObj | null
): Set<ITask> => {
  const roots: Set<ITask> = new Set();
  if (modifiedTasks) {
    // only modified roots
    for (const modifiedTask of Object.values(modifiedTasks)) {
      let parentId = modifiedTask?.internalParentId;
      let parent: ITask;

      while (parentId) {
        parent = allTasks[parentId];
        parentId = parent?.internalParentId;
      }

      roots.add(parent!);
    }
  } else {
    // all roots
    Object.values(allTasks).forEach((t) => {
      if (!t.internalParentId) roots.add(t);
    });
  }

  return roots;
};

export const updateWbsAggregations = (
  allTasks: ITaskObj,
  modifiedTasks?: ITaskObj | null
) => {
  let updatedRootWbs = {} as ITaskObj;
  const roots = getRoots(allTasks, modifiedTasks);

  for (const rootTask of roots) {
    const updatedTasks = traverseWbsForUpdates(
      allTasks,
      modifiedTasks ?? ({} as ITaskObj),
      rootTask
    );
    updatedRootWbs = { ...updatedRootWbs, ...updatedTasks };
  }

  return updatedRootWbs;
};

const applyNewPlanIdToTask = (newPlanId: string, taskRaw: ITask) => {
  const calendarId =
    taskRaw.calendarId && applyNewPlanId(taskRaw.calendarId, newPlanId);
  const internalParentId =
    taskRaw.internalParentId &&
    applyNewPlanId(taskRaw.internalParentId, newPlanId);

  const relationship = taskRaw.relationship && {
    FF:
      (taskRaw.relationship.FF.length &&
        taskRaw.relationship.FF.map((rel: string) =>
          applyNewPlanId(rel, newPlanId)
        )) ||
      [],
    FS:
      (taskRaw.relationship.FS.length &&
        taskRaw.relationship.FS.map((rel: string) =>
          applyNewPlanId(rel, newPlanId)
        )) ||
      [],
    SF:
      (taskRaw.relationship.SF.length &&
        taskRaw.relationship.SF.map((rel: string) =>
          applyNewPlanId(rel, newPlanId)
        )) ||
      [],
    SS:
      (taskRaw.relationship.SS.length &&
        taskRaw.relationship.SS.map((rel: string) =>
          applyNewPlanId(rel, newPlanId)
        )) ||
      [],
  };

  const newTask = {
    ...taskRaw,
    plan: newPlanId,
    predecessors:
      (taskRaw.predecessors &&
        taskRaw.predecessors.length &&
        taskRaw.predecessors.map((predecessor: string) =>
          applyNewPlanId(predecessor, newPlanId)
        )) ||
      [],
    successors:
      (taskRaw.successors &&
        taskRaw.successors.length &&
        taskRaw.successors.map((successor: string) =>
          applyNewPlanId(successor, newPlanId)
        )) ||
      [],
    subtasks:
      (taskRaw.subtasks &&
        taskRaw.subtasks.length &&
        taskRaw.subtasks.map((subTask: string) =>
          applyNewPlanId(subTask, newPlanId)
        )) ||
      [],
    id: applyNewPlanId(taskRaw.id, newPlanId),
  };
  if (internalParentId) newTask.internalParentId = internalParentId;
  if (relationship) newTask.relationship = relationship;
  if (calendarId) newTask.calendarId = calendarId;

  return newTask;
};

const applyNewPlanId = (oldId: string, planId: string) =>
  isOldFormatId(oldId) ? oldId : planId + "$$" + oldId.split("$$")[1];

export const applyNewPlanIdToTasks = async (
  newPlanId: string,
  tasks: ITask[]
) => {
  const convertedTasks: ITask[] = [];
  await Promise.all(
    tasks.map(async (task, i) => {
      if (i % 500 === 0) {
        await yieldMainThread();
      }
      convertedTasks.push(applyNewPlanIdToTask(newPlanId, task));
    })
  );
  return convertedTasks;
};

const yieldMainThread = (delay = 0) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};
