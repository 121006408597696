import { isGuest, IPlan, ISite } from "@ehabitation/ts-utils/browser";
import { Tooltip } from "@material-ui/core";
import { MapSite } from "Pages/sites/components/MapSite";
import { FC, useEffect, useState } from "react";
import { BiChevronDown, BiChevronRight, BiLinkExternal } from "react-icons/bi";
import { FaSpinner } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { selectRole } from "store/auth";

interface SidebarContentProps {
  site: ISite;
  selectedJourney: string | null;
  setSelectedJourney: (journey: string | null) => void;
  simulationAlert: string;
  simulationInProgress: boolean;
  handleOpenCategorise: () => void;
  plan: IPlan | undefined;
}

export const SidebarContent: FC<SidebarContentProps> = ({
  site,
  selectedJourney,
  setSelectedJourney,
  simulationAlert,
  simulationInProgress,
  handleOpenCategorise,
  plan,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(selectedJourney !== null);
  const userRole = useAppSelector(selectRole);
  const userIsGuest = isGuest(userRole!);
  const isSiteActive = site?.status === "open";
  const canImportPlan = isSiteActive && site?.project;


  useEffect(() => {
    setIsCollapsed(selectedJourney !== null);
  }, [selectedJourney]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleNavClick = () => {
    setSelectedJourney(null);
  };

  return (
    <>
      <div>
        <span
          className="text-2xl font-semibold cursor-pointer flex items-center pb-6"
          onClick={toggleCollapse}
        >
          {isCollapsed ? <BiChevronRight /> : <BiChevronDown />}
          All modules
        </span>
        {!isCollapsed && (
          <ul>
            {/* Group 1: Settings */}
            <li className="relative border-l-2 border-grey-500 pl-4">
              <NavLink
                to={"settings/thresholds"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Set thresholds</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-grey-500 pl-4">
              {!userIsGuest && canImportPlan ? (
                <NavLink
                  to={"import-plan"}
                  className={({ isActive }) =>
                    `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                      isActive
                        ? "bg-[#1CEAA0] text-gray-600"
                        : "hover:bg-[#cfebe0]"
                    } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                  }
                  onClick={handleNavClick}
                >
                  <span>Import a plan</span>
                </NavLink>
              ) : (
                <Tooltip
                  arrow
                  title={
                    <span className="text-none font-boldpx-2 py-4">
                      {isSiteActive
                        ? "Forecast only sites do not support plans."
                        : "Building site weather model..."}
                    </span>
                  }
                >
                  <span className="flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 opacity-50 cursor-not-allowed">
                    Import a plan
                  </span>
                </Tooltip>
              )}
            </li>

            <li className="relative pt-2 border-l-2 border-grey-500 pl-4">
              {!plan ? (
                <Tooltip
                  arrow
                  title={
                    <span className="text-none font-bold py-4">
                      No plan selected. Please select a plan to enable this option.
                    </span>
                  }
                >
                  <span className="flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 opacity-50 cursor-not-allowed">
                    Run simulation
                  </span>
                </Tooltip>
              ) : (
                <NavLink
                  to={"simulate"}
                  className={({ isActive }) =>
                    `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                      isActive
                        ? "bg-[#1CEAA0] text-gray-600"
                        : "hover:bg-[#cfebe0]"
                    } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                  }
                  onClick={handleNavClick}
                  title={simulationAlert}
                >
                  <span className="flex items-center">
                    Run simulation
                    {simulationInProgress && (
                      <FaSpinner className="ml-2 animate-spin" />
                    )}
                    {simulationAlert && (
                      <span className="ml-2 text-red-500">●</span>
                    )}
                  </span>
                </NavLink>
              )}
            </li>
            <li className="relative border-l-2 border-grey-500 pl-4 mb-4">
              <Tooltip
                arrow
                title={
                  !plan ? (
                    <span className="text-none font-bold py-4">
                      No plan selected. Please select a plan to enable this option.
                    </span>
                  ) : simulationAlert ? (
                    simulationAlert
                  ) : (
                    ""
                  )
                }
              >
                <span
                  className={`
                    flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                      !plan ? "opacity-50 cursor-not-allowed" : "hover:bg-[#cfebe0] hover:cursor-pointer"
                    } text-ellipsis whitespace-nowrap rounded duration-300 ease-in-out`}
                  onClick={plan ? handleOpenCategorise : undefined}
                >
                  Re-categorise activities
                </span>
              </Tooltip>
            </li>

            {/* Group 2: Overview */}
            <li className="relative pt-2 border-l-2 border-yellow-200 pl-4">
              <NavLink
                to={"plan"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Plan overview</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-yellow-200 pl-4 mb-4">
              <NavLink
                to={"tra-qsra"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Weather days breakdown (TRA)</span>
              </NavLink>
            </li>

            {/* Group 3: Risk Management */}
            <li className="relative pt-2 border-l-2 border-red-200 pl-4">
              <NavLink
                to={"risk-drivers"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Identify risks</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-red-200 pl-4">
              <NavLink
                to={"mitigations"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Mitigate risks</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-red-200 pl-4 mb-4">
              <NavLink
                to={"weather-calendars-qsra"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Weather calendars (QSRA)</span>
              </NavLink>
            </li>

            {/* Group 4: Weather Management */}
            <li className="relative pt-2 border-l-2 border-blue-200 pl-4">
              <NavLink
                to={"variance"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Manage change</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-blue-200 pl-4 mb-4">
              <NavLink
                to={"weather"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 transition-colors ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Forecast weather windows</span>
              </NavLink>
            </li>

            {/* Group 5: Compensation */}
            <li className="relative pt-2 border-l-2 border-gray-300 pl-4">
              {" "}
              {/* Added mb-4 for spacing between groups */}
              <NavLink
                to={"settings/compensation-events"}
                className={({ isActive }) =>
                  `flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 ${
                    isActive
                      ? "bg-[#1CEAA0] text-gray-600"
                      : "hover:bg-[#cfebe0]"
                  } text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out`
                }
                onClick={handleNavClick}
              >
                <span>Compensation Events</span>
              </NavLink>
            </li>

            <li className="relative pt-2 border-l-2 border-gray-300 pl-4">
              {!plan ? (
                <Tooltip
                  arrow
                  title={
                    <span className="text-none font-bold py-4">
                      No plan selected. Please select a plan to enable this option.
                    </span>
                  }
                >
                  <span className="pt-2 flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 opacity-50 cursor-not-allowed">
                    Gantt View
                  </span>
                </Tooltip>
              ) : (
                <a
                  href={`/projects/${site.project}/plan`}
                  className="pt-2 flex items-center text-none font-normal px-4 h-14 overflow-hidden text-gray-700 hover:bg-[#cfebe0] text-ellipsis whitespace-nowrap rounded hover:cursor-pointer transition duration-300 ease-in-out"
                  target="_blank" // This will open the link in a new tab
                  rel="noopener noreferrer" // Security for opening links in a new tab
                  onClick={handleNavClick}
                >
                  <div className="flex items-center gap-2">
                    <span>Gantt View</span> <BiLinkExternal />
                  </div>
                </a>
              )}
            </li>
          </ul>
        )}
      </div>
      <div className="pt-10">
        <MapSite site={site} />
      </div>
    </>
  );
};
