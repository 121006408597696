import {
  Button,
  LoadingType,
  LoadingWrapper,
  MonochromeButton
} from "@ehabitation/ui";
import { FC, ReactNode, useState } from "react";

import {
  IPlan,
  ISimulation
} from "@ehabitation/ts-utils/browser";
import { simulateConfirmation } from "Components/Plan/TaskList/constants";
import StayOnPageAlert from "Components/StayOnPageAlert/StayOnPageAlert";
import {
  SimulationResultsKey
} from "Pages/sites/hooks";
import inspectSvgPath from "assets/icons/inspect.svg";
import { logSimulateBase, logSimulateMitigations } from "helpers/analytics";
import { RiPlayLine } from "react-icons/ri";



export const SimulationRequiredWrapper: FC<{
    expectedKey?: SimulationResultsKey;
    simulation?: ISimulation;
    simulationLoading: boolean;
    plan: IPlan;
    requestSimulation: () => void;
    setCategoriseOpen: (value: boolean) => void;
    renderContent: ({ simulation }: { simulation: ISimulation }) => ReactNode;
  }> = ({
    simulation,
    simulationLoading,
    plan,
    requestSimulation,
    renderContent,
    expectedKey,
    setCategoriseOpen,
  }) => {
    const [isOpenSimulateConfirm, setOpenSimulateConfirm] = useState(false);
  
    const simulationFailed = (simulation?.status as string) === "error";
    const expectedKeyMissing =
      !!expectedKey &&
      (simulation?.status as string) === "complete" &&
      !simulation?.[expectedKey];
  
      console.log("simulationLoading: " + simulationLoading );

    return (
      <>
        <LoadingWrapper
          message="Loading tasks..."
          type={LoadingType.subscriptions}
          loading={simulationLoading}
        >
          {simulationFailed || !simulation?.status || expectedKeyMissing ? (
            <div className="flex-grow grid place-content-center gap-2">
              <p className="font-bold">
                {simulationFailed
                  ? "Simulation failed, click below to try again or contact support@ehab.co if error persists."
                  : expectedKeyMissing
                  ? "Please click below to update simulation for this page. Contact support@ehab.co if message persists."
                  : "No Simulation Found For Selected Plan."}
              </p>
              <Button
                className="mr-0 py-2"
                type="button"
                aria-label="Simulate"
                disabled={simulationLoading}
                onClick={async () => {
                  setOpenSimulateConfirm(true);
                }}
              >
                <RiPlayLine className="inline" /> Simulate
              </Button>
            </div>
          ) : (
            <LoadingWrapper
              message={"Running simulation..."}
              type={LoadingType.subscriptions}
              loading={[
                "pending",
                "site_pending",
                "partial",
                "prepared",
              ].includes(simulation.status)}
            >
              {simulation?.status === "complete" &&
              simulation.simulatedTasks &&
              simulation.simulatedTasks > 0 ? (
                renderContent({
                  simulation,
                })
              ) : (
                <div className="flex-grow grid place-content-center gap-2">
                  <p className="font-bold">
                    Simulation found no weather risk for selected plan.
                  </p>
                  <span className="self-stretch">
                    <MonochromeButton
                      className="w-full"
                      level="secondary"
                      onClick={() => setCategoriseOpen(true)}
                    >
                      <img
                        src={inspectSvgPath}
                        alt="inspect"
                        className="h-full mr-3"
                      />{" "}
                      Categorise&nbsp;Tasks
                    </MonochromeButton>
                  </span>
                </div>
              )}
            </LoadingWrapper>
          )}
        </LoadingWrapper>
        {isOpenSimulateConfirm ? (
          <StayOnPageAlert
            message={simulateConfirmation}
            handleStayOnPage={() => setOpenSimulateConfirm(false)}
            handleSave={() => {
              requestSimulation();
              setOpenSimulateConfirm(false);
              plan.mitigatesPlanId
                ? logSimulateMitigations(simulation!.site, plan?.id)
                : logSimulateBase(simulation!.site, plan?.id);
            }}
            handleLeavePage={() => {
              setOpenSimulateConfirm(false);
            }}
            saveButtonText={"Simulate"}
            dontSaveButtonText="Cancel"
          />
        ) : null}
      </>
    );
  };