import React, { useState } from "react";

export const useContextMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
  };
};
