import { IDivision, IUser } from "@ehabitation/ts-utils/browser";

export interface IOrganisationUsers {
  [key: string]: IUser;
}

export interface IDivisionMap {
  [key: string]: IDivision;
}

export enum UserManagementTabOption {
  Users = "Users",
  Details = "Details",
}

export type TUserManagementTabOption = "Divisions" | "Details";
