import { ITask } from "@ehabitation/ts-utils/browser";
import {
  SimulationLevel,
  SimulationRiskDriverResult,
  SimulationTaskResult,
} from "helpers";
import { useMemo } from "react";

export interface MilestoneOption {
  readonly value: string;
  readonly label: string;
  readonly startDelay?: number;
}

export const calculateHierarchy = (
  task: ITask & { simulation: SimulationTaskResult },
  tasks: (ITask & { simulation: SimulationTaskResult })[]
) => {
  const hierarchy = [];
  let currentTask = task;
  let i = 0;
  while (currentTask.internalParentId) {
    const parentTask = tasks?.find(
      (task) => task.id === currentTask.internalParentId
    );
    if (parentTask) {
      hierarchy.push({ name: parentTask.title, position: i });
      currentTask = parentTask;
      i++;
    } else {
      currentTask.internalParentId = undefined;
    }
  }
  return hierarchy.sort((a, b) => a.position - b.position);
};

export const useRiskDriverMilestones = (
  milestones: (ITask & { simulation: SimulationTaskResult })[],
  selectedSimLevel: SimulationLevel,
  simulationRiskDriverResult?: SimulationRiskDriverResult
) => {
  const milestoneOptions: MilestoneOption[] = useMemo(() => {
    return milestones
      .filter((milestone) => {
        return !!simulationRiskDriverResult?.taskCorrelationsMatrix[
          milestone.id
        ];
      })
      .map((milestone) => {
        return {
          value: milestone.id,
          label: `${milestone.taskCode ? "[" + milestone.taskCode + "] " : ""}${
            milestone.title
          }`,
          startDelay:
            simulationRiskDriverResult?.taskCorrelationsMatrix[milestone.id]
              .startDelay,
        };
      })
      .filter((opt) => opt.startDelay || 0 > 0)
      .sort((a: MilestoneOption, b: MilestoneOption) => {
        return (b.startDelay || 0) - (a.startDelay || 0);
      });
  }, [milestones, selectedSimLevel, simulationRiskDriverResult]);
  return { milestoneOptions };
};

export const useSimulationRiskDrivers = (
  tasks: (ITask & { simulation: SimulationTaskResult })[],
  milestones: (ITask & { simulation: SimulationTaskResult })[],
  selectedMilestone: MilestoneOption,
  selectedSimLevel: SimulationLevel,
  sortOrder: string,
  showIsCriticalPath: boolean,
  simulationRiskDriverResult?: SimulationRiskDriverResult
) => {
  const riskDriverViewContext = useMemo(() => {
    var maxWeatherDays = 0;
    const visibleTasks: (ITask & { simulation: SimulationTaskResult } & {
      correlation?: number;
      hierarchy: { name: string; position: number }[];
    })[] = [];

    // If not found we will assume plan end correlations.
    const selectedMilestoneCorrelations =
      simulationRiskDriverResult?.taskCorrelationsMatrix?.[
        selectedMilestone?.value || ""
      ];

    const milestoneResult = milestones
      ?.filter((milestone) => {
        return milestone.id === selectedMilestone?.value;
      })
      .pop();

    for (const task of tasks) {
      var includeTask = false;
      const weatherDays =
        task.simulation.riskIntervalResults?.[selectedSimLevel]?.weatherDays;
      var correlation = null;
      if (selectedMilestoneCorrelations) {
        // If a milestone is selected we should filter based on that milestones correlated tasks.
        correlation =
          selectedMilestoneCorrelations?.predecessorCorrelations?.[task.id];
      } else {
        // else the plan end correlation is used.
        correlation = task.simulation.planEndCorrelation;
      }

      // does the task have both weather days and correlation.
      includeTask = !!weatherDays && !!correlation && correlation > 0;

      // Filter the task if the show critical flag is set
      includeTask = showIsCriticalPath ? !!task.isCriticalPath : includeTask;

      if (includeTask) {
        const hierarchy = calculateHierarchy(task, tasks);
        visibleTasks.push({ ...task, hierarchy, correlation });
        maxWeatherDays =
          weatherDays && weatherDays > maxWeatherDays
            ? weatherDays
            : maxWeatherDays;
      }
    }

    // Sote the visible tasks
    visibleTasks.sort(
      (
        a: ITask & { simulation: SimulationTaskResult } & {
          correlation?: number;
          hierarchy: { name: string; position: number }[];
        },
        b: ITask & { simulation: SimulationTaskResult } & {
          correlation?: number;
          hierarchy: { name: string; position: number }[];
        }
      ) =>
        sortOrder === "weatherDays"
          ? (b.simulation.riskIntervalResults?.[selectedSimLevel]
              ?.weatherDays || 0) -
            (a.simulation.riskIntervalResults?.[selectedSimLevel]
              ?.weatherDays || 0)
          : (b.correlation || 0) - (a.correlation || 0)
    );

    return {
      maxWeatherDays,
      visibleTasks,
      milestoneResult,
    };
  }, [
    tasks,
    selectedSimLevel,
    selectedMilestone,
    simulationRiskDriverResult,
    sortOrder,
  ]);

  return { riskDriverViewContext };
};
