//React
import { useRef, useCallback } from "react";
//Store
import { useAppDispatch } from "store";
import { batch } from "react-redux";
import { setGanttFirstDate, setGanttLastDate } from "store/ui";
//Helpers
import dateUtils from "gantt-lib/date_utils";
// Types
import { ViewMode } from "types";

export const VIEW_MODE_COLUMN_WIDTH = {
  HalfDay: 26,
  QuarterDay: 26,
  Day: 26,
  Week: 140,
  Month: 260,
  Year: 380,
};

const DAYS_IN_WEEK = 7;

const calculateColumnWidth = (mode: ViewMode): number => {
  if (mode === ViewMode.DAY) {
    return VIEW_MODE_COLUMN_WIDTH.Day;
  }
  if (mode === ViewMode.WEEK) {
    return VIEW_MODE_COLUMN_WIDTH.Week;
  }
  if (mode === ViewMode.MONTH) {
    return VIEW_MODE_COLUMN_WIDTH.Month;
  } else {
    return VIEW_MODE_COLUMN_WIDTH.Year;
  }
};

export const useScrollLocation = () => {
  const dispatch = useAppDispatch();

  const ganttScrollLocation = useRef<number>(0); //Stores the x location of the gantt chart in a ref

  const startDateRef = useRef<Date | null>(null);
  const endDateRef = useRef<Date | null>(null);

  // Uses a scroll event listner in the frappe-libb to get these values
  // Finds first and last data at any given scroll point and zooms the chart appropriately
  // hasGanttStartChanged & daysSubtract in-place to fix dragging bars into the past
  const handleUpdateScrollPosition = useCallback(
    (
      xPos: number,
      dates: any[],
      offsetWidth: number,
      hasGanttStartChanged: boolean,
      ganttChartViewMode: ViewMode,
      daysSubtract?: number
    ) => {
      // Write start and end of gantt to state on first load or plan change
      if (!startDateRef.current || hasGanttStartChanged) {
        batch(() => {
          dispatch(setGanttFirstDate(dates[1]));
          dispatch(setGanttLastDate(dates[dates.length - 1]));
        });
      }

      if (xPos !== ganttScrollLocation.current || hasGanttStartChanged) {
        ganttScrollLocation.current = xPos;

        const columnWidth = calculateColumnWidth(ganttChartViewMode);
        const scale =
          ganttChartViewMode === ViewMode.WEEK
            ? ViewMode.DAY.toLocaleLowerCase()
            : ganttChartViewMode.toLocaleLowerCase();

        let indexOfDateAtScroll = xPos / columnWidth;

        let firstDateInView = dates[Math.round(indexOfDateAtScroll)];

        if (daysSubtract) {
          dateUtils.add(firstDateInView ?? dates[0], daysSubtract, scale);
        }
        // When the scale is WEEK, we have no easy way of calculating lastDateInView,
        // so I take the number of columns and multiply it by 7 (days in week).
        // This gives us the correct, lastDayInView that we pass to zoom RiskChart.
        const daysInView = Math.round(offsetWidth / columnWidth);
        const dateRangeInView =
          ganttChartViewMode === ViewMode.WEEK
            ? daysInView * DAYS_IN_WEEK
            : daysInView;

        const lastDateInView = dateUtils.add(
          firstDateInView ?? dates[0],
          dateRangeInView,
          scale
        );

        startDateRef.current = firstDateInView;
        endDateRef.current = lastDateInView;
      }
    },
    [dispatch]
  );

  return {
    handleUpdateScrollPosition,
    ganttScrollLocation,
  };
};
