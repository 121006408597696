import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DivisionManagementTabOption } from "types";
import { RootState } from "store";
export interface IDivisionManagementState {
  selectedDivision: string | null;
  selectedTab: DivisionManagementTabOption;
  divisionManagementError: string;
  isLoading: boolean;
}

export const initialState: IDivisionManagementState = {
  selectedDivision: null,
  selectedTab: "Divisions",
  isLoading: false,
  divisionManagementError: "",
};

export const divisionManagementSlice = createSlice({
  name: "divisionManagement",
  initialState,
  reducers: {
    setSelectedDivision: (state, action: PayloadAction<string | null>) => {
      state.selectedDivision = action.payload;
    },

    setSelectedDivisionTab: (
      state,
      action: PayloadAction<DivisionManagementTabOption>
    ) => {
      state.selectedTab = action.payload;
    },

    setDivisionManagementError: (state, action: PayloadAction<string>) => {
      state.divisionManagementError = action.payload;
    },

    setDivisionManagementIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setSelectedDivision,
  setSelectedDivisionTab,
  setDivisionManagementError,
  setDivisionManagementIsLoading,
} = divisionManagementSlice.actions;

//Selectors
export const selectDivisionManagementIsLoading = (state: RootState) =>
  state.divisionManagement.isLoading;

export const selectDivisionManagementError = (state: RootState) =>
  state.divisionManagement.divisionManagementError;

export const selectDivisionManagementTab = (state: RootState) =>
  state.divisionManagement.selectedTab;

export const selectSelectedDivision = (state: RootState) =>
  state.divisionManagement.selectedDivision;

//Reducer
export default divisionManagementSlice.reducer;
