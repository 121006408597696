import React, { FC } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { UserManagementTabOption } from "types";
import { RootState } from "store";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  UserRole,
  greaterThanRole,
  IUser,
  isGuest,
} from "@ehabitation/ts-utils/browser";
import { useContextMenu, useFeatureFlag } from "hooks";
import {
  selectDivisionName,
  selectProjectName,
} from "store/userManagement/computedSelectors";
import { useAppSelector, useAppDispatch } from "store";
import { deleteUserById } from "./thunks";
import { selectRole } from "store/auth";
import { StyledAdminIconButton } from "elements";
import { StyledTableRow } from "./styles";
import { batch } from "react-redux";
import { setSelectedTab, setSelectedUser } from "store/userManagement";

interface IProps {
  user: IUser;
}

const UserRow: FC<IProps> = ({ user }) => {
  const {
    email,
    firstName,
    lastName,
    role = UserRole.guest,
    divisionId,
    project,
    id,
  } = user;

  const dispatch = useAppDispatch();
  const callerRole = useAppSelector(selectRole) ?? UserRole.guest;
  const { anchorEl, handleOpenContextMenu, handleCloseContextMenu } =
    useContextMenu();
  const divisionName = useAppSelector((state: RootState) =>
    selectDivisionName(state, divisionId)
  );
  const projectName = useAppSelector((state: RootState) =>
    selectProjectName(state, project)
  );

  const hasDeleteFeatureFlag = useFeatureFlag("delete");

  const handleEditUser = () => {
    batch(() => {
      dispatch(setSelectedUser(id!));
      dispatch(setSelectedTab(UserManagementTabOption.Details));
    });
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserById(id));
    handleCloseContextMenu();
  };

  return (
    <StyledTableRow
      isOrgAdmin={role === UserRole.orgAdmin ? true : false}
      data-testid="user-row"
    >
      <p className="short" data-testid="user-row-name">
        {firstName} {lastName}
      </p>

      <div className="long">
        <p className="role" data-testid="user-row-role">
          {role}
        </p>
      </div>

      <p className="short" data-testid="user-row-division">
        {role === UserRole.divisionAdmin ||
        role === UserRole.projectAdmin ||
        role === UserRole.planner
          ? divisionName
          : ""}
      </p>

      <p className="long" data-testid="user-row-project">
        {role === UserRole.projectAdmin || role === UserRole.planner
          ? projectName
          : ""}
      </p>

      <div className="long">
        <p className="email" data-testid="user-row-email">
          {email}
        </p>
      </div>

      <div className="icon">
        <StyledAdminIconButton
          onClick={handleOpenContextMenu}
          disabled={
            !greaterThanRole(callerRole, role) ||
            (isGuest(role) && greaterThanRole(callerRole, UserRole.orgAdmin))
          }
          data-testid="user-row-icon-button"
        >
          <BiDotsVerticalRounded />
        </StyledAdminIconButton>

        {greaterThanRole(callerRole, role) ? (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseContextMenu}
          >
            <MenuItem
              onClick={handleEditUser}
              data-testid="user-row-edit-button"
            >
              Edit
            </MenuItem>
            {hasDeleteFeatureFlag ? (
              <MenuItem
                onClick={handleDeleteUser}
                data-testid="user-row-delete-button"
              >
                Delete
              </MenuItem>
            ) : null}
          </Menu>
        ) : null}
      </div>
    </StyledTableRow>
  );
};

export default UserRow;
