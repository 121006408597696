import { VIEW_MODE_COLUMN_WIDTH } from "../Pages/projects/useScrollLocation";
import Arrow from "./arrow";
import Bar from "./bar";
import date_utils from "./date_utils";
import dep_utils from "./dep_utils";
import "./gantt.css";
import Popup from "./popup";
import { $, createSVG } from "./svg_utils";

import Hex from "crypto-js/enc-hex";
import md5 from "crypto-js/md5";

export const VIEW_MODE = {
  QUARTER_DAY: "Quarter Day",
  HALF_DAY: "Half Day",
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

export default class Gantt {
  constructor(
    wrapper,
    tasks,
    allTasks,
    isSimulating,
    showDeps,
    startDate,
    endDate,
    workDays,
    holidays,
    metadata,
    options
  ) {
    this.setup_metadata(metadata);
    this.setup_site_start_end(startDate, endDate);
    this.setup_wrapper(wrapper);
    this.setup_options(options);
    this.setup_tasks(tasks);
    this.setup_calendar(workDays, holidays);
    // initialize with default view mode
    this.change_view_mode();
    this.bind_events();
    this.showDeps = showDeps;
    this.tasks_under_sim = [];
    this.rendered_task_ids = [];
    this.modified_task_hash = "";
    this.isSimulating = isSimulating;
    this.allTasks = allTasks;
  }

  // This was a workaround to pass props through gantt. Not needed.
  setup_metadata(metadata) {
    this.metadata = metadata;
  }

  // Set start/end dates by passing in project start/end.
  setup_site_start_end(startDate, endDate) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    this.startDate = startDate;
    this.endDate = endDate;
    this.first_scroll_set = false;
  }

  // Pass in workedDays file, contains data to highlight days off.
  setup_calendar(workDaysArray, holidays) {
    this.nonWorkingDays = [];
    this.holidays = [];

    const workDays = [...workDaysArray];
    workDays.unshift(workDays[workDays.length - 1]);
    workDays.pop();

    for (const i in workDays) {
      if (!workDays[i].startTime || !workDays[i].endTime)
        this.nonWorkingDays.push(Number(i));
    }

    for (const day of holidays) {
      const splitDate = day.split("/");
      const holidayDate = new Date(
        splitDate[2],
        splitDate[1] - 1,
        splitDate[0]
      );
      holidayDate.setHours(0, 0, 0, 0);
      this.holidays.push(holidayDate);
    }
  }

  setup_wrapper(element) {
    let svg_element, wrapper_element;

    // CSS Selector is passed
    if (typeof element === "string") {
      element = document.querySelector(element);
    }

    // get the SVGElement
    if (element instanceof HTMLElement) {
      wrapper_element = element;
      svg_element = element.querySelector("svg");
    } else if (element instanceof SVGElement) {
      svg_element = element;
    } else {
      throw new TypeError(
        "Frappé Gantt only supports usage of a string CSS selector," +
          " HTML DOM element or SVG DOM element for the 'element' parameter"
      );
    }

    // svg element
    if (!svg_element) {
      // create it
      this.$svg = createSVG("svg", {
        append_to: wrapper_element,
        class: "gantt",
      });
    } else {
      this.$svg = svg_element;
      this.$svg.classList.add("gantt");
    }

    // wrapper element
    this.$container = document.createElement("div");
    this.$container.classList.add("gantt-container");
    
    const parent_element = this.$svg.parentElement;
    parent_element.appendChild(this.$container);
    this.$container.appendChild(this.$svg);

    // popup wrapper
    this.popup_wrapper = document.createElement("div");
    this.popup_wrapper.classList.add("popup-wrapper");
    this.$container.appendChild(this.popup_wrapper);
  }

  // Default values, never been changed.
  setup_options(options) {
    const default_options = {
      header_height: 28,
      column_width: 26,
      step: 24,
      view_modes: [...Object.values(VIEW_MODE)],
      bar_height: 20,
      bar_corner_radius: 0,
      arrow_curve: 5,
      padding: 18,
      view_mode: "Day",
      date_format: "YYYY-MM-DD",
      popup_trigger: "click",
      custom_popup_html: null,
      language: "en",
    };
    this.options = Object.assign({}, default_options, options);
  }

  // Function is called when doing a full re-render (Replace all tasks in this.tasks)
  setup_tasks(tasks) {
    // prepare tasks
    this.rendered_task_ids = [];

    this.tasks = tasks.map((t, i) => {
      let task = { ...t };

      // Create copy of all tasks being rendered so that we know what tasks exist in chart
      // Used when extracting task data from deps.
      this.rendered_task_ids.push(task.id);

      // If bar is in loading state, get the task data from that bar as it holds latest state.
      const pendingSimTask =
        this.tasks_under_sim &&
        this.tasks_under_sim.find((simTask) => simTask.id === task.id);
      if (pendingSimTask) {
        task = {
          ...pendingSimTask,
          risks: [],
        };
      }

      // convert to Date objects
      task._start = date_utils.parse(task.start);
      task._end = date_utils.parse(task.end);

      // make task invalid if duration too large
      if (date_utils.diff(task._end, task._start, "year") > 10) {
        task.end = null;
      }

      // cache index
      task._index = i;

      // invalid dates
      if (!task.start && !task.end) {
        const today = date_utils.today();
        task._start = today;
        task._end = date_utils.add(today, 2, "day");
      }

      if (!task.start && task.end) {
        task._start = date_utils.add(task._end, -2, "day");
      }

      if (task.start && !task.end) {
        task._end = date_utils.add(task._start, 2, "day");
      }

      // if hours is not set, assume the last day is full day
      // e.g: 2018-09-09 becomes 2018-09-09 23:59:59
      const task_end_values = date_utils.get_date_values(task._end);
      if (task_end_values.slice(3).every((d) => d === 0)) {
        task._end = date_utils.add(task._end, 24, "hour");
      }

      // invalid flag
      if (!task.start || !task.end) {
        task.invalid = true;
      }

      // uids
      if (!task.id) {
        task.id = generate_id(task);
      }

      // Catch invalid risk data
      const tb = !task.risks || (task.risks && task.risks.length === 0);
      const risks = tb ? [] : task.risks;

      return { ...task, risks };
    });
  }

  // Used when updating modified tasks (partial render)
  update_tasks(tasks) {
    let foundCounter = 0;
    const updateTaskMap = tasks.map((task) => task.id);
    let remainingTasks = [...updateTaskMap];

    for (const [i, task] of this.tasks.entries()) {
      if (foundCounter === updateTaskMap.length) {
        break;
      }

      // If task is being updated, get copy of task for modification.
      if (!updateTaskMap.includes(task.id)) continue;
      const updateTaskIndex = updateTaskMap.indexOf(task.id);
      const updateTask = { ...tasks[updateTaskIndex] };

      const bar = this.get_bar(task.id);

      // Update start/end dates
      updateTask._start = date_utils.parse(updateTask.start);
      updateTask._end = date_utils.parse(updateTask.end);

      // Write updated task to bar
      bar.task = { ...updateTask };

      // Modify SVG dimensions based on new task input
      bar.recalculate_bar_dimensions(1);

      // Remove current risk bar(s)
      bar.destroy_risk_bar_elements();

      // Remove current confidence data
      bar.destroy_confidence_label();
      bar.draw_base_confidence_label();

      // Update risk data in bar and draw
      bar.risk_bars = updateTask.risks;
      bar.draw_risk_bars();
      bar.draw_risk_bar_label();

      // Draw dep warning if flag is set
      //if (bar.task.isModelBrokenDep) bar.draw_dependency_warning_icon();

      foundCounter++;
      this.tasks[i] = updateTask;

      // Remove task from sim state (complete)
      this.remove_tasks_from_sim_state(updateTask.id);

      // Update outstanding tasks
      remainingTasks = remainingTasks.filter((id) => id !== updateTask.id);
    }

    // Remove updated tasks which have been returned but not rendered
    for (const unrenderedTask of remainingTasks) {
      if (!this.rendered_task_ids.includes(unrenderedTask)) {
        this.remove_tasks_from_sim_state(unrenderedTask);
      }
    }

    if (!this.dependencyMap) return;

    // Draw dep warnings on new tasks
    //this.assess_dependencies(updateTaskMap);
  }

  // Remove complete tasks from tasks_under_sim array.
  // Array length affects how partial/full renders trigger.
  remove_tasks_from_sim_state(id) {
    const actionIndex = this.tasks_under_sim.findIndex(
      (simTask) => simTask.id === id
    );

    if (actionIndex > -1) {
      this.tasks_under_sim.splice(actionIndex, 1);
    }
  }

  // Iterate over task dependencies and assess if parent tasks impose time restrictions.
  // Seperate dependency assessment to weather-based delays which are calculated server side.
  assess_dependencies(tasks) {
    // When we update a task we check the dependencies and create visual cues
    for (const task of tasks) {
      const child = this.get_bar(task);
      const relationships = (child && child.task.relationship) || null;

      if (!relationships) continue;

      for (const [relType, parents] of Object.entries(relationships)) {
        if (parents.length === 0) continue;

        for (const parentId of parents) {
          const parent = this.get_parent_bar(parentId);

          if (!parent) continue;

          if (dep_utils.check_valid_dependency(relType, parent, child)) {
            child.task.isTaskBrokenDep = true;
            child.task.taskOffsetDays = {
              type: dep_utils.get_dependency_delay_type(relType),
              offset: dep_utils.calculate_dependency_offset_days(
                this,
                relType,
                parent,
                child
              ),
            };
          } else {
            child.task.isTaskBrokenDep = false;
            child.task.taskOffsetDays = {};
          }
        }
      }

      if (child.task.isTaskBrokenDep) child.draw_dependency_warning_icon();
      else if (!child.task.isTaskBrokenDep && !child.task.isModelBrokenDep)
        child.destroy_dependency_warning_icon();
    }
  }

  // Get bar for task, will create a dummy bar if it dosen't exist
  // Dummy makes it easier to make calculations on x loc and width.
  get_parent_bar(id) {
    const parentBar = this.get_bar(id);

    if (parentBar || !(id in this.allTasks)) return parentBar;

    let barTask = {
      ...this.allTasks[id],
    };

    barTask._start = date_utils.parse(barTask.start);
    barTask._end = date_utils.parse(barTask.end);

    return new Bar(this, barTask, this.options.view_mode, false);
  }

  // REFRESH BEHAVIOUR
  // tasks not match & metadata not match - reload
  // tasks match & metadata not match - reload
  // tasks not match & metadata match - update
  // tasks match & metadata match - nothing
  refresh(tasks, allTasks, isSimulating, showDeps, metadata, xPos, options) {
    if (xPos !== 0) this.last_scroll_position = xPos;

    // Updates callback functions being passed in.
    this.setup_options(options);

    this.isSimulating = isSimulating;
    this.allTasks = allTasks;

    const tasksMatch =
      this.getStringifyHash(this.tasks) === this.getStringifyHash(tasks);
    const taskLengthMatch = this.tasks.length === tasks.length;
    const metadataMatch =
      this.getStringifyHash(this.metadata) === this.getStringifyHash(metadata);
    const taskFilter =
      metadataMatch && !tasksMatch && this.tasks_under_sim.length === 0;
    const wbsFilter = !taskLengthMatch && metadataMatch;

    // Scrub task under sim array if we change project/plan
    if (!metadataMatch) this.tasks_under_sim = [];

    // Full render
    if (!metadataMatch || taskFilter || wbsFilter) {
      this.setup_metadata(metadata);
      this.setup_tasks(tasks);
      this.change_view_mode();
      this.setup_gantt_dates();
      //this.assess_dependencies(this.rendered_task_ids);

      // Partial render
    } else if (!tasksMatch) {
      this.update_tasks(tasks);
      this.modified_task_hash = this.getStringifyHash(tasks);
    }

    // Flag to disable arrows in gantt AND will revert to passing single bars to model update.
    if (this.showDeps !== showDeps) {
      this.update_dependency_view(showDeps);
      this.showDeps = showDeps;
    }
  }

  // Disables dependency arrows
  update_dependency_view(showDeps) {
    const allArrows = this.layers.arrow.getElementsByTagName("path");
    const newClass = showDeps ? "arrow" : "arrow arrow-disabled";

    for (const arrow of allArrows) {
      arrow.setAttribute("class", newClass);
    }
  }

  // Original function, never changed
  change_view_mode(mode = this.options.view_mode) {
    this.update_view_scale(mode);
    this.setup_dates();
    this.render();
    // fire viewmode_change event
    if (mode !== this.options.view_mode)
      this.trigger_event("view_change", [mode]);
  }

  // Original function, never changed
  update_view_scale(view_mode) {
    this.options.view_mode = view_mode;

    if (view_mode === VIEW_MODE.DAY) {
      this.options.step = 24;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.Day;
    } else if (view_mode === VIEW_MODE.HALF_DAY) {
      this.options.step = 24 / 2;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.HalfDay;
    } else if (view_mode === VIEW_MODE.QUARTER_DAY) {
      this.options.step = 24 / 4;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.QuarterDay;
    } else if (view_mode === VIEW_MODE.WEEK) {
      this.options.step = 24 * 7;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.Week;
    } else if (view_mode === VIEW_MODE.MONTH) {
      this.options.step = 24 * 30;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.Month;
    } else if (view_mode === VIEW_MODE.YEAR) {
      this.options.step = 24 * 365;
      this.options.column_width = VIEW_MODE_COLUMN_WIDTH.Year;
    }
  }

  setup_dates() {
    this.setup_gantt_dates();
    this.setup_date_values();
  }

  setup_gantt_dates() {
    // If task needs to extend to the left, then set flag to adjust the risk chart
    if (
      this.tasks.length !== 0 && // accounts for 0 tasks from filters
      this.gantt_start &&
      this.gantt_old_start &&
      this.gantt_start.getTime() !== this.gantt_old_start.getTime()
    ) {
      this.has_gantt_start_changed = true;
      this.days_gantt_start_changed = date_utils.diff(
        this.gantt_start,
        this.gantt_old_start
      );
      this.set_scroll_position();
    }

    //Fallbacks for missing tasks
    this.gantt_old_start = this.gantt_start;
    this.gantt_old_end = this.gantt_end;

    this.gantt_start = this.gantt_end = null;

    let gantt_end_max_risk;

    for (let task of this.tasks) {
      // Here we want to make sure the gantt chart can accomodate the confidence bars of a task.
      // To do this, we see if tasks have associated bars.
      // We then need to parse (array of objects with unknown keys).
      // We need to then calculate the days from the duration in hours
      // Add to end date, then assess against that
      let task_end_max_risk = task._end;

      if (task.risks && task.risks.length > 0) {
        let last_task_key = Object.keys(task.risks[task.risks.length - 1]);
        if (last_task_key.length === 1) {
          let max_task_delay_days =
            task.risks[task.risks.length - 1][last_task_key[0]].width / 24;
          task_end_max_risk = date_utils.add(
            task._end,
            max_task_delay_days,
            "day"
          );
        } else {
          task_end_max_risk = date_utils.add(task._end, 90, "day");
        }
      }

      if (!gantt_end_max_risk || task_end_max_risk > gantt_end_max_risk) {
        gantt_end_max_risk = task_end_max_risk;
      }

      // set global start and end date
      if (!this.gantt_start || task._start < this.gantt_start) {
        this.gantt_start = task._start;
      }
    }
    if (!this.gantt_end || gantt_end_max_risk > this.gantt_end) {
      this.gantt_end = gantt_end_max_risk;
    }

    //Accounts for 0 tasks - defaults to fallbacks
    if (this.gantt_start === null) {
      this.gantt_start = this.gantt_old_start;
      this.gantt_end = this.gantt_old_end;
    }

    this.gantt_start = date_utils.start_of(this.gantt_start, "day");
    this.gantt_end = date_utils.start_of(this.gantt_end, "day");

    // add date padding on both sides
    if (this.view_is([VIEW_MODE.QUARTER_DAY, VIEW_MODE.HALF_DAY])) {
      this.gantt_start = date_utils.add(this.gantt_start, -60, "day");
      //this.gantt_end = date_utils.add(this.gantt_end, 60, "day");
    } else if (this.view_is(VIEW_MODE.MONTH)) {
      this.gantt_start = date_utils.start_of(this.gantt_start, "year");
      //this.gantt_end = date_utils.add(this.gantt_end, 1, "year");
    } else if (this.view_is(VIEW_MODE.YEAR)) {
      this.gantt_start = date_utils.add(this.gantt_start, -2, "year");
      //this.gantt_end = date_utils.add(this.gantt_end, 2, "year");
    } else {
      this.gantt_start = date_utils.add(this.gantt_start, -2, "month");
      //this.gantt_end = date_utils.add(this.gantt_end, 2, "month");
    }
  }

  // Default function
  setup_date_values() {
    this.dates = [];
    let cur_date = null;

    while (cur_date === null || cur_date < this.gantt_end) {
      if (!cur_date) {
        cur_date = date_utils.clone(this.gantt_start);
      } else {
        if (this.view_is(VIEW_MODE.YEAR)) {
          cur_date = date_utils.add(cur_date, 1, "year");
        } else if (this.view_is(VIEW_MODE.MONTH)) {
          cur_date = date_utils.add(cur_date, 1, "month");
        } else {
          cur_date = date_utils.add(cur_date, this.options.step, "hour");
        }
      }
      this.dates.push(cur_date);
    }
  }

  bind_events() {
    this.bind_grid_click();
    this.bind_bar_events();
  }

  render() {
    this.clear();
    this.setup_layers();
    this.make_grid();
    this.make_dates();
    this.make_bars();
    this.make_arrows();
    this.map_arrows_on_bars();
    this.set_width();
    this.set_scroll_position();
  }

  setup_layers() {
    this.layers = {};
    const layers = ["grid", "arrow", "bar", "details", "date"];
    // make group layers
    for (let layer of layers) {
      this.layers[layer] = createSVG("g", {
        class: layer,
        append_to: this.$svg,
      });
    }
  }

  make_grid() {
    this.make_grid_background();
    this.make_grid_rows();
    this.make_grid_header();
    this.make_grid_ticks();
    this.make_grid_highlights();
  }

  make_grid_background() {
    const grid_width = this.dates.length * this.options.column_width;

    const multiplier = Math.max(
      this.tasks.length > 0 ? this.tasks.length : 1,
      20
    );
    const grid_height =
      this.options.header_height +
      (this.options.bar_height + this.options.padding) * multiplier;

    createSVG("rect", {
      x: 0,
      y: 0,
      width: grid_width,
      height: grid_height,
      class: "grid-background",
      append_to: this.layers.grid,
    });

    $.attr(this.$svg, {
      height: grid_height,
      width: "100%",
    });
  }

  make_grid_rows() {
    const rows_layer = createSVG("g", { append_to: this.layers.grid });
    const lines_layer = createSVG("g", { append_to: this.layers.grid });

    const row_width = this.dates.length * this.options.column_width;
    const row_height = this.options.bar_height + this.options.padding;

    let row_y = this.options.header_height + this.options.padding / 2;

    const tasksLength = Math.max(
      this.tasks.length > 0 ? this.tasks.length : 1,
      20
    );

    // eslint-disable-next-line
    for (let i = 0; i < tasksLength; i++) {
      createSVG("rect", {
        x: 0,
        y: row_y,
        width: row_width,
        height: row_height,
        class: "grid-row",
        append_to: rows_layer,
      });

      createSVG("line", {
        x1: 0,
        y1: row_y + row_height,
        x2: row_width,
        y2: row_y + row_height,
        class: "row-line",
        append_to: lines_layer,
      });

      row_y += this.options.bar_height + this.options.padding;
    }
  }

  make_grid_header() {
    const header_width = this.dates.length * this.options.column_width;
    const header_height = this.options.header_height + 10;

    createSVG("rect", {
      x: 0,
      y: 0,
      width: header_width,
      height: header_height,
      class: "grid-header",
      append_to: this.layers.date,
    });
  }

  make_grid_ticks() {
    let tick_x = 0;
    let tick_y = this.options.header_height + this.options.padding / 2;
    let tick_height =
      (this.options.bar_height + this.options.padding) *
      Math.max(this.tasks.length, 20);

    for (let date of this.dates) {
      let tick_class = "tick";
      // thick tick for monday
      if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
        tick_class += " thick";
      }
      // thick tick for first week
      if (
        this.view_is(VIEW_MODE.WEEK) &&
        date.getDate() >= 1 &&
        date.getDate() < 8
      ) {
        tick_class += " thick";
      }
      // thick ticks for quarters
      if (this.view_is(VIEW_MODE.MONTH) && (date.getMonth() + 1) % 3 === 0) {
        tick_class += " thick";
      }

      createSVG("path", {
        d: `M ${tick_x} ${tick_y} v ${tick_height}`,
        class: tick_class,
        append_to: this.layers.grid,
      });

      if (this.view_is(VIEW_MODE.MONTH)) {
        tick_x +=
          (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
      } else {
        tick_x += this.options.column_width;
      }
    }
  }

  // Add highlights for current day, start/end date and days off.
  make_grid_highlights() {
    // highlight today's date
    if (this.view_is(VIEW_MODE.DAY)) {
      const loopDate = new Date(this.gantt_start.getTime());
      loopDate.setHours(0, 0, 0, 0);

      this.highlight_date(date_utils.today(), "today-highlight");
      this.startDate_x = this.highlight_date(this.startDate, "start-highlight");
      this.endDate_x = this.highlight_date(this.endDate, "end-highlight");

      for (
        const day = loopDate;
        day <= this.gantt_end;
        day.setDate(day.getDate() + 1)
      ) {
        const isNonWorkingDay = Boolean(
          this.nonWorkingDays.some((element) => day.getDay() === element)
        );
        const isHoliday = Boolean(
          this.holidays.some((element) => day.getTime() === element.getTime())
        );

        if (isNonWorkingDay || isHoliday) {
          this.highlight_date(day, "off-highlight");
        }
      }
    }
  }

  highlight_date(date, className) {
    const [x, y, w, h] = this.get_highlight_parameters(date);

    createSVG("rect", {
      x: x,
      y: y,
      width: w,
      height: h,
      class: className,
      append_to: this.layers.grid,
    });

    return x;
  }

  get_highlight_parameters(date) {
    const x =
      (date_utils.diff(date, this.gantt_start, "hour") / this.options.step) *
      this.options.column_width;
    const y = 0;

    const width = this.options.column_width;
    const height =
      (this.options.bar_height + this.options.padding) * this.tasks.length +
      this.options.header_height +
      this.options.padding / 2;

    return [x, y, width, height];
  }

  make_dates() {
    for (let date of this.get_dates_to_draw()) {
      createSVG("text", {
        x: date.lower_x,
        y: date.lower_y,
        innerHTML: date.lower_text,
        class: "lower-text",
        append_to: this.layers.date,
      });

      if (date.upper_text) {
        const $upper_text = createSVG("text", {
          x: date.upper_x,
          y: date.upper_y,
          innerHTML: date.upper_text,
          class: "upper-text",
          append_to: this.layers.date,
        });

        // remove out-of-bound dates
        if ($upper_text.getBBox().x2 > this.layers.grid.getBBox().width) {
          $upper_text.remove();
        }
      }
    }
  }

  get_dates_to_draw() {
    let last_date = null;
    const dates = this.dates.map((date, i) => {
      const d = this.get_date_info(date, last_date, i);
      last_date = date;
      return d;
    });
    return dates;
  }

  get_date_info(date, last_date, i) {
    if (!last_date) {
      last_date = date_utils.add(date, 1, "year");
    }
    const date_text = {
      "Quarter Day_lower": date_utils.format(date, "HH", this.options.language),
      "Half Day_lower": date_utils.format(date, "HH", this.options.language),
      Day_lower:
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, "D", this.options.language)
          : "",
      Week_lower:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, "D MMM", this.options.language)
          : date_utils.format(date, "D", this.options.language),
      Month_lower: date_utils.format(date, "MMMM", this.options.language),
      Year_lower: date_utils.format(date, "YYYY", this.options.language),
      "Quarter Day_upper":
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, "D MMM", this.options.language)
          : "",
      "Half Day_upper":
        date.getDate() !== last_date.getDate()
          ? date.getMonth() !== last_date.getMonth()
            ? date_utils.format(date, "D MMM", this.options.language)
            : date_utils.format(date, "D", this.options.language)
          : "",
      Day_upper:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, "MMMM YYYY", this.options.language)
          : "",
      Week_upper:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, "MMMM", this.options.language)
          : "",
      Month_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, "YYYY", this.options.language)
          : "",
      Year_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, "YYYY", this.options.language)
          : "",
    };

    const base_pos = {
      x: i * this.options.column_width,
      lower_y: this.options.header_height,
      upper_y: this.options.header_height - 15,
    };

    const x_pos = {
      "Quarter Day_lower": (this.options.column_width * 4) / 2,
      "Quarter Day_upper": 0,
      "Half Day_lower": (this.options.column_width * 2) / 2,
      "Half Day_upper": 0,
      Day_lower: this.options.column_width / 2,
      Day_upper: (this.options.column_width * 30) / 2,
      Week_lower: 0,
      Week_upper: (this.options.column_width * 4) / 2,
      Month_lower: this.options.column_width / 2,
      Month_upper: (this.options.column_width * 12) / 2,
      Year_lower: this.options.column_width / 2,
      Year_upper: (this.options.column_width * 30) / 2,
    };

    return {
      upper_text: date_text[`${this.options.view_mode}_upper`],
      lower_text: date_text[`${this.options.view_mode}_lower`],
      upper_x: base_pos.x + x_pos[`${this.options.view_mode}_upper`],
      upper_y: base_pos.upper_y,
      lower_x: base_pos.x + x_pos[`${this.options.view_mode}_lower`],
      lower_y: base_pos.lower_y,
    };
  }

  make_bars() {
    this.bars = this.tasks.map((task) => {
      const bar = new Bar(this, task, this.options.view_mode);
      this.layers.bar.appendChild(bar.group);
      return bar;
    });
  }

  make_arrows() {
    this.arrows = [];

    // task.predecessors & task.successors are generated during import function
    // extracted from P6 XML file
    for (let task of this.tasks) {
      if (!task.predecessors) {
        continue;
      }

      let arrows = [];
      arrows = task.predecessors
        .map((task_id) => {
          const dependency = this.get_task(task_id);
          if (!dependency) return undefined;
          const arrow = new Arrow(
            this,
            this.bars[dependency._index], // from_task
            this.bars[task._index],
            this.showDeps // to_task
          );
          this.layers.arrow.appendChild(arrow.element);
          return arrow;
        })
        .filter(Boolean); // filter falsy values
      this.arrows = this.arrows.concat(arrows);
    }
  }

  map_arrows_on_bars() {
    for (let bar of this.bars) {
      bar.arrows = this.arrows.filter((arrow) => {
        return (
          arrow.from_task.task.id === bar.task.id ||
          arrow.to_task.task.id === bar.task.id
        );
      });
    }
  }

  set_width() {
    const cur_width = this.$svg.getBoundingClientRect().width;
    const actual_width = this.$svg
      .querySelector(".grid .grid-row")
      .getAttribute("width");
    if (cur_width < actual_width) {
      this.$svg.setAttribute("width", actual_width);
    }
  }

  set_scroll_position() {
    const parent_element = this.$svg.parentElement;
    if (!parent_element) return;

    //Sets cursor type for scrolling chart
    parent_element.style.cursor = "grab";

    let scroll_pos;

    // If/Else stops jumping of scroll bar when selecting SVGs. Also sets starting point.
    if (!this.last_scroll_position) {
      const hours_before_first_task = date_utils.diff(
        date_utils.today(),
        this.gantt_start,
        "hour"
      );

      scroll_pos =
        (hours_before_first_task / this.options.step) *
          this.options.column_width -
        this.options.column_width;

      this.last_scroll_position = scroll_pos;
    } else {
      scroll_pos = this.last_scroll_position;
    }

    parent_element.scrollLeft = scroll_pos;

    // Fixes skipping when dragging task bars into the past
    if (this.has_gantt_start_changed || !this.first_scroll_set) {
      this.trigger_event("scroll_change", [
        parent_element.scrollLeft,
        this.dates,
        parent_element.offsetWidth,
        this.has_gantt_start_changed,
        this.options.view_mode,
        this.days_gantt_start_changed,
      ]);

      this.first_scroll_set = true;
      this.has_gantt_start_changed = false;
      this.days_gantt_start_changed = 0;
    }

    $.on(parent_element, "scroll", ".gantt-container", (e) => {
      this.layers.date.setAttribute(
        "transform",
        "translate(0," + e.currentTarget.scrollTop + ")"
      );

      const offsetWidth = e.target.offsetWidth;

      // Only trigger on horizontal scroll
      if (
        this.last_parent_scroll &&
        this.last_parent_scroll !== parent_element.scrollLeft
      ) {
        this.trigger_event("scroll_change", [
          parent_element.scrollLeft,
          this.dates,
          offsetWidth,
          false,
          this.options.view_mode,
        ]);
      }
      this.last_parent_scroll = parent_element.scrollLeft;
    });

    //Removes context menu from right-clicking on chart
    parent_element.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    //DRAG TO SCROLL FEATURE
    //Creates a mousedown event listener
    $.on(
      parent_element,
      "mousedown",
      ".bar-wrapper, .handle, .gantt-container", // Listen for clicks on all of these
      (e, element) => {
        if (element.className !== "gantt-container") return; //Ignore if not on the background

        //Right-click to scroll
        if (e.button === 2) {
          //Changes cursor for user feedback
          parent_element.style.cursor = "col-resize";
          parent_element.style.userSelect = "none";

          //Gets base scroll position and mouse position
          const pos = {
            left: parent_element.scrollLeft,
            x: e.clientX,
          };

          //Event Listener Callbacks
          // Detects how far the mouse has moved and scrolls the chart accordingly
          const mouseMoveHandler = (e) => {
            const dx = e.clientX - pos.x;
            parent_element.scrollLeft = pos.left - dx;
          };

          //Clears listeners and returns to previous state
          const mouseUpHandler = () => {
            parent_element.style.cursor = "grab";
            parent_element.style.removeProperty("user-select");
            parent_element.removeEventListener("mousemove", mouseMoveHandler);
            parent_element.removeEventListener("mouseup", mouseUpHandler);
          };

          //Creates event listeners only during mousedown
          parent_element.addEventListener("mousemove", mouseMoveHandler);
          document.addEventListener("mouseup", mouseUpHandler);
        }
      }
    );
  }

  // Not very reliable
  set_critical_path_highlight() {
    this.bars.forEach((bar) => this.handle_gantt_critical_path(bar));
  }

  bind_grid_click() {
    $.on(
      this.$svg,
      this.options.popup_trigger,
      ".grid-row, .grid-header",
      () => {
        this.unselect_all();
        this.hide_popup();
      }
    );
  }

  // This function is where the bulk of the task processing occurs
  // Task bindings when interacting with bars
  bind_bar_events() {
    let is_dragging = false;
    let x_on_start = 0;
    let y_on_start = 0;
    let is_resizing_left = false;
    let is_resizing_right = false;
    let parent_bar_id = null;
    let bars = []; // instanceof Bar
    let ids = [];

    this.bar_being_dragged = null;

    this.last_scroll_position = null;

    function action_in_progress() {
      return is_dragging || is_resizing_left || is_resizing_right;
    }

    $.on(this.$svg, "mousedown", ".bar-wrapper, .handle", (e, element) => {
      const bar_wrapper = $.closest(".bar-wrapper", element);
      bar_wrapper.style.cursor = "ew-resize";

      if (element.classList.contains("left")) {
        is_resizing_left = true;
      } else if (element.classList.contains("right")) {
        is_resizing_right = true;
      } else if (element.classList.contains("bar-wrapper")) {
        is_dragging = true;
      }

      if (!action_in_progress()) return;

      bar_wrapper.classList.add("active");

      x_on_start = e.layerX;
      y_on_start = e.layerY;

      parent_bar_id = bar_wrapper.getAttribute("data-id");
      ids = [parent_bar_id];

      // Ensure bar is movable (not under sim or milestone)
      bars = ids
        .map((id) => this.get_bar(id))
        .filter(
          (bar) =>
            (!this.tasks_under_sim.find(
              (simTask) => simTask.id === bar.task.id
            ) &&
              !bar.task.milestone) ||
            !bar.task.id
        );

      this.bar_being_dragged = parent_bar_id;

      bars.forEach((bar) => {
        const $bar = bar.$bar;
        $bar.ox = $bar.getX();
        $bar.oy = $bar.getY();
        $bar.owidth = $bar.getWidth();
        $bar.finaldx = 0;
      });
    });

    $.on(this.$svg, "mousemove", (e) => {
      if (!action_in_progress()) return;
      const dx = e.layerX - x_on_start;
      // eslint-disable-next-line
      const dy = e.layerY - y_on_start;

      bars.forEach((bar) => {
        // Bar only moves when mouse is in x-y boundaries of bars.
        if (
          this.isSimulating ||
          !this.check_mouse_valid_bar_area(bars[0].$bar, e)
        ) {
          return;
        }

        const $bar = bar.$bar;
        $bar.finaldx = this.get_snap_position(dx);

        // Remove current risk when moving
        // If bar is moving the remove risk bars.
        if (
          (is_resizing_left || is_resizing_right || is_dragging) &&
          $bar.finaldx !== 0
        ) {
          bar.destroy_risk_bar_elements();
        }

        // Set of conditionals to update bar position based on activity.
        if (is_resizing_left) {
          if (parent_bar_id === bar.task.id) {
            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx,
              width: $bar.owidth - $bar.finaldx,
              left: true,
              finaldx: $bar.finaldx,
            });
          } else {
            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx,
            });
          }
        } else if (is_resizing_right) {
          if (parent_bar_id === bar.task.id) {
            bar.update_bar_position({
              width: $bar.owidth + $bar.finaldx,
              right: true,
              finaldx: $bar.finaldx,
            });
          }
        } else if (is_dragging) {
          bar.update_bar_position({
            x: $bar.ox + $bar.finaldx,
            finaldx: $bar.finaldx,
            drag: true,
          });
        }

        this.handle_gantt_critical_path(bar);
      });
    });

    document.addEventListener("mouseup", (e) => {
      if (is_dragging || is_resizing_left || is_resizing_right) {
        bars.forEach((bar) => bar.group.classList.remove("active"));
      }

      is_dragging = false;
      is_resizing_left = false;
      is_resizing_right = false;
    });

    $.on(this.$svg, "mouseup", ".bar-wrapper", (e, element) => {
      this.bar_being_dragged = null;

      const bar_wrapper = $.closest(".bar-wrapper", element);
      bar_wrapper.style.cursor = "pointer";

      bars.forEach((bar) => {
        bar.destroy_bar_label();
      });

      // Grab bars which have moved
      let tasksToBeUpdated = is_dragging
        ? this.set_bar_state(bars, e)
        : this.set_bar_state([bars[0]], e);

      if (tasksToBeUpdated.length > 0) {
        // Modify position of wbs bars
        const wbsTasks = this.recalculate_wbs_bar(tasksToBeUpdated);
        const dependantTasks = [];

        // From pre-baked dependency map, get task data from each bar
        // Put bars into tasks_under_sim array
        if (this.dependencyMap && parent_bar_id in this.dependencyMap) {
          for (const deps of this.dependencyMap[parent_bar_id]) {
            const depBar = this.get_bar(deps.id);
            if (depBar) {
              dependantTasks.push(depBar.task);
              this.tasks_under_sim.push(depBar.task);
              this.clear_risk_bar_state(depBar);
            } else {
              dependantTasks.push(deps);
              this.tasks_under_sim.push(deps);
            }
          }
        }

        const parentBar = this.get_bar(parent_bar_id);
        this.tasks_under_sim.push(parentBar.task);

        // Get predecessors of bar being dragged
        // Apply depParent field allows for task to be included in sim
        // but not updated on planner.
        if (this.dependencyMap && parentBar.task.predecessors) {
          for (const predecessor of parentBar.task.predecessors) {
            const predBar = this.get_bar(predecessor);
            let predTask = predBar ? predBar.task : this.allTasks[predecessor];

            if (!predTask) continue;

            predTask = {
              ...predTask,
              depParent: true,
            };

            dependantTasks.push(predTask);
          }
        }

        tasksToBeUpdated = [...tasksToBeUpdated, ...dependantTasks];

        // This is what passes the call to run the simulation
        this.trigger_event("update_risks", [
          this.metadata,
          tasksToBeUpdated,
          wbsTasks,
        ]);
      }
    });
  }

  recalculate_wbs_bar(tasks) {
    const processedWbs = [];
    const wbsTasks = [];

    for (const task of tasks) {
      if (!task.parentId || task.parentId in processedWbs) continue;

      let wbsBar = this.get_wbs_bar(task.parentId);
      const wbsSubtasks = wbsBar.task.subtasks.map(
        (id) => this.get_task(id) || this.allTasks[id]
      );

      const updatedWbsSubtasks = wbsSubtasks.map((iterTask) => {
        if (!iterTask) return false;

        const taskId = iterTask.id;

        for (const t of tasks) {
          if (taskId === t.id) {
            return t;
          }
        }

        return iterTask;
      });

      updatedWbsSubtasks.sort(
        (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
      );

      const newWbsStart =
        typeof updatedWbsSubtasks[0].start === "string"
          ? String(updatedWbsSubtasks[0].start)
          : updatedWbsSubtasks[0].start.toISOString();

      updatedWbsSubtasks.sort(
        (a, b) =>
          new Date(a.safetyEnd).getTime() - new Date(b.safetyEnd).getTime()
      );

      const newWbsEnd =
        typeof updatedWbsSubtasks[updatedWbsSubtasks.length - 1].safetyEnd ===
        "string"
          ? String(updatedWbsSubtasks[updatedWbsSubtasks.length - 1].safetyEnd)
          : updatedWbsSubtasks[
              updatedWbsSubtasks.length - 1
            ].safetyEnd.toISOString();

      const _start = date_utils.parse(newWbsStart);
      const _end = date_utils.parse(newWbsEnd);

      wbsBar.task._start = _start;
      wbsBar.task.start = newWbsStart;
      wbsBar.task._end = _end;
      wbsBar.task.end = newWbsEnd;
      wbsBar.task.safetyEnd = newWbsEnd;

      // Offset needed on task update
      wbsBar.destroy_bar_label();
      wbsBar.recalculate_bar_dimensions(1);
      wbsBar.update_bar_position({
        x: wbsBar.x,
        width: wbsBar.width,
        isWbs: true,
      });

      processedWbs.push(task.parentId);
      wbsTasks.push(wbsBar.task);
    }

    return wbsTasks;
  }

  clear_risk_bar_state(bar) {
    bar.risk_bars = [];
    bar.destroy_risk_bar_elements();
    bar.draw_risk_bar_label();
    //bar.destroy_dependency_warning_icon();
    bar.destroy_confidence_label();
  }

  set_bar_state(bars, e) {
    const returnTasks = [];

    bars.forEach((bar) => {
      if (!bar) return;
      const $bar = bar.$bar;
      if (!$bar.finaldx) return;
      if (this.check_mouse_valid_bar_area(bars[0].$bar, e)) {
        // Log position of last bar move in scroll to stop jumping on refresh
        this.last_scroll_position = this.$svg.parentElement.scrollLeft;
        bar.date_changed();
        returnTasks.push(bar.task);

        bar.recalculate_bar_dimensions();

        this.clear_risk_bar_state(bar);

        bar.set_action_completed();
      }
    });

    return returnTasks;
  }

  handle_gantt_critical_path(bar) {
    const $bar = bar.$bar;
    const doesBarXCrossFinishX = $bar.getX() + $bar.getWidth() > this.endDate_x;
    const isCriticalPathEnabled = $bar.getClass() === "bar critical";

    if (doesBarXCrossFinishX && !isCriticalPathEnabled) {
      this.trigger_critical_path(bar.task, true);
    } else if (!doesBarXCrossFinishX && isCriticalPathEnabled) {
      this.trigger_critical_path(bar.task, false);
    }
  }

  trigger_critical_path(task, isEnabled) {
    this.tasksToRefesh = [];
    let updateTasks = [];
    updateTasks = updateTasks.concat(task.predecessors);
    updateTasks.push(task.id);
    updateTasks = updateTasks.concat(task.successors);

    const newClass = isEnabled ? "bar critical" : "bar main";

    for (const id of updateTasks) {
      const pred_task = this.get_task(id);
      if (pred_task) {
        pred_task.isCriticalPath = isEnabled;
        const pred_bar = this.get_bar(id);
        pred_bar.$bar.setClass(newClass);
        this.tasksToRefesh.push(pred_task);
      }
    }

    task.isCriticalPath = isEnabled;
    return task;
  }

  // Check mouse against x-y bar boundaries + allowance
  check_mouse_valid_bar_area(bar, e) {
    const xOffset = 200,
      yOffset = 50;

    const leftButtonClick = e.button === 0;
    const withinLeftXRange = bar.getX() - xOffset < e.offsetX;
    const withinRightXRange = bar.getX() + bar.getWidth() + xOffset > e.offsetX;
    const withinBottomYRange = bar.getY() - yOffset < e.offsetY;
    const withinTopYRange = bar.getY() + bar.getHeight() + yOffset > e.offsetY;

    return (
      leftButtonClick &&
      withinLeftXRange &&
      withinRightXRange &&
      withinBottomYRange &&
      withinTopYRange
    );
  }

  // Old code to generate dependency list, not great for large projects with many-many relationships
  // No longer used, pre-baked dependency map is now used.
  get_all_dependent_tasks(task_id) {
    let out = [];
    let to_process = [task_id];
    while (to_process.length) {
      const deps = to_process.reduce((acc, curr) => {
        acc = acc.concat(this.dependency_map[curr]);
        return acc;
      }, []);

      out = out.concat(deps);
      // eslint-disable-next-line
      to_process = deps.filter((d) => !to_process.includes(d));
    }

    return out.filter(Boolean);
  }

  // Default function, no changes.
  get_snap_position(dx) {
    let odx = dx,
      rem,
      position;

    if (this.view_is(VIEW_MODE.WEEK)) {
      rem = dx % (this.options.column_width / 7);
      position =
        odx -
        rem +
        (rem < this.options.column_width / 14
          ? 0
          : this.options.column_width / 7);
    } else if (this.view_is(VIEW_MODE.MONTH)) {
      rem = dx % (this.options.column_width / 30);
      position =
        odx -
        rem +
        (rem < this.options.column_width / 60
          ? 0
          : this.options.column_width / 30);
    } else {
      rem = dx % this.options.column_width;
      position =
        odx -
        rem +
        (rem < this.options.column_width / 2 ? 0 : this.options.column_width);
    }

    return position;
  }

  unselect_all() {
    [...this.$svg.querySelectorAll(".bar-wrapper")].forEach((el) => {
      el.classList.remove("active");
    });
  }

  view_is(modes) {
    if (typeof modes === "string") {
      return this.options.view_mode === modes;
    }

    if (Array.isArray(modes)) {
      return modes.some((mode) => this.options.view_mode === mode);
    }

    return false;
  }

  get_task(id) {
    return this.tasks.find((task) => {
      return task.id === id;
    });
  }

  get_bar(id) {
    return this.bars.find((bar) => {
      return bar.task.id === id;
    });
  }

  get_wbs_bar(id) {
    return this.bars.find((bar) => {
      return bar.task.objectId === id;
    });
  }

  get_risk_bar(risk_bars, id) {
    return risk_bars.find((risk_bar) => {
      return risk_bar.id === id;
    });
  }

  show_popup(options) {
    if (!this.popup) {
      this.popup = new Popup(
        this.popup_wrapper,
        this.options.custom_popup_html
      );
    }
    this.popup.show(options);
  }

  show_detailed_popup(options) {
    if (!this.popup) {
      this.popup = new Popup(
        this.popup_wrapper,
        this.options.custom_popup_html
      );
    }
    this.popup.show_detailed(options);
  }

  hide_popup() {
    this.popup && this.popup.hide();
  }

  trigger_event(event, args) {
    if (this.options["on_" + event]) {
      this.options["on_" + event].apply(null, args);
    }
  }

  getStringifyHash(data) {
    return this.getHash(JSON.stringify(data));
  }

  getHash(string) {
    return Hex.stringify(md5(string));
  }

  /**
   * Gets the oldest starting date from the list of tasks
   *
   * @returns Date
   * @memberof Gantt
   */
  get_oldest_starting_date() {
    return this.tasks
      .map((task) => task._start)
      .reduce((prev_date, cur_date) =>
        cur_date <= prev_date ? cur_date : prev_date
      );
  }

  /**
   * Clear all elements from the parent svg element
   *
   * @memberof Gantt
   */
  clear() {
    this.$svg.innerHTML = "";
  }
}

Gantt.VIEW_MODE = VIEW_MODE;

function generate_id(task) {
  return task.title + "_" + Math.random().toString(36).slice(2, 12);
}
