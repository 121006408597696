import styled from "styled-components";
import { flex, grays, spacing, fontSize } from "styles";

export const StyledOverview = styled.div`
  ${flex("row", "center", "center")};
  width: 100%;
  border-top: 0.1rem solid ${grays.light};
  position: relative;
  margin-top: 3rem;

  > .label {
    position: absolute;
    left: 0;
    top: 0;
    background: ${grays.veryLight};
    width: 20%;
    padding: 0.3rem ${spacing.s};
    font-size: ${fontSize.s};
    font-weight: 500;
  }

  > div:not(:last-of-type) {
    border-right: 0.1rem solid ${grays.veryLight};
  }

  > div {
    ${flex("column", "flex-end", "center")};

    width: ${100 / 6}%;
    height: 10rem;

    p {
      text-align: center;
    }

    .icon-container {
      ${flex("row", "center", "center")}
      width: 100%;
      margin-bottom: ${spacing.s};

      .icon {
        margin-right: ${spacing.m};
        height: 2rem;
        width: 2rem;
      }

      > p {
        font-size: ${fontSize.m};
        font-weight: 500;
      }
    }

    > p {
      color: ${grays.dark};
      text-transform: uppercase;
    }
  }

  div.disabled {
    > div,
    p {
      opacity: 0.3;
    }
  }
`;
