import { useEffect } from "react";
import { fetchForecast } from "Pages/projects/thunks";
import { useAppDispatch, useAppSelector } from "store";

import { selectActiveSiteId } from "store/auth";
import {
  clearForecastState,
  selectDailyForecast,
  selectForecastError,
  selectForecastSiteId,
} from "store/forecast";
import { IDailyForecast } from "@ehabitation/ts-utils/browser";

interface IUseForecast {
  dailyForecasts: IDailyForecast[];
  forecastSiteId: string | null;
  error?: string;
}

export const useForecast = (): IUseForecast => {
  const dispatch = useAppDispatch();

  const siteId = useAppSelector(selectActiveSiteId);

  // Fetch forecast when siteId appears/changes
  useEffect(() => {
    if (!siteId) return;
    dispatch(clearForecastState());
    dispatch(fetchForecast());
  }, [siteId, dispatch]);

  const dailyForecasts = useAppSelector(selectDailyForecast);
  const forecastSiteId = useAppSelector(selectForecastSiteId);
  const error = useAppSelector(selectForecastError);

  return {
    dailyForecasts,
    forecastSiteId,
    error,
  };
};
