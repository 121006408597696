import { useCallback } from "react";
import { useAppDispatch } from "store";
import { closeSettingsMenu } from "store/menu";
import { StyledBackdrop } from "elements";

const Backdrop = () => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(closeSettingsMenu());
  }, [dispatch]);

  return (
    <StyledBackdrop onClick={handleClick} data-testid="main-menu-backdrop" />
  );
};

export default Backdrop;
