import { analytics } from "firebaseConfig";
import { ICustomClaims } from "@ehabitation/ts-utils/browser";
import {
  logEvent as logFireBaseEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";

export type SitePagesType =
  | "weather"
  | "plan"
  | "mitigations"
  | "variance"
  | "tra"
  | "risk-drivers";

const logEvent = (eventName: string, params?: any) => {
  logFireBaseEvent(analytics, eventName, params);
};

export const setAnalyticsUserProperties = (claims?: ICustomClaims) => {
  try {
    claims && setUserProperties(analytics, { ...claims });
  } catch (error) {
    console.error(error);
  }
};

export const setAnalyticsUserId = (userId: string) => {
  try {
    setUserId(analytics, userId);
  } catch (error) {
    console.error(error);
  }
};

export const logOpenSiteEvent = (siteId: string) => {
  try {
    logEvent("open_site", { siteId });
  } catch (error) {
    console.error(error);
  }
};

export const logOpenPlannerEvent = (siteId: string) => {
  try {
    logEvent("open_planner", { siteId });
  } catch (error) {
    console.error(error);
  }
};

export const logTaskModifyEvent = (planId: string, siteId: string) => {
  try {
    logEvent("task_modify", { planId, siteId });
  } catch (error) {
    console.error(error);
  }
};

export const logSimulationTriggeredEvent = (
  planId: string,
  siteId: string,
  simulationId: string
) => {
  try {
    logEvent("trigger_simulation", { planId, siteId, simulationId });
  } catch (error) {
    console.error(error);
  }
};

export const logImportPlanEvent = (siteId: string, planId: string) => {
  try {
    logEvent("import_plan", { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export enum ExportTypes {
  Baseline = "weather_baseline",
  OraclePrimaveraCalendar = "weather_baseline",
  AcumenRiskCalendar = "acumen_risk_calendar",
  SafranRiskCalendar = "safran_risk_calendar",
  AcumenRiskSiteCalendar = "acumen_risk_site_calendar",
  PRARiskCalendar = "pra_risk_calendar",
  AdjustmentCalendars = "adjustment_calendars",
  HistoricalWeather = "historical_weather",
  HistoricalSiteStats = "historical_site_statistics",
  TaskActivity = "task_activity",
}

export const logExportEvent = ({
  planId,
  siteId,
  exportType,
}: {
  planId?: string;
  siteId?: string;
  exportType: ExportTypes;
}) => {
  try {
    logEvent("export_" + exportType, { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export const logMappingEvent = ({
  planId,
  orgId,
}: {
  planId: string;
  orgId: string;
}) => {
  try {
    logEvent("plnx_mapping", { planId, orgId });
  } catch (error) {
    console.error(error);
  }
};

export const logSafranProjectEvent = ({
  planId,
  orgId,
}: {
  planId: string;
  orgId: string;
}) => {
  try {
    logEvent("safran_project", { planId, orgId });
  } catch (error) {
    console.error(error);
  }
};

export const logSafranRiskEvent = ({
  planId,
  orgId,
}: {
  planId: string;
  orgId: string;
}) => {
  try {
    logEvent("safran_risk", { planId, orgId });
  } catch (error) {
    console.error(error);
  }
};

export const logLoginEvent = (method = "password") => {
  try {
    logEvent("login", { method });
  } catch (error) {
    console.error(error);
  }
};

export const logSignupEvent = (method = "password") => {
  try {
    logEvent("sign_up", { method });
  } catch (error) {
    console.error(error);
  }
};

export const logCreatePersonalSiteEvent = (siteId: string) => {
  try {
    logEvent("site_create_personal", { siteId });
  } catch (error) {
    console.error(error);
  }
};

export const logDeletePersonalSiteEvent = (siteId: string) => {
  try {
    logEvent("site_delete_personal", { siteId });
  } catch (error) {
    console.error(error);
  }
};

export const logOpenSitePage = (
  type: SitePagesType,
  siteId: string,
  planId?: string
) => {
  try {
    logEvent("open_" + type + "_site_page", { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export const logOpenSitePrintPage = (
  type: SitePagesType,
  siteId: string,
  planId?: string
) => {
  try {
    logEvent("print_" + type + "_site_page", { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export const logSimulateMitigations = (siteId: string, planId: string) => {
  try {
    logEvent("simulate_mitigations", { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export const logSimulateBase = (siteId: string, planId: string) => {
  try {
    logEvent("simulate_base", { siteId, planId });
  } catch (error) {
    console.error(error);
  }
};

export const logVarianceDiff = (
  siteId: string,
  planId: string,
  comparisonPlanId: string
) => {
  try {
    logEvent("variance_diff", { siteId, planId, comparisonPlanId });
  } catch (error) {
    console.error(error);
  }
};

export const logRiskMatrixView = (siteId: string, riskMatrixId: string) => {
  try {
    logEvent("open_riskmatrix", { siteId, riskMatrixId });
  } catch (error) {
    console.error(error);
  }
};

export const logRiskMatrixModify = (siteId: string, riskMatrixId: string) => {
  try {
    logEvent("modify_riskmatrix", { siteId, riskMatrixId });
  } catch (error) {
    console.error(error);
  }
};

export const logOpenAdmin = () => {
  try {
    logEvent("open_admin");
  } catch (error) {
    console.error(error);
  }
};

export const logOpenSuperAdmin = () => {
  try {
    logEvent("open_super_admin");
  } catch (error) {
    console.error(error);
  }
};
