import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  updateDoc,
  where,
} from "firebase/firestore";
import { MutableRefObject } from "react";
import { batch } from "react-redux";

import {
  CollectionType,
  ISimulation,
  ISite,
  ITask,
  ITaskCategory,
  transformTaskDoc,
  WeatherKey,
} from "@ehabitation/ts-utils/browser";
import { db } from "firebaseConfig";
import {
  getLastCompleteSimulationQuery,
  getLastSimulationQuery,
  getPlanRiskMatrixQuery,
  getSimulationResults,
  getTasksQuery,
} from "helpers";
import { thresholdUnits } from "Pages/sites/weather/WeatherView";
import { AppThunk } from "store";
import { selectActiveSiteId, updateUserState } from "store/auth";
import { setCurrentSite } from "store/project";
import { clickTab } from "store/tabs";
import {
  setAllTasks,
  setCurrentPlanSimulation,
  setCurrentPlanSimulationResults,
  setCurrentTaskId,
  setLatestCurrentPlanSimulation,
  setLoadedPlanId,
  setTasksSnapshot,
  setTransientData,
} from "store/tasks";
import {
  setIsLoadingPlan,
  setIsRevertingToSnapshot,
  setIsUpdating,
  setSelectedChart,
  setTaskModified,
} from "store/ui";
import { ITaskObj, weatherUnitKey } from "types";

interface UnsubscriptionRefs {
  tasksUnsubscribe: () => void | undefined;
  simulationsUnsubscribe: () => void | undefined;
  latestSimulationsUnsubscribe: () => void | undefined;
}

export const applyRiskMatrix = (task: ITask, riskMatrix: ITaskCategory[]) => {
  const isCustom = task.taskType === "Custom";
  const isMitigated = task.isMitigated;
  if (isCustom || isMitigated) return task;
  const categoryName = task.taskCategory?.selectedName;
  const category = riskMatrix.find(({ name }) => name === categoryName);
  if (!category) return task;
  for (const threshold in WeatherKey) {
    const unitKey = weatherUnitKey[threshold as WeatherKey];
    const categoryThreshold = category.thresholds[threshold as WeatherKey];
    if (typeof categoryThreshold === "number") {
      task[threshold as WeatherKey] = categoryThreshold;
      const unit = thresholdUnits[threshold as WeatherKey];
      // @ts-expect-error couldn't get rid of Type 'string' is not assignable to type 'never'. error
      task[unitKey] = unit;
    } else {
      delete task[threshold as WeatherKey];
      delete task[unitKey];
    }
  }
  return task;
};

export const getPlanCategories = async (planId: string) => {
  const riskMatrixDocs = await getDocs(getPlanRiskMatrixQuery(planId));
  if (riskMatrixDocs.empty) {
    throw new Error("No risk matrix found for plan");
  }

  const riskMatrixCategorySnap = await getDocs(
    query(
      collection(
        db,
        riskMatrixDocs.docs[0].ref.path,
        CollectionType.Categories
      ),
      where("level", "==", "category")
    )
  );

  const riskMatrix = riskMatrixCategorySnap.docs.map(
    (doc) => doc.data() as ITaskCategory
  );
  return riskMatrix;
};

export const selectPlanById: AppThunk =
  (planId: string, unsubscribeRef: MutableRefObject<UnsubscriptionRefs>) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentSite = state.project.currentSite;
    const currentUserState = state.auth.userState;
    const currentSelectedTaskId = state.task.currentTaskId;
    const siteId = selectActiveSiteId(state);

    if (!currentSite || !siteId) return;

    // force unsubscribe from previous tasks
    if (unsubscribeRef?.current?.tasksUnsubscribe) {
      unsubscribeRef?.current?.tasksUnsubscribe();
    }
    // force unsubscribe from previous latest simulation
    if (unsubscribeRef?.current?.latestSimulationsUnsubscribe) {
      unsubscribeRef?.current?.latestSimulationsUnsubscribe();
    }

    // force unsubscribe from previous simulation
    if (unsubscribeRef?.current?.simulationsUnsubscribe) {
      unsubscribeRef?.current?.simulationsUnsubscribe();
    }

    dispatch(clickTab("Gantt")); //Need to switch to gantt tab to prevent selector funtions from breaking (a result of not accounting for undefined)

    // clear state for previously loaded plan
    batch(() => {
      dispatch(setIsLoadingPlan(true));
      dispatch(
        setCurrentSite({
          ...currentSite,
          plan: planId,
        })
      );
      dispatch(setSelectedChart("weather"));
      dispatch(setCurrentTaskId(null));
      dispatch(setTransientData({}));
      dispatch(setAllTasks(null));
      dispatch(setTasksSnapshot(null));
      dispatch(setLatestCurrentPlanSimulation(null));
      dispatch(setCurrentPlanSimulation(null));
      dispatch(setCurrentPlanSimulationResults(null));
      dispatch(setTaskModified(false));
    });

    // handler for new loaded tasks and updates to existing plan tasks
    const handleTasksSnapshot = async (querySnapshot: any) => {
      const newTasks: ITaskObj = {};
      const riskMatrixCategories = await getPlanCategories(planId);

      querySnapshot.forEach((doc: any) => {
        const task = transformTaskDoc(doc.id, doc.data()) as ITask;

        newTasks[doc.id] = applyRiskMatrix(task, riskMatrixCategories);
      });

      batch(() => {
        dispatch(setAllTasks(newTasks));
        dispatch(setTasksSnapshot(newTasks));
        dispatch(setLoadedPlanId(planId));
        dispatch(setIsLoadingPlan(false));
      });
    };

    const tasksQuery = getTasksQuery(planId);
    // Set unsubscription ref for tasks
    if (!unsubscribeRef.current)
      unsubscribeRef.current = {} as UnsubscriptionRefs;
    unsubscribeRef.current.tasksUnsubscribe = onSnapshot(
      tasksQuery,
      handleTasksSnapshot
    );

    const handleLatestSimulationSnapshot = async (
      querySnapshot: QuerySnapshot
    ) => {
      querySnapshot.forEach(async (doc: any) => {
        const simulation = doc.data() as ISimulation;
        dispatch(setLatestCurrentPlanSimulation(simulation));
      });
    };

    const latestSimulationQuery = getLastSimulationQuery(planId, siteId);

    unsubscribeRef.current.latestSimulationsUnsubscribe = onSnapshot(
      latestSimulationQuery,
      handleLatestSimulationSnapshot
    );

    const handleSimulationSnapshot = async (querySnapshot: QuerySnapshot) => {
      querySnapshot.forEach(async (doc: any) => {
        const simulation = doc.data() as ISimulation;
        dispatch(setCurrentPlanSimulation(simulation));

        const simulationResults = await getSimulationResults(
          simulation?.resultsFileWithDependencies
        );

        dispatch(setCurrentPlanSimulationResults(simulationResults));

        if (currentSelectedTaskId) {
          dispatch(setCurrentTaskId(currentSelectedTaskId));
        }
      });
    };

    const simulationsQuery = getLastCompleteSimulationQuery(planId);
    // todo listen to simulation results.
    // subscription
    unsubscribeRef.current.simulationsUnsubscribe = onSnapshot(
      simulationsQuery,
      handleSimulationSnapshot
    );
    dispatch(
      updateUserState({
        ...currentUserState,
      })
    );

    dispatch(clickTab("Gantt"));
  };

export const revertToSnapshots: AppThunk = () => async (dispatch, getState) => {
  const state = getState();
  const currentUserState = state.auth.userState;
  const tasksSnapshot = state.task.tasksSnapshot;

  if (!tasksSnapshot) return;

  batch(() => {
    dispatch(setAllTasks(tasksSnapshot));
    dispatch(
      updateUserState({
        ...currentUserState,
      })
    );
    dispatch(setIsRevertingToSnapshot(true));
    dispatch(setTaskModified(false));
  });
};

export const updateSnapshots: AppThunk = () => async (dispatch, getState) => {
  const state = getState();
  const allTasks = state.task.allTasks!;

  if (!allTasks) return;

  batch(() => {
    dispatch(setTasksSnapshot(allTasks));
  });
};

/**
 * Set mainPlanId on current site
 *
 *
 * @param {ISite} site
 * @param {string} planId
 * @return {void}
 */
export const setAsMainPlan: AppThunk =
  (site: ISite, planId: string) => async (dispatch) => {
    if (planId !== site?.mainPlanId) {
      dispatch(setIsUpdating(true));
      try {
        const sitesCollection = collection(db, "sites");
        const siteDoc = doc(sitesCollection, site.id);
        await updateDoc(siteDoc, {
          mainPlanId: planId,
        });
        dispatch(setCurrentSite({ ...site!, mainPlanId: planId }));
      } catch (error) {
        console.error(error);
        batch(() => {
          dispatch(setIsUpdating(false));
          dispatch(setCurrentSite({ ...site!, mainPlanId: site?.mainPlanId }));
        });
      }
      dispatch(setIsUpdating(false));
    }
  };
