//React
import React, { FC } from "react";
//Styles
import { StyledCustomAlert } from "./styles";

interface IProps {
  message: string;
  handleCancel: () => void;
  handleDiscard: () => void;
}

const DiscardAlert: FC<IProps> = ({ message, handleCancel, handleDiscard }) => {
  return (
    <StyledCustomAlert>
      <div
        className="backdrop"
        onClick={handleCancel}
        data-testid="discard-backdrop"
      />

      <div className="content" data-testid="discard-alert">
        <div className="message">
          <p>{message}</p>
        </div>

        <div className="buttons">
          <button onClick={handleDiscard} data-testid="submit-discard">
            Discard
          </button>
          <button onClick={handleCancel} data-testid="cancel-discard">
            Cancel
          </button>
        </div>
      </div>
    </StyledCustomAlert>
  );
};

export default DiscardAlert;
