import React, { FC } from "react";
import { StyledTitleInput } from "./styles";
import { BiPencil } from "react-icons/bi";
import { RiSaveLine } from "react-icons/ri";
import { useInputEdit } from "../useInputEdit";

export interface IProps {
  stateValue: string;
  disabled: boolean;
}

const TitleInput: FC<IProps> = ({ stateValue, disabled }) => {
  const {
    mode,
    inputValue,
    updateLocalState,
    saveTitleChanges,
    setToEditMode,
    handleKeyPressSave,
  } = useInputEdit({ stateValue, type: "title" });

  return (
    <StyledTitleInput className="pb-4" data-testid="title-input-parent">
      {mode === "edit" ? (
        <input
          value={inputValue}
          onChange={updateLocalState}
          onKeyPress={handleKeyPressSave}
          type="text"
          name="name"
          autoFocus
          data-testid="title-input"
        />
      ) : (
        <h2 data-testid="title">{stateValue}</h2>
      )}

      {!disabled && (
        <div className="icon-container">
          {mode === "edit" ? (
            <button onClick={saveTitleChanges}>
              <RiSaveLine className="save icon" data-testid="title-save-icon" />
            </button>
          ) : (
            <button onClick={setToEditMode}>
              <BiPencil className="edit icon" data-testid="title-edit-icon" />
            </button>
          )}
        </div>
      )}
    </StyledTitleInput>
  );
};

export default TitleInput;
