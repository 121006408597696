import React, { FC, memo } from "react";
import { Milestone } from "@ehabitation/ts-utils/browser";

interface IProps {
  start: string;
  milestone: Milestone | undefined;
}

const StartCell: FC<IProps> = ({ start, milestone }) => {
  return (
    <div className="cell-date" data-testid="cell-start">
      {milestone && milestone === "Finish Milestone" ? "" : start}
    </div>
  );
};

export default memo(StartCell);
