import { useRef, useEffect, useCallback } from "react";

export const useIsMounted = () => {
  const isMountedRef = useRef<boolean>(false);

  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMounted;
};
