import { FC, useEffect, useState } from "react";
import { useAppSelector } from "store";
import { selectCurrentTab, tasksSnapshotSelector } from "store/tasks";
import { selectCurrentTaskId, allTasksSelector } from "store/tasks";
import { StyledTabContainer } from "./styles";
import Impact from "Components/Impact/Impact";
import ThresholdsTab from "Components/ThresholdsTab/ThresholdsTab";
import Risk from "Components/Risk/Risk";
import Information from "Components/Information/Information";
import { useProcessTaskUpdates } from "../../../hooks/useProcessTaskUpdates";
import { tasksEquivalent } from "helpers";

const TabContainer: FC = () => {
  const allTasks = useAppSelector(allTasksSelector);
  const currentTab = useAppSelector(selectCurrentTab);
  const currentTaskId = useAppSelector(selectCurrentTaskId);
  const { handleTaskUpdate } = useProcessTaskUpdates();
  const tasksSnapshot = useAppSelector(tasksSnapshotSelector);

  const [lastTab, setLastTab] = useState("Gantt");
  const [lastTaskId, setLastTaskId] = useState("");

  useEffect(() => {
    const taskHasChanged = lastTaskId !== currentTaskId;
    const tabHasChanged = lastTab !== currentTab;
    if (tabHasChanged || taskHasChanged) {
      const lastTask = allTasks![lastTaskId];
      const updateTask = taskHasChanged ? lastTask : undefined;

      if (
        updateTask &&
        !tasksEquivalent(tasksSnapshot?.[updateTask.id], updateTask)
      ) {
        handleTaskUpdate([updateTask]);
      }

      setLastTab(currentTab);
      setLastTaskId(currentTaskId!);
    }
  }, [
    currentTab,
    lastTab,
    currentTaskId,
    allTasks,
    lastTaskId,
    handleTaskUpdate,
  ]);

  return (
    <StyledTabContainer off={currentTab === "Gantt"}>
      {/* Components for the different tabs will go here */}
      {currentTab === "Info" && <Information />}
      {currentTab === "Thresholds" && (
        <ThresholdsTab />
      )}
      {currentTab === "Impact" && <Impact />}
      {currentTab === "Risk" && <Risk />}
    </StyledTabContainer>
  );
};

export default TabContainer;
