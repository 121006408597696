import { FC, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  isGuest as roleIsGuest,
  IPlan,
  ISite,
} from "@ehabitation/ts-utils/browser";
import {
  StyledPlanConfiguratorPanel,
  StyledDraftsContainer,
  StyledMainPlanContainer,
  StyledPlanInfo,
  StyledButton,
  StyledCircularIconButton,
} from "elements";
import { useAppSelector } from "store";
import { selectRole, selectUserId } from "store/auth";
import { sortPlans } from "helpers/firebase/firestoreUtils";
import { BiTrash } from "react-icons/bi";
import { i } from "mathjs";

interface IPlanConfiguratorPanelProps {
  plans: IPlan[];
  currentSite: ISite;
  selectedPlan: string | undefined;
  togglePanel: (visibility: boolean) => void;
  handleSelectPlan: (planId: string) => void;
  setMainPlan?: (site: ISite, planId: string) => void;
  setDiscardPlanModal?: (id: string) => void;
  isShort: boolean;
}

export const Arrow = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: "absolute", left: "2rem" }}
  >
    <path d="M10 6L0 0.226497L0 11.7735L10 6Z" fill="#6D6E70" />
  </svg>
);

const Plan = ({
  plan,
  isSelected,
  onSelect,
  setMain,
  onDiscard,
}: {
  plan: IPlan;
  isSelected: boolean;
  onSelect?: (id: string) => void;
  setMain?: (id: string) => void;
  onDiscard?: (id: string) => void;
}) => {
  const userId = useAppSelector(selectUserId);
  var discardPressed = false;

  return (
    <StyledPlanInfo
      className="plan-select"
      role="button"
      onClick={() => {
        if (!isSelected && onSelect && !discardPressed) {
          onSelect(plan.id);
        } else if (discardPressed) {
          discardPressed = false;
        }
      }}
      key={plan.id}
      isSelected={isSelected}
    >
      {isSelected && <Arrow />}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{plan.title}</span>
        <div className="flex gap-2">
          {plan.createdAt && (
            <small>{`Created on ${format(
              plan.createdAt,
              "dd/MM/yyyy HH:mm"
            )}`}</small>
          )}
          {plan.owner === userId && <small>by you</small>}
        </div>
      </div>

      {!!onDiscard ? (
        <StyledCircularIconButton
          onClick={() => {
            // button is embedded, let the parent button know this button was clicked
            discardPressed = true;
            onDiscard(plan.id);
          }}
        >
          <BiTrash />
        </StyledCircularIconButton>
      ) : null}
      {setMain ? (
        <StyledButton onClick={() => setMain(plan.id)}>Set Main</StyledButton>
      ) : null}
    </StyledPlanInfo>
  );
};

const PlanConfiguratorPanel: FC<IPlanConfiguratorPanelProps> = ({
  plans,
  currentSite,
  selectedPlan,
  togglePanel,
  handleSelectPlan,
  setMainPlan,
  setDiscardPlanModal,
  isShort,
}) => {
  const userRole = useAppSelector(selectRole);
  const userId = useAppSelector(selectUserId);
  const isGuest = roleIsGuest(userRole!);
  const sortedPlans = useMemo(() => (plans ? sortPlans(plans) : []), [plans]);
  const mainPlan = sortedPlans.find(
    (plan) => plan.id === currentSite.mainPlanId
  );
  const draftPlans = sortedPlans.filter(
    (plan) => plan.id !== currentSite.mainPlanId
  );
  const isMainPlanSelected = !!(mainPlan && selectedPlan === mainPlan.id);

  const setMain = (planId: string) => {
    setMainPlan && setMainPlan(currentSite, planId);
    togglePanel(false);
  };

  const selectPlan = (planId: string) => {
    handleSelectPlan(planId);
    togglePanel(false);
  };

  const userDrafts = draftPlans.filter((plan) => plan.owner === userId);
  const otherUserDrafts = draftPlans.filter((plan) => plan.owner !== userId);

  return (
    <StyledPlanConfiguratorPanel className="shadow-md" isShort={isShort}>
      <StyledMainPlanContainer>
        <h3>Main Plan</h3>
        {mainPlan ? (
          <Plan
            plan={mainPlan}
            isSelected={isMainPlanSelected}
            onSelect={selectPlan}
          />
        ) : (
          <StyledPlanInfo disabled>
            <i>No Main Plan Selected</i>
          </StyledPlanInfo>
        )}
      </StyledMainPlanContainer>
      <StyledDraftsContainer>
        <div className="flex self-stretch gap-2 justify-between">
          <h3>Your Drafts</h3>
        </div>
        {userDrafts.length ? (
          userDrafts.map((plan) => {
            const isSelected = selectedPlan === plan.id;
            const canSetAsMain = Boolean(
              !isGuest && isSelected && plan.id !== currentSite.id
            );
            return (
              <Plan
                key={plan.id}
                plan={plan}
                isSelected={isSelected}
                onSelect={selectPlan}
                setMain={canSetAsMain && !!setMainPlan ? setMain : undefined}
                onDiscard={setDiscardPlanModal}
              />
            );
          })
        ) : (
          <StyledPlanInfo disabled>
            <i>None</i>
          </StyledPlanInfo>
        )}
      </StyledDraftsContainer>
      <StyledDraftsContainer>
        <h3>Drafts</h3>
        {otherUserDrafts?.length ? (
          otherUserDrafts.map((plan) => {
            const isSelected = selectedPlan === plan.id;
            const canSetAsMain = Boolean(
              !isGuest && isSelected && plan.id !== currentSite.id
            );
            return (
              <Plan
                key={plan.id}
                plan={plan}
                isSelected={isSelected}
                onSelect={selectPlan}
                setMain={canSetAsMain ? setMain : undefined}
              />
            );
          })
        ) : (
          <StyledPlanInfo disabled>
            <i>None</i>
          </StyledPlanInfo>
        )}
      </StyledDraftsContainer>
    </StyledPlanConfiguratorPanel>
  );
};

export default PlanConfiguratorPanel;
