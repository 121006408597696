import { FC } from "react";
import {
  LoadingBackdrop,
  LoadingBackdropAlt,
  LoadingBackdropSubscriptions,
} from "./styles";
import { CircularProgress } from "@material-ui/core";

export enum LoadingType {
  full = "full",
  alt = "alt",
  subscriptions = "subscriptions",
}

const LoadingWrapperMap = {
  [LoadingType.full]: LoadingBackdrop,
  [LoadingType.alt]: LoadingBackdropAlt,
  [LoadingType.subscriptions]: LoadingBackdropSubscriptions,
};

interface IProps {
  message?: string;
  type?: LoadingType;
  loading?: boolean;
  white?: boolean;
}

const LoadingWrapper: FC<IProps> = ({
  message = "Loading",
  type = LoadingType.alt,
  loading,
  children,
  white,
}) => {
  const Loader = LoadingWrapperMap[type];
  return (
    <>
      {loading ? (
        <Loader>
          <div className={`text-center ${white ? "text-white" : ""}`}>
            <CircularProgress color="inherit" />
            <p className={`mt-4 text-3xl ${white ? "text-white" : ""}`}>
              {message}
            </p>
          </div>
        </Loader>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingWrapper;
