import { SimulationLevel } from "helpers";

import clearSvgPath from "assets/icons/weather/clear.svg";
import snow24hr from "assets/icons/weather/snow24hr.svg";
import snow from "assets/icons/weather/snow.svg";
import rainfall from "assets/icons/weather/rainfall.svg";
import rain from "assets/icons/weather/rain.svg";
import wind from "assets/icons/weather/wind.svg";
import cloudy from "assets/icons/weather/cloudy.svg";
import wave from "assets/icons/weather/wave.svg";
import windGust from "assets/icons/weather/windGust.svg";
import minTempSvgPath from "assets/icons/weather/minTemp.svg";
import maxTempSvgPath from "assets/icons/weather/maxTemp.svg";

export const impactMitigationLevels: {
  key: SimulationLevel;
  label: string;
}[] = [
  {
    key: "min",
    label: "Min",
  },
  {
    key: "max",
    label: "Max",
  },
  {
    key: "20",
    label: "P-20",
  },
  {
    key: "40",
    label: "P-40",
  },
  {
    key: "80",
    label: "P-80",
  },
];

export const nonBreakingSpace = "\u00A0";
export const getRowHeight = () => 25.195;
export const isOldFormatId = (id: string) => !id.includes("$$");

export const weatherKeyIcons: any = {
  temp: clearSvgPath,
  windSpeed: wind,
  cumulative: rain,
  rainfall: rainfall,
  snowfall: snow,
  waveHeight: wave,
  windGusts: windGust,
  snowfall24Hour: snow24hr,
  visibility: cloudy,
  minTemp: minTempSvgPath,
  maxTemp: maxTempSvgPath,
  wind: wind,
  dailyRainAcc: rain,
  hourlyRainAcc: rainfall,
};
