import styled from "styled-components";
import { flex, fontSize, spacing } from "styles";

export const StyledInitialising = styled.main`
  ${flex("column", "center", "center")};
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;

  .welcome {
    ${flex("row", "center", "center")};

    img {
      margin-left: ${spacing.m};
    }
  }

  p {
    font-size: ${fontSize.l};
    margin-top: ${spacing.m};
  }
`;
