import React, { FC } from "react";
import { IProjectWithSites } from "types";
import { StyledTableRow } from "./styles";
import { StyledAdminIconButton } from "elements";
import { Menu, MenuItem } from "@material-ui/core";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useHandleContextMenuActions } from "./useHandleContextMenuActions";
import { useAppSelector } from "store";
import {
  selectNoOfSitesByDivision,
  selectNoOfUsersByDivision,
} from "store/divisionManagement";
import { useFeatureFlag } from "hooks";
import { IDivision, UserRole } from "@ehabitation/ts-utils/browser";

interface IProps {
  projects: IProjectWithSites[];
  division: IDivision;
}

const DivisionRow: FC<IProps> = ({ projects, division }) => {
  const { id, name, visibility } = division;

  const {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
    handleDeleteDivision,
    handleEditDivision,
  } = useHandleContextMenuActions(id);

  const noOfSites = useAppSelector(selectNoOfSitesByDivision);
  const noOfUsers = useAppSelector(selectNoOfUsersByDivision);

  const hasDeleteFeatureFlag = useFeatureFlag("delete");

  return (
    <StyledTableRow data-testid="division-row">
      <div className="name" data-testid="division-name">
        <p>{name}</p>
      </div>

      <div className="projects">
        {projects.length > 0 ? (
          projects.map(({ id: projectId, name: projectName, projectCode }) => {
            return (
              <p key={projectId}>
                {projectCode ? `[${projectCode}] ` : ""} {projectName}
              </p>
            );
          })
        ) : (
          <p>
            <em>No projects to display</em>
          </p>
        )}
      </div>

      <div className="sites">{noOfSites[id!] ?? 0}</div>

      <div className="users">{noOfUsers[id!] ?? 0}</div>

      <div className="visibility" data-testid="division-row-visibility">
        {visibility ?? UserRole.planner}
      </div>

      <div className="icon">
        <StyledAdminIconButton
          onClick={handleOpenContextMenu}
          data-testid="context-menu-button"
        >
          <BiDotsVerticalRounded />
        </StyledAdminIconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseContextMenu}
        >
          <MenuItem
            onClick={handleEditDivision}
            data-testid="division-row-edit-button"
          >
            Edit
          </MenuItem>
          {hasDeleteFeatureFlag ? (
            <MenuItem
              onClick={handleDeleteDivision}
              data-testid="division-row-delete-button"
            >
              Delete
            </MenuItem>
          ) : null}
        </Menu>
      </div>
    </StyledTableRow>
  );
};

export default DivisionRow;
