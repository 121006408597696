import { FC } from "react";
import { IOrganisationSite } from "types";
import { StyledTableRow, StyledStatusPip } from "./styles";
import { StyledAdminIconButton } from "elements";
import moment from "moment";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Menu, MenuItem } from "@material-ui/core";
import { useHandleContextMenuActions } from "./useHandleContextMenuActions";
import { useFeatureFlag } from "hooks";
import { Location } from "@ehabitation/ui";
interface IProps {
  site: IOrganisationSite;
}

const SiteRow: FC<IProps> = ({ site }) => {
  const {
    anchorEl,
    handleOpenContextMenu,
    handleCloseContextMenu,
    handleDeleteSite,
    handleEditSite,
  } = useHandleContextMenuActions(site.id!);

  const hasDeleteFeatureFlag = useFeatureFlag("delete");

  //As adding the sources to the site is the last step of the site initialisation pipeline
  //This should be the sign that the site has been fully created
  const sourcesAvailable = () => {
    if (!site.forecastInUse || !site.realTimeInUse) return false;
    return site.forecastInUse.length > 0 && site.realTimeInUse.length > 0;
  };

  return (
    <StyledTableRow data-testid="site-row">
      <div className="status">
        <StyledStatusPip isOkay={sourcesAvailable()} />
      </div>

      <div className="name">
        <p>{site.name}</p>
      </div>

      <div className="location">
        <Location
          address={site.address}
          latitude={Number(site.location?.latitude)}
          longitude={Number(site.location?.longitude)}
        />
      </div>

      <div className="latlong">
        ( {Number(site.location?.latitude)} , {Number(site.location?.longitude)} )
      </div>

      <div className="start">
        <p>{moment(site.startDate).format("DD/MM/YYYY")}</p>
      </div>

      <div className="end">
        <p>{moment(site.endDate).format("DD/MM/YYYY")}</p>
      </div>

      <div className="icon">
        <StyledAdminIconButton
          onClick={handleOpenContextMenu}
          data-testid="context-menu-button"
        >
          <BiDotsVerticalRounded />
        </StyledAdminIconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseContextMenu}
        >
          <MenuItem onClick={handleEditSite}>Edit</MenuItem>
          {hasDeleteFeatureFlag && (
            <MenuItem onClick={handleDeleteSite}>Delete</MenuItem>
          )}
        </Menu>
      </div>
    </StyledTableRow>
  );
};

export default SiteRow;
