import { KeyViolationData, ViolationData } from "@ehabitation/ts-utils/browser";
import {
  DelayDistributionAsArray,
  IHistogramData,
  WeatherImpactData,
} from "types";
import { color } from "@amcharts/amcharts4/core";
import { colors } from "styles";

export const determineWeatherTypeLongString = (key: KeyViolationData) => {
  const refObj = {
    temp: "Temp.",
    windSpeed: "Wind",
    cumulative: "24hr Rain",
    rainfall: "Hourly Rain",
    waveHeight: "Wave Ht.",
    windGust: "Gust",
    snowfall: "Max Snow",
    snowfall24Hour: "24hr Snow",
    visibility: "Visibility",
  };
  return refObj[key];
};

export const determineColor = (key: KeyViolationData) => {
  const refObjColor = {
    temp: colors.temp,
    windSpeed: colors.windSpeed,
    cumulative: colors.cumulativeRainfall,
    rainfall: colors.hourlyRainfall,
    waveHeight: colors.waveHeight,
    windGust: colors.windGusts,
    snowfall: colors.snowfall,
    snowfall24Hour: colors.snowfall24Hour,
    visibility: colors.visibility,
  };
  return refObjColor[key];
};

export const prepareParetoData = (
  data: DelayDistributionAsArray
): IHistogramData[] => {
  const output: IHistogramData[] = [...data];

  let total = 0;

  for (let i = 0; i < output.length; i++) {
    const value = output[i].simulations;
    total += value;
  }

  let sum = 0;

  for (let j = 0; j < output.length; j++) {
    const value = output[j].simulations;
    sum += value;
    output[j].pareto = (sum / total) * 100;
  }

  return output.filter((obj) => {
    // Remove 'dead' items from the array
    if ((obj.pareto === 100 || obj.pareto === 0) && obj.simulations === 0)
      return false;
    return true;
  });
};

export const prepareWeatherImpactData = (
  maxDelayDays: number,
  violationData: ViolationData
): WeatherImpactData[] => {
  const output = [];

  let key: KeyViolationData;
  for (key in violationData) {
    if (violationData[key] === 0) continue; //Remove all 0 values

    const dataObj = {
      weatherType: determineWeatherTypeLongString(key),
      value: (violationData[key]! / maxDelayDays) * 100,
      color: color(determineColor(key)),
    } as WeatherImpactData;

    output.push(dataObj);
  }

  return output;
};
