import { Unsubscribe } from "firebase/firestore";
import { FC, useRef, useState } from "react";
import { TraSteps } from "../../Pages/sites/tra-qsra/TraSteps";

export const WeatherCalendarsQsra: FC<{
  orgId: string;
  siteId: string;
  planId: string;
}> = ({ orgId, siteId, planId }) => {
  const [selectedFormat, setSelectedFormat] = useState("");
  const subscriptions = useRef<Unsubscribe[]>([]);

  const addSubscription = (unsubscribe: Unsubscribe) => {
    subscriptions.current.push(unsubscribe);
  };

  return (
    <div className="h-full w-full p-16">
      <div className="flex justify-start items-center w-full h-full">
        <div className="flex flex-col h-full w-2/5 justify-start pr-8 pt-10">
          <div className="flex flex-col justify-center w-full items-start text-start">
            <h2 className="mb-2">QSRA Integrations</h2>
            <p className="my-4 ml-1">
              Incorporate weather impact into your programme.
            </p>
            <p className="mb-4 ml-1">
              Select your software and follow the step by step instructions.
            </p>
            <select
              name="risk-format"
              data-testid="risk-format-select"
              className="ml-1 mt-1 block focus:border-indigo-500 focus:outline-none focus:ring-indigo-50 bg-[url('/src/assets/icons/chevron.svg')] bg-[right_-20px_top_50%]
  bg-no-repeat bg-[9rem_auto,100%] rounded-full border border-[#444444] border-solid
  py-2 pl-6 pr-16 max-w-lg cursor-pointer appearance-none"
              placeholder="Select your risk software"
              value={selectedFormat}
              onChange={(e) => {
                setSelectedFormat(e.target.value);
              }}
            >
              <option disabled selected value="">
                Select your risk software
              </option>
              <option value="asta">Asta Powerproject</option>
              <option value="acumen">Deltek Acumen Risk</option>
              <option value="nodesLinks">Nodes and Links</option>
              <option value="oraclePrimavera">Oracle Primavera Cloud</option>
              <option value="pra">Oracle Primavera Risk Analysis (PRA)</option>
              <option value="adjustedCalendars">Oracle Primavera P6</option>
              <option value="safran">Safran Risk</option>
            </select>
          </div>
          {selectedFormat === "pra" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB integration directly with PRA’s weather module.
              </p>
              <p className="ml-2 text-left mt-4">
                For each type of activity EHAB exports a “Weather Calendars”
                containing the probability of impact for every 3 days of the
                project.
              </p>
              <p className="ml-2 text-left mt-4">
                In PRA, you can then manually assign the weather calendars to
                activities or, as an extra set, let EHAB assign them to
                activities.
              </p>
              <p className="ml-2 text-left mt-4">
                PRA will then incorporate EHAB probabilistic impact when
                simulating.
              </p>
            </div>
          )}
          {selectedFormat === "safran" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB integrates with Safran through unique “Downtime Samples,”
                which Safran uses to generate Risk Calendars.
              </p>
              <p className="ml-2 text-left mt-4">
                Import these samples into Safran and allocate them to activities
                to incorporate EHAB’s probabilistic weather impact into your
                QSRA.
              </p>
            </div>
          )}
          {selectedFormat === "acumen" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB integrates with Deltek Acumen Risk through Risk Register
                Calendar Events.
              </p>
              <p className="ml-2 text-left mt-4">
                EHAB exports an Acumen Risk-specific spreadsheet, formatted
                specifically to match containing “Calendar Events” that
                represent activity types—such as concrete and lifting—along with
                their probability of impact and links to planned activities.
              </p>
              <p className="ml-2 text-left mt-4">
                Incorporate this data into the project risk register to utilize
                EHAB’s weather risk insights in your QSRA.
              </p>
            </div>
          )}
          {selectedFormat === "adjustedCalendars" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB integrates directly with P6 by generating unique Resource
                or Project Calendars.
              </p>
              <p className="ml-2 text-left mt-4">
                Based on the plan’s activities and calendars, EHAB calculates
                the expected weather risk for each week of the project. It then
                generates P6 calendars that represent various activity
                types - such as concrete and lifting — and include designated
                “days off” for weather-related impacts.
              </p>
              <p className="ml-2 text-left mt-4">
                This allows you to incorporate EHAB's probabilistic impact as
                deterministic days in your P6 planning.
              </p>
            </div>
          )}
          {selectedFormat === "oraclePrimavera" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB seamlessly integrates with Oracle Primavera Cloud through
                Risk Register Calendar Events.
              </p>
              <p className="ml-2 text-left mt-4">
                By incorporating this data into the register, you can
                effectively utilize EHAB’s weather risk insights in your QSRA.
              </p>
            </div>
          )}
          {selectedFormat === "nodesLinks" && (
            <div className="flex flex-col justify-center w-full items-start text-center mt-14">
              <h3 className="ml-1 items-start mb-4">How does it work?</h3>
              <p className="ml-2 text-left">
                EHAB integrates with Nodes and Links by generating unique
                Resource or Project Calendars.
              </p>
              <p className="ml-2 text-left mt-4">
                Based on the plan’s activities and calendars, EHAB calculates
                the expected weather risk for each week of the project. It then
                generates Project calendars representing the various
                activity types - such as concrete and lifting - and include
                designated “days off” for weather-related impacts.
              </p>
              <p className="ml-2 text-left mt-4">
                This allows you to incorporate EHAB's probabilistic impact as
                deterministic days in your Nodes and Links project.
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start h-full w-3/5 pl-8 mt-6">
          {selectedFormat !== "" && (
            <div className="border border-gray-400 rounded-lg bg-gray-100 py-6 px-8">
              <TraSteps
                selectedFormat={selectedFormat}
                orgId={orgId}
                siteId={siteId}
                planId={planId}
                addSubscription={addSubscription}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
