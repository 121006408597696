//React
import React from "react";
//Material UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Store
type ToastProps = {
  error?: string;
  successMessage?: string;
  handleClose?: () => void;
};

const AddNewOrgFormToast = ({
  error,
  handleClose,
  successMessage,
}: ToastProps) => {
  const renderAlert = () => {
    if (error) {
      return (
        <Alert severity="error" onClose={handleClose}>
          {error}
        </Alert>
      );
    }

    return (
      <Alert severity="success" onClose={handleClose}>
        {successMessage}
      </Alert>
    );
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={2000}
      open={!!error || !!successMessage}
    >
      {renderAlert()}
    </Snackbar>
  );
};

export default AddNewOrgFormToast;
