import pattern from "assets/grunge/pattern.svg";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useMemo, useState } from "react";
import { FaChevronRight, FaPlus } from "react-icons/fa";

import { ISite, UserRole } from "@ehabitation/ts-utils/browser";
import { Accordion, Breadcrumb as UiBreadcrumb } from "@ehabitation/ui";
import Initialising from "Components/Initialising/Initialising";
import logo_small from "assets/images/logo_square.png";
import { db } from "firebaseConfig";
import {
  useIsFreeUser,
  useIsMounted,
  usePersonalSites,
  useSetupSubscriptions,
} from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { selectUserClaims, selectUserId } from "store/auth";
import { selectOrderedProjectsByDivision } from "store/divisionManagement";
import { selectOrganisationProjects, selectOrganisationSites } from "store/siteManagement";
import { selectDivisionMap } from "store/userManagement";
import { isMultiSite } from "types";
import {
  InlineNewDivisionForm,
  InlineNewProjectForm,
  InlineNewSiteForm,
} from "./components/InlineCreate";
import SiteRow from "./components/SiteRow";
import { useSiteNotificationStatuses } from "./hooks";

const usePinnedSiteIds = () => {
  const userId = useAppSelector(selectUserId);
  const [pinnedSiteIds, setPinnedSiteIds] = useState<string[] | null>(null);
  useEffect(() => {
    const ref = doc(collection(db, "users"), userId);
    const unSubscribe = onSnapshot(ref, (snap) => {
      if (snap.exists()) {
        const data = snap.data();
        setPinnedSiteIds(data?.pinnedSites || []);
      } else {
        setPinnedSiteIds([]);
      }
    });
    return () => unSubscribe();
  }, [userId]);
  return pinnedSiteIds;
};

const Breadcrumb: FC = () => {
  const subscriptionsReady = useSetupSubscriptions();
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();

  const [addingPersonalSite, setAddingPersonalSite] = useState<boolean>(false);
  const [addingSiteProjectId, setAddingSiteProjectId] = useState<string>();
  const [addingProjectDivisionId, setAddingProjectDivisionId] =
    useState<string>();
  const [addingDivision, setAddingDivision] = useState<boolean>(false);

  // SOON: const siteId = useParams<{ siteId: string }>().siteId;
  const siteId = location.pathname.split("sites/")[1]?.split("/")[0];

  const currentSitePage = siteId && location.pathname.split(`${siteId}/`)[1];

  const orderedProjectsByDivision = useAppSelector(
    selectOrderedProjectsByDivision
  );
  const divisionMap = useAppSelector(selectDivisionMap);
  const organisationSites = useAppSelector(selectOrganisationSites);
  const userClaims = useAppSelector(selectUserClaims);

  const userIsOrgAdmin = userClaims?.role === UserRole.orgAdmin;

  const pinnedSiteIds = usePinnedSiteIds();
  const {
    sites: personalSites = [],
    initialisingSites: initialisingUserSites,
  } = usePersonalSites();
  const allSites = useMemo(() => {
    return [...Object.values(organisationSites), ...(personalSites || [])];
  }, [organisationSites, personalSites]);

  const { siteNotificationStatuses, loading: loadingNotificationStatus } =
    useSiteNotificationStatuses(allSites);

  const pinnedSites = useMemo(() => {
    if (!pinnedSiteIds || !organisationSites) return null;
    return [
      ...Object.values(organisationSites),
      ...(personalSites || []),
    ].filter((site) => pinnedSiteIds.includes(site.id));
  }, [pinnedSiteIds, organisationSites, personalSites]);

  const MAX_PAID_SITES = 5;
  const MAX_FREE_SITES = 1;
  const selectedSite = siteId
    ? organisationSites[siteId] ||
      personalSites?.find(({ id }: ISite) => id === siteId)
    : undefined;
  const selectedDivision = selectedSite
    ? divisionMap[selectedSite.divisionId]
    : null;
  const project =
    selectedSite && orderedProjectsByDivision[selectedSite.divisionId]
      ? orderedProjectsByDivision[selectedSite.divisionId].find(
          (p) => p.id === selectedSite.project
        )
      : null;
  const isFreeUser = useIsFreeUser();

  const personalSitesCount = personalSites?.length || 0;
  const maxPersonalSitesCount = isFreeUser ? MAX_FREE_SITES : MAX_PAID_SITES;

  const organisationProjects = useAppSelector(selectOrganisationProjects);
  const projectObj = project ? organisationProjects[project.id] : undefined;

  if (!subscriptionsReady || initialisingUserSites)
    return (
      <Initialising>
        <p>Preparing your workspace...</p>
      </Initialising>
    );
  return (
    <UiBreadcrumb
      data-testid={`breadcrumb`}
      defaultOpen={!siteId && !location.pathname.includes("sites/new")}
      title={
        <>
          {/* One of these will be chosen depending on viewport width */}
          <div
            className="flex-col justify-center items-center block lg:hidden h-20 sm:h-20 mr-8"
            data-testid="logo-link"
          >
            <img src={logo_small} alt="EHAB Small Logo" className="h-full" />
          </div>

          <div
            className="flex-col justify-center items-center hidden lg:block h-12 mr-4"
          >
            <img src={logo_small} alt="EHAB Logo" className="h-full" />
          </div>
          <h4 className="flex items-center gap-4 text-gray-800 text-2xl">
            {selectedDivision ? (
              <>
                <span>{selectedDivision.name}</span>
                <FaChevronRight className="text-gray-400 text-xl" />
              </>
            ) : null}
            {project ? (
              <>
                <span>{project.name}</span>
                <FaChevronRight className="text-gray-400 text-xl" />
              </>
            ) : null}
            <span>
              {selectedSite ? `${selectedSite?.name}` : "No site selected"}
            </span>
            {projectObj && isMultiSite(projectObj) ? (
              <>
                <span className="bg-yellow-100 text-yellow-800 text-xl font-medium me-2 px-2.5 py-0.5 rounded-lg dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
                  Multi-site project
                </span>
              </>
            ) : null}
          </h4>
        </>
      }
    >
      {(close: VoidFunction) => {
        return (
          <div className="px-8 pb-4 border-r-2 border-r-[#bg-gray-300] border-b-2 border-b-[#bg-gray-300]">
            <div
              className={`flex flex-col items-stretch bg-[url('${pattern}')] bg-repeat`}
            >
              <h2 className="bg-gray-300 p-2 text-2xl">My Sites</h2>
              <div className="flex flex-col justify-start items-stretch ml-4 mb-4 pt-4 gap-4 border-l-2 border-l-gray-400">
                <Accordion
                  data-testid={`project-accordian-pinned`}
                  title={() => (
                    <div className="flex gap-2 items-center">
                      <h4>Pinned</h4>
                      <span className="italic text-gray-500">
                        ({pinnedSites?.length} site
                        {pinnedSites?.length !== 1 ? "s" : ""})
                      </span>
                    </div>
                  )}
                  headerComponent={"h4"}
                  defaultOpen={!!pinnedSites?.length}
                  headerClass="flex flex-row justify-start items-center border-y-2 border-y-gray-300 bg-gray-50 gap-1 py-2 px-2 text-2xl cursor-pointer hover:bg-gray-200 transition-colors"
                >
                  <div className="ml-8 mb-4 flex flex-col items-stretch gap-4 border-l-2 border-l-gray-300 pt-4">
                    {pinnedSites?.length ? (
                      pinnedSites.map((site: ISite) => {
                        const isNotificationEnabled =
                          !!siteNotificationStatuses.get(site.id)?.enabled;
                        return (
                          <SiteRow
                            key={site.id}
                            site={site}
                            setSiteId={(siteId: string) => {
                              navigate(
                                `/sites/${siteId}/${currentSitePage || ""}`
                              );
                              close();
                              setAddingPersonalSite(false);
                              setAddingSiteProjectId(undefined);
                              setAddingProjectDivisionId(undefined);
                              setAddingDivision(false);
                            }}
                            isSelectedSite={siteId === site.id}
                            isPinned={pinnedSiteIds?.includes(site.id)}
                            notificationEnabled={isNotificationEnabled}
                          />
                        );
                      })
                    ) : (
                      <h4 className="flex flex-row justify-between items-center gap-2 text-gray-700 py-2 px-4 border-b border-b-gray-300 bg-gray-100 text-2xl italic opacity[.7]">
                        No Pinned Sites
                      </h4>
                    )}
                  </div>
                </Accordion>
                <Accordion
                  data-testid={`project-accordian-personal`}
                  title={() => (
                    <div className="flex gap-2 items-center">
                      <h4>Forecast Only</h4>
                      <span className="italic text-gray-500">
                        ({personalSitesCount}/{maxPersonalSitesCount} site
                        {personalSitesCount !== 1 ? "s" : ""})
                      </span>
                    </div>
                  )}
                  headerComponent={"h4"}
                  defaultOpen={
                    !!personalSites?.find((site) => siteId === site.id) ||
                    !siteId
                  }
                  headerClass="flex flex-row justify-start items-center border-y-2 border-y-gray-300 bg-gray-50 gap-1 py-2 px-2 text-2xl cursor-pointer hover:bg-gray-200 transition-colors"
                >
                  <div className="ml-8 mb-4 flex flex-col items-stretch gap-4 border-l-2 border-l-gray-300 pt-4 overflow-auto">
                    {personalSites?.length ? (
                      personalSites.map((site: ISite) => {
                        const isNotificationEnabled =
                          !!siteNotificationStatuses.get(site.id)?.enabled;
                        return (
                          <SiteRow
                            key={site.id}
                            site={site}
                            isPinned={pinnedSiteIds?.includes(site.id)}
                            setSiteId={(siteId: string) => {
                              navigate(
                                `/sites/${siteId}/${currentSitePage || ""}`
                              );
                              close();
                              setAddingPersonalSite(false);
                              setAddingSiteProjectId(undefined);
                              setAddingProjectDivisionId(undefined);
                              setAddingDivision(false);
                            }}
                            isSelectedSite={siteId === site.id}
                            notificationEnabled={isNotificationEnabled}
                          />
                        );
                      })
                    ) : (
                      <h4 className="flex flex-row justify-between items-center gap-2 text-gray-700 py-2 px-4 border-b border-b-gray-300 bg-gray-100 text-2xl italic opacity[.7]">
                        No Sites
                      </h4>
                    )}

                    {addingPersonalSite ? (
                      <div className="p-4 bg-gray-200 flex flex-col gap-6">
                        <h4 className="text-2xl text-gray-700 font-[500] flex gap-2 items-center">
                          New Forecast Site
                        </h4>
                        <div className="px-2">
                          <InlineNewSiteForm
                            handleCancel={() => setAddingPersonalSite(false)}
                            handleSiteCreated={() =>
                              isMounted() && setAddingPersonalSite(false)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <h4
                        role={"button"}
                        onClick={() => {
                          setAddingPersonalSite(true);
                          setAddingProjectDivisionId(undefined);
                          setAddingSiteProjectId(undefined);
                          setAddingDivision(false);
                        }}
                        className="flex flex-row justify-between items-center gap-2 transition-colors text-gray-600 py-2 px-4 border-b border-gray-300 text-2xl hover:bg-gray-200 bg-gray-50"
                      >
                        <p
                          className="text-2xl text-gray-700 font-[500] flex gap-2 items-center"
                        >
                          <FaPlus className="icon" /> Add Forecast Only Site
                        </p>
                      </h4>
                    )}
                  </div>
                </Accordion>
              </div>
            </div>
            {Object.keys(orderedProjectsByDivision).length ? (
              Object.keys(orderedProjectsByDivision).map((divisionId) => {
                const division = divisionMap[divisionId];
                const userIsDivisionAdmin =
                  userIsOrgAdmin ||
                  (userClaims?.role === UserRole.divisionAdmin &&
                    userClaims.division === divisionId);
                return (
                  <div
                    key={divisionId}
                    className={`flex flex-col items-stretch bg-[url('${pattern}')] bg-repeat`}
                  >
                    <h2
                      className="bg-gray-300 p-2 text-2xl"
                      id={
                        division.name === "Demo Division"
                          ? "demo_division"
                          : ""
                      }
                    >
                      {division.name}
                    </h2>
                    <div className="flex flex-col justify-start items-stretch ml-4 border-l-2 border-l-gray-400 mb-4 pt-4 gap-4">
                      {orderedProjectsByDivision[divisionId].length ? (
                        orderedProjectsByDivision[divisionId].map(
                          ({ id: projectId, projectCode, sites, name }) => {
                            const userIsProjectAdmin =
                              userIsDivisionAdmin ||
                              (userClaims?.role === UserRole.projectAdmin &&
                                userClaims.project === projectId);

                            return (
                              <Accordion
                                data-testid={`project-accordian-${projectId}`}
                                key={projectId}
                                title={() => (
                                  <div className="flex gap-2 items-center">
                                    <h4>
                                      {`${
                                        projectCode ? `[${projectCode}] ` : ""
                                      }${name}`}
                                    </h4>
                                    <span className="italic text-gray-500">
                                      ({sites?.length} site
                                      {sites?.length !== 1 ? "s" : ""})
                                    </span>
                                  </div>
                                )}
                                headerComponent={"h4"}
                                defaultOpen={
                                  projectId === selectedSite?.project
                                }
                                headerClass="flex flex-row justify-start items-center border-y-2 border-y-gray-300 bg-gray-50 gap-1 py-2 px-2 text-2xl cursor-pointer hover:bg-gray-200 transition-colors"
                              >
                                <div className="ml-8 mb-4 flex flex-col items-stretch gap-4 border-l-2 border-gray-300 pt-4">
                                  {sites?.length ? (
                                    sites.map((site) => {
                                      const isNotificationEnabled =
                                        !!siteNotificationStatuses.get(site.id)
                                          ?.enabled;
                                      return (
                                        <SiteRow
                                          key={site.id}
                                          site={site}
                                          setSiteId={(siteId: string) => {
                                            navigate(
                                              `/sites/${siteId}/${
                                                currentSitePage || ""
                                              }`
                                            );
                                            close();
                                            setAddingPersonalSite(false);
                                            setAddingSiteProjectId(undefined);
                                            setAddingProjectDivisionId(
                                              undefined
                                            );
                                            setAddingDivision(false);
                                          }}
                                          isPinned={pinnedSiteIds?.includes(
                                            site.id
                                          )}
                                          isSelectedSite={siteId === site.id}
                                          notificationEnabled={
                                            isNotificationEnabled
                                          }
                                        />
                                      );
                                    })
                                  ) : (
                                    <h4 className="flex flex-row justify-between items-center gap-2 text-gray-700 py-2 px-4 border-b border-b-gray-300 bg-gray-100 text-2xl italic opacity[.7]">
                                      No Sites
                                    </h4>
                                  )}
                                  {userIsProjectAdmin ? (
                                    addingSiteProjectId === projectId &&
                                    addingProjectDivisionId !== divisionId ? (
                                      <div className="p-4 bg-gray-200 flex flex-col gap-6">
                                        <h4 className="text-2xl text-gray-700 font-[500] flex gap-2 items-center">
                                          New Site
                                        </h4>
                                        <div className="px-2">
                                          <InlineNewSiteForm
                                            disabled={!!addingProjectDivisionId}
                                            handleCancel={() =>
                                              setAddingSiteProjectId(undefined)
                                            }
                                            handleSiteCreated={() => {
                                              if (
                                                isMounted() &&
                                                addingSiteProjectId ===
                                                  projectId
                                              ) {
                                                navigate(
                                                  `/sites/${siteId}/${
                                                    currentSitePage || ""
                                                  }`
                                                );
                                                close();
                                                setAddingPersonalSite(false);
                                                setAddingSiteProjectId(
                                                  undefined
                                                );
                                                setAddingProjectDivisionId(
                                                  undefined
                                                );
                                                setAddingDivision(false);
                                              }
                                            }}
                                            projectId={addingSiteProjectId}
                                            divisionId={divisionId}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <h4
                                        role={
                                          addingProjectDivisionId &&
                                          addingProjectDivisionId === divisionId
                                            ? ""
                                            : "button"
                                        }
                                        onClick={
                                          addingProjectDivisionId === divisionId
                                            ? undefined
                                            : () => {
                                                setAddingSiteProjectId(
                                                  projectId
                                                );
                                                setAddingPersonalSite(false);
                                                setAddingProjectDivisionId(
                                                  undefined
                                                );
                                                setAddingDivision(false);
                                              }
                                        }
                                        className={`flex items-center gap-2 transition-colors py-2 px-4 border-b-2 border-gray-300 bg-gray-50 text-2xl text-gray-800 ${
                                          addingProjectDivisionId === divisionId
                                            ? "opacity-70 cursor-default"
                                            : "hover:bg-gray-200"
                                        }`}
                                      >
                                        <FaPlus className="icon" /> Add Site
                                      </h4>
                                    )
                                  ) : null}
                                </div>
                              </Accordion>
                            );
                          }
                        )
                      ) : (
                        <h4 className="flex flex-row justify-start items-center border-y-2 border-y-gray-300 bg-gray-50 gap-1 py-2 px-2 text-2xl opacity-70 italic">
                          No Projects
                        </h4>
                      )}
                      {userIsDivisionAdmin ? (
                        addingProjectDivisionId === divisionId ? (
                          <div className="p-4 bg-gray-200 flex flex-col gap-6">
                            <h4 className="text-2xl text-gray-700 font-[500] flex gap-2 items-center">
                              New Project
                            </h4>
                            <div className="px-2">
                              <InlineNewProjectForm
                                handleCancel={() =>
                                  setAddingProjectDivisionId(undefined)
                                }
                                handleProjectCreated={(projectId: string) => {
                                  if (
                                    isMounted() &&
                                    addingProjectDivisionId === divisionId
                                  ) {
                                    setAddingSiteProjectId(projectId);
                                  }
                                }}
                                handleSiteCreated={(siteId: string) => {
                                  if (
                                    isMounted() &&
                                    addingProjectDivisionId === divisionId
                                  ) {
                                    navigate(
                                      `/sites/${siteId}/${
                                        currentSitePage || ""
                                      }`
                                    );
                                    close();
                                    setAddingPersonalSite(false);
                                    setAddingSiteProjectId(undefined);
                                    setAddingProjectDivisionId(undefined);
                                    setAddingDivision(false);
                                  }
                                }}
                                projectId={addingProjectDivisionId}
                                divisionId={divisionId}
                              />
                            </div>
                          </div>
                        ) : (
                          <h4
                            role={"button"}
                            onClick={() => {
                              setAddingProjectDivisionId(divisionId);
                              setAddingPersonalSite(false);
                              setAddingSiteProjectId(undefined);
                              setAddingDivision(false);
                            }}
                            className="flex items-center gap-2 transition-colors py-2 px-4 border-b-2 border-gray-400 bg-gray-50 text-2xl text-gray-800 hover:bg-gray-200"
                          >
                            <FaPlus className="icon" /> Add Project
                          </h4>
                        )
                      ) : null}
                    </div>
                  </div>
                );
              })
            ) : (
              <h2 className="bg-gray-300 p-2 text-2xl">No Divisions</h2>
            )}
            {userIsOrgAdmin ? (
              addingDivision ? (
                <div className="p-4 bg-gray-200 flex flex-col gap-6">
                  <h4 className="text-2xl text-gray-700 font-[500] flex gap-2 items-center">
                    New Division
                  </h4>
                  <div className="px-2">
                    <InlineNewDivisionForm
                      handleCancel={() => setAddingDivision(false)}
                      handleDivisionCreated={(divisionId: string) => {
                        if (isMounted()) {
                          setAddingDivision(false);
                          setAddingProjectDivisionId(divisionId);
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <h4
                  role={"button"}
                  onClick={() => {
                    setAddingDivision(true);
                    setAddingProjectDivisionId(undefined);
                    setAddingPersonalSite(false);
                    setAddingSiteProjectId(undefined);
                  }}
                  className="flex items-center gap-2 transition-colors py-2 px-4 bg-gray-50 border-2 border-gray-400 text-2xl text-gray-800 hover:bg-gray-200"
                >
                  <FaPlus className="icon" /> Add Division
                </h4>
              )
            ) : null}
          </div>
        );
      }}
    </UiBreadcrumb>
  );
};

export default Breadcrumb;
