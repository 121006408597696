import { useCallback } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ITask } from "@ehabitation/ts-utils/browser";
import moment from "moment";
import { calculateTaskDuration } from "helpers";
import { batch } from "react-redux";
import { useAppDispatch } from "store";
import { updateTask } from "store/tasks";
import { setTaskModified, setTaskTriggerModel } from "store/ui";
import { ITaskUpdate } from "types";

export const useDateControls = (currentTask: ITask) => {
  const dispatch = useAppDispatch();

  const handleEditDate = useCallback(
    (date: MaterialUiPickersDate, type: "start" | "end") => {
      const lastDate = currentTask![type];

      const lastField = type === "start" ? "oldStart" : "oldEnd";

      const dateString = moment(date).toISOString();

      const update = {
        [type]: dateString,
        [lastField]: lastDate!,
      } as ITaskUpdate;

      if (type === "start") {
        update["totalTaskSpan"] = `${calculateTaskDuration(
          new Date(dateString),
          new Date(currentTask!.safetyEnd!)
        )} days`;
      } else {
        update["totalTaskSpan"] = `${calculateTaskDuration(
          new Date(currentTask!.start!),
          new Date(dateString)
        )} days`;
      }

      if (type === "end")
        update["safetyEnd"] = moment(update["end"]).format("DD/MM/YYYY");

      update["accuracy"] = 0;
      update["baseAccuracy"] = 0;

      batch(() => {
        dispatch(setTaskModified(true));
        dispatch(setTaskTriggerModel(true));
        dispatch(updateTask({ id: currentTask.id!, update }));
      });
    },
    [dispatch, currentTask]
  );

  const setDisableStart = useCallback(
    (date: MaterialUiPickersDate) => {
      return date! > new Date(currentTask!.end!);
    },
    [currentTask]
  );

  const setDisableFinish = useCallback(
    (date: MaterialUiPickersDate) => {
      const disabledDate = new Date(currentTask!.start!);
      disabledDate.setDate(disabledDate.getDate() - 1);
      return date! < disabledDate;
    },
    [currentTask]
  );

  return {
    handleEditDate,
    setDisableStart,
    setDisableFinish,
  };
};
