import { useAppSelector } from "store";
import { selectDivisionManagementIsLoading } from "store/divisionManagement";
import { StyledAdminContent } from "elements";
import {
  StyledContentWrapper,
  StyledForm,
} from "Components/ProjectManagement/ProjectForm/styles";
import TextInputGroup from "Components/Inputs/TextInputGroup";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { useNewDivisionForm } from "./useNewDivisionForm";
import SelectBoxGroup from "Components/Inputs/SelectBoxGroup";
import {
  isGuest,
  greaterThanEqualRole,
  greaterThanRole,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { selectRole } from "store/auth";
import { visibilityOptions } from "../constants";

const NewDivision = () => {
  const loading = !!useAppSelector(selectDivisionManagementIsLoading);
  const callerRole = useAppSelector(selectRole);

  const { error, handleSubmit, handleUpdateForm, newDivision } =
    useNewDivisionForm();

  const { name, visibility: divisionVisibility } = newDivision;

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        <StyledContentWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <h2>Division Details</h2>

            <TextInputGroup
              type="name"
              changeHandler={handleUpdateForm}
              value={name}
              inputType="text"
              error={error}
              data-testid="name-input"
            />

            <SelectBoxGroup
              type="visibility"
              changeHandler={handleUpdateForm}
              value={divisionVisibility ?? UserRole.planner}
              options={visibilityOptions.filter(
                (role) =>
                  greaterThanRole(callerRole!, role.id) &&
                  (!isGuest(role.id) ||
                    greaterThanEqualRole(callerRole!, UserRole.orgAdmin))
              )}
              error={error}
            />

            <div className="button-container">
              <MonochromeButton
                level="primary"
                width="lg"
                type="submit"
              >
                Save
              </MonochromeButton>
            </div>
          </StyledForm>
        </StyledContentWrapper>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default NewDivision;
