import {
  ITask,
  Shift,
  WeatherKey,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";

// DH TODO: additional weather keys. Also rather than retrrieving unit, lets just standardise for now
export const weatherUnitKey: Record<WeatherKey, keyof ITask> = {
  [WeatherKey.minTemp]: "tempUnit",
  [WeatherKey.maxTemp]: "tempUnit",
  [WeatherKey.wind]: "windUnit",
  [WeatherKey.dailyRainAcc]: "dailyRainAccUnit",
  [WeatherKey.hourlyRainAcc]: "hourlyRainAccUnit",
  [WeatherKey.snowfall]: "snowfallUnit",
  [WeatherKey.waveHeight]: "waveHeightUnit",
  [WeatherKey.windGusts]: "windGustsUnit",
  [WeatherKey.snowfall24Hour]: "snowfall24HourUnit",
  [WeatherKey.visibility]: "visibilityUnit",
};

export interface ThresholdChanges extends WeatherThresholds {
  categoryCode: string;
  activityCode: string;
  shift?: Shift;
}
