import { IProject, ISite } from "@ehabitation/ts-utils/browser";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlanOverview } from "types";
import { RootState } from "store";

export interface IProjectState {
  project: IProject | null;
  currentSite: ISite | null;
  allSites: ISite[] | null;
  availablePlans: IPlanOverview | null;
  saveAsModalIsOpen: boolean;
  risksLoaded: boolean;
  planGraphSaved: boolean;
}

export const initialState: IProjectState = {
  project: null,
  currentSite: null,
  allSites: null,
  availablePlans: null,
  saveAsModalIsOpen: false,
  risksLoaded: false,
  planGraphSaved: false,
};

//Slice
export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload;
    },

    setAllSites: (state, action: PayloadAction<ISite[]>) => {
      state.allSites = action.payload;
    },

    setCurrentSite: (state, action: PayloadAction<ISite>) => {
      state.currentSite = action.payload;
    },

    setAvailablePlans: (state, action: PayloadAction<IPlanOverview>) => {
      state.availablePlans = action.payload;
    },

    setSaveAsModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.saveAsModalIsOpen = action.payload;
    },

    setRisksLoaded: (state, action: PayloadAction<boolean>) => {
      state.risksLoaded = action.payload;
    },
    setPlanGraphSaved: (state) => {
      state.planGraphSaved = !state.planGraphSaved;
    },

    clearProjectState: () => {
      return initialState;
    },
  },
});

//Actions
export const {
  clearProjectState,
  setAllSites,
  setAvailablePlans,
  setCurrentSite,
  setPlanGraphSaved,
  setProject,
  setRisksLoaded,
  setSaveAsModalIsOpen,
} = projectSlice.actions;

//Selectors
export const selectAllSites = (state: RootState) => state.project.allSites;

export const selectProject = (state: RootState) => state.project.project;

export const selectCurrentSite = (state: RootState) =>
  state.project.currentSite;

export const selectSiteName = (state: RootState) =>
  state.project.currentSite?.name;

export const selectProjectName = (state: RootState) => {
  if (!state.project.project) return null;
  return `${
    state.project.project.projectCode
      ? `[${state.project.project.projectCode}] ${state.project.project.name}`
      : state.project.project.name
  }`;
};

export const selectProjectId = (state: RootState) => state.project.project?.id;

export const selectProjectEndDate = (state: RootState) =>
  state.project.currentSite?.endDate;

export const planIdSelector = (state: RootState) =>
  state.project.currentSite?.mainPlanId ?? state.project.currentSite?.plan;

export const selectCurrentPlanId = (state: RootState) =>
  state.project.currentSite?.plan;

export const select1In10Thresholds = (state: RootState) =>
  state.project.currentSite?.historicThresholds;

export const selectAvailablePlans = (state: RootState) =>
  state.project.availablePlans;

export const selectPlanGraphSaved = (state: RootState) =>
  state.project.planGraphSaved;

//Reducer
export default projectSlice.reducer;
