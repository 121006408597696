import { batch } from "react-redux";

import { AppThunk } from "store";
import { setError, setLoading } from "store/userManagement";
import { firebaseFunction } from "helpers";

export const deleteUserById: AppThunk =
  (userId: string) => async (dispatch) => {
    const deleteUser = firebaseFunction("deleteUser");

    dispatch(setLoading("Deleting user..."));

    try {
      await deleteUser({ userId });
      dispatch(setLoading(""));
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setError(error.message)); //shows a toast to the user
        dispatch(setLoading(""));
      });
    }
  };
