import { createSelector } from "reselect";
import { ITask, WeatherKey } from "@ehabitation/ts-utils/browser";
import { isWBSPlan, updateWbsAggregations } from "helpers";
import { receiveIdFromProps } from "store/selectors/generic";
import { selectProjectEndDate } from "store/project";
import {
  allTasksSelector,
  selectTransientTaskData,
  tasksSnapshotSelector,
  selectDependencyMap,
  selectCurrentPlanSimulationResults,
  selectCurrentPlanSimulation,
  selectCurrentTaskId,
} from "./tasksSlice";
import { isEmpty } from "lodash";
import { ITaskObj, ITaskWithResultsObj, ITransientObj } from "types";
import { applySimulationResults } from "./helper";
import { WeatherThresholds } from "firestoreTypes";

export const selectAllTasksWithResults = createSelector(
  [
    allTasksSelector,
    selectCurrentPlanSimulationResults,
    selectCurrentPlanSimulation,
  ],
  (allTasks, currentPlanSimulationResults, currentPlanSimulation) => {
    if (currentPlanSimulationResults && allTasks) {
      const res = {} as ITaskWithResultsObj;
      Object.keys(allTasks).forEach((key) => {
        res[key] = applySimulationResults(
          allTasks[key],
          currentPlanSimulationResults,
          currentPlanSimulation
        );
      });
      const updatesWithaggregatedTasks = updateWbsAggregations(res, null);
      return { ...res, ...updatesWithaggregatedTasks } as ITaskWithResultsObj;
    } else {
      return allTasks as ITaskWithResultsObj;
    }
  }
);

export const selectAllTasksWithResultsEntries = createSelector(
  [selectAllTasksWithResults],
  (allTasksWithResults) => {
    return allTasksWithResults ? Object.values(allTasksWithResults) : [];
  }
);

export const selectCurrentTask = createSelector(
  [selectAllTasksWithResults, selectCurrentTaskId],
  (allTasksWithResults, currentTaskId) =>
    currentTaskId ? allTasksWithResults?.[currentTaskId] : null
);

const allTasksArrayFilteredEmptyTitles = (allTasks: ITaskObj) => {
  const allTasksArray = Object.values(allTasks).filter(
    (task) => !!task.title
  ) as any[];
  return allTasksArray;
};

//Filters by should WBS should show children as well
export const allTasksAsArraySelector = createSelector(
  [selectAllTasksWithResults, selectTransientTaskData],
  (allTasks, selectTransientTaskData) => {
    if (!allTasks) return [];

    const allTasksArray = allTasksArrayFilteredEmptyTitles(allTasks);

    if (!isWBSPlan(allTasksArray)) return allTasksArray;

    const shouldShowChildren: Record<string, boolean> = {};

    allTasksArray.forEach((task) => {
      let show = showChildren(selectTransientTaskData, task.id);
      if (task.WBS && show) {
        let parent: ITask | undefined = allTasks[task.internalParentId];
        // We should only show children if of the parents in the tree are also showing children
        while (parent && show) {
          show = showChildren(selectTransientTaskData, parent.id);
          parent = parent.internalParentId
            ? allTasks[parent.internalParentId]
            : undefined;
        }

        if (show) shouldShowChildren[task.objectId] = show;
      }
    });

    return allTasksArray.filter((task) => {
      if (task.WBS && task.wbsHierarchyLevel == 0) return true;
      if (shouldShowChildren.hasOwnProperty(task.parentId)) return true;
      return false;
    });
  }
);

const showChildren = (
  transientTaskData: ITransientObj | null,
  wbsId: string
): boolean => {
  const value =
    transientTaskData &&
    transientTaskData[wbsId] &&
    transientTaskData[wbsId].showChildren;
  return value as boolean;
};

export const selectAllTasksWithoutWBS = createSelector(
  [
    selectAllTasksWithResults,
    selectCurrentPlanSimulationResults,
    selectCurrentPlanSimulation,
  ],
  (allTasks) => {
    if (isEmpty(allTasks)) return [];

    const allTasksArray = allTasksArrayFilteredEmptyTitles(allTasks);

    if (!isWBSPlan(allTasksArray)) return allTasksArray;

    return allTasksArray.filter((task) => !task.WBS);
  }
);

export const selectTaskThresholdsById = createSelector(
  selectAllTasksWithResults,
  receiveIdFromProps,
  (allTasksWithResults, id) => {
    const task = allTasksWithResults![id!];
    return task as WeatherThresholds;
  }
);

export const selectTasksSnapshotAsArray = createSelector(
  [tasksSnapshotSelector],
  (taskSnapshot) =>
    taskSnapshot && Object.keys(taskSnapshot).length > 0
      ? [...Object.values(taskSnapshot!)]
      : []
);

export const nonWBSTasksLengthSelector = createSelector(
  [allTasksAsArraySelector],
  (allTasks) => allTasks?.filter((task) => !task.WBS).length
);

export const selectTaskIndex = createSelector(
  [allTasksAsArraySelector, selectCurrentTask],
  (tasks, currentTask) => {
    if (!currentTask || !tasks) return 0;
    if (currentTask.index !== undefined) return currentTask.index; //For plans with WBS, using the index added to the task object during fetch
    return tasks.findIndex((task) => task.id === currentTask.id); //Backup for older plans with no WBS, using the array index
  }
);

export const selectAllWBSAsMap = createSelector(
  [allTasksAsArraySelector],
  (allTasks) => {
    if (!allTasks) return {};

    return allTasks.reduce((output, task) => {
      if (!task.WBS) return output;
      return {
        ...output,
        [task.objectId!]: task,
      };
    }, {});
  }
);

export const selectTaskIsMilestone = createSelector(
  [allTasksSelector, receiveIdFromProps],
  (tasks, id) => {
    if (!tasks || !id) return false;
    if (tasks[id]?.milestone) return true;
    return false;
  }
);

export const selectMilestoneId = createSelector(
  [allTasksSelector, receiveIdFromProps],
  (tasks, id) => {
    if (!tasks || !id) return "";
    const objectId = tasks[id]?.objectId;
    if (!objectId) return "";
    return `bar_milestone_${objectId}`;
  }
);
// computed selectors only for tasks + simulation results!
export const selectTaskCompleteDependencyMap = createSelector(
  [selectDependencyMap, selectAllTasksWithResults],
  (dependencyMap, allTasks) => {
    if (!dependencyMap || !allTasks) return null;

    const taskCompleteMap: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(dependencyMap)) {
      taskCompleteMap[key] = value.children.map((id) => allTasks[id]);
    }

    return taskCompleteMap;
  }
);

export const selectDelayDistribution = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.delayDistribution : null)
);

export const selectMaxDelayDays = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.maxDelayDays : null)
);

export const selectViolationData = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.violationData : null)
);

export const selectCurrentTaskRisks = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.risks : null)
);

export const selectCurrentTaskEndDate = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.end : null)
);

export const selectSelectedRiskProfile = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.accuracy : null)
);

export const selectBaseConfidence = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.baseAccuracy : null)
);

export const selectSelectedConfidence = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.accuracy : null)
);

export const selectMilestone = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.milestone : null)
);

export const selectLevelOfEffort = createSelector(
  [selectCurrentTask],
  (currentTask) => (currentTask ? currentTask.levelOfEffort : null)
);
