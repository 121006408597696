import { useEffect, useState } from "react";
import config from "config";
import { useIsMounted } from "hooks";

export const useDebouncedLocation = ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}) => {
  const [debouncedLocation, setDebouncedLocation] = useState({
    latitude: latitude,
    longitude: longitude,
  });

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!latitude || !longitude) {
      setDebouncedLocation({
        latitude: latitude,
        longitude: longitude,
      });
    } else {
      const timeoutId = setTimeout(() => {
        isMounted() &&
          setDebouncedLocation({
            latitude: latitude,
            longitude: longitude,
          });
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [latitude, longitude]);

  return debouncedLocation;
};

export const usePlace = (latitude: string, longitude: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [place, setPlace] = useState<any>();

  const isMounted = useIsMounted();

  const { latitude: debouncedLatitude, longitude: debouncedLongitude } =
    useDebouncedLocation({ latitude, longitude });

  useEffect(() => {
    if (debouncedLatitude && debouncedLongitude) {
      setIsLoading(true);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${config.API_KEY}`
      )
        .then((response) => {
          return response.json().then(({ results }) => {
            const postcodePlace = results.find(
              ({ types }: { types: string[] }) => {
                return types.includes("postal_code") && types.length === 1;
              }
            );
            if (postcodePlace) {
              isMounted() && setPlace(postcodePlace);
            } else {
              isMounted() && setPlace(results.find(() => true));
            }
          });
        })
        .catch(() => {
          setPlace(undefined);
        })
        .finally(() => {
          isMounted() && setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setPlace(undefined);
    }
  }, [debouncedLatitude, debouncedLongitude]);

  return { isLoading, place };
};

const MAPS_IMAGE_BASE_URL = "https://maps.googleapis.com/maps/api/staticmap";
const MAP_IMAGE_SIZE = "640x274"; // 21/9 aspect, max dimension is 640
const MAP_ZOOM_LEVEL = 15;

export const getStaticMapUrl = (
  latitude: number,
  longitude: number,
  mapImageSize: string = MAP_IMAGE_SIZE,
  mapZoomLevel: number = MAP_ZOOM_LEVEL
) =>
  `${MAPS_IMAGE_BASE_URL}?markers=${latitude},${longitude}&zoom=${mapZoomLevel}&size=${mapImageSize}&key=${config.API_KEY}`;
