import React, { useState, useEffect } from "react";
import pattern from "assets/grunge/pattern.svg";
import logo from "assets/images/logo.svg";
import { auth, functions } from "firebaseConfig";
import climacastImage from "assets/images/ehab-climacast.png";
import weatherwiseImage from "assets/images/ehab-weatherwise.png";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import config from "config";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const targetDomain = (() => {
  const projectId = config.PROJECT_ID;
  if (projectId === "ehab-prod-1") {
    return "climacast.ehab.co";
  }
  const env = projectId.split("-")[1];
  return `${env}-climacast.ehab.co`;
})();

const ImageWithSkeleton = ({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className?: string;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="relative">
      {!imageLoaded && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse" />
      )}
      <img
        src={src}
        alt={alt}
        className={`${className} ${
          !imageLoaded ? "opacity-0" : "opacity-100"
        } transition-opacity duration-300`}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

const SelectDomain = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState<string | null>(null);

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  const handleDomainSelect = async (domain: string) => {
    if (auth.currentUser) {
      setIsLoading(domain);

      if (domain === "weatherwise") {
        navigate("/dashboard");
      } else if (domain === "climacast") {
        try {
          const authCustomToken = httpsCallable(functions, "authCustomToken");
          const response = await authCustomToken({});
          const customToken = (response.data as { token: string }).token;
          window.open(
            `https://${targetDomain}/home?token=${customToken}`,
            "_blank"
          );
          setIsLoading(null);
        } catch (error) {
          console.error(
            "Failed to generate custom token for Climacast:",
            error
          );
          setIsLoading(null);
        }
      }
    }
  };

  return (
    <div
      style={dotMatrixBgStyle}
      className="min-h-screen flex flex-col items-center justify-center"
    >
      <img
        className="w-full max-w-xs sm:max-w-lg m-6"
        src={logo}
        alt="EHAB Logo"
      />

      <section className="pt-20">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 xl:w-1/2 px-4">
              <div
                className={`bg-white rounded-lg border border-gray-300 overflow-hidden mb-10 cursor-pointer hover:shadow-lg transition-shadow relative ${
                  isLoading === "climacast" ? "opacity-70" : ""
                }`}
                onClick={() => handleDomainSelect("climacast")}
              >
                {isLoading === "climacast" && (
                  <div className="absolute inset-0 flex flex-col gap-2 items-center justify-center bg-white bg-opacity-50">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    <span className="font-bold text-gray-900">Loading...</span>
                  </div>
                )}
                <ImageWithSkeleton
                  src={climacastImage}
                  alt="EHAB - Climacast"
                  className="w-full h-auto hover:scale-105 transition-transform duration-300"
                />
                <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                  <h3>
                    <span
                      className="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                      "
                    >
                      EHAB - Climacast
                    </span>
                  </h3>
                  <p className="text-base text-body-color leading-relaxed mb-7">
                    For designers and planners to get customisable weather and
                    climate reports.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 xl:w-1/2 px-4">
              <div
                className={`bg-white rounded-lg border border-gray-300 overflow-hidden mb-10 cursor-pointer hover:shadow-lg transition-shadow relative ${
                  isLoading === "weatherwise" ? "opacity-70" : ""
                }`}
                onClick={() => handleDomainSelect("weatherwise")}
              >
                {isLoading === "weatherwise" && (
                  <div className="absolute inset-0 flex flex-col gap-2 items-center justify-center bg-white bg-opacity-50">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    <span className="font-bold text-gray-900">Loading...</span>
                  </div>
                )}
                <ImageWithSkeleton
                  src={weatherwiseImage}
                  alt="EHAB - WeatherWise"
                  className="w-full h-auto hover:scale-105 transition-transform duration-300"
                />
                <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                  <h3>
                    <span
                      className="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                      "
                    >
                      EHAB - WeatherWise
                    </span>
                  </h3>
                  <p className="text-base text-body-color leading-relaxed mb-7">
                    Specialised weather schedule analysis for bidding,
                    construction and operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SelectDomain;
