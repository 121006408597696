import { AddNewOrgForm } from "Components/AddNewOrgForm";

import GrantRoleForm from "./GrantRoleForm";
import { Organisations } from "./Organisations";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { logOpenSuperAdmin } from "helpers/analytics";

const SuperAdminPanel = () => {
  useEffect(() => {
    logOpenSuperAdmin();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full gap-8 grow overflow-y-hidden">
      <div className="px-16 pt-[7rem] overflow-y-auto">
        <Routes>
          <Route path="" element={<Organisations />} />
          <Route path="roles" element={<GrantRoleForm />} />
          <Route path="new_org" element={<AddNewOrgForm />} />
        </Routes>
      </div>
    </div>
  );
};

export default SuperAdminPanel;
