import { rgba } from "polished";
import styled from "styled-components";
import { flex, spacing, colors } from "styles";

export const StyledSimulationBreakdown = styled.div`
  ${flex("column", "flex-start", "flex-start")};
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const StyledConfidenceProfiles = styled.div`
  ${flex("column", "flex-start", "flex-start")};
  width: 100%;
  gap: ${spacing.s};
`;

interface IStyledConfidenceRow {
  isSelected: boolean;
  confidenceColor: string;
  overviewPageStyles: boolean | undefined;
  hoverOnOriginalAvailable: boolean | undefined;
}
export const StyledConfidenceRow = styled.div<IStyledConfidenceRow>`
  display: grid;
  grid-template-columns: 0.5rem 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;

  background-color: ${(props) =>
    props.isSelected ? rgba(props.confidenceColor, 0.4) : "white"};

  .pip {
    height: 100%;
    background-color: ${(props) => props.confidenceColor};
  }

  .confidence {
    padding-left: ${spacing.m};
  }

  > p:nth-child(4) {
    padding-left: ${(props) => (props.overviewPageStyles ? "3rem" : "0")};
  }

  //tooltip styles
  .confidence .tooltip-text {
    visibility: hidden;
    width: 10rem;
    background-color: ${colors.ganttGray};
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .confidence:hover .tooltip-text {
    visibility: ${(props) =>
      props.hoverOnOriginalAvailable ? "visible" : "none"};
  }
`;

export const StyledDateBreakdown = styled.div`
  ${flex("column", "flex-start", "flex-start")};
  width: 100%;
  padding-bottom: ${spacing.l};
  gap: ${spacing.s};

  .row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    width: 100%;
  }

  p {
    font-size: 12px;
  }
`;
