import styled from "styled-components";
type SpinnerProps = {
  size?: "xSmall" | "small" | "medium" | "large";
};

const SpinnerWrapper = styled.div<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  svg {
    animation: 2s linear infinite svg-animation;

    circle {
      fill: transparent;
      stroke: #1ceaa0;
      stroke-width: 10;
      animation: 1.4s ease-in-out infinite both circle-animation;
      stroke-linecap: round;
      stroke-dasharray: 283;
      stroke-dashoffset: 280;
      stroke-width: 10px;
      transform-origin: 50% 50%;
    }
  }

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;

export default function Spinner({ size = "small" }: SpinnerProps) {
  const sizeMap = {
    xSmall: "2rem",
    small: "3rem",
    medium: "4rem",
    large: "5rem",
  };

  return (
    <SpinnerWrapper size={sizeMap[size]}>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="45" />
      </svg>
    </SpinnerWrapper>
  );
}
