//Store
import { useAppDispatch, useAppSelector } from "store";
import { setSelectedTab, selectUserManagementTab } from "store/userManagement";
//Types
import { UserManagementTabOption } from "types";

export const useUserManagementTab = () => {
  const dispatch = useAppDispatch();

  const selectedTab = useAppSelector(selectUserManagementTab);

  const handleChangeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    dispatch(setSelectedTab(e.currentTarget.name as UserManagementTabOption));
  };

  return {
    selectedTab,
    handleChangeTab,
  };
};
