import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectSiteManagementLoading,
  setSelectedSite,
} from "store/siteManagement";
import { StyledAdminButtonContainer, StyledAdminContent } from "elements";
import SitesHeader from "./SitesHeader";
import SitesList from "./SitesList";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { useHandleTabSelect } from "./useHandleTabSelect";

const Sites = () => {
  const dispatch = useAppDispatch();
  const goToNewSite = useHandleTabSelect();

  const loading = !!useAppSelector(selectSiteManagementLoading);

  /* Clear the selected user when entering or returning to this tab */
  useEffect(() => {
    dispatch(setSelectedSite(null));
  }, [dispatch]);

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        {/* SCROLLS */}
        <div className="content-container">
          <StyledAdminButtonContainer>
            <MonochromeButton
              level="primary"
              width="lg"
              onClick={goToNewSite}
              data-testid="site-add"
            >
              Add A New Site
            </MonochromeButton>
          </StyledAdminButtonContainer>{" "}
          <SitesHeader />
          <SitesList />
        </div>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default Sites;
