import { FC, useState } from "react";

import { Search } from "@ehabitation/ui";
import { LoadingWrapper, LoadingType } from "./LoadingWrapper";

interface TabWrapperParams {
  menu?: string[];
  map: Record<string, FC>;
  loading: boolean;
  id: string;
  search?: (searchOption: string) => void;
  showSearch?: boolean;
  searchedOption?: string;
}

export const TabPageWrapper: FC<TabWrapperParams> = ({
  menu,
  map,
  loading,
  id,
  search,
  showSearch,
  searchedOption,
}) => {
  const tabMenu = menu ?? Object.keys(map);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(tabMenu[0]);
  const ActiveTab = map[selectedMenuItem];

  return (
    <div
      className="flex flex-row justify-start items-start w-full"
      data-testid={`${id}-page`}
      style={{ height: "calc(100vh - 14rem)" }}
    >
      <div className="h-full pt-8 flex-col justify-start items-start pr-8 w-1/6 ">
        <div
          className="flex-col justify-start items-center"
        >
          {tabMenu.map((item, i) => (
            <button
              role={"tab"}
              aria-selected={selectedMenuItem === item ? true : undefined}
              aria-controls="tabpanel-id"
              key={item}
              onClick={() => setSelectedMenuItem(item)}
              className={`transition-colors ${
                selectedMenuItem === item
                  ? "bg-[#1CEAA0] cursor-default"
                  : "hover:bg-gray-100"
              } w-full p-4 text-left`}
              name={item}
              id={`usetiful-${item.split(" ")[0].toLowerCase()}-${id}-button`}
              data-testid={`${item.split(" ")[0].toLowerCase()}-${id}-button`}
            >
              <h4>{item}</h4>
            </button>
          ))}
        </div>
      </div>

      <LoadingWrapper
        loading={loading}
        type={LoadingType.subscriptions}
        message="Loading page"
      >
        <div className="h-full pt-8 pl-8 flex-grow pr-8">
          {showSearch && (
            <Search
              search={search!}
              searchedOption={searchedOption!}
              classes="mr-8"
            />
          )}
          <ActiveTab />
        </div>
      </LoadingWrapper>
    </div>
  );
};
