import { Popover, Transition } from "@headlessui/react";
import { ComponentProps, FC, Fragment, forwardRef } from "react";
import { VscFilterFilled } from "react-icons/vsc";

export const FilterButton = ({
  disabled,
  isEnabled,
  open,
  className,
  children,
  ...props
}: {
  disabled?: boolean;
  isEnabled: boolean;
  open?: boolean;
  className: string;
  children?: React.ReactNode;
} & ComponentProps<"button">) => {
  return (
    <button
      role="checkbox"
      aria-selected={isEnabled}
      className={`inline-flex relative items-center px-4 py-1 gap-4 border rounded-lg whitespace-nowrap ${
        isEnabled ? "bg-green-300 " : "bg-gray-200 text-gray-600 "
      }${
        disabled
          ? ""
          : isEnabled
          ? "hover:bg-green-200 active:bg-green-400 "
          : "hover:bg-gray-100 active:bg-gray-300 "
      }${
        open
          ? isEnabled
            ? "bg-green-400 font-bold "
            : "bg-gray-300 font-bold "
          : ""
      }${disabled ? "opacity-70 " : "cursor-pointer "}${className}`}
      disabled={disabled}
      {...props}
    >
      <svg className="mr-2" width="1.2em" height="1.2em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="20" rx="5" fill={isEnabled ? "#10B981" : "#D1D5DB"} />
          {isEnabled && (
              <path d="M5 10l3 3 7-7" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          )}
      </svg>

      {children} {/* Render children here */}
    </button>
  );
};

const FilterRoot = forwardRef<
  HTMLButtonElement,
  {
    title: string;
    enabledText?: string;
    enabled?: boolean;
    disabled?: boolean;
    children: (props: { close: () => void }) => React.ReactNode;
  }
>(({ title, enabledText, enabled, disabled, children }, ref) => {
  const isEnabled = !!enabledText || enabled;
  const id = title.toLowerCase().replace(" ", "-") + "-filter";
  return (
    <Popover as="div" data-testid={id}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={ref}
            aria-selected={isEnabled}
            data-testid={`${id}-button`}
            className={`inline-flex relative items-center px-4 py-1 gap-4 border rounded-lg ${
              isEnabled ? "bg-green-300 " : "bg-gray-200 text-gray-600 "
            }${
              disabled
                ? ""
                : isEnabled
                ? "hover:bg-green-200 active:bg-green-400 "
                : "hover:bg-gray-100 active:bg-gray-300 "
            }${
              open
                ? isEnabled
                  ? "bg-green-400 font-bold "
                  : "bg-gray-300 font-bold "
                : ""
            }${disabled ? "opacity-70 " : "cursor-pointer "}`}
            disabled={disabled}
          >
            <span className="flex text-xl items-center gap-2 whitespace-nowrap">
              <span className=" pt-[0.2rem]">
                {title}
                {enabledText ? (
                  <>
                    {" "}
                    - <span className="font-bold">{enabledText}</span>
                  </>
                ) : null}
              </span>
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel>
              {({ close }) => {
                return (
                  <div
                    className="absolute top-0 mt-2 rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                    data-testid={`${id}-panel`}
                  >
                    {children({ close })}
                  </div>
                );
              }}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
});

FilterRoot.displayName = "Filter";
export const Filter = Object.assign(FilterRoot, { Group: Popover.Group });

export const FilterIndicator: FC<{
  handleClick?: () => void;
  label: string;
  isEnabled: boolean;
  disabled?: boolean;
}> = ({ handleClick, label, isEnabled, disabled }) => {
  return handleClick ? (
    <button
      onClick={() => handleClick()}
      disabled={disabled}
      className={`px-1 rounded-lg transition-colors disabled:opacity-70 ${
        isEnabled ? "text-green-500 " : "text-gray-400 "
      }${
        isEnabled
          ? "hover:text-green-400 active:text-green-600 "
          : "hover:text-gray-300 active:text-gray-500 "
      }
      `}
    >
      <label className="sr-only">{label}</label>
      <VscFilterFilled className="text-2xl inline" />
    </button>
  ) : (
    <button
      disabled={disabled}
      className={`px-1 rounded-lg transition-colors disabled:opacity-70 ${
        isEnabled ? "text-green-500 " : "text-gray-400 "
      }
      `}
    >
      <label className="sr-only">{label}</label>
      <VscFilterFilled className="text-2xl inline" />
    </button>
  );
};
