import { grays, fontSize } from "styles";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

export const StyledCapitalisedTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: grays.dark,
    fontFamily: "Decima Mono X",
    fontSize: fontSize.xs,
    maxWidth: 500,
    textTransform: "capitalize",
  },

  arrow: {
    color: grays.dark,
    "&::before": {
      backgroundColor: grays.dark,
      border: `2px solid ${grays.dark}`,
    },
  },
}))(Tooltip);

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: grays.dark,
    fontFamily: "Decima Mono X",
    fontSize: fontSize.xs,
    maxWidth: 500,
  },

  arrow: {
    color: grays.dark,
    "&::before": {
      backgroundColor: grays.dark,
      border: `2px solid ${grays.dark}`,
    },
  },
}))(Tooltip);
