import { IUser, UserRole } from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers";

export const validateUserForm = (
  newUser: IUser
): [boolean, string | null, string] => {
  const { firstName, lastName, divisionId, email, project, role } = newUser;
  if (!firstName)
    return [false, "firstName", "Please provide a first name for the user"];

  if (!lastName)
    return [false, "lastName", "Please provide a last name for the user"];

  if (!divisionId && ![UserRole.guest].includes(role))
    return [false, "divisionId", "Please provide a division for the user"];

  if (!email)
    return [false, "email", "Please provide a valid email for the user"];

  if (!project && ![UserRole.divisionAdmin, UserRole.guest].includes(role))
    return [false, "project", "Please select a valid project for the user"];

  return [true, null, ""];
};

export const createNewUser = async (userDetails: IUser) => {
  const callCreateNewUser = firebaseFunction("createNewUser");
  await callCreateNewUser(userDetails);
};

export const updateUser = async (userDetails: IUser) => {
  const callUpdateUser = firebaseFunction("updateUser");
  await callUpdateUser({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    orgId: userDetails.orgId,
    project: userDetails.project,
    divisionId: userDetails.divisionId,
    role: userDetails.role,
    id: userDetails.id,
  });
};
