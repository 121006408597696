//Types
import { AppThunk } from "store";
//Store
import {
  setProjectManagementIsLoading,
  setProjectManagementError,
} from "store/projectManagement";
import { batch } from "react-redux";
//Helpers
import { deleteProject } from "./helpers";

export const deleteProjectById: AppThunk =
  (projectId: string) => async (dispatch) => {
    dispatch(setProjectManagementIsLoading(true));

    try {
      await deleteProject(projectId);
      dispatch(setProjectManagementIsLoading(false));
    } catch (e) {
      const error = e as Error;
      console.error(error);
      batch(() => {
        dispatch(setProjectManagementError(error.message)); //shows a toast to the user
        dispatch(setProjectManagementIsLoading(false));
      });
    }
  };
