import React, { FC, memo } from "react";
import moment from "moment";
import { Milestone } from "@ehabitation/ts-utils/browser";

interface IProps {
  end: string;
  color: string;
  milestone: Milestone | undefined;
}

const EndCell: FC<IProps> = ({ end, color, milestone }) => {
  return (
    <div className={`cell-date ${color}`} data-testid="cell-end">
      {milestone && milestone === "Start Milestone" ? "" : end}
    </div>
  );
};

export default memo(EndCell);
