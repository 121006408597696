//React
import React from "react";
//Material UI
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
//Store
import { useAppDispatch, useAppSelector } from "store";
import {
  setSiteManagementError,
  selectSiteManagementError,
} from "store/siteManagement";

const SiteManagementToast = () => {
  const dispatch = useAppDispatch();
  const siteManagementError = useAppSelector(selectSiteManagementError);

  const handleClose = () => {
    dispatch(setSiteManagementError(""));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={5000}
      onClose={handleClose}
      open={!!siteManagementError}
    >
      <Alert severity="error" onClose={handleClose}>
        {siteManagementError}
      </Alert>
    </Snackbar>
  );
};

export default SiteManagementToast;
