import { FC, useCallback, useRef, useState } from "react";
import { StyledPlanDisplayInfo, StyledPlanTitle } from "elements";
import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import PlanConfiguratorPanel from "./partials/PlanConfiguratorPanel";
import { useClickOutside } from "hooks/use-click-outside";

interface PlanSelectorProps {
  plans?: IPlan[];
  selectedPlan: string | undefined;
  currentSite: ISite;
  handleSelectPlan: (planId: string) => void;
  setMainPlan: (site: ISite, planId: string) => void;
  setDiscardPlanModal: (planId: string) => void;
  isShort?: boolean;
}

const PlanSelector: FC<PlanSelectorProps> = ({
  plans,
  currentSite,
  selectedPlan,
  handleSelectPlan,
  setMainPlan,
  setDiscardPlanModal,
  isShort = false,
}) => {
  const [isPlanConfiguratorOpen, setIsPlanConfiguratorOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => setIsPlanConfiguratorOpen(false));
  const getDisplayLabel = useCallback(() => {
    if (!plans) {
      return "Loading Plans...";
    }

    if (!selectedPlan) {
      return "Select a plan";
    }

    if (selectedPlan) {
      const isMainPlan = selectedPlan === currentSite.mainPlanId;
      const planName = plans.find((plan) => plan.id === selectedPlan)?.title;
      return (
        <>
          <StyledPlanTitle>{planName}</StyledPlanTitle>
          <i>&nbsp;{isMainPlan ? "Main" : "Draft"}</i>
        </>
      );
    }
  }, [selectedPlan, plans, currentSite]);

  return (
    <StyledPlanDisplayInfo
      ref={ref}
      onClick={() => setIsPlanConfiguratorOpen(!isPlanConfiguratorOpen)}
    >
      {getDisplayLabel()}
      {isPlanConfiguratorOpen && plans && (
        <PlanConfiguratorPanel
          isShort={isShort}
          plans={plans.filter(
            (plan) =>
              !plan?.status ||
              !["discarded", "error", "categorised"].includes(plan?.status)
          )}
          selectedPlan={selectedPlan}
          currentSite={currentSite}
          togglePanel={setIsPlanConfiguratorOpen}
          handleSelectPlan={handleSelectPlan}
          setMainPlan={setMainPlan}
          setDiscardPlanModal={setDiscardPlanModal}
        />
      )}
    </StyledPlanDisplayInfo>
  );
};

export default PlanSelector;
