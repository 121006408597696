import { FC } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  paddingSize?: "large" | "small";
}

export const Input: FC<InputProps> = ({
  className = "",
  paddingSize = "large",
  ...inputProps
}) => {
  const paddingClasses = paddingSize === "large" ? "p-4 pl-12" : "p-2 pl-6";
  return (
    <input
      className={`${paddingClasses} rounded-full disabled:bg-gray-100 disabled:text-gray-500 border border-gray-500 outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89] text-2xl sm:text-[1.4rem] ${className}`}
      {...inputProps}
    />
  );
};
