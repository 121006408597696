import styled, { css } from "styled-components";
import { StyledAdminTableHeader, StyledAdminTableRow } from "elements";
import { colors } from "styles";

const columnWidths = css`
  .long {
    width: 21.66%;
  }

  .short {
    width: 15%;
  }

  .icon {
    width: 5%;
  }
`;

interface IStyledTableRowProps {
  isOrgAdmin: boolean;
}

export const StyledTableRow = styled(StyledAdminTableRow)<IStyledTableRowProps>`
  ${columnWidths};

  .long,
  .short {
    .email {
      width: 95%;
      overflow-wrap: break-word;
    }

    .role {
      text-transform: capitalize;
    }
  }

  > p:nth-of-type(1),
  > .long:nth-of-type(2) > p {
    color: ${(props) => (props.isOrgAdmin ? colors.major : "inherit")};
  }
`;

export const StyledTableHeader = styled(StyledAdminTableHeader)`
  ${columnWidths};
`;
