import { ChangeEvent, useState } from "react";
import { addDoc, collection, deleteDoc } from "firebase/firestore";
import styled from "styled-components";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { Button } from "@ehabitation/ui";
import { db } from "firebaseConfig";
import { useAppDispatch } from "store";
import TextInputGroup from "Components/Inputs/TextInputGroup";
import { Spinner } from "../Spinner";
import AddNewOrgFormToast from "./AddNewOrgFormToast";
import { createNewUserFromForm } from "Components/UserManagement/ManageUser/thunks";
import { errorDictionary } from "./errors";

type OrgAdmins = [
  { key: number; email: string; firstName?: string; lastName?: string }
];

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
`;

const UserEmailInput = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  div {
    cursor: pointer;
  }
`;

const AddNewOrgForm: React.FC = () => {
  const [orgAdmins, setOrgAdmins] = useState<OrgAdmins | []>([]);
  const [organisationName, setOrganisationName] = useState<string>("");
  const [addNewOrgFormErrors, setAddNewOrgFormErrors] = useState<
    string | undefined
  >(undefined);
  const [addNewOrgFormSuccessMessage, setAddNewOrgFormSuccessMessage] =
    useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const usersCountArray = new Array(usersCount).fill(0).map((_, i) => i);

  const handleCreateOrg = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const allInputs = document.querySelectorAll("input");
    const allInputsArray = Array.from(allInputs);
    const invalidInputs = allInputsArray
      .filter((input) => !input.checkValidity() || !input.value)
      .map((input) => input.name);
    if (invalidInputs.length) {
      return alert("Invalid inputs: " + invalidInputs.join(","));
    }
    setIsLoading(true);

    let createdOrgDoc = null;

    try {
      const organisationsCollection = collection(db, "organisations");
      createdOrgDoc = await addDoc(organisationsCollection, {
        name: organisationName,
      });

      if (createdOrgDoc) {
        for (const [index, user] of orgAdmins.entries()) {
          const userData = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            orgId: createdOrgDoc.id,
            role: "orgAdmin",
          };

          try {
            await dispatch(createNewUserFromForm(userData, true));
          } catch (error) {
            if (index === 0) {
              if (
                (error as any).message === errorDictionary.emailAlreadExists
              ) {
                setAddNewOrgFormErrors(
                  `Error adding ${userData.email} to the organisation`
                );
              }

              setAddNewOrgFormErrors(
                `Error adding creating users for ${organisationName}`
              );
              throw error;
            }
          }
        }
      }

      setIsLoading(false);
      navigate("/super/organisations");
    } catch (error) {
      console.error(`Error creating ${organisationName}, deleting.`, error);
      if (createdOrgDoc) {
        await deleteDoc(createdOrgDoc);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          handleCreateOrg(event);
        }}
      >
        <TextInputGroup
          label="Organisation name"
          type="orgName"
          changeHandler={({ target: { value } }) => setOrganisationName(value)}
          value={organisationName}
          inputType="text"
          error={null}
          name={`Org Name`}
        />
        <UserContainer>
          <h4>Org Admin Users to Create:</h4>
          {usersCountArray.map((_, index) => {
            const handleUpdateUserEmail = ({
              target: { value },
            }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const newOrgAdmins: OrgAdmins = [...(orgAdmins as OrgAdmins)];
              const currentUser = newOrgAdmins.find(
                (user) => user.key === index
              );

              if (!currentUser) {
                newOrgAdmins.push({
                  key: index,
                  email: value,
                  firstName: "",
                  lastName: "",
                });
              } else {
                newOrgAdmins[index] = { ...newOrgAdmins[index], email: value };
              }

              setOrgAdmins(newOrgAdmins);
            };

            const handleUpdateUserName = (value: string, field: string) => {
              const newOrgAdmins: OrgAdmins = [...(orgAdmins as OrgAdmins)];
              const currentUser = newOrgAdmins.find(
                (user) => user.key === index
              );

              if (!currentUser) {
                newOrgAdmins.push({
                  key: index,
                  email: "",
                  [field]: value,
                });
              } else {
                newOrgAdmins[index] = {
                  ...newOrgAdmins[index],
                  [field]: value,
                };
              }

              setOrgAdmins(newOrgAdmins);
            };

            return (
              <UserEmailInput key={index}>
                <>
                  <TextInputGroup
                    type="firstName"
                    changeHandler={({ target: { value } }) =>
                      handleUpdateUserName(value, "firstName")
                    }
                    value={orgAdmins[index]?.firstName || ""}
                    inputType="text"
                    error={null}
                    name={`First Name ${index + 1}`}
                  />
                  <TextInputGroup
                    type="lastName"
                    changeHandler={({ target: { value } }) =>
                      handleUpdateUserName(value, "lastName")
                    }
                    value={orgAdmins[index]?.lastName || ""}
                    inputType="text"
                    error={null}
                    name={`Last Name ${index + 1}`}
                  />
                  <TextInputGroup
                    type="email"
                    changeHandler={handleUpdateUserEmail}
                    value={orgAdmins[index]?.email || ""}
                    inputType="email"
                    error={null}
                    name={`Email ${index + 1}`}
                  />
                </>
                {usersCount > 1 && (
                  <Button
                    type="button"
                    aria-label="Remove User"
                    className="rounded-full"
                    style={{ padding: "0.5rem", lineHeight: 1 }}
                    onClick={() => {
                      setUsersCount(usersCount - 1);
                      setOrgAdmins(
                        orgAdmins.filter(
                          ({ key }: any) => key !== index
                        ) as OrgAdmins
                      );
                    }}
                    disabled={isLoading}
                  >
                    <AiOutlineMinus className="icon" />
                  </Button>
                )}
              </UserEmailInput>
            );
          })}
          <Button
            type="button"
            className="rounded-full self-center"
            aria-label="Add User"
            style={{ padding: "0.5rem", lineHeight: 1 }}
            onClick={() => setUsersCount(usersCount + 1)}
          >
            <AiOutlinePlus className="icon" />
          </Button>
        </UserContainer>
        <Button type="submit" disabled={isLoading}>
          {isLoading && <Spinner />}
          {!isLoading && `Create Organisation`}
        </Button>
      </form>
      <AddNewOrgFormToast
        error={addNewOrgFormErrors}
        handleClose={() => {
          setAddNewOrgFormErrors(undefined);
          setAddNewOrgFormSuccessMessage(undefined);
        }}
        successMessage={addNewOrgFormSuccessMessage}
      />
    </>
  );
};

export default AddNewOrgForm;
