import { FC, useEffect, useState } from "react";
import { CollectionType, IPlan, ISite } from "@ehabitation/ts-utils/browser";
import { PlanImportCategorySelection } from "./pages";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";

const Categorise: FC<{
  site: ISite;
  plan?: IPlan;
  initialOpenTaskId?: string;
  handleComplete: () => void;
  categoriesNeedUpdating: boolean;
}> = ({ site, plan, initialOpenTaskId, handleComplete, categoriesNeedUpdating }) => {
  const [mitigationPlanId, setMitigationPlanId] = useState<string>();

  useEffect(() => {
    if (!plan) {
      setMitigationPlanId(undefined);
      return;
    }
    const mitigationPlanQuery = query(
      collection(db, CollectionType.Plans),
      where("mitigatesPlanId", "==", plan.id),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(
      mitigationPlanQuery,
      ({ docs }) => {
        if (docs.length) {
          const activeMitigationDoc = docs[0];
          setMitigationPlanId(activeMitigationDoc.id);
        } else {
          setMitigationPlanId(undefined);
        }
      },
      (error) => {
        console.error(error);
      }
    );
    return unsubscribe;
  }, [plan]);

  return (
    <div className="h-full w-full">
      <div className="h-full w-full py-8 overflow-hidden flex flex-col justify-center">
        {plan ? (
          <div
            data-testid="import-tasks-slide"
            className="self-stretch flex flex-col gap-2 overflow-hidden flex-grow"
          >
            <PlanImportCategorySelection
              plan={plan}
              site={site}
              mitigationPlanId={mitigationPlanId}
              initialOpenTaskId={initialOpenTaskId}
              handleComplete={() => {
                handleComplete();
              }}
              nextText="Close"
              categoriesNeedUpdating={categoriesNeedUpdating}
              isRecategoriseTasksView={true}
            />
          </div>
        ) : (
          <div>
            <p className="text-red-600">Looks like you haven't selected a plan to categorise.</p>
            <p className="text-red-600">
              Please reload this page and try again, or contact support@ehab.co
              if this error persists.
            </p>
            <p className="text-red-600">
              If you would like to try and recover an in-progress import, please
              contact support@ehab.co. We might be able to help!
            </p>
          </div>
        )}
        {plan ? (
          <div className="bg-slate-200 px-4 py-1 absolute bottom-4 left-4 text-[1.3rem] ">
            <strong className="truncate">{plan.title}</strong>{" "}
            <em>uploaded {plan.createdAt?.toLocaleString()}</em>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Categorise;
