import React, { FC } from "react";
import { StyledTabbedContainer } from "elements";
import { menu } from "./constants";
import { useDivisionManagementTab } from "./useDivisionManagementTab";
import DivisionManagementToast from "./DivisionManagementToast";
import Divisions from "./Divisions/Divisions";
import NewDivision from "./NewDivision/NewDivision";

const DivisionManagement: FC = () => {
  const { selectedTab, handleChangeTab } = useDivisionManagementTab();

  return (
    <StyledTabbedContainer data-testid="division-management-container">
      <div className="tabs">
        {menu.map((item) => (
          <button
            key={item}
            name={item}
            onClick={handleChangeTab}
            className={selectedTab === item ? "selected" : ""}
            disabled={selectedTab !== "Details"}
            data-testid={`menu-${item.toLowerCase()}`}
          >
            <h3>{item}</h3>
          </button>
        ))}
      </div>

      <div className="container">
        {selectedTab === "Divisions" ? <Divisions /> : null}
        {selectedTab === "Details" ? <NewDivision /> : null}
      </div>

      <DivisionManagementToast />
    </StyledTabbedContainer>
  );
};

export default DivisionManagement;
