//React
import { useEffect, useRef } from "react";
//Types
import { IFormError } from "types";

/*
Takes the errorField i.e. the key on the current error
and the inputType of the input (name, email). If they
match, set focus

For whatever reason, the MUI datepickers cannot be focused using refs
*/

export const useHandleErrorFocus = (
  error: IFormError | null,
  inputType: string
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  useEffect(() => {
    if (!error) return;

    if (error.field === inputType) {
      inputRef?.current?.focus();
      textareaRef?.current?.focus();
      selectRef?.current?.focus();
    }
  }, [error, inputType]);

  return {
    inputRef,
    textareaRef,
    selectRef,
  };
};
