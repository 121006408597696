import { IUser, UserRole } from "@ehabitation/ts-utils/browser";
import { useState, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "store";
import {
  selectUserProjectId,
  selectUserDivisionId,
  selectUserOrgId,
} from "store/auth";
import { selectSelectedUserDetails } from "store/userManagement";
import { IFormError } from "types";
import { validateUserForm } from "./helpers";
import { createNewUserFromForm, updateUserDetails } from "./thunks";

export const useManageUser = () => {
  const dispatch = useAppDispatch();

  const selectedUserDetails = useAppSelector(selectSelectedUserDetails);
  const orgId = useAppSelector(selectUserOrgId);
  const divisionId = useAppSelector(selectUserDivisionId);
  const projectId = useAppSelector(selectUserProjectId);

  const [userDetails, setUserDetails] = useState<IUser>(() => {
    if (!selectedUserDetails)
      return {
        id: "",
        firstName: "",
        lastName: "",
        divisionId: divisionId ?? "",
        project: projectId ?? "",
        email: "",
        site: "",
        orgId: orgId ?? "",
        role: UserRole.planner,
      };

    return selectedUserDetails;
  });

  const [password, setPassword] = useState<string>("");
  const [userFormError, setUserFormError] = useState<IFormError | null>(null);

  const handleUpdateUser = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const field = e.target.name;
      const value = e.target.value;

      if (field === "password") {
        setPassword(value);
      } else {
        setUserDetails((prev) => {
          return {
            ...prev,
            [field]: value,
          };
        });
      }
    },
    []
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, password: string) => {
    e.preventDefault();
  
    const [isValid, field, errorMessage] = validateUserForm(userDetails);
  
    if (!isValid) {
      setUserFormError({
        field: field ? field : "",
        message: errorMessage,
      });
      return;
    }
  
    if (!selectedUserDetails) {
      // New user
      const newUserDetails = { ...userDetails, password };
      dispatch(createNewUserFromForm(newUserDetails));
    } else {
      // Updating user
      dispatch(updateUserDetails(userDetails));
    }
  };
  
  return {
    isNewUser: selectedUserDetails === null ? true : false,
    userDetails,
    password,
    userFormError,
    handleUpdateUser,
    handleSubmit,
  };

  return {
    isNewUser: selectedUserDetails === null ? true : false,
    userDetails,
    password,
    userFormError,
    handleUpdateUser,
    handleSubmit,
  };
};