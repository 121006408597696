import { useRef, useCallback } from "react";
import { useVirtual } from "react-virtual";

export const useListVirtualizer = (tasksLength: number | undefined) => {
  const parentRef = useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtual({
    size: tasksLength ? tasksLength + 1 : 1, //always need 1 for the header
    parentRef,
    estimateSize: useCallback(() => 38, []), //38 corresponds to 3.8rem row height
    overscan: 9, //Also helps with testing the component by rendering the correct amount
  });

  return {
    parentRef,
    totalSize: rowVirtualizer.totalSize,
    virtualItems: rowVirtualizer.virtualItems,
  };
};
