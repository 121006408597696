//React
import React from "react";
//Styles
import { StyledTableHeader } from "./styles";

const SitesHeader = () => {
  return (
    <StyledTableHeader data-testid="sites-header">
      <p className="status">Status</p>
      <p className="name">Name</p>
      <p className="location">Location</p>
      <p className="latlong">Lat, Long</p>
      <p className="start">Start</p>
      <p className="end">End</p>
      <p className="icon"></p>
    </StyledTableHeader>
  );
};

export default SitesHeader;
