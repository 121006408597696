import {
  isGuest,
  greaterThanEqualRole,
  greaterThanRole,
  UserRole,
} from "@ehabitation/ts-utils/browser";
import { StyledContentForm } from "./styles";
import TextInputGroup from "Components/Inputs/TextInputGroup";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import SelectBoxGroup from "Components/Inputs/SelectBoxGroup";
import { useManageUser } from "./useManageUser";
import { useAppSelector, RootState } from "store";
import { selectUserManagementLoading } from "store/userManagement";
import {
  selectDivisionOptions,
  selectProjectOptions,
} from "store/siteManagement";
import { selectRole } from "store/auth";
import { roleOptions } from "./constants";
import { useState, ChangeEvent } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard"; // Assuming you have this package installed
import { IFormError } from "types/form";
import { AiOutlineCopy } from "react-icons/ai";

const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
const commonSequences = ["1234", "abcd", "password", "qwerty"];

const generateRandomPassword = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*(),.?":{}|<>';
  let password = "";
  while (
    !passwordRegex.test(password) ||
    commonSequences.some((seq) => password.includes(seq))
  ) {
    password = Array.from({ length: 12 }, () =>
      characters.charAt(Math.floor(Math.random() * characters.length))
    ).join("");
  }
  return password;
};

// ... existing code ...

const ManageUser = () => {
  const {
    isNewUser,
    userDetails,
    userFormError,
    handleUpdateUser,
    handleSubmit: originalHandleSubmit,
  } = useManageUser();

  const [password, setPassword] = useState("");
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [passwordError, setPasswordError] = useState<IFormError | null>(null);

  const loading = !!useAppSelector(selectUserManagementLoading);
  const callerRole = useAppSelector(selectRole);
  const divisionOptions = useAppSelector(selectDivisionOptions);
  const projectOptions = useAppSelector((state: RootState) =>
    selectProjectOptions(state, userDetails.divisionId)
  );

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setPassword(newPassword);
    setPasswordError(null);
  };

  const handlePasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const handleCopyPassword = () => {
    setPasswordCopied(true);
    setTimeout(() => setPasswordCopied(false), 2000);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNewUser) {
      if (!passwordRegex.test(password)) {
        setPasswordError({
          field: "password",
          message: "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
        });
        return;
      }
      if (commonSequences.some(seq => password.includes(seq))) {
        setPasswordError({
          field: "password",
          message: "Password should not contain common sequences like '1234', 'abcd', 'password', or 'qwerty'."
        });
        return;
      }
      setPasswordError(null);
    }
    originalHandleSubmit(e, password);
  };

  return (
    <StyledContentForm onSubmit={handleSubmit}>
      <LoadingWrapper loading={loading}>
        <h2 data-testid="user-edit-details">
          {isNewUser
            ? "New User Details"
            : `Edit Details For ${userDetails.firstName} ${userDetails.lastName}`}
        </h2>

        <div className="user-details">
          <div className="left">
            <TextInputGroup
              type="firstName"
              changeHandler={handleUpdateUser}
              value={userDetails.firstName}
              inputType="text"
              error={userFormError}
            />

            <TextInputGroup
              type="lastName"
              changeHandler={handleUpdateUser}
              value={userDetails.lastName}
              inputType="text"
              error={userFormError}
            />

            <TextInputGroup
              type="email"
              changeHandler={handleUpdateUser}
              value={userDetails.email}
              inputType="email"
              error={userFormError}
              disabled={!isNewUser}
            />

            {isNewUser && (
              <div className="password-container flex items-center">
                <div className="w-1/2">
                  <TextInputGroup
                    type="password"
                    changeHandler={handlePasswordChange}
                    value={password}
                    inputType="text" /* Password will be hidden */
                    error={userFormError}
                  />
                </div>
                <div className="flex justify-between w-1/2 pl-10 pr-10">
                  <MonochromeButton level="secondary" onClick={handleGeneratePassword} type="button">
                    Generate
                  </MonochromeButton>
                  <CopyToClipboard text={password} onCopy={handleCopyPassword}>
                    <button type="button" className="password-button flex items-center">
                      {passwordCopied ? "Copied!" : <><AiOutlineCopy className="mr-2" />Copy</>}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
           {passwordError && <p className="error-message text-red-500 mb-10">{passwordError.message}</p>}

            <SelectBoxGroup
              type="role"
              changeHandler={handleUpdateUser}
              value={userDetails.role}
              options={roleOptions.filter(
                (role) =>
                  greaterThanRole(callerRole!, role.id) &&
                  (!isGuest(role.id) ||
                    greaterThanEqualRole(callerRole!, UserRole.orgAdmin))
              )}
              error={userFormError}
            />

            {userDetails.role !== UserRole.guest && (
              <SelectBoxGroup
                type="divisionId"
                changeHandler={handleUpdateUser}
                value={userDetails.divisionId}
                options={divisionOptions}
                error={userFormError}
                disabled={callerRole !== UserRole.orgAdmin}
              />
            )}

            {userDetails.role !== UserRole.divisionAdmin &&
              userDetails.role !== UserRole.guest && (
                <SelectBoxGroup
                  type="project"
                  changeHandler={handleUpdateUser}
                  value={userDetails.project}
                  options={projectOptions}
                  error={userFormError}
                  disabled={
                    !userDetails.divisionId ||
                    projectOptions.length === 0 ||
                    callerRole === UserRole.projectAdmin
                  }
                />
              )}
          </div>

          <div className="right">{/* Spacer */}</div>
        </div>

        <div className="button-container">
          <MonochromeButton level="primary" width="lg" type="submit" disabled={isNewUser && !password}>
            Save
          </MonochromeButton>
        </div>
      </LoadingWrapper>
    </StyledContentForm>
  );
};

export default ManageUser;