import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store";
import { selectIsLoadingPlan } from "store/ui";

import { selectCurrentSite, selectCurrentPlanId } from "store/project";
import { selectLoadedPlanId } from "store/tasks";
import { useCollectionQuery } from "hooks";
import {
  CollectionType,
  IPlan,
  IProject,
  ISite,
} from "@ehabitation/ts-utils/browser";
import { where } from "firebase/firestore";
import { isMultiSite } from "types";

export default function useInitPlan(
  handleSelectplan: Function,
  site?: ISite | null,
  project?: IProject | null
) {
  const { isLoading: isLoadingPlans, data: rawPlans } =
    useCollectionQuery<IPlan>(
      CollectionType.Plans,
      where("project", "==", site?.project)
    );

  var filteredPlans;
  if (isMultiSite(project)) filteredPlans = rawPlans;
  else filteredPlans = rawPlans?.filter((p) => (p as any).site == site?.id);

  // select plan if none yet selected
  const dispatch = useAppDispatch();
  const isLoadingPlan = useAppSelector(selectIsLoadingPlan);
  const currentSite = useAppSelector(selectCurrentSite);
  const currentPlanId = useAppSelector(selectCurrentPlanId);
  const loadedPlanId = useAppSelector(selectLoadedPlanId);
  const isCurrentPlanLoaded = currentPlanId && currentPlanId === loadedPlanId;

  const [isLoading, setIsLoading] = useState(!isCurrentPlanLoaded);

  useEffect(() => {
    if (!isCurrentPlanLoaded && !isLoadingPlan && !isLoadingPlans) {
      const fallBackPlanId = rawPlans?.sort((a: any, b: any) => {
        if ((a?.createdAt?.seconds || 0) > (b?.createdAt?.seconds || 0)) {
          return -1;
        } else {
          return 1;
        }
      })[0]?.id;

      const initPlanId =
        currentSite?.mainPlanId || currentPlanId || fallBackPlanId;

      if (initPlanId) {
        handleSelectplan(
          currentSite?.mainPlanId || currentPlanId || fallBackPlanId
        );
      }
      setIsLoading(false);
    }
  }, [
    dispatch,
    currentSite,
    currentPlanId,
    isLoadingPlan,
    isCurrentPlanLoaded,
    isLoadingPlans,
  ]);

  return {
    loadedPlanId,
    isLoading: isLoading || isLoadingPlan,
  };
}
