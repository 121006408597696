import { createSelector } from "reselect";
import { selectTaskCategoriesMap } from "store/taskCategory";

export const selectHighLevelTaskCategories = createSelector(
  [selectTaskCategoriesMap],
  (taskCategoriesMap) =>
    Object.values(taskCategoriesMap)?.filter(
      (taskType) => taskType.level === "category"
    )
);

export const selectSortedHighLevelTaskCategories = createSelector(
  [selectHighLevelTaskCategories],
  (taskTypes) => {
    if (!taskTypes) return [];
    return [...taskTypes].sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }
);

export const selectSubCategoryTaskCategories = createSelector(
  [selectTaskCategoriesMap],
  (taskCategoriesMap) =>
    Object.values(taskCategoriesMap)?.filter(
      (taskType) => taskType.level === "subCategory"
    )
);

export const selectItemTaskCategories = createSelector(
  [selectTaskCategoriesMap],
  (taskCategoriesMap) =>
    Object.values(taskCategoriesMap)?.filter(
      (taskType) => taskType.level === "item"
    )
);
