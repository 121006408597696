import { UserRole, IUser } from "@ehabitation/ts-utils/browser";

export const roleOptions: { id: UserRole; name: string }[] = [
  { id: UserRole.orgAdmin, name: "Organisation Admin" },
  { id: UserRole.divisionAdmin, name: "Division Admin" },
  { id: UserRole.projectAdmin, name: "Project Admin" },
  { id: UserRole.planner, name: "Planner" },
  { id: UserRole.guest, name: "Guest" },
];

export const intialUserFormState: IUser = {
  id: "",
  project: "",
  site: "",
  firstName: "",
  lastName: "",
  divisionId: "",
  email: "",
  orgId: "",
  role: UserRole.planner,
};
