import styled from "styled-components";
import { taskListWidth } from "styles";

interface IStyledTabContainer {
  off?: boolean;
}

export const StyledTabContainer = styled.div<IStyledTabContainer>`
  background-color: white;
  ${(props) => (props.off ? "display: none" : "")};
  height: 100%;
  left: 0;
  margin-left: calc(
    ${taskListWidth} + 2rem
  ); //2 rem is the margin right of the task list
  position: absolute;
  top: 0;
  width: calc(100% - ${taskListWidth} - 2rem);
  z-index: 1;
`;
