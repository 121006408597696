import { CollectionType, ITask } from "@ehabitation/ts-utils/browser";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import { fetchSiteTaskDocs } from "./tasks";

export const discardPlan = async (planId: string) =>
  updateDoc(doc(db, CollectionType.Plans, planId), {
    status: "discarded",
    complete: true,
  });

export const getPlanActiveCategories = async (siteId: string, planId: string) => {
    const tasks = ((await fetchSiteTaskDocs(planId)) ?? []).map(
      (doc) => doc.data() as ITask
    );
    const activeCategories = tasks.reduce((acc, task) => {
      if (task.taskCategory?.selectedName)
        acc.add(task.taskCategory.selectedName);
      return acc;
    }, new Set<string>());
  
    return activeCategories;
  };