import React, { Fragment, useEffect, useState } from "react";
import { RootState, useAppSelector } from "store";
import { selectOrderedProjects } from "store/projectManagement";
import { selectRole } from "store/auth";
import ProjectRow from "./ProjectRow";
import Divider from "Components/Admin/Divider";
import { IProjectWithSites } from "types";
import { ISite } from "@ehabitation/ts-utils/browser";

interface IDivisionProject {
  divisionId: string;
  projects: IProjectWithSites[];
}

const ProjectsList = () => {
  const orderedProjects = useAppSelector(selectOrderedProjects);
  const role = useAppSelector(selectRole);
  const { searchInput } = useAppSelector((state: RootState) => state.search);
  const [searchedDivisionProjects, setSearchedDivisionProjects] = useState<
    IDivisionProject[] | null
  >(null);
  const [divisionProjects, setDivisionProjects] = useState<IDivisionProject[]>(
    []
  );

  const checkSites = (sites: ISite[] | undefined) => {
    const names = sites!.length > 0 ? sites!.map((s) => s.name) : [];
    return names.join(" ").toLowerCase().includes(searchInput);
  };

  useEffect(() => {
    const createDivisionProjects = Object.entries(orderedProjects)
      .sort((a, b) => {
        if (a[0] > b[0]) return 1;
        if (b[0] > a[0]) return -1;
        return 0;
      })
      .map(([divisionId, projects]) => {
        return { divisionId, projects };
      });
    setDivisionProjects(createDivisionProjects);
  }, [orderedProjects]);

  useEffect(() => {
    const filterProjects = (projects: IProjectWithSites[]) => {
      return projects.filter(
        (p) =>
          `${p.projectCode ? `[${p.projectCode}] ` : ""} ${p.name}`
            ?.toLowerCase()
            .includes(searchInput) ||
          checkSites(p.sites) ||
          p.visibility?.toLowerCase().includes(searchInput)
      );
    };
    const filterDivisionProjects = () => {
      const filteredDivisionProjects = divisionProjects.map((dp) => {
        return {
          divisionId: dp.divisionId,
          projects: filterProjects(dp.projects),
        };
      });
      return filteredDivisionProjects.filter((dp) => dp.projects.length > 0);
    };
    setSearchedDivisionProjects(
      searchInput === "" ? divisionProjects : filterDivisionProjects()
    );
  }, [searchInput, divisionProjects]);

  return (
    <>
      {searchedDivisionProjects?.map((divisionProjects) => {
        return (
          <Fragment key={divisionProjects.divisionId}>
            {role !== "divisionAdmin" && role !== "projectAdmin" ? (
              <Divider text={divisionProjects.divisionId} version="division" />
            ) : null}

            {divisionProjects.projects.map((project) => (
              <ProjectRow project={project} key={project.id} />
            ))}
          </Fragment>
        );
      })}
    </>
  );
};

export default ProjectsList;
