import { FC, useEffect, useState } from "react";
import { db } from "firebaseConfig";
import {
  CollectionType,
  IPlan,
  ISite,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { useIsMounted } from "hooks";
import { completePlan } from "../helpers";
import { MonochromeButton, Spinner } from "@ehabitation/ui";
import { doc, onSnapshot } from "firebase/firestore";
import { useAppSelector } from "store";
import { selectUserId } from "store/auth";

const usePlan = (planId: string | undefined) => {
  const [plan, setPlan] = useState<IPlan>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (planId) {
      const unsubscribe = onSnapshot(
        doc(db, CollectionType.Plans, planId),
        (snapshot) => {
          if (isMounted()) {
            const planDoc = snapshot;
            if (!planDoc.exists()) {
              setError("Failed to load completed plan.");
            } else {
              setPlan(transformPlanDoc(planDoc.id, planDoc.data()));
            }
            setIsLoading(false);
          }
        },
        (error) => {
          console.error(error);
          if (isMounted()) {
            setError("Failed to load completed plan.");
            setIsLoading(false);
          }
        }
      );
      return unsubscribe;
    }
  }, [planId]);
  return { plan, isLoading, error };
};

export const PlanImportComplete: FC<{
  planId: string;
  handleBack: Function;
  handleSuccess: Function;
  site: ISite;
}> = ({ planId, handleBack, handleSuccess, site }) => {
  const isMounted = useIsMounted();
  const userId = useAppSelector(selectUserId)!;

  const [error, setError] = useState<string>();
  const [isCompleting, setIsCompleting] = useState<boolean>(false);

  const {
    plan,
    error: loadPlanError,
    isLoading: isLoadingPlan,
  } = usePlan(planId);

  if (isLoadingPlan)
    return (
      <div className="w-full h-full grid place-content-center">
        <div className="flex flex-col items-center gap-6">
          <Spinner />
          <p className="mt-4 text-3xl">Loading Plan...</p>
        </div>
      </div>
    );

  return (
    <div className="w-full h-full grid place-content-center">
      <div className="w-[56rem] flex flex-col gap-6">
        {error || loadPlanError || !plan ? (
          <>
            <div>
              <p className="text-red-600">An error has occurred.</p>
              <p className="text-red-600">
                Please reload this page and try again, or contact
                support@ehab.co if this error persists.
              </p>
              <p className="text-red-600">
                If you would like to try and recover an in-progress import,
                please contact support@ehab.co. We might be able to help!
              </p>
            </div>
          </>
        ) : isCompleting ? (
          <div className="flex flex-col items-center gap-6">
            <Spinner />
            <p className="mt-4 text-3xl">Completing Import...</p>
            <ul className="bg-slate-200 p-4 w-full">
              <li>
                <strong>Site:</strong> {site?.name}
              </li>
              <li>
                <strong>Plan Name:</strong> {plan.title}
              </li>
              <li>
                <strong>File Name:</strong> {plan.originalPlanName}
              </li>
              <li>
                <strong>Uploaded:</strong> {plan.createdAt?.toLocaleString()}
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <h2 className="font-bold">Complete Import?</h2>
            <ul className="bg-slate-200 p-4 w-full">
              <li>
                <strong>Site:</strong> {site?.name}
              </li>
              <li>
                <strong>Plan Name:</strong> {plan.title}
              </li>
              <li>
                <strong>File Name:</strong> {plan.originalPlanName}
              </li>
              <li>
                <strong>Plan Created:</strong>{" "}
                {plan.createdAt?.toLocaleString()}
              </li>
            </ul>
          </div>
        )}
        <div className="flex gap-6 w-full justify-between">
          <MonochromeButton
            className="self-end"
            level="primary"
            onClick={() => handleBack()}
            disabled={isCompleting || !plan}
            width="md"
            name="discard"
            data-testid="draft-slide-discard-button"
          >
            Back
          </MonochromeButton>
          <MonochromeButton
            className="self-end"
            level="secondary"
            disabled={isCompleting || !plan}
            onClick={() => {
              if (plan) {
                setIsCompleting(true);
                completePlan(plan.id, site.id, userId)
                  .then(() => {
                    handleSuccess();
                  })
                  .catch((error) => isMounted() && setError(error))
                  .finally(() => isMounted() && setIsCompleting(false));
              }
            }}
            width="md"
            name="continue"
            data-testid="draft-slide-continue-button"
          >
            Complete
          </MonochromeButton>
        </div>
      </div>
    </div>
  );
};
