import styled, { css } from "styled-components";
import { StyledAdminTableHeader, StyledAdminTableRow } from "elements";
import { flex } from "styles";

const columnWidths = css`
  .name {
    width: 20%;
  }

  .sites {
    width: 40%;
  }

  .users {
    width: 15%;
  }

  .visibility {
    width: 20%;
  }

  .icon {
    width: 5%;
  }
`;

export const StyledTableHeader = styled(StyledAdminTableHeader)`
  ${columnWidths};
`;

export const StyledTableRow = styled(StyledAdminTableRow)`
  ${columnWidths};

  .sites,
  .name,
  .visibility,
  .users {
    ${flex("column", "center", "flex-start")};
    height: 100%;

    p {
      text-transform: capitalize;
    }
  }
`;
