import { ViolationData, WeatherKey } from "@ehabitation/ts-utils/browser";
import { limit, orderBy, where, query, collection } from "firebase/firestore";
import { getBlob, getStorage, ref } from "firebase/storage";
import { db } from "firebaseConfig";

export type SimulationLevel = "min" | "max" | "20" | "40" | "80";
export interface SimulationTaskResult {
  accuracy: number;
  baseAccuracy: number;
  delayDistribution: { [key: string]: number } | undefined;
  likelyCause: string;
  maxDelayDays: number | undefined;
  riskCategoryId: string | null;
  riskIntervalResults: { [level in SimulationLevel]: RiskIntervalTaskResult };
  risks: any[]; // this has to be any because historically the structure of the risk object was not consistent
  start: Date;
  end: Date;
  violationData: ViolationData | undefined;
  taskType: string;
  startDistribution: [{ date: string; count: number }];
  endDistribution: [{ date: string; count: number }];
  planEndCorrelation: number;
  [WeatherKey.dailyRainAcc]?: number;
  [WeatherKey.hourlyRainAcc]?: number;
  [WeatherKey.maxTemp]?: number;
  [WeatherKey.minTemp]?: number;
  [WeatherKey.snowfall]?: number;
  [WeatherKey.snowfall24Hour]?: number;
  [WeatherKey.visibility]?: number;
  [WeatherKey.waveHeight]?: number;
  [WeatherKey.wind]?: number;
  [WeatherKey.windGusts]?: number;
}

export interface RiskIntervalTaskResult {
  endDate: Date;
  startDate: Date;
  startDelay: number;
  dependencyDelay: number;
  weatherDays: number;
}

export interface RiskIntervalPlanResult {
  finishDate: Date;
  weatherDays: number;
  weatherDaysPerQuantile: number;
  floats: number;
  tasksImpacted: number;
  workingDaysDelay?: number;
}

export interface SimulationPlanResult {
  plannedEndDate: Date;
  riskIntervalResults?: { [riskInterval: string]: RiskIntervalPlanResult };
  maxDelayDays?: number;
  finishDistribution?: { date: string; count: number }[];
}

export interface SimulationResult {
  taskResults: { [id: string]: SimulationTaskResult };
  planResults: SimulationPlanResult;
}

export interface SimulationRiskDriverResult {
  taskCorrelationsMatrix: {
    [id: string]: {
      startDelay: number;
      predecessorCorrelations: {
        [id: string]: number;
      };
    };
  };
}

export const getSimulationResults = async (
  resultsPath: string
): Promise<SimulationResult> => {
  const storage = getStorage();
  const storageRef = ref(storage, resultsPath);

  const blob = await getBlob(storageRef);

  return blob.text().then((text) => {
    const data = JSON.parse(text, (key, value) => {
      if (key === "start" || key === "end") {
        return new Date(value);
      }
      return value;
    }) as SimulationResult;
    return data;
  });
};

export const getSimulationRiskDriverResults = async (
  resultsPath: string
): Promise<SimulationRiskDriverResult> => {
  const storage = getStorage();
  const storageRef = ref(storage, resultsPath);

  const blob = await getBlob(storageRef);

  return blob.text().then((text) => {
    const data = JSON.parse(text) as SimulationRiskDriverResult;
    return data;
  });
};

export const getLastSimulationQuery = (planId: string, siteId: string) => {
  return query(
    collection(db, "plans", planId, "simulations"),
    // where("site", "==", siteId),
    orderBy("createdAt", "desc"),
    limit(1)
  );
};

export const getLastCompleteSimulationQuery = (planId: string) => {
  return query(
    collection(db, "plans", planId, "simulations"),
    where("status", "==", "complete"),
    orderBy("createdAt", "desc"),
    limit(1)
  );
};
