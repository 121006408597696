import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { convertUnits } from "../../../../utils/unitConversions";
import {
  extremeDailyRainAcc,
  extremeMinTemp,
  extremeWind,
  extremeMaxTemp,
  WeatherKey,
  WeatherThresholds,
  extremeSnowfall,
  extremeWaveHeight,
  extremeWindGusts,
  extremeSnowfall24Hour,
  extremeVisibility,
} from "@ehabitation/ts-utils/browser";

interface ThresholdInputProps {
  id: string;
  readOnly: boolean;
  className?: string;
  type: WeatherKey;
  handleChange: Function;
  thresholds: WeatherThresholds;
  label: string;
}

const isError = (type: WeatherKey, thresholds: WeatherThresholds) => {
  switch (type) {
    case WeatherKey.minTemp:
    case WeatherKey.maxTemp:
      return thresholds.minTemp != null &&
        thresholds.maxTemp != null &&
        thresholds.minTemp >= thresholds.maxTemp!
        ? `error`
        : "";
    case WeatherKey.dailyRainAcc:
    case WeatherKey.hourlyRainAcc:
      return thresholds.hourlyRainAcc != null &&
        thresholds.dailyRainAcc != null &&
        thresholds.hourlyRainAcc > thresholds.dailyRainAcc
        ? `error`
        : "";
    case WeatherKey.snowfall24Hour:
    case WeatherKey.snowfall:
      return thresholds.snowfall != null &&
          thresholds.snowfall24Hour != null &&
          thresholds.snowfall > thresholds.snowfall24Hour
        ? `error`
        : "";
    default:
      return "";
  }
};

export const minMaxFetchers: Record<
  WeatherKey,
  (thresholds: WeatherThresholds) => { min: number; max: number }
> = {
  [WeatherKey.minTemp]: (thresholds) => ({
    min: extremeMinTemp,
    max: thresholds.maxTemp ?? extremeMaxTemp,
  }),
  [WeatherKey.maxTemp]: (thresholds) => ({
    min: thresholds.minTemp ?? 0,
    max: extremeMaxTemp,
  }),
  [WeatherKey.dailyRainAcc]: (thresholds) => ({
    min: thresholds.hourlyRainAcc ?? 0,
    max: extremeDailyRainAcc,
  }),
  [WeatherKey.hourlyRainAcc]: (thresholds) => ({
    min: 0,
    max: thresholds.dailyRainAcc ?? extremeDailyRainAcc,
  }),
  [WeatherKey.wind]: () => ({
    min: 0,
    max: extremeWind,
  }),
  [WeatherKey.snowfall]: () => ({
    min: 0,
    max: extremeSnowfall,
  }),
  [WeatherKey.waveHeight]: () => ({
    min: 0,
    max: extremeWaveHeight,
  }),
  [WeatherKey.windGusts]: () => ({
    min: 0,
    max: extremeWindGusts,
  }),
  [WeatherKey.snowfall24Hour]: () => ({
    min: 0,
    max: extremeSnowfall24Hour,
  }),
  [WeatherKey.visibility]: () => ({
    min: 0,
    max: extremeVisibility,
  }),
};

const ThresholdInput: FC<ThresholdInputProps> = ({
  id,
  readOnly,
  type,
  thresholds,
  handleChange,
  label,
  className,
}) => {
  const currentUnit = useSelector((state: RootState) => state.unit.currentUnit);
  const [rawValue, setRawValue] = useState<number | undefined>(
    thresholds[type] !== undefined ? Number(thresholds[type]) : undefined
  );

  const { min, max } = minMaxFetchers[type](thresholds);

  const convertToDisplayUnit = (value: number) =>
    convertUnits(
      value,
      "metric",
      currentUnit,
      type === WeatherKey.minTemp || type === WeatherKey.maxTemp
        ? "temperature"
        : type === WeatherKey.wind || type === WeatherKey.windGusts
        ? "speed"
        : "distance"
    );

  const convertToStorageUnit = (value: number) =>
    convertUnits(
      value,
      currentUnit,
      "metric",
      type === WeatherKey.minTemp || type === WeatherKey.maxTemp
        ? "temperature"
        : type === WeatherKey.wind || type === WeatherKey.windGusts
        ? "speed"
        : "distance"
    );

  useEffect(() => {
    if (thresholds[type] !== undefined) {
      setRawValue(Number(thresholds[type]));
    }
  }, [thresholds, type]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = isNaN(Number(event.target.value))
      ? undefined
      : Number(event.target.value);

    setRawValue(newValue);

    if (newValue !== undefined) {
      // Convert input back to storage unit for consistency
      handleChange(convertToStorageUnit(newValue));
    } else {
      handleChange(undefined);
    }
  };

  const displayValue = rawValue !== undefined ? convertToDisplayUnit(rawValue) : "";

  const convertedMin = convertToDisplayUnit(min);
  const convertedMax = convertToDisplayUnit(max);

  console.log(displayValue, convertedMin, convertedMax);
  
  const errored =
    isError(type, thresholds) ||
    (typeof displayValue === "number" &&
      ((convertedMax !== undefined && displayValue > convertedMax) ||
       (convertedMin !== undefined && displayValue < convertedMin)));

  const errorClass = errored ? "text-red-500 font-bold" : "";

  return (
    <div className={`w-full ${className}`} data-testid={`category-${type}`}>
      <input
        type="number"
        placeholder={displayValue === undefined ? "None" : ""}
        data-testid={`input-${id}`}
        value={displayValue}
        onChange={handleInputChange}
        name={type}
        role="spinbutton"
        aria-invalid={!!errorClass}
        min={convertedMin}
        max={convertedMax}
        className={`${errorClass} bg-inherit w-full`}
        aria-label={label}
        disabled={readOnly}
        style={{ minWidth: "50px" }}
      />
    </div>
  );
};

export default ThresholdInput;
