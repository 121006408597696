//Store
import { useAppDispatch, useAppSelector } from "store";
import {
  setSelectedDivisionTab,
  selectDivisionManagementTab,
} from "store/divisionManagement";
//Types
import { DivisionManagementTabOption } from "types";

export const useDivisionManagementTab = () => {
  const dispatch = useAppDispatch();

  const selectedTab = useAppSelector(selectDivisionManagementTab);

  const handleChangeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const tabOption = e.currentTarget.name as DivisionManagementTabOption;
    dispatch(setSelectedDivisionTab(tabOption));
  };

  const goToNewTab = () => {
    dispatch(setSelectedDivisionTab("Details"));
  };

  return {
    selectedTab,
    handleChangeTab,
    goToNewTab,
  };
};
