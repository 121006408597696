//Store
import { useAppSelector } from "store";
import { selectHolidays, selectWorkDays } from "store/calendar";
import { selectCurrentSite, selectCurrentPlanId } from "store/project";
import { userMetadataSelector } from "store/p6";
import {
  selectTaskCompleteDependencyMap,
  allTasksSelector,
  allTasksAsArraySelector,
} from "store/tasks";

export const usePlanStateManager = () => {
  const allTasks = useAppSelector(allTasksSelector);
  const ganttTasks = useAppSelector(allTasksAsArraySelector);
  const currentSite = useAppSelector(selectCurrentSite);
  const workDays = useAppSelector(selectWorkDays);
  const holidays = useAppSelector(selectHolidays);
  const planId = useAppSelector(selectCurrentPlanId);
  const { siteId } = useAppSelector(userMetadataSelector);
  const dependencyMap = useAppSelector(selectTaskCompleteDependencyMap);

  return {
    ganttTasks,
    allTasks,
    dependencyMap,
    currentSite,
    siteStartDate: currentSite?.startDate && new Date(currentSite.startDate),
    siteEndDate: currentSite?.endDate && new Date(currentSite.endDate),
    workDays: [...workDays],
    holidays: [...holidays],
    metadata: {
      siteId,
      planId,
    },
  };
};
