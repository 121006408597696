import { Component } from "react";
import GanttJS from "gantt-lib";
import md5 from "crypto-js/md5";
import Hex from "crypto-js/enc-hex";
import fastEqual from "fast-deep-equal/es6/react";
import { noop } from "lodash";

export default class ReactGantt extends Component {
  ganttRef = undefined;

  componentDidMount() {
    if (this.props.tasks?.length) {
      this.renderFrappeGanttDOM();
    }
  }

  getStringifyHash(data) {
    return this.getHash(JSON.stringify(data));
  }

  getHash(string) {
    return Hex.stringify(md5(string));
  }

  // redraw the gantt when updated
  componentDidUpdate(prevProps, prevState) {
    if (!this.ganttInst && this.props.tasks?.length) {
      this.renderFrappeGanttDOM();
    } else if (
      this.ganttInst &&
      (prevProps.tasks.length !== this.props.tasks.length ||
        !fastEqual(prevProps, this.props))
    ) {
      this.ganttInst.refresh(
        this.props.tasks,
        this.props.allTasks,
        this.props.isSimulating,
        this.props.showDeps,
        this.props.metadata,
        this.props.xPos,
        {
          on_update_risks: this.props.onUpdateRisks || noop,
          on_safety_end_change: this.props.onSafetyEndChange || noop,
          on_scroll_change: this.props.onScrollChange || noop,
        }
      );
    }
  }

  /**
   * render the gantt chart
   * @returns {GanttJS}
   */
  renderFrappeGanttDOM() {
    // init the Gantt
    // if exist, return
    if (this.ganttInst) return this.ganttInst;

    const {
      onUpdateRisks,
      onSafetyEndChange,
      tasks,
      allTasks,
      isSimulating,
      viewMode,
      onScrollChange,
      startDate,
      endDate,
      showDeps,
      workDays,
      holidays,
      metadata,
    } = this.props;

    // new instance
    this.ganttInst = new GanttJS(
      this.ganttRef,
      tasks,
      allTasks,
      isSimulating,
      showDeps,
      startDate,
      endDate,
      workDays,
      holidays,
      metadata,
      {
        on_update_risks: onUpdateRisks || noop,
        on_safety_end_change: onSafetyEndChange || noop,
        on_scroll_change: onScrollChange || noop,
      }
    );

    // change view mode
    this.ganttInst.change_view_mode(viewMode);
    return this.ganttInst;
  }

  render() {
    return (
      <svg
        ref={(node) => {
          this.ganttRef = node;
        }}
      />
    );
  }
}
