//Store
import { createSelector } from "reselect";
//Selectors
import { selectCurrentSite, selectAllSites } from "store/project";

export const selectSiteOptions = createSelector([selectAllSites], (allSites) =>
  allSites
    ?.map((site) => ({
      name: site.name,
      id: site.id,
      status: site.status,
    }))
    .sort((a, b) => {
      const aName = a.name!.toLowerCase();
      const bName = b.name!.toLowerCase();

      if (aName < bName) return -1;
      if (bName > aName) return 1;
      return 0;
    })
);

export const selectProjectStartAndEndDates = createSelector(
  [selectCurrentSite],
  (currentSite) => ({
    startDate: currentSite?.startDate,
    endDate: currentSite?.endDate,
  })
);
