import { useEffect, useState } from "react";
import { db } from "firebaseConfig";
import { FirestoreSchema } from "firestoreTypes/firestore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  onSnapshot,
  query,
  Query,
  QueryConstraint,
} from "firebase/firestore";
import { noop } from "lodash";
import { useIsMounted } from "./useIsMounted";

function subscribeToCollection<T>(
  collectionName: keyof FirestoreSchema | "divisions" | string,
  updateData: (data: T[]) => void
) {
  const collectionRef = collection(db, collectionName);
  return onSnapshot(collectionRef, (docs) => {
    const data: T[] = [];
    docs.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
      } as T);
    });
    updateData(data);
  });
}

function subscribeToSnap<T>(queryRef: Query, updateData: (data: T[]) => void) {
  return onSnapshot(queryRef, (docs) => {
    const data: T[] = [];
    docs.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
      } as T);
    });
    updateData(data);
  });
}

export function useCollectionQuery<T>(
  collectionPath:
    | keyof FirestoreSchema
    | "divisions"
    | "notificationTypes"
    | string,
  ...queryConstraints: QueryConstraint[]
) {
  const queryClient = useQueryClient();

  const queryConstraintsKey = queryConstraints.map(
    (c: any) => `${c.type}_${c._field?.segments?.join("-")}_${c._value}`
  );
  const queryKeys = queryConstraints
    ? [collectionPath, ...queryConstraintsKey]
    : [collectionPath];

  // not ideal, will setup multiple listeners if used in multiple places with same path
  useEffect(() => {
    const handleSnapshot = (newData: T[]) => {
      queryClient.setQueryData(queryKeys, newData);
    };
    const unsubscribe = queryConstraints
      ? subscribeToSnap<T>(
          query(collection(db, collectionPath), ...queryConstraints),
          handleSnapshot
        )
      : subscribeToCollection<T>(collectionPath, handleSnapshot);

    return () => unsubscribe();
  }, [collectionPath, queryClient, ...queryKeys]);

  return useQuery<T[], Error>(queryKeys, () => new Promise<T[]>(noop));
}

export function useDocQuery<T>(
  firebasePathSegments: string[] = [],
  transformer?: (data: DocumentSnapshot<DocumentData>) => T
) {
  const queryClient = useQueryClient();

  // not ideal, will setup multiple listeners if used in multiple places with same path
  useEffect(() => {
    if (firebasePathSegments.length) {
      const handleSnapshot = (newData: DocumentSnapshot<DocumentData>) => {
        if (transformer) {
          queryClient.setQueryData(firebasePathSegments, transformer(newData));
        } else {
          queryClient.setQueryData(firebasePathSegments, {
            ...newData.data(),
            id: newData.id,
          } as T);
        }
      };

      const unsubscribe = onSnapshot(
        doc(db, firebasePathSegments.join("/")),
        handleSnapshot
      );

      return unsubscribe;
    }
  }, [...firebasePathSegments, transformer]);

  return useQuery<T, Error>(firebasePathSegments, () => new Promise<T>(noop));
}

export function useDocs<T>(
  collectionPath: string,
  docIds: string[],
  transformer: (id: string, doc: any) => T
) {
  const [results, setResults] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    const subscriptions: (() => void)[] = [];
    if (collectionPath && docIds.length) {
      setIsLoading(true);
      let resolvedCount = 0;
      for (const docId of docIds) {
        const docRef = doc(db, collectionPath, docId);
        const unsubscribe = onSnapshot(
          docRef,
          (doc) => {
            if (doc.exists()) {
              isMounted() &&
                setResults((docs) => {
                  const index = docs.findIndex(({ id }: any) => id === docId);
                  if (index > -1) {
                    docs[index] = transformer(doc.id, doc.data());
                  } else {
                    docs.push(transformer(doc.id, doc.data()));
                  }
                  return [...docs];
                });
              resolvedCount++;
              if (resolvedCount === docIds.length && isMounted()) {
                setIsLoading(false);
              }
            }
          },
          () => {
            resolvedCount++;
            if (resolvedCount === docIds.length && isMounted()) {
              setIsLoading(false);
            }
          }
        );
        subscriptions.push(unsubscribe);
      }
    }
    if (subscriptions.length) {
      return () => {
        for (const unsubscribe of subscriptions) {
          unsubscribe();
        }
      };
    }
  }, [collectionPath, docIds]);

  return { results, isLoading };
}
