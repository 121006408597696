import { IOrganisationUsers } from "types";
import { db } from "firebaseConfig";
import { CollectionType, IUser, UserRole } from "@ehabitation/ts-utils/browser";
import { collection, onSnapshot, query, where } from "firebase/firestore";

interface ISubscribeToUsers {
  role: UserRole;
  orgId: string;
  divisionId?: string;
  projectId?: string;
  dispatcher: (users: IOrganisationUsers) => void;
  setReady: (bool: boolean) => void;
}
export const subscribeToUsers = ({
  role,
  orgId,
  divisionId,
  projectId,
  dispatcher,
  setReady,
}: ISubscribeToUsers) => {
  const queryBase = determineQueryBase(role, orgId, divisionId, projectId);

  const unsubscribe = onSnapshot(queryBase, (snapshot) => {
    setReady(true);

    const organisationUsers: IOrganisationUsers = {};

    snapshot.forEach((doc) => {
      const data = doc.data() as IUser;
      organisationUsers[doc.id] = data;
    });

    dispatcher(organisationUsers);
  });

  return unsubscribe;
};

const determineQueryBase = (
  role: UserRole,
  orgId?: string,
  divisionId?: string,
  projectId?: string
) => {
  switch (role) {
    case UserRole.orgAdmin: {
      return query(
        collection(db, CollectionType.Users),
        where("orgId", "==", orgId)
      );
    }
    case UserRole.divisionAdmin: {
      return query(
        collection(db, CollectionType.Users),
        where("divisionId", "==", divisionId)
      );
    }
    default: {
      return query(
        collection(db, CollectionType.Users),
        where("project", "==", projectId)
      );
    }
  }
};
