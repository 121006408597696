import styled, { css } from "styled-components";
import { StyledAdminTableHeader, StyledAdminTableRow } from "elements";
import { flex } from "styles";

const columnWidths = css`
  .name {
    width: 27%;
  }

  .projects {
    width: 27%;
  }

  .sites {
    width: 10%;
  }

  .users {
    width: 10%;
  }

  .visibility {
    width: 21%;
  }

  .icon {
    width: 5%;
  }
`;

export const StyledTableHeader = styled(StyledAdminTableHeader)`
  ${columnWidths};
`;

export const StyledTableRow = styled(StyledAdminTableRow)`
  ${columnWidths};

  .projects,
  .sites,
  .name,
  .visibility,
  .users {
    ${flex("column", "flex-start", "flex-start")};
    height: 100%;

    p {
      text-transform: capitalize;
    }
  }
`;
