import { ChangeEvent, useCallback, useState } from "react";
import config from "config";
import { useAppSelector } from "store";
import { logMappingEvent } from "helpers/analytics";
import { firebaseFunction } from "helpers";
import { getBlob, getStorage, ref, uploadBytes } from "firebase/storage";
import fileDownload from "js-file-download";
import { selectUserId } from "store/auth";

export const usePlnxMapping = (planId: string, orgId: string) => {
  const [isMapping, setIsMapping] = useState(false);
  const [unknownFile, setUnknownFile] = useState<boolean>(false);
  const [plnxFile, setPlnxFile] = useState<File>();

  const userId = useAppSelector(selectUserId);

  const mappingDisabled = !plnxFile || isMapping || unknownFile;

  const submitPlnx = useCallback(async () => {
    if (mappingDisabled) return;
    setIsMapping(true);
    logMappingEvent({ planId, orgId });

    const fileName = `${planId}_${userId}_${new Date().getTime()}.plnx`;
    const storage = getStorage(undefined, `gs://${config.CACHE_BUCKET}`);
    await uploadBytes(
      ref(storage, `client_data/${orgId}/PRAPlnxMapping/${fileName}`),
      plnxFile!
    );

    const PRAPlnxMapping = firebaseFunction<{ message: string }>(
      "PRAPlnxMapping"
    );
    const {
      data: { message },
    } = await PRAPlnxMapping({
      userId,
      planId,
      orgId,
      fileName,
    });
    const mappedFileName = JSON.parse(message).mappedFileName;
    const fileRef = ref(
      storage,
      `client_data/${orgId}/PRAPlnxMapping/${mappedFileName}`
    );

    if (fileRef) {
      await getBlob(fileRef)
        .then((blob) =>
          fileDownload(blob, `mapped_${plnxFile!.name}`, "application/xml")
        )
        .catch((error) =>
          console.error(
            error.code,
            `could not download plnx file for plan ${planId}: ${error.message}`
          )
        );
    } else {
      console.error("could not download file");
    }

    setPlnxFile(undefined);
    setIsMapping(false);
  }, [mappingDisabled, planId, plnxFile]);

  const handlePlnxFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return; //error?
    const file = event.target.files[0];
    const fileType = file.name.split(".").at(-1)?.toLocaleUpperCase();
    setUnknownFile(!(fileType === "PLNX"));
    setPlnxFile(file);
  };

  return {
    isMapping,
    mappingDisabled,
    plnxFileName: plnxFile?.name,
    plnxFileIsSet: !!plnxFile,
    handlePlnxFile,
    submitPlnx,
  };
};
