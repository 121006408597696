import {
  CollectionType,
  ITask,
  WeatherKey,
  weatherUnitKey,
} from "@ehabitation/ts-utils/browser";
import {
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import { isEqual } from "lodash";

import { getTasksQuery } from "helpers";

export const tasksEquivalent = (
  snapshotTask?: ITask,
  newTask?: ITask
): boolean => {
  if (!snapshotTask || !newTask) return false; // We don't have enough info to compare
  if (
    !isEqual(newTask.start, snapshotTask.start) ||
    !isEqual(newTask.end, snapshotTask.end) ||
    !isEqual(newTask.title, snapshotTask.title) ||
    !isEqual(newTask.description, snapshotTask.description) ||
    !isEqual(newTask.accuracy, snapshotTask.accuracy) ||
    !isEqual(newTask.minTemp, snapshotTask.minTemp) ||
    !isEqual(newTask.maxTemp, snapshotTask.maxTemp) ||
    !isEqual(newTask.wind, snapshotTask.wind) ||
    !isEqual(newTask.hourlyRainAcc, snapshotTask.hourlyRainAcc) ||
    !isEqual(newTask.dailyRainAcc, snapshotTask.dailyRainAcc) ||
    !isEqual(newTask.risks, snapshotTask.risks) ||
    !isEqual(newTask.taskType, snapshotTask.taskType)
  ) {
    return false;
  } else {
    return true;
  }
};

export const saveTasksToDB = async (
  allTasks: ITask[],
  savedTaskSnapshot: ITask[],
  planId: string
) => {
  try {
    const tasksCollRef = collection(
      db,
      CollectionType.Plans,
      planId,
      CollectionType.Tasks
    );

    let updatesBatch = writeBatch(db);
    let updatesCount = 0;

    for (let i = 0; i < allTasks.length; i++) {
      const taskFound = savedTaskSnapshot.find(
        (taskDB) => taskDB.id === allTasks[i].id
      );

      const newTask: any = { ...allTasks![i] };
      newTask.start = new Date(allTasks![i]!.start!);
      newTask.end = new Date(allTasks![i]!.end!);

      if (updatesCount === 500) {
        await updatesBatch.commit();
        updatesBatch = writeBatch(db);
        updatesCount = 0;
      }

      if (taskFound) {
        if (
          !isEqual(allTasks[i].start, taskFound.start?.toISOString?.()) ||
          !isEqual(allTasks[i].end, taskFound.end?.toISOString?.()) ||
          !isEqual(allTasks[i].title, taskFound.title) ||
          !isEqual(allTasks[i].description, taskFound.description) ||
          !isEqual(allTasks[i].accuracy, taskFound.accuracy) ||
          !isEqual(allTasks[i].minTemp, taskFound.minTemp) ||
          !isEqual(allTasks[i].maxTemp, taskFound.maxTemp) ||
          !isEqual(allTasks[i].wind, taskFound.wind) ||
          !isEqual(allTasks[i].hourlyRainAcc, taskFound.hourlyRainAcc) ||
          !isEqual(allTasks[i].dailyRainAcc, taskFound.dailyRainAcc) ||
          !isEqual(allTasks[i].risks, taskFound.risks) ||
          !isEqual(allTasks[i].taskType, taskFound.taskType)
        ) {
          if (!newTask.isCriticalPath) newTask.isCriticalPath = false;

          const updateTask: any = { ...newTask };
          if (updateTask.WBS) {
            updateTask.accuracy = undefined;
            updateTask.baseAccuracy = undefined;
          }

          for (const threshold in WeatherKey) {
            if (typeof updateTask[threshold] !== "number") {
              const unitKey = weatherUnitKey[threshold as WeatherKey];
              updateTask[threshold as WeatherKey] = deleteField();
              updateTask[unitKey] = deleteField();
            }
          }

          updatesBatch.update(doc(tasksCollRef, newTask.id), updateTask);
          updatesCount++;
        }
      } else if (!taskFound) {
        newTask.createdAt = newTask.createdAt.toDate().toISOString();
        updatesBatch.set(doc(tasksCollRef, newTask.id), newTask);
        updatesCount++;
      }
    }

    await updatesBatch.commit();
    await updateDoc(doc(db, "plans", planId), { tasksUpdatedAt: new Date() });
  } catch (error) {
    console.log("There was an error saving to the DB", error);
  }
};

export const determineTaskTypeDocRef = async (projectId: string) => {
  const siteRiskMatrixDoc = query(
    collection(db, "riskMatrix"),
    where("tier", "==", "projects"),
    where("tierObjectId", "==", projectId)
  );

  const siteRiskMatrixSnapshot = await getDocs(siteRiskMatrixDoc);

  if (!siteRiskMatrixSnapshot.empty) {
    return siteRiskMatrixSnapshot.docs[0].ref;
  } else return doc(db, "riskMatrix", "default");
};

export const fetchSiteTaskDocs = async (planId: string) => {
  try {
    if (planId) {
      const tasksQuery = getTasksQuery(planId);
      return (await getDocs(tasksQuery)).docs;
    }
  } catch (error) {
    console.error(error);
  }
};
