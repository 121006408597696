import React from "react";
import { StyledTableHeader } from "./styles";

const ProjectsHeader = () => {
  return (
    <StyledTableHeader data-testid="projects-header">
      <p className="name">Name</p>
      <p className="sites">Sites</p>
      <p className="users">Users</p>
      <p className="visibility">visibility</p>
      <p className="icon"></p>
    </StyledTableHeader>
  );
};

export default ProjectsHeader;
