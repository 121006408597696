//React
import React, { useCallback } from "react";
//Icons
import { VscMenu } from "react-icons/vsc";
//Store
import { useAppDispatch } from "store";
import { clickMenuButton } from "store/menu";

const MenuButton = () => {
  const dispatch = useAppDispatch();

  const handleToggleMenu = useCallback(() => {
    dispatch(clickMenuButton());
  }, [dispatch]);

  return (
    <button
      onClick={handleToggleMenu}
      name="Menu"
      data-testid="menu-button"
      className="flex flex-row justify-center items-center outline-none focus:shadow-[0_0_0.2rem_0.1rem] focus:shadow-[#13cd89] bg-gray-50 border-none cursor-pointer p-2 z-[2]"
    >
      <VscMenu className="text-gray-800 text-4xl" />
    </button>
  );
};

export default MenuButton;
