import { FC, useState } from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { JourneyStep } from "./Journey";

interface Journey {
  title: string;
  steps: JourneyStep[];
}

interface JourneyGroup {
  group: string;
  journeys: Journey[];
}

interface SidebarContentProps {
  journeys: JourneyGroup[];
  onJourneySelect: (title: string) => void;
  selectedJourney: string | null;
}

export const SidebarContentJourneys: FC<SidebarContentProps> = ({
  journeys,
  onJourneySelect,
  selectedJourney,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(selectedJourney !== null);
  const [expandedGroup, setExpandedGroup] = useState<string | null>(null);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleGroup = (group: string) => {
    setExpandedGroup((prevGroup) => (prevGroup === group ? null : group));
  };

  return (
    <div>
      <h3
        className="text-2xl font-semibold flex items-center pt-8 pb-4"
        // onClick={toggleCollapse}
      >
        {/* {isCollapsed ? <BiChevronRight /> : <BiChevronDown />} */}
        Workflows
      </h3>
      {!isCollapsed &&
        journeys.map((group) => (
          <div key={group.group}>
            <div
              className="text-2xl font-semibold cursor-pointer flex items-center pt-4 pb-2"
              onClick={() => toggleGroup(group.group)}
            >
              {expandedGroup === group.group ? <BiChevronDown /> : <BiChevronRight />}
              {group.group}
            </div>
            {expandedGroup === group.group && (
              <ul>
                {group.journeys.map((journey) => (
                  <li
                    key={journey.title}
                    onClick={() => onJourneySelect(journey.title)}
                    className={`p-2 pl-6 cursor-pointer transition-colors ${
                      selectedJourney === journey.title ? 'bg-[#1CEAA0] text-gray-600' : 'hover:bg-gray-200'
                    }`}
                  >
                    {journey.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
    </div>
  );
};
