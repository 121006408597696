import React, { FC, Fragment, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { AiOutlineCheck, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { collection, doc, updateDoc } from "firebase/firestore";

import { Button } from "@ehabitation/ui";
import { db } from "firebaseConfig";
import { IOrganisationDocument } from "firestoreTypes";
import { Spinner } from "Components/Spinner";
import { useIsMounted, useCollectionQuery } from "hooks";
import {
  CollectionType,
  IUser,
  FeatureFlags as TFeatureFlags,
  UserRole,
  IOrganisation,
  IProject,
  IDivision,
} from "@ehabitation/ts-utils/browser";
import { useNavigate } from "react-router-dom";

import { BiCaretRight } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { UserRoleChip } from "./GrantRoleForm";
import { BsFillQuestionCircleFill } from "react-icons/bs";

const tooltipClasses =
  "relative before:absolute before:border before:border-black before:shadow-lg before:font-normal before:bg-green-50 before:z-20 before:content-tip before:left-0 before:top-[125%] before:mb-2 before:rounded-md before:px-2 hover:before:flex before:hidden";

interface ChipProps {
  children: JSX.Element | string;
  onClick?: () => void;
  className?: string;
  label?: string;
  warning?: boolean;
  baseBGClass?: string;
}

export const Chip: React.FC<ChipProps> = ({
  children,
  onClick,
  className,
  label = "",
  warning,
  baseBGClass = "bg-indigo-200",
}: any) => {
  return (
    <div
      role={onClick && "button"}
      onClick={onClick}
      aria-label={label}
      className={`px-3 py-0 self-center border border-gray-400 rounded-md font-semibold select-none ${
        warning ? "bg-rose-200" : baseBGClass
      } ${onClick ? "cursor-pointer" : ""} ${className}`}
    >
      {children}
    </div>
  );
};

const availableFlags: TFeatureFlags[] = [
  "debug",
  "delete",
  "siteLevelExports",
  "historic",
];

const FeatureFlags: React.FC<{
  featureFlags?: TFeatureFlags[];
  setFlags: (flags: TFeatureFlags[]) => void;
  disabled?: boolean;
}> = ({ featureFlags, setFlags, disabled }) => {
  const [deletingFlag, setDeletingFlag] = useState<string | null>(null);

  const displayFlags = featureFlags?.filter((flag) =>
    availableFlags.includes(flag)
  );

  return displayFlags && displayFlags.length ? (
    <>
      {displayFlags.map((flag) => (
        <Fragment key={flag}>
          <Chip
            onClick={
              disabled
                ? undefined
                : () => setDeletingFlag(deletingFlag === flag ? "" : flag)
            }
            warning={deletingFlag === flag}
          >
            {flag}
          </Chip>
          {deletingFlag === flag ? (
            <>
              <Button
                type="button"
                className="rounded-full self-center"
                aria-label="Confirm Domain Delete"
                style={{ padding: "0.5rem", lineHeight: 1 }}
                onClick={() => {
                  const set = new Set(featureFlags);
                  set.delete(flag);
                  setFlags(Array.from(set));
                  setDeletingFlag(null);
                }}
              >
                <AiOutlineDelete className="icon" />
              </Button>
            </>
          ) : null}
        </Fragment>
      ))}
    </>
  ) : (
    <p className="text-gray-400">None</p>
  );
};

const EmailDomains: React.FC<{
  emailDomains?: string[];
  setDomains: (newDomains: string[]) => void;
  disabled?: boolean;
}> = ({ emailDomains, setDomains, disabled }) => {
  const [deletingDomain, setDeletingDomain] = useState<string | null>(null);

  return emailDomains && emailDomains.length ? (
    <>
      {emailDomains.map((emailDomain) => (
        <Fragment key={emailDomain}>
          <Chip
            baseBGClass="bg-yellow-200"
            onClick={
              disabled
                ? undefined
                : () =>
                    setDeletingDomain(
                      deletingDomain === emailDomain ? "" : emailDomain
                    )
            }
            warning={deletingDomain === emailDomain}
          >
            {emailDomain}
          </Chip>
          {deletingDomain === emailDomain ? (
            <>
              <Button
                type="button"
                className="rounded-full self-center"
                aria-label="Confirm Domain Delete"
                style={{ padding: "0.5rem", lineHeight: 1 }}
                onClick={() => {
                  const domainsSet = new Set(emailDomains);
                  domainsSet.delete(emailDomain);
                  setDomains(Array.from(domainsSet));
                  setDeletingDomain(null);
                }}
              >
                <AiOutlineDelete className="icon" />
              </Button>
            </>
          ) : null}
        </Fragment>
      ))}
    </>
  ) : (
    <p className="text-gray-400">None</p>
  );
};

const DomainInput: React.FC<{
  addDomain: (newDomain: string) => void;
  disabled?: boolean;
}> = ({ addDomain, disabled }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newEmailDomain, setNewEmailDomain] = useState<string>("");
  const isMounted = useIsMounted();

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmailDomain(e.target.value);
  };

  const handleSubmitDomain = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await addDomain(newEmailDomain);
    if (isMounted()) {
      setIsEditing(false);
      setNewEmailDomain("");
    }
  };

  if (!isEditing) {
    return (
      <Button
        disabled={disabled}
        type="button"
        className="rounded-full self-center text-indigo-100"
        aria-label="Add Email Domain"
        style={{ padding: "0.3rem", lineHeight: 1 }}
        onClick={() => setIsEditing(true)}
      >
        <FaPlus className="icon" />
      </Button>
    );
  }

  return (
    <form className="flex gap-2" onSubmit={handleSubmitDomain}>
      <input
        disabled={disabled}
        className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-2"
        placeholder="e.g. ehab.co"
        name="newEmail"
        pattern="^\S+\.\S+$"
        required
        type="text"
        title="Email domain, e.g ehab.co"
        value={newEmailDomain}
        onChange={handleChangeEmail}
      />
      <Button
        disabled={disabled}
        type="button"
        className="rounded-full self-center"
        aria-label="Cancel"
        onClick={() => {
          setIsEditing(false);
          setNewEmailDomain("");
        }}
        style={{ padding: "0.5rem", lineHeight: 1 }}
      >
        <AiOutlinePlus className="icon rotate-45" />
      </Button>
      <Button
        disabled={disabled}
        type="submit"
        aria-label="Add Email Domain"
        className="rounded-full self-center"
        style={{ padding: "0.5rem", lineHeight: 1 }}
      >
        <AiOutlineCheck className="icon" />
      </Button>
    </form>
  );
};

const updateDomains = (emailDomains: string[], orgId: string) => {
  const organisationsCollection = collection(db, "organisations");
  return updateDoc(doc(organisationsCollection, orgId), { emailDomains });
};

const OrgDomains: React.FC<{
  org: IOrganisationDocument;
  allEmailDomains: string[];
}> = ({ org, allEmailDomains }) => {
  const domainsMutation = useMutation((emailDomains: string[]) =>
    updateDomains(emailDomains, org.id!)
  );
  return (
    <div
      className={`flex items-center gap-4 ${
        domainsMutation.isLoading ? " animate-pulse" : ""
      }`}
    >
      <EmailDomains
        disabled={domainsMutation.isLoading}
        emailDomains={org?.emailDomains}
        setDomains={(emailDomains: string[]) =>
          domainsMutation.mutate(emailDomains)
        }
      />
      <DomainInput
        disabled={domainsMutation.isLoading}
        addDomain={(newDomain: string) => {
          const cleanNewDomain = newDomain.trim().toLowerCase();
          if (allEmailDomains.includes(cleanNewDomain)) {
            alert(
              `Domain ${cleanNewDomain} is already assigned to an organisation.`
            );
            return;
          }
          const domainsSet = new Set(org.emailDomains);
          domainsSet.add(cleanNewDomain);
          return domainsMutation.mutate(Array.from(domainsSet));
        }}
      />
    </div>
  );
};

const FlagSelect: React.FC<{
  addFlag: (newFlag: TFeatureFlags) => void;
  disabled?: boolean;
}> = ({ addFlag, disabled }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newFlag, setNewFlag] = useState<TFeatureFlags | "">("");
  const isMounted = useIsMounted();

  const handleChangeFlag = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewFlag(e.target.value as TFeatureFlags);
  };

  const handleSubmitFlag = (event: React.SyntheticEvent) => {
    event.preventDefault();
    newFlag && addFlag(newFlag);
    if (isMounted()) {
      setIsEditing(false);
      setNewFlag("");
    }
  };

  if (!isEditing) {
    return (
      <Button
        disabled={disabled}
        type="button"
        className="rounded-full self-center text-indigo-100"
        aria-label="Add Feature Flag"
        style={{ padding: "0.3rem", lineHeight: 1 }}
        onClick={() => setIsEditing(true)}
      >
        <FaPlus className="icon" />
      </Button>
    );
  }

  return (
    <form className="flex gap-2 flex-shrink-0" onSubmit={handleSubmitFlag}>
      <select
        disabled={disabled}
        className="block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-2"
        required
        title="Feature flag"
        value={newFlag}
        onChange={handleChangeFlag}
      >
        <option value="">--Feature--</option>
        <option value="debug">Debug Menu</option>
        <option value="siteLevelExports">Acumen Cal Export</option>
        <option value="historic">Historic</option>
      </select>
      <Button
        disabled={disabled}
        type="button"
        className="rounded-full self-center"
        aria-label="Cancel"
        onClick={() => {
          setIsEditing(false);
          setNewFlag("");
        }}
        style={{ padding: "0.5rem", lineHeight: 1 }}
      >
        <AiOutlinePlus className="icon rotate-45" />
      </Button>
      <Button
        disabled={disabled || !newFlag}
        type="submit"
        aria-label="Add Email Domain"
        className="rounded-full self-center"
        style={{ padding: "0.5rem", lineHeight: 1 }}
      >
        <AiOutlineCheck className="icon" />
      </Button>
    </form>
  );
};

const updateUserFlags = (featureFlags: TFeatureFlags[], userId: string) => {
  const usersCollection = collection(db, CollectionType.Users);
  return updateDoc(doc(usersCollection, userId), { featureFlags });
};

const UserFlags: React.FC<{
  user: IUser;
}> = ({ user }) => {
  const userFlagsMutation = useMutation((featureFlags: TFeatureFlags[]) =>
    updateUserFlags(featureFlags, user.id!)
  );

  const flags = user.featureFlags;
  return (
    <div
      className={`flex items-center flex-wrap gap-4 ${
        userFlagsMutation.isLoading ? " animate-pulse" : ""
      }`}
    >
      <FeatureFlags
        disabled={userFlagsMutation.isLoading}
        featureFlags={flags}
        setFlags={(flags: TFeatureFlags[]) => userFlagsMutation.mutate(flags)}
      />
      <FlagSelect
        disabled={userFlagsMutation.isLoading}
        addFlag={(newFlag: TFeatureFlags) => {
          const flagsSet = new Set(flags);
          flagsSet.add(newFlag);
          return userFlagsMutation.mutate(Array.from(flagsSet));
        }}
      />
    </div>
  );
};

const UsersTable: FC<{
  org?: IOrganisationDocument;
  users: IUser[];
}> = ({ users, org }) => {
  const {
    isLoading: isDivisionsLoading,
    data: divisions,
    error: divisionsError,
  } = useCollectionQuery<IDivision>("divisions");
  const {
    isLoading: isProjectsLoading,
    data: projects,
    error: projectsError,
  } = useCollectionQuery<IProject>("projects");

  const navigate = useNavigate();

  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg max-h-[300px] overflow-y-scroll relative">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="z-20">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 bg-gray-50 sticky top-0 z-20"
            >
              Email
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 bg-gray-50 sticky top-0 z-20"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-2xl font-semibold text-gray-900 bg-gray-50 sticky top-0 z-20"
            >
              Role
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-2xl font-semibold text-gray-900 bg-gray-50 sticky top-0 z-20"
            >
              <div
                className={`flex gap-2 items-center ${tooltipClasses}`}
                data-tip="debug: debug menu | Acumen Cal: in site dash exports"
              >
                <div>Feature Flags</div>
                <BsFillQuestionCircleFill className="text-3xl text-gray-500 bg-white rounded-full" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-medium">
                  {user.email}
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-medium">
                  {`${user.firstName} ${user.lastName}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-2xl">
                  {isDivisionsLoading || isProjectsLoading ? (
                    <Spinner />
                  ) : (
                    <UserRoleChip
                      onEdit={() => {
                        navigate("/super/roles", {
                          state: { email: user.email },
                        });
                      }}
                      claims={{
                        role: user.role,
                        organisation: user.orgId,
                        division: user.divisionId,
                        project: user.project,
                      }}
                      organisations={org ? [org as IOrganisation] : []}
                      projects={(projects as IProject[]) || []}
                      divisions={(divisions as IDivision[]) || []}
                    />
                  )}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-2xl">
                  <div className="flex gap-4">
                    <UserFlags user={user} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const OrgTable: FC<{
  organisations: IOrganisationDocument[];
  users: IUser[];
  renderDomains: (org: IOrganisationDocument) => JSX.Element;
}> = ({ organisations, users, renderDomains }) => {
  const [activeOrgId, setActiveOrgId] = useState<string | null>(null);
  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg relative">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-center text-2xl font-semibold text-gray-900 "
            >
              Users
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-2xl font-semibold text-gray-900 relative"
            >
              <div
                className={`flex gap-2 items-center ${tooltipClasses}`}
                data-tip="Any new users signing up with email addresses under these domains will automatically be added to the org as a guest user."
              >
                <div>Email Domains</div>
                <BsFillQuestionCircleFill className="text-3xl text-gray-500 bg-white rounded-full" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {organisations.map((organisation) => {
            const orgUsers = users
              .filter((user) => {
                return user.orgId === organisation.id;
              })
              .sort((a, b) => {
                if (!a.email || !b.email) return 0;
                return a.email
                  .toLowerCase()
                  .localeCompare(b.email.toLowerCase());
              });
            const isActiveOrg = activeOrgId === organisation.id;
            return (
              <Fragment key={organisation.id}>
                <tr>
                  <td
                    className={`whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-medium cursor-pointer text-gray-900 sm:pl-6 ${
                      isActiveOrg ? "bg-green-100" : ""
                    }`}
                    onClick={() =>
                      setActiveOrgId(
                        isActiveOrg ? null : organisation?.id || null
                      )
                    }
                  >
                    <div className="flex gap-4 items-center">
                      <BiCaretRight
                        className={`transition-transform ${
                          isActiveOrg ? "rotate-90" : "rotate-0"
                        }`}
                      />
                      <span className="text-3xl">{organisation.name}</span>
                    </div>
                  </td>
                  <td
                    className={`whitespace-nowrap px-3 py-4 text-2xl cursor-pointer ${
                      isActiveOrg ? "bg-green-100" : ""
                    }`}
                    onClick={() =>
                      setActiveOrgId(
                        isActiveOrg ? null : organisation?.id || null
                      )
                    }
                  >
                    <div
                      className={`text-center text-3xl ${
                        isActiveOrg ? "font-bold" : ""
                      }`}
                    >
                      {orgUsers.length}
                    </div>
                  </td>
                  <td
                    className={`whitespace-nowrap px-3 py-4 text-2xl ${
                      isActiveOrg ? "bg-green-100" : ""
                    }`}
                  >
                    {renderDomains(organisation)}
                  </td>
                </tr>
                {activeOrgId && activeOrgId === organisation.id ? (
                  <tr>
                    <td colSpan={3}>
                      <div className="p-4 overflow-hidden bg-green-100 -mt-[1px]">
                        <UsersTable users={orgUsers} org={organisation} />
                      </div>
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const Organisations: React.FC = () => {
  const {
    isError: isOrgsError,
    data: organisations,
    error: orgsError,
  } = useCollectionQuery<IOrganisationDocument>("organisations");

  const {
    isError: isUsersError,
    data: users,
    error: usersError,
  } = useCollectionQuery<IUser>("users");

  const navigate = useNavigate();

  const allEmailDomains = useMemo(() => {
    const allEmailDomains: string[] = [];
    organisations?.forEach(
      ({ emailDomains }) =>
        emailDomains && allEmailDomains.push(...emailDomains)
    );
    return allEmailDomains;
  }, [organisations]);

  return (
    <div className="flex flex-col gap-6 min-w-[70vw] pb-12">
      <div className="flex justify-between">
        <h1>Organisation Users</h1>
        <div className="flex gap-4">
          <Button onClick={() => navigate("/super/roles")}>
            View/Change User Role
          </Button>
          <Button onClick={() => navigate("/super/new_org")}>
            Create New Org
          </Button>
        </div>
      </div>
      {isOrgsError && (
        <p>
          Error loading organsations: <code>{orgsError}</code>
        </p>
      )}
      {isUsersError && (
        <p>
          Error loading users: <code>{usersError}</code>
        </p>
      )}
      {users && organisations ? (
        <OrgTable
          organisations={organisations!}
          users={users!}
          renderDomains={(org) => (
            <OrgDomains org={org} allEmailDomains={allEmailDomains} />
          )}
        />
      ) : (
        <Spinner />
      )}
      <div className="flex justify-between">
        <h1 className="mt-6">Super Users</h1>
      </div>
      {users ? (
        <UsersTable
          users={(users || []).filter(
            (user) => user.role === UserRole.superAdmin
          )}
        />
      ) : (
        <Spinner />
      )}
      <div className="flex justify-between">
        <h1 className="mt-6">Other Users (no org, not super!)</h1>
      </div>
      {users ? (
        <UsersTable
          users={(users || []).filter(
            (user) => user.role !== UserRole.superAdmin && !user.orgId
          )}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
