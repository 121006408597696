import React, { FC } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { IProjectWithSites } from "types";
import { StyledTableRow } from "./styles";
import { StyledAdminIconButton } from "elements";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useHandleContextMenuActions } from "./useHandleContextMenuActions";
import { useAppSelector } from "store";
import { selectNoOfUsersByProject } from "store/projectManagement";
import { useFeatureFlag } from "hooks";
import { UserRole } from "@ehabitation/ts-utils/browser";

interface IProps {
  project: IProjectWithSites;
}

const ProjectRow: FC<IProps> = ({
  project: { id, name, projectCode, sites, visibility },
}) => {
  const {
    anchorEl,
    handleCloseContextMenu,
    handleDeleteProject,
    handleOpenContextMenu,
    handleEditProject,
  } = useHandleContextMenuActions(id!);

  const noOfUsers = useAppSelector(selectNoOfUsersByProject);

  const hasDeleteFeatureFlag = useFeatureFlag("delete");

  return (
    <StyledTableRow data-testid="project-row">
      <div className="name" data-testid="project-row-name">
        <p>{`${projectCode ? `[${projectCode}] ` : ""} ${name}`}</p>
      </div>

      <div className="sites">
        {sites!.length > 0 ? (
          sites!.map((site) => <p key={site.id}>{site.name}</p>)
        ) : (
          <p>
            <em>No sites to display</em>
          </p>
        )}
      </div>

      <div className="users">{noOfUsers[id!] ?? 0}</div>

      <div className="visibility" data-testid="project-row-visibility">
        {visibility ?? UserRole.planner}
      </div>

      <div className="icon">
        <StyledAdminIconButton
          onClick={handleOpenContextMenu}
          data-testid="context-menu-button"
        >
          <BiDotsVerticalRounded />
        </StyledAdminIconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseContextMenu}
        >
          <MenuItem
            onClick={handleEditProject}
            data-testid="project-row-edit-button"
          >
            Edit
          </MenuItem>
          {hasDeleteFeatureFlag ? (
            <MenuItem
              onClick={handleDeleteProject}
              data-testid="project-row-delete-button"
            >
              Delete
            </MenuItem>
          ) : null}
        </Menu>
      </div>
    </StyledTableRow>
  );
};

export default ProjectRow;
