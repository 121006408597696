import { FC } from "react";
import { Tooltip } from "@material-ui/core";
import { SimulationLevel, SimulationTaskResult } from "helpers";
import { ITask } from "@ehabitation/ts-utils/browser";
import { weatherKeyIcons } from "../constants";
import { BsDot } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import { FiAlertTriangle } from "react-icons/fi";
import WeatherImpactsChart from "Components/Risk/WeatherImpactsChart";

const tooltipClasses = `
  relative before:absolute before:shadow-lg before:font-normal before:bg-gray-300
  before:z-50 before:content-tip before:left-0 before:top-[125%] before:border
  before:border-gray-600 before:mb-2 before:bg-white before:rounded-md before:px-2
  hover:before:flex before:hidden
`;

interface RiskDriversRowProps {
  task: ITask & { simulation: SimulationTaskResult };
  selectedSimLevel: SimulationLevel;
  maxWeatherDays: number;
  hierarchy: {
    name: string;
    position: number;
  }[];
  correlation?: number;
  handleOpenCategorise?: (taskId: string) => void;
}

const calculateCorrelation = (correlation: number) => {
  return (correlation * 100).toFixed(0);
};

const calculateWeatherDaysPercentage = (
  weatherDays: number,
  maxWeatherDays: number
) => {
  return ((weatherDays * 100) / maxWeatherDays).toFixed(0);
};

export const RiskDriversRow: FC<RiskDriversRowProps> = ({
  task,
  handleOpenCategorise,
  selectedSimLevel,
  maxWeatherDays,
  hierarchy,
  correlation,
}) => {
  const joinedHierarchy = hierarchy.map((item) => item.name).join(" > ");
  return (
    <>
      <tr
        data-task-id={task.id}
        data-testid="risk-drivers-row"
        className="hover:bg-blue-50 h-[140px] align-top"
      >
        <td>
          {task.isCriticalPath && (
            <div className="text-[2.5rem] text-[#FF6363]">
              <BsDot />
            </div>
          )}
        </td>
        <td className="px-4">
          <div className="font-medium">{task.title}</div>
          <div className="flex justify-between text-gray-400 pt-2">
            <div className="text-xl">{task.taskCode && task.taskCode}</div>
            <div className="text-xl flex items-center">
              {task.taskType !== task.simulation.taskType && (
                <p
                  className={`text-gray-500 flex-shrink-0 ${tooltipClasses} whitespace-pre pr-2`}
                  aria-label="Mitigation issue"
                  data-tip={
                    task.simulation.taskType !== task.taskType
                      ? "Task category no longer matches simulation, resimulate plan"
                      : ""
                  }
                >
                  <FiAlertTriangle className="text-red-500" />
                </p>
              )}
              {task.taskType}
              {handleOpenCategorise && (
                <p
                  className="pl-2 cursor-pointer"
                  onClick={() => handleOpenCategorise(task.id)}
                >
                  <BiPencil />
                </p>
              )}
            </div>
          </div>
          {hierarchy.length > 3 ? (
            <Tooltip
              arrow
              title={<span className="text-2xl">{joinedHierarchy}</span>}
            >
              <div className="text-xl text-gray-400 pt-2">
                {`${hierarchy[0].name} > ... > ${
                  hierarchy[hierarchy.length - 1].name
                }`}
              </div>
            </Tooltip>
          ) : (
            <div className="text-xl text-gray-400 pt-2">{joinedHierarchy}</div>
          )}
        </td>
        <td className="text-center pt-1">
          <Tooltip
            arrow
            enterDelay={500}
            leaveDelay={250}
            enterNextDelay={500}
            title={
              <WeatherImpactsChart
                className="w-full h-5/6 bg-white rounded"
                maxDelayDays={task.simulation.maxDelayDays}
                violationData={task.simulation.violationData}
                settings={{ fontSize: 12, maxWidth: 80 }}
              />
            }
          >
            <span>
              {task.simulation.likelyCause ? (
                <div
                  className="w-8 h-8 bg-contain"
                  style={{
                    backgroundImage: `url(${
                      weatherKeyIcons[task.simulation.likelyCause]
                    })`,
                  }}
                ></div>
              ) : (
                "-"
              )}
            </span>
          </Tooltip>
        </td>
        {task.simulation.riskIntervalResults?.[selectedSimLevel]
          ?.weatherDays ? (
          <td className="px-4 pt-4">
            <>
              <div className="h-3 w-full">
                <div
                  style={{
                    width:
                      calculateWeatherDaysPercentage(
                        task.simulation.riskIntervalResults?.[selectedSimLevel]
                          ?.weatherDays,
                        maxWeatherDays
                      ) + "%",
                  }}
                  className={`h-3 bg-[#2C71D9]`}
                ></div>
              </div>
              <div className="text-xl pt-5 text-gray-400">{`${task.simulation.riskIntervalResults?.[selectedSimLevel]?.weatherDays} days`}</div>
            </>
          </td>
        ) : (
          <td className="px-4 pt-1">-</td>
        )}
        {correlation ? (
          <td className="px-4 pt-4">
            <>
              <div className="h-3 w-full">
                <div
                  style={{
                    width: calculateCorrelation(correlation) + "%",
                  }}
                  className={`h-3 bg-[#C45BD6]`}
                ></div>
              </div>
              <div className="text-xl pt-4 text-gray-400">
                {`${calculateCorrelation(correlation)}% correlation`}
              </div>
            </>
          </td>
        ) : (
          <td className="px-4 pt-1">-</td>
        )}
      </tr>
    </>
  );
};
