import { FC } from "react";

interface IProps {
  id: string;
  shift?: string;
  disabled: boolean;
  handleChange: Function;
  className: string;
}

const ShiftInput: FC<IProps> = ({
  id,
  shift,
  disabled,
  handleChange,
  className,
}) => {
  return (
    <div
      className={`w-24 min-w-[8rem] max-w-[8rem] ${className}`}
      data-testid={`category-shift`}
    >
      <select
        disabled={disabled}
        className={`${className} block w-full rounded-md border-gray-300 border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-1 px-2`}
        value={shift || "24hr"}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option value="day">Day</option>
        <option value="night">Night</option>
        <option value="24hr">24Hr</option>
      </select>
    </div>
  );
};

export default ShiftInput;
