import { useEffect, useState } from "react";
import { StyledAdminButtonContainer, StyledAdminContent } from "elements";
import { StyledTableHeader } from "./styles";
import UserRow from "./UserRow";
import { LoadingWrapper, MonochromeButton } from "@ehabitation/ui";
import { RootState, useAppDispatch, useAppSelector } from "store";
import {
  selectUserManagementLoading,
  selectSortedOrganisationUsers,
  setSelectedTab,
  setSelectedUser,
  selectDivisionMap,
} from "store/userManagement";
import { UserManagementTabOption } from "types";
import { IUser } from "@ehabitation/ts-utils/browser";
import { selectOrganisationProjects } from "store/siteManagement";

const Users = () => {
  const dispatch = useAppDispatch();

  const organisationUsers = useAppSelector(selectSortedOrganisationUsers);
  const loading = !!useAppSelector(selectUserManagementLoading);
  const { searchInput } = useAppSelector((state: RootState) => state.search);
  const divisionMap = useAppSelector(selectDivisionMap);
  const organisationProjects = useAppSelector(selectOrganisationProjects);
  const [searchedUsers, setSearchedUsers] = useState<IUser[] | null>(null);

  const handleOpenNewUserTab = () => {
    dispatch(setSelectedTab(UserManagementTabOption.Details));
  };

  /* Automatically clear the selected user when entering or returning 
  to this tab so that the tab is always correctly disabled */
  useEffect(() => {
    dispatch(setSelectedUser(null));
  }, [dispatch]);

  useEffect(() => {
    const filterUsers = () => {
      return organisationUsers.filter(
        (organisationUser) =>
          organisationUser.firstName?.toLowerCase().includes(searchInput) ||
          organisationUser.lastName?.toLowerCase().includes(searchInput) ||
          organisationUser.role?.toLowerCase().includes(searchInput) ||
          organisationUser.email?.toLowerCase().includes(searchInput) ||
          divisionMap[organisationUser.divisionId]?.name
            ?.toLowerCase()
            .includes(searchInput) ||
          organisationProjects[organisationUser.project]?.name
            ?.toLowerCase()
            .includes(searchInput)
      );
    };
    setSearchedUsers(searchInput === "" ? organisationUsers : filterUsers());
  }, [searchInput, organisationUsers, divisionMap, organisationProjects]);

  return (
    <StyledAdminContent>
      <LoadingWrapper loading={loading}>
        {/* SCROLLS */}
        <div className="content-container">
          <StyledAdminButtonContainer>
            <MonochromeButton
              level="primary"
              width="lg"
              onClick={handleOpenNewUserTab}
              data-testid="user-add"
            >
              Add A New User
            </MonochromeButton>
          </StyledAdminButtonContainer>
          <StyledTableHeader data-testid="users-header">
            <p className="short">Name</p>
            <p className="long">Access Level</p>
            <p className="short">Division</p>
            <p className="long">Project</p>
            <p className="long">Email</p>

            <p className="icon"></p>
          </StyledTableHeader>

          {searchedUsers?.map((user) => (
            <UserRow user={user} key={user.id} />
          ))}
        </div>
      </LoadingWrapper>
    </StyledAdminContent>
  );
};

export default Users;
