import { FC } from "react";
import {
  IDailyForecast,
  IForecastReading,
  warningPrecipitation,
  warningTemperature,
  warningWind,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";
import { convertUnits } from "../../../apps/web/src/utils/unitConversions"; // Adjust path as necessary

const threeDigitsMax = (value: number, decimal = false): string => {
  if (value >= 1000) {
    if (value >= 10000) {
      return Math.round(value / 1000) + "k";
    }
    return (value / 1000).toFixed(1) + "k";
  }
  if (decimal && value < 10) {
    return value.toFixed(1);
  }
  return String(Math.round(value));
};

const Value = ({
  data: { unit, value },
  className = "",
  decimal = false,
}: {
  data: IForecastReading;
  className?: string;
  decimal?: boolean;
}) => (
  <div className={`flex gap-1 ${className}`}>
    <div className="text-gray-900">
      {typeof value === "number" ? threeDigitsMax(value, decimal) : "-"}
    </div>
    <div className="text-[0.7em] align-super w-8 invisible relative text-gray-900">
      &nbsp;
      <span className="visible absolute left-0 top-0">{unit}</span>
    </div>
  </div>
);

const Precipitation: FC<{
  data: IDailyForecast;
  thresholds?: WeatherThresholds;
  unit: "metric" | "imperial";
}> = ({ data, thresholds, unit }) => {
  const dailyRain = data.dailyRainAcc.value !== null
    ? convertUnits(data.dailyRainAcc.value, "metric", unit, "precipitation")
    : null;
  const hourlyRain = data.hourlyRainAcc.value !== null
    ? convertUnits(data.hourlyRainAcc.value, "metric", unit, "precipitation")
    : null;

  return (
    <Container
      title="Rain"
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Day</span>
          <Value
            data={{ value: dailyRain, unit: unit === "metric" ? "mm" : "in" }}
            decimal
          />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            data={{ value: hourlyRain, unit: unit === "metric" ? "mm" : "in" }}
            decimal
          />
        </>
      }
    />
  );
};

const Wind: FC<{ data: IDailyForecast; thresholds?: WeatherThresholds; unit: "metric" | "imperial" }> = ({
  data,
  thresholds,
  unit,
}) => {
  const minWindSpeed = data.minWindSpeed.value !== null
    ? convertUnits(data.minWindSpeed.value, "metric", unit, "speed")
    : null;
  const maxWindSpeed = data.maxWindSpeed.value !== null
    ? convertUnits(data.maxWindSpeed.value, "metric", unit, "speed")
    : null;

  return (
    <Container
      title="Wind"
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Min</span>
          <Value
            data={{ value: minWindSpeed, unit: unit === "metric" ? "m/s" : "mph" }}
          />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            data={{ value: maxWindSpeed, unit: unit === "metric" ? "m/s" : "mph" }}
          />
        </>
      }
    />
  );
};

const Temp: FC<{ data: IDailyForecast; thresholds?: WeatherThresholds; unit: "metric" | "imperial" }> = ({
  data,
  thresholds,
  unit,
}) => {
  const minTemp = data.minTemp.value !== null
    ? convertUnits(data.minTemp.value, "metric", unit, "temperature")
    : null;
  const maxTemp = data.maxTemp.value !== null
    ? convertUnits(data.maxTemp.value, "metric", unit, "temperature")
    : null;

  return (
    <Container
      title="Temp"
      upper={
        <>
          <span className="text-base align-baseline text-gray-600">Min</span>
          <Value
            data={{ value: minTemp, unit: unit === "metric" ? "°C" : "°F" }}
          />
        </>
      }
      lower={
        <>
          <span className="text-base align-baseline text-gray-600">Max</span>
          <Value
            data={{ value: maxTemp, unit: unit === "metric" ? "°C" : "°F" }}
          />
        </>
      }
    />
  );
};

const Container: FC<{
  upper?: React.ReactNode;
  lower?: React.ReactNode;
  title: string;
  thresholdBroken?: boolean;
}> = ({ upper, lower, title, thresholdBroken }) => (
  <div className="flex flex-col items-stretch">
    <h3
      className={`tracking-widest text-xl capitalize font-[500] border-b-2 border-solid ${
        thresholdBroken ? "border-rose-200" : "border-[#1CEAA0]"
      }`}
    >
      {title}
    </h3>
    {upper && <div className="flex items-center gap-2">{upper}</div>}
    {lower && <div className="flex items-center gap-2">{lower}</div>}
  </div>
);

export const Weather = Object.assign(() => <div>WEATHER!</div>, {
  Precipitation,
  Wind,
  Temp,
  Container,
});
