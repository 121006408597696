import { useEffect, useState, useMemo } from "react";
import { useSubscribeToSites } from "./useSubscribeToSites";
import { useSubscribeToProjects } from "./useSubscribeToProjects";
import { useSubscribeToDivisions } from "./useSubscribeToDivisions";
import { useSubscribeToUsers } from "./useSubscribeToUsers";

/*
    Waits for the subscription hooks to do their initial fetch,
    (or waits a minimum of 2 seconds) before communicating to the Admin
    page to reveal the UI
*/
export const useSetupSubscriptions = () => {
  const divisionsReady = useSubscribeToDivisions();
  const projectsReady = useSubscribeToProjects();
  const sitesReady = useSubscribeToSites();
  const usersReady = useSubscribeToUsers();

  const [minimumTimeHasPassed, setMinimumTimeHasPassed] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMinimumTimeHasPassed(true);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  /* 
    The use of useMemo here can be considered as similar to reselect's computed selectors
    i.e. it is memoised derived state (returning a boolean derivived from a combination of other booleans)
  */
  const subscriptionsReady = useMemo(() => {
    if (
      !divisionsReady ||
      !usersReady ||
      !sitesReady ||
      !projectsReady ||
      !minimumTimeHasPassed
    )
      return false;
    return true;
  }, [
    divisionsReady,
    usersReady,
    sitesReady,
    projectsReady,
    minimumTimeHasPassed,
  ]);

  return subscriptionsReady;
};
