import React, { FC, memo } from "react";
import { StyledCapitalisedTooltip } from "elements";

interface IProps {
  title: string;
}

const titleClasses: { [key: string]: string } = {
  name: "cell-name title",
  start: "cell-date title",
  task_code: "cell-value title",
  end: "cell-date title",
  confidence: "cell-value title confidence",
};

const CellTitle: FC<IProps> = ({ title }) => {
  return (
    <StyledCapitalisedTooltip title="" arrow placement="right" enterDelay={500}>
      <div className={titleClasses[title]} data-testid={`${title}-button`}>
        {title.replace(/_/g, " ")}
      </div>
    </StyledCapitalisedTooltip>
  );
};

export default memo(CellTitle);
