import { useEffect, useMemo, useState } from "react";
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import {
  CollectionType,
  IProject,
  ISite,
  transformPlanDoc,
} from "@ehabitation/ts-utils/browser";
import { useIsMounted } from "./useIsMounted";
import { ProjectMode, isMultiSite } from "types";

export const usePlans = (site?: ISite, project?: IProject) => {
  const isMounted = useIsMounted();
  const [rawPlans, setRawPlans] =
    useState<QueryDocumentSnapshot<DocumentData, DocumentData>[]>();

  const isPersonalSite = site ? !site.project : undefined;

  useEffect(() => {
    if (typeof isPersonalSite === "boolean" && site?.id) {
      if (isPersonalSite) {
        setRawPlans([]);
      } else {
        setRawPlans(undefined);
        const unsubscribe = onSnapshot(
          query(
            collection(db, CollectionType.Plans),
            where("project", "==", site.project)
          ),
          (snapshot) => {
            if (isMultiSite(project)) {
              isMounted() &&
                setRawPlans(
                  snapshot.docs.filter(
                    (qs) =>
                      qs.data()?.projectMode ==
                        ProjectMode.MultiSiteWithActivityCodes &&
                      qs.data()?.project == project?.id
                  )
                );
            } else {
              isMounted() &&
                setRawPlans(
                  snapshot.docs.filter((qs) => qs.data()?.site == site?.id)
                );
            }
          },
          (error) => {
            console.error(error);
          }
        );
        return () => unsubscribe();
      }
    }
  }, [site?.id, isPersonalSite]);

  const plans = useMemo(() => {
    return (
      rawPlans &&
      rawPlans
        .map((planDoc) => transformPlanDoc(planDoc.id, planDoc.data()))
        .filter(({ status }) => status !== "discarded")
    );
  }, [rawPlans]);

  return plans;
};
