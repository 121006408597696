import { useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { RootState } from "store";
import { db } from "firebaseConfig";
import {
  selectAvailablePlans,
  selectCurrentSite,
  selectProject,
  setAvailablePlans,
} from "store/project";
import { getSitesPlansWithoutMitigations } from "helpers/firebase/firestoreUtils";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { isMultiSite } from "types";

export const useSubscribeToAvailablePlans = () => {
  const dispatch = useAppDispatch();
  const { siteId } = useAppSelector((state: RootState) => state.auth.userState);
  const availablePlans = useAppSelector(selectAvailablePlans);
  const currentSite = useAppSelector(selectCurrentSite);
  const project = useAppSelector(selectProject);

  const subscriptionRef = useRef<() => void>();
  useEffect(() => {
    if (subscriptionRef.current) {
      subscriptionRef.current = undefined;
    }

    subscriptionRef.current = onSnapshot(
      query(
        collection(db, "plans"),
        isMultiSite(project)
          ? where("project", "==", currentSite?.project)
          : where("site", "==", siteId)
      ),
      (snapshot) => {
        const planList = getSitesPlansWithoutMitigations(
          siteId!,
          snapshot.docs
        );
        dispatch(setAvailablePlans(planList));
      }
    );

    return () => {
      if (subscriptionRef.current) subscriptionRef.current();
    };
  }, [siteId, dispatch]);

  return { currentSite, availablePlans };
};
