import styled from "styled-components";
import {
  colors,
  flex,
  font,
  fontSize,
  headerHeight,
  planBottomRowHeight,
  planControlsHeight,
  spacing,
  grays,
  highlightHover,
} from "styles";

interface IStyledChartsToggleButton {
  selected: boolean;
}

const bottomRowPadding = spacing.l;

export const StyledPlanPage = styled.main`
  .chart-container {
    ${flex()};
    height: calc(
      100vh - ${headerHeight} - ${planControlsHeight} - ${planBottomRowHeight} -
        ${bottomRowPadding}
    );
    overflow-y: none;
    overflow-x: none;
    position: relative;
  }

  .bottom-row {
    ${flex("row", "flex-start", "flex-start")}
    height: ${planBottomRowHeight};
    padding-top: ${bottomRowPadding};
    width: 100%;
  }
`;

export const StyledChartsToggleButton = styled.button<IStyledChartsToggleButton>`
  ${flex("row", "center", "center")};
  ${font("title")};
  ${highlightHover()};
  cursor: pointer;
  font-size: ${fontSize.s};
  font-weight: 400;
  padding: ${spacing.m} ${spacing.l};
  border-style: 1px solid;
  background-color: ${(props) =>
    props.selected ? colors.ganttGreen : colors.ganttGray};
  color: ${grays.dark};
  border-radius: 30px;
  border: none;
  margin-top: ${spacing.s};
  width: 7rem;
`;

export const StyledChartsToggleButtonContainer = styled.div`
  ${flex("column", "center", "center")};
`;
