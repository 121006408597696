import styled from "styled-components";
import { flex, spacing } from "styles";

export const StyledRisk = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  padding-right: 2rem;

  display: grid;
  grid-template-areas:
    "histogram weather"
    "histogram breakdown";
  grid-gap: ${spacing.m};
  overflow-y: none;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  > .distribution-histogram {
    ${flex("column", "flex-start", "flex-start")};
    grid-area: histogram;
  }

  > .weather-impacts {
    ${flex("column", "flex-start", "flex-start")};
    grid-area: weather;
    overflow-y: auto;
  }

  > .simulation-breakdown {
    ${flex("column", "flex-start", "flex-start")};
    grid-area: breakdown;
    overflow-y: auto;
  }
`;

export const StyledChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledNoGraphMessage = styled.div`
  ${flex("column", "center", "center")};
  width: 100%;
  height: 100%;
  padding: ${spacing.m};
`;
