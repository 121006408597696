//Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//State
export interface ITabsState {
  selectedTab: TabOption;
}

export type TabOption =
  | "Gantt"
  | "Thresholds"
  | "Impact"
  | "Risk"
  | "Tornado"
  | "Info";

export const initialState: ITabsState = {
  selectedTab: "Gantt",
};

//Slice
export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    clickTab: (state, action: PayloadAction<TabOption>) => {
      state.selectedTab = action.payload;
    },
  },
});

//Actions
export const { clickTab } = tabsSlice.actions;

//Reducer
export default tabsSlice.reducer;
