import moment from "moment";

export const generatePopupMarkup = ({
  title,
  start,
  end,
  safetyEnd,
  accuracy,
  baseAccuracy,
  risks,
  isModelBrokenDep,
  isTaskBrokenDep,
  modelOffsetDays,
  taskOffsetDays,
}) => {
  // Omit dependency data until review
  isTaskBrokenDep = false;
  isModelBrokenDep = false;

  const taskLengthInDays = Math.ceil(
    Math.abs(moment.duration(moment(start).diff(moment(safetyEnd))).asDays())
  ); //My assumption is that we should count any day that the task covers as 1 day so always round up (ceil)

  const extraDays = convertRiskObjectToExtraDaysHTML(risks);
  const modelProposedStartDates = isModelBrokenDep
    ? convertModelOffsetToHTML(start, modelOffsetDays)
    : "";
  const taskProposedDateString = isTaskBrokenDep
    ? convertTaskOffsetToHTML(start, end, taskOffsetDays)
    : "";

  const isTaskOffsetStart = isTaskBrokenDep && taskOffsetDays.type === "start";
  const isTaskOffsetEnd = isTaskBrokenDep && taskOffsetDays.type === "finish";

  const dependencyWarnings = convertBrokenDepsToHTML(
    isTaskBrokenDep,
    isModelBrokenDep
  );

  const popupTitle = `<p>${title}</p>`;

  const popupBody = `
    ${dependencyWarnings}
    ${
      (isModelBrokenDep &&
        !isTaskBrokenDep &&
        `<div class="extra-days">
    <p><strong>Start Dates:</strong><p/>
    <p>${baseAccuracy}% : ${moment(start).format("DD/MM/YYYY")}</p>
    ${modelProposedStartDates}
    </div>`) ||
      (isTaskOffsetStart && taskProposedDateString) ||
      `<p><strong>Start Date</strong>: ${moment(start).format(
        "DD/MM/YYYY"
      )}</p>`
    }
    ${
      (isTaskOffsetEnd && taskProposedDateString) ||
      `<p><strong>End Date</strong>: ${moment(safetyEnd).format(
        "DD/MM/YYYY"
      )}</p>`
    }
    <p><strong>Initial End Date</strong>: ${moment(end).format(
      "DD/MM/YYYY"
    )}</p>
    <p><strong>Task Length</strong>: ${taskLengthInDays} days</p>
    <p><strong>Confidence</strong>: ${accuracy}</p>
    <p><strong>Initial Confidence</strong>: ${baseAccuracy}</p>
    <div class="extra-days">
    ${extraDays.length > 0 ? "<p><strong>Extra Days</strong></p>" : ""}
    ${extraDays}
    </div>
  `;

  return {
    popupTitle,
    popupBody,
  };
};

export const convertBrokenDepsToHTML = (taskFlag, modelFlag) => {
  return `<p />
  ${
    (modelFlag &&
      '<p style="line-height:50%;"><font size="+1">\u26A0\uFE0E</font><strong> Parent task weather may impact start date</strong></p>') ||
    ""
  }
  ${
    (taskFlag &&
      '<p><font size="+1">\u26A0\uFE0E</font><strong> Parent task timing may impact task dates</strong></p>') ||
    ""
  }
  `;
};

export const convertTaskOffsetToHTML = (start, end, taskOffsetDays) => {
  const mStart = moment(start);
  const mEnd = moment(end);

  const offsetDate =
    taskOffsetDays.type === "start"
      ? mStart.add(taskOffsetDays.offset, "days").format("DD/MM/YYYY")
      : mEnd.add(taskOffsetDays.offset, "days").format("DD/MM/YYYY");

  const offsetHTML =
    taskOffsetDays.type === "start"
      ? `<p><strong>Start Date</strong>: ${moment(start).format(
          "DD/MM/YYYY"
        )} -> ${offsetDate}</p>`
      : `<p><strong>End Date</strong>: ${moment(end).format(
          "DD/MM/YYYY"
        )} -> ${offsetDate}</p>`;

  return offsetHTML;
};

export const convertModelOffsetToHTML = (start, startOffsetDays) => {
  const mStart = moment(start);
  let html = ``;

  for (const [confidence, dayOffset] of Object.entries(startOffsetDays)) {
    html += `<p>${confidence}% : ${mStart
      .clone()
      .add(dayOffset, "days")
      .format("DD/MM/YYYY")}</p>`;
  }

  return html;
};

export const convertRiskObjectToExtraDaysHTML = (risks) => {
  //Risks have a nested data structure, and is not consistent across tasks with risk and without
  return (
    Object.keys(risks)
      //Remove 'risks' for tasks with no risks
      .filter((key) => {
        const [id] = Object.entries(risks[key])[0];
        if (id === "label" || id === "width") return false;
        return true;
      })
      //Make sure we proceed with an ascending iteration order
      .sort((a, b) => {
        const [aId] = Object.entries(risks[a])[0];
        const [bId] = Object.entries(risks[b])[0];
        return Number(aId) - Number(bId);
      })
      //Build up an html string, with awareness of the previous label's value
      .reduce(
        (accumulator, key) => {
          const [id, { label }] = Object.entries(risks[key])[0];
          const newLabel = label - accumulator.previousLabel;
          return {
            html:
              accumulator.html +
              `<p>${id}% : ${Math.floor(newLabel / 24)} day(s)</p>`,
            previousLabel: label,
          };
        },
        {
          html: "",
          previousLabel: 0,
        }
      ).html
  );
};
