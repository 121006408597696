import { Button, Input } from "@ehabitation/ui";
import pattern from "assets/grunge/pattern.svg";
import {
  MultiFactorSession,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  multiFactor,
  signOut,
} from "firebase/auth";
import { auth } from "firebaseConfig";
import { useEffect, useRef, useState } from "react";
import logo from "assets/images/logo.svg";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const EnrollMFAPage = ({ mfaUser }: { mfaUser: User }) => {
  const [phone, setPhone] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationId, setVerificationId] = useState<string>("");
  const [recaptchaCheck, setRecaptchaCheck] = useState(true);
  const [requestingCode, setRequestingCode] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const [requestError, setRequireRecentLogin] = useState<string>("");
  const [enrolled, setEnrolled] = useState(false);
  const recaptchaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (recaptchaVerifier) {
      if (recaptchaRef && recaptchaRef.current) {
        recaptchaRef.current.innerHTML = `<div id="mfa-captcha" ></div>`;
      }
    } else {
      const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
        size: "invisible",
      });

      setRecaptchaVerifier(verifier);
    }
  }, [recaptchaCheck]);

  const submitEnrollRequest = async () => {
    setRequestingCode(true);
    multiFactor(mfaUser)
      .getSession()
      .then(function (mfaSession: MultiFactorSession) {
        const phoneInfoOptions = {
          phoneNumber: phone,
          session: mfaSession,
        };
        const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
          size: "invisible",
        });

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, verifier)
          .then(function (verificationId) {
            setVerificationId(verificationId);
          })
          .catch(function (error) {
            console.log(error);
            if (error.code == "auth/requires-recent-login")
              setRequireRecentLogin(
                "Requires recent login, please sign out and in."
              );
            if (error.code == "auth/invalid-phone-number")
              setRequireRecentLogin(
                "Invalid phone number, include country code."
              );
            else
              setRequireRecentLogin(
                "Error requesting code. Please contact support@ehab.co"
              );
            setRequestingCode(false);
            setRecaptchaCheck(!recaptchaCheck);
          });
      })
      .catch((reason) => {
        console.error(reason);
      });

    setRequestingCode(false);
  };

  const verifyMfaEnrollment = async () => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await multiFactor(mfaUser).enroll(
      multiFactorAssertion,
      "Primary phone MFA"
    );
    setEnrolled(true);
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full overflow-auto py-4 sm:py-12"
      style={dotMatrixBgStyle}
    >
      <div className="w-[min(32rem,100vw)] flex flex-col items-center gap-6">
        <img
          className="w-full max-w-xs sm:max-w-lg m-6"
          src={logo}
          alt="EHAB Logo"
        />
        <div className="flex justify-center">
          <form>
            {/** don't change this weird embedding, required in the recaptcha dance! */}
            <div ref={recaptchaRef}>
              <div id="mfa-captcha"></div>
            </div>
            <p className="mt-6 mb-4">
              Your organisation requires a second authentication factor, please
              provide a phone number to receive verification codes.
            </p>
            <div>
              <div className="flex-grow flex flex-col items-stretch gap-2">
                <label
                  className="font-bold text-2xl self-start pl-8"
                  htmlFor="phone"
                >
                  Phone number
                </label>
                <div className="inline-flex">
                  <Input
                    value={phone}
                    className="inline-flex mb-4 mt-6 mr-4"
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    type="text"
                    name="mfa-phone"
                    placeholder="eg: +447733734253"
                    aria-label="MFA Phone"
                  />

                  <Button
                    id="enroll-button"
                    className="inline-flex mb-4 mt-6"
                    type="button"
                    disabled={requestingCode}
                    onClick={(e) => {
                      submitEnrollRequest();
                    }}
                  >
                    Request Code
                  </Button>
                </div>
              </div>
              <div className="flex-grow flex flex-col items-stretch gap-2">
                <label
                  className="font-bold text-2xl self-start pl-8"
                  htmlFor="phone"
                >
                  Verification Code
                </label>
                <div className="inline-flex">
                  <Input
                    className="mb-4 mt-6 mr-4"
                    onChange={(e) => {
                      setVerificationCode(e.target.value);
                    }}
                    type="text"
                    name="code"
                    aria-label="MFA Verify Code"
                  />
                  <div className="w-full"></div>
                  <Button
                    id="enroll-button"
                    className="justify-right mb-4 mt-6"
                    type="button"
                    disabled={!!!verificationId || !!!verificationCode}
                    onClick={(e) => {
                      verifyMfaEnrollment();
                    }}
                  >
                    Verify Code
                  </Button>
                </div>
              </div>
              {!enrolled && requestError && (
                <p className="text-red-500">{requestError}</p>
              )}
              {enrolled && (
                <p className="text-green-500">
                  "Successfully enrolled your second authentication, sign out
                  and in"
                </p>
              )}
              <div className="flex justify-center">
                <Button
                  id="logout-button"
                  className="mt-10 self-center"
                  type="submit"
                  onClick={async (e) => {
                    await signOut(auth);
                  }}
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnrollMFAPage;
