import { IProject } from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "helpers";

export const createNewProject = async (
  newProject: Omit<IProject, "projectId" | "id">
) => {
  const callCreateNewProject = firebaseFunction<{
    result: string;
    projectId: string;
  }>("createNewProject");
  const result = await callCreateNewProject(newProject);
  return result.data?.projectId;
};

export const updateProject = async (newProject: IProject) => {
  const callUpdateProject = firebaseFunction("updateProject");
  return await callUpdateProject(newProject);
};
