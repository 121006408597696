import { INewSiteForm } from "@ehabitation/ts-utils/browser";
import { createSelector } from "reselect";
import { receiveIdFromProps } from "store/selectors/generic";
import {
  selectOrganisationProjects,
  selectOrganisationSites,
  selectSelectedSite,
} from "store/siteManagement/siteManagementSlice";
import { selectDivisionMap } from "store/userManagement/userManagementSlice";
import { IOrganisationSite, ISiteNamesByProject } from "types";

export const selectSiteNamesByProject = createSelector(
  [selectOrganisationProjects, selectOrganisationSites],
  (organisationProjects, organisationSites): ISiteNamesByProject => {
    //Extract necessary site info
    const sites: { id: string; name: string; project: string }[] = [];
    Object.keys(organisationSites).forEach((siteId) => {
      sites.push({
        id: siteId,
        name: organisationSites[siteId].name!,
        project: organisationSites[siteId].project!,
      });
    });

    //Extract necessary project info
    const projects: { id: string; name: string }[] = [];
    Object.keys(organisationProjects).forEach((projectId) => {
      projects.push({
        id: projectId,
        name: organisationProjects[projectId].name!,
      });
    });

    //Combine the two into the correct data structure
    const allProjectSite: ISiteNamesByProject = {};
    projects.forEach((project) => {
      allProjectSite[project.id] = {
        name: project.name,
        sites: {},
      };
    });
    sites.forEach((site) => {
      allProjectSite[site.project].sites[site.id] = site.name;
    });

    return allProjectSite;
  }
);

export const selectOrderedSites = createSelector(
  [selectOrganisationSites, selectSiteNamesByProject],
  (sites, allProjectSite) => {
    const projectAndSiteMap: {
      [key: string]: IOrganisationSite[];
    } = {};

    Object.keys(sites).forEach((siteId) => {
      const site = sites[siteId];
      const parentProjectId = site.project;

      if (!parentProjectId) return;

      if (projectAndSiteMap.hasOwnProperty(parentProjectId)) {
        projectAndSiteMap[parentProjectId].push(site);
        return;
      }

      projectAndSiteMap[parentProjectId] = [site];
    });

    //Sort all site arrays on the object alphabetically
    Object.keys(projectAndSiteMap).forEach((projectId) => {
      projectAndSiteMap[projectId].sort((a, b) => {
        if (a.name! > b.name!) return 1;
        if (b.name! > a.name!) return -1;
        return 0;
      });
    });

    return (
      Object.keys(projectAndSiteMap)
        .map((projectId) => ({
          name: allProjectSite[projectId].name,
          id: projectId,
          sites: projectAndSiteMap[projectId],
        }))
        //Sort projects alphabetically
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        })
    );
  }
);

export const selectDivisionOptions = createSelector(
  [selectDivisionMap],
  (divisionMap) => {
    return Object.keys(divisionMap)
      .map((id) => ({
        id,
        name: divisionMap[id].name,
      }))
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
  }
);

export const selectProjectOptions = createSelector(
  [selectOrganisationProjects, receiveIdFromProps],
  (projects, divisionId) => {
    if (!divisionId) return [];

    return Object.keys(projects)
      .map((projectId) => ({
        id: projectId,
        name: projects[projectId].name || "",
        divisionId: projects[projectId].divisionId,
      }))
      .filter((project) => project.divisionId === divisionId)
      .sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
  }
);

export const selectSelectedSiteDetails = createSelector(
  [selectSelectedSite, selectOrganisationSites],
  (selectedSite, siteMap) => {
    if (!selectedSite) return null;
    const {
      id,
      name,
      description,
      location: { latitude: lat, longitude: long },
      startDate,
      endDate,
      orgId,
      divisionId: division,
      project,
      siteCode,
      visibility,
    } = siteMap[selectedSite];
    return {
      id,
      name,
      description,
      lat: lat.toString(),
      long: long.toString(),
      startDate,
      endDate,
      orgId,
      division,
      project,
      siteCode,
      visibility,
    } as INewSiteForm;
  }
);
