import {
  LoadingType,
  LoadingWrapper
} from "@ehabitation/ui";
import { FC, ReactNode } from "react";

import {
  IPlan,
  IRiskMatrix,
  ISimulation,
  ISite,
  ITask,
  ITaskCategory
} from "@ehabitation/ts-utils/browser";
import { SimulationResult, SimulationTaskResult } from "helpers";
import {
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories
} from "hooks";
import {
  useSimulationResult
} from "Pages/sites/hooks";



export const PlanTasksLoader: FC<{
    site: ISite;
    plan: IPlan;
    openingTaskId?: string;
    siteCategories: ITaskCategory[];
    simulation?: ISimulation;
    allTasksWithSimulationResults?: (ITask & {
      simulation: SimulationTaskResult;
    })[];
    isLoadingTasks: boolean;
    tasksError?: string;
    renderContent: ({
      simulationResult,
      allTasks,
      planRiskMatrix,
      planCategories,
    }: {
      simulationResult?: SimulationResult;
      allTasks?: (ITask & { simulation: SimulationTaskResult })[];
      planRiskMatrix?: IRiskMatrix;
      planCategories?: ITaskCategory[];
    }) => ReactNode;
  }> = ({
    plan,
    simulation,
    renderContent,
    tasksError,
    allTasksWithSimulationResults,
    isLoadingTasks,
  }) => {
    const { simulationResult, simulationResultLoading } =
      useSimulationResult(simulation);
  
    const { riskMatrix: planRiskMatrix, isLoading: isLoadingBaseRiskMatrix } =
      usePlanRiskMatrix(plan.id);
  
    const { categories: planCategories, isLoading: isLoadingBaseCategories } =
      useRiskMatrixTopLevelCategories(planRiskMatrix?.id);
  
    return tasksError ? (
      <div>Error loading tasks and categories</div>
    ) : (
      <LoadingWrapper
        message="Loading tasks..."
        type={LoadingType.subscriptions}
        loading={simulationResultLoading || isLoadingTasks}
      >
        {renderContent({
          simulationResult,
          allTasks: allTasksWithSimulationResults,
          planRiskMatrix,
          planCategories,
        })}
      </LoadingWrapper>
    );
  };