import { UserRole } from "@ehabitation/ts-utils/browser";

export const roles: UserRole[] = [
  UserRole.superAdmin,
  UserRole.orgAdmin,
  UserRole.divisionAdmin,
  UserRole.projectAdmin,
  UserRole.planner,
  UserRole.guest,
];
