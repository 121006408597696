import {
  collection,
  writeBatch,
  doc,
  getDocs,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import {
  CollectionType,
  ITaskCategory,
  getRiskMatrixHashes,
} from "@ehabitation/ts-utils/browser";
import _ from "lodash";

//Builds an update to state and the db, as well as a fallback snapshot
export const truncateName = (name: string) => {
  const maxLength = 55;
  if (name.length > maxLength) return name.substr(0, maxLength) + "...";
  return name;
};

export const updateCategoryThresholds = async (
  riskMatrixId: string,
  changeMap: Record<string, Partial<ITaskCategory>>
) => {
  const riskMatrixRef = doc(db, CollectionType.RiskMatrix, riskMatrixId);
  const rmCategoriesCollection = collection(
    riskMatrixRef,
    CollectionType.Categories
  );

  const rmUpdates = _.chunk(Object.entries(changeMap), 300).map(
    (changeChunk) => {
      const batch = writeBatch(db);
      for (const [id, update] of changeChunk) {
        batch.update(doc(rmCategoriesCollection, id), update);
      }
      return batch;
    }
  );

  await Promise.all(rmUpdates.map((batch) => batch.commit()));

  const projectRM = (await getDocs(rmCategoriesCollection)).docs.map(
    (doc) => doc.data() as ITaskCategory
  );

  const { rmDocMajorHash, rmDocMinorHash } = getRiskMatrixHashes(projectRM);

  await updateDoc(riskMatrixRef, {
    minorHash: rmDocMinorHash,
    majorHash: rmDocMajorHash,
    lastUpdated: Timestamp.now(),
  });
};
