import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { closeSettingsMenu } from "store/menu";
import { selectCurrentPlanId, setSaveAsModalIsOpen } from "store/project";
import { setTornadoModalIsOpen } from "store/tornado";
import {
  downloadHistoricalWeatherData,
  downloadTaskExportData,
  logout,
  rerunModel,
  logCopyKeyState,
  resetThresholds,
} from "./thunks";
import { selectActiveSiteId } from "store/auth";

export const useMenuActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentSiteId = useAppSelector(selectActiveSiteId);
  const currentPlanId = useAppSelector(selectCurrentPlanId);

  const handleGoToDashboard = useCallback(() => {
    dispatch(closeSettingsMenu());
    navigate("/");
  }, [dispatch, history]);

  const handleGoToPlanPage = useCallback(() => {
    dispatch(closeSettingsMenu());
    // EWW SO HACKY. Getting rid soon.
    navigate(`${location.pathname.split("settings")[0]}plan`);
  }, [dispatch, history]);

  const handleGoToSettingsPage = useCallback(() => {
    dispatch(closeSettingsMenu());
    // EWW SO HACKY. Getting rid soon.
    navigate(`${location.pathname.split("plan")[0]}settings`);
  }, [dispatch, history]);

  const handleGoToAdminPage = useCallback(() => {
    dispatch(closeSettingsMenu());
    navigate("/admin");
  }, [dispatch, history]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleOpenSaveAsModal = useCallback(() => {
    dispatch(setSaveAsModalIsOpen(true));
  }, [dispatch]);

  const handleOpenTornadoModal = useCallback(() => {
    dispatch(setTornadoModalIsOpen(true));
  }, [dispatch]);

  const handleDownloadHistoricalWeatherData = useCallback(() => {
    dispatch(downloadHistoricalWeatherData(currentSiteId));
  }, [dispatch]);

  const handleDownloadTaskExport = useCallback(() => {
    dispatch(downloadTaskExportData(currentSiteId, currentPlanId));
  }, [dispatch]);

  const handleRerunModel = useCallback(() => {
    dispatch(rerunModel());
  }, [dispatch]);

  const handleResetThresholds = useCallback(() => {
    dispatch(resetThresholds(currentPlanId));
  }, [dispatch]);

  const handleLogCopyKeyState = useCallback(() => {
    dispatch(logCopyKeyState());
  }, [dispatch]);

  return {
    handleGoToDashboard,
    handleGoToPlanPage,
    handleGoToSettingsPage,
    handleGoToAdminPage,
    handleLogout,
    handleOpenSaveAsModal,
    handleOpenTornadoModal,
    handleDownloadHistoricalWeatherData,
    handleDownloadTaskExport,
    handleRerunModel,
    handleResetThresholds,
    handleLogCopyKeyState,
  };
};
