import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { IFormError } from "types";
import { triggerCreateNewDivision, triggerUpdateDivision } from "./thunks";
import { newDivisionSchema } from "./validation";
import { validateNewForm } from "helpers/validation/validation";
import { selectSelectedDivisionDetails } from "store/divisionManagement";
import { IDivision, UserRole } from "@ehabitation/ts-utils/browser";

export const useNewDivisionForm = () => {
  const dispatch = useAppDispatch();

  const selectedDivisionDetails = useAppSelector(selectSelectedDivisionDetails);
  const [newDivision, updateNewDivision] = useState<IDivision>(
    !selectedDivisionDetails
      ? ({
          id: "",
          name: "",
          orgId: "",
          visibility: UserRole.planner,
        } as IDivision)
      : selectedDivisionDetails
  );

  const [error, setError] = useState<IFormError | null>(null);

  const handleUpdateForm = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    e.persist();
    const { name, value } = e.target;
    updateNewDivision((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const [isValid, error] = await validateNewForm(
      newDivisionSchema,
      newDivision
    );

    if (isValid && !newDivision.id)
      dispatch(triggerCreateNewDivision(newDivision));
    else if (isValid) dispatch(triggerUpdateDivision(newDivision));
    else if (!isValid && error) setError(error);
  };

  return {
    error,
    newDivision,
    handleUpdateForm,
    handleSubmit,
  };
};
