import React, { useEffect, useMemo, useState } from "react";

import { firebaseFunction } from "helpers";
import { IUser, UserRole } from "@ehabitation/ts-utils/browser";

export const useGrantRole = (
  role: UserRole | "",
  orgId: string,
  divisionId: string,
  projectId: string,
  users?: IUser[],
  initialEmail?: string
) => {
  const [email, setEmail] = useState(initialEmail || "");

  const [isHandlingRequest, setIsHandlingRequest] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [role, orgId, divisionId, projectId]);

  const entityId = useMemo(() => {
    switch (role) {
      case "superAdmin":
        return "superAdmin";
      case "orgAdmin":
      case "guest":
        return orgId;
      case "divisionAdmin":
        return divisionId;
      case "projectAdmin":
      case "planner":
        return projectId;
      default:
        return "";
    }
  }, [role, orgId, divisionId, projectId]);

  const handleUpdateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const foundUser = useMemo(() => {
    const foundUser = users?.find(
      (user) => user.email?.toLowerCase() === email.toLowerCase()
    );
    return foundUser;
  }, [email, users]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError("Please provide a valid email address");
      return;
    }

    if (!foundUser) {
      setError("The user does not exist");
      return;
    }

    if (!role) {
      setError(`Please select a role`);
      return;
    }

    if (!entityId) {
      setError(`Invalid selection for the ${role} role`);
      return;
    }

    const grantRole = firebaseFunction("masterGrantRole");

    setIsHandlingRequest(true);

    try {
      await grantRole({
        email,
        role,
        entityId,
      });

      setIsHandlingRequest(false);
    } catch (e) {
      const error = e as Error;
      console.error(error);
      setError(error.message);
      setIsHandlingRequest(false);
      throw e;
    }
  };

  return {
    email,
    handleUpdateEmail,
    handleSubmit,
    isHandlingRequest,
    foundUser,
    error,
    isValid: !!entityId,
  };
};
