//React
import React, { useLayoutEffect, useRef, useState } from "react";
//Styles
import {
  StyledSeriesMenu,
  StyledErrorMessage,
  StyledChart,
  StyledChartContainer,
  StyledLoadingMessage,
} from "./styles";
import * as am4charts from "@amcharts/amcharts4/charts";

import { useAppSelector } from "store";
import { selectActiveSiteId } from "store/auth/authSlice";
import config from "config";

//Types
import { WeatherMetric, Source, WeatherMetricLabels } from "./types";

//Components
import ToggleButton from "./ToggleButton";
import {
  addPrecAccSeries,
  addPrecSeries,
  addTempSeries,
  addWindSeries,
  addXAxis,
  addYAxis,
  createChart,
  setYAxisLabel,
  showSeries,
  sources,
  setupDataLoader,
  weatherMetrics,
} from "./helpers";

const weatherMetricLabels: WeatherMetricLabels = {
  temperature: "temp",
  windSpeed: "wind",
  precipitation: "prec",
  precipitationAccumulation: "prec acc",
};

const loadingMessage = (
  <StyledLoadingMessage>Loading Weather Chart...</StyledLoadingMessage>
);
const emptyMessage = (
  <StyledErrorMessage>No weather data found for site</StyledErrorMessage>
);
const errorMessage = (
  <StyledErrorMessage>Error loading weather data</StyledErrorMessage>
);

const WeatherGraph = () => {
  const siteId = useAppSelector(selectActiveSiteId);

  const [selectedWeatherMetric, setSelectedWeatherMetric] =
    useState<WeatherMetric>("temperature");
  const [dataEmpty, setDataEmpty] = useState<Boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<Boolean>(false);
  const [dataErrored, setDataErrored] = useState<Boolean>(false);

  const chart = useRef<am4charts.XYChart | null>(null);

  useLayoutEffect(() => {
    const handleDataEmpty = () => setDataEmpty(true);
    const handleDataLoaded = () => setDataLoaded(true);
    const handleDataLoadError = () => setDataErrored(true);
    chart.current = createChart("siteWeatherGraph");

    setupDataLoader(
      chart.current,
      `${config.WEATHER_GRAPH_URL}?site=${siteId}`,
      handleDataEmpty,
      handleDataLoaded,
      handleDataLoadError
    );

    addXAxis(chart.current);

    addYAxis(chart.current);

    for (const source of sources) {
      addPrecSeries(chart.current, source);
      addTempSeries(chart.current, source);
      addWindSeries(chart.current, source);
      addPrecAccSeries(chart.current, source);
    }

    return () => {
      chart.current?.dispose();
    };
  }, [siteId]);

  useLayoutEffect(() => {
    if (chart.current) {
      const seriesToShow: string[] = sources.map(
        (source: Source) => `${source}_${selectedWeatherMetric}`
      );

      showSeries(chart.current, seriesToShow);

      switch (selectedWeatherMetric) {
        case "temperature":
          setYAxisLabel(chart.current, "°C");
          break;
        case "windSpeed":
          setYAxisLabel(chart.current, "m/s");
          break;
        case "precipitation":
        case "precipitationAccumulation":
          setYAxisLabel(chart.current, "mm");
          break;
        default:
          setYAxisLabel(chart.current, "");
      }
    }
  }, [selectedWeatherMetric, siteId]);

  let graphInfoMessage = null;

  if (dataErrored) {
    graphInfoMessage = errorMessage;
  } else if (dataEmpty) {
    graphInfoMessage = emptyMessage;
  } else if (!dataLoaded) {
    graphInfoMessage = loadingMessage;
  }

  return (
    <>
      {graphInfoMessage}
      <StyledChartContainer visible={!graphInfoMessage}>
        <StyledSeriesMenu>
          {weatherMetrics.map((weatherMetric: WeatherMetric) => (
            <ToggleButton
              key={weatherMetric}
              label={weatherMetricLabels[weatherMetric]}
              weatherMetric={weatherMetric}
              selectedWeatherMetric={selectedWeatherMetric}
              toggleWeatherMetric={setSelectedWeatherMetric}
            />
          ))}
        </StyledSeriesMenu>
        <StyledChart id="siteWeatherGraph" key={siteId} />
      </StyledChartContainer>
    </>
  );
};

export default WeatherGraph;
