import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UnitState {
    currentUnit: 'metric' | 'imperial';
}

export const initialState: UnitState = {
    currentUnit: 'metric', // Default to metric
};

const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        setUnit: (state, action: PayloadAction<'metric' | 'imperial'>) => {
            state.currentUnit = action.payload;
        },
    },
});

export const { setUnit } = unitSlice.actions;
export default unitSlice.reducer; 